import { HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from '../../../utils';
import { VariantOptions } from 'components/ui/Badge/index';

const badgeVariants: Record<VariantOptions, string> = {
  default: 'border-transparent bg-primary text-white hover:bg-primary/80',
  secondary: 'border-transparent bg-secondary text-primary hover:bg-secondary/80',
  success: 'border-transparent bg-green-50 text-green-700 hover:bg-success/80',
  info: 'border-transparent bg-blue-100 text-blue-700 hover:bg-blue/80',
  warning: 'border-transparent bg-yellow-100 text-yellow-800 hover:bg-yellow/80',
  danger: 'border-transparent bg-red-100 text-red-700 hover:bg-danger/80',
  outline: 'text-secondary',
};

export interface BadgeProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  variant?: keyof typeof badgeVariants;
}

function BadgeFlat({ className, variant = 'default', ...props }: BadgeProps) {
  const baseClasses =
    'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2';
  return <div className={cn(baseClasses, badgeVariants[variant], className)} {...props} />;
}

export { BadgeFlat };

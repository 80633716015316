import '@aws-amplify/ui-react/styles.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PendingInvitationOrApplicationForm } from 'components/widgets/PendingInvitationOrApplicationForm';
import { getCompanySlugsFromLocalStorage } from 'hooks/access';
import useCustomAuth from 'hooks/useCustomAuth';
import { useActiveCompany } from 'providers/ActiveCompany';

const Home = () => {
  const [customers] = useState(() => {
    return getCompanySlugsFromLocalStorage();
  });
  const navigate = useNavigate();
  const { user } = useCustomAuth();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  useEffect(() => {
    if (customers) {
      if (customers.length === 1) {
        navigate('/dashboard');
      }
      if (customers.length > 1) {
        navigate('/dashboard');
      }
    }
  }, [navigate, user, customers, activeCompanySlug]);

  return <PendingInvitationOrApplicationForm />;
};

export default Home;

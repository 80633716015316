import { PaymentsVsSpendChart } from '../DashboardCharts/PaymentsVsSpendChart';

export const PaymentsVsSpendMarketingGraph = () => {
  return (
    <PaymentsVsSpendChart
      totalMargin={16_000}
      days={120}
      onChangeDays={() => {}}
      isLoading={false}
      disabled
      data={[
        {
          id: '5a7e26dd-42b6-4b31-a35e-c0a471b6e6ce',
          name: 'January',
          payments: 10_000,
          spend: 4_000,
        },
        {
          id: '4e2d0e79-74de-4682-8e60-42806fd07994',
          name: 'February',
          payments: 4_000,
          spend: 5_000,
        },
        {
          id: 'a3cd36ec-84c9-48a0-8c8c-9d04ae221dc0',
          name: 'March',
          payments: 5_000,
          spend: 3_000,
        },
        {
          id: '10eb8312-cab9-466e-8f8d-c18ac1b54bb7',
          name: 'April',
          payments: 6_000,
          spend: 7_500,
        },
      ]}
    />
  );
};

import Rewards from './Rewards';
import { PerksLayoutPage } from '../PerksLayoutPage';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { onApolloError } from 'utils';
import { AppState, useAppState } from 'stores/UserStore';
import { useEffect } from 'react';
import { PageTitle } from 'components/atoms/PageTitle';
import { GenericLoader } from 'components/atoms/Loader';
import { useActiveCompany } from 'providers/ActiveCompany';

const SET_VENDOR_DISCOUNT_VISITED_MUTATION = gql(`
  mutation SetVendorDiscountsVisitedCompleted($customerId: String!) {
    setVendorDiscountsVisited(customerId: $customerId) {
      message
    }
  }
`);

const GET_VISITED_SETTINGS = gql(`
  query GetVisitedSettings($customerId: String!) {
    getSettings(customerId: $customerId) {
        visitedVendorDiscounts
      }
  }
`);

export const PerksRewards = () => {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { data, loading } = useQuery(GET_VISITED_SETTINGS, {
    variables: {
      customerId: companySlug,
    },
  });

  const [setErrorMsg] = useAppState((state: AppState) => [state.setErrorMsg]);
  const [setVendorDiscountVisited] = useMutation(SET_VENDOR_DISCOUNT_VISITED_MUTATION, {
    variables: {
      customerId: companySlug,
    },
    onError: (error: ApolloError) => onApolloError(error, setErrorMsg, []),
    refetchQueries: ['OnboardingQuery'],
  });

  const visitedVendorDiscounts = !!data?.getSettings?.visitedVendorDiscounts;

  useEffect(() => {
    if (!visitedVendorDiscounts) {
      setVendorDiscountVisited();
    }
  }, [visitedVendorDiscounts, setVendorDiscountVisited]);

  if (loading) {
    return (
      <>
        <PageTitle hidden text="Loading perks..." />
        <div className="w-full flex justify-center self-start mt-28">
          <GenericLoader />
        </div>
      </>
    );
  }

  return (
    <PerksLayoutPage pageName="REWARDS">
      <Rewards customerId={companySlug} />
    </PerksLayoutPage>
  );
};

import { ShippingAddress } from '__generated__/graphql';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { User } from 'types';
import { formatNumberAsDollars } from 'utils';

type CardReviewStepProps = {
  shippingAddress: ShippingAddress;
  user: User;
  cardName: string;
  dailyLimit: number | null;
  monthlyLimit: number | null;
  cardType: 'physicalCard' | 'virtualCard';
  createCardLoading?: boolean;
};
const CardReviewStep = ({
  cardName,
  dailyLimit,
  monthlyLimit,
  shippingAddress,
  user,
  cardType,
  createCardLoading,
}: CardReviewStepProps) => {
  const { street, street2, city, state, postalCode } = shippingAddress;
  const cardTypeDisplay = cardType === 'physicalCard' ? 'Physical Card' : 'Virtual Card';
  return (
    <div>
      <h1 className="text-4xl mb-10">Review the information</h1>
      {user && (
        <div className="bg-white border rounded-3xl p-8 mb-10 shadow-3xl">
          <p className="font-medium text-black mb-2">Card Type</p>
          <p className="text-xl font-medium">{cardTypeDisplay}</p>
          <div className="border w-full my-6"></div>
          <p className="font-medium text-black mb-2">User</p>
          <p className="text-xl font-medium">{user?.name}</p>
        </div>
      )}
      <div>
        <div className="mb-6">
          <h2 className="text-2xl pb-2">Card details</h2>
          <p className="text-asphalt">You can modify this information later in the settings.</p>
        </div>

        <div className="border border-feather rounded-3xl p-8">
          <div>
            <p className="font-medium pb-2">Card name</p>
            <p className="text-lg">{cardName}</p>
          </div>
          {(dailyLimit || monthlyLimit) && (
            <>
              <div className="border w-full my-6"></div>
              <div className={'flex flex-row'}>
                {dailyLimit && (
                  <div className="grow">
                    <p className="font-medium pb-2">Daily spending limit</p>
                    <p className="text-lg">{formatNumberAsDollars(dailyLimit) || 'N/A'}</p>
                  </div>
                )}
                {monthlyLimit && (
                  <div className="grow">
                    <p className="font-medium pb-2">Monthly spending limit</p>
                    <p className="text-lg">{formatNumberAsDollars(monthlyLimit) || 'N/A'}</p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {street && (
        <div>
          <h2 className="text-2xl mt-10">Shipping Details</h2>
          <p className="text-asphalt mt-2 mb-6">
            Your physical card will arrive in 4-7 business days.{' '}
          </p>
          <div className="border border-feather rounded-3xl p-8">
            <div className="grid grid-cols-2">
              <div>
                <p className="font-medium pb-2">Street</p>
                <p className=" text-lg">{street}</p>
              </div>
              <div>
                {street2 && (
                  <>
                    <p className="font-medium pb-2">Apartment, unit, suite (Optional)</p>
                    <p className="text-lg">{street2}</p>
                  </>
                )}
              </div>
            </div>
            <div className="border w-full my-6"></div>
            <div className="grid grid-cols-2">
              <div>
                <p className="font-medium pb-2">City</p>
                <p className="text-lg">{city}</p>
              </div>
              <div>
                <p className="font-medium pb-2">State</p>
                <p className="text-lg">{state}</p>
              </div>
            </div>
            <div className="border w-full my-6"></div>
            <div className="grid grid-cols-2">
              <div>
                <p className="font-medium pb-2">Zip code</p>
                <p className="text-lg">{postalCode}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {createCardLoading && (
        <div className="absolute flex items-center mt-4">
          <LoadingSpinner />
          <div className="ml-2">Creating card...</div>
        </div>
      )}
    </div>
  );
};

export default CardReviewStep;

import { Pill } from 'components/atoms/Pill';
import { useSearchParams } from 'react-router-dom';
import { StatementFilters, useStatementFilter } from '../StatementFilter';
import moment from 'moment-timezone';

type StatementFiltersParam = keyof StatementFilters;

const StatementSelectedFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { savedFilters, updateAndSaveFilters } = useStatementFilter();
  const onClickCancel = (filter: StatementFiltersParam) => {
    const newFilters: StatementFilters = {};
    if (filter === 'lastMonth' || filter === 'lastQuarter' || filter === 'lastYear') {
      newFilters['fromDate'] = '';
      newFilters['toDate'] = '';
      newFilters[filter] = false;
    } else if (filter === 'statementDate') {
      searchParams.delete('statementDate');
      newFilters['statementDate'] = undefined;
      setSearchParams(searchParams);
    } else {
      newFilters[filter] = undefined;
    }
    updateAndSaveFilters(newFilters);
  };

  const formatDate = (date: string) => moment(date).format('MM/DD/YYYY');
  return (
    <div className="mx-10 flex h-10 w-full items-center">
      <div className="flex h-10 w-full items-center overflow-x-auto">
        {savedFilters?.lastMonth && (
          <Pill
            text={'Last Month'}
            variant={'cancelable'}
            className={'mx-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel('lastMonth')}
          />
        )}
        {savedFilters?.lastQuarter && (
          <Pill
            text={'Last Quarter'}
            variant={'cancelable'}
            className={'mx-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel('lastQuarter')}
          />
        )}
        {savedFilters?.lastYear && (
          <Pill
            text={'Last Year'}
            variant={'cancelable'}
            className={'mx-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel('lastYear')}
          />
        )}
        {savedFilters?.fromDate &&
          !savedFilters?.lastMonth &&
          !savedFilters?.lastQuarter &&
          !savedFilters?.lastYear && (
            <Pill
              text={'From: ' + formatDate(savedFilters?.fromDate)}
              variant={'cancelable'}
              className={'mx-1 flex-shrink-0'}
              onClickCancel={() => onClickCancel('fromDate')}
            />
          )}
        {savedFilters?.toDate &&
          !savedFilters?.lastMonth &&
          !savedFilters?.lastQuarter &&
          !savedFilters?.lastYear && (
            <Pill
              text={'To: ' + formatDate(savedFilters?.toDate)}
              variant={'cancelable'}
              className={'mx-1 flex-shrink-0'}
              onClickCancel={() => onClickCancel('toDate')}
            />
          )}
        {savedFilters?.statementDate && (
          <Pill
            text={'Statement Date: ' + savedFilters.statementDate}
            variant={'cancelable'}
            className={'mx-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel('statementDate')}
          />
        )}
      </div>
    </div>
  );
};

export default StatementSelectedFilters;

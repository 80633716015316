import { ApplyForZenaCard } from 'components/widgets/marketing/ApplyForZenaCard';
import { ConnectRepaymentAccount } from 'components/widgets/marketing/ConnectRepaymentAccount';
import { FinishApplyingForZenaCard } from 'components/widgets/marketing/FinishApplyingForZenaCard';
import { DashboardContainer } from 'pages/(dashboard)/DashboardLayout/DashboardContainer';
import { ReactNode } from 'react';

const MarketingWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <DashboardContainer
      className="flex justify-center items-center bg-accent-200 m-4 border border-primary-400 border-solid"
      gridColumnsWidth={3}
    >
      <div className="flex justify-center w-full">{children}</div>
    </DashboardContainer>
  );
};

export const FinishApplingForZenaCardMarketing = () => (
  <MarketingWrapper>
    <FinishApplyingForZenaCard />
  </MarketingWrapper>
);

export const ApplyForZenaCardMarketing = () => (
  <MarketingWrapper>
    <ApplyForZenaCard />
  </MarketingWrapper>
);

export const ConnectPaymentAccountMarketing = () => (
  <MarketingWrapper>
    <ConnectRepaymentAccount />
  </MarketingWrapper>
);

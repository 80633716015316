import { Project } from '__generated__/graphql';
import { ReactNode } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface SelectableUser {
  id: string;
  name: string;
  email: string;
  profilePictureUrl?: string;
}

export type Selectable = SelectableUser | Project | { id: string; name: string };

export interface LocalUserStore {
  signedProfilePhotoUrl: string;
  smallSignedProfilePhotoUrl: string;
  setSignedProfilePhotoUrl: (signedUrl: string) => void;
  setSmallSignedProfilePhotoUrl: (smallSignedUrl: string) => void;
  impersonationEmail: string;
  setImpersonationEmail: (email: string) => void;
}

export interface AppState {
  hasStatement: boolean | null;
  setHasStatement: (hasStatement: boolean) => void;
  userAttemptedPlaidSetup: boolean;
  setUserAttemptedPlaidSetup: (attempted: boolean) => void;
  errorMsg: ReactNode;
  setErrorMsg: (errorMsg: ReactNode) => void;
  successMsg: ReactNode;
  setSuccessMsg: (successMsg: ReactNode) => void;
  projectUsers: readonly Selectable[];
  setProjectUsers: (users: readonly Selectable[]) => void;
  addProjectUser: (item: Selectable) => void;
  removeProjectUser: (item: Selectable) => void;
  teamSettingsVisited: string | null;
  setTeamSettingsVisited: (teamSettingsVisited: string | null) => void;
}

export const useLocalStore = create<LocalUserStore>()(
  persist(
    (set) => ({
      signedProfilePhotoUrl: '',
      setSignedProfilePhotoUrl: (signedUrl) =>
        set((state) => ({ signedProfilePhotoUrl: signedUrl })),
      smallSignedProfilePhotoUrl: '',
      setSmallSignedProfilePhotoUrl: (smallSignedUrl) =>
        set((state) => ({ smallSignedProfilePhotoUrl: smallSignedUrl })),
      impersonationEmail: '',
      setImpersonationEmail: (email) => set((state) => ({ impersonationEmail: email })),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useAppState = create<AppState>((set) => ({
  hasStatement: null,
  setHasStatement: (hasStatement: boolean) => set((state) => ({ hasStatement })),
  userAttemptedPlaidSetup: false,
  setUserAttemptedPlaidSetup: (attempted) =>
    set((state) => ({ userAttemptedPlaidSetup: attempted })),
  errorMsg: '',
  setErrorMsg: (errorMsg) => set((state) => ({ errorMsg })),
  successMsg: '',
  setSuccessMsg: (successMsg) => set((state) => ({ successMsg })),
  projectUsers: [],
  setProjectUsers: (users) => set((state) => ({ projectUsers: users })),
  addProjectUser: (item) => set((state) => ({ projectUsers: [...state.projectUsers, item] })),
  removeProjectUser: (item) =>
    set((state) => ({ projectUsers: state.projectUsers.filter((user) => user.id !== item.id) })),
  teamSettingsVisited: null,
  setTeamSettingsVisited: (teamSettingsVisited: string | null) =>
    set((state) => ({ teamSettingsVisited })),
}));

import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from 'lucide-react';
import { gql } from '__generated__/gql';
import { useMutation, useQuery } from '@apollo/client';
import { Card } from '__generated__/graphql';
export const cardType = [
  {
    value: 'virtual',
    label: 'Virtual',
  },
  {
    value: 'physical',
    label: 'Physical',
  },
];

// currently OpenSearch sends back a title-cased status. This will be fixed in the future, but for now value and label are the same.
export const statuses = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Inactive',
    label: 'Inactive',
  },
  {
    value: 'Frozen',
    label: 'Frozen',
  },
  {
    value: 'Closed',
    label: 'Closed',
  },
];

export const QUERY_GET_ALL_CARDS = gql(`
  query GetAllCards($companySlug: String!, $corporateOnly: Boolean = false, $filters: CardFiltersInput) {
    getCards(companySlug: $companySlug, corporateOnly: $corporateOnly, filters: $filters) {
      data {
        id
        name
        projectName
        type
        status
        last4Digits
        cardholderFullName {
          first
          last
        }
        expirationDate
        shippingAddress {
          street
          street2
          city
          state
          postalCode
          country
        }
      }
    }
  }
`);

export const useGetCards = ({ customerId }: { customerId: string }) => {
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ALL_CARDS, {
    variables: {
      companySlug: customerId,
      corporateOnly: true,
      filters: {},
    },
  });

  return {
    cards: data?.getCards?.data ?? ([] as Card[]),
    loading,
    error,
    refetch,
  };
};

export const MUTATIONS_BULK_FREEZE_CARDS = gql(`
  mutation BulkFreezeCards($companySlug: String!, $cardIds: [String!]!) {
      bulkFreezeZenaCards(companySlug: $companySlug, cardIds: $cardIds) {
        data {
          id
          name
          type
          status
          last4Digits
          cardholderFullName {
            first
            last
          }
          expirationDate
          shippingAddress {
            street
            street2
            city
            state
            postalCode
            country
          }
        }
    }
  }
`);

export const useMutationBulkFreezeCards = () => {
  const [trigger, { data, loading, error }] = useMutation(MUTATIONS_BULK_FREEZE_CARDS);

  return {
    trigger,
    data,
    loading,
    error,
  };
};

export const MUTATIONS_BULK_CLOSE_CARDS = gql(`
  mutation BulkCloseCards($companySlug: String!, $cardIds: [String!]!) {
      bulkCloseCards(companySlug: $companySlug, cardIds: $cardIds) {
        data {
          id
          name
          type
          status
          last4Digits
          cardholderFullName {
            first
            last
          }
          expirationDate
          shippingAddress {
            street
            street2
            city
            state
            postalCode
            country
          }
        }
    }
  }
`);

export const useMutationBulkCloseCards = () => {
  const [trigger, { data, loading, error }] = useMutation(MUTATIONS_BULK_CLOSE_CARDS);

  return {
    trigger,
    data,
    loading,
    error,
  };
};

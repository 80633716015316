import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Card } from '__generated__/graphql';
import { Button } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { Modal } from 'components/atoms/Modal';
import { getAllStates } from 'utils/us-states';
import useGooglePlaceAutoComplete from 'hooks/googlePlaceAutocomplete';
import { useEffect, useRef, useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { Pencil } from 'lucide-react';

export interface ReplaceCardModalProps {
  show: boolean;
  onClose: Function;
  cardData: Card;
  cardId: string;
  customerId: string;
}

const MUTATION_REPLACE_CARD = gql(`
  mutation ReplaceCard($customerId: String!, $cardId: String!, $shippingAddress: ShippingAddress!) {
    replaceCard(customerId: $customerId, cardId: $cardId, shippingAddress: $shippingAddress) {
      id
    }
  }
`);

const ReplaceCardModal = ({
  show,
  onClose,
  cardData,
  cardId,
  customerId,
}: ReplaceCardModalProps) => {
  const [updateAddressSectionActive, setUpdateAddressSectionActive] = useState(false);
  const [inputStreet, setInputStreet] = useState('');
  const [inputStreet2, setInputStreet2] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputState, setInputState] = useState('');
  const [inputZipCode, setInputZipCode] = useState('');

  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [, setErrorMessage] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();

  const states = getAllStates();
  const address1Ref = useRef<HTMLInputElement>(null);
  let autoComplete = '';

  useEffect(() => {
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    if (address1Ref.current) {
      address1Ref.current.value = inputStreet || '';
      loadGoogleMaps();
    }
  }, [updateAddressSectionActive]);

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

    if (address1Ref.current) {
      address1Ref.current.value = addressObj.address1;
    }

    setInputStreet(addressObj.address1);
    setInputStreet2('');
    setInputCity(addressObj.locality || addressObj.sublocality);
    setInputState(addressObj.adminArea1Short);
    setInputZipCode(addressObj.postalCode);

    googleAutoCompleteSvc.clearResult(autoComplete);
  };

  const { street, street2, city, state, postalCode, country } = cardData.shippingAddress || {};

  const shippingAddressString = `${
    street + (street2 ? street2 + ' ' : '')
  }, ${city}, ${state}, ${country}, ${postalCode} `;

  const [replaceCard] = useMutation(MUTATION_REPLACE_CARD, {
    onCompleted: () => {
      handleClose(true);
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  const validateFields = () => {
    let hasError = false;
    if (!inputStreet) {
      setStreetError('Street is required');
      hasError = true;
    } else {
      setStreetError('');
    }
    if (!inputCity) {
      setCityError('City is required');
      hasError = true;
    } else {
      setCityError('');
    }
    if (!inputCity) {
      setCityError('City is Required');
      hasError = true;
    } else {
      setCityError('');
    }
    if (!inputState) {
      setStateError('State is Required');
      hasError = true;
    } else {
      setStateError('');
    }
    if (!inputZipCode) {
      setZipCodeError('Zip code is Required');
      hasError = true;
    } else {
      setZipCodeError('');
    }

    return hasError;
  };

  const onSubmit = () => {
    if (updateAddressSectionActive) {
      const hasError = validateFields();
      if (!hasError) {
        replaceCard({
          variables: {
            cardId,
            customerId,
            shippingAddress: {
              street: inputStreet,
              street2: inputStreet2,
              city: inputCity,
              state: inputState,
              postalCode: inputZipCode,
              country: 'US',
            },
          },
        });
      }
    } else {
      if (street && city && state && postalCode) {
        replaceCard({
          variables: {
            cardId,
            customerId,
            shippingAddress: {
              street: street,
              street2: street2,
              city: city,
              state: state,
              postalCode: postalCode,
              country: 'US',
            },
          },
        });
      }
    }
  };

  const handleClose = (showSuccess: boolean) => {
    setUpdateAddressSectionActive(false);
    onClose(showSuccess);
  };

  return (
    <Modal show={show} onClose={() => handleClose(false)}>
      <div className="font-['Helvetica'] leading-normal">
        <div className="font-bold text-[14px] text-gray-500">Replace card</div>
        {!updateAddressSectionActive ? (
          <>
            <h2 className="font-bold text-2xl mb-4">Confirm shipping address</h2>
            <div className="text-base text-gray-500 mb-12">
              Your card will be shipped to this address in 5-7 buisness days
            </div>
            <TextInput disabled label="" value={shippingAddressString} />
            <Button
              variant="secondary"
              className="float-right p-0 border-none text-sm"
              onClick={() => setUpdateAddressSectionActive(true)}
            >
              <div>
                <span className="inline">Change address</span>{' '}
                <span className="inline pl-1">
                  <Pencil />
                </span>
              </div>
            </Button>
            <Button className="mt-4 w-full" onClick={onSubmit}>
              Proceed
            </Button>
          </>
        ) : (
          <>
            <h2 className="font-bold text-2xl">Update your shipping address</h2>
            <div className="text-base text-gray-500 mb-12">
              Your card will be shipped to this address in 5-7 business days
            </div>
            <TextInput
              hasError={!!streetError}
              errorMessage={streetError}
              ref={address1Ref}
              className="mb-3"
              label="Street Address"
              value={inputStreet}
              onChange={(e) => setInputStreet(e.target.value)}
            />
            <div className="flex gap-3 mb-3">
              <TextInput
                label="Apt, Suite, Unit"
                value={inputStreet2}
                onChange={(e) => setInputStreet(e.target.value)}
              />
              <TextInput
                hasError={!!cityError}
                errorMessage={streetError}
                label="City"
                value={inputCity}
                onChange={(e) => setInputCity(e.target.value)}
              />
            </div>
            <div className="flex gap-3 mb-3">
              <div
                className={`w-1/2 flex flex-col rounded-lg border ${
                  !!stateError ? 'border-rustic' : 'border-marble'
                } px-4 pt-2 pb-1.5`}
              >
                <label className="block mb-2 text-sm text-gray-900">State</label>
                <select
                  value={inputState}
                  onChange={(e) => setInputState(e.target.value)}
                  className="bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-lavender"
                >
                  {states.map((stateOpt) => (
                    <option key={stateOpt.isoCode} value={stateOpt.isoCode}>
                      {stateOpt.isoCode}
                    </option>
                  ))}
                </select>
              </div>
              <TextInput
                hasError={!!zipCodeError}
                errorMessage={zipCodeError}
                label="Zip code"
                value={inputZipCode}
                onChange={(e) => setInputZipCode(e.target.value)}
              />
            </div>
            <Button className="mt-4 w-full" onClick={onSubmit}>
              Confirm Address
            </Button>
            <Button
              className="mt-4 w-full"
              variant="secondary"
              onClick={() => setUpdateAddressSectionActive(false)}
            >
              Back
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ReplaceCardModal;

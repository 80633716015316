import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ShippingAddress } from '__generated__/graphql';
import { PageTitle } from 'components/atoms/PageTitle';
import { Workflow, WorkflowStep } from 'components/widgets/Workflow';
import { isAdmin } from 'hooks/access';
import useCustomAuth from 'hooks/useCustomAuth';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AppState, useAppState } from 'stores/UserStore';
import { nonNullable, onApolloError, toCents } from 'utils';
import {
  CardNameStep,
  CardReviewStep,
  CardShippingAddressStep,
  ChooseCardStep,
  FinishStep,
  SelectCardUser,
} from './steps';
import { useWindowKeyboardInteraction } from 'components/atoms/WindowInteractionListeners/keyboard';
import { QUERY_GET_CUSTOMER_CARDS } from 'pages/Cards/data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { User } from 'types';

export const MUTATION_CREATE_CARD = gql(`
  mutation CreateCard($customerId: String!, $cardName: String!, $shippingAddress: ShippingAddress, $dailyLimit: Int, $monthlyLimit: Int, $virtual: Boolean, $cardholderUserId: String!) {
    createCard(customerId: $customerId, cardName: $cardName, shippingAddress: $shippingAddress, dailyLimit: $dailyLimit, monthlyLimit: $monthlyLimit, virtual: $virtual, cardholderUserId: $cardholderUserId) {
      id
      last4Digits
    }
  }
`);

const GET_SELECTABLE_CARD_USERS = gql(`
query GetCustomerTeamMembers($customerId:String!){
  getCustomerTeamMembers(customerId:$customerId) {
    userId
    fullName {
      first
      last
    }
    email
    signedProfilePhotoUrl
    role
  }
}
`);

type CreateCardMutationProps = {
  customerId: string;
  cardName: string;
  cardholderUserId: string;
  shippingAddress?: ShippingAddress;
  dailyLimit?: number | undefined;
  monthlyLimit?: number | undefined;
  virtual: boolean;
};

const CreateCardWorkflow = ({
  referringPage,
}: {
  referringPage: '/cards' | '/cards-management';
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { user: currentUser } = useCustomAuth();
  const adminAccess = isAdmin(activeCompanySlug);
  const cardType = useRef<'physicalCard' | 'virtualCard'>('physicalCard');

  let startStep = 'chooseCard';

  const [currentStep, setCurrentStep] = useState(startStep);

  const [cardLast4Digits, setCardLast4Digits] = useState<string | undefined>(undefined);
  const [selectableUsers, setSelectableUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [cardName, setCardName] = useState('');
  const [cardId, setCardId] = useState<string | undefined>(undefined);
  const [cardNameError, setCardNameError] = useState('');
  const [dailyLimit, setDailyLimit] = useState<number | null>(null);
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);
  const [alertMsg, setAlertMsg] = useState('');
  const [progress, setProgress] = useState(0);
  const [numSteps, setNumSteps] = useState(0);
  const [addressStreetError, setAddressStreetError] = useState('');
  const [addressCityError, setAddressCityError] = useState('');
  const [addressStateError, setAddressStateError] = useState('');
  const [addressPostalError, setAddressPostalError] = useState('');
  const [setErrorMsg] = useAppState((state: AppState) => [state.setErrorMsg]);
  const {
    register: shippingAddressFormRegister,
    setValue: setShippingAddressValue,
    getValues: getShippingAddressValues,
  } = useForm<ShippingAddress>();
  const navigate = useNavigate();

  const expectedErrorTypes = ['CreateCardFailed', 'CardLimitError', 'InvalidCharacterError'];

  const { data: getSelectableCardUsers, loading: getSelectableCardUsersLoading } = useQuery(
    GET_SELECTABLE_CARD_USERS,
    {
      variables: {
        customerId: activeCompanySlug,
      },
    }
  );

  const [createCard, { loading: createCardLoading, client }] = useMutation(MUTATION_CREATE_CARD, {
    onError: (error: ApolloError) => onApolloError(error, setErrorMsg, expectedErrorTypes),
  });

  const getCurrentUserData = (): User => {
    return {
      id: currentUser?.attributes?.sub ? currentUser.attributes.sub : '',
      name: 'You',
      email: currentUser?.attributes.email ? currentUser.attributes.email : '',
    };
  };

  useEffect(() => {
    let teamMembers = getSelectableCardUsers?.getCustomerTeamMembers || [];
    if (teamMembers) {
      const currentUserId =
        currentUser && currentUser.attributes ? currentUser.attributes.sub : null;
      const filteredTeamMembers = teamMembers.filter(nonNullable).map<User>((user) => ({
        id: user.userId,
        name:
          user.userId === currentUserId ? 'You' : `${user.fullName.first} ${user.fullName.last}`,
        email: user.email,
      }));
      setSelectableUsers(filteredTeamMembers);
    }
  }, [getSelectableCardUsers]);

  useEffect(() => {
    if (currentStep === 'physicalCard') {
      setNumSteps(4); // physicalCard > cardName > shippingAddress > cardReview
    } else if (cardType.current === 'physicalCard' && numSteps === 0) {
      // You hit this situation if adding a physical card as a team member
      setNumSteps(3); // cardName > shippingAddress > cardReview
    } else if (cardType.current === 'virtualCard') {
      setNumSteps(3); // chooser > cardName  > cardReview
    }
  }, [currentStep]);

  const issueCard = async () => {
    const variables: CreateCardMutationProps = {
      customerId: activeCompanySlug,
      cardName,
      cardholderUserId: selectedUser ? selectedUser.id : currentUser?.attributes?.sub || '',
      dailyLimit: toCents(dailyLimit || 0),
      monthlyLimit: toCents(monthlyLimit || 0),
      virtual: cardType.current === 'virtualCard',
    };
    if (cardType.current === 'physicalCard') {
      variables.shippingAddress = { ...getShippingAddressValues(), country: 'US' };
    }
    createCard({ variables }).then((res) => {
      const newCardData = res.data;
      const newCard = newCardData?.createCard;
      if (!newCard) {
        return;
      }

      setCardId(newCard.id);
      setCardLast4Digits(newCard.last4Digits || undefined);

      setCurrentStep('finish');
      setProgress(progress + 1);

      // If it does not have a last 4 digits, this suggests it is a card request, and not a full-fledged card. Don't
      // attempt to add to the cards list.
      if (!newCard.last4Digits) {
        return;
      }
      // Insert new card ID on top of cards cache
      client.cache.updateQuery(
        {
          query: QUERY_GET_CUSTOMER_CARDS,
          variables: {
            companySlug: activeCompanySlug,
            corporateOnly: true,
          },
        },
        (cardsData) => {
          return {
            ...cardsData,
            getCards: {
              ...cardsData?.getCards,
              data: [
                // Dedupe in case idempotency returned an existing card
                ...(!cardsData?.getCards?.data.some(
                  (card: any) => card.id === newCardData?.createCard?.id
                )
                  ? newCardData.createCard
                    ? [newCardData.createCard]
                    : []
                  : []),
                ...(cardsData?.getCards?.data ?? []),
              ],
            },
          };
        }
      );
    });
  };

  const setErrorMessageForAddress = () => {
    const shippingAddressValues = getShippingAddressValues();

    if (!shippingAddressValues.street) {
      setAddressStreetError('Please fill out a valid street');
    }
    if (!shippingAddressValues.city) {
      setAddressCityError('Please fill out a valid city');
    }
    if (!shippingAddressValues.state) {
      setAddressStateError('Please fill out a valid state');
    }
    if (!shippingAddressValues.postalCode) {
      setAddressPostalError('Please fill out a valid zip code');
    }
  };

  const buttonRef = useRef<HTMLButtonElement>(null);
  useWindowKeyboardInteraction({
    onKeyDown: (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const address = getShippingAddressValues();

        if (currentStep === 'shippingAddress' && address.city.length === 0) {
          // do nothing
        } else {
          if (buttonRef.current) {
            buttonRef.current.click();
          }
        }
      } else if (event.key === 'Escape') {
        navigate(referringPage);
      }
    },
  });

  return (
    <>
      <PageTitle hidden text="Create new card" />
      <Workflow
        rootUrl="/"
        closeHref={referringPage}
        currentStep={currentStep}
        alertMsg={alertMsg}
        progress={[progress, numSteps]}
      >
        <WorkflowStep
          currentStep={currentStep}
          step="chooseCard"
          ctaButton={{
            onClick: () => {
              if (cardType.current === 'physicalCard') {
                if (!adminAccess) {
                  setCurrentStep('cardName');
                  setProgress(progress + 1);
                  setSelectedUser(getCurrentUserData());
                } else {
                  setCurrentStep('physicalCard');
                }
              } else if (cardType.current === 'virtualCard') {
                setCurrentStep('cardName');
              }
              setProgress(progress + 1);
            },
          }}
          backButton={{
            href: referringPage,
            hidden: true,
          }}
          ref={buttonRef}
        >
          <ChooseCardStep
            onCardSelect={(value) => {
              cardType.current = value;
            }}
            isAdmin={adminAccess}
          />
        </WorkflowStep>
        <WorkflowStep
          currentStep={currentStep}
          step="cardName"
          ctaButton={{
            onClick: (e: React.MouseEvent<HTMLElement>) => {
              if (!cardName) {
                setCardNameError('Card name is required');
                e.preventDefault();
                return;
              } else {
                setCardNameError('');
              }
              setProgress(progress + 1);
              if (cardType.current === 'virtualCard') {
                setCurrentStep('cardReview');
              } else {
                setCurrentStep('shippingAddress');
              }
            },
          }}
          ref={buttonRef}
          backButton={{
            hidden: !adminAccess,
            onClick: () => {
              if (cardType.current === 'physicalCard') {
                setCurrentStep('physicalCard');
              } else if (cardType.current === 'virtualCard') {
                setCurrentStep('chooseCard');
                cardType.current = 'physicalCard';
              }
              setProgress(progress - 1);
            },
          }}
        >
          <CardNameStep
            cardName={cardName}
            cardNameError={cardNameError}
            setCardName={setCardName}
          />
        </WorkflowStep>
        <WorkflowStep
          currentStep={currentStep}
          step="physicalCard"
          ctaButton={{
            onClick: () => {
              if (!selectedUser) {
                setAlertMsg('Please select a user');
                return;
              } else {
                setAlertMsg('');
              }
              setCurrentStep('cardName');
              setProgress(progress + 1);
            },
          }}
          ref={buttonRef}
          backButton={{
            onClick: () => {
              setCurrentStep('chooseCard');
              setProgress(progress - 1);
            },
          }}
        >
          {getSelectableCardUsersLoading ? (
            <LoadingSpinner />
          ) : (
            <SelectCardUser
              selectableUsers={selectableUsers}
              onUserSelect={(user) => setSelectedUser(user)}
            />
          )}
        </WorkflowStep>
        <WorkflowStep
          currentStep={currentStep}
          step="shippingAddress"
          ctaButton={{
            onClick: () => {
              if (
                !getShippingAddressValues().street ||
                !getShippingAddressValues().city ||
                !getShippingAddressValues().state ||
                !getShippingAddressValues().postalCode
              ) {
                setErrorMessageForAddress();
                return;
              } else {
                setAddressStreetError('');
                setAddressCityError('');
                setAddressStateError('');
                setAddressPostalError('');
              }
              setProgress(progress + 1);
              setCurrentStep('cardReview');
            },
          }}
          ref={buttonRef}
          backButton={{
            onClick: () => {
              setCurrentStep('cardName');
              setProgress(progress - 1);
            },
          }}
        >
          <CardShippingAddressStep
            formRegister={shippingAddressFormRegister}
            setValue={setShippingAddressValue}
            getValues={getShippingAddressValues}
            addressStreetError={addressStreetError}
            addressCityError={addressCityError}
            addressStateError={addressStateError}
            addressPostalCodeError={addressPostalError}
          />
        </WorkflowStep>
        <WorkflowStep
          currentStep={currentStep}
          step="cardReview"
          ctaButton={{
            onClick: issueCard,
            text: 'Create card',
            disabled: createCardLoading,
          }}
          ref={buttonRef}
          backButton={{
            onClick: () => {
              if (cardType.current === 'physicalCard') {
                setCurrentStep('shippingAddress');
              } else if (cardType.current === 'virtualCard') {
                setCurrentStep('cardName');
              }
              setProgress(progress - 1);
            },
          }}
        >
          <CardReviewStep
            shippingAddress={getShippingAddressValues()}
            user={selectedUser!}
            cardName={cardName}
            dailyLimit={dailyLimit}
            monthlyLimit={monthlyLimit}
            cardType={cardType.current}
            createCardLoading={createCardLoading}
          />
        </WorkflowStep>
        <WorkflowStep
          buttonsCentered
          currentStep={currentStep}
          step="finish"
          ctaButton={{
            text: adminAccess ? 'View card' : 'Back to cards',
            href: adminAccess ? `${referringPage}/${cardId}` : referringPage,
            hidden: true,
          }}
          ref={buttonRef}
          backButton={{
            onClick: () => {
              setCurrentStep('cardReview');
            },
            hidden: true,
          }}
        >
          <FinishStep
            cardLast4Digits={cardLast4Digits}
            cardName={cardName}
            cardId={cardId}
            isAdmin={adminAccess}
            cardType={cardType.current}
            referringPage={referringPage}
          />
        </WorkflowStep>
      </Workflow>
    </>
  );
};

export default CreateCardWorkflow;

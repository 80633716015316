import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { onApolloError } from '../../../../../../utils';
import { MUTATION_UPDATE_NOTIFICATION_SETTINGS } from './data';
import { SettingsSwitchProps } from './index';
import { SelectField } from '@aws-amplify/ui-react';
import { OutlinedWidgetWithSwitch } from './OutlinedWidgetWithSwitch';

export const LargeTransactionAlert = ({
  loading,
  customerId,
  setErrorMsg,
  initialValue,
  defaultEnabled = false,
}: SettingsSwitchProps<number>) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);
  useEffect(() => {
    setIsEnabled(defaultEnabled);
  }, [defaultEnabled]);
  const [value, setValue] = useState(initialValue || 0);
  const [updateSettings, { loading: isUpdating, called: updated }] = useMutation(
    MUTATION_UPDATE_NOTIFICATION_SETTINGS,
    {
      onError: (error) => onApolloError(error, setErrorMsg, ['UpdateNotificationSettingsFailed']),
    }
  );
  const onUpdate = (checked: boolean, value: number) => {
    updateSettings({
      variables: {
        customerId,
        notificationSettings: {
          largeTransactions: checked ? value : null,
        },
      },
    });
  };

  return (
    <OutlinedWidgetWithSwitch
      title="Large transaction alert"
      loading={loading}
      checked={isEnabled}
      isUpdating={isUpdating}
      hasUpdated={updated}
      onChange={(checked) => {
        setIsEnabled(checked);
        onUpdate(checked, value);
      }}
    >
      <div className="flex items-center flex-col md:flex-row">
        <p>Triggers when a Zena Card transaction is larger than</p>
        <div className="m-2">
          <SelectField
            label="Trigger Dollar Amount"
            labelHidden
            value={value.toString()}
            onChange={(e) => {
              const v = parseInt(e.target.value);
              setValue(v);
              onUpdate(isEnabled, v);
            }}
            disabled={!isEnabled}
          >
            <option value={500}>$500</option>
            <option value={1000}>$1,000</option>
            <option value={2500}>$2,500</option>
            <option value={5000}>$5,000</option>
            <option value={10000}>$10,000</option>
          </SelectField>
        </div>
      </div>
    </OutlinedWidgetWithSwitch>
  );
};

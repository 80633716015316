import { Days } from 'components/atoms/DaysDropdown';
import { SpendBreakdownChart } from 'pages/(dashboard)/DashboardCharts/SpendBreakdownChart';
import { useState } from 'react';
import { useSpendBreakdownCategoryTotals } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';

export const SpendBreakdownWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [days, setDays] = useState<Days>(30);
  const today = new Date();
  const fromDate = new Date(today.setDate(today.getDate() - days));
  const { loading, categorizedData } = useSpendBreakdownCategoryTotals({
    companySlug: activeCompanySlug,
    fromDate,
  });

  return (
    <SpendBreakdownChart
      totalSpending={categorizedData.totalAmount}
      days={days}
      onChangeDays={(newDays) => {
        setDays(newDays);
      }}
      isLoading={loading}
      className="min-h-[400px]"
      data={[
        {
          name: 'Projects',
          id: 'Projects',
          amount: categorizedData.totalProjectAmount,
        },
        {
          name: 'Other',
          id: 'Other',
          amount: categorizedData.totalOtherAmount,
        },
      ]}
    />
  );
};

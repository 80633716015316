import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const OAuthLanding = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    const realmId = searchParams.get('realmId');
    const csrfToken = searchParams.get('state');
    const allValues = searchParams.toString();
    if (code && realmId) {
      localStorage.setItem(
        'qboOAuthResponse',
        JSON.stringify({ code, realmId, csrfToken, allValues })
      );
    }
  }, []);

  return (
    <div className="h-screen w-screen bg-ivory flex justify-center items-center">
      <p>You may close this window to return to Zena.</p>
    </div>
  );
};

export default OAuthLanding;

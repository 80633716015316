import { PageTitle } from 'components/atoms/PageTitle';
import { TransactionsPageLayout } from '../TransactionsLayout';
import TransactionsNeedingAttentionTable from 'components/widgets/transaction-tables/TransactionsNeedingAttentionTable/TransactionsNeedingAttentionTable';
import { OrganizeTableHeader } from './OrganizeTableHeader';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useGetIntuitAppConnection } from './data';
import { TransactionDetailsStateProvider } from 'components/widgets/TransactionDetailsDrawer/state';

export const OrganizeTransactionsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { isActive, loading } = useGetIntuitAppConnection({ companySlug: activeCompanySlug });

  if (loading) {
    return null;
  }
  return (
    <>
      <PageTitle hidden text="Organize Transactions" />
      <TransactionsPageLayout
        pageName="ORGANIZE_TRANSACTIONS"
        title="Organize transactions"
        className="mt-8"
      >
        <OrganizeTableHeader qboActive={isActive} />
        <TransactionDetailsStateProvider>
          <TransactionsNeedingAttentionTable
            companySlug={activeCompanySlug}
            onlyZenaTransaction={isActive}
          />
        </TransactionDetailsStateProvider>
      </TransactionsPageLayout>
    </>
  );
};

import { StayOrganizedWidget } from '../widgets/StayOrganizedWidget/StayOrganizedWidget';
import { isAdmin } from 'hooks/access';
import { useActiveCompany } from 'providers/ActiveCompany';

export const StayOrganizedMarketingWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);

  return (
    <div className="opacity-[0.5]">
      <StayOrganizedWidget
        stepsComplete={3}
        hasProject={false}
        hasZenaCard={false}
        hasLinkedAccounts={false}
        hasTransactions={false}
        hasInvitedTeammates={false}
        allDone={false}
        stepsLoading={false}
        transactionsLoading={false}
        approvalsLoading={false}
        transactionsCount={8}
        approvalsCount={3}
        admin={admin}
      />
    </div>
  );
};

import { useCategories, useSelectedProject } from './data';
import { HTMLAttributes, ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import useOnclickOutside from 'react-cool-onclickoutside';
import { cn } from 'utils';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useWindowKeyboardInteraction } from 'components/atoms/WindowInteractionListeners/keyboard';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ChevronRight } from 'lucide-react';

export const DISMISSED_TRANSACTION_CATEGORY_ID = 'NA';

export const AllocateTransactionCategoryDropdown = ({
  selectedProjectId,
  onSelectProject,
  onSelectNoCategory,
}: {
  selectedProjectId?: string | 'NA';
  onSelectProject: (projectId: string) => void;
  onSelectNoCategory: () => void;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [isOpen, setIsOpen] = useState(false);
  const { project: selectedProject, loading } = useSelectedProject({
    companySlug: activeCompanySlug,
    projectId:
      selectedProjectId && selectedProjectId !== DISMISSED_TRANSACTION_CATEGORY_ID
        ? selectedProjectId
        : '',
  });

  useWindowKeyboardInteraction({
    onKeyPress: (keyEvent) => {
      if (keyEvent.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    },
  });

  return (
    <div className="w-full h-10 overflow-visible relative">
      <button
        className="border border-primary-400 rounded-lg px-5 py-[6px] h-full flex bg-white items-center w-full"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <div className="h-full flex-auto flex items-center overflow-hidden mr-8">
          {selectedProjectId === DISMISSED_TRANSACTION_CATEGORY_ID && (
            <div className="w-full  h-full text-black bg-tertiary-800 rounded-3xl px-4 text-sm whitespace-nowrap text-left flex items-center">
              Not business related
            </div>
          )}

          {selectedProject && (
            <div className="w-full h-full text-white bg-lavender rounded-3xl px-4 text-sm whitespace-nowrap items-center truncate text-left leading-6">
              {selectedProject.name}
            </div>
          )}

          {loading && (
            <div className="w-full h-full flex items-center">
              <RectangleSkeleton />
            </div>
          )}
        </div>
        <motion.div
          className="flex-initial"
          animate={{
            rotate: isOpen ? 90 : 0,
            y: isOpen ? 5 : 0,
          }}
        >
          <ChevronRight />
        </motion.div>
      </button>
      {isOpen && (
        <AllocateTransactionsCategoryDropdownCategoriesContent
          companySlug={activeCompanySlug}
          onSelectProject={onSelectProject}
          onSelectNoCategory={onSelectNoCategory}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export const AllocateTransactionsCategoryDropdownCategoriesContent = ({
  className = '',
  companySlug,
  onSelectProject,
  onSelectNoCategory,
  onClose,
}: {
  className?: string;
  companySlug: string;
  onSelectProject: (projectId: string) => void;
  onSelectNoCategory: () => void;
  onClose: () => void;
}) => {
  const ref = useOnclickOutside((e) => {
    onClose();
  });
  const { projects, loading, error } = useCategories({ companySlug });
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const totalLength = projects.length + 1;

  useWindowKeyboardInteraction({
    onKeyDown: (e) => {
      if ((e.key === 'ArrowDown' || e.key === 'Tab') && activeIndex < totalLength - 1) {
        setActiveIndex(activeIndex + 1);
      }

      if (e.key === 'ArrowUp' && activeIndex > -1) {
        setActiveIndex(activeIndex - 1);
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        if (activeIndex === totalLength - 1) {
          onSelectNoCategory();
        } else {
          onSelectProject(projects[activeIndex].id);
        }
        onClose();
        setActiveIndex(-1);
      }

      if (e.key === 'Escape') {
        onClose();
      }
    },
  });

  return (
    <div
      className={cn(
        'absolute mt-1 w-full top-full left-0 rounded-xl shadow-[0px_4px_22px_0px_#00000026] pb-2 pt-4 flex flex-col gap-4 bg-white z-50',
        className
      )}
      ref={ref}
    >
      <CategoryContainer title="Project">
        {!loading && error && (
          <div className="px-6">
            <p className="text-danger text-center">
              Something went wrong. Please refresh and try again
            </p>
          </div>
        )}

        {loading && (
          <div className="grid grid-cols-[auto_1fr] gap-4 py-2 px-6 hover:!bg-tertiary-800 items-center justify-items-start">
            <Dot className="bg-primary-400" />
            <RectangleSkeleton rounded={false} />
          </div>
        )}

        {!loading && projects.length === 0 && (
          <div className="text-muted text-center">You have no projects yet</div>
        )}

        {projects.map((project, index) => (
          <ProjectRow
            key={project.id}
            isActive={index === activeIndex}
            onClick={(e) => {
              e.stopPropagation();
              onSelectProject(project.id);
              onClose();
            }}
          >
            {project.name}
          </ProjectRow>
        ))}
      </CategoryContainer>
      <CategoryContainer title="N/A">
        <NaRow
          isActive={activeIndex === totalLength - 1}
          onClick={(e) => {
            e.stopPropagation();
            onSelectNoCategory();
            onClose();
          }}
        >
          Not business related
        </NaRow>
      </CategoryContainer>
    </div>
  );
};

const CategoryContainer = ({ children, title }: { title: string; children: ReactNode }) => {
  return (
    <div className="grid">
      <div className="text-base font-semibold ml-6 mb-2">{title}</div>
      {children}
    </div>
  );
};

const Dot = ({ className, ...props }: { className: string } & HTMLAttributes<HTMLDivElement>) => {
  return <div {...props} className={cn('w-3 h-3 rounded-full', className)} />;
};

const RowContainer = ({
  children,
  isActive,
  ...props
}: { children: ReactNode; isActive?: boolean } & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="grid grid-cols-[auto_1fr] gap-4 py-2 px-6 hover:!bg-tertiary-800 items-center justify-items-start"
      style={{
        backgroundColor: isActive ? '#F7F8FC' : '#FFF',
      }}
      {...props}
    >
      {children}
    </button>
  );
};

const ProjectRow = ({
  children,
  ...props
}: { children: ReactNode; isActive?: boolean } & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <RowContainer {...props}>
      <Dot className="bg-lavender" />
      <div className="text-base text-black max-w-full text-left truncate">{children}</div>
    </RowContainer>
  );
};

const NaRow = ({
  children,
  ...props
}: { children: ReactNode; isActive?: boolean } & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <RowContainer {...props}>
      <Dot className="bg-primary-400" />
      <div className="text-base text-black whitespace-nowrap">{children}</div>
    </RowContainer>
  );
};

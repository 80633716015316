import { gql } from '__generated__/gql';

export const GetActiveCompanyQuery = gql(`
  query getActiveCompanyForActiveCompanyProvider {
    getMe {
      id
      defaultCompany {
        id
        name
        status
        tier
        slug
      }
    }
  }
`);

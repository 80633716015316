import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const GET_ADMIN_APPROVAL_REQUESTS = gql(`
  query GetAdminApprovalRequests($customerId: String!, $limit: Int, $type: String) {
    getAdminApprovalRequests(customerId: $customerId, limit: $limit, type: $type) {
      id
      requesterUserId
      requesterUserName
      title
      type
      details
      customerId
      createdAt
    }
  }
`);

export const useGetAdminApprovalRequests = ({
  customerId,
  type,
}: {
  customerId: string;
  type?: string;
}) => {
  const { data, loading, error } = useQuery(GET_ADMIN_APPROVAL_REQUESTS, {
    variables: {
      customerId,
      type,
    },
  });

  return {
    approvalRequests: data?.getAdminApprovalRequests ?? [],
    loading,
    error,
  };
};

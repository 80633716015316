import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { PropsWithChildren, useMemo } from 'react';
import { Switch, SwitchProps } from 'components/atoms/Switch';
import { Field as HeadlessField } from '@headlessui/react';
import { Label } from 'components/atoms/fieldset';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { cn } from '../../../../../../../utils';

interface OutlinedWidgetWithSwitchProps extends SwitchProps {
  title: string;
  loading?: boolean;
  isUpdating?: boolean;
  hasUpdated?: boolean;
}
export const OutlinedWidgetWithSwitch = ({
  title,
  loading = false,
  children,
  isUpdating,
  hasUpdated,
  ...props
}: PropsWithChildren<OutlinedWidgetWithSwitchProps>) => {
  const status: 'saving' | 'saved' | null = useMemo(() => {
    if (isUpdating && hasUpdated) {
      return 'saving';
    } else if (!isUpdating && hasUpdated) {
      return 'saved';
    }
    return null;
  }, [isUpdating, hasUpdated]);

  return (
    <OutlinedWidget>
      <div className="border-b pb-3 min-w-64">
        <HeadlessField className="flex items-center gap-4">
          {loading && <LoadingSpinner className="h-4 w-4 border-2" />}
          {!loading && <Switch {...props} />}
          <Label className="lg:text-xl cursor-pointer text-graphite">{title}</Label>
          {status && (
            <p
              className={cn(
                'ml-auto text-gray-400 opacity-80 transition ease-in-out',
                status === 'saved' && 'opacity-0 duration-500 delay-1000'
              )}
            >
              {status === 'saving' ? 'Saving...' : 'Saved'}
            </p>
          )}
        </HeadlessField>
      </div>
      <div className="mt-4">{children}</div>
    </OutlinedWidget>
  );
};

import { Card } from '__generated__/graphql';
import { Modal } from 'components/atoms/Modal';
import { ActivateForm } from './ActivateForm';

export interface ActivateCardModalProps {
  show: boolean;
  onClose: Function;
  cardId: string;
  customerId: string;
  cardData: Card;
}

const ActivateCardModal = ({
  show,
  onClose,
  cardData,
  cardId,
  customerId,
}: ActivateCardModalProps) => {
  return (
    <Modal show={show} onClose={() => onClose(false)}>
      <div className="font-['Helvetica'] leading-normal">
        <div className="font-bold text-[14px] text-gray-500">Card Activation</div>
        <h2 className="font-bold text-2xl mb-4">Activate your card</h2>
        <div className="text-base text-gray-500 mb-12">Provide your card details below</div>
        <ActivateForm cardId={cardId} onClose={() => onClose(true)} />
      </div>
    </Modal>
  );
};

export default ActivateCardModal;

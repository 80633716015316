import { ProfilePhotoImage } from '../ProfilePhotoImage';
import { Trash2 } from 'lucide-react';

interface ListItemProps {
  name: string;
  email: string;
  url?: string;
  onRemove?: () => void;
}

const ListItem = ({ name, email, url, onRemove }: ListItemProps) => (
  <div className="flex flex-wrap cursor-pointer" key={email}>
    {url !== undefined && (
      <div className="float-left w-1/6 flex justify-center items-center">
        <ProfilePhotoImage signedProfilePhotoUrl={url || ''} size={12} />
      </div>
    )}
    <div className="float-right w-5/6 p-2 flex items-center">
      <div className="h-full w-full">
        <h4 className="">{name}</h4>
        <h5 className="text-asphalt text-sm">{email}</h5>
      </div>
      {onRemove && (
        <a onClick={onRemove} className="float-right">
          <Trash2 className="inline-block w-4 h-4 mr-1" />
        </a>
      )}
    </div>
  </div>
);

export default ListItem;

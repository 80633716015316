import { TransactionDetailsHeader } from './TransactionDetailsHeader';
import { cn } from 'utils';
import { useIntuitAppConnectionStatus, useTransactionDetails } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { TransactionDetailsUploadReceipt } from './TransactionDetailsUploadReceipt';
import { TransactionDetailsMemo } from './TransactionDetailsMemo';
import { QuickbookSyncStatus } from 'components/widgets/TransactionDetailsDrawer/QuickbookSyncStatus';
import { AssignChildTransaction, AssignParentTransaction } from './TransactionDetailsAssign';
import { CardTransactionDetails } from './CardTransactionDetails';
import { useTransactionDetailsContext } from './state';

export interface TransactionDetailsProps {
  onClose: () => void;
  className?: string;
  showMissingRequirements?: boolean;
}

export const TransactionDetails = ({
  className = '',
  onClose,
  showMissingRequirements = false,
}: TransactionDetailsProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { state } = useTransactionDetailsContext();
  const { isIntuitAppConnectedAndActive } = useIntuitAppConnectionStatus({
    companySlug: activeCompanySlug,
  });
  const { transaction, loading } = useTransactionDetails({
    transactionId: state.transactionId,
    activeCompanySlug,
  });

  const isChildTransaction = !!transaction?.parentTransaction;

  if (loading) {
    return (
      <div className={cn('flex justify-center', className)}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={cn('grid gap-5', className)}>
      <TransactionDetailsHeader transactionId={state.transactionId} onClose={onClose} />

      {!isChildTransaction && (
        <AssignParentTransaction showMissingRequirements={showMissingRequirements} />
      )}
      {isChildTransaction && (
        <AssignChildTransaction showMissingRequirements={showMissingRequirements} />
      )}

      {!isChildTransaction && transaction?.id && (
        <TransactionDetailsUploadReceipt
          transactionId={state.transactionId}
          showMissingRequirements={showMissingRequirements}
        />
      )}

      {!isChildTransaction && (
        <TransactionDetailsMemo showMissingRequirements={showMissingRequirements} />
      )}
      {!isChildTransaction && isIntuitAppConnectedAndActive && (
        <QuickbookSyncStatus transactionId={state.transactionId} companySlug={activeCompanySlug} />
      )}

      {!isChildTransaction && <CardTransactionDetails transactionId={state.transactionId} />}
    </div>
  );
};

import { TransactionAssignment, ZenaTransactionType } from '__generated__/graphql';
import { DateRangeValue } from 'components/atoms/Inputs/DateRange/DateRangeCalendar';
import { PageLimit } from 'components/atoms/Pagination';
import { FiltersState } from '../TransactionFilters';
import { Dispatch, useEffect, useReducer } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Maybe } from 'utils/types';
import deepEquals from 'fast-deep-equal';

export interface TransactionsTableState {
  searchTerm: string;
  dropdownFilters: FiltersState;
  pagination: Pagination;
  detailedViewTransactionId: Maybe<string>;
  confirmLostTransactionDetailsTransactionId: string | null;
  showConfirmLostTransactionDetails: boolean;
}

interface Pagination {
  pageLimit: PageLimit;
  pageNumber: number;
  totalPages: number;
}

export const defaultTransactionsTableState: TransactionsTableState = {
  searchTerm: '',
  dropdownFilters: {
    assignmentFilters: [TransactionAssignment.project, TransactionAssignment.business],
    transactionTypeFilters: [
      ZenaTransactionType.bankPayment,
      ZenaTransactionType.creditCardPayment,
      ZenaTransactionType.otherIncome,
      ZenaTransactionType.paymentReceived,
      ZenaTransactionType.return,
      ZenaTransactionType.spend,
      ZenaTransactionType.transferIn,
      ZenaTransactionType.transferOut,
      ZenaTransactionType.withdraw,
    ],
    dateRange: [null, null],
  },
  confirmLostTransactionDetailsTransactionId: null,
  showConfirmLostTransactionDetails: false,
  pagination: {
    pageLimit: 30,
    pageNumber: 0,
    totalPages: 0,
  },
  detailedViewTransactionId: undefined,
};

// ACTIONS
interface UpdateSearchTerm {
  type: 'UPDATE_SEARCH_TERM';
  payload: string;
}

interface UpdateDropdownFilters {
  type: 'UPDATE_DROPDOWN_FILTERS';
  payload: FiltersState;
}

interface UpdatePagination {
  type: 'UPDATE_PAGINATION';
  payload: Pagination;
}

interface UpdateDetailedViewTransactionId {
  type: 'UPDATE_DETAILED_VIEW_TRANSACTION_ID';
  payload: Maybe<string>;
}

interface UpdateSearchFromUrl {
  type: 'UPDATED_URL_SEARCH_PARAMETERS';
  payload: SerializedState;
}

interface ShowConfirmLoseTransactionDetailsChanges {
  type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES';
  payload: string | null;
}

interface CancelConfirmLoseTransactionDetailsChanges {
  type: 'CANCEL_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES';
}

interface ConfirmLoseTransactionDetailsChanges {
  type: 'CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES';
}

type TransactionTableAction =
  | UpdateSearchTerm
  | UpdateDropdownFilters
  | UpdatePagination
  | UpdateDetailedViewTransactionId
  | UpdateSearchFromUrl
  | ShowConfirmLoseTransactionDetailsChanges
  | CancelConfirmLoseTransactionDetailsChanges
  | ConfirmLoseTransactionDetailsChanges;

const TransactionTableReducer = (
  prevState: TransactionsTableState,
  action: TransactionTableAction
): TransactionsTableState => {
  switch (action.type) {
    case 'UPDATE_DROPDOWN_FILTERS':
      return {
        ...prevState,
        dropdownFilters: action.payload,
        pagination: { ...defaultTransactionsTableState.pagination },
      };
    case 'UPDATE_SEARCH_TERM':
      return {
        ...prevState,
        searchTerm: action.payload,
        pagination: { ...defaultTransactionsTableState.pagination },
      };
    case 'UPDATE_PAGINATION':
      return { ...prevState, pagination: action.payload };
    case 'UPDATE_DETAILED_VIEW_TRANSACTION_ID':
      return { ...prevState, detailedViewTransactionId: action.payload };
    case 'UPDATED_URL_SEARCH_PARAMETERS':
      return {
        ...prevState,
        dropdownFilters: {
          ...prevState.dropdownFilters,
          dateRange:
            action.payload.dateRange || defaultTransactionsTableState.dropdownFilters.dateRange,
          assignmentFilters:
            action.payload.assignmentFilters ||
            defaultTransactionsTableState.dropdownFilters.assignmentFilters,
          transactionTypeFilters:
            action.payload.transactionTypeFilters ||
            defaultTransactionsTableState.dropdownFilters.transactionTypeFilters,
        },
        pagination: {
          ...prevState.pagination,
          pageLimit: action.payload.pageLimit || defaultTransactionsTableState.pagination.pageLimit,
          pageNumber:
            action.payload.pageNumber || defaultTransactionsTableState.pagination.pageNumber,
        },
        detailedViewTransactionId: action.payload.detailedViewTransactionId,
      };
    case 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES':
      return {
        ...prevState,
        confirmLostTransactionDetailsTransactionId: action.payload,
        showConfirmLostTransactionDetails: true,
      };
    case 'CANCEL_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES':
      return {
        ...prevState,
        confirmLostTransactionDetailsTransactionId: null,
        showConfirmLostTransactionDetails: false,
      };
    case 'CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES':
      return {
        ...prevState,
        confirmLostTransactionDetailsTransactionId: null,
        showConfirmLostTransactionDetails: false,
        detailedViewTransactionId:
          prevState.confirmLostTransactionDetailsTransactionId &&
          prevState.confirmLostTransactionDetailsTransactionId !==
            prevState.detailedViewTransactionId
            ? prevState.confirmLostTransactionDetailsTransactionId
            : '',
      };
    default:
      return { ...prevState };
  }
};

export const useTransactionTableReducer = (defaults?: TransactionsTableState) => {
  return useReducer(TransactionTableReducer, {
    ...defaultTransactionsTableState,
    ...defaults,
  });
};

// STATE URL_QUERY PERSISTANCE
interface SerializedState {
  searchTerm: string;
  assignmentFilters: TransactionAssignment[];
  transactionTypeFilters: ZenaTransactionType[];
  dateRange: DateRangeValue | null;
  detailedViewTransactionId: Maybe<string>;
  pageLimit: PageLimit;
  pageNumber: number;

  currentParams?: URLSearchParams;
}

const serializeUrlQueryFromState = (state: SerializedState) => {
  const searchParams = new URLSearchParams();

  // add original search params
  const currentParams = state.currentParams;
  if (currentParams) {
    currentParams.forEach((value, key) => {
      searchParams.set(key, value);
    });
  }
  searchParams.set('searchTerm', state.searchTerm);
  searchParams.set('assignmentFilters', JSON.stringify(state.assignmentFilters));
  searchParams.set('transactionTypeFilters', JSON.stringify(state.transactionTypeFilters));
  searchParams.set('dateRange', JSON.stringify(state.dateRange));
  searchParams.set('detailedViewTransactionId', state.detailedViewTransactionId ?? '');
  searchParams.set('pageLimit', String(state.pageLimit));
  searchParams.set('pageNumber', String(state.pageNumber));

  return searchParams;
};

const deserializeUrlToSearchState = (searchParams: URLSearchParams): SerializedState => {
  const detailedViewTransactionId = searchParams.get('detailedViewTransactionId');
  let dateRangeUrl = searchParams.get('dateRange');
  const dateRange = dateRangeUrl
    ? JSON.parse(dateRangeUrl)
    : defaultTransactionsTableState.dropdownFilters.dateRange;
  let transactionTypeFiltersUrl = searchParams.get('transactionTypeFilters');
  const transactionTypeFilters = transactionTypeFiltersUrl
    ? JSON.parse(transactionTypeFiltersUrl)
    : defaultTransactionsTableState.dropdownFilters.transactionTypeFilters;
  let assignmentFiltersUrl = searchParams.get('assignmentFilters');
  const assignmentFilters = assignmentFiltersUrl
    ? JSON.parse(assignmentFiltersUrl)
    : defaultTransactionsTableState.dropdownFilters.assignmentFilters;

  return {
    searchTerm: searchParams.get('searchTerm') ?? '',
    detailedViewTransactionId:
      detailedViewTransactionId === 'undefined' || !detailedViewTransactionId
        ? undefined
        : detailedViewTransactionId,
    pageLimit: (Number(searchParams.get('pageLimit')) as PageLimit) ?? 30,
    pageNumber: Number(searchParams.get('pageNumber')) ?? 0,
    dateRange,
    transactionTypeFilters,
    assignmentFilters,
  };
};

export const useTransactionsTableUrlQueryPersistence = ({
  state,
  dispatch,
  disabled = false,
}: {
  state: TransactionsTableState;
  dispatch: Dispatch<TransactionTableAction>;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const maybeUpdateSearchParams = useDebouncedCallback((oldSearchParams, newSearchParams) => {
    if (!disabled && !deepEquals(oldSearchParams, newSearchParams)) {
      navigate(`${pathname}?${newSearchParams}`);
    }
  }, 500);

  // on state change
  useEffect(() => {
    const updatedSearchParams = serializeUrlQueryFromState({
      detailedViewTransactionId:
        state.detailedViewTransactionId === undefined ? '' : state.detailedViewTransactionId,
      searchTerm: state.searchTerm,
      assignmentFilters: state.dropdownFilters.assignmentFilters,
      transactionTypeFilters: state.dropdownFilters.transactionTypeFilters,
      dateRange: state.dropdownFilters.dateRange,
      pageLimit: state.pagination.pageLimit,
      pageNumber: state.pagination.pageNumber,
      currentParams: searchParams,
    });
    // update query params
    maybeUpdateSearchParams(searchParams, updatedSearchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // on url params change
  useEffect(() => {
    const updateSearchState = deserializeUrlToSearchState(searchParams);
    if (!deepEquals(state, updateSearchState)) {
      dispatch({
        type: 'UPDATED_URL_SEARCH_PARAMETERS',
        payload: updateSearchState,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return null;
};

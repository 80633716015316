import { ApolloError } from '@apollo/client';
import { SpendCategory, TransactionFeedTransactionType } from '__generated__/graphql';
import { type ClassValue, clsx } from 'clsx';
import moment from 'moment';
import { useCallback, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const centsToDollarDisplay = (amountCents: number) => {
  let dollarAmount = toDollars(amountCents).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let dollars = dollarAmount.split('.')[0];
  let centsRemainder = dollarAmount.split('.')[1] ?? '00';
  return (
    <p className="text-3xl">
      <span>$</span>
      {dollars}
      <span className={'text-xs'}>{`.${centsRemainder}`}</span>
    </p>
  );
};

export const toDollarDisplay = (amountDollars: number) => {
  const dollars = Math.floor(Math.abs(amountDollars));
  const centsRemainder = Math.round((Math.abs(amountDollars) - dollars) * 100);
  const centsRemainderString = centsRemainder < 10 ? `0${centsRemainder}` : `${centsRemainder}`;
  const displayedDollars = dollars * Math.sign(amountDollars);

  return (
    <p className="text-3xl">
      <span>$</span>
      {displayedDollars.toLocaleString('en-US')}
      <span className={'text-xs'}>.{centsRemainderString}</span>
    </p>
  );
};

export const toCents = (dollarValue: number): number => {
  let dollarValueString = (dollarValue + '').replace(/[^\d.-]/g, '');
  if (dollarValueString && dollarValueString.includes('.')) {
    dollarValueString = dollarValueString.substring(0, dollarValueString.indexOf('.') + 3);
  }

  return dollarValueString ? Math.round(parseFloat(dollarValueString) * 100) : 0;
};

export const toDollars = (centsValue: number): number => {
  const centsValueString = (centsValue + '').replace(/[^\d.-]/g, '');
  const centsValueFloat = parseFloat(centsValueString);
  return centsValueFloat ? centsValueFloat / 100 : 0;
};

export const formatCentsToDollarsString = (centsValue: number, hideCents?: boolean): string => {
  const dollars = toDollars(centsValue);
  return formatNumberAsDollars(dollars, hideCents);
};

export const formatCentsToAbsDollarsString = (centsValue: number, hideCents?: boolean): string => {
  const dollars = toDollars(centsValue);
  if (dollars < 0) {
    return formatNumberAsDollars(Math.abs(dollars), hideCents);
  }
  return formatNumberAsDollars(dollars, hideCents);
};

export const formatCentsToDollarStringWithDecimal = (amountCents: number) => {
  let dollarAmount = toDollars(amountCents).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  let dollars = dollarAmount.split('.')[0];
  let centsRemainder = dollarAmount.split('.')[1] ?? '00';
  return `$${dollars}.${centsRemainder}`;
};

export const formatNumberAsDollars = (numberDollars: number, hideCents?: boolean): string => {
  let formatterProps = {
    style: 'currency',
    currency: 'USD',
  };

  formatterProps = {
    ...formatterProps,
    ...{ maximumFractionDigits: hideCents ? 0 : 2 },
  };
  const currencyFormatter = new Intl.NumberFormat('en-US', formatterProps);
  return currencyFormatter.format(numberDollars);
};

export const removeNullsFromArray = <T,>(collection: (T | null | undefined)[]): T[] => {
  return collection.filter((item: T | null | undefined): item is T => nonNullable(item));
};

export const sortCollectionAlphabeticallyAndRemoveNulls = <
  T extends Record<K, string>,
  K extends keyof T,
>(
  collection: (T | null | undefined)[],
  key: K
): T[] => {
  let nonNullUnsortedCollection = removeNullsFromArray(collection);
  return nonNullUnsortedCollection.sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
};

export const sortCollectionAlphabetically = <T extends Record<K, string>, K extends keyof T>(
  collection: T[],
  key: K
): T[] => {
  let sorted = [...collection];
  return sorted.sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
};

export const relativeDateText = (date: moment.Moment, includeTime?: boolean): string => {
  includeTime ??= true;

  const beforeYesterday = moment().subtract(2, 'day').endOf('day');
  const yesterday = moment().subtract(1, 'day').endOf('day');
  const today = moment().endOf('day');
  const tomorrow = moment().add(1, 'day').endOf('day');

  const datetimeFormat = 'MMM D YYYY,[ at ]h:mm A';
  const dateFormat = 'MMM D YYYY';
  const defaultFormat = includeTime ? datetimeFormat : dateFormat;

  if (date < beforeYesterday) {
    let formattedDate = date.format(defaultFormat);
    if (date.year() === moment().year()) {
      if (includeTime) {
        formattedDate = date.format('MMM D,[ at ]h:mm A');
      } else {
        formattedDate = date.format('MMM D');
      }
    }
    return formattedDate;
  }
  if (date < yesterday) {
    if (includeTime) {
      return date.format('[Yesterday at ]h:mm A');
    } else {
      return date.format('[Yesterday]');
    }
  }
  if (date < today) {
    if (includeTime) {
      return date.format('[Today at ]h:mm A');
    } else {
      return date.format('[Today]');
    }
  }
  if (date < tomorrow) {
    if (includeTime) {
      return date.format('[Tomorrow at ]h:mm A');
    } else {
      return date.format('[Tomorrow]');
    }
  }

  let formattedDate = date.format(defaultFormat);
  if (date.year() === moment().year()) {
    if (includeTime) {
      formattedDate = date.format('MMM D,[ at ]h:mm A');
    } else {
      formattedDate = date.format('MMM D');
    }
  }
  return formattedDate;
};

export function nonNullable<T>(value: T | null | undefined): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function nonBlankable<T>(value: T | null | undefined): value is NonNullable<T> {
  return value !== null && value !== undefined && value !== '';
}

export const formatPhoneNumber = (input: string) => {
  //  if the input is null, return a null value
  if (!input) return input;
  // remove all characters from the input except number input.
  const numberInput = input.replace(/[^\d]/g, '');
  //  take the length of the value of the input
  const numberInputLength = numberInput.length;
  // if the number length is 1, 2, or 3, then return it as it is.
  if (numberInputLength < 4) {
    return numberInput;
  } else if (numberInputLength < 7) {
    // if the number input length is 4, 5, or 6, format it accordingly.
    return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3)}`;
  } else {
    //  if the number input length is 7, 8, 9, 10, or more, format it like the below.
    return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3, 6)}-${numberInput.slice(6, 10)}`;
  }
};

export const deformatPhoneNumber = (num: string) => {
  return num.replace(/[^0-9]/g, '');
};

export const onApolloError = (
  error: ApolloError,
  setErrorMsg: (errorMsg: string, errorType: any) => void,
  allowListErrorTypes: Array<string>,
  blockListErrorTypes?: Array<string>
) => {
  if (process.env.REACT_APP_REPORT_ERRORS === 'true') {
    console.error(error);
  }
  let errorType;

  if (error.graphQLErrors.length) {
    const expectedError = error.graphQLErrors.find((e: any) => {
      errorType = e.errorType;
      return allowListErrorTypes.includes(e.errorType);
    });

    if (expectedError) {
      setErrorMsg(expectedError.message, errorType);
    } else {
      const blockedError = error.graphQLErrors.find((e: any) => {
        errorType = e.errorType;
        return blockListErrorTypes?.includes(e.errorType);
      });
      if (blockedError) {
        return;
      }
      if (error.graphQLErrors.find((e: any) => e.errorType === 'Unauthorized')) {
        setErrorMsg('You are not authorized to perform this action.', 'Unauthorized');
      } else {
        setErrorMsg('An unexpected error occurred. Please try again.', errorType);
      }
    }
  }
};

export const isUsingMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const useCopyContents = () => {
  const contentsRef = useRef<HTMLSpanElement>(null);
  const copyContents = useCallback(() => {
    const contents = contentsRef.current;
    if (!contents) {
      return;
    }

    const selection = window.getSelection();
    if (selection) {
      selection.selectAllChildren(contents);
      document.execCommand('copy');
      selection.removeAllRanges();
    }
  }, []);
  return {
    copyContents,
    contentsRef,
  };
};

export type RewardTierName = 'Gold' | 'Platinum' | 'Diamond' | 'Elite' | 'Green';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/** The first letter in each word in the str is uppercased, joining with the rest of the word as is */
export function toFirstLetterUpcase(str: string) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function isCredit({
  transactionZenaType,
  transactionSpendCategory,
}: {
  transactionZenaType?: TransactionFeedTransactionType;
  transactionSpendCategory?: SpendCategory;
}) {
  if (transactionZenaType === TransactionFeedTransactionType.return) {
    return true;
  }

  if (transactionSpendCategory === SpendCategory.income) {
    return true;
  }

  return false;
}

import { checkCustomerTokenExpiration, getCustomerTokenFromLocalStorage } from 'hooks/access';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCompany } from 'hooks/useCompany';
import { useActiveCompany } from './ActiveCompany';

interface RequireTwoFactorAuthProps {
  children: JSX.Element;
}

export function RequireTwoFactorAuth({ children }: RequireTwoFactorAuthProps): JSX.Element | null {
  const location = useLocation();
  const navigate = useNavigate();
  const twoFactorVerificationUrl = '/verification';
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { loading, company } = useCompany({
    companySlug: activeCompanySlug,
  });

  useEffect(() => {
    checkCustomerTokenExpiration();
    const localCustomerToken = getCustomerTokenFromLocalStorage();
    if (!localCustomerToken || localCustomerToken === '') {
      if (company?.tier === 'luxe') {
        navigate(twoFactorVerificationUrl, { state: { from: location }, replace: true });
      }
    }
  }, [company]);

  if (loading) {
    return <></>;
  }

  return children;
}

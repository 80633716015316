import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__';
import { ProfilePhotoImage } from 'components/atoms/ProfilePhotoImage';
import { useEffect } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Trash2 } from 'lucide-react';

export const MUTATION_DELETE_USER_INVITATION = gql(`
  mutation DeleteUserInvitation($customerId: String!, $inviteeEmail: String!) {
    deleteUserInvitation(customerId: $customerId, inviteeEmail: $inviteeEmail) {
      message
    }
  }
`);

type PendingInviteCardProps = {
  customerId: string;
  email: string;
};

const PendingInviteCard = ({ customerId, email }: PendingInviteCardProps) => {
  const [setSuccessMsg, setErrorMsg] = useAppState((state) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const [deleteUserInvite, { data: deleteData, loading: deleteLoading }] = useMutation(
    MUTATION_DELETE_USER_INVITATION,
    {
      variables: {
        customerId,
        inviteeEmail: email,
      },
      onError: (error: ApolloError) => onApolloError(error, setErrorMsg, []),
      refetchQueries: ['GetPendingInvitations'],
    }
  );

  const handleDeleteClick = () => {
    deleteUserInvite();
  };

  useEffect(() => {
    if (deleteData && deleteData.deleteUserInvitation?.message) {
      setSuccessMsg(deleteData.deleteUserInvitation.message);
    }
  }, [deleteData]);

  return (
    <div>
      <div className="flex justify-between mt-4">
        <div className="flex">
          <div className="hidden sm:block">
            <ProfilePhotoImage signedProfilePhotoUrl={null} size={10} />
          </div>

          <div className="px-2">
            <p className="font-medium">{email}</p>
            <p className="text-sm text-gray-500">{email}</p>
          </div>
        </div>
        <div className="flex items-center">
          <p className="px-2">Pending invitation</p>

          <div className="w-8">
            {deleteLoading ? (
              <LoadingSpinner />
            ) : (
              <button onClick={handleDeleteClick} className="pt-2 pb-2">
                <span className="float-left px-2">
                  <Trash2 />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingInviteCard;

import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';

const GET_INTUIT_APP_CONNECTION_QUERY = gql(`
  query GetIntuitAppConnectionForConfigurationForIntegrations($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
      connectionInvalidatedAt
      disconnectedAt
    }
  }
`);

export const useGetIntuitAppConnection = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_QUERY, {
    variables: { companySlug: companySlug },
  });

  return {
    appConnection: data?.getIntuitAppConnection ?? null,
    loading,
    error,
  };
};

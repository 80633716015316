import { AddressOutput, ClientInfoOutput } from '__generated__/graphql';
import { Mail, Phone } from 'lucide-react';

const DisplayedClientDetails = ({
  clientInfo,
  address,
}: {
  clientInfo?: ClientInfoOutput;
  address?: AddressOutput;
}) => {
  const firstName = clientInfo?.firstName;
  const lastName = clientInfo?.lastName;

  let nameString = 'First name / last name';

  if (firstName || lastName) {
    nameString = `${firstName} ${lastName}`;
  }

  const defaultEmail = 'your.email@your.domain.com';
  const emailString = clientInfo?.email || defaultEmail;

  const defaultPhone = '(000) 000-0000';
  const phoneString = clientInfo?.phone || defaultPhone;

  const { street, street2, city, state, postalCode } = address || {};

  const projectAddressString1 = street
    ? `${street + (street2 ? ' ' + street2 : '')}`
    : "Input the client's address for easy reference.";
  const projectAddressString2 = street ? `${city}, ${state}` : '';
  const projectAddressString3 = street ? `${postalCode}` : '';

  return (
    <div className="flex flex-wrap">
      <div className="min-w-fit sm:pr-6">
        <label className="font-bold text-base">Client name</label>
        <div>{nameString}</div>
      </div>
      <div className="flex-1 min-w-fit sm:p-6">
        <div className="flex gap-2">
          <Mail />
          {emailString === defaultEmail ? (
            <span>{emailString}</span>
          ) : (
            <a href={`mailto:${emailString}`}>{emailString}</a>
          )}
        </div>
        <div className="flex gap-2">
          <Phone />
          {phoneString === defaultPhone ? (
            <span>{phoneString}</span>
          ) : (
            <a href={`tel:${phoneString}`}>{phoneString}</a>
          )}
        </div>
      </div>
      <div className="flex-1 sm:border-l sm:border-feather sm:pl-6">
        <label className="font-bold text-base">Project address</label>
        <div>
          {projectAddressString1}
          <br />
          {projectAddressString2}
          <br />
          {projectAddressString3}
        </div>
      </div>
    </div>
  );
};

export default DisplayedClientDetails;

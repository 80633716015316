import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRedirectIfLoggedIn } from 'hooks/useRedirectIfLoggedIn';
import { LoginLayout } from './LoginLayout';
import useCustomAuth from 'hooks/useCustomAuth';
import { PageTitle } from 'components/atoms/PageTitle';
import { GoogleSignInButton } from 'components/widgets/GoogleSignInButton';
import { LoginForm } from './LoginForm';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { Alert } from 'components/atoms/Alert';
import { getCompanySlugsFromLocalStorage } from 'hooks/access';

export const LoginPage = () => {
  useRedirectIfLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();

  const prevPathname = location.state?.from?.pathname || null;
  const prevSearch = location.state?.from?.search || null;
  const successMessageFromState = location.state?.successMessage || null;
  const successMessageTitle = location.state?.successMessageTitle || 'Confirmed';
  const from = prevSearch ? `${prevPathname}${prevSearch}` : prevPathname || '/';

  const { signIn, resendConfirmationCode, user, isLoading } = useCustomAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(successMessageFromState || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchParams] = useSearchParams();

  // Previously we used the customer id for routing, so this will be removed in the future.
  // Until recently there have been emails that still contained links with the customer id.
  useEffect(() => {
    if (!isLoading && user) {
      const customerIds = getCompanySlugsFromLocalStorage();
      const customerIdFromRoute = from.split('/').filter((s: string) => s !== '')[0];
      const redirectUrl = customerIds?.includes(customerIdFromRoute) ? from : '/';
      navigate(redirectUrl, { replace: true });
    }
  }, [user, navigate, from, isLoading]);

  const onSubmit = async ({ email, password }: { email: string; password: string }) => {
    setIsSubmitting(true);

    try {
      const response = await signIn(email, password, { sub: searchParams.get('sub') ?? undefined });

      if (response.success) {
        setErrorMessage('');
        navigate(from ?? '/');
      } else {
        if (response.errorCode === 'UserNotConfirmedException') {
          resendConfirmationCode(email);
          navigate('/signup/confirmation', {
            state: {
              signupEmail: email,
              redirectUrl: '/login',
            },
          });
        } else if (response.errorCode === 'InvalidParameterException') {
          if (!password) {
            setErrorMessage('Please enter a valid password');
          }
        } else if (response.errorCode === 'UserNotFoundException') {
          setErrorMessage(
            'Email and password combination does not match a valid user. Please try again.'
          );
        } else if (response.errorMessage === 'Username cannot be empty') {
          setErrorMessage('Please enter a valid email');
        } else {
          setErrorMessage(response.errorMessage ?? 'Error during sign in, please contact support');
        }
      }
    } catch (error) {
      setErrorMessage('Unknown error, please contact support');
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <PageTitle text="Log in" hidden />
      <LoginLayout className="grid gap-4 lg:gap-8">
        <h1 className="text-center text-3xl lg:text-5xl font-normal">Welcome to Zena!</h1>
        <GoogleSignInButton text="Log in with Google" />
        <div className="relative flex items-center">
          <div className="flex-grow border-t border-muted"></div>
          <span className="flex-shrink mx-4 text-muted">Or, log in with your email</span>
          <div className="flex-grow border-t border-muted"></div>
        </div>
        <LoginForm onSubmit={onSubmit} isLoadingSubmission={isSubmitting} />
      </LoginLayout>

      {errorMessage && (
        <ToastContainer>
          <Alert
            variation="error"
            onDismiss={() => setErrorMessage('')}
            title="Something went wrong"
            description={errorMessage}
          />
        </ToastContainer>
      )}

      {successMessage && (
        <ToastContainer>
          <Alert
            variation="success"
            onDismiss={() => setSuccessMessage('')}
            title={successMessageTitle}
            description={successMessage}
          />
        </ToastContainer>
      )}
    </>
  );
};

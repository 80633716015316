import { TextField } from '@aws-amplify/ui-react';
import { RoundButton } from 'components/atoms/Buttons';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';

interface DateFilters {
  lastMonth?: boolean;
  lastQuarter?: boolean;
  lastYear?: boolean;
  fromDate?: string;
  toDate?: string;
}

interface DateFilterProps {
  addTopBar?: boolean;
  filters?: DateFilters;
  setFilters?: Dispatch<SetStateAction<DateFilters>>;
}

const DateFilter = ({ addTopBar = false, filters, setFilters }: DateFilterProps) => {
  const [fromDateError, setFromDateError] = useState<string>('');
  const [toDateError, setToDateError] = useState<string>('');

  return (
    <div className={addTopBar ? 'mt-8 border-t-2 border-gray-100' : ''}>
      <h3 className="pb-6 pt-8 text-xl">Date</h3>
      <div className="flex space-x-4">
        <RoundButton
          text="Last month"
          className="!px-4 !py-2 !font-normal"
          variant={filters?.lastMonth ? 'primary' : 'secondary'}
          active={!!filters?.lastMonth}
          onClick={() => {
            const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
            const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
            setFilters &&
              setFilters((prev) => ({
                ...prev,
                lastMonth: true,
                lastQuarter: false,
                lastYear: false,
                fromDate: startOfLastMonth.format('YYYY-MM-DD'),
                toDate: endOfLastMonth.format('YYYY-MM-DD'),
              }));
          }}
        />
        <RoundButton
          text="Last quarter"
          className="!px-4 !py-2 !font-normal"
          variant={filters?.lastQuarter ? 'primary' : 'secondary'}
          active={!!filters?.lastQuarter}
          onClick={() => {
            const lastQuarterStart = moment().subtract(1, 'quarter').startOf('quarter');
            const lastQuarterEnd = moment().subtract(1, 'quarter').endOf('quarter');
            setFilters &&
              setFilters((prev) => ({
                ...prev,
                lastMonth: false,
                lastQuarter: true,
                lastYear: false,
                fromDate: lastQuarterStart.format('YYYY-MM-DD'),
                toDate: lastQuarterEnd.format('YYYY-MM-DD'),
              }));
          }}
        />
        <RoundButton
          text="Last year"
          className="!px-4 !py-2 !font-normal"
          variant={filters?.lastYear ? 'primary' : 'secondary'}
          active={!!filters?.lastYear}
          onClick={() => {
            const lastYearStart = moment().subtract(1, 'year').startOf('year');
            const lastYearEnd = moment().subtract(1, 'year').endOf('year');
            setFilters &&
              setFilters((prev) => ({
                ...prev,
                lastMonth: false,
                lastQuarter: false,
                lastYear: true,
                fromDate: lastYearStart.format('YYYY-MM-DD'),
                toDate: lastYearEnd.format('YYYY-MM-DD'),
              }));
          }}
        />
      </div>
      <div className="mt-4 flex">
        <TextField
          placeholder="From date"
          label="From"
          labelHidden
          type="date"
          className="flex-1"
          errorMessage={fromDateError}
          hasError={!!fromDateError}
          onChange={(e) => {
            const newFromDate = e.target.value;
            if (newFromDate) {
              const newToDateMoment = moment(filters?.toDate);
              const newFromDateMoment = moment(newFromDate);
              if (newToDateMoment.isBefore(newFromDateMoment)) {
                setFromDateError('date cannot be after end date');
                return;
              } else {
                setFromDateError('');
              }
            }
            setFilters &&
              setFilters((prev) => ({
                ...prev,
                lastMonth: false,
                lastQuarter: false,
                lastYear: false,
                fromDate: newFromDate,
              }));
          }}
          value={filters?.fromDate || ''}
        />
        <span className="mx-3 mt-2">-</span>
        <TextField
          placeholder="To date"
          label="To"
          labelHidden
          type="date"
          className="flex-1"
          errorMessage={toDateError}
          hasError={!!toDateError}
          onChange={(e) => {
            const newToDate = e.target.value;
            if (newToDate) {
              const newToDateMoment = moment(newToDate);
              const newFromDateMoment = moment(filters?.fromDate);
              if (newToDateMoment.isBefore(newFromDateMoment)) {
                setToDateError('date cannot be before start date');
                return;
              } else {
                setToDateError('');
              }
            }
            setFilters &&
              setFilters((prev) => ({
                ...prev,
                lastMonth: false,
                lastQuarter: false,
                lastYear: false,
                toDate: newToDate,
              }));
          }}
          value={moment(filters?.toDate).format('YYYY-MM-DD') || ''}
        />
      </div>
    </div>
  );
};

export default DateFilter;

import { Breadcrumbs } from 'components/atoms/Breadcrumbs';
import { HowQboIntegrationWorksSection } from './HowQboIntegrationWorksSection';
import { QboConfigurationSection } from './QboConfigurationSection';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ActivationStepsWidget } from './ActivationStepsWidget';
import { HTMLAttributes, useMemo } from 'react';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { cn } from '../../../../../utils';
import {
  ChangeQBOLinkedAccount,
  DeactivateQuickbooksAccountButton,
  QBOPairedAccount,
  useGetAuthorizeUrl,
  useGetIntuitAppConnection,
} from 'components/widgets/Quickbooks';
import moment from 'moment';
import { StyledTooltip } from './QboConfigurationSection/helperComponents';
import OAuthPopup from 'components/atoms/OAuthPopup';
import { useAppState } from '../../../../../stores/UserStore';
import RoundButton from '../../../../../components/atoms/Buttons/RoundButton/RoundButton';
import { useReconnectIntuitAppConnection } from 'components/widgets/Quickbooks/data';
import { Info, Link2Off } from 'lucide-react';

export const QboIntegration = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const breadcrumbs = [
    { name: 'Integrations', url: '/settings/integrations' },
    { name: 'Quickbooks Online', url: 'settings/integrations/qbo' },
  ];

  const { appConnection } = useGetIntuitAppConnection({ companySlug: activeCompanySlug });
  const isConnected = !!(
    appConnection?.id &&
    appConnection.qboAccountId &&
    appConnection.startedAt
  );
  const isDisconnected = !!(
    appConnection &&
    !appConnection.disconnectedAt &&
    appConnection?.connectionInvalidatedAt
  );
  return (
    <div>
      {isDisconnected && <ReconnectBanner companySlug={activeCompanySlug} />}
      <div className="mb-4">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className="space-y-6">
        {isConnected && <QboConfigurationSection />}
        <div className="grid grid-cols-3 gap-6">
          {isConnected ? (
            <ActivatedIntegrationConsole className="col-span-2" />
          ) : (
            <ActivationStepsWidget className="col-span-2" />
          )}
          <HowQboIntegrationWorksSection />
        </div>
      </div>
    </div>
  );
};

const ReconnectBanner = ({ companySlug }: { companySlug: string }) => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { authorizeUrl } = useGetAuthorizeUrl({ companySlug });

  const { createIntuitAppConnection } = useReconnectIntuitAppConnection();

  const onCode = async (response: string): Promise<void> => {
    const { code, realmId, csrfToken } = JSON.parse(response);
    try {
      createIntuitAppConnection({
        variables: { companySlug, code, csrfToken, realmId },
        refetchQueries: ['GetIntuitAppConnection'],
        onError: (error) => setErrorMsg(error.message),
      });
    } catch (e) {
      console.error(e);
    } finally {
      window.localStorage.removeItem('qboOAuthResponse');
    }
  };

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-danger-600 px-6 py-8 sm:px-3.5 rounded-lg justify-center mb-12">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <div>
          <Link2Off />
        </div>
        <div>
          <p className="text-lg leading-6 text-danger font-semibold">QuickBooks disconnected</p>
        </div>
        <div>
          <p className="leading-6 text-danger">
            Please reconnect to keep sending transactions to QuickBooks.
          </p>
        </div>
      </div>
      <div>
        <OAuthPopup
          url={authorizeUrl}
          onCode={onCode}
          title="Connect to QuickBooks® Online"
          width={700}
          height={700}
        >
          <RoundButton className="py-2 text-base">
            Reconnect <span aria-hidden="true">&rarr;</span>
          </RoundButton>
        </OAuthPopup>
      </div>
    </div>
  );
};

interface ActivatedIntegrationConsoleProps extends HTMLAttributes<HTMLDivElement> {}

const ActivatedIntegrationConsole = (props: ActivatedIntegrationConsoleProps) => {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { appConnection } = useGetIntuitAppConnection({ companySlug });
  const isActive = !!appConnection?.startedAt;
  const status = useMemo(() => {
    if (appConnection && !appConnection.disconnectedAt && appConnection?.connectionInvalidatedAt) {
      return 'invalid';
    } else if (isActive) {
      return 'active';
    } else {
      return 'inactive';
    }
  }, [appConnection?.connectionInvalidatedAt, isActive]);

  return (
    <OutlinedWidget {...props}>
      <div className="border-b pb-5 mb-7 flex items-center">
        <h3 className="text-black text-xl">Integration connection</h3>
      </div>
      <div>
        <dl>
          <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 items-center">
            <dt className="font-medium flex gap-2 items-center">
              <div>Status</div>
            </dt>
            <dd className="flex flex-col justify-end">
              <StatusIndicator
                status={status}
                className={cn(
                  'border w-32 flex justify-center h-[36px] ml-auto',
                  isActive ? '' : 'bg-primary-200 text-primary-500'
                )}
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 items-center">
            <dt className="">
              <div className="font-medium flex gap-2 items-center">
                <div>Activation date</div>
                <Info className="cursor-pointer" data-tooltip-id="qbo-activation-date" />
                <StyledTooltip id="qbo-activation-date">
                  <div>
                    <p>
                      The starting date date that Zena will start sync transactions from.
                      Transactions before this date will not be pushed to QuickBooks.
                    </p>
                  </div>
                </StyledTooltip>
              </div>
              <div className="text-sm font-medium leading-6 text-gray-500">
                Date: {moment(appConnection?.startedAt).format('MM/DD/YYYY')}
              </div>
            </dt>
            <dd className="flex justify-end"></dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 items-center">
            <dt>
              <div className="font-medium">Zena account linked to Bank Feed</div>
              <div className="text-sm font-medium leading-6 text-gray-500">
                Account: <QBOPairedAccount />
              </div>
            </dt>
            <dd className="flex justify-end">
              <ChangeQBOLinkedAccount />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0 items-center">
            <dt className="font-medium">QuickBooks Account</dt>
            <dd className="flex justify-end">
              <DeactivateQuickbooksAccountButton />
            </dd>
          </div>
        </dl>
      </div>
    </OutlinedWidget>
  );
};

export default QboIntegration;

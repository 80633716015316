import { Pill } from 'components/atoms/Pill';

export const TransactionAssignedPill = ({
  assignedCategoryType,
  categoryName,
}: {
  assignedCategoryType: 'project' | 'business' | 'notRelated';
  categoryName: string;
}) => {
  const pillColorMap = {
    project: 'bg-lavender-300',
    business: 'bg-accent-400',
    notRelated: 'bg-primary-200',
  };

  return <Pill className={pillColorMap[assignedCategoryType]} text={categoryName} />;
};

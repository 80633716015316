import { useMemo } from 'react';
import { DaysDropdown } from 'components/atoms/DaysDropdown';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { HTMLAttributes, ReactNode } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { cn, formatNumberAsDollars } from 'utils';
import { formatNumberAbbreviated } from 'utils/number';

type Days = 30 | 60 | 90 | 120;

export interface SpendBreakdownChartData {
  id: string;
  name: string;
  amount: number;
}

export const SpendBreakdownChart = ({
  totalSpending,
  days,
  onChangeDays,
  isLoading,
  data,
  className = '',
  disabled = false,
}: {
  totalSpending: number;
  days: Days;
  onChangeDays: (day: Days) => void;
  isLoading?: boolean;
  disabled?: boolean;
  data: SpendBreakdownChartData[];
  className?: string;
}) => {
  const COLORS = {
    900: '#7B5481',
    800: '#986C9F',
    700: '#A980AF',
    600: '#B994BF',
    500: '#DCC2E0',
    400: '#EADAED',
    300: '#F3EBF5',
    200: '#F9F2FA',
  };

  const EMPTY_KEY = 'empty_case';

  if (totalSpending === 0 && !data.find((d) => d.amount > 0)) {
    data = [
      ...data,
      {
        id: EMPTY_KEY,
        amount: 1,
        name: 'No Spending Yet',
      },
    ];
  }

  const processedData = useMemo(() => {
    return data.map((dataInstance) => ({
      ...dataInstance,
      amount: Math.max(dataInstance.amount, 0), // Ensure the value is non-negative
    }));
  }, [data]);

  return (
    <div className={cn('w-full h-full grid gap-12 content-stretch overflow-hidden', className)}>
      <div className="w-full grid gap-4">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-2 sm:gap-0">
          <CardTitle>Spend breakdown</CardTitle>
          <DaysDropdown value={days} onChange={onChangeDays} disabled={disabled} />
        </div>

        <div className="flex flex-row justify-between items-end">
          <div>
            <div className="text-3xl">
              {isLoading ? (
                <RectangleSkeleton height="large" width="large" />
              ) : (
                `$${formatNumberAbbreviated({ amount: totalSpending })}`
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-[1fr_auto] gap-4 grow">
        <div className="relative flex">
          {isLoading && (
            <div className="w-full grid gap-4">
              <RectangleSkeleton />
              <RectangleSkeleton />
            </div>
          )}

          {!isLoading && (
            <div className="grow h-full">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie data={processedData} dataKey="amount" innerRadius={'50%'}>
                    {processedData.map((dataInstance, index) => {
                      const color =
                        dataInstance.id === EMPTY_KEY
                          ? '#F7F7F7'
                          : // @ts-ignore
                            COLORS[1000 - (index + 1) * 100] ?? '#7B5481';
                      return <Cell key={dataInstance.id} fill={color} />;
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>

              <div className="absolute bg-transparent top-0 bottom-0 right-0 left-0 flex justify-center items-center">
                <div className="font-medium">spend</div>
              </div>
            </div>
          )}
        </div>

        <div className="grid gap-2 content-center">
          {data.map((dataInstance, index) => {
            if (dataInstance.id === EMPTY_KEY) {
              return null;
            }

            // @ts-ignore
            const color = COLORS[1000 - (index + 1) * 100] ?? '#7B5481';
            return (
              <LabelContainer key={dataInstance.id}>
                <Dot
                  style={{
                    backgroundColor: color,
                  }}
                  className="mt-1"
                />
                <div>
                  <LabelTitle>{dataInstance.name}</LabelTitle>
                  {isLoading && <RectangleSkeleton />}
                  {!isLoading && (
                    <LabelAmount>{formatNumberAsDollars(dataInstance.amount)}</LabelAmount>
                  )}
                </div>
              </LabelContainer>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const LabelContainer = ({ children }: { children: ReactNode }) => (
  <div className="flex gap-1 flex-row justify-start items-start">{children}</div>
);

const Dot = ({ className, ...props }: { className: string } & HTMLAttributes<HTMLDivElement>) => {
  return <div {...props} className={cn('w-3 h-3 rounded-full', className)} />;
};

const LabelTitle = ({ children }: { children: ReactNode }) => (
  <div className="text-sm">{children}</div>
);

const LabelAmount = ({ children }: { children: ReactNode }) => (
  <div className="text-sm font-medium">{children}</div>
);

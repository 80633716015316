import { MultipleCombobox } from 'components/atoms/Comboboxes/MultipleCombobox';
import { Pill } from 'components/atoms/Pill';
import { Color } from 'components/atoms/Pill/Pill';
import { ComboboxOption, ComboboxProps } from 'components/atoms/Comboboxes/types';

export const MultiPillCombobox = ({
  variant = 'accent',
  onRemove,
  ...props
}: { variant?: Color; onRemove?: (option: ComboboxOption) => void } & ComboboxProps<
  ComboboxOption[]
>) => {
  return (
    <MultipleCombobox
      {...props}
      selectedComponent={({ option, setSelected }) => (
        <Pill
          text={option.label}
          variant={variant}
          className="font-normal"
          onClickCancel={() => {
            setSelected((current) => current.filter((op) => op.value !== option.value));
            onRemove && onRemove(option);
          }}
        />
      )}
    />
  );
};

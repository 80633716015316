import { ListItem } from 'components/atoms/ListItem';
import { UseFormRegisterReturn } from 'react-hook-form';
import Select, { MultiValue } from 'react-select';
import { Selectable } from 'stores/UserStore';

type CustomOptionProps = {
  data: Selectable;
  innerProps: any;
  isDisabled: boolean;
};

type SearchOption = {
  data: Selectable;
};

const CustomOption = ({ data, innerProps, isDisabled }: CustomOptionProps) => {
  if (isDisabled) {
    return null;
  }
  return (
    <div {...innerProps} className="hover:bg-lavender/25 mx-2 rounded-xl pt-5">
      <ListItem
        name={data.name}
        email={'email' in data ? data.email : ''}
        key={data.id}
        url={'profilePictureUrl' in data ? data.profilePictureUrl : ''}
      />
    </div>
  );
};

const searchAlgo = (option: SearchOption, searchText: string) => {
  if (!searchText) {
    return true;
  }
  if (
    option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
    ('email' in option.data && option.data.email.toLowerCase().includes(searchText.toLowerCase()))
  ) {
    return true;
  } else {
    return false;
  }
};

type MultiSelectProps = {
  initialSelectedUsers?: readonly Selectable[];
  selectableUsers: readonly Selectable[];
  setUsers: (users: readonly Selectable[]) => void;
  useFormRegister?: UseFormRegisterReturn;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

const MultiSelect = ({
  selectableUsers,
  setUsers,
  initialSelectedUsers,
  useFormRegister,
  onKeyDown,
}: MultiSelectProps) => {
  const defaultValue = initialSelectedUsers || [];
  const onChange = (selectedOptions: MultiValue<Selectable>) => setUsers(selectedOptions);

  return (
    <div>
      <Select
        unstyled
        classNames={{
          control: () => 'border-b-2 border-marble bg-transparent',
          valueContainer: () => 'flex',
          multiValue: () => 'bg-lavender/25 p-3 rounded-md mx-2 space-x-4',
          multiValueLabel: () => 'pr-2',
          menuList: () => 'bg-white pt-4 round-2xl',
          placeholder: () => 'text-feather text-3xl',
        }}
        defaultValue={defaultValue}
        {...useFormRegister}
        onChange={onChange}
        options={selectableUsers}
        isMulti
        isSearchable
        placeholder="type name or email"
        filterOption={searchAlgo}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        components={{ Option: CustomOption }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default MultiSelect;

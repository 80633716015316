import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import signupImage from 'images/macAndGoldWatch.webp';
import { OnboardingLayout } from '../OnboardingLayout';

export const CreateAccountLayout = ({
  hideBackButton = false,
  children,
  className = '',
  allowSignout = false,
}: {
  children: ReactNode;
  className?: string;
  hideBackButton?: boolean;
  allowSignout?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <OnboardingLayout
      image={signupImage}
      allowSignout={allowSignout}
      imageAlt="Two business associates in a meeting"
      onClickGoBack={
        hideBackButton
          ? undefined
          : () => {
              navigate(-1);
            }
      }
      className={className}
    >
      {children}
    </OnboardingLayout>
  );
};

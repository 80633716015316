import { cloneElement, HTMLAttributes, ReactElement, ReactNode, useEffect, useState } from 'react';
import { cn } from 'utils/utils';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

interface OutlinedWidgetProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}
const OutlinedWidget = ({ children, className = '', ...props }: OutlinedWidgetProps) => {
  return (
    <div className={cn('border-feather border rounded-3xl p-8', className)} {...props}>
      {children}
    </div>
  );
};

interface OutlinedWidgetTitleProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const OutlinedWidgetTitle = ({ children, className, ...props }: OutlinedWidgetTitleProps) => {
  return (
    <div className={cn('border-b pb-5 mb-7', className)} {...props}>
      <h3 className="text-black text-xl">{children}</h3>
    </div>
  );
};

const OutlinedWidgetSubtitle = ({ children }: { children: ReactNode }) => {
  return <h4 className="text-black">{children}</h4>;
};

const CollapsableOutlinedWidget = ({
  children,
  initialOpenState = false,
  openExpandedContent,
  onClose = () => {},
  hideCollapsableButton = false,
}: {
  children: React.ReactNode | ((props: any) => React.ReactNode);
  initialOpenState?: boolean;
  openExpandedContent?: boolean;
  onClose?: () => void;
  hideCollapsableButton?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(initialOpenState);

  useEffect(() => {
    if (openExpandedContent !== undefined) {
      setIsOpen(openExpandedContent);
    }
  }, [openExpandedContent]);

  const handleExpandedStateChange = () => {
    if (isOpen) {
      onClose();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {!hideCollapsableButton && (
        <button className="absolute top-2 right-0" onClick={handleExpandedStateChange}>
          <motion.div
            className="flex-initial"
            animate={{
              rotate: isOpen ? 90 : 0,
              y: isOpen ? 5 : 0,
            }}
          >
            <ChevronRight />
          </motion.div>
        </button>
      )}
      {typeof children === 'function'
        ? (children as (props: any) => JSX.Element)({ isOpen })
        : cloneElement(children as ReactElement, { isOpen })}
    </div>
  );
};

const OutlinedWidgetExpandedContent = ({
  children,
  isOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    <motion.div
      className="overflow-hidden"
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

OutlinedWidget.Title = OutlinedWidgetTitle;
OutlinedWidget.Subtitle = OutlinedWidgetSubtitle;
OutlinedWidget.Collapsable = CollapsableOutlinedWidget;
OutlinedWidget.ExpandedContent = OutlinedWidgetExpandedContent;

export default OutlinedWidget;

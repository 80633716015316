import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { Payment, Statement } from '__generated__/graphql';
import { PageTitle } from 'components/atoms/PageTitle';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { TransactionsTable } from 'components/widgets/transaction-tables/TransactionsTable';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatCentsToDollarsString } from 'utils';

const GET_STATEMENT = gql(`
  query GetStatement($customerId: String!, $date: String!) {
    getStatement(customerId: $customerId, date: $date) {
      id
      date
      beginningBalance
      statementAmount
      endingBalance
      paymentMade
      paymentCreatedAt
      status
      transactionIds
      repaymentIds
      adjustments {
        id
        amountCents
        reason
      }
    }
  }
`);

const GET_PAYMENTS_FOR_STATEMENT = gql(`
  query GetPaymentsForStatement($customerId: String!, $statementDate: String!) {
    getPaymentsForStatement(customerId: $customerId, statementDate: $statementDate) {
      id
      amountCents
      status
      account
      dateInitiated
      initiatedBy
      statementDate
    }
  }
`);

const DataElement = ({ label, value }: { label: string; value: string | JSX.Element }) => (
  <div>
    <label className="min-w-[200px] inline-block">{label}</label>
    <div className="inline-block">{value}</div>
  </div>
);

const StatementDetail = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { statementDate } = useParams();

  const [statement, setStatement] = useState<Statement | null>(null);
  const [payments, setPayments] = useState<Payment[]>([]);
  useQuery(GET_STATEMENT, {
    variables: {
      customerId: activeCompanySlug,
      date: statementDate || '',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getStatement }) => {
      if (getStatement) {
        setStatement(getStatement);
      }
    },
  });

  const { called, loading } = useQuery(GET_PAYMENTS_FOR_STATEMENT, {
    variables: {
      customerId: activeCompanySlug,
      statementDate: statementDate || '',
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getPaymentsForStatement }) => {
      if (getPaymentsForStatement) {
        setPayments(getPaymentsForStatement);
      }
    },
  });

  // if statement date is not in the YYYY-MM-DD format, error out
  if (!statementDate || !statementDate.match(/\d{4}-\d{2}-\d{2}/)) {
    return (
      <div className="h-full w-full bg-white relative px-2 sm:px-12">
        <div className="flex justify-between">
          <PageTitle text="Invalid statement date" />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full bg-white relative px-2 sm:px-12">
      <div className="flex justify-between">
        <PageTitle text={`Statement for ${statement ? statement.date : statementDate}`} />
      </div>
      <div className="pb-8 mt-[-10px]">
        <Link to="/statements" className="underline">
          Back to all statements
        </Link>
      </div>
      {statement && (
        <div>
          <DataElement
            label="Beginning Balance"
            value={formatCentsToDollarsString(statement.beginningBalance)}
          />
          <DataElement
            label="Statement's Transactions"
            value={formatCentsToDollarsString(statement.statementAmount)}
          />
          {statement.adjustments.map((adjustment, idx) => (
            <DataElement
              key={adjustment?.id}
              label={`Adjustment #${idx + 1}`}
              value={`${formatCentsToDollarsString(adjustment?.amountCents || 0)} ${
                adjustment?.reason ? `(Reason: ${adjustment.reason})` : ''
              }`}
            />
          ))}
          <DataElement
            label="Payments Made"
            value={
              statement.paymentMade
                ? `(${formatCentsToDollarsString(statement.paymentMade)})`
                : '$0.00'
            }
          />
          <DataElement
            label="Remaining Balance"
            value={formatCentsToDollarsString(statement.endingBalance)}
          />
        </div>
      )}
      <div className="my-10">
        <h2 className="mb-5">Payments Made</h2>
        {payments.length > 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 content-stretch">
            {payments.map((payment) => (
              <div key={payment.id} className="border-feature border rounded-3xl p-8">
                <DataElement
                  label="Payment Amount"
                  value={
                    payment.amountCents != null
                      ? formatCentsToDollarsString(payment.amountCents)
                      : ''
                  }
                />
                <DataElement label="Status" value={payment.status ? payment.status : 'N/A'} />
                <DataElement label="Account" value={payment.account} />
                <DataElement
                  label="Date Initiated"
                  value={<RelativeDate date={payment.dateInitiated} />}
                />
                <DataElement
                  label="Initiated By"
                  value={payment.initiatedBy ? payment.initiatedBy : 'Auto Payment'}
                />
              </div>
            ))}
          </div>
        )}
        {called && !loading && payments.length === 0 && <div>No payments found</div>}
        {!called && loading && payments.length === 0 && <div>Loading payments</div>}
      </div>
      <div className="my-10">
        <h2 className="mb-5">
          Statement's Transactions&nbsp;
          {statement?.statementAmount
            ? `(Total: ${formatCentsToDollarsString(statement.statementAmount)})`
            : ''}
        </h2>
        <TransactionsTable
          overrides={{ statementDate: statementDate || '' }}
          companySlug={activeCompanySlug}
          showSearch={false}
          showFilters={false}
        />
      </div>
    </div>
  );
};

export default StatementDetail;

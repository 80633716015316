import { DashboardCreditWidget } from '../widgets/CreditWidget';
import { ProjectWidget } from '../widgets/ProjectSpendWidget/ProjectSpendWidget';
import { UpcomingPaymentWidget } from '../widgets/UpcomingPaymentWidget';
import { DashboardContainer } from '../DashboardLayout/DashboardContainer';
import { useHasPlaidConnection } from './data';
import { StayOrganized } from '../DashboardCharts/StayOrganized';
import { SpendBreakdownWidget } from '../widgets/SpendBreakdownWidget';
import { LoadingWidget } from '../widgets/LoadingWidget';
import { FinishApplyingForZenaCard } from '../../../components/widgets/marketing/FinishApplyingForZenaCard';
import { PaymentsVsSpendWidget } from '../widgets/PaymentsVsSpendWidget';
import { PlaidLinkedAccountStatus } from '__generated__/graphql';
import { IncomeWidget } from '../widgets/IncomeWidget';
import { useActiveCompany } from 'providers/ActiveCompany';

export const LuxeDashboard = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { plaidConnectionStatus, loading } = useHasPlaidConnection({
    companySlug: activeCompanySlug,
  });

  return (
    <>
      {loading && (
        <>
          <DashboardContainer gridColumnsWidth={2}>
            <LoadingWidget />
          </DashboardContainer>

          <DashboardContainer>
            <LoadingWidget />
          </DashboardContainer>
        </>
      )}

      {(plaidConnectionStatus === PlaidLinkedAccountStatus.active ||
        plaidConnectionStatus === PlaidLinkedAccountStatus.disconnected) && (
        <>
          <DashboardContainer gridColumnsWidth={2}>
            <DashboardCreditWidget />
          </DashboardContainer>

          <DashboardContainer>
            <UpcomingPaymentWidget />
          </DashboardContainer>
        </>
      )}

      {!loading && !plaidConnectionStatus && (
        <DashboardContainer
          className="flex justify-center items-center border-0 bg-accent-200"
          gridColumnsWidth={3}
        >
          <FinishApplyingForZenaCard />
        </DashboardContainer>
      )}

      <DashboardContainer gridColumnsWidth={2}>
        <PaymentsVsSpendWidget />
      </DashboardContainer>

      <DashboardContainer>
        <SpendBreakdownWidget />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={2}>
        <IncomeWidget />
      </DashboardContainer>

      <DashboardContainer>
        <StayOrganized />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={3}>
        <ProjectWidget />
      </DashboardContainer>
    </>
  );
};

import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import erinKristine from 'images/newLeafInteriors.webp';
import catherineShuman from 'images/intentionalDesignStudio.webp';
import morganRae from 'images/leautelier.webp';
import { cn } from 'utils';
import { ArrowLeft, ArrowRight } from 'lucide-react';

export const DesignerQuotes = ({ className = '' }: { className?: string }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectedQuote: DesignerQuote = DESIGNER_QUOTES[selectedIndex];

  if (selectedQuote) {
    return (
      <div className={cn(className, 'py-4 px-4 rounded-xl grid gap-4 bg-ivory lg:bg-white')}>
        <div className="grid gap-4 grid-col-10">
          {DESIGNER_QUOTES.map((quote, index) => {
            if (index === selectedIndex) {
              return (
                <AnimatePresence key={`image_quote_${quote.id}_${index}`}>
                  <motion.img
                    key="image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.5,
                    }}
                    className="col-span-3 grid-flow-dense rounded-xl"
                    src={quote.image}
                    alt="promotional quote author"
                  />
                  <motion.div
                    key="quote"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.5,
                    }}
                    className="col-start-4 font-[500] text-xl"
                  >
                    {selectedQuote.quote}
                  </motion.div>
                </AnimatePresence>
              );
            }

            return null;
          })}
        </div>

        <div className="flex flex-row items-end justify-between">
          {DESIGNER_QUOTES.map((quote, index) => {
            if (index === selectedIndex) {
              return (
                <AnimatePresence key={`names_${quote.id}_${index}`}>
                  <motion.div
                    key="names"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.5,
                    }}
                  >
                    <div>{selectedQuote.name}</div>
                    <div className="text-sm font-medium">{selectedQuote.businessName}</div>
                  </motion.div>
                </AnimatePresence>
              );
            }

            return null;
          })}

          <div className="flex flex-row gap-4">
            <button
              onClick={() => {
                const nextIndex = selectedIndex - 1;
                setSelectedIndex(nextIndex < 0 ? DESIGNER_QUOTES.length - 1 : nextIndex);
              }}
            >
              <ArrowLeft />
            </button>
            <button
              onClick={() => {
                const nextIndex = selectedIndex + 1;
                setSelectedIndex(nextIndex > DESIGNER_QUOTES.length - 1 ? 0 : nextIndex);
              }}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

interface DesignerQuote {
  id: string;
  name: string;
  businessName: string;
  quote: string;
  image: string;
}

const DESIGNER_QUOTES: DesignerQuote[] = [
  {
    id: '430b1679-989e-4ff3-8747-a1f5ff0b2ec1',
    name: 'Erin Kristine',
    businessName: 'New Leaf Interiors',
    image: erinKristine,
    quote:
      "Joining Zena was a no- brainer...it's so easy to track purchases and reconcile expenses, saving us valuable time and energy we can put towards clients.",
  },
  {
    id: '24d8a252-e245-4a0a-a795-28510088b88f',
    name: 'Catherine Shuman',
    businessName: 'Founder of The Intentional Design Studio',
    image: catherineShuman,
    quote:
      "Zena's taking the hassle out of budget tracking and bookkeeping…their flexible reporting keeps me informed at all times.",
  },
  {
    id: '084cb41b-36df-4a8c-8d9f-8cf348d422d9',
    name: 'Morgan Ræ',
    businessName: 'Founder of L’eautelier',
    image: morganRae,
    quote:
      'I really value companies that take the time to get my feedback. Zena listens to what our needs are and I appreciate that.',
  },
];

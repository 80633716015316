import { ApolloError, useBackgroundQuery, useMutation } from '@apollo/client';
import { Divider } from '@aws-amplify/ui-react';
import { gql } from '__generated__';
import { Suspense, useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import TransactionImportAccountsSection from './TransactionImportAccountsSection';
import { ApplyForZenaCard } from 'components/widgets/marketing/ApplyForZenaCard';
import { useCompany } from 'hooks/useCompany';
import { CompanyTier } from '__generated__/graphql';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useActiveCompany } from 'providers/ActiveCompany';
import { PlaidLink } from 'components/widgets/PlaidLink';
import { CUSTOMER_CREDIT_QUERY } from 'pages/(dashboard)/widgets/CreditWidget/data';
import { LinkedBankDisconnectedCard } from './LinkedBankAccountCard/DisconnectedBankWrapper';
import { LinkedBankAccountCard } from './LinkedBankAccountCard/LinkedBankAccountCard';
import { LinkedBankAccountCardLoading } from './LinkedBankAccountCard/Loading';

const QUERY_GET_PLAID_LINKED_ACCOUNT_SUMMARY = gql(`
  query GetPlaidLinkedAccountSummary($companySlug: String!) {
    getPlaidLinkedAccountSummary(customerId: $companySlug) {
      id,
      bank,
      last4,
      accountType,
      status
    }
  }
`);

const CHANGE_PLAID_LINKED_ACCOUNT_MUTATION = gql(`
  mutation ChangePlaidLinkedAccount($companySlug: String!, $publicToken: String!, $accountId: String!) {
    createPlaidLinkedAccount(companySlug: $companySlug, accountType: "deposit", publicToken: $publicToken, accountId: $accountId) {
      message
    }
  }
`);

const RECONNECT_PLAID_LINKED_ACCOUNT_MUTATION = gql(`
  mutation ReconnectPlaidLinkedAccount($companySlug: String!) {
    reconnectPlaidLinkedAccount(companySlug: $companySlug) {
      message
    }
  }
`);

const BankSettings = () => {
  const [showPlaidLink, setShowPlaidLink] = useState(false);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false);
  const [suspendAccountSummary, setSuspendAccountSummary] = useState(false);
  const setErrorMsg = useAppState((state) => state.setErrorMsg);
  const setSuccessMsg = useAppState((state) => state.setSuccessMsg);
  const { company, loading: companyLoading } = useCompany({
    companySlug: activeCompanySlug,
  });

  const isLuxeCompany = company?.tier === CompanyTier.luxe;

  const [repaymentAccountQueryRef, { refetch: refetchAccountSummary }] = useBackgroundQuery(
    QUERY_GET_PLAID_LINKED_ACCOUNT_SUMMARY,
    {
      variables: {
        companySlug: activeCompanySlug,
      },
    }
  );

  const [changePlaidLinkedAccount] = useMutation(CHANGE_PLAID_LINKED_ACCOUNT_MUTATION, {
    onCompleted() {
      refetchAccountSummary();
      setSuspendAccountSummary(false);
      setSuccessMsg('Success! Zena connected to your bank account.');
      setIsUpdatingAccount(false);
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, [
        'CounterpartyNameVerificationError',
        'PlaidLinkAccountError',
      ]);
      setSuspendAccountSummary(false);
      setIsUpdatingAccount(false);
    },
    refetchQueries: ['GetPlaidLinkedAccountSummary', 'GetPlaidLinkedAccountStatus'],
  });

  const [reconnectPlaidLinkedAccount] = useMutation(RECONNECT_PLAID_LINKED_ACCOUNT_MUTATION, {
    variables: {
      companySlug: activeCompanySlug,
    },
    onCompleted() {
      refetchAccountSummary();
      setSuspendAccountSummary(false);
      setSuccessMsg('Success! Zena reconnected to your bank account.');
      setIsUpdatingAccount(false);
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['PlaidLinkAccountError']);
      setSuspendAccountSummary(false);
      setIsUpdatingAccount(false);
    },
    refetchQueries: [
      'GetPlaidLinkedAccountSummary',
      'GetCustomerAccountLimits',
      'GetPlaidLinkedAccountStatus',
      {
        query: CUSTOMER_CREDIT_QUERY,
        variables: {
          companySlug: activeCompanySlug,
        },
      },
    ],
  });

  const initChangeAccountMode = () => {
    setShowPlaidLink(true);
    setIsUpdatingAccount(false);
  };

  const initReconnectAccountMode = () => {
    setShowPlaidLink(true);
    setIsUpdatingAccount(true);
  };

  const handlePlaidConnectSuccess = async (publicToken: string, accountId: string) => {
    if (isUpdatingAccount) {
      setSuspendAccountSummary(true);
      await reconnectPlaidLinkedAccount();
    } else {
      setSuspendAccountSummary(true);
      await changePlaidLinkedAccount({
        variables: { companySlug: activeCompanySlug, publicToken, accountId },
        refetchQueries: [
          {
            query: CUSTOMER_CREDIT_QUERY,
            variables: {
              companySlug: activeCompanySlug,
            },
          },
        ],
      });
    }
    setIsUpdatingAccount(false);
    setShowPlaidLink(false);
  };

  const MainBankAccountSection = () => {
    if (companyLoading) {
      return <RectangleSkeleton className="h-40 my-8" />;
    }

    if (isLuxeCompany) {
      return (
        <div className="flex flex-col lg:flex-row mt-8 mb-8">
          <div className="flex-1 lg:w-1/3 lg:pr-12 mb-8 gap-2">
            <div className="flex flex-col flex-1 mb-8 gap-2">
              <div className="font-bold tracking-wide">Connected repayments bank account</div>
              <div className="self-stretch text-zinc-600 text-lg leading-snug">
                Daily payments will be withdrawn from your linked bank account. You must have an
                account linked at all times.
              </div>
            </div>
            <Suspense fallback={<></>}>
              <LinkedBankDisconnectedCard
                queryRef={repaymentAccountQueryRef}
                suspend={suspendAccountSummary}
              >
                <div className="flex flex-col flex-1 mb-8 gap-2">
                  <div className="text-sm font-normal tracking-wide">
                    Bank account reconnection information
                  </div>
                  <div className="self-stretch text-zinc-600 text-lg leading-snug">
                    Once you reconnect your bank account, Zena will automatically withdraw the
                    outstanding balance from your account.{' '}
                  </div>
                </div>
              </LinkedBankDisconnectedCard>
            </Suspense>
          </div>
          <div className="flex-2 lg:w-2/3">
            <Suspense fallback={<LinkedBankAccountCardLoading />}>
              <LinkedBankAccountCard
                queryRef={repaymentAccountQueryRef}
                initChangeAccountMode={initChangeAccountMode}
                initReconnectAccountMode={initReconnectAccountMode}
                suspend={suspendAccountSummary}
              />
            </Suspense>
          </div>
        </div>
      );
    }

    return (
      <div className="w-full border border-primary-400 rounded-3xl bg-accent-200 p-8 my-8">
        <div className="block m-auto w-fit">
          <ApplyForZenaCard />
        </div>
      </div>
    );
  };

  return (
    <div>
      <MainBankAccountSection />
      <div className="hidden">
        {showPlaidLink && (
          <PlaidLink
            onConnectAccount={handlePlaidConnectSuccess}
            onFailure={() => setIsUpdatingAccount(false)}
            isUpdatingAccount={isUpdatingAccount}
            onExit={() => setShowPlaidLink(false)}
          />
        )}
      </div>

      <Divider />
      <TransactionImportAccountsSection companySlug={activeCompanySlug} />
    </div>
  );
};

export default BankSettings;

import { useEffect, useState } from 'react';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { TagChart } from './TagChart';
import { useGetProjectTagAggregations, categoryDemoData, roomDemoData } from './data';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TransactionTag } from '__generated__/graphql';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Info, Pencil } from 'lucide-react';

export const ProjectTagCharts = ({
  projectId,
  tags,
}: {
  projectId: string;
  tags: TransactionTag[];
}) => {
  const [projectCategoryTags, setProjectCategoryTags] = useState<string[]>([]);
  const [projectRoomTags, setProjectRoomTags] = useState<string[]>([]);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { tagAggregations, error } = useGetProjectTagAggregations({
    companySlug: activeCompanySlug,
    projectId: projectId,
  });

  const hasData = tagAggregations.categories.length || tagAggregations.rooms.length;

  useEffect(() => {
    const categories: string[] = [];
    const rooms: string[] = [];
    tags.forEach((tag) => {
      if (tag.type === 'category') {
        categories.push(tag.name);
      } else {
        rooms.push(tag.name);
      }
    });

    setProjectCategoryTags(categories);
    setProjectRoomTags(rooms);
  }, [tags]);

  const { projectOverviewTags } = useFlags();
  if (!projectOverviewTags) {
    return null;
  }

  const categoryData = projectCategoryTags.length ? tagAggregations?.categories : categoryDemoData;
  const roomData = projectRoomTags.length ? tagAggregations?.rooms : roomDemoData;

  const NoDataMessage = () => {
    return (
      <div className="text-center py-16">
        {error ? (
          <h3 className="text-red-700">Unable to retrieve category & room tags</h3>
        ) : (
          <>
            <h4 className="mb-6 font-semibold">Start spending, or add historical transactions.</h4>
            <h4 className="font-semibold">Your spend will be broken down by category and room.</h4>
          </>
        )}
      </div>
    );
  };

  return (
    <OutlinedWidget>
      <OutlinedWidget.Title>
        <div className="flex gap-2 items-center">
          Spend by categories & rooms
          <Tooltip id="project-tags-info">
            You can add custom category or room tags to organize and track your spend better.
          </Tooltip>
          <Info data-tooltip-id="project-tags-info" />
          <Link to={`/projects/${projectId}/settings?scrollTo=tags`} className="ml-auto">
            <Pencil size={18} />
          </Link>
        </div>
      </OutlinedWidget.Title>
      {hasData ? (
        <div className="flex justify-around flex-wrap">
          <TagChart
            chartName="Category"
            barColor="text-accent-600"
            data={categoryData}
            isDemo={!projectCategoryTags.length}
            projectId={projectId}
          />
          <TagChart
            chartName="Room"
            data={roomData}
            isDemo={!projectRoomTags.length}
            projectId={projectId}
          />
        </div>
      ) : (
        <NoDataMessage />
      )}
    </OutlinedWidget>
  );
};

import logoZ from '../../../images/logoZ.webp';

type ProfilePhotoImageProps = {
  signedProfilePhotoUrl: string | null;
  size: number;
};

const onImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = logoZ;
};

const ProfilePhotoImage = ({ signedProfilePhotoUrl, size }: ProfilePhotoImageProps) => {
  const className = `bg-gray-100 rounded-full h-${size} w-${size} rounded-${size / 2}`;
  return signedProfilePhotoUrl ? (
    <div className="relative">
      <img
        className={className}
        src={signedProfilePhotoUrl || ''}
        onError={onImageError}
        alt="Profile"
      />
    </div>
  ) : (
    <img className={className} src={logoZ} alt="Profile" />
  );
};

export default ProfilePhotoImage;

const checkmarkImg = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    className="m-auto"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#F5F5F7" />
    <path
      d="M43.199 65.5999L28.2656 50.6666L31.1323 47.7999L43.199 59.8666L68.799 34.2666L71.6656 37.1333L43.199 65.5999Z"
      fill="#19191B"
    />
  </svg>
);

export const Finish = () => {
  return (
    <>
      <div className="mb-6">{checkmarkImg}</div>
      <h1 className="text-3xl font-bold mb-2 w-fit mx-auto">Thanks for your response!</h1>
      <p className="text-[#68686B] mb-6 w-fit mx-auto">We will notify your team.</p>
    </>
  );
};

import { HTMLAttributes } from 'react';
import { useAppState } from '../../../../../stores/UserStore';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { RequireProjectApproval } from './Switches';
import { useActiveCompany } from '../../../../../providers/ActiveCompany';

export const QUERY_GET_SETTINGS = gql(`
  query GetProjectSettings($customerId: String!) {
    getSettings(customerId: $customerId) {
        approvalRequiredProject
        visited
    }
  }
`);
interface ProjectSettingsProps extends HTMLAttributes<HTMLDivElement> {}
export const ProjectSettings = (props: ProjectSettingsProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data: getDataResults } = useQuery(QUERY_GET_SETTINGS, {
    variables: {
      customerId: activeCompanySlug,
    },
  });

  return (
    <div {...props}>
      <div className="flex">
        <div className="flex-1 w-1/3 pr-12">
          <div className="font-bold mb-2">Project settings</div>
          <div className="text-gray-500">Set rules to easily manage your projects.</div>
        </div>
        <div className="flex-2 w-2/3">
          <form className="grid gap-8">
            <RequireProjectApproval
              loading={loading}
              customerId={activeCompanySlug}
              setErrorMsg={setErrorMsg}
              defaultEnabled={getDataResults?.getSettings?.approvalRequiredProject ?? false}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

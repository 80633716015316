import { ReactNode } from 'react';
import { cn } from 'utils';

export const Badge = ({
  children,
  variant = 'active',
  size = 'small',
  className = '',
  ...props
}: {
  children: ReactNode;
  variant?: 'active';
  size?: 'small';
  className?: string;
}) => {
  return (
    <div>
      <div
        className={cn(
          'rounded-full flex items-center justify-center text-white text-center',
          variant === 'active' && 'bg-success-900',
          size === 'small' && 'w-6 h-6 text-sm',
          className
        )}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

import useCustomAuth from 'hooks/useCustomAuth';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function RequireNoAuth({ children }: { children: ReactNode }): JSX.Element | null {
  const navigate = useNavigate();
  const { isLoading, user } = useCustomAuth();

  useEffect(() => {
    if (!isLoading && !!user) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <>{children}</>;
  }

  return null;
}

import { useDashboardLinkedAccount, useDashboardUpcomingPayment } from './data';
import { ReactNode } from 'react';
import { cn, formatCentsToDollarStringWithDecimal } from 'utils';
import { CardTitle } from '../../../../components/widgets/cards/CardTitle';
import { Button, RoundButton } from 'components/atoms/Buttons';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { formatShortDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ArrowRight, Calendar, Info, RotateCcw } from 'lucide-react';

export const UpcomingPaymentWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { upcomingPayment, loading, error } = useDashboardUpcomingPayment({
    companySlug: activeCompanySlug,
  });
  const {
    linkedAccount,
    loading: accountLoading,
    error: accountError,
  } = useDashboardLinkedAccount({ companySlug: activeCompanySlug });
  if (loading || accountLoading) {
    return (
      <Container>
        <Title />
        <div>
          <PaymentsMiniTableHeader />
          <ContentContainer className="grid gap-2">
            <RectangleSkeleton />
            <RectangleSkeleton />
          </ContentContainer>
          <MakePaymentContainer>
            <RectangleSkeleton className="bg-accent" width="medium" />
          </MakePaymentContainer>
        </div>
      </Container>
    );
  }

  if (upcomingPayment) {
    const date = new Date(upcomingPayment.date);
    const formattedDate = formatShortDate(date);
    return (
      <Container>
        <Title />
        <div className="w-full max-w-full overflow-hidden">
          <PaymentsMiniTableHeader />
          <ContentContainer>
            <JustifyBetween>
              {linkedAccount && (
                <div className="font-semibold w-[60%] overflow-hidden flex">
                  <span className="max-w-[70%] whitespace-nowrap overflow-hidden">
                    {linkedAccount.bank}
                  </span>
                  <span>{`...${linkedAccount.last4}`}</span>
                </div>
              )}
              {!linkedAccount && <div>No account linked</div>}

              <div>{formatCentsToDollarStringWithDecimal(upcomingPayment.amountCents ?? 0)}</div>
            </JustifyBetween>
            <JustifyBetween>
              <div className="flex flex-row gap-1 items-center">
                <Calendar size={17} />
                <div className="text-sm">Scheduled</div>
              </div>
              <div className="flex flex-row gap-1 items-center">
                <RotateCcw size={14} />
                <div className="text-sm">{formattedDate}</div>
              </div>
            </JustifyBetween>
          </ContentContainer>
          <MakePaymentContainer>
            <Link to="/payments?makePaymentOpen=true">
              <Button variant="link" className="p-0 no-underline">
                Pay my balance
                <ArrowRight className="stroke-accent" size={16} />
              </Button>
            </Link>
          </MakePaymentContainer>
        </div>
      </Container>
    );
  }

  if (error || accountError) {
    return (
      <Container>
        <Title />
        <div className="text-danger">Something went wrong, please try again later</div>
      </Container>
    );
  }

  return (
    <Container>
      <Title />
      <div>You have no scheduled upcoming payment at this time</div>
      <Link to="/payments">
        <RoundButton variant="secondary">Go to payments</RoundButton>
      </Link>
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className="grid gap-6 w-full overflow-hidden">{children}</div>
);

const JustifyBetween = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={cn('flex flex-row justify-between', className)}>{children}</div>
);

const Title = () => {
  return (
    <div className="flex flex-row gap-4 items-center">
      <Tooltip id="make-payment-title">
        Payments are automatic and scheduled for each day. Payments will amount to your full
        balance, as of the day scheduled.
      </Tooltip>
      <div data-tooltip-id="make-payment-title">
        <Info className="fill:primary" />
      </div>

      <CardTitle>Upcoming payment</CardTitle>
    </div>
  );
};

const PaymentsMiniTableHeader = () => {
  return (
    <JustifyBetween className="pb-1 border-b border-primary">
      <SecondaryText>Scheduled payments</SecondaryText>
      <SecondaryText>Autopay</SecondaryText>
    </JustifyBetween>
  );
};

const SecondaryText = ({ children }: { children: ReactNode }) => (
  <div className="text-sm text-black font-light">{children}</div>
);

const ContentContainer = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={cn('px-2 py-4 border-b border-primary-400', className)}>{children}</div>
);

const MakePaymentContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => <div className={cn('flex flex-row justify-end mt-2', className)}>{children}</div>;

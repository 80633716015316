import { useQuery } from '@apollo/client';
import { AddressOutput, Card } from '__generated__/graphql';
import { ZenaCard } from 'components/atoms/ZenaCard';
import { useCopyContents } from 'utils';
import { Tooltip } from 'react-tooltip';
import { gql } from '__generated__/gql';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Copy } from 'lucide-react';

const QUERY_GET_CARD = gql(`
  query GetProjectZenaCard($customerId: String!, $cardId: String!) {
    getCard(customerId: $customerId, cardId: $cardId) {
      id
      last4Digits
      name
      type
      status
      cardholderFullName {
        first
        last
      }
      expirationDate
      billingAddress {
        street
        street2
        city
        state
        postalCode
      }
    }
  }
`);

export const ProjectZenaCard = ({
  cardId,
  projectAddress,
  showCardOnly,
}: {
  cardId: string;
  cardData?: Card;
  projectAddress?: AddressOutput;
  showCardOnly?: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { copyContents: copyBillingAddress, contentsRef: billingAddressRef } = useCopyContents();
  const { copyContents: copyProjectAddress, contentsRef: projectAddressRef } = useCopyContents();

  const { data } = useQuery(QUERY_GET_CARD, {
    variables: {
      customerId: activeCompanySlug,
      cardId,
    },
  });

  const cardData = data?.getCard;

  let billingAddressString1 = '';
  let billingAddressString2 = '';
  if (cardData?.billingAddress) {
    const { street, street2, city, state, postalCode } = cardData.billingAddress;
    billingAddressString1 = street + (street2 ? ` ${street2}` : '');
    billingAddressString2 = `${city}, ${state}, ${postalCode}`;
  }

  let projectAddressString1 = '';
  let projectAddressString2 = '';
  if (projectAddress && projectAddress.street) {
    projectAddressString1 =
      projectAddress.street + (projectAddress.street2 ? ` ${projectAddress.street2}` : '');
    projectAddressString2 = `${projectAddress.city}, ${projectAddress.state}, ${projectAddress.postalCode}`;
  }

  const projectTooltipId = `copy-project-address-tooltip-${cardId}`;
  const billingTooltipId = `copy-billing-address-tooltip-${cardId}`;

  return cardData ? (
    <div>
      <ZenaCard cardId={cardId} hideCardName showCardOnly={showCardOnly} />
      <hr className="my-5" />
      <h4 className="text-sm mb-2">Billing Address</h4>
      <div className="inline-block">
        <span className="text-base font-semibold" ref={billingAddressRef}>
          {billingAddressString1}
          <br />
          {billingAddressString2}
        </span>
      </div>
      <button
        className="float-right rounded-[50%] h-10 w-10 p-2.5 bg-ivory m-auto mb-4 transition ease-in-out active:bg-lavender active:delay-0 active:duration-0 delay-1000 duration-1000"
        onClick={copyBillingAddress}
        data-tooltip-id={billingTooltipId}
      >
        <Tooltip id={billingTooltipId} openOnClick>
          Copied Billing Address
        </Tooltip>
        <span
          style={{
            filter:
              'invert(86%) sepia(15%) saturate(143%) hue-rotate(351deg) brightness(94%) contrast(89%)',
          }}
        >
          <Copy size={18} />
        </span>
      </button>
      {projectAddress?.street && (
        <>
          <hr className="my-5" />
          <h4 className="text-sm mb-2">Project Address</h4>
          <div className="inline-block">
            <span className="text-base font-semibold" ref={projectAddressRef}>
              {projectAddressString1}
              <br />
              {projectAddressString2}
            </span>
          </div>

          <button
            className="float-right rounded-[50%] h-10 w-10 p-2.5 bg-ivory m-auto mb-4 transition ease-in-out active:bg-lavender active:delay-0 active:duration-0 delay-1000 duration-1000"
            onClick={copyProjectAddress}
            data-tooltip-id={projectTooltipId}
          >
            <Tooltip id={projectTooltipId} openOnClick>
              Copied Project Address
            </Tooltip>
            <span
              style={{
                filter:
                  'invert(86%) sepia(15%) saturate(143%) hue-rotate(351deg) brightness(94%) contrast(89%)',
              }}
            >
              <Copy size={18} />
            </span>
          </button>
        </>
      )}
    </div>
  ) : null;
};

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { StatementFiltersInput } from '__generated__/graphql';
import { updatedDiff } from 'deep-object-diff';
import moment from 'moment';

export interface StatementFilters {
  lastMonth?: boolean;
  lastQuarter?: boolean;
  lastYear?: boolean;
  fromDate?: string;
  toDate?: string;
  statementDate?: string;
}

type ValidationErrors<T extends {}> = { [key in keyof T]: string };

export interface StatementFilterContext {
  // filtersInput is the object that is used to query the backend.
  filtersInput: StatementFiltersInput;
  // selectedFilters is the object that is used to display the selected filters in the UI.
  selectedFilters: StatementFilters;
  // savedFilters is the object that is used to display the selected filters in the UI.
  savedFilters: StatementFilters;
  // updateFilters is used to update the selectedFilters object. It does not update the filtersInput object.
  updateFilters: Dispatch<SetStateAction<StatementFilters>>;
  // saveFilters is used to update the filtersInput object. It takes what is currently the selectedFilters and transforms them to a filtersInput object.
  saveFilters: () => void;
  // updateAndSaveFilters is useful for when you want to update a single or multiple filters and then save the changes to update the filtersInput immediately. Example, remove a filter.
  updateAndSaveFilters: (filters: { [key in keyof StatementFilters]: any }) => void;
  // clearFilters is used to clear the selectedFilters object back to the default values and updates the filtersInput.
  clearFilters: () => void;
  // discardChanges is used to discard any changes to the selectedFilters object and revert back to the previously saved selectedFilters object.
  discardChanges: () => void;
  // hasUnsavedChanges is a boolean that is used to determine if there are any changes to the selectedFilters object that have not been saved to the filtersInput object.
  hasUnsavedChanges: boolean;
  // errors is an object that contains the errors for each filter. It is used to display errors in the UI.
  errors?: ValidationErrors<StatementFilters>;
}

const FilterCtx = createContext<StatementFilterContext | null>(null);

export const useStatementFilter = () => {
  const context = useContext(FilterCtx);
  if (!context) {
    throw new Error('useStatementFilter must be used within a FilterProvider');
  }
  return context;
};

interface FilterProviderProps extends PropsWithChildren {
  initialValues?: StatementFilters;
}

const DEFAULT_INITIAL_VALUES: StatementFilters = {
  lastMonth: false,
  lastQuarter: false,
  lastYear: false,
  fromDate: undefined,
  toDate: '',
};

// normalizeFilters insures that the final filters object is valid and can be used to query the backend.
const normalizeFilters = (filters: StatementFilters) => {
  // FIXME: until (issue 583)[https://vermouthlabs.atlassian.net/browse/ZENA-583] is resolved, we need to set the default toDate too today to avoid an error.
  if (!filters.toDate) {
    filters.toDate = moment().toDate().toISOString();
  }

  return filters;
};
const validateFilters = (
  filters: StatementFilters
): ValidationErrors<StatementFilters> | undefined => {
  let errors: ValidationErrors<StatementFilters> = {};

  return Object.keys(errors).length ? errors : undefined;
};

export function StatementFilterProvider({ initialValues, children }: FilterProviderProps) {
  const initValues = normalizeFilters({
    ...DEFAULT_INITIAL_VALUES,
    ...initialValues,
  });
  const [selectedFilters, _updateFilters] = useState<StatementFilters>(initValues);

  const [filtersInput, _setFiltersInput] = useState<StatementFiltersInput>(
    mapFiltersToFiltersInput(initValues)
  );
  const [errors, _setErrors] = useState<ValidationErrors<StatementFilters>>();

  const previousSavedFilters = useRef(initValues);
  const selectedDiff = updatedDiff(selectedFilters, previousSavedFilters.current) as Record<
    string,
    any
  >;
  const hasUnsavedChanges = !!Object.keys(selectedDiff || {}).length;

  const updateFilters = (filters: SetStateAction<StatementFilters>): void => {
    let resolvedFilters: StatementFilters;

    // SetStateAction can be a function or a new state object
    if (typeof filters === 'function') {
      // Call it with a dummy state to get the resolved filters
      resolvedFilters = (filters as (prevState: StatementFilters) => StatementFilters)(
        selectedFilters
      );
    } else {
      // 'filters' is not a function, so we assume it's the new state object
      resolvedFilters = filters;
    }

    const errors = validateFilters(resolvedFilters);
    _setErrors(errors);
    _updateFilters(normalizeFilters(resolvedFilters));
  };

  const saveFilters = () => {
    const errors = validateFilters(selectedFilters);
    _setErrors(errors);
    if (errors) {
      return;
    }

    const newValues = mapFiltersToFiltersInput(normalizeFilters(selectedFilters));
    _setFiltersInput(newValues);
    previousSavedFilters.current = selectedFilters;
  };

  const updateAndSaveFilters = (filters: { [key in keyof StatementFilters]: any }) => {
    const newFilters = { ...selectedFilters, ...filters };
    updateFilters(newFilters);

    const newValues = mapFiltersToFiltersInput(newFilters);
    _setFiltersInput(newValues);

    previousSavedFilters.current = newFilters;
  };

  const clearFilters = () => {
    const newValues = normalizeFilters(DEFAULT_INITIAL_VALUES);
    _setErrors(undefined);
    updateFilters(newValues);
  };

  const discardChanges = () => {
    if (previousSavedFilters) {
      updateAndSaveFilters(previousSavedFilters.current);
    } else {
      clearFilters();
    }
  };

  return (
    <FilterCtx.Provider
      value={{
        filtersInput,
        selectedFilters,
        savedFilters: previousSavedFilters.current,
        updateFilters,
        saveFilters,
        updateAndSaveFilters,
        clearFilters,
        discardChanges,
        hasUnsavedChanges,
        errors,
      }}
    >
      {children}
    </FilterCtx.Provider>
  );
}

const mapFiltersToFiltersInput = (filters: StatementFilters): StatementFiltersInput => {
  return {
    startDate: filters?.fromDate || '',
    endDate: filters?.toDate || '',
  };
};

export default StatementFilterProvider;

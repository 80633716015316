import { PhoneNumberField } from '@aws-amplify/ui-react';
import { Alert } from 'components/atoms/Alert';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { gql } from '__generated__/gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { updateCompaniesToLocalStorage } from 'hooks/access';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { deformatPhoneNumber, formatPhoneNumber, onApolloError } from 'utils';
import zenaLogo from '../../images/zenaLogo.webp';
import {
  GET_USER_CUSTOMER_ACCESS,
  useGetAuthorizedInvitation,
  useGetCompanyRolesToJoinTeam,
} from './data';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { ArrowLeft, XIcon } from 'lucide-react';

export const GetActiveCompanyForNewTeamMember = gql(`
  query getActiveCompanyForNewTeamMember {
    getMe {
      id
      defaultCompany {
        id
        name
        status
        tier
        slug
      }
    }
  }
`);

export const MUTATION_ACCEPT_AUTHORIZED_USER_INVITATION = gql(`
  mutation AcceptAuthorizedUserInvitation($customerId: String!, $data: AuthorizedUserInput!) {
    acceptAuthorizedUserInvitation(customerId: $customerId, data: $data) {
      pendingInvitations {
        email
        companySlug
        status
      }
    }
  }
`);

interface IFormInputs {
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
  dob: string;
}

const JoinTeam = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get('email') || '';
  const tokenParam = searchParams.get('token') || '';
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('1');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [dobError, setDobError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const { refetch } = useGetCompanyRolesToJoinTeam();
  const [getCompany] = useLazyQuery(GetActiveCompanyForNewTeamMember, {
    fetchPolicy: 'network-only',
  });
  const [redirecting, setIsRedirecting] = useState(false);
  const { authorizedInvitation, loading: authorizedInvitationLoading } = useGetAuthorizedInvitation(
    {
      tokenParam,
      setErrorMessage,
    }
  );

  const [acceptInvitationMutation, { data, loading: acceptInvitationLoading }] = useMutation(
    MUTATION_ACCEPT_AUTHORIZED_USER_INVITATION,
    {
      onError: (error) => onApolloError(error, setErrorMessage, ['NotInvited']),
      onCompleted: () => {
        setIsRedirecting(true);
        refetch().then((r) => {
          const roles = r.data.getUserCompanyRole;
          updateCompaniesToLocalStorage(
            roles.map((cr) => ({
              customerId: cr.customerSlug,
              roles: [cr.role] ?? [],
            }))
          );
          getCompany().then(() => {
            setIsRedirecting(false);
            navigate('/dashboard');
          });
        });
      },
      refetchQueries: [
        {
          query: GET_USER_CUSTOMER_ACCESS,
        },
      ],
    }
  );

  useEffect(() => {
    if (!emailParam || !tokenParam) {
      setErrorMessage('Invitation is not valid');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateFields = (data: IFormInputs) => {
    let hasError = false;
    if (!data.firstName) {
      setFirstNameError('First name is required');
      hasError = true;
    } else {
      setFirstNameError('');
    }
    if (!data.lastName) {
      setLastNameError('Last name is required');
      hasError = true;
    } else {
      setLastNameError('');
    }
    if (!data.dob) {
      setDobError('Date of birth is required');
      hasError = true;
    } else {
      setDobError('');
    }
    if (!mobilePhoneNumber) {
      setPhoneError('Phone is required');
      hasError = true;
    } else {
      setPhoneError('');
    }

    return hasError;
  };
  const { register, handleSubmit } = useForm<IFormInputs>();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const hasError = validateFields(data);
    if (hasError) return;
    data.firstName &&
      data.lastName &&
      data.dob &&
      authorizedInvitation?.companySlug &&
      acceptInvitationMutation({
        variables: {
          customerId: authorizedInvitation.companySlug,
          data: {
            fullName: {
              first: data.firstName,
              last: data.lastName,
            },
            email: authorizedInvitation?.email,
            phone: {
              countryCode: countryCode,
              number: deformatPhoneNumber(mobilePhoneNumber),
            },
            birthDate: data.dob,
          },
        },
        onError(error) {
          setErrorMessage(error.message);
        },
      });
  };

  const hasInvitation = authorizedInvitation?.status;

  if (acceptInvitationLoading || authorizedInvitationLoading || redirecting)
    return (
      <>
        <PageTitle hidden text="Loading ..." />
        <div className="grid place-items-center mt-52">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Loading ...</p>
          </div>
        </div>
      </>
    );

  if (!authorizedInvitationLoading && !hasInvitation)
    return (
      <>
        <div className="grid h-screen w-screen bg-secondary-400">
          <div className="flex flex-col xl:grid xl:grid-cols-3 h-full">
            <div className="m-8">
              <div className="flex w-full justify-center xl:block">
                <Link to="/">
                  <img className="h-6" src={zenaLogo} alt="Zena Logo"></img>
                </Link>
              </div>
            </div>
            <div className="absolute top-9 right-8 mobile:top-4 mobile:h-11 mobile:pt-2">
              <Link to="/">
                <XIcon className="mx-2" />
              </Link>
            </div>
            <PageTitle hidden text="Invitation Not Accepted" />
            <div className="flex justify-center ">
              <div className="xl:mt-24">
                <Alert variation="error">
                  Invitation not accepted. Please double check that you are logged into the invited
                  account first.
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <PageTitle hidden text="Join Team" />
      <div className="grid place-content-center h-screen w-screen bg-secondary-400">
        <div className="p-12 bg-ivory rounded-4xl">
          <img className="h-4" src={zenaLogo} alt="Zena Logo"></img>
          <h1 className="mt-10 text-2xl">Tell us about yourself</h1>
          <p className="mt-4">
            Our bank partner requires this information to verify your identity and issue Zena cards.
          </p>
          {errorMessage && (
            <div className="mt-4">
              <Alert variation="error">{errorMessage}</Alert>
            </div>
          )}
          <div className="mt-4"></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-x-2">
              <TextInput
                className="mb-2 text-asphalt"
                label="First Name"
                useFormRegister={register('firstName')}
                hasError={!!firstNameError}
                errorMessage={firstNameError}
              />
              <TextInput
                className="mb-2 text-asphalt"
                label="Last Name"
                useFormRegister={register('lastName')}
                hasError={!!lastNameError}
                errorMessage={lastNameError}
              />
            </div>
            <TextInput
              className="mb-2 text-feather"
              label="Country"
              value="United States"
              disabled
            />
            <TextInput
              className="mb-2 text-asphalt"
              label="Date of Birth"
              type="date"
              useFormRegister={register('dob')}
              hasError={!!dobError}
              errorMessage={dobError}
            />
            <div
              className={`flex flex-col px-4 pt-2 pb-1.5 rounded-lg border ${
                phoneError ? 'border-rustic' : 'border-marble'
              }`}
            >
              <p className="text-sm mb-1 text-asphalt">Phone</p>
              <PhoneNumberField
                label="Phone"
                labelHidden
                variation="quiet"
                defaultDialCode="+1"
                dialCodeList={['+1']}
                onChange={(e) => {
                  setMobilePhoneNumber(formatPhoneNumber(e.target.value));
                }}
                onDialCodeChange={(e) => setCountryCode(e.target.value.substring(1))}
                value={mobilePhoneNumber}
              />
            </div>
            {phoneError && <div className="text-rustic mt-2">{phoneError}</div>}
            <div className="flex justify-between items-center mt-8">
              <div className="flex">
                <ArrowLeft />
                <UnderlineButton text="Back" />
              </div>
              <div>
                {acceptInvitationLoading && (
                  <span className="self-center mr-3">
                    <LoadingSpinner />
                  </span>
                )}
                <RoundButton
                  type="submit"
                  text="Join your Team"
                  disabled={acceptInvitationLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default JoinTeam;

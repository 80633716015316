import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const CATEGORIES_QUERY = gql(`
  query GetAllCategoriesToAllocateTransaction($companySlug: String!) {
    getProjects(customerId: $companySlug, filters: {
      includeUnapproved: false
      status: active
    }) {
      projects {
        id
        name
      }
    }
  }
`);

export const useCategories = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(CATEGORIES_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const projects = data?.getProjects?.projects ?? [];

  return { projects, loading, error };
};

const GET_SELECTED_PROJECT_QUERY = gql(`
  query GetSelectedProjectForDropdownSelect($companySlug: String!, $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      name
    }
  }
`);

export const useSelectedProject = ({
  companySlug,
  projectId,
}: {
  companySlug: string;
  projectId: string;
}) => {
  const { data, loading, error } = useQuery(GET_SELECTED_PROJECT_QUERY, {
    variables: {
      companySlug,
      projectId,
    },
    skip: !companySlug || !projectId,
  });

  const project = data?.getProject ?? null;

  return { project, loading, error };
};

import { Field } from 'components/atoms/Inputs/Field';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { SelectAccountingTag } from '../../SelectAccountingTag';
import { SelectProject } from 'components/widgets/TransactionDetailsDrawer/SelectProject';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Input } from 'components/atoms/Inputs/Input';
import { SelectProjectRooms } from '../../SelectProjectRooms';
import { SelectProjectCategories } from '../../SelectProjectCategories';
import { TransactionMissingFields } from '__generated__/graphql';
import { ChevronRight } from 'lucide-react';

export const NonChildTransactionDetails = ({
  projectId,
  onChangeAccountingTag,
  selectedAccountingTagName,
  onChangeProjectId,
  onChangeInvoiceNumber,
  projectCategories,
  projectRooms,
  onChangeProjectCategories,
  onChangeProjectRooms,
  invoiceNumber,
  isWidgetOpenByDefault = false,
  hasActiveQboConnection = false,
  missingFields,
  showMissingRequirements,
}: {
  projectId: string;
  onChangeProjectId: (projectId: string) => void;
  onChangeInvoiceNumber: (invoiceNumber: string) => void;
  onChangeAccountingTag: (
    data: { accountingTagId: string; accountingTagName: string } | null
  ) => void;
  selectedAccountingTagName: string;
  projectCategories: string[];
  projectRooms: string[];
  invoiceNumber: string;
  isWidgetOpenByDefault?: boolean;
  hasActiveQboConnection?: boolean;
  showMissingRequirements: boolean;
  onChangeProjectRooms: (rooms: string[]) => void;
  onChangeProjectCategories: (rooms: string[]) => void;
  missingFields?: TransactionMissingFields;
}) => {
  const [forceExpandedContentOpen, setForceExpandedContentOpen] = useState(isWidgetOpenByDefault);
  const showAccountingCategoriesSection = hasActiveQboConnection;

  return (
    <OutlinedWidget className="p-5 rounded-lg">
      <OutlinedWidget.Collapsable
        openExpandedContent={forceExpandedContentOpen}
        onClose={() => setForceExpandedContentOpen(false)}
        hideCollapsableButton
      >
        {({ isOpen }) => (
          <>
            <div className="grid gap-8">
              <Field label="Select project or business area">
                <SelectProject
                  projectId={projectId}
                  onChange={(updatedProjectId) => {
                    onChangeProjectId(updatedProjectId);
                  }}
                />
              </Field>
              {showAccountingCategoriesSection && (
                <Field label="Accounting tag for Quickbooks">
                  <SelectAccountingTag
                    selectedAccountingTagName={selectedAccountingTagName}
                    onChangeAccountingTag={(accountingTag) => {
                      onChangeAccountingTag(accountingTag);
                    }}
                    isMissing={
                      showMissingRequirements &&
                      missingFields?.accountingTag &&
                      !selectedAccountingTagName
                    }
                  />
                </Field>
              )}

              <button
                className="p-0 border-none w-full flex justify-between gap-4 items-center"
                onClick={() => {
                  setForceExpandedContentOpen(!forceExpandedContentOpen);
                }}
              >
                <div className="text-muted">more options</div>
                <motion.div
                  className="flex-initial"
                  animate={{
                    rotate: forceExpandedContentOpen ? 90 : 0,
                    y: forceExpandedContentOpen ? 5 : 0,
                  }}
                >
                  <ChevronRight />
                </motion.div>
              </button>
            </div>

            <OutlinedWidget.ExpandedContent isOpen={isOpen}>
              <div className="grid gap-6 mt-6">
                <Field
                  label="Add category tag"
                  clarifier={projectId ? '' : 'Select a project to assign categories'}
                >
                  <SelectProjectCategories
                    projectId={projectId}
                    onChangeCategories={(newCategories) => onChangeProjectCategories(newCategories)}
                    categories={projectCategories}
                  />
                </Field>

                <Field
                  label="Add room tag"
                  clarifier={projectId ? '' : 'Select a project to assign rooms'}
                >
                  <SelectProjectRooms
                    projectId={projectId}
                    onChangeRooms={(newRooms) => onChangeProjectRooms(newRooms)}
                    rooms={projectRooms}
                  />
                </Field>

                <Field label="PO/Invoice #">
                  <Input
                    value={invoiceNumber}
                    onChange={(e) => onChangeInvoiceNumber(e.currentTarget.value)}
                  />
                </Field>
              </div>
            </OutlinedWidget.ExpandedContent>
          </>
        )}
      </OutlinedWidget.Collapsable>
    </OutlinedWidget>
  );
};

import { Days } from 'components/atoms/DaysDropdown';
import { useState } from 'react';
import { useIncomeVsSpendData } from './data';
import { PaymentsVsSpendChart } from 'pages/(dashboard)/DashboardCharts/PaymentsVsSpendChart';
import { toDollars } from 'utils';
import { getMonthShortNameFromMonthEnum } from 'utils/text';
import { useActiveCompany } from 'providers/ActiveCompany';

export const PaymentsVsSpendWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [days, setDays] = useState<Days>(120);
  const today = new Date();
  const fromDate = new Date(today.setDate(today.getDate() - days));

  const { data, loading } = useIncomeVsSpendData({
    companySlug: activeCompanySlug,
    fromDate,
  });

  return (
    <PaymentsVsSpendChart
      totalMargin={data ? toDollars(data.totalIncomeAmountCents - data.totalSpendAmountCents) : 0}
      days={days}
      onChangeDays={(day) => setDays(day)}
      isLoading={loading}
      data={
        data?.months.map((month) => ({
          id: month.id,
          name: getMonthShortNameFromMonthEnum(month.month),
          payments: toDollars(month.totalIncomeAmountCents),
          spend: toDollars(month.totalSpendAmountCents),
        })) ?? []
      }
    />
  );
};

import { HTMLAttributes } from 'react';

export type Days = 30 | 60 | 90 | 120;

export const DaysDropdown = ({
  value,
  onChange,
  disabled,
  ...props
}: {
  value: Days;
  onChange: (newValue: Days) => void;
  disabled?: boolean;
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onChange' | 'value'>) => {
  const dayOptions = ['30', '60', '90', '120'];

  return (
    <select
      value={String(value)}
      onChange={(e) => {
        onChange(Number(e.currentTarget.value) as Days);
      }}
      disabled={disabled}
      {...props}
    >
      {dayOptions.map((day) => {
        return <option key={day} value={day}>{`${day} days`}</option>;
      })}
    </select>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const PLAID_CONNECTION_STATUS_QUERY = gql(`
  query GetPlaidLinkedAccountStatusForNotifications($companySlug: String!) {
    getPlaidLinkedAccountSummary(customerId: $companySlug) {
      status
    }
  }
`);

export const useHasPlaidConnection = ({ activeCompanySlug }: { activeCompanySlug?: string }) => {
  const { data, loading, error } = useQuery(PLAID_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug: activeCompanySlug ?? '',
    },
    fetchPolicy: 'network-only',
    skip: !activeCompanySlug,
  });

  return {
    plaidConnectionStatus: data?.getPlaidLinkedAccountSummary?.status,
    loading,
    error,
  };
};

const GET_CUSTOMER_ACCOUNT_INFO_FOR_NOTIFICATIONS = gql(`
query GetCompanyInformationForNotifications($companySlug: String!) {
  getCustomerAccount(customerId: $companySlug) {
    id
    creditBalanceCents
    isFrozen
  }
}
`);

export const useGetCompanyForNotifications = ({ companySlug }: { companySlug?: string }) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_ACCOUNT_INFO_FOR_NOTIFICATIONS, {
    variables: {
      companySlug: companySlug ?? '',
    },
    skip: !companySlug,
  });

  return {
    isFrozen: data?.getCustomerAccount?.isFrozen,
    balance: data?.getCustomerAccount?.creditBalanceCents,
    loading,
    error,
  };
};

import { ApolloError, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';

export const QUERY_PROJECT = gql(`
  query GetProjectForProjectPageLayout($companySlug: String!, $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      name
      approved
      rejectedAt
    }
  }
`);

export const useGetProject = ({
  companySlug,
  projectId,
}: {
  companySlug?: string;
  projectId?: string;
}) => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data, error, refetch } = useQuery(QUERY_PROJECT, {
    variables: {
      companySlug: companySlug || '',
      projectId: projectId || '',
    },
    skip: !projectId || !companySlug,
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['ProjectNotFound']);
    },
  });

  return {
    project: data?.getProject,
    refetchProject: refetch,
    loading,
    error,
  };
};

import { Button, RoundButton } from 'components/atoms/Buttons';
import { Pill } from 'components/atoms/Pill';
import { Link } from 'react-router-dom';
import { toDollarDisplay, toDollars } from 'utils';

type ProjectSuccessProps = {
  customerId: string;
  approved: boolean;
  name: string;
  budget?: number;
  projectId: string;
  cardLast4Digits?: string;
  onApproveProject: () => void;
};
export const ProjectReviewSuccess = ({
  customerId,
  approved,
  name,
  budget,
  projectId,
  cardLast4Digits,
  onApproveProject,
}: ProjectSuccessProps) => {
  const budgetDollars = toDollarDisplay(toDollars(budget || 0));
  return (
    <div className="w-full h-full bg-ivory flex flex-col items-center justify-center">
      <div className="bg-white p-8 h-[265px] w-[360px] rounded-3xl">
        {approved ? (
          <Pill variant="success" text="New Project" />
        ) : (
          <Pill
            variant={approved ? 'success' : 'reject'}
            text={approved ? 'Approved' : 'Rejected'}
          />
        )}
        <p className="text-2xl mt-6">{name}</p>
        <div className="border-t border-secondary-400 mt-10"></div>
        <div className="flex justify-between items-end mt-4">
          {budget && budget > 0 ? (
            <div>
              {budgetDollars}
              <p className="text-sm text-asphalt">Remaining Budget</p>
            </div>
          ) : (
            <div>
              <Link to={`/projects/${projectId}/settings`}>
                <Button text="Set Budget" variant="tertiary" />
              </Link>
            </div>
          )}
          <p className="text-sm text-asphalt">
            {'Virtual'} · {cardLast4Digits}
          </p>
        </div>
      </div>
      <h1 className="mt-12">
        {approved ? 'The project was approved' : 'Thanks for your response'}
      </h1>
      <p className="mt-6">
        {approved
          ? "We'll create the project and issue a project card to use for spending."
          : "We'll notify the requester and remove the pending project."}
      </p>
      <RoundButton
        className="mt-6"
        text={approved ? 'View project' : 'View all projects'}
        variant="primary"
        onClick={() =>
          approved ? onApproveProject() : (window.location.href = `/${customerId}/projects`)
        }
      />
    </div>
  );
};

import { Check, CircleAlert, Clock, X } from 'lucide-react';

interface NotificationBannerProps {
  variation: 'pending' | 'success' | 'warning';
  message: string | React.ReactNode;
  onDismiss?: () => void;
}

const StyleMappings = {
  pending: {
    icon: <Clock />,
    background: 'bg-forest-300',
  },
  success: {
    icon: <Check />,
    background: 'bg-forest-300',
  },
  warning: {
    icon: <CircleAlert />,
    background: 'bg-[#B92B0C14]',
  },
};

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  variation,
  message,
  onDismiss,
}) => {
  const style = StyleMappings[variation];
  return (
    <div className={`w-full ${style.background} rounded-full py-4 px-6 flex items-center`}>
      <span>{style.icon}</span>
      <div className="grow">{message}</div>
      {onDismiss && (
        <div className="p-4 my-[-4rem] mr-[-1rem] cursor-pointer" onClick={onDismiss}>
          <X />
        </div>
      )}
    </div>
  );
};

export default NotificationBanner;

import { UnderlineTextInput } from 'components/atoms/Inputs/UnderlineTextInput';

type CardNameProps = {
  cardName: string;
  setCardName: (value: string) => void;
  cardNameError?: string;
};

const CardNameStep = ({ cardName, setCardName, cardNameError = '' }: CardNameProps) => {
  return (
    <UnderlineTextInput
      label="Please give the card a name"
      placeholder="Card's Name"
      value={cardName}
      onChange={(e) => setCardName(e.target.value)}
      hasError={!!cardNameError}
      errorMessage={cardNameError}
    />
  );
};

export default CardNameStep;

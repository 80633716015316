import { useQuery } from '@apollo/client';
import { gql } from '__generated__';

export const GET_TRANSACTIONS_REQUIRE_ATTENTION_NOTIFICATION = gql(`
    query GetTransactionsRequireAttentionNotification(
    $customerId: String!
    $pageNumber: Int!
    $pageSize: Int
    ) {
        getAuthorizationsAndTransactions(
            customerId: $customerId
            pageNumber: $pageNumber
            pageSize: $pageSize
            filters: { onlyMissingData: true }
        ) {
            meta {
                total
            }
        }
    }
`);

export const useGetTransactionsRequireAttentionNotification = ({
  customerId,
  pageNumber,
  pageSize,
}: {
  customerId: string;
  pageNumber: number;
  pageSize: number;
}) => {
  const { data, loading, error } = useQuery(GET_TRANSACTIONS_REQUIRE_ATTENTION_NOTIFICATION, {
    variables: {
      customerId,
      pageNumber,
      pageSize,
    },
  });

  return {
    loading,
    error,
    transactionsNeedingAttentionNotification: !!data?.getAuthorizationsAndTransactions?.meta?.total,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { PageTitle } from 'components/atoms/PageTitle';
import TagPurchaseWorkflow from 'components/widgets/TagPurchaseWorkflow/TagPurchaseWorkflow';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useParams } from 'react-router-dom';

const GET_TRANSACTION = gql(`
  query GetTransaction($customerId: String!, $transactionId: String!) {
    getTransaction(customerId: $customerId, transactionId: $transactionId) {
      id
      name
      shortName
      amount
      amountWithDirection
      spendCategory
      zenaType
      date
      direction
      accountId
      isAuthorization
      categories
      rooms
      projectId
      memo
      card {
        id
        name
      }
      tags {
        tagName
        value
      }
      roomTags {
        tagName
        value
      }
      categoryTags {
        tagName
        value
      }
      poCodeTag {
        tagName
        value
      }
      user {
        email
        fullName {
          first
          last
        }
      }
      receipt {
        imageUrl
      }
      lineItems {
        externalId
        totalCents
        description
      }
      childTransactionIds
    }
  }
`);

const TagTransactionPage = () => {
  const { transactionId } = useParams();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { data, error: transactionError } = useQuery(GET_TRANSACTION, {
    variables: {
      customerId: activeCompanySlug,
      transactionId: transactionId!,
    },
  });

  return (
    <>
      <PageTitle hidden text="Tag a purchase" />
      {transactionError ? (
        <div>{transactionError.message}</div>
      ) : (
        <TagPurchaseWorkflow companySlug={activeCompanySlug} model={data?.getTransaction || null} />
      )}
    </>
  );
};

export default TagTransactionPage;

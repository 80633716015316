import data from './states.json';

export interface State {
  name: string;
  isoCode: string;
}

export const sortStates = (a: State, b: State) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export function getAllStates(): State[] {
  const states = data;
  return states.sort(sortStates);
}

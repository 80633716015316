import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const APPLICATION_QUERY = gql(`
  query ApplicationQuery  {
    getApplication {
      formId
      status
      complete
    }
  }
`);

export const useApplication = () => {
  const { data, loading, error } = useQuery(APPLICATION_QUERY);

  return { loading, error, application: data?.getApplication ?? null };
};

import { useLazyQuery } from '@apollo/client';
import { Card } from '__generated__/graphql';
import { ZenaCard } from 'components/atoms/ZenaCard';
import { TransactionsTable } from 'components/widgets/transaction-tables/TransactionsTable';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AmountSpent } from './AmountSpent';
import { gql } from '__generated__/gql';
import { useActiveCompany } from 'providers/ActiveCompany';
import { companyRole } from 'hooks/access';

const QUERY_GET_CARD_LIMITS = gql(`
  query GetCardLimits($cardId: String!, $companySlug: String!) {
    getCardLimits(cardId: $cardId, companySlug: $companySlug) {
      limits {
        dailyPurchase
        monthlyPurchase
      }
      dailyTotals {
        purchases
      }
      monthlyTotals {
        purchases
      }
    }
  }
`);

interface CardsListProps {
  customerId: string;
  cardsData: Card[];
  showSummary?: boolean;
  showTransactions?: boolean;
  archived?: boolean;
}

const CardsList = ({
  cardsData,
  customerId,
  showSummary = false,
  showTransactions = false,
  archived,
}: CardsListProps) => {
  const { cardId } = useParams();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [spentToday, setSpentToday] = useState<number>(0);
  const [spentMonthly, setSpentMonthly] = useState<number>(0);
  const [dailyBudget, setDailyBudget] = useState<number>(NaN);
  const [monthlyBudget, setMonthlyBudget] = useState<number>(NaN);
  const navigate = useNavigate();
  const location = useLocation();

  const role = companyRole(activeCompanySlug);
  const showCardOnly = role !== 'admin';

  const [getCardLimits, { data: cardLimitsData }] = useLazyQuery(QUERY_GET_CARD_LIMITS);

  useEffect(() => {
    if (cardsData.length > 0) {
      if (cardId) {
        const selectedCard = cardsData.find((card: Card) => card.id === cardId);
        if (selectedCard) {
          setSelectedCardId(cardId);
        } else {
          setSelectedCardId(cardsData[0].id);
        }
      } else {
        setSelectedCardId(cardsData[0].id);
      }
    }
  }, [cardsData, cardId]);

  useEffect(() => {
    if (selectedCardId) {
      getCardLimits({
        variables: {
          cardId: selectedCardId,
          companySlug: activeCompanySlug,
        },
      });
      const newRoute = `/cards/${selectedCardId}`;
      if (!location.pathname.startsWith(newRoute)) {
        navigate(newRoute);
      }
    }
  }, [cardsData, selectedCardId, customerId, getCardLimits]);

  useEffect(() => {
    if (cardLimitsData?.getCardLimits?.dailyTotals?.purchases) {
      setSpentToday(cardLimitsData.getCardLimits.dailyTotals.purchases / 100);
    }
    if (cardLimitsData?.getCardLimits?.monthlyTotals?.purchases) {
      setSpentMonthly(cardLimitsData.getCardLimits.monthlyTotals.purchases / 100);
    }
    if (cardLimitsData?.getCardLimits?.limits?.dailyPurchase) {
      setDailyBudget(cardLimitsData.getCardLimits.limits?.dailyPurchase / 100);
    }
    if (cardLimitsData?.getCardLimits?.limits?.monthlyPurchase) {
      setMonthlyBudget(cardLimitsData.getCardLimits.limits?.monthlyPurchase / 100);
    }
  }, [cardLimitsData]);

  const emptyView = () =>
    archived ? (
      <div className="grid">
        <div className="max-w-max lg:max-w-[50%]">
          <h2 className="text-4xl">You don't have any archived cards</h2>
          <p className="text-asphalt mt-6">
            To archive or freeze a Business spend card, select either 'close card' or 'freeze card'
            from the card menu dropdown. All data will remain accessible for viewing, even when the
            card is closed.
          </p>
        </div>
      </div>
    ) : (
      <div className="grid">
        <div className="max-w-max lg:max-w-[50%]">
          <h2 className="text-4xl">Create your first Business Spend card</h2>
          <p className="text-asphalt mt-6">
            When creating a new Business Spend card, you have the option to select either a physical
            or virtual corporate card. Each team member is eligible for one physical corporate card,
            which must be issued in their full name. Virtual Business Spend cards offer flexibility
            for various expenses, including client gifts, travel, conferences, recurring
            subscriptions, and other business-related costs.
          </p>
        </div>
      </div>
    );

  return (
    <>
      {cardsData.length ? (
        <ul className="flex w-full flex-row flex-nowrap overflow-x-auto " data-testid="cards">
          {cardsData.map((theCard, index) => {
            const theCardId = theCard.id;
            return (
              <li
                className={`p-3 ${
                  theCardId === selectedCardId ? 'bg-green-900/25' : null
                } flex-shrink-0`}
                key={theCardId}
              >
                <div onClick={() => setSelectedCardId(theCardId)}>
                  <ZenaCard
                    cardId={theCardId}
                    cardData={cardsData[index]}
                    showCardOnly={showCardOnly}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        emptyView()
      )}
      {showSummary && (
        <div className="py-8">
          <h2 className="">Summary</h2>
          <div className="mt-4 flex w-full flex-col md:flex-row">
            <div className="w-full p-2">
              <AmountSpent spent={spentToday} total={dailyBudget} text="Today" />
            </div>
            <div className="w-full p-2">
              <AmountSpent spent={spentMonthly} total={monthlyBudget} text="Monthly" />
            </div>
          </div>
        </div>
      )}
      {showTransactions && selectedCardId && (
        <div className="pt-4">
          <h2>Activity</h2>
          <div className="mt-6">
            <TransactionsTable
              overrides={{ cardId }}
              companySlug={customerId}
              showSearch={false}
              showFilters={false}
              disableFiltersUrlQueryPersistence
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CardsList;

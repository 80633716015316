import { motion } from 'framer-motion';
import { RoundButton } from '../Buttons';
import { useMemo, useState } from 'react';
import { InputDropdown } from '../InputDropdown';
import { ChevronLeft } from 'lucide-react';

const lastDaysList = [7, 30, 60, 90, 'ALL'] as const;
export type LastDays = (typeof lastDaysList)[number];

export const LastDaysDropdown = ({
  lastDays,
  onChange,
  className,
}: {
  lastDays: LastDays;
  onChange: (value: LastDays) => void;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputList = useMemo(() => {
    return lastDaysList.map((ld) => ({
      id: String(ld),
      value: ld === 'ALL' ? 'All data' : String(ld),
    }));
  }, []);

  return (
    <div className="relative w-36">
      <RoundButton variant="primary" className="w-full gap-1" onClick={() => setIsOpen(true)}>
        <div className="text-white text-nowrap">
          {lastDays === 'ALL' ? 'All data' : `Last ${lastDays} days`}
        </div>
        <motion.div
          animate={{
            rotate: isOpen ? 90 : -90,
          }}
        >
          <ChevronLeft className="fill-white" />
        </motion.div>
      </RoundButton>

      {isOpen && (
        <InputDropdown
          list={inputList}
          onClose={() => setIsOpen(false)}
          onSelectItem={(item) => {
            onChange(item === 'ALL' ? item : (Number(item) as LastDays));
          }}
        />
      )}
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetParentTransactionSplitDetailsQuery } from '__generated__/graphql';

const ASSIGN_PARENT_TRANSACTION_SPLIT_DETAILS_QUERY = gql(`
  query GetParentTransactionSplitDetails($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      parentTransactionId
      amountWithDirection
      shortName
      projectId
      accountingTag {
        tagName
        value
      }
      rooms
      categories
      poCodeTag {
        tagName
        value
      }
      missingFields {
        memo
        receipt
        roomTag
        categoryTag
        accountingTag
      }
      childTransactions {
        id
        parentTransactionId
        amountWithDirection
        shortName
        projectName
        projectId
        accountingTag {
          tagName
          value
        }
        rooms
      categories
        poCodeTag {
          tagName
          value
        }
      }
    }
  }
`);

export const useChildTransactionDetails = ({
  transactionId,
  activeCompanySlug,
  onCompleted,
}: {
  transactionId: string;
  activeCompanySlug: string;
  onCompleted: (data: GetParentTransactionSplitDetailsQuery) => void;
}) => {
  const { data, loading, error } = useQuery(ASSIGN_PARENT_TRANSACTION_SPLIT_DETAILS_QUERY, {
    variables: {
      transactionId,
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || !transactionId,
    onCompleted,
  });

  return { transaction: data?.getTransaction, loading, error };
};

const GET_INTUIT_APP_CONNECTION_STATUS_QUERY = gql(`
  query GetIntuitAppConnectionForTransactionDetailsDrawerParentSplit($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
    }
  }
`);

export const useIntuitAppConnectionStatus = ({ companySlug }: { companySlug: string }) => {
  const { data } = useQuery(GET_INTUIT_APP_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const isIntuitAppConnectedAndActive =
    data?.getIntuitAppConnection?.id &&
    data.getIntuitAppConnection.qboAccountId &&
    data.getIntuitAppConnection.startedAt;

  return { isIntuitAppConnectedAndActive: !!isIntuitAppConnectedAndActive };
};

const GET_COMPANY_INTUIT_CONNECTION = gql(`
  query GetCompanyIntuitConnectionForParentTransactionDetails($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      intuitConnection {
        requireAccountingTag
      }
    }
  }
`);

export const useIsAccountingTagRequired = ({ companySlug }: { companySlug: string }) => {
  const { data, loading } = useQuery(GET_COMPANY_INTUIT_CONNECTION, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return {
    isAccountingTagRequired: !!data?.getCompany?.intuitConnection?.requireAccountingTag ?? false,
    loading,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const GET_COMPANY_ROLES = gql(`
query GetUserCustomerAccessForRequireCompanyRoles {
  getUserCompanyRole {
    customerSlug
    role
  }
}
`);

export const useGetCompanyRoles = ({ companySlugs = [] }: { companySlugs: string[] }) => {
  const { data, loading, error } = useQuery(GET_COMPANY_ROLES, {
    fetchPolicy: 'network-only',
    skip: companySlugs.length > 0,
  });

  return {
    companyRoles: data?.getUserCompanyRole ?? [],
    loading,
    error,
  };
};

import { BarChart } from 'components/atoms/BarChart';
import { Link } from 'react-router-dom';
import { formatNumberAsDollars, toDollars } from 'utils';
import { TooltipContent } from './TooltipContent';

type Tag = {
  key: string;
  count: number;
  spendByTag: number;
};

const xTickFormatter = (value: string) => {
  const tickNum = parseInt(value);
  return formatNumberAsDollars(toDollars(tickNum), true);
};

const TagChart = ({
  chartName,
  barColor,
  data,
  isDemo,
  projectId,
}: {
  chartName: string;
  barColor?: string;
  data?: Tag[];
  isDemo: boolean;
  projectId: string;
}) => {
  const BarChartWithData = () => {
    return (
      <BarChart
        data={data || []}
        barClassName={barColor}
        height={400}
        layout="vertical"
        xDataKey="key"
        yDataKey="spendByTag"
        xTickFormatter={xTickFormatter}
        xAxisOrientation="top"
        tooltipContent={<TooltipContent />}
      />
    );
  };

  return (
    <div className="w-full max-w-[540px]">
      <h4 className="font-bold ml-6 mb-6">{chartName}</h4>
      {isDemo ? (
        <div className="relative">
          <div className="font-semibold mb-6 max-w-80 text-center absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-30%] z-50">
            <h4 className="mb-6">
              You can add custom {chartName.toLowerCase()} tags to organize and track your spend
              better
            </h4>
            <Link className="underline" to={`/projects/${projectId}/settings?scrollTo=tags`}>
              Add tags here
            </Link>
          </div>
          <div className="opacity-30">
            <BarChartWithData />
          </div>
        </div>
      ) : (
        <BarChartWithData />
      )}
    </div>
  );
};

export default TagChart;

import { GasMeter } from 'components/atoms/GasMeter';
import moment from 'moment';
import {
  RewardTierName,
  centsToDollarDisplay,
  formatCentsToDollarsString,
  relativeDateText,
} from 'utils';
import RewardsPath from './RewardsPath';
import { Button } from 'components/atoms/Buttons';

const gradientFillMap = {
  Green: 'to-rewards-green',
  Gold: 'to-rewards-gold',
  Platinum: 'to-marble',
  Diamond: 'to-rewards-diamond',
  Elite: 'to-primary',
};

export type RewardsHeaderProps = {
  chevronRight: (tier: RewardTierName) => JSX.Element;
  currentTier: RewardTierName;
  deadlineDate: string;
  nextTier: RewardTierName | null;
  rewardsBadge: (tier: RewardTierName) => JSX.Element;
  nextTierValueCents: number;
  tiersCents: number[];
  totalSpentCents: number;
};

export const RewardsHeader = ({
  chevronRight,
  currentTier,
  deadlineDate,
  nextTier,
  nextTierValueCents,
  rewardsBadge,
  tiersCents,
  totalSpentCents,
}: RewardsHeaderProps) => {
  const dollarsLeftTillNextTier = formatCentsToDollarsString(nextTierValueCents - totalSpentCents);
  const nextTierValueDollars = formatCentsToDollarsString(nextTierValueCents);

  return (
    <div className="w-full p-2">
      <div className="w-[60%] float-left p-4">
        <div className="text-neutral-800 text-[26px] leading-[29.90px] pb-8 pt-4 flex">
          <h1>Zena {currentTier}</h1>
          <div className="mt-2 ml-2">{currentTier !== 'Green' && rewardsBadge(currentTier)}</div>
        </div>
        <div className="w-full mt-2 h-16">
          <div className="float-left w-1/2">
            You currently spent {centsToDollarDisplay(totalSpentCents)}
          </div>
        </div>
        <div className="w-full mt-2">
          <div className="mt-2 flex flex-col">
            {nextTier ? (
              <div className="w-full flex flex-row">
                <p className="text-sm float-left text-zinc-600 leading-normal text-[12px] flex-grow">
                  Spend <b>{dollarsLeftTillNextTier}</b> this year to unlock {nextTier} status
                </p>
                <p className="float-right leading-normal font-medium flex-shrink">
                  {nextTierValueDollars}
                </p>
              </div>
            ) : (
              <div className="w-full flex flex-row">
                <p>Congratulations, you are part of Zena's Elite tier!</p>
              </div>
            )}
            <GasMeter
              total={nextTierValueCents}
              used={nextTierValueCents - totalSpentCents}
              color={`bg-gradient-to-r from-transparent ${gradientFillMap[currentTier]}`}
              thickness={'h-2.5'}
            />
          </div>
          <div className="pt-4">
            <span className="text-sm">
              <div className="float-left">
                {nextTier && (
                  <p className="text-sm">
                    Qualify by {relativeDateText(moment(deadlineDate), false)}.{' '}
                  </p>
                )}
              </div>
              <p className="text-marble">
                Your status is based on the amount you spend in each year, starting based on your
                sign-up date. Your status will update instantly upon reaching the required tiered
                spend, and you’ll keep your status until the end of the following year.
              </p>
            </span>
          </div>
        </div>

        <div className="border-b border-secondary-400 pb-6" />
        <div className="w-full h-full flex flex-col py-6">
          <h4 className="font-bold text-lg">Rewards you actually want!</h4>
          <p className="text-lg w-full">
            Zena Rewards is our progressive, tier-based program that rewards you for using Zena for
            all client purchases and business spend.
          </p>
          <a
            href={'https://www.getzena.com/rewards'}
            target="_blank"
            rel="noreferrer"
            className={`space-x-1 my-6`}
          >
            <Button variant="tertiary">
              View rewards
              {chevronRight('Green' as RewardTierName)}
            </Button>
          </a>
        </div>
      </div>
      <div className="w-[40%] float-right flex items-center justify-center pt-4">
        <RewardsPath totalSpentCents={totalSpentCents} valuesCents={tiersCents} />
      </div>
    </div>
  );
};

import { ReactNode } from 'react';
import { companyRole } from 'hooks/access';
import { Link, useLocation } from 'react-router-dom';
import { useApplication } from 'hooks/useApplication';
import { TransactionsRequireAttentionNotification } from '../../components/widgets/TransactionsRequireAttentionNotification';
import { useActiveCompany } from 'providers/ActiveCompany';
import {
  Armchair,
  Home,
  Settings,
  CreditCard,
  Gift,
  FileText,
  ArrowRightLeft,
  LifeBuoy,
  Wallet,
  LineChart,
} from 'lucide-react';
import { useFlags, ZenaFeatureFlags } from '../FeatureFlags';

type SidebarItem = {
  title: string;
  path: string;
  icon: ReactNode;
  feature: keyof ZenaFeatureFlags | null;
  notification?: ReactNode;
};

const sidebarItems: Record<string, SidebarItem> = {
  home: {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Home size={22} />,
    feature: null,
  },
  projects: {
    title: 'Projects',
    path: '/projects',
    icon: <Armchair size={22} />,
    feature: null,
  },
  businessSpend: {
    title: 'Business',
    path: '/business-spend',
    icon: <LineChart size={22} />,
    feature: null,
  },
  cards: {
    title: 'Cards',
    path: '/cards-management',
    icon: <CreditCard size={22} />,
    feature: 'cardManagement',
  },
  transactions: {
    title: 'Transactions',
    path: '/transactions',
    icon: <ArrowRightLeft size={22} />,
    feature: null,
    notification: <TransactionsRequireAttentionNotification />,
  },
  settings: {
    title: 'Settings',
    path: '/settings',
    icon: <Settings size={22} />,
    feature: null,
  },
  statements: {
    title: 'Statements',
    path: '/statements',
    icon: <FileText size={22} />,
    feature: null,
  },
  payments: {
    title: 'Payments',
    path: '/payments',
    icon: <Wallet size={22} />,
    feature: null,
  },
  perks: {
    title: 'Rewards & Benefits',
    path: '/perks/rewards',
    icon: <Gift size={22} />,
    feature: null,
  },
};

const deniedApplicationMenu: SidebarItem[] = [
  sidebarItems.home,
  sidebarItems.projects,
  sidebarItems.transactions,
];
const sidebarData: SidebarItem[] = [
  sidebarItems.home,
  sidebarItems.projects,
  sidebarItems.businessSpend,
  sidebarItems.cards,
  sidebarItems.transactions,
];

const sidebarBottomAdmin: SidebarItem[] = [sidebarItems.settings];
const sidebarBottomMembers: SidebarItem[] = [];

const adminRoutes: SidebarItem[] = [
  sidebarItems.statements,
  sidebarItems.payments,
  sidebarItems.perks,
];

const bookkeeperRoutes: SidebarItem[] = [sidebarItems.statements, sidebarItems.payments];

export const Navbar = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const location = useLocation();
  const flags = useFlags();
  const { pathname } = location;
  const role = companyRole(activeCompanySlug);
  const { loading: loadingApplication, application } = useApplication();
  const applicationDenied = application?.status
    ? ['Denied', 'Canceled'].includes(application.status)
    : false;

  let sideBarMenuData: SidebarItem[];

  switch (role) {
    case 'admin': {
      sideBarMenuData = [...sidebarData, ...adminRoutes];
      break;
    }
    case 'bookkeeper': {
      sideBarMenuData = [...sidebarData, ...bookkeeperRoutes];
      break;
    }
    default: {
      sideBarMenuData = sidebarData;
      break;
    }
  }

  // if application denied
  if (applicationDenied) {
    sideBarMenuData = [...deniedApplicationMenu];
  }

  if (loadingApplication) {
    sideBarMenuData = [];
  }

  const sidebarBottom =
    role && ['admin', 'bookkeeper'].includes(role) ? sidebarBottomAdmin : sidebarBottomMembers;

  return (
    <nav className="bg-secondary-300 min-h-screen grid grid-cols-1 content-between">
      <ul className="mt-2 md:mt-10">
        {sideBarMenuData.map((item, index) => {
          const link = item.path;
          const isActive =
            pathname === link ||
            (link === '/' && pathname === '/dashboard') ||
            (link === '/cards' && pathname.includes('/cards/'));
          const isFeatureEnabled = item.feature ? flags[item.feature] : true;
          if (!isFeatureEnabled) {
            return null;
          }

          return (
            <li key={index} className="flex items-center h-12 px-2">
              <Link
                className={`text-black no-underline text-md px-4 mx-3 py-2 w-full rounded-xl hover:bg-secondary outline-accent-600 flex ${
                  isActive && 'bg-secondary'
                }`}
                to={link}
              >
                <div className="flex gap-3 items-start">
                  {item.icon}
                  <span className="flex-grow">{item.title}</span>
                  {item.notification && <div>{item.notification}</div>}
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className="mb-24">
        {sidebarBottom.map((item, index) => {
          const link = item.path;
          const isActive = pathname === link;
          return (
            <li key={index} className="flex items-center h-12 px-2">
              {item.path.startsWith('mailto:') ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={`text-black no-underline text-md px-4 py-2 w-full rounded-xl hover:bg-secondary outline-accent-600 flex ${
                    isActive ? 'bg-secondary' : ''
                  }`}
                  href={item.path}
                >
                  <span className="mr-3">{item.icon}</span>
                  <span>{item.title}</span>
                </a>
              ) : (
                <Link
                  className={`text-black no-underline text-md px-4 py-2 w-full rounded-xl hover:bg-secondary outline-accent-600 flex ${
                    isActive ? 'bg-secondary' : ''
                  }`}
                  to={link}
                >
                  <span className="mr-3">{item.icon}</span>
                  <span>{item.title}</span>
                </Link>
              )}
            </li>
          );
        })}
        <li className="px-2">
          <button
            className={`text-black no-underline text-md px-4 py-2 w-full rounded-xl hover:bg-secondary outline-accent-600 flex items-center`}
            // @ts-ignore
            onClick={() => window.Beacon('toggle')}
          >
            <div className="mr-3">
              <LifeBuoy />
            </div>
            <div>
              <span>Support</span>
            </div>
          </button>
        </li>
      </ul>
    </nav>
  );
};

import { useReducer } from 'react';
import { Maybe } from 'utils/types';

export interface ResetPasswordFormState {
  verificationCode: string;
  password: string;
}

export const defaultResetPasswordFormState: ResetPasswordFormState = {
  verificationCode: '',
  password: '',
};

// Actions
interface UpdateVerificationCode {
  type: 'UPDATE_VERIFICATION_CODE';
  payload: string;
}

interface UpdatePassword {
  type: 'UPDATE_PASSWORD';
  payload: string;
}

type ResetPasswordFormAction = UpdateVerificationCode | UpdatePassword;

const ResetPasswordFormReducer = (
  prevState: ResetPasswordFormState,
  action: ResetPasswordFormAction
): ResetPasswordFormState => {
  switch (action.type) {
    case 'UPDATE_VERIFICATION_CODE':
      return { ...prevState, verificationCode: action.payload };
    case 'UPDATE_PASSWORD':
      return { ...prevState, password: action.payload };
    default:
      return { ...prevState };
  }
};

export const useResetPasswordFormReducer = (defaults?: ResetPasswordFormState) => {
  return useReducer(ResetPasswordFormReducer, {
    ...defaultResetPasswordFormState,
    ...defaults,
  });
};

// Error Validation
interface ResetPasswordFormStateErrors {
  verificationCode: Maybe<string>;
  password: Maybe<string>;
  isValid: boolean;
}

export const validateResetPasswordFormState = (
  state: ResetPasswordFormState
): ResetPasswordFormStateErrors => {
  const errors: ResetPasswordFormStateErrors = {
    verificationCode: undefined,
    password: undefined,
    isValid: true,
  };

  if (!state.verificationCode) {
    errors.verificationCode = 'Code is required';
    errors.isValid = false;
  }

  if (!state.password) {
    errors.password = 'Password is required';
    errors.isValid = false;
  }

  if (!!state.password && state.password.length > 0 && state.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
    errors.isValid = false;
  }

  return errors;
};

import React from 'react';
import { cn } from 'utils';
import { Maybe } from 'utils/types';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'disabled'
  | 'ghost'
  | 'danger'
  | 'link';

export interface ButtonProps {
  text?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  variant?: ButtonVariant;
  disabled?: boolean;
  icon?: React.ReactNode;
  toolTipId?: string;
  children?: React.ReactNode;
  type?: Maybe<'submit' | 'reset' | 'button'>;
  active?: boolean;
  overrideDisabledStyled?: boolean;
  isLoading?: boolean;
  isIconLeft?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      text,
      toolTipId,
      icon,
      variant = 'primary',
      type = 'button',
      active,
      overrideDisabledStyled = false,
      isLoading = false,
      isIconLeft = true,
    }: ButtonProps,
    ref
  ) => {
    const buttonStyleBase: Record<ButtonVariant, string> = {
      primary: 'bg-primary-cta text-white border border-primary',
      secondary: 'bg-secondary-cta text-black border border-secondary',
      tertiary: 'bg-tertiary-cta text-black border border-tertiary-700',
      disabled: 'bg-primary-200 text-primary-500 border border-primary-200 cursor-not-allowed',
      ghost: 'bg-transparent',
      link: 'bg-transparent text-accent underline underline-offset-4 px-2',
      danger: 'bg-danger-cta text-white border border-danger-cta',
    };

    const buttonStyleHover: Record<ButtonVariant, string> = {
      primary: 'hover:bg-primary-700 hover:border-primary',
      secondary: 'hover:bg-secondary-600 hover:border-secondary',
      tertiary: 'hover:bg-tertiary-800',
      disabled: '',
      ghost: '',
      link: '',
      danger: 'hover:bg-danger-800 hover:border-danger-800',
    };

    // If the button is "active" disable hover-over mode
    let buttonStyle = active
      ? buttonStyleBase[variant]
      : `${buttonStyleBase[variant]} ${buttonStyleHover[variant]}`;

    // If disabled change the button style to disabled
    if (disabled && !overrideDisabledStyled) {
      buttonStyle = buttonStyleBase['disabled'];
    }

    return (
      <button
        className={cn(
          'px-6 py-2 rounded outline-accent-600 flex justify-center items-center flex-row gap-2',
          buttonStyle,
          className
        )}
        onClick={onClick}
        disabled={disabled || false}
        ref={ref}
        data-tooltip-id={toolTipId || ''}
        type={type}
      >
        {isLoading ? (
          <LoadingSpinner className={getLoaderStyle(variant)} />
        ) : (
          <>
            {isIconLeft ? icon : null}
            {text}
            {children}
            {isIconLeft ? null : icon}
          </>
        )}
      </button>
    );
  }
);

export const getLoaderStyle = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'border-t-lavender-600';
    case 'danger':
      return 'border-t-black';
    default:
      return '';
  }
};

export default Button;

export const hasRepaymentConnectionCompleteFromLocalStorage = () => {
  // get hasPlaidToken for legacy variable
  const value =
    localStorage.getItem('hasRepaymentConnection') || localStorage.getItem('hasPlaidToken');
  const hasPlaidToken = value ? JSON.parse(value) : false;
  return hasPlaidToken;
};

export const setRepaymentConnectionCompleteFromLocalStorage = (hasPlaidToken: boolean) => {
  const value = JSON.stringify(hasPlaidToken);
  localStorage.setItem('hasRepaymentConnection', value);
};

export const clearRepaymentConnectionCompleteFromLocalStorage = () => {
  localStorage.removeItem('hasRepaymentConnection');
};

export const hasPlaidConnectionCompleteFromLocalStorage = () => {
  // get hasPlaidToken for legacy variable
  const value = localStorage.getItem('hasPlaidConnection') || localStorage.getItem('hasPlaidToken');
  const hasPlaidToken = value ? JSON.parse(value) : false;
  return hasPlaidToken;
};

export const setHasPlaidConnectionCompleteFromLocalStorage = (hasPlaidToken: boolean) => {
  const value = JSON.stringify(hasPlaidToken);
  localStorage.setItem('hasPlaidConnection', value);
};

export const clearHasPlaidConnectionCompleteFromLocalStorage = () => {
  localStorage.removeItem('hasPlaidToken');
  localStorage.removeItem('hasPlaidConnection');
};

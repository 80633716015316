import { Transaction } from '__generated__/graphql';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageLimit } from 'components/atoms/Pagination';
import { TableHead } from 'components/atoms/table/TableHead';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { Pill } from 'components/atoms/Pill';
import { TransactionAssignedPill } from '../TransactionAssignedPill';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntuitAppConnectionStatus, useGetTransactionsNeedingAttention } from './data';
import { QboLogo } from 'icons/qboLogo';
import { useDebounce } from 'hooks/use-debounce';
import { Split } from 'lucide-react';
import { TableBody, TableBodyRenderProp } from '../TransactionsTableHelpers/TableBody';
import { TableContainer } from '../TransactionsTableHelpers/TableContainer';
import { TransactionRowSyncStatus } from '../TransactionsTableHelpers/TransactionTableRow/TransactionRowSyncStatus';
import { useTransactionFeedSyncSubscription } from '../TransactionsTable/data';
import {
  TransactionDetailsStateProvider,
  useTransactionDetailsContext,
} from 'components/widgets/TransactionDetailsDrawer/state';
import {
  ConfirmCloseTransactionDetailsDrawer,
  TransactionDetailsDrawerContent,
} from 'components/widgets/TransactionDetailsDrawer';
import { formatSimpleDate } from 'utils/date';
import { formatCentsToAbsDollarsString } from 'utils';

interface TransactionsTableParams {
  companySlug: string;
  overrides?: Object; // any filters that should remain static
  onlyZenaTransaction?: boolean;
}

const TransactionsNeedingAttentionTable = ({
  companySlug,
  overrides,
  onlyZenaTransaction = false,
}: TransactionsTableParams) => {
  const [pageLimit, setPageLimit] = useState<PageLimit>(30);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchParamTransactionId = searchParams.get('transactionId') || null;
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | null>(null);
  const [
    confirmLostTransactionDetailsTransactionId,
    setConfirmLostTransactionDetailsTransactionId,
  ] = useState<string | null>();
  const [showConfirmLostTransactionDetails, setShowConfirmLostTransactionDetails] =
    useState<boolean>();
  const [allTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  const { state: transactionDetailsState } = useTransactionDetailsContext();
  const { transactionDetailsDrawer2, transactionTableQboColumn } = useFlags();

  const { data, loading, fetchMore, error, refetch } = useGetTransactionsNeedingAttention({
    customerId: companySlug,
    pageNumber,
    pageSize: pageLimit,
    filters: {
      searchTerm: debouncedSearchTerm,
      ...overrides,
      onlyMissingData: true,
      onlyZenaTransaction,
    },
    excludeChildren: transactionDetailsDrawer2,
    onCompleted: (data) => {
      if (data) {
        if (data.getAuthorizationsAndTransactions) {
          const transactionData = data.getAuthorizationsAndTransactions.data;
          setAllTransactions(transactionData);
        }
      }
    },
  });

  const loadNextPage = (pageNumber: number) => {
    setPageNumber(pageNumber);
    fetchMore({
      variables: { pageNumber },
    });
  };

  useEffect(() => {
    if (pageNumber !== 0) {
      setAllTransactions([]);
      setPageNumber(0);
    }
  }, [pageNumber, setPageNumber]);

  const { isIntuitAppConnectedAndActive } = useIntuitAppConnectionStatus({
    companySlug,
  });

  useEffect(() => {
    const transaction = allTransactions.find(
      (transaction) => transaction.id === urlSearchParamTransactionId
    );
    if (transaction) {
      setSelectedTransactionId(transaction.id);
    }
  }, [allTransactions, urlSearchParamTransactionId]);

  const authorizationsAndTransactions = allTransactions || [];
  const meta = data?.getAuthorizationsAndTransactions?.meta!;

  useTransactionFeedSyncSubscription({
    companySlug,
    onData: () => {
      refetch();
    },
  });

  const isLoading = loading && !authorizationsAndTransactions.length;

  const showQboColumn = !!isIntuitAppConnectedAndActive && transactionTableQboColumn;

  return (
    <>
      <TableContainer
        loading={loading}
        showFilters={false}
        showPagination={true}
        showSearch
        meta={meta}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
        data={authorizationsAndTransactions}
        errorMessage={error?.message}
        overrides={overrides}
        currentPageIndex={pageNumber}
        onNext={() => loadNextPage(meta.pageNumber + 1)}
        onPrevious={() => loadNextPage(meta.pageNumber - 1)}
        totalPages={Math.ceil((meta?.total ?? 0) / pageLimit) || 1}
        onChangeSearchTerm={(newSearchTerm) => setSearchTerm(newSearchTerm)}
      >
        <TableWithPagination loading={isLoading} meta={meta}>
          <TableHead className="font-normal text-black">
            <td>Date</td>
            <td>Merchant</td>
            {showQboColumn && (
              <td className="flex gap-4">
                <QboLogo />
                QBO Sync
              </td>
            )}
            <td className="hidden lg:table-cell">Assigned</td>
            <td className="hidden lg:table-cell">Source</td>
            <td>Amount</td>
            <td className="hidden lg:table-cell">Missing Info</td>
          </TableHead>
          <tbody>
            <TableBody
              loading={isLoading}
              data={authorizationsAndTransactions}
              errorMessage={error?.message}
              overrides={overrides}
              gridCols={7}
              qboActive={showQboColumn}
              selectedTransactionId={selectedTransactionId ?? ''}
              onClickTransactionRow={(transactionId) => {
                if (transactionDetailsState.hasChangesToSave) {
                  setShowConfirmLostTransactionDetails(true);
                  setConfirmLostTransactionDetailsTransactionId(transactionId);
                } else if (transactionId && transactionId === selectedTransactionId) {
                  setSelectedTransactionId(null);
                } else {
                  if (transactionId) {
                    searchParams.set('transactionId', transactionId);
                  }

                  setSearchParams(searchParams);

                  setSelectedTransactionId(transactionId);
                }
              }}
            >
              {(props) => <TableRow {...props} />}
            </TableBody>
          </tbody>
        </TableWithPagination>
      </TableContainer>

      <TransactionDetailsDrawerContent
        transactionId={selectedTransactionId ?? ''}
        isVisible={!!selectedTransactionId}
        onClose={() => {
          if (transactionDetailsState.hasChangesToSave) {
            setShowConfirmLostTransactionDetails(true);
            setConfirmLostTransactionDetailsTransactionId(null);
          } else {
            searchParams.delete('transactionId');
            setSearchParams(searchParams);

            setSelectedTransactionId(null);
          }
        }}
        showMissingRequirements
      />

      {showConfirmLostTransactionDetails && (
        <ConfirmCloseTransactionDetailsDrawer
          onCancel={() => {
            setShowConfirmLostTransactionDetails(false);
            setConfirmLostTransactionDetailsTransactionId(null);
          }}
          onConfirmDangerously={() => {
            setShowConfirmLostTransactionDetails(false);
            if (confirmLostTransactionDetailsTransactionId) {
              searchParams.set('transactionId', confirmLostTransactionDetailsTransactionId);
            }

            setSearchParams(searchParams);

            setSelectedTransactionId(
              confirmLostTransactionDetailsTransactionId &&
                confirmLostTransactionDetailsTransactionId !== selectedTransactionId
                ? confirmLostTransactionDetailsTransactionId
                : null
            );
            setConfirmLostTransactionDetailsTransactionId(null);
          }}
        />
      )}
    </>
  );
};

const TableRow = ({
  row,
  isAuthorization,
  isIncome,
  missingFields,
  childTransactionIds,
  qboActive,
}: TableBodyRenderProp<Transaction>) => {
  const { transactionDetailsDrawer2 } = useFlags();

  return (
    <>
      {/* date column */}
      <td className="text-primary-700">{formatSimpleDate(new Date(row.date))}</td>

      {/* merchant column */}
      <td>
        <span className={`${!isAuthorization ? 'text-black' : ''}`}>{row.shortName}</span>
        {isAuthorization && <Pill variant="pending" className="inline-block ml-3" text="Pending" />}
      </td>

      {/* qbo sync column */}
      {qboActive && (
        <td>
          <TransactionRowSyncStatus
            isImported={row.type === 'IMPORTED'}
            syncStatus={row.accountingSyncStatus || undefined}
            syncedAt={row.qboAccountingIntegrationSync?.syncedAt}
            expenseId={row.qboAccountingIntegrationSync?.expenseId}
          />
        </td>
      )}

      {/* assigned column */}
      <td className="hidden lg:table-cell text-primary-700">
        {transactionDetailsDrawer2 && childTransactionIds.length ? (
          <div className="flex gap-3">
            <Split />
            {childTransactionIds.length} split transactions
          </div>
        ) : (
          // TODO - update logic to determine type when more types are available (today 'not related' gets filtered out and there are no business expense categories)
          <TransactionAssignedPill
            assignedCategoryType={row.projectName ? 'project' : 'business'}
            categoryName={row.projectName || 'Business expense'}
          />
        )}
      </td>

      {/* source column */}
      <td className="hidden lg:table-cell text-black">
        {row?.card ? row?.card.name || '' : row?.bank?.name || ''}
      </td>

      {/* amount column */}
      <td className="max-w-[80px] relative font-medium text-primary-700">
        {(isIncome || row.zenaType === 'return') && (
          <span className="absolute top-0 -ml-4 text-base font-bold flex items-center h-full text-forest-900">
            +
          </span>
        )}
        <span>{formatCentsToAbsDollarsString(row.amountWithDirection)}</span>
      </td>

      {/* missing info column */}
      <td className="hidden lg:table-cell capitalize text-black">
        {missingFields(row).join(', ')}
      </td>
    </>
  );
};

const TransactionsNeedingAttention = ({
  projectId,
  cardId,
  ...props
}: TransactionsTableParams & { cardId?: string; projectId?: string }) => {
  return (
    <TransactionDetailsStateProvider>
      <TransactionsNeedingAttentionTable {...props} />
    </TransactionDetailsStateProvider>
  );
};

export default TransactionsNeedingAttention;

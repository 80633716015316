import { gql } from '__generated__/gql';
import { useAppState } from '../../../../../stores/UserStore';
import { ApolloError, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { nonNullable, onApolloError } from '../../../../../utils';
import { IntegrationAccount } from '__generated__/graphql';
import { useState } from 'react';
import { ComboboxOption } from 'components/atoms/Comboboxes';

export const GET_ALL_PROJECT_SPEND_CATEGORIES = gql(`
  query GetAllProjectSpendCategories($companySlug: String!) {
      getAllProjectSpendCategories(companySlug: $companySlug) {
          name
    }
  }
`);
export interface SpendCategoryTag {
  name: string;
}
export const useGetAllProjectSpendCategories = (
  companySlug: string
): { tags: SpendCategoryTag[]; loading: boolean; error: ApolloError | undefined } => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data, error } = useQuery(GET_ALL_PROJECT_SPEND_CATEGORIES, {
    variables: {
      companySlug,
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['ProjectNotFound']);
    },
  });

  const tags =
    data?.getAllProjectSpendCategories?.map((tag: SpendCategoryTag) => {
      return tag;
    }) || [];

  return {
    tags,
    loading,
    error,
  };
};

export const GET_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAGS = gql(`
  query GetAccountingCategoryProjectCategoryTags($accountingCategoryId: String!) {
      getAccountingCategoryProjectCategoryTags(accountingCategoryId: $accountingCategoryId) {
          id
          projectCategoryTagName
          integrationAccount {
              accountId
              accountName
          }
    }
  }
`);

export const useGetAccountingCategoryProjectCategoryTags = ({
  accountingCategoryId,
}: {
  accountingCategoryId: string;
}): {
  pairedTags: ComboboxOption[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data, error } = useQuery(GET_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAGS, {
    variables: {
      accountingCategoryId,
    },
    skip: !accountingCategoryId,
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['ProjectNotFound']);
    },
  });

  return {
    pairedTags:
      data?.getAccountingCategoryProjectCategoryTags &&
      data.getAccountingCategoryProjectCategoryTags.length > 0
        ? data.getAccountingCategoryProjectCategoryTags
            .map((tag) => ({
              label: tag.projectCategoryTagName || '',
              value: tag.id || '',
            }))
            .filter(nonNullable)
        : [],
    loading,
    error,
  };
};

export const LIST_QBO_EXPENSE_ACCOUNTS = gql(`
  query ListQBOExpenseAccounts($companySlug: String!) {
      listQBOExpenseAccounts(companySlug: $companySlug) {
          accountId
          accountName
    }
  }
`);
export const useListQBOExpenseAccounts = (
  companySlug: string
): { data: IntegrationAccount[]; loading: boolean; error: ApolloError | undefined } => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data, error } = useQuery(LIST_QBO_EXPENSE_ACCOUNTS, {
    variables: {
      companySlug,
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['ProjectNotFound']);
    },
  });

  return {
    data: data?.listQBOExpenseAccounts || [],
    loading,
    error,
  };
};

export const CREATE_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAG = gql(`
  mutation CreateAccountingCategoryProjectCategoryTag(
  $companySlug: String!
  $accountingCategoryId: String!
  $accountingCategoryName: String!
  $projectCategoryTagName: String!
  ) {
      createAccountingCategoryProjectCategoryTag(
          companySlug: $companySlug
          accountingCategoryId: $accountingCategoryId
          accountingCategoryName: $accountingCategoryName
          projectCategoryTagName: $projectCategoryTagName
      ) {
          id
          projectCategoryTagName
          integrationAccount {
              accountId
              accountName
          }
      }
  }
`);

export const DELETE_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAG = gql(`
  mutation DeleteAccountingCategoryProjectCategoryTag($accountingCategoryProjectCategoryTagId: ID!) {
      deleteAccountingCategoryProjectCategoryTag(
          accountingCategoryProjectCategoryTagId: $accountingCategoryProjectCategoryTagId
      ) {
          id
          projectCategoryTagName
          integrationAccount {
            accountId
            accountName
          }
      }
  }
`);

export const useUpdateAccountingTagPairing = ({
  accountingCategoryId,
}: {
  accountingCategoryId: string;
}) => {
  const client = useApolloClient();
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [loading, setLoading] = useState(false);
  const [create] = useMutation(CREATE_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAG, {
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['']);
    },
    update: (cache, { data }) => {
      if (data?.createAccountingCategoryProjectCategoryTag) {
        client.writeQuery({
          query: GET_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAGS,
          variables: {
            accountingCategoryId,
          },
          data: {
            getAccountingCategoryProjectCategoryTags:
              data.createAccountingCategoryProjectCategoryTag,
          },
        });
      }
    },
    onCompleted: () => {
      setLoading(false);
    },
  });
  const [remove] = useMutation(DELETE_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAG, {
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['']);
    },
    update: (cache, { data }) => {
      if (data?.deleteAccountingCategoryProjectCategoryTag) {
        client.writeQuery({
          query: GET_ACCOUNTING_CATEGORY_PROJECT_CATEGORY_TAGS,
          variables: {
            accountingCategoryId,
          },
          data: {
            getAccountingCategoryProjectCategoryTags:
              data.deleteAccountingCategoryProjectCategoryTag,
          },
        });
      }
    },
    onCompleted: (data) => {
      setLoading(false);
    },
  });

  const createWrapper: typeof create = async (options) => {
    setLoading(true);
    return create(options);
  };

  const removeWrapper: typeof remove = async (options) => {
    setLoading(true);
    return remove(options);
  };

  return {
    create: createWrapper,
    remove: removeWrapper,
    loading,
  };
};

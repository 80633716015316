import { useApolloClient } from '@apollo/client';

export const ApolloClient = () => {
  const apolloClient = useApolloClient();

  return apolloClient;
};

export const clearApolloCache = (apolloClient = ApolloClient()) => {
  apolloClient.clearStore();
};

import { Select } from 'components/atoms/Inputs/Select';
import { Maybe } from 'utils/types';
import { useProjects } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { RectangleSkeleton } from 'components/atoms/Skeletons';

export const SelectProject = ({
  projectId,
  onChange,
  className = '',
  placeholder = 'Select a project',
}: {
  projectId: Maybe<string>;
  onChange: (projectId: string) => void;
  className?: string;
  placeholder?: string;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { projects, loading } = useProjects({ companySlug: activeCompanySlug });

  if (loading) {
    return <RectangleSkeleton width="full" height="large" />;
  }

  return (
    <Select
      value={projectId ?? ''}
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      className={className}
    >
      <option key="select_project_placeholder" value="">
        {placeholder}
      </option>
      {projects.map((project) => (
        <option key={project.id} value={project.id}>
          {project.name}
        </option>
      ))}
    </Select>
  );
};

import { Link, useNavigate } from 'react-router-dom';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { PasswordInput } from 'components/atoms/Inputs/PasswordInput';
import { Field } from 'components/atoms/Inputs/Field';
import { EmailInput } from 'components/atoms/Inputs/EmailInput';
import { LoginFormState, useLoginFormReducer, validateLoginFormState } from './LoginFormState';
import { useMemo, useState } from 'react';

export const LoginForm = ({
  onSubmit,
  isLoadingSubmission,
}: {
  onSubmit: (values: LoginFormState) => void;
  isLoadingSubmission: boolean;
}) => {
  const navigate = useNavigate();
  const [state, dispatch] = useLoginFormReducer();
  const [showFormErrors, setShowFormErrors] = useState(false);
  const formValidation = useMemo(() => {
    return validateLoginFormState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{ ...state }]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (formValidation.isValid) {
          onSubmit({
            ...state,
          });
        } else {
          setShowFormErrors(true);
        }
      }}
      className="grid gap-8"
    >
      <div className="mb-4 grid gap-4">
        <Field label="Email" errorMessage={showFormErrors ? formValidation.email : undefined}>
          <EmailInput
            value={state.email}
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_EMAIL',
                payload: e.currentTarget.value,
              });
            }}
            inputState={showFormErrors && formValidation.email ? 'error' : 'normal'}
          />
        </Field>
        <Field label="Password" errorMessage={showFormErrors ? formValidation.password : undefined}>
          <PasswordInput
            value={state.password}
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_PASSWORD',
                payload: e.currentTarget.value,
              });
            }}
            inputState={showFormErrors && formValidation.password ? 'error' : 'normal'}
          />
        </Field>
        <div className="flex justify-end">
          <UnderlineButton
            className="underline text-muted text-sm py-0 px-1"
            onClick={(e) => {
              e.preventDefault();
              navigate('/forgot-password');
            }}
            type="button"
          >
            Forgot password?
          </UnderlineButton>
        </div>
      </div>
      <div className="grid gap-6">
        <RoundButton text="Login" variant="primary" disabled={isLoadingSubmission} type="submit" />
      </div>
    </form>
  );
};

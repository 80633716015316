import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const QUERY_PROJECT_SETTINGS = gql(`
  query GetProjectForProjectSettings($companySlug: String!, $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      status
      address {
        street
        street2
        city
        state
        postalCode
      }
      name
      budget
      description
      tags {
        name
        type
      }
      clientInfo {
        firstName
        lastName
        email
        phone
      }
    }
  }
`);

export const useGetProjectSettings = ({
  companySlug,
  projectId,
}: {
  companySlug?: string;
  projectId?: string;
}) => {
  const { loading, data, error } = useQuery(QUERY_PROJECT_SETTINGS, {
    variables: {
      companySlug: companySlug || '',
      projectId: projectId || '',
    },
    skip: !projectId || !companySlug,
  });

  return {
    project: data?.getProject,
    loading,
    error,
  };
};

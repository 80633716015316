import { useState } from 'react';
import { Modal } from 'components/atoms/Modal';
import { AddAccountOverview } from './AddAccountOverview';
import { ConnectThroughPlaid } from './ConnectThroughPlaid';
import { LoadTransactions } from './LoadTransactions';
import { AllocateTransactionsModal } from 'components/widgets/AllocateTransactions/AllocateTransactionsModal';

interface AddAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  disableAllocateTransactionsModal?: boolean;
}

const AddAccountModal = ({
  isOpen,
  onClose,
  disableAllocateTransactionsModal = false,
}: AddAccountModalProps) => {
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2 | 3>(0);
  const [plaidDetails, setPlaidDetails] = useState({ publicToken: '', accountId: '' });

  const handleClose = () => {
    onClose();
    //delay reset so user doesn't see screen flash
    setTimeout(() => {
      setCurrentStep(0);
    }, 500);
  };

  const steps = [
    <AddAccountOverview setCurrentStep={setCurrentStep} />,
    <ConnectThroughPlaid setCurrentStep={setCurrentStep} setPlaidDetails={setPlaidDetails} />,
    <LoadTransactions
      setCurrentStep={setCurrentStep}
      onClose={() => {
        if (disableAllocateTransactionsModal) {
          onClose();
        } else {
          setCurrentStep(3);
        }
      }}
      plaidDetails={plaidDetails}
    />,
  ];

  if (currentStep === 3 && !disableAllocateTransactionsModal) {
    return <AllocateTransactionsModal isOpen onClose={handleClose} />;
  }

  return (
    <Modal show={isOpen} onClose={handleClose}>
      {steps[currentStep]}
    </Modal>
  );
};

export default AddAccountModal;

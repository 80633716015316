import { CsvExportType, Transaction, TransactionFiltersInput } from '__generated__/graphql';
import { useState } from 'react';
import { PageLimit } from 'components/atoms/Pagination';
import {
  TransactionsTableState,
  useTransactionsTableUrlQueryPersistence,
  useTransactionTableReducer,
} from './state';
import { useDebounce } from 'hooks/use-debounce';
import { formatGraphqlDate } from 'utils/date';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetAuthorizationsAndTransactions, useTransactionFeedSyncSubscription } from './data';
import useCustomAuth from 'hooks/useCustomAuth';
import { TransactionDownload } from '../TransactionDownload';
import { TableContainer } from '../TransactionsTableHelpers/TableContainer';
import {
  TransactionDetailsStateProvider,
  useTransactionDetailsContext,
} from 'components/widgets/TransactionDetailsDrawer/state';
import {
  ConfirmCloseTransactionDetailsDrawer,
  TransactionDetailsDrawerContent,
} from 'components/widgets/TransactionDetailsDrawer';

interface TransactionsTableParams {
  companySlug: string;
  projectId?: string;
  overrides?: Partial<TransactionFiltersInput>; // any filters that should remain static
  showSearch?: boolean;
  showFilters?: boolean;
  excludeChildren?: boolean;
  showDownloadDrawer?: boolean;
  onCloseDownloadDrawer?: () => void;
  disableFiltersUrlQueryPersistence?: boolean;
  showSplitTransactionsOnDownload?: boolean;
  csvExportType?: CsvExportType;
}

const Table = ({
  companySlug,
  projectId,
  overrides,
  showSearch = true,
  showFilters = true,
  excludeChildren = true,
  showDownloadDrawer = false,
  onCloseDownloadDrawer,
  disableFiltersUrlQueryPersistence = false,
  showSplitTransactionsOnDownload,
  csvExportType,
}: TransactionsTableParams) => {
  const { user } = useCustomAuth();
  const [state, dispatch] = useTransactionTableReducer();
  const debouncedState: TransactionsTableState = useDebounce(state, 150);
  useTransactionsTableUrlQueryPersistence({
    state: { ...debouncedState },
    dispatch,
    disabled: disableFiltersUrlQueryPersistence,
  });
  const [allAuthorizationsAndTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const { state: transactionDetailsState, dispatch: dispatchTransactionDetails } =
    useTransactionDetailsContext();

  const { transactionDetailsDrawer2 } = useFlags();

  const fromDate = debouncedState.dropdownFilters.dateRange?.[0]
    ? formatGraphqlDate(debouncedState.dropdownFilters.dateRange[0])
    : undefined;
  const toDate = debouncedState.dropdownFilters.dateRange?.[1]
    ? formatGraphqlDate(debouncedState.dropdownFilters.dateRange[1])
    : undefined;

  const filters = {
    searchTerm: debouncedState.searchTerm,
    toDate,
    fromDate,
    ...overrides,
    zena: {
      transactionAssignments: debouncedState.dropdownFilters.assignmentFilters,
      zenaTransactionTypes: debouncedState.dropdownFilters.transactionTypeFilters,
      ...overrides?.zena,
    },
  };

  const {
    data: queryResult,
    loading,
    fetchMore,
    error,
    refetch,
  } = useGetAuthorizationsAndTransactions({
    customerId: companySlug,
    pageNumber: debouncedState.pagination.pageNumber,
    pageSize: debouncedState.pagination.pageLimit,
    excludeChildren: excludeChildren && transactionDetailsDrawer2,
    filters,
    onCompleted: (data) => {
      if (data?.getAuthorizationsAndTransactions) {
        const transactionData = data.getAuthorizationsAndTransactions.data;

        setAllTransactions(transactionData);

        if (data.getAuthorizationsAndTransactions?.meta) {
          dispatch({
            type: 'UPDATE_PAGINATION',
            payload: {
              ...state.pagination,
              totalPages: Math.ceil(
                data.getAuthorizationsAndTransactions.meta.total / state.pagination.pageLimit
              ),
            },
          });
        }
      }
    },
  });

  useTransactionFeedSyncSubscription({
    companySlug,
    onData: () => {
      refetch();
    },
  });

  const loadNextPage = (pageNumber: number) => {
    dispatch({
      type: 'UPDATE_PAGINATION',
      payload: { ...state.pagination, pageNumber: pageNumber },
    });
    fetchMore({
      variables: { pageNumber },
    });
  };

  // TODO - on the table refactor, this should be removed. ! in TS almost always causes issues
  const meta = queryResult?.getAuthorizationsAndTransactions?.meta!;
  const authorizationsAndTransactions = allAuthorizationsAndTransactions || [];

  return (
    <>
      <TableContainer
        loading={loading && !authorizationsAndTransactions.length}
        showFilters={showFilters}
        showPagination
        showSearch={showSearch}
        meta={meta}
        pageLimit={state.pagination.pageLimit}
        setPageLimit={(newPageLimit: PageLimit) => {
          dispatch({
            type: 'UPDATE_PAGINATION',
            payload: { ...state.pagination, pageNumber: 0, pageLimit: newPageLimit },
          });
        }}
        data={authorizationsAndTransactions}
        errorMessage={error?.message}
        overrides={overrides}
        selectedTransactionId={state.detailedViewTransactionId}
        onClickTransactionRow={(transactionId) => {
          if (transactionDetailsState.hasChangesToSave) {
            dispatch({
              type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES',
              payload: transactionId,
            });
          } else {
            dispatch({
              type: 'UPDATE_DETAILED_VIEW_TRANSACTION_ID',
              payload:
                transactionId !== state.detailedViewTransactionId ? transactionId : undefined,
            });
          }
        }}
        currentPageIndex={state.pagination.pageNumber}
        onNext={() => loadNextPage(state.pagination.pageNumber + 1)}
        onPrevious={() => loadNextPage(state.pagination.pageNumber - 1)}
        totalPages={debouncedState.pagination.totalPages || 1}
        showTransactionFiltersDropdown
        onChangeFilters={(filters) => {
          dispatch({
            type: 'UPDATE_DROPDOWN_FILTERS',
            payload: { ...filters },
          });
        }}
        onChangeSearchTerm={(value) => {
          dispatch({
            type: 'UPDATE_SEARCH_TERM',
            payload: value,
          });
        }}
      />

      <TransactionDownload
        allowSplitTransactions={showSplitTransactionsOnDownload}
        email={user?.attributes.email ?? ''}
        projectId={projectId}
        visible={showDownloadDrawer}
        onClose={() => onCloseDownloadDrawer?.()}
        transactionCount={queryResult?.getAuthorizationsAndTransactions?.meta?.total ?? 0}
        filters={filters}
        csvExportType={csvExportType}
      />

      <TransactionDetailsDrawerContent
        transactionId={state.detailedViewTransactionId ?? ''}
        isVisible={!!state.detailedViewTransactionId}
        onClose={() => {
          if (transactionDetailsState.hasChangesToSave) {
            dispatch({
              type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES',
              payload: null,
            });
          } else {
            dispatch({
              type: 'UPDATE_DETAILED_VIEW_TRANSACTION_ID',
              payload: undefined,
            });
            dispatchTransactionDetails({
              type: 'CLEAR_TRANSACTION_DETAILS_STATE',
            });
          }
        }}
        onUpdateTransaction={() => refetch()}
      />

      {state.showConfirmLostTransactionDetails && (
        <ConfirmCloseTransactionDetailsDrawer
          onCancel={() =>
            dispatch({
              type: 'CANCEL_CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES',
            })
          }
          onConfirmDangerously={() => {
            dispatch({
              type: 'CONFIRM_LOSE_TRANSACTION_DETAILS_CHANGES',
            });
          }}
        />
      )}
    </>
  );
};

export const TransactionsTable = ({ ...props }: TransactionsTableParams) => {
  return (
    <TransactionDetailsStateProvider>
      <Table {...props} />
    </TransactionDetailsStateProvider>
  );
};

import { Transaction } from '__generated__/graphql';
import { TransactionReceiptImage } from 'components/atoms/TransactionReceiptImage';
import { TransactionReceiptUploader } from './TransactionReceiptUploader';

const Receipt = ({ customerId, model }: { customerId: string; model: Transaction }) => {
  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-asphalt text-2xl">
          Attach a copy of the receipt or invoice <span className="text-marble">(optional)</span>
        </h1>
      </div>
      <div className="mt-6">
        <TransactionReceiptUploader model={model} customerId={customerId} displayStaticTooltip />
        {model.receipt && (
          <div className="mt-1">
            <TransactionReceiptImage transactionReceipt={model.receipt} />
          </div>
        )}
      </div>
    </>
  );
};

export default Receipt;

import { useAppState } from '../../../stores/UserStore';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { useEffect, useState } from 'react';
import {
  useGetIntuitAppConnection,
  useLazyQueryListQBOAccounts,
  useUpdateIntuitAppAccountId,
} from 'components/widgets/Quickbooks';
import { onApolloError } from '../../../utils';
import { RoundButton } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const ChangeQBOLinkedAccount = () => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);

  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';

  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [selectedAccountId] = useState<string | undefined | null>(null);
  const [potentialZenaAccountId, setPotentialZenaAccountNameId] = useState<string | null>();
  const [potentialZenaAccountName, setPotentialZenaAccountName] = useState<string | null>();

  const {
    listQBOAccounts,
    qboAccounts,
    loading: listAccountsLoading,
    client,
  } = useLazyQueryListQBOAccounts({ companySlug });
  const { updateIntuitAppAccountId, loading: updateAccountIdLoading } =
    useUpdateIntuitAppAccountId();

  const { appConnection } = useGetIntuitAppConnection({
    companySlug,
    onError: (error) => setErrorMsg(error.message),
  });

  useEffect(() => {
    if (accountModalOpen || appConnection?.qboAccountId) {
      listQBOAccounts({
        onCompleted: (res) => {
          const accounts = res?.listQBOLiabilityAccounts ?? [];
          const zenaAccount = accounts.find((account) =>
            account.accountName.toLowerCase().includes('zena')
          );
          if (zenaAccount) {
            setPotentialZenaAccountNameId(zenaAccount.accountId);
            setPotentialZenaAccountName(zenaAccount.accountName);
          }
        },
        onError: (error) => {
          client.refetchQueries({ include: ['GetIntuitAppConnection'] });
          onApolloError(
            error,
            (errorMessage: string, errorType: string) => {
              if (errorType === 'TokenRefreshError') {
                setErrorMsg(
                  'Your Quickbooks connection has become invalid. Please visit settings to reconnect.'
                );
              } else {
                setErrorMsg(errorMessage);
              }
            },
            ['TokenRefreshError']
          );
        },
      });
    }
  }, [accountModalOpen, appConnection?.qboAccountId]);

  const handleSelectAccount = (qboAccountId: string) => {
    updateIntuitAppAccountId({
      variables: { companySlug, qboAccountId },
      refetchQueries: ['GetIntuitAppConnection'],
      onError: (error) => setErrorMsg(error.message),
    });
    setAccountModalOpen(false);
  };

  const handleViewOtherAccounts = () => {
    setPotentialZenaAccountNameId(null);
    setPotentialZenaAccountName(null);
  };
  return (
    <>
      <RoundButton
        variant="tertiary"
        disabled={!appConnection}
        onClick={() => setAccountModalOpen(true)}
      >
        Change account
      </RoundButton>
      <Modal
        show={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        className="max-h-1/2"
      >
        <div className="overflow-auto max-h-96">
          {listAccountsLoading || updateAccountIdLoading ? (
            <div className="h-96 w-96 flex justify-center items-center">
              <LoadingSpinner />
            </div>
          ) : potentialZenaAccountId && !selectedAccountId ? (
            <div>
              <h3 className="text-center">Match Found!</h3>
              <p className="text-center text-gray-500">
                We found a Zena account in your QuickBooks Online account
              </p>
              <p className="text-xl w-full text-center mt-8">{potentialZenaAccountName}</p>
              <div className="flex w-full justify-center gap-4 mt-12">
                <RoundButton onClick={() => handleSelectAccount(potentialZenaAccountId || '')}>
                  Confirm
                </RoundButton>
                <RoundButton variant="secondary" onClick={handleViewOtherAccounts}>
                  View other accounts
                </RoundButton>
              </div>
            </div>
          ) : (
            <ul>
              {qboAccounts.map((account) => {
                return (
                  <li key={account.accountId} className="flex items-center border-b">
                    <div className="text-left">{account.accountName}</div>
                    <div className="ml-auto flex items-center">
                      <div className="p-2 sm:p-12">
                        <RoundButton
                          variant="primary"
                          onClick={() => handleSelectAccount(account.accountId)}
                        >
                          Select
                        </RoundButton>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </Modal>
    </>
  );
};

import { useActiveCompany } from 'providers/ActiveCompany';
import { useCardTransactionDetails } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { ReactNode, useState } from 'react';
import { RelativeDate } from 'components/atoms/RelativeDate';

export const CardTransactionDetails = ({ transactionId }: { transactionId: string }) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { transaction, loading } = useCardTransactionDetails({ transactionId, activeCompanySlug });
  const [forceExpandedContentOpen, setForceExpandedContentOpen] = useState(false);

  if (loading) {
    return <RectangleSkeleton className="h-[85px]" width="full" />;
  }

  return (
    <OutlinedWidget className="p-5 rounded-lg">
      <OutlinedWidget.Collapsable
        openExpandedContent={forceExpandedContentOpen}
        onClose={() => {
          setForceExpandedContentOpen(false);
        }}
      >
        {({ isOpen }) => (
          <>
            <div
              className="cursor-pointer grid items-center pt-1"
              onClick={() => {
                setForceExpandedContentOpen(!forceExpandedContentOpen);
              }}
            >
              Card transaction details
            </div>

            <OutlinedWidget.ExpandedContent isOpen={isOpen}>
              <div className="pt-8 mt-7 border-t border-primary-400 grid gap-8">
                {transaction?.user && (
                  <DetailsContentRow>
                    <SmallTitle>User</SmallTitle>
                    <div>{`${transaction.user.fullName.first} ${transaction.user.fullName.last}`}</div>
                  </DetailsContentRow>
                )}

                {!!transaction?.card?.name && (
                  <DetailsContentRow>
                    <SmallTitle>Card</SmallTitle>
                    <div>{transaction.card.name}</div>
                  </DetailsContentRow>
                )}

                {transaction?.date && (
                  <DetailsContentRow>
                    <SmallTitle>Posted</SmallTitle>
                    <RelativeDate date={transaction.date} />
                  </DetailsContentRow>
                )}

                {transaction?.authorizationCreatedAt && (
                  <DetailsContentRow>
                    <SmallTitle>Purchased</SmallTitle>
                    <RelativeDate date={transaction.authorizationCreatedAt} />
                  </DetailsContentRow>
                )}
              </div>
            </OutlinedWidget.ExpandedContent>
          </>
        )}
      </OutlinedWidget.Collapsable>
    </OutlinedWidget>
  );
};

const DetailsContentRow = ({ children }: { children: ReactNode }) => {
  return <div className="grid grid-cols-[125px_1fr] gap-4 items-start">{children}</div>;
};

const SmallTitle = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-1 text-sm">{children}</div>;
};

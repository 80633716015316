import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { toDollars } from 'utils';
import { formatGraphqlDate } from 'utils/date';

const SPEND_BREAKDOWN_QUERY = gql(`
  query GetTransactionsForLuxeSpendBreakdownWidget(
    $companySlug: String!
    $fromDate: String!
  ) {
    getSpendBreakdown(
      companySlug: $companySlug
      fromDate: $fromDate
    ) {
      totalAmountCents
      totalProjectAmountCents
      totalOtherAmountCents
    }
  }
`);

export const useSpendBreakdownCategoryTotals = ({
  fromDate,
  companySlug,
}: {
  fromDate: Date;
  companySlug: string;
}) => {
  const { data, loading, error } = useQuery(SPEND_BREAKDOWN_QUERY, {
    variables: {
      companySlug,
      fromDate: formatGraphqlDate(fromDate),
    },
    skip: !fromDate || !companySlug,
  });

  return {
    categorizedData: {
      totalAmount: data?.getSpendBreakdown ? toDollars(data.getSpendBreakdown.totalAmountCents) : 0,
      totalProjectAmount: data?.getSpendBreakdown
        ? toDollars(data.getSpendBreakdown.totalProjectAmountCents)
        : 0,
      totalOtherAmount: data?.getSpendBreakdown
        ? toDollars(data.getSpendBreakdown.totalOtherAmountCents)
        : 0,
    },
    loading,
    error,
  };
};

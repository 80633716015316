import { Disclosure as HeadlessDisclosure } from '@headlessui/react';
import React, { HTMLAttributes, ReactNode } from 'react';
import { cn } from '../../../utils';
import { DisclosureProps as HeadlessDisclosureProps } from '@headlessui/react/dist/components/disclosure/disclosure';
import { ChevronDown } from 'lucide-react';

interface DisclosureProps extends HeadlessDisclosureProps {
  children: ReactNode;
}
export const Disclosure = ({ children, ...props }: DisclosureProps) => {
  return <HeadlessDisclosure {...props}>{children}</HeadlessDisclosure>;
};

interface DisclosureTitleProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const Title = ({ children, ...props }: DisclosureTitleProps) => {
  return (
    <HeadlessDisclosure.Button className="flex w-full justify-between items-center focus:outline-none">
      <div {...props}>{children}</div>
    </HeadlessDisclosure.Button>
  );
};
const TitleWithChevron = ({ children, className, ...props }: DisclosureTitleProps) => {
  return (
    <HeadlessDisclosure.Button className="w-full">
      <div
        className={cn('flex w-full justify-between items-center focus:outline-none', className)}
        {...props}
      >
        {children}
        <ChevronDown className="ui-open:rotate-180 ui-open:transform fill-black transition duration-250" />
      </div>
    </HeadlessDisclosure.Button>
  );
};
Disclosure.Title = Title;
Disclosure.TitleWithChevron = TitleWithChevron;

interface DisclosureBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}
const Body = ({ children, ...props }: DisclosureBodyProps) => {
  return <HeadlessDisclosure.Panel {...props}>{children}</HeadlessDisclosure.Panel>;
};
Disclosure.Body = Body;

import { default as ReactCalendar } from 'react-calendar';
import './calendar.css';
import { useState } from 'react';
import { Value } from 'react-calendar/src/shared/types';

export type DateValue = Value;

export const Calendar = ({
  initialValue,
  onChange,
  maxDate,
}: {
  initialValue?: DateValue;
  onChange?: (value: DateValue) => void;
  maxDate?: Date;
}) => {
  const [date, setDate] = useState<DateValue>(initialValue ?? null);
  return (
    <ReactCalendar
      value={date}
      onChange={(newValue, event) => {
        setDate(newValue);
        onChange && onChange(newValue);
      }}
      maxDate={maxDate}
    />
  );
};

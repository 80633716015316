interface DisplayedProjectDetailsProps {
  name: string;
  description: string;
}

const DisplayedProjectDetails = ({ name, description }: DisplayedProjectDetailsProps) => {
  return (
    <div className="flex flex-wrap">
      <div>
        <div className="min-w-fit sm:pr-6">
          <label className="font-bold text-base">Project name</label>
          <div>{name}</div>
        </div>
      </div>
      <div className="flex-1 sm:border-l sm:border-feather sm:pl-6">
        <label className="font-bold text-base">Project description</label>
        <div>{description}</div>
      </div>
    </div>
  );
};

export default DisplayedProjectDetails;

import { ReactElement } from 'react';
import { formatCentsToDollarsString } from 'utils';
import { RewardsProgressBar } from '../../../components/atoms/RewardsProgressBar';

type RewardsPathProps = {
  totalSpentCents: number;
  valuesCents: number[];
};

const RewardsPath = ({ totalSpentCents, valuesCents }: RewardsPathProps): ReactElement => {
  const format = (value: number) => formatCentsToDollarsString(value);
  return (
    <div className="flex flex-col h-full border border-secondary-400 rounded-xl py-6 justify-center items-center w-2/3">
      <div className="w-2/3 font-medium py-4">
        <h4>Earn points and level up to receive higher tier rewards</h4>
      </div>
      <div className="flex flex-1 justify-center">
        <div className="relative float-left mr-2 pb-7">
          <RewardsProgressBar totalSpentCents={totalSpentCents} valuesCents={valuesCents} />
        </div>
        <div className="float-right w-full flex flex-col justify-between space-y-6">
          <div className="h-1/5">
            <h5 className="">Elite</h5>
            <p className="text-sm text-asphalt">Spend {format(valuesCents[3])}</p>
          </div>
          <div className="h-1/5">
            <h5 className="">Diamond</h5>
            <p className="text-sm text-asphalt">Spend {format(valuesCents[2])}</p>
          </div>
          <div className="h-1/5">
            <h5 className="">Platinum</h5>
            <p className="text-sm text-asphalt">Spend {format(valuesCents[1])}</p>
          </div>
          <div className="h-1/5">
            <h5 className="">Gold</h5>
            <p className="text-sm text-asphalt">Spend {format(valuesCents[0])}</p>
          </div>
          <div className="h-1/5">
            <h5 className="">Green</h5>
            <p className="text-sm text-asphalt">Spend {format(0)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsPath;

import { RoundButton } from './Buttons';
import CopyControl from './CopyControl';

const Discount = ({
  discountTitle,
  discountAbout,
  discountCopy,
  discountHref,
  discountCode,
  iconSrc,
  iconAlt,
}: {
  discountTitle: string;
  discountAbout: JSX.Element;
  discountCopy: JSX.Element;
  discountHref: string;
  discountCode: string;
  iconSrc: string;
  iconAlt: string;
}) => {
  return (
    <div className="py-8 border-b-stone-200 my-[30px] last:border-none">
      <div className="grid grid-cols-1 xl:grid-cols-3 xl:space-x-3 xl:space-y-0 space-y-5">
        <div className="flex">
          <img src={iconSrc} alt={iconAlt} className="w-[80px] h-[80px] grow-0" />
          <div className="ml-6 pt-1">
            <h3 className="mb-1">{discountTitle}</h3>
            <div className="text-neutral-800 text-base leading-tight">{discountCopy}</div>
          </div>
        </div>
        <div>
          <div className="text-neutral-800 text-base leading-tight">{discountAbout}</div>
        </div>
        <div className="grow-0 relative">
          <CopyControl
            fullLengthLabel={discountCode}
            label={discountCode}
            id={discountTitle}
            className="mb-2 w-full"
          />
          <a href={discountHref} target="_blank" rel="noreferrer" className="text-center block">
            <RoundButton variant="primary" text="Shop now" className="h-10 !py-0 w-[220px]" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Discount;

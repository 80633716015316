import { useState } from 'react';
import { Pill } from '../Pill';

type BoxSelectProps = {
  options: { value: string; title: string; body?: string; fineprint?: string }[];
  onSelect: (option: any) => void;
};

const BoxSelect = ({ options, onSelect }: BoxSelectProps) => {
  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div>
      {options.map((option, index) => (
        <div
          key={option.value}
          className={`flex flex-col justify-center items-start rounded-3xl p-6 ${
            selectedOption === index
              ? 'bg-lavender-400'
              : ' bg-secondary-400 hover:bg-secondary-600'
          } border-solid cursor-pointer mb-4`}
          onClick={() => {
            setSelectedOption(index);
            onSelect(option.value);
          }}
        >
          <div className="w-full flex justify-between mb-4">
            <h2 className="text-2xl">{option.title}</h2>
            {selectedOption === index && (
              <div>
                <Pill text="Selected" variant="selected" />
              </div>
            )}
          </div>
          <p className="text-asphalt">{option.body}</p>
          {option.fineprint && <p className="mt-4">{option.fineprint}</p>}
        </div>
      ))}
    </div>
  );
};

export default BoxSelect;

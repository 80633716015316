import { RoundButton } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import { ZenaCard } from 'components/atoms/ZenaCard';
import zenaLogo from '../../../../images/zenaLogo.webp';
import { Link } from 'react-router-dom';

interface ProjectCreatedModalProps {
  isOpen: boolean;
  onClose: (connectAccounts?: boolean) => void;
  cardId?: string;
}

const ProjectCreatedModal = ({ isOpen, onClose, cardId }: ProjectCreatedModalProps) => {
  const AccountConnectedInfo = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <h4 className="font-semibold">You're all set!</h4>
        <p className="text-center mt-4 max-w-60">
          We created a unique virtual card just for this project.
        </p>
        <div className="mt-14">{cardId && <ZenaCard cardId={cardId} showCardOnly />}</div>
        <div className="my-14">
          <RoundButton onClick={() => onClose(false)}>Let's go!</RoundButton>
        </div>
        <img src={zenaLogo} alt="Zena Logo" className="h-6" />
      </div>
    );
  };

  const AccountNotConnectedInfo = () => {
    return (
      <>
        <h3 className="mb-4">Great job!</h3>
        <div className="flex flex-col items-center justify-center">
          <p className="text-center mt-4 max-w-60">
            All you have left to do is add transactions to your project.
          </p>
          <div className="rounded-xl bg-slate-50 text-center my-5 py-6 sm:px-16 flex flex-col items-center justify-center">
            <h4 className="font-bold mb-1.5">Get a Zena project card</h4>
            <p className="text-neutral-600 mb-5">
              All spend on the project card will be assigned to this project - automatically.
            </p>
            <Link to="/what-to-expect" className="flex justify-center">
              <RoundButton>Apply now</RoundButton>
            </Link>
          </div>
          <div className="rounded-xl bg-slate-50 text-center mt-5 py-6 sm:px-16 flex flex-col items-center justify-center mb-14">
            <h4 className="font-bold mb-1.5">Connect your business accounts</h4>
            <p className="text-neutral-600 mb-5">
              Grant Zena read-only access to track and display income and spend in this project.{' '}
            </p>
            <RoundButton onClick={() => onClose(true)}>Connect my accounts</RoundButton>
          </div>
          <img src={zenaLogo} alt="Zena Logo" className="mt-5 h-6" />
        </div>
      </>
    );
  };

  return (
    <Modal show={isOpen} onClose={() => onClose(false)}>
      {cardId ? <AccountConnectedInfo /> : <AccountNotConnectedInfo />}
    </Modal>
  );
};

export default ProjectCreatedModal;

import { AssignContainer, AssignHeaderContainer } from '../lib';
import { RoundButton } from 'components/atoms/Buttons';
import { useTransactionDetailsContext } from '../../state';
import {
  useChildTransactionDetails,
  useIntuitAppConnectionStatus,
  useIsAccountingTagRequired,
} from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { TransactionSplitDetails } from '../TransactionSplitDetails';
import { formatCentsToDollarsString } from 'utils';
import { Eye } from 'lucide-react';

export const AssignChildTransaction = ({
  showMissingRequirements,
}: {
  showMissingRequirements: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { state, dispatch } = useTransactionDetailsContext();

  const { transaction, loading } = useChildTransactionDetails({
    transactionId: state.transactionId,
    activeCompanySlug,
    onCompleted: (data) => {
      if (data.getTransaction) {
        dispatch({
          type: 'POPULATE_TAGS_AND_SPLITS',
          payload: {
            accountingTag: data.getTransaction.accountingTag
              ? {
                  id: data.getTransaction.accountingTag.value ?? '',
                  name: data.getTransaction.accountingTag.tagName,
                }
              : null,
            rooms: data.getTransaction.rooms,
            categories: data.getTransaction.categories,
            invoiceNumber: data.getTransaction.poCodeTag?.value ?? '',
            projectId: data.getTransaction.projectId ?? '',
          },
        });
      }
    },
  });

  const { isAccountingTagRequired, loading: companySettingsLoading } = useIsAccountingTagRequired({
    companySlug: activeCompanySlug,
  });

  const { isIntuitAppConnectedAndActive } = useIntuitAppConnectionStatus({
    companySlug: activeCompanySlug,
  });

  if (loading || !transaction || companySettingsLoading) {
    return (
      <AssignContainer>
        <AssignHeaderContainer>
          <RectangleSkeleton height="large" width="medium" />
        </AssignHeaderContainer>
        <RectangleSkeleton className="h-[300px]" width="full" />
      </AssignContainer>
    );
  }

  const isMissingAccountingTag =
    isIntuitAppConnectedAndActive && !transaction.accountingTag?.tagName;
  // TODO => use this instead when BE is ready
  // && transaction.missingFields?.accountingTag

  return (
    <AssignContainer>
      <AssignHeaderContainer>
        {transaction.parentTransactionId && (
          <RoundButton
            variant="secondary"
            onClick={() => {
              dispatch({
                type: 'UPDATE_TRANSACTION_ID',
                payload: transaction.parentTransactionId ?? '',
              });
            }}
          >
            View parent transaction <Eye />
          </RoundButton>
        )}
      </AssignHeaderContainer>
      <TransactionSplitDetails
        splitIndex={1}
        formattedAmount={formatCentsToDollarsString(transaction.amountWithDirection)}
        projectTitle={transaction.projectName ?? 'N/A'}
        projectCategories={state.categories}
        projectRooms={state.rooms}
        invoiceNumber={state.invoiceNumber ?? ''}
        status={isMissingAccountingTag && isAccountingTagRequired ? 'NEEDS_ATTENTION' : 'DONE'}
        hasActiveQboConnection={isIntuitAppConnectedAndActive}
        isAccountingTagRequiredForQbo={isAccountingTagRequired}
        onChangeAccountingTag={(tag) => {
          dispatch({
            type: 'UPDATE_ACCOUNTING_TAG',
            payload: tag
              ? {
                  id: tag.accountingTagId,
                  name: tag.accountingTagName,
                }
              : null,
          });
        }}
        selectedAccountingTagName={state.accountingTag?.name ?? ''}
        isWidgetOpenByDefault
        showMissingRequirements={showMissingRequirements}
      />
    </AssignContainer>
  );
};

import { useActiveCompany } from 'providers/ActiveCompany';
import { ProjectLayout } from '../ProjectLayout';
import Overview from './Overview';
import { useGetProjectOverview } from './data';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { AddAccountModal } from 'components/widgets/AddAccountModal';
import { ProjectCreatedModal } from './ProjectCreatedModal';

export const ProjectOverviewPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const isNewProject = searchParams.get('new') === 'true';
  const [showOnboarding, setShowOnboarding] = useState<boolean>(isNewProject);
  const [showConnectAccountsModal, setShowConnectAccountsModal] = useState(false);

  const { project, totalSpent, loading } = useGetProjectOverview({
    projectId,
    companySlug: activeCompanySlug,
  });

  const handleProjectCreatedClose = (connectAccounts?: boolean) => {
    setShowOnboarding(false);
    if (connectAccounts) {
      setShowConnectAccountsModal(true);
    }
  };

  return (
    <>
      <ProjectLayout pageName="OVERVIEW">
        {project && (
          <Overview
            totalSpent={totalSpent}
            projectId={project?.id ?? ''}
            budget={project?.budget ?? undefined}
            description={project?.description ?? ''}
            cardId={project?.cardId ?? ''}
            address={project?.address ?? undefined}
            tags={project?.tags ?? []}
          />
        )}

        {(loading || !project || !projectId) && (
          <div className="pt-6 flex justify-center">
            <LoadingSpinner />
          </div>
        )}
      </ProjectLayout>

      {project && (
        <AddAccountModal
          isOpen={showConnectAccountsModal}
          onClose={() => setShowConnectAccountsModal(false)}
        />
      )}

      {project && (
        <ProjectCreatedModal
          isOpen={showOnboarding}
          onClose={(connectAcconuts) => handleProjectCreatedClose(connectAcconuts)}
          cardId={project.cardId || ''}
        />
      )}
    </>
  );
};

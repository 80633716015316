import { useActiveCompany } from 'providers/ActiveCompany';
import { useTransactionHeaderDetails } from './data';
import { ReactNode } from 'react';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { Button } from 'components/atoms/Buttons';
import { formatSimpleDate, formatTime } from 'utils/date';
import { formatCentsToDollarsString } from 'utils';
import { X } from 'lucide-react';

export const TransactionDetailsHeader = ({
  transactionId,
  onClose,
}: {
  transactionId: string;
  onClose: () => void;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { transaction, loading } = useTransactionHeaderDetails({
    transactionId,
    activeCompanySlug,
  });

  if (loading || !transaction) {
    return (
      <Container>
        <TitleContainer>
          <TitleRow>
            <RectangleSkeleton height="large" width="full" />
            <CloseButton onClick={onClose} />
          </TitleRow>
          <SubDetailsRow>
            <RectangleSkeleton height="small" width="small" />
            <RectangleSkeleton height="small" width="small" />
          </SubDetailsRow>
        </TitleContainer>
        <AmountRow>
          <div>Amount</div>
          <RectangleSkeleton height="small" width="small" />
        </AmountRow>
      </Container>
    );
  }

  const date = new Date(transaction.date);

  return (
    <Container>
      <TitleContainer>
        <TitleRow>
          <h2 className="text-3xl truncate">{transaction.shortName}</h2>
          <CloseButton onClick={onClose} />
        </TitleRow>
        <SubDetailsRow>
          <p className="text-primary-700 truncate tracking-wide">{`${formatSimpleDate(
            date
          )} at ${formatTime(date)}`}</p>
          {transaction.card && (
            <p className="text-primary-700 truncate tracking-wide">
              {transaction.card.name ?? transaction.card.last4Digits}
            </p>
          )}
        </SubDetailsRow>
      </TitleContainer>
      <AmountRow>
        <div>
          <div>Amount</div>
          {transaction.parentTransaction && (
            <div className="text-sm text-muted">{`split from original ${formatCentsToDollarsString(
              transaction.parentTransaction.amountWithDirection
            )}`}</div>
          )}
        </div>
        <div>{formatCentsToDollarsString(transaction.amountWithDirection)}</div>
      </AmountRow>
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 max-w-full min-w-0">{children}</div>;
};

const TitleContainer = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 border-b border-primary pb-4 min-w-0">{children}</div>;
};

const TitleRow = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between items-start gap-4 min-w-0">{children}</div>;
};

const SubDetailsRow = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between gap-4 min-w-0 tracking-wide">{children}</div>;
};

const AmountRow = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-between py-4 font-semibold gap-4 border-b border-primary-400 tracking-wide items-center">
      {children}
    </div>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="ghost" className="p-0" onClick={onClick}>
      <X />
    </Button>
  );
};

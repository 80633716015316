import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY_GET_APPLICATION_FORM = gql(`
  query GetUnitApplicationForm($companySlug: String!){
    getUnitApplicationForm(companySlug: $companySlug){
      companyName
      firstName
      lastName
    }
  }
`);

export const useGetApplicationForm = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY_GET_APPLICATION_FORM, {
    variables: { companySlug },
  });

  return {
    unitApplication: data?.getUnitApplicationForm || { companyName: '' },
    loading,
    error,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const TRANSACTION_DETAILS_DISPLAY_LOGIC_QUERY = gql(`
  query GetTransactionDetailsDisplayLogicData($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      amountWithDirection
      spendCategory
      zenaType
      parentTransaction {
        id
        amountWithDirection
      }
      childTransactions {
        id
      }
    }
  }
`);

export const useTransactionDetails = ({
  transactionId,
  activeCompanySlug,
}: {
  transactionId: string;
  activeCompanySlug: string;
}) => {
  const { data, loading, error } = useQuery(TRANSACTION_DETAILS_DISPLAY_LOGIC_QUERY, {
    variables: {
      transactionId,
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || !transactionId,
  });

  return { transaction: data?.getTransaction, loading, error };
};

const GET_INTUIT_APP_CONNECTION_STATUS_QUERY = gql(`
  query GetIntuitAppConnectionForTransactionDetailsDrawer($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
    }
  }
`);

export const useIntuitAppConnectionStatus = ({ companySlug }: { companySlug: string }) => {
  const { data } = useQuery(GET_INTUIT_APP_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const isIntuitAppConnectedAndActive =
    data?.getIntuitAppConnection?.id &&
    data.getIntuitAppConnection.qboAccountId &&
    data.getIntuitAppConnection.startedAt;

  return { isIntuitAppConnectedAndActive };
};

export const MUTATION_UPDATE_CHILD_TRANSACTION = gql(`
  mutation UpdateChildTransactionTagsTransactionDetails($companySlug: String!, $childTransactionId: String!, $transactionMetadata: TransactionMetadataInput!) {
    updateChildTransactionMetadata(companySlug: $companySlug, childTransactionId: $childTransactionId, transactionMetadata: $transactionMetadata) {
      id
      shortName
      amountWithDirection
      parentTransactionId
      amountWithDirection
      shortName
      projectName
      accountingTag {
        tagName
        value
      }
      rooms
      categories
      categoryTags {
        tagName
        value
      }
      poCodeTag {
        tagName
        value
      }
      memo
      missingFields {
        memo
        receipt
        roomTag
        categoryTag
      }
      childTransactions {
        id
        parentTransactionId
        amountWithDirection
        shortName
        projectName
        projectId
        accountingTag {
          tagName
          value
        }
        rooms
        categories
        poCodeTag {
          tagName
          value
        }
        missingFields {
          categoryTag
        }
      }
    }
  }
`);

export const MUTATION_UPDATE_STANDARD_TRANSACTION = gql(`
  mutation UpdateTransactionFromTransactionDetailsDrawer($companySlug: String!, $transactionId: String!, $transactionMetadata: TransactionMetadataInput!, $childTransactions: [ChildTransactionInput!]) {
    updateTransaction(companySlug: $companySlug, transactionId: $transactionId, transactionMetadata: $transactionMetadata, childTransactions: $childTransactions) {
      id
      shortName
      amountWithDirection
      parentTransactionId
      amountWithDirection
      shortName
      projectName
      accountingSyncStatus
      receipt {
        imageUrl
      }
      accountingTag {
        tagName
        value
      }
      rooms
      categories
      poCodeTag {
        tagName
        value
      }
      memo
      missingFields {
        memo
        receipt
        roomTag
        categoryTag
      }
      childTransactions {
        id
        parentTransactionId
        amountWithDirection
        shortName
        projectName
        projectId
        accountingTag {
          tagName
          value
        }
        rooms
        categories
        poCodeTag {
          tagName
          value
        }
        missingFields {
          categoryTag
        }
      }
    }
  }
`);

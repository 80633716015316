import { TransactionsTable } from 'components/widgets/transaction-tables/TransactionsTable';
import { ProjectLayout } from '../ProjectLayout';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { AllocateTransactionsModal } from 'components/widgets/AllocateTransactions/AllocateTransactionsModal';
import { CsvExportType } from '__generated__/graphql';

export const ProjectTransactionsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { projectId } = useParams();

  const [showAllocateTransactionsModal, setShowAllocateTransactionsModal] = useState(false);
  const [showDownloadDrawer, setShowDownloadDrawer] = useState(false);

  return (
    <>
      <ProjectLayout
        pageName="TRANSACTIONS"
        onClickDownload={() => setShowDownloadDrawer(true)}
        onClickImportTransactions={() => setShowAllocateTransactionsModal(true)}
      >
        <div className="pt-6">
          <TransactionsTable
            companySlug={activeCompanySlug}
            showSearch={false}
            showFilters={false}
            excludeChildren={false}
            overrides={{
              zena: {
                projectId,
              },
            }}
            projectId={projectId}
            showDownloadDrawer={showDownloadDrawer}
            onCloseDownloadDrawer={() => setShowDownloadDrawer(false)}
            csvExportType={CsvExportType.projects}
          />
        </div>
      </ProjectLayout>

      <AllocateTransactionsModal
        isOpen={showAllocateTransactionsModal}
        onClose={() => setShowAllocateTransactionsModal(false)}
      />
    </>
  );
};

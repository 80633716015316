import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY_PROJECT_REVIEW = gql(`
  query GetProjectForProjectReview($companySlug: String!, $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      budget
      users
      name
    }
  }
`);

const GET_PROJECT_AUTHORIZED_USERS = gql(`
  query GetProjectTeamMembersForProjectReview($companySlug: String!) {
    getCustomerTeamMembers(customerId: $companySlug) {
      userId
      fullName {
        first
        last
      }
      email
    }
  }
`);

export const useGetProjectReview = ({
  companySlug,
  projectId,
}: {
  companySlug?: string;
  projectId?: string;
}) => {
  const { loading, data, error } = useQuery(QUERY_PROJECT_REVIEW, {
    variables: {
      companySlug: companySlug || '',
      projectId: projectId || '',
    },
    skip: !projectId || !companySlug,
  });

  return {
    project: data?.getProject,
    loading,
    error,
  };
};

export const useAuthorizedUsers = ({ companySlug }: { companySlug?: string }) => {
  const { loading, data, error } = useQuery(GET_PROJECT_AUTHORIZED_USERS, {
    variables: {
      companySlug: companySlug || '',
    },
    skip: !companySlug,
  });

  return {
    authorizedUsers: data?.getCustomerTeamMembers ?? [],
    loading,
    error,
  };
};

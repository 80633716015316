import { PageTitle } from 'components/atoms/PageTitle';
import { TransactionsPageLayout } from '../TransactionsLayout';
import { cn } from 'utils';
import { Table } from 'components/atoms/table/Table';
import { TableHead } from 'components/atoms/table/TableHead';
import { useCsvExportUrl, useCsvExports } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { formatDateWithTime } from 'utils/date';
import { Link } from 'react-router-dom';
import { RoundButton } from 'components/atoms/Buttons';
import { TableRow } from 'components/atoms/table/TableRow';
import { useSearchParams } from 'react-router-dom';
import { Check } from 'lucide-react';

export const ExportTransactionsPage = () => {
  return (
    <>
      <PageTitle hidden text="Transaction exports" />
      <TransactionsPageLayout pageName="EXPORT_TRANSACTIONS" title="Transaction exports">
        <div
          className={cn(
            'flex flex-col h-full overflow-hidden pb-4 pt-8',
            'lg:h-auto lg:overflow-visible'
          )}
        >
          <Table>
            <TableHead>
              <td>Date</td>
              <td className="hidden md:table-cell">Source</td>
              <td className="hidden sm:table-cell">Include splits</td>
              <td className="hidden sm:table-cell">Include imported transactions</td>
              <td>Status</td>
              <td></td>
            </TableHead>
            <tbody>
              <Body />
            </tbody>
          </Table>
        </div>
      </TransactionsPageLayout>
    </>
  );
};

const Body = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { csvExports, loading } = useCsvExports({
    companySlug: activeCompanySlug,
  });

  // Download file if id provided in url param
  const [searchParams] = useSearchParams();
  const csvExportId = searchParams.get('id') ?? '';
  const { csvExportUrl } = useCsvExportUrl({
    companySlug: activeCompanySlug,
    csvExportId,
  });
  if (csvExportUrl) {
    window.location.href = csvExportUrl;
  }

  if (loading) {
    return (
      <tr>
        <td className="text-asphalt text-center" colSpan={5}>
          <em>Loading transactions...</em>
        </td>
      </tr>
    );
  }

  if (csvExports.length === 0) {
    return (
      <tr>
        <td className="text-asphalt text-center" colSpan={5}>
          <em>No files created...</em>
        </td>
      </tr>
    );
  }

  return (
    <>
      {csvExports.map((csvExport) => (
        <TableRow key={csvExport.id}>
          <td>{formatDateWithTime(new Date(csvExport.dateTime))}</td>
          <td className="hidden md:table-cell">
            {csvExport.type === 'transactions'
              ? 'All Transactions'
              : // Defaulting to 'Project' for downloads that were created prior to us saving that data
                // 'Project' as default should be removed in the future
                csvExport.projectName ?? 'Project'}
          </td>
          <td className="hidden sm:table-cell">{csvExport.includeChildren && <Check />}</td>
          <td className="hidden sm:table-cell">
            {csvExport.includeImportedTransactions && <Check />}
          </td>
          <td className="capitalize">{csvExport.status}</td>
          <td>
            {csvExport.url && (
              <Link to={csvExport.url}>
                <RoundButton>Download</RoundButton>
              </Link>
            )}
          </td>
        </TableRow>
      ))}
    </>
  );
};

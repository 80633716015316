import { PageLimit } from 'components/atoms/Pagination';
import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Maybe } from 'utils/types';
import { useDebouncedCallback } from 'use-debounce';
import deepEquals from 'fast-deep-equal';
import { LastDays } from 'components/atoms/LastDaysDropdown';
import { ZenaSuggestedAssignment } from '__generated__/graphql';

export interface AllocateTransactionsState {
  selectedTransactionIds: string[];
  assignedCategories: Record<string, Category>;
  showBulkAssignDropdown: boolean;
  pagination: Pagination;
  alertErrorMessage: Maybe<string>;
  searchFilters: SearchFilters;
  isViewingTransactionDetailsTransactionId: Maybe<string>;
  showConfirmLoseTransactionDetails: boolean;
  confirmLoseTransactionDetailsNextTransactionId: string | null;
}

interface SearchFilters {
  searchTerm: string;
  lastDays: LastDays;
  systemSuggestion: Maybe<ZenaSuggestedAssignment>;
}

type CategoryType = 'PROJECT' | 'NA';

interface Category {
  type: CategoryType;
  id: string;
}

interface Pagination {
  pageLimit: PageLimit;
  pageOffset: number;
  totalPages: number;
}

export const defaultAllocateTransactionState: AllocateTransactionsState = {
  selectedTransactionIds: [],
  assignedCategories: {},
  showBulkAssignDropdown: false,
  pagination: {
    pageLimit: 30,
    pageOffset: 0,
    totalPages: 0,
  },
  alertErrorMessage: undefined,
  searchFilters: {
    searchTerm: '',
    lastDays: 7,
    systemSuggestion: undefined,
  },
  isViewingTransactionDetailsTransactionId: '',
  showConfirmLoseTransactionDetails: false,
  confirmLoseTransactionDetailsNextTransactionId: null,
};

// ACTIONS
interface UpdateSelectedTransactionIds {
  type: 'UPDATE_SELECTED_TRANSACTION_IDS';
  payload: string[];
}

interface UpdateAssignedProjects {
  type: 'UPDATE_ASSIGNED_CATEGORIES';
  payload: Record<string, Category>;
}

interface BulkAssignProject {
  type: 'BULK_ASSIGN_PROJECT';
  payload: string;
}

interface BulkAssignNa {
  type: 'BULK_ASSIGN_NA';
}

interface UpdateShowBulkAssignDropdown {
  type: 'UPDATE_SHOW_BULK_ASSIGN_DROPDOWN';
  payload: boolean;
}

interface UpdatePagination {
  type: 'UPDATE_PAGINATION';
  payload: Pagination;
}

interface ConfirmedTransaction {
  type: 'CONFIRMED_TRANSACTION';
  payload: string;
}

interface ConfirmedSelectedTransactions {
  type: 'CONFIRMED_SELECTED_TRANSACTIONS';
}

interface UpdateAlertErrorMessage {
  type: 'UPDATE_ALERT_ERROR_MESSAGE';
  payload: Maybe<string>;
}

interface UpdateSearchTerm {
  type: 'UPDATE_SEARCH_TERM';
  payload: string;
}

interface UpdateLastDays {
  type: 'UPDATE_LAST_DAYS';
  payload: LastDays;
}

interface UpdateSystemSuggestion {
  type: 'UPDATE_SYSTEM_SUGGESTION';
  payload: ZenaSuggestedAssignment | undefined;
}

interface UpdateIsViewingTransactionDetailsTransactionId {
  type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID';
  payload: Maybe<string>;
}

interface UpdateSearchFromUrl {
  type: 'UPDATED_URL_SEARCH_PARAMETERS';
  payload: SerializedState;
}

interface ShowConfirmLoseTransactionDetails {
  type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS';
  nextTransactionId?: string;
}

interface CancelLoseTransactionDetails {
  type: 'CANCEL_LOSE_TRANSACTION_DETAILS';
}

type AllocationTransactionsStateAction =
  | UpdateSelectedTransactionIds
  | UpdateAssignedProjects
  | BulkAssignProject
  | UpdateShowBulkAssignDropdown
  | UpdatePagination
  | BulkAssignNa
  | ConfirmedTransaction
  | ConfirmedSelectedTransactions
  | UpdateAlertErrorMessage
  | UpdateSearchFromUrl
  | UpdateSearchTerm
  | UpdateLastDays
  | UpdateSystemSuggestion
  | UpdateIsViewingTransactionDetailsTransactionId
  | ShowConfirmLoseTransactionDetails
  | CancelLoseTransactionDetails;

const AllocationTransactionsReducer = (
  prevState: AllocateTransactionsState,
  action: AllocationTransactionsStateAction
): AllocateTransactionsState => {
  switch (action.type) {
    case 'UPDATE_SELECTED_TRANSACTION_IDS':
      return { ...prevState, selectedTransactionIds: action.payload, alertErrorMessage: undefined };
    case 'UPDATE_ASSIGNED_CATEGORIES':
      return { ...prevState, assignedCategories: action.payload, alertErrorMessage: undefined };
    case 'BULK_ASSIGN_PROJECT':
      const bulkAssignedCategories = prevState.selectedTransactionIds.reduce(
        (acc: Record<string, Category>, selectedTransactionId) => {
          return {
            ...acc,
            [selectedTransactionId]: {
              type: 'PROJECT' as CategoryType,
              id: action.payload,
            },
          };
        },
        {}
      );

      return {
        ...prevState,
        assignedCategories: { ...prevState.assignedCategories, ...bulkAssignedCategories },
        alertErrorMessage: undefined,
      };
    case 'BULK_ASSIGN_NA':
      const bulkdAssignedToDismiss = prevState.selectedTransactionIds.reduce(
        (acc: Record<string, Category>, selectedTransactionId) => {
          return {
            ...acc,
            [selectedTransactionId]: {
              type: 'NA' as CategoryType,
              id: 'NA',
            },
          };
        },
        {}
      );

      return {
        ...prevState,
        assignedCategories: { ...prevState.assignedCategories, ...bulkdAssignedToDismiss },
        alertErrorMessage: undefined,
      };
    case 'UPDATE_SHOW_BULK_ASSIGN_DROPDOWN':
      return { ...prevState, showBulkAssignDropdown: action.payload, alertErrorMessage: undefined };
    case 'UPDATE_PAGINATION':
      return { ...prevState, pagination: action.payload };
    case 'CONFIRMED_TRANSACTION':
      const newAssignedCategories = { ...prevState.assignedCategories };
      delete newAssignedCategories[action.payload];
      return {
        ...prevState,
        selectedTransactionIds: prevState.selectedTransactionIds.filter(
          (transactionId) => transactionId !== action.payload
        ),
        assignedCategories: newAssignedCategories,
        alertErrorMessage: undefined,
        isViewingTransactionDetailsTransactionId:
          prevState.isViewingTransactionDetailsTransactionId === action.payload
            ? undefined
            : prevState.isViewingTransactionDetailsTransactionId,
      };
    case 'CONFIRMED_SELECTED_TRANSACTIONS':
      const assignedCagoriesCopy = { ...prevState.assignedCategories };
      prevState.selectedTransactionIds.forEach((transactionId) => {
        delete assignedCagoriesCopy[transactionId];
      });
      return {
        ...prevState,
        selectedTransactionIds: [],
        assignedCategories: assignedCagoriesCopy,
        pagination: {
          ...prevState.pagination,
          pageOffset: 0,
        },
        alertErrorMessage: undefined,
      };
    case 'UPDATE_ALERT_ERROR_MESSAGE':
      return { ...prevState, alertErrorMessage: action.payload };
    case 'UPDATED_URL_SEARCH_PARAMETERS':
      return {
        ...prevState,
        searchFilters: {
          ...prevState.searchFilters,
          lastDays:
            action.payload.lastDays || defaultAllocateTransactionState.searchFilters.lastDays,
          searchTerm:
            action.payload.searchTerm || defaultAllocateTransactionState.searchFilters.searchTerm,
          systemSuggestion:
            action.payload.systemSuggestion ||
            defaultAllocateTransactionState.searchFilters.systemSuggestion,
        },
        pagination: {
          ...prevState.pagination,
          pageLimit:
            action.payload.pageLimit || defaultAllocateTransactionState.pagination.pageLimit,
          pageOffset:
            action.payload.pageOffset || defaultAllocateTransactionState.pagination.pageOffset,
        },
        isViewingTransactionDetailsTransactionId:
          action.payload.isViewingTransactionDetailsTransactionId,
      };
    case 'UPDATE_SEARCH_TERM':
      return {
        ...prevState,
        searchFilters: { ...prevState.searchFilters, searchTerm: action.payload },
        pagination: { ...defaultAllocateTransactionState.pagination },
      };
    case 'UPDATE_LAST_DAYS':
      return {
        ...prevState,
        searchFilters: { ...prevState.searchFilters, lastDays: action.payload },
        pagination: { ...defaultAllocateTransactionState.pagination },
      };
    case 'UPDATE_SYSTEM_SUGGESTION':
      return {
        ...prevState,
        searchFilters: { ...prevState.searchFilters, systemSuggestion: action.payload },
        pagination: { ...defaultAllocateTransactionState.pagination },
      };
    case 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID':
      return {
        ...prevState,
        isViewingTransactionDetailsTransactionId: action.payload,
        confirmLoseTransactionDetailsNextTransactionId: null,
        showConfirmLoseTransactionDetails: false,
      };
    case 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS':
      return {
        ...prevState,
        confirmLoseTransactionDetailsNextTransactionId: action.nextTransactionId ?? null,
        showConfirmLoseTransactionDetails: true,
      };
    case 'CANCEL_LOSE_TRANSACTION_DETAILS':
      return {
        ...prevState,
        showConfirmLoseTransactionDetails: false,
        confirmLoseTransactionDetailsNextTransactionId: null,
      };
    default:
      return { ...prevState };
  }
};

const useAllocateTransactionsReducer = (defaults?: AllocateTransactionsState) => {
  return useReducer(AllocationTransactionsReducer, {
    ...defaultAllocateTransactionState,
    ...defaults,
  });
};

// CONTEXT / PROVIDER

const AllocateTransactionsContext = createContext<{
  state: AllocateTransactionsState;
  dispatch: Dispatch<AllocationTransactionsStateAction>;
}>({
  state: {
    ...defaultAllocateTransactionState,
  },
  dispatch: () => {
    // no op
  },
});

export const AllocateTransactionsStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useAllocateTransactionsReducer();

  return (
    <AllocateTransactionsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AllocateTransactionsContext.Provider>
  );
};

export const useAllocateTransactionsContext = () => {
  return useContext(AllocateTransactionsContext);
};

// STATE URL_QUERY PERSISTANCE

interface SerializedState {
  searchTerm: string;
  lastDays: LastDays;
  systemSuggestion: Maybe<ZenaSuggestedAssignment>;
  isViewingTransactionDetailsTransactionId: Maybe<string>;
  pageLimit: PageLimit;
  pageOffset: number;
}

const serializeUrlQueryFromState = (state: SerializedState) => {
  const searchParams = new URLSearchParams(state as Record<string, any>);

  return searchParams;
};

const deserializeUrlToSearchState = (searchParams: URLSearchParams): SerializedState => {
  const lastDays = searchParams.get('lastDays');
  const systemSuggestion = searchParams.get('systemSuggestion');
  const isViewingTransactionDetailsTransactionId = searchParams.get(
    'isViewingTransactionDetailsTransactionId'
  );
  return {
    searchTerm: searchParams.get('searchTerm') ?? '',
    systemSuggestion:
      systemSuggestion === 'undefined' || !systemSuggestion
        ? undefined
        : (systemSuggestion as ZenaSuggestedAssignment),
    isViewingTransactionDetailsTransactionId:
      isViewingTransactionDetailsTransactionId === 'undefined' ||
      !isViewingTransactionDetailsTransactionId
        ? undefined
        : isViewingTransactionDetailsTransactionId,
    lastDays: lastDays === 'ALL' ? lastDays : (Number(lastDays) as LastDays),
    pageLimit: (Number(searchParams.get('pageLimit')) as PageLimit) ?? 30,
    pageOffset: Number(searchParams.get('pageOffset')) ?? 0,
  };
};

export const useImportTransactionsUrlQueryPersistence = ({
  searchState,
  dispatch,
  disabled = false,
}: {
  searchState: SerializedState;
  dispatch: Dispatch<AllocationTransactionsStateAction>;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const maybeUpdateSearchParams = useDebouncedCallback((oldSearchParams, newSearchParams) => {
    if (!disabled && !deepEquals(oldSearchParams, newSearchParams)) {
      navigate(`${pathname}?${newSearchParams}`);
    }
  }, 500);

  // on state change
  useEffect(() => {
    const updatedSearchParams = serializeUrlQueryFromState({
      ...searchState,
      isViewingTransactionDetailsTransactionId:
        searchState.isViewingTransactionDetailsTransactionId === undefined
          ? ''
          : searchState.isViewingTransactionDetailsTransactionId,
      systemSuggestion:
        searchState.systemSuggestion === undefined
          ? ('' as ZenaSuggestedAssignment)
          : (searchState.systemSuggestion as ZenaSuggestedAssignment),
    });
    // update query params
    maybeUpdateSearchParams(searchParams, updatedSearchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  // on url params change
  useEffect(() => {
    const updateSearchState = deserializeUrlToSearchState(searchParams);
    if (!deepEquals(searchState, updateSearchState)) {
      dispatch({
        type: 'UPDATED_URL_SEARCH_PARAMETERS',
        payload: updateSearchState,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return null;
};

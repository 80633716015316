import { Link } from 'react-router-dom';
import { HTMLAttributes } from 'react';
import { cn } from '../../../utils';

type BreadcrumbsProps = {
  breadcrumbs: {
    name: string;
    url: string;
  }[];
};

const Breadcrumbs = ({
  breadcrumbs,
  className,
  ...props
}: BreadcrumbsProps & HTMLAttributes<HTMLDivElement>) => {
  const breadcrumbContent: JSX.Element[] = [];
  breadcrumbs.forEach((breadcrumb, index) => {
    breadcrumbContent.push(
      <Link key={index} to={breadcrumb.url} className="text-accent font-semibold text-sm">
        {breadcrumb.name}
      </Link>
    );
    if (index < breadcrumbs.length - 1) {
      breadcrumbContent.push(
        <span key={`separator-${index}`} className="text-sm">
          {'>'}
        </span>
      );
    }
  });

  return (
    <div className={cn('flex gap-2', className)} {...props}>
      {breadcrumbContent}
    </div>
  );
};

export default Breadcrumbs;

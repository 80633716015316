import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cn } from 'utils';
import { Search } from 'lucide-react';

let debounceTime: NodeJS.Timeout;
const debounce = function (callback: (...args: any[]) => void, delay = 1000) {
  return (...args: any[]) => {
    clearTimeout(debounceTime);
    debounceTime = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export interface SearchTableProps extends InputHTMLAttributes<HTMLInputElement> {
  callbackFn?: (v: string) => void;
}

function SearchInputWithUrlSerialization({ callbackFn, value, ...props }: SearchTableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(value ? value : searchParams.get('search') || '');

  useEffect(() => {
    if (!searchTerm && !!searchParams.get('search')) {
      // remove search param when search bar is empty
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  }, [searchParams, searchTerm, setSearchParams]);

  return (
    <SearchInput
      searchTerm={String(searchTerm)}
      onChange={(newSearchTerm) => {
        const term = newSearchTerm as string;
        setSearchTerm(term);
        callbackFn && debounce(() => callbackFn(term))();
      }}
    />
  );
}

export default SearchInputWithUrlSerialization;

export const SearchInput = ({
  searchTerm,
  onChange,
  className,
  ...props
}: {
  searchTerm: string;
  onChange: (newSearchTerm: string) => void;
  className?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  return (
    <div className="flex w-full relative">
      <input
        className={cn(
          'border-primary-400 w-full border py-1 pr-4 pl-11 text-base focus:outline-none focus:ring-0 rounded-4xl',
          'h-10',
          className
        )}
        value={searchTerm}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        placeholder="Search"
        {...props}
      />

      <div className="absolute left-4 h-full flex items-center">
        <Search />
      </div>
    </div>
  );
};

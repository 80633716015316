import { NetworkStatus, useQuery } from '@apollo/client';
import useCustomAuth from 'hooks/useCustomAuth';
import { GetActiveCompanyQuery } from './data';
import { useLocation } from 'react-router-dom';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CompanyPublicStatus, CompanyTier } from '__generated__/graphql';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

interface Company {
  slug: string;
  name: string;
  status: CompanyPublicStatus;
  tier: CompanyTier;
}

const ActiveCompanyContext = createContext<{
  activeCompany: Company | null;
}>({
  activeCompany: null,
});

export const ActiveCompanyProvider = ({ children }: { children: ReactNode }) => {
  const { user, isLoading: isLoadingAuth } = useCustomAuth();
  const location = useLocation();
  const { pathname } = location;
  const [isInitialized, setIsInitialized] = useState(false);
  const skipQuery = !user || pathname === '/signup/business' || pathname === '/signup/confirmation';

  const { loading, data, error, refetch, networkStatus } = useQuery(GetActiveCompanyQuery, {
    skip: skipQuery,
    fetchPolicy: 'cache-and-network',
  });
  const activeCompany = data?.getMe?.defaultCompany || null;

  useEffect(() => {
    if (loading && !isInitialized) {
      setIsInitialized(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user]);

  useEffect(() => {
    if (!!user && !activeCompany) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (activeCompany) {
      analytics.group(activeCompany?.slug, {
        name: activeCompany?.name,
        tier: activeCompany?.tier,
        status: activeCompany?.status,
      });
    }
  }, [activeCompany]);

  if (
    loading ||
    (!isInitialized && !skipQuery) ||
    isLoadingAuth ||
    networkStatus === NetworkStatus.refetch ||
    (!activeCompany &&
      !!user &&
      pathname !== '/signup/business' &&
      pathname !== '/signup/confirmation' &&
      pathname !== '/invitation' &&
      pathname !== '/pendingInvitations' &&
      pathname !== '/join-team')
  ) {
    return (
      <div className="w-full h-full flex items-center justify-center min-h-80">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    // Should we show an error handling page in this case?
  }

  return (
    <ActiveCompanyContext.Provider
      value={{
        activeCompany,
      }}
    >
      {children}
    </ActiveCompanyContext.Provider>
  );
};

export const useActiveCompanyContext = () => {
  return useContext(ActiveCompanyContext);
};

export const useActiveCompany = (): { activeCompany: Company | null } => {
  const { activeCompany } = useActiveCompanyContext();

  return { activeCompany };
};

import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface AreaChartData {
  name: String;
  value: number;
}

interface AreaChartProps {
  data: Array<AreaChartData>;
  xTickFormatter?: (value: string) => string;
  yTickFormatter?: (value: string) => string;
  xTick?: boolean | object | React.ReactElement;
  yTick?: boolean | object | React.ReactElement;
  tooltipContent?: React.ReactElement;
}

const AreaChart = ({
  data,
  xTickFormatter,
  yTickFormatter,
  xTick,
  yTick,
  tooltipContent,
}: AreaChartProps) => {
  const getAreaGradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.value));
    const dataMin = Math.min(...data.map((i) => i.value));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const areaGradientOffset = getAreaGradientOffset();

  const getLineGradientOffsets = () => {
    const red = '#B20909';
    const green = '#658F75';

    if (!data.length) {
      return [];
    }

    let previousAmount = data[0].value;
    let previousSign = Math.sign(previousAmount);
    let percentageChanges = [
      <stop key="pf-start" offset={0} stopColor={previousSign === -1 ? red : green} />,
    ];

    data.forEach((item, index) => {
      const currentSign = Math.sign(item.value);

      if (currentSign !== previousSign && currentSign !== 0) {
        const currentMax = Math.max(previousAmount, item.value);
        const currentMin = Math.min(previousAmount, item.value);
        let percentage = currentMax / (currentMax + Math.abs(currentMin));
        if (currentSign === -1) {
          percentage = 1 - percentage;
        }

        const indexPercentage = index / (data.length - 1);
        const adjustedPercentage = indexPercentage - percentage * (1 / (data.length - 1));

        percentageChanges.push(
          <stop
            key={`pf-${index}-1`}
            offset={adjustedPercentage}
            stopColor={currentSign === -1 ? green : red}
          />
        );
        percentageChanges.push(
          <stop
            key={`pf-${index}-2`}
            offset={adjustedPercentage}
            stopColor={currentSign === -1 ? red : green}
          />
        );

        previousSign = currentSign;
      }
      previousAmount = item.value;
    });

    percentageChanges.push(
      <stop key="pf-finish" offset={100} stopColor={previousSign === -1 ? red : green} />
    );
    return percentageChanges;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <RechartsAreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#658F75" stopOpacity={0.8} />
            <stop offset={areaGradientOffset} stopColor="#658F75" stopOpacity={0.2} />
            <stop offset={areaGradientOffset} stopColor="#B20909" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#B20909" stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="colorLine" x1="0" y1="0" x2="1" y2="0">
            {getLineGradientOffsets()}
          </linearGradient>
        </defs>
        <XAxis dataKey="name" tickFormatter={xTickFormatter} tick={xTick} />
        <YAxis width={75} tickFormatter={yTickFormatter} tick={yTick} />
        <Tooltip content={tooltipContent} />
        <Area
          type="monotone"
          dataKey="value"
          stroke="url(#colorLine)"
          strokeWidth={3}
          fillOpacity={1}
          fill="url(#colorValue)"
        />
      </RechartsAreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;

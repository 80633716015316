import { AlertSettings, ProjectSettings, TransactionSettings } from './MainSettings/';

const SettingsTab = () => {
  return (
    <div className="flex flex-col gap-16 divide-y">
      <ProjectSettings />
      <TransactionSettings className="pt-16" />
      <AlertSettings className="pt-16" />
    </div>
  );
};

export default SettingsTab;

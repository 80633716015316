import { useQuery } from '@apollo/client';
import { PerksLayoutPage } from '../PerksLayoutPage';
import { gql } from '__generated__/gql';
import { MerchantDiscount } from '__generated__/graphql';
import { Discounts } from 'pages/(perks)/Discounts/Discounts';
import { GenericLoader } from 'components/atoms/Loader';
import { useActiveCompany } from 'providers/ActiveCompany';

const GET_MERCHANT_DISCOUNT_QUERY = gql(`
  query GetMerchantDiscountQuery($customerId: String!, $merchantName: String!, $discountCodeType: String!) {
    getMerchantDiscount(customerId: $customerId, merchantName: $merchantName, discountCodeType: $discountCodeType) {
      id
      merchantName
      discountCode
      customerId
    }
  }
`);

export const PerksDiscounts = () => {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { data, loading } = useQuery(GET_MERCHANT_DISCOUNT_QUERY, {
    variables: {
      customerId: companySlug,
      merchantName: 'Daniel House',
      discountCodeType: 'pro',
    },
  });
  const merchantDiscount = data?.getMerchantDiscount;
  const merchantDiscounts: MerchantDiscount[] = [];

  if (merchantDiscount) {
    merchantDiscounts.push(merchantDiscount as MerchantDiscount);
  }

  return (
    <PerksLayoutPage pageName="DISCOUNTS">
      {loading && (
        <div className="w-full flex justify-center self-start mt-28">
          <GenericLoader />
        </div>
      )}

      {!loading && (
        <>
          {merchantDiscounts.length > 0 ? (
            <Discounts merchantDiscounts={merchantDiscounts} />
          ) : (
            <div className="p-8">
              <h1>Merchant discounts: coming soon</h1>
            </div>
          )}
        </>
      )}
    </PerksLayoutPage>
  );
};

import { useQuery, ApolloError } from '@apollo/client';
import { onApolloError } from 'utils';
import { gql } from '__generated__/gql';
import { useAppState } from 'stores/UserStore';

export const GET_PROJECT_FINACIALS = gql(`
  query GetProjectFinancials($companySlug: String!, $projectId: String!, $timezone: String!) {
    getProjectFinancials(companySlug: $companySlug, projectId: $projectId, timezone: $timezone) {
      timeFrameTotals { 
        date
        txnCount
        timeFrameTotal
        cumulativeSum
      }
      totalPayments
      totalSpend
      }
    }
  `);

export const useGetProjectFinancials = ({
  companySlug,
  projectId,
  timezone,
  onSuccess,
  onError,
}: {
  companySlug: string;
  projectId: string;
  timezone: string;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}) => {
  const setErrorMsg = useAppState((state) => state.setErrorMsg);

  const { data, loading, error } = useQuery(GET_PROJECT_FINACIALS, {
    variables: {
      companySlug,
      projectId,
      timezone,
    },
    onCompleted: onSuccess,
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, []);

      if (onError) {
        onError(error);
      }
    },
  });

  return { loading, error, projectFinancials: data?.getProjectFinancials };
};

import { StayOrganizedWidget } from 'pages/(dashboard)/widgets/StayOrganizedWidget/StayOrganizedWidget';
import { useGetAdminApprovalCount, useGetOnboardingStatus, useGetTransactionsCount } from './data';
import { isAdmin } from 'hooks/access';
import { useActiveCompany } from 'providers/ActiveCompany';

export const StayOrganized = () => {
  const {
    stepsComplete,
    hasProject,
    hasZenaCard,
    hasLinkedAccounts,
    hasTransactions,
    hasInvitedTeammates,
    allDone,
    loading,
  } = useGetOnboardingStatus();
  const { approvals, loading: approvalsLoading } = useGetAdminApprovalCount();
  const { transactions, loading: transactionsLoading } = useGetTransactionsCount();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);

  return (
    <StayOrganizedWidget
      stepsComplete={stepsComplete}
      hasProject={hasProject}
      hasZenaCard={hasZenaCard}
      hasLinkedAccounts={hasLinkedAccounts}
      hasTransactions={hasTransactions}
      hasInvitedTeammates={hasInvitedTeammates}
      allDone={allDone}
      stepsLoading={loading}
      transactionsLoading={transactionsLoading}
      approvalsLoading={approvalsLoading}
      transactionsCount={transactions}
      approvalsCount={approvals}
      admin={admin}
      enableClicks
    />
  );
};

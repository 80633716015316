import { useLazyQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Payment } from '__generated__/graphql';
import { TableHead } from 'components/atoms/table/TableHead';
import { PaymentTableRow } from './PaymentTableRow';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { useEffect } from 'react';

export const QUERY_GET_PAYMENTS = gql(`
  query GetPayments($customerId: String!, $pageNumber:Int!) {
    getPayments(customerId: $customerId, pageNumber: $pageNumber) {
      meta{
        pageNumber
        pageSize
        total
      }
      payments{
        id
        initiatedBy
        dateInitiated
        amountCents
        status
        statementDate
        account
      }
    }
  }
`);

type PaymentsTableProps = {
  customerId: string;
  setPaymentDetails: (payment: Payment) => void;
  setShowPaymentDetails: (show: boolean) => void;
};
export const PaymentsTable = ({
  customerId,
  setPaymentDetails,
  setShowPaymentDetails,
}: PaymentsTableProps) => {
  const [getPaymentData, paymentData] = useLazyQuery(QUERY_GET_PAYMENTS, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getPaymentData({ variables: { customerId, pageNumber: 0 } });
  }, [customerId]);

  const meta = paymentData.data?.getPayments?.meta!;

  const fetchMorePayments = () => {
    paymentData.fetchMore({
      variables: { customerId, pageNumber: meta.pageNumber + 1 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const existingPayments = previousResult.getPayments?.payments as Payment[];
        const incomingPayments = fetchMoreResult.getPayments?.payments as Payment[];
        return {
          ...fetchMoreResult,
          getPayments: {
            ...fetchMoreResult.getPayments,
            payments: [...existingPayments, ...incomingPayments],
          },
        };
      },
    });
  };

  let payments = (paymentData.data?.getPayments?.payments as Payment[]) || [];

  let paymentsBody = null;
  if (payments.length) {
    paymentsBody = payments.map((payment) => (
      <PaymentTableRow
        id={payment?.id || ''}
        key={payment?.id || ''}
        dateInitiated={payment?.dateInitiated || ''}
        amountCents={payment?.amountCents || 0}
        status={payment?.status || ''}
        user={payment?.initiatedBy || undefined}
        onClick={() => {
          setPaymentDetails(payment);
          setShowPaymentDetails(true);
        }}
      />
    ));
  } else if (paymentData.error) {
    paymentsBody = (
      <tr>
        <td className="text-center text-asphalt" colSpan={6}>
          {paymentData.error.message}
        </td>
      </tr>
    );
  } else if (paymentData.loading) {
    paymentsBody = (
      <tr>
        <td className="text-center text-asphalt" colSpan={6}>
          <em>Loading payments...</em>
        </td>
      </tr>
    );
  } else if (paymentData.called) {
    paymentsBody = (
      <tr>
        <td className="text-center text-asphalt" colSpan={6}>
          <p className="font-medium">No payments to display.</p>
          <p> This list will display your payments made to-date.</p>
        </td>
      </tr>
    );
  } else {
    paymentsBody = (
      <tr>
        <td className="text-center text-asphalt" colSpan={6}>
          <em>Loading payments...</em>
        </td>
      </tr>
    );
  }

  return (
    <>
      <TableWithPagination
        meta={meta}
        viewMoreButtonCopy="View more payments"
        loadNextPage={fetchMorePayments}
        loading={paymentData.loading}
      >
        <TableHead>
          <td>Date initiated</td>
          <td>Amount</td>
          <td>Status</td>
          <td>Initiated by</td>
        </TableHead>
        <tbody>{paymentsBody}</tbody>
      </TableWithPagination>
    </>
  );
};

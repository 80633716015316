import SettingsForm from 'components/molecules/SettingsForm';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { onApolloError } from 'utils';
import { useAppState } from 'stores/UserStore';
import { ClientDetailsForm } from './ClientDetailsForm';
import { DetailsFormData } from './ClientDetailsForm/ClientDetailsForm';
import { DisplayedClientDetails } from './DisplayedClientDetails';
import { AddressOutput, ClientInfoOutput } from '__generated__/graphql';

export const MUTATION_UPDATE_PROJECT = gql(`
  mutation UpdateProjectSettingsClientDetails($customerId: String!, $projectId: String!, $attributes: ProjectInput!) {
    updateProject(projectId: $projectId, customerId: $customerId, attributes: $attributes) {
      id
      name
      description
      budget
      users
    },
  }
`);

const REFETCH_PROJECT = gql(`
  query RefetchProjectFromClientDetails($customerId: String!, $projectId: String!) {
    getProject(customerId: $customerId, projectId: $projectId) {
      id
      name
      description
      budget
      approved
      approvedAt
      rejectedAt
      users
      cardId
      amountSpent
      status
      address {
        street
        street2
        city
        state
        postalCode
      }
      clientInfo {
        firstName
        lastName
        email
        phone
      }
      tags {
        name
        type
      }
    }
  }
`);

const ProjectClientDetails = ({
  customerId,
  projectId,
  clientInfo,
  address,
}: {
  customerId: string;
  projectId: string;
  clientInfo?: ClientInfoOutput;
  address?: AddressOutput;
}) => {
  const [formSeed, setFormSeed] = useState(0);
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [setSuccessMsg, setErrorMsg] = useAppState((state) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);

  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT, {
    onError: (error) => onApolloError(error, setErrorMsg, ['UpsertFailed']),
    onCompleted: () => setSuccessMsg(`Project client details updated.`),
  });

  useEffect(() => {
    setFormSeed(Math.random());
  }, []);

  const defaultValues = {
    firstName: clientInfo?.firstName || undefined,
    lastName: clientInfo?.lastName || undefined,
    emailAddress: clientInfo?.email || undefined,
    phoneNumber: clientInfo?.phone || undefined,
    address: address?.street || undefined,
    address2: address?.street2 || undefined,
    city: address?.city || undefined,
    state: address?.state || undefined,
    zipCode: address?.postalCode || undefined,
  };

  const { register, getValues, reset, setValue, watch } = useForm<DetailsFormData>({
    defaultValues,
  });

  const onFormReset = () => {
    reset({ ...defaultValues });
  };

  const onSubmit = () => {
    const data = getValues();
    const hasError = validateFields(data);
    if (!hasError) {
      let variables = {
        customerId,
        projectId,
        attributes: {
          clientInfo: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.emailAddress,
            phone: data.phoneNumber,
          },
          address: {
            street: data.address,
            street2: data.address2,
            city: data.city,
            state: data.state,
            postalCode: data.zipCode,
          },
        },
      };

      updateProject({
        variables,
        refetchQueries: [
          {
            query: REFETCH_PROJECT,
            variables: {
              customerId,
              projectId,
            },
          },
        ],
      });
    }
    return !hasError;
  };

  const validateFields = (data: any) => {
    let hasError = false;

    const { address, city, state, zipCode, emailAddress } = data;
    if (address || city || state || zipCode) {
      if (!address) {
        setAddressError('Street is required');
        hasError = true;
      } else {
        setAddressError('');
      }
      if (!city) {
        setCityError('City is required');
        hasError = true;
      } else {
        setCityError('');
      }
      if (!state) {
        setStateError('State is Required');
        hasError = true;
      } else {
        setStateError('');
      }
      if (!zipCode) {
        setZipCodeError('Zip code is Required');
        hasError = true;
      } else {
        setZipCodeError('');
      }
    } else {
      setAddressError('');
      setCityError('');
      setStateError('');
      setZipCodeError('');
    }

    if (emailAddress) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isInvalidEmail = !emailRegex.test(emailAddress);
      if (isInvalidEmail) {
        setEmailError('Invalid email address');
        hasError = true;
      }
    } else {
      setEmailError('');
    }

    return hasError;
  };

  const errors = {
    emailError,
    addressError,
    cityError,
    stateError,
    zipCodeError,
  };

  return (
    <SettingsForm
      key={formSeed}
      formTitle="Client Details"
      onSubmit={onSubmit}
      onCancel={onFormReset}
    >
      {({ editing }) =>
        editing ? (
          <ClientDetailsForm
            getValues={getValues}
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
          />
        ) : (
          <DisplayedClientDetails clientInfo={clientInfo} address={address} />
        )
      }
    </SettingsForm>
  );
};

export default ProjectClientDetails;

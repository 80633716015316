import moment from 'moment';
import { relativeDateText } from 'utils';

type RelativeDateProps = {
  date: string;
  includeTime?: boolean;
};

const RelativeDate = ({ date, includeTime }: RelativeDateProps) => {
  const momentDate = moment.utc(date).local();
  return <span>{relativeDateText(momentDate, includeTime)}</span>;
};

export default RelativeDate;

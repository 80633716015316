import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const COMPANY_TIER_QUERY = gql(`
  query GetCompanyTier($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      tier
    }
  }
`);

export const useCompany = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(COMPANY_TIER_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return { loading, error, company: data?.getCompany ?? null };
};

import { ListItem } from 'components/atoms/ListItem';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { Selectable } from 'stores/UserStore';
import { isUsingMobile } from 'utils';
import { CornerDownLeft } from 'lucide-react';

type CustomOptionProps = {
  data: Selectable;
  innerProps: any;
  isDisabled: boolean;
};

type SearchOption = {
  data: Selectable;
};

const CustomOption = ({ data, innerProps, isDisabled }: CustomOptionProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = isUsingMobile();
  if (isDisabled) {
    return null;
  }
  return (
    <div className="border-b border-feather last:border-b-0 mx-2">
      <div
        {...innerProps}
        className="relative hover:bg-lavender/25 rounded-xl pt-1 pb-1"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <ListItem
          name={data.name}
          email={'email' in data ? data.email : ''}
          key={data.id}
          url={'profilePictureUrl' in data ? data.profilePictureUrl : undefined}
        />
        <div className="absolute z-10 right-0 top-1/2 transform -translate-y-1/2">
          {isHovered && !isMobile && (
            <div className="bg-lavender h-8 w-8 grid place-items-center rounded mr-2">
              <CornerDownLeft />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const searchAlgo = (option: SearchOption, searchText: string) => {
  if (!searchText) {
    return true;
  }
  if (
    option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
    ('email' in option.data && option.data?.email?.toLowerCase().includes(searchText.toLowerCase()))
  ) {
    return true;
  } else {
    return false;
  }
};

type SingleSelectProps = {
  initialSelected?: string;
  values: readonly Selectable[];
  setSelected: (value: string) => void;
  placeholder?: string;
  withNone?: boolean;
  ref?: React.RefObject<any>;
};

const SingleSelect = ({
  values,
  setSelected,
  initialSelected,
  placeholder,
  withNone = true,
  ref,
}: SingleSelectProps) => {
  const onChange = (selectedOptions: SingleValue<Selectable>) =>
    setSelected(selectedOptions?.id || '');

  let selectValues = withNone ? [{ id: 'none', name: 'None' }, ...values] : values;

  useEffect(() => {
    if (values.length === 1) {
      setSelected(values[0]?.id);
    } else if (initialSelected) {
      setSelected(initialSelected);
    }
  }, [values, initialSelected]);

  return (
    <div>
      <Select
        unstyled
        classNames={{
          control: () => 'border-2 border-marble bg-transparent rounded-lg pr-2',
          valueContainer: () => 'flex p-4',
          menuList: () => 'bg-white mt-4 py-2 rounded-2xl shadow-lg',
          placeholder: () => 'text-feather text-3xl',
        }}
        defaultValue={values.filter((item: Selectable) => item?.id === initialSelected)[0]}
        onChange={onChange}
        options={selectValues}
        isMulti={false}
        isSearchable
        placeholder={placeholder || 'type name'}
        filterOption={searchAlgo}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        components={{ Option: CustomOption }}
        ref={ref}
      />
    </div>
  );
};

export default SingleSelect;

import { useState } from 'react';
import { RoundButton } from 'components/atoms/Buttons';
import upsellFinancials from '../../../images/upsellFinancials.webp';
import { AddAccountModal } from 'components/widgets/AddAccountModal';
import { isAdmin } from 'hooks/access';
import { useNavigate } from 'react-router-dom';
import { useActiveCompany } from 'providers/ActiveCompany';

export const ConnectRepaymentAccount = ({
  hasConnectedAccounts = false,
}: {
  hasConnectedAccounts?: boolean;
}) => {
  const [showConnectAccountsModal, setShowConnectAccountsModal] = useState<boolean>(false);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex-auto grid gap-6 px-4 content-end justify-items-center">
        <img src={upsellFinancials} alt="upsell marketing images" />
        <div className="text-black text-center">
          Connect your other accounts and learn how your projects are performing
        </div>
        <div className="flex flex-col gap-2">
          <RoundButton
            disabled={!admin}
            onClick={() => {
              if (hasConnectedAccounts) {
                navigate('/transactions/import');
              } else {
                setShowConnectAccountsModal(true);
              }
            }}
            variant="secondary"
          >
            {hasConnectedAccounts ? 'Import now' : 'Connect my accounts'}
          </RoundButton>
          {!admin && (
            <p className="text-sm text-gray-500">* Must be completed by a company admin</p>
          )}
        </div>
      </div>
      <AddAccountModal
        isOpen={showConnectAccountsModal}
        onClose={() => setShowConnectAccountsModal(false)}
      />
    </>
  );
};

import { DropdownHeading, DropdownSection, DropdownSeparator } from 'components/atoms/Dropdown';
import { ComponentPropsWithoutRef, PropsWithChildren, useMemo, useState } from 'react';
import { Checkbox, CheckboxDropdownItem } from 'components/atoms/Checkbox';
import { Label } from 'components/atoms/fieldset';
import { TransactionAssignment, ZenaTransactionType } from '__generated__/graphql';
import voca from 'voca';
import { RoundButton } from 'components/atoms/Buttons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { DateRangeValue } from 'components/atoms/Inputs/DateRange/DateRangeCalendar';
import { formatSimpleDate } from 'utils/date';
import { DateRange } from 'components/atoms/Inputs/DateRange';
import clsx from 'clsx';
import { CalendarIcon, Check, ChevronDown, X } from 'lucide-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cn } from 'utils';

export interface FiltersState {
  assignmentFilters: TransactionAssignment[];
  transactionTypeFilters: ZenaTransactionType[];
  dateRange: DateRangeValue | null;
}

interface TransactionFilterDropdownProps {
  onChange: (filters: FiltersState) => void;
}
export const TransactionFilterDropdown = ({ onChange }: TransactionFilterDropdownProps) => {
  // TODO => have this default to a value from initial filters
  const [dateRange, setDateRange] = useState<DateRangeValue>([null, null]);

  //list options with their initial values
  const assignmentOptions = useMemo(
    (): Record<TransactionAssignment, boolean> => ({
      [TransactionAssignment.project]: true,
      [TransactionAssignment.business]: true,
      [TransactionAssignment.notBusinessRelated]: false,
    }),
    []
  );

  const typeOptions = useMemo(
    (): Record<ZenaTransactionType, boolean> => ({
      [ZenaTransactionType.bankPayment]: true,
      [ZenaTransactionType.creditCardPayment]: true,
      [ZenaTransactionType.otherIncome]: true,
      [ZenaTransactionType.paymentReceived]: true,
      [ZenaTransactionType.return]: true,
      [ZenaTransactionType.spend]: true,
      [ZenaTransactionType.transferIn]: true,
      [ZenaTransactionType.transferOut]: true,
      [ZenaTransactionType.withdraw]: true,
    }),
    []
  );

  const initialValues = useMemo((): string[] => {
    const allOptions = {
      ...assignmentOptions,
      ...typeOptions,
    };
    let initialSelectedOptions: string[] = [];
    Object.keys(allOptions).forEach((option) => {
      if (allOptions[option as keyof typeof allOptions]) {
        initialSelectedOptions.push(option);
      }
    });
    return initialSelectedOptions;
  }, [assignmentOptions, typeOptions]);

  let [selected, setSelected] = useState(initialValues);

  useMemo(() => {
    onChange({
      assignmentFilters: selected.filter((item) =>
        Object.keys(assignmentOptions).includes(item)
      ) as TransactionAssignment[],
      transactionTypeFilters: selected.filter((item) =>
        Object.keys(typeOptions).includes(item)
      ) as ZenaTransactionType[],
      dateRange: dateRange,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, dateRange]);

  const handleSelectClearAll = () => {
    if (!selected.length) {
      setSelected([...Object.keys(assignmentOptions), ...Object.keys(typeOptions)]);
    } else {
      setSelected([]);
    }

    setDateRange([null, null]);
  };

  return (
    <>
      <Popover>
        <PopoverButton>
          <RoundButton variant="tertiary">
            Filter <ChevronDown />
          </RoundButton>
        </PopoverButton>
        <PopoverPanel className="flex-col 2xl:flex-row flex gap-2 lg:gap-4 items-start absolute z-50 overflow-visible">
          <div
            className={clsx(
              // Anchor positioning
              '[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.3)] data-[anchor~=end]:[--anchor-offset:4px] data-[anchor~=start]:[--anchor-offset:-4px]',

              // Base styles
              'isolate rounded-xl p-1',

              // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
              'outline outline-1 outline-transparent focus:outline-none',

              // Handle scrolling when menu won't fit in viewport
              'overflow-y-auto',

              // Popover background
              'bg-white backdrop-blur-xl dark:bg-zinc-800/75',

              // Shadows
              'shadow-xl ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10',

              // Define grid at the menu level if subgrid is supported
              'supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto] content-start',

              // responsiveness
              'grid min-w-64 max-w-[80vw] lg:max-w-[auto] mt-4 max-h-[50vh]'
            )}
          >
            <FiltersContent
              handleSelectClearAll={handleSelectClearAll}
              selected={selected}
              dateRange={dateRange}
              assignmentOptions={assignmentOptions}
              onChangeSelectedValues={(newValues) => setSelected(newValues)}
              transactionTypeOptions={typeOptions}
              onChangeDateRange={(newDates) => setDateRange(newDates)}
            />
          </div>
        </PopoverPanel>
      </Popover>
    </>
  );
};

const FiltersContent = ({
  handleSelectClearAll,
  selected,
  dateRange,
  assignmentOptions,
  onChangeSelectedValues,
  transactionTypeOptions,
  onChangeDateRange,
}: {
  handleSelectClearAll: () => void;
  selected: string[];
  dateRange: DateRangeValue;
  assignmentOptions: Record<TransactionAssignment, boolean>;
  onChangeSelectedValues: (values: string[]) => void;
  transactionTypeOptions: Record<ZenaTransactionType, boolean>;
  onChangeDateRange: (dateRange: DateRangeValue) => void;
}) => {
  const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);
  const { removeImportedTransactions } = useFlags();

  return (
    <>
      <DropdownSection aria-label="manage filters">
        <InteractiveSection onClick={handleSelectClearAll}>
          {!selected.length ? <Check data-slot="icon" /> : <X data-slot="icon" />}

          <span>{!selected.length ? 'Select all' : 'Clear all'}</span>
        </InteractiveSection>
      </DropdownSection>
      <DropdownSeparator />
      <DropdownSection aria-label="Date">
        <InteractiveSection onClick={() => setShowDateRangeSelector(!showDateRangeSelector)}>
          <CalendarIcon data-slot="icon" />
          <span>Date</span>
          {dateRange && <FormattedDateRangeValue dateRange={dateRange} />}
        </InteractiveSection>
      </DropdownSection>
      {showDateRangeSelector && (
        <DropdownSection>
          <DateRange
            value={dateRange ?? [null, null]}
            onChange={(newDates) => onChangeDateRange(newDates)}
            maxDate={new Date()}
            onClose={() => setShowDateRangeSelector(false)}
            className="static"
          />
        </DropdownSection>
      )}
      <DropdownSeparator />
      <DropdownSection aria-label="Assignment">
        <DropdownHeading>Assignment</DropdownHeading>
        {Object.keys(assignmentOptions).map((option) => {
          if (option === 'notBusinessRelated' && !removeImportedTransactions) {
            return null;
          }
          return (
            <CheckboxDropdownItem key={option}>
              <Checkbox
                name={option}
                checked={selected.includes(option)}
                onChange={(checked) => {
                  return onChangeSelectedValues(
                    checked ? [...selected, option] : selected.filter((item) => item !== option)
                  );
                }}
              />
              <Label className="cursor-pointer capitalize">{formatLabel(option)}</Label>
            </CheckboxDropdownItem>
          );
        })}
      </DropdownSection>
      <DropdownSeparator />
      <DropdownSection aria-label="Transaction Type">
        <DropdownHeading>Transaction Type</DropdownHeading>
        {Object.keys(transactionTypeOptions).map((key, index) => (
          <CheckboxDropdownItem key={key}>
            <Checkbox
              name={key}
              checked={selected.includes(key)}
              onChange={(checked) => {
                return onChangeSelectedValues(
                  checked ? [...selected, key] : selected.filter((item) => item !== key)
                );
              }}
            />
            <Label className="cursor-pointer">{formatLabel(key)}</Label>
          </CheckboxDropdownItem>
        ))}
      </DropdownSection>
    </>
  );
};

const formatLabel = (label: string): string => {
  return voca.capitalize(voca.words(label).join(' '));
};

const InteractiveSection = (props: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <div
      {...props}
      className={cn(
        props.className,
        // Base styles
        'group cursor-default rounded-lg px-3.5 py-2.5 focus:outline-none sm:px-3 sm:py-1.5',

        // Text styles
        'text-left text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white forced-colors:text-[CanvasText]',

        // Focus / Hover state
        'hover:bg-tertiary-800',
        'cursor-pointer',
        // Disabled state
        'data-[disabled]:opacity-50',
        // Forced colors mode
        'forced-color-adjust-none forced-colors:data-[focus]:bg-[Highlight] forced-colors:data-[focus]:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:data-[focus]:text-[HighlightText]',

        // Use subgrid when available but fallback to an explicit grid layout if not
        'col-span-full grid grid-cols-[auto_auto_1rem_1fr_auto] items-center supports-[grid-template-columns:subgrid]:grid-cols-subgrid',

        'gap-x-4',
        // Icon
        '[&>[data-slot=icon]]:col-start-1 [&>[data-slot=icon]]:row-start-1 [&>[data-slot=icon]]:size-5 [&>[data-slot=icon]]:sm:size-4',
        '[&>[data-slot=icon]]:text-zinc-500 [&>[data-slot=icon]]:data-[focus]:text-white [&>[data-slot=icon]]:dark:text-zinc-500 [&>[data-slot=icon]]:data-[focus]:dark:text-white',

        // date range styling
        '[&>[data-slot=daterange]]:col-start-3 [&>[data-slot=daterange]]:row-start-1 sm:[&>[data-slot=daterange]]:ml-2',
        '[&>[data-slot=daterange]]:text-zinc-500 [&>[data-slot=daterange]]:data-[focus]:text-white [&>[data-slot=daterange]]:dark:text-zinc-500 [&>[data-slot=daterange]]:data-[focus]:dark:text-white',
        '[&>[data-slot=daterange]]:col-span-2'
      )}
    />
  );
};

const FormattedDateRangeValue = ({ dateRange }: { dateRange: DateRangeValue | null }) => {
  let body = '';
  if (dateRange && dateRange[0] && dateRange[1]) {
    body = `${formatSimpleDate(dateRange[0])} - ${formatSimpleDate(dateRange[1])}`;
  } else if (dateRange && dateRange[0]) {
    body = `Starting: ${formatSimpleDate(dateRange[0])}`;
  } else if (dateRange && dateRange[1]) {
    body = `End: ${formatSimpleDate(dateRange[1])}`;
  }

  return (
    <span className="text-xs font-bold" data-slot="daterange">
      {body}
    </span>
  );
};

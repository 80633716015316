import { HTMLAttributes } from 'react';
import { useAppState } from '../../../../../stores/UserStore';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { RequireReceipts } from './Switches/RequireReciepts';
import { RequireMemo } from './Switches';
import { useActiveCompany } from '../../../../../providers/ActiveCompany';

export const QUERY_GET_SETTINGS = gql(`
  query GetTransactionalSettings($customerId: String!) {
    getSettings(customerId: $customerId) {
        requireMemoOver,
        requireReceiptOver,
        visited
    }
  }
`);

interface TransactionSettingsProps extends HTMLAttributes<HTMLDivElement> {}
export const TransactionSettings = (props: TransactionSettingsProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { loading, data: getDataResults } = useQuery(QUERY_GET_SETTINGS, {
    variables: {
      customerId: activeCompanySlug,
    },
  });

  return (
    <div {...props}>
      <div className="flex">
        <div className="flex-1 w-1/3 pr-12">
          <div className="font-bold mb-2">Transaction settings</div>
          <div className="text-gray-500">
            Set rules to keep transactions organized across all projects and business spend.
          </div>
        </div>
        <div className="flex-2 w-2/3">
          <form className="grid gap-8">
            <RequireReceipts
              loading={loading}
              customerId={activeCompanySlug}
              setErrorMsg={setErrorMsg}
              initialValue={getDataResults?.getSettings?.requireReceiptOver || 0}
              defaultEnabled={getDataResults?.getSettings?.requireReceiptOver !== null}
            />
            <RequireMemo
              loading={loading}
              customerId={activeCompanySlug}
              setErrorMsg={setErrorMsg}
              initialValue={getDataResults?.getSettings?.requireMemoOver || 0}
              defaultEnabled={getDataResults?.getSettings?.requireMemoOver !== null}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

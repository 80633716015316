import { CircleSkeleton, RectangleSkeleton, TextSkeleton } from 'components/atoms/Skeletons';
import { useProjectTeamMembers } from './TeamMembersProvider';
import { TeamMemberProfile } from './TeamMemberProfile';
import { X } from 'lucide-react';
import { cn } from 'utils';

const TeamMembersCollection = () => {
  const { loading, projectTeamMembers, removeTeamMember } = useProjectTeamMembers();

  const handleRemoveRequest = async (userId: string) => {
    removeTeamMember(userId);
  };

  if (loading) return <TeamMembersCollectionLoading />;

  const disabled = projectTeamMembers.length === 1;
  return (
    <ul className="divide-y divide-grey-100 mt-3">
      {projectTeamMembers?.map((teamMember) => (
        <li key={teamMember.userId} className="flex justify-between gap-x-6 py-5">
          <TeamMemberProfile member={teamMember} />
          <div className="shrink-0 flex flex-col items-end justify-center">
            <button
              onClick={() => handleRemoveRequest(teamMember.userId)}
              disabled={disabled}
              className={cn('relative', disabled && 'opacity-50 cursor-not-allowed')}
            >
              <span className="h-3 w-3">
                <X />
              </span>
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const TeamMembersCollectionLoading = () => {
  return (
    <div>
      <ul className="divide-y divide-grey-100 mt-3">
        {[...Array(3)].map((_, i) => (
          <li key={i} className="flex justify-between gap-x-4 py-5 items-center">
            <div className="h-14 w-14">
              <CircleSkeleton size="full" className="bg-primary-400/60" />
            </div>
            <div className="w-full space-y-2">
              <TextSkeleton width="small" className="bg-primary-400/80" />
              <RectangleSkeleton width="medium" className="justify-self-end bg-primary-400/40" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeamMembersCollection;

import { cn } from 'utils';
import { useAllocateTransactionsContext } from '../state';
import { SearchInput } from 'components/molecules/SearchInput/SearchInput';
import { LastDaysDropdown } from 'components/atoms/LastDaysDropdown';
import { FilterBySystemSuggestionsDropdown } from './SystemSuggestionsDropdown';
import { FilterBySystemSuggestionsWithPrompts } from './SystemSuggestionsWithPrompts';
import { RoundButton } from 'components/atoms/Buttons';
import { useMemo } from 'react';
import { AllocateTransactionsCategoryDropdownCategoriesContent } from 'components/widgets/AllocateTransactions/AllocateTransactionTableRow/AllocateTransactionCategoryDropdown';
import { Pagination } from 'components/atoms/Pagination';

export const AllocateTransactionsFilters = ({
  mutationsLoading = false,
  onConfirmSelectedTransactionForBulkAllocation,
  companySlug,
}: {
  companySlug: string;
  mutationsLoading?: boolean;
  onConfirmSelectedTransactionForBulkAllocation: () => void;
}) => {
  const { state, dispatch } = useAllocateTransactionsContext();

  const isAllSelectedTransactionsAllocated = useMemo(() => {
    return (
      state.selectedTransactionIds.length > 0 &&
      !state.selectedTransactionIds.find(
        (transactionId) => !state.assignedCategories[transactionId]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedTransactionIds.length, state.assignedCategories]);

  return (
    <>
      {/* MOBILE */}
      <div className={cn('lg:hidden', 'flex flex-col gap-4')}>
        <SearchInput
          searchTerm={state.searchFilters.searchTerm}
          onChange={(newSearchTerm) => {
            dispatch({
              type: 'UPDATE_SEARCH_TERM',
              payload: newSearchTerm,
            });
          }}
        />
        <div className="flex gap-4">
          <LastDaysDropdown
            lastDays={state.searchFilters.lastDays}
            onChange={(value) => {
              dispatch({
                type: 'UPDATE_LAST_DAYS',
                payload: value,
              });
            }}
          />
          <FilterBySystemSuggestionsDropdown
            systemSuggestion={state.searchFilters.systemSuggestion}
            onChange={(value) =>
              dispatch({
                type: 'UPDATE_SYSTEM_SUGGESTION',
                payload: value,
              })
            }
          />
        </div>
      </div>

      {/* DESKTOP */}
      <div className={cn('hidden lg:flex flex-col gap-14')}>
        <FilterBySystemSuggestionsWithPrompts
          systemSuggestion={state.searchFilters.systemSuggestion}
          onChange={(value) =>
            dispatch({
              type: 'UPDATE_SYSTEM_SUGGESTION',
              payload: value,
            })
          }
        />

        <div className="flex flex-col gap-10 items-start">
          <div
            id="bulk-allocation-of-transactions"
            className="flex justify-center items-center relative overflow-visible"
          >
            <AssignBulkTransactionsButton
              totalSelectedTransactions={state.selectedTransactionIds.length}
              disabled={state.selectedTransactionIds.length === 0 || mutationsLoading}
              onClick={() => {
                if (isAllSelectedTransactionsAllocated) {
                  onConfirmSelectedTransactionForBulkAllocation();
                } else {
                  dispatch({
                    type: 'UPDATE_SHOW_BULK_ASSIGN_DROPDOWN',
                    payload: true,
                  });
                }
              }}
              isAllSelectedTransactionsAllocated={isAllSelectedTransactionsAllocated}
            />
            {state.showBulkAssignDropdown && (
              <AllocateTransactionsCategoryDropdownCategoriesContent
                className={cn(
                  'top-auto left-auto bottom-full mb-2 w-[90vw] max-w-[25em]',
                  'lg:left-0 lg:right-[-5em] lg:top-full lg:bottom-auto lg:mb-0 lg:w-auto'
                )}
                companySlug={companySlug}
                onClose={() => {
                  dispatch({
                    type: 'UPDATE_SHOW_BULK_ASSIGN_DROPDOWN',
                    payload: !state.showBulkAssignDropdown,
                  });
                }}
                onSelectNoCategory={() => {
                  dispatch({
                    type: 'BULK_ASSIGN_NA',
                  });
                }}
                onSelectProject={(projectId) => {
                  dispatch({
                    type: 'BULK_ASSIGN_PROJECT',
                    payload: projectId,
                  });
                }}
              />
            )}
          </div>

          <div className="flex w-full gap-4 flex-row justify-between items-baseline">
            <div className="grow max-w-[600px] flex gap-4">
              <SearchInput
                searchTerm={state.searchFilters.searchTerm}
                onChange={(newSearchTerm) => {
                  dispatch({
                    type: 'UPDATE_SEARCH_TERM',
                    payload: newSearchTerm,
                  });
                }}
              />
              <div>
                <LastDaysDropdown
                  lastDays={state.searchFilters.lastDays}
                  onChange={(value) => {
                    dispatch({
                      type: 'UPDATE_LAST_DAYS',
                      payload: value,
                    });
                  }}
                />
              </div>
            </div>
            <Pagination
              pageLimit={state.pagination.pageLimit}
              onChangePageLimit={(newPageLimit) => {
                dispatch({
                  type: 'UPDATE_PAGINATION',
                  payload: { ...state.pagination, pageLimit: newPageLimit, pageOffset: 0 },
                });
              }}
              totalPages={state.pagination.totalPages}
              currentPage={Math.floor(state.pagination.pageOffset / state.pagination.pageLimit) + 1}
              onPreviousPage={() => {
                dispatch({
                  type: 'UPDATE_PAGINATION',
                  payload: {
                    ...state.pagination,
                    pageOffset: state.pagination.pageOffset - state.pagination.pageLimit,
                  },
                });
              }}
              onNextPage={() => {
                dispatch({
                  type: 'UPDATE_PAGINATION',
                  payload: {
                    ...state.pagination,
                    pageOffset: state.pagination.pageOffset + state.pagination.pageLimit,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const AssignBulkTransactionsButton = ({
  onClick,
  disabled = false,
  isAllSelectedTransactionsAllocated = false,
  totalSelectedTransactions,
}: {
  onClick: () => void;
  disabled?: boolean;
  isAllSelectedTransactionsAllocated?: boolean;
  totalSelectedTransactions: number;
}) => {
  return (
    <RoundButton
      text={
        isAllSelectedTransactionsAllocated
          ? `Confirm ${totalSelectedTransactions} selected transactions`
          : `Assign${
              totalSelectedTransactions > 0 ? ` ${totalSelectedTransactions}` : ''
            } selected transactions`
      }
      disabled={disabled}
      className="whitespace-nowrap"
      onClick={onClick}
    />
  );
};

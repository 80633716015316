import { cn } from 'utils';
import {
  useIntuitAppConnection,
  useQboSyncTransactionDetails,
  useTransactionFeedSyncSubscription,
} from './data';
import { CircleSkeleton, RectangleSkeleton } from 'components/atoms/Skeletons';
import { ReactNode } from 'react';
import { formatSimpleDate } from 'utils/date';
import {
  TransactionFeedTransactionType,
  TransactionMissingFields,
  TransactionSyncStatus,
} from '__generated__/graphql';
import { CircleCheck, RefreshCcw, RefreshCwOff, TriangleAlert } from 'lucide-react';

export const QuickbookSyncStatus = ({
  className = '',
  transactionId,
  companySlug,
}: {
  transactionId: string;
  companySlug: string;
  className?: string;
}) => {
  const { userNeedsToReconnectTheirQboAccount } = useIntuitAppConnection({
    companySlug,
  });
  const {
    transaction,
    isSyncedWithQbo,
    isImportedTransaction,
    accountingSyncStatus,
    zenaType,
    loading,
  } = useQboSyncTransactionDetails({
    companySlug,
    transactionId,
  });
  const hasMissingInfo =
    transaction?.missingFields?.accountingTag ||
    transaction?.missingFields?.memo ||
    transaction?.missingFields?.receipt;

  useTransactionFeedSyncSubscription({
    companySlug,
    transactionId,
  });

  const getMissingFieldDisplayNames = (missingFields: TransactionMissingFields): string => {
    const getName = (key: keyof TransactionMissingFields) => {
      switch (key) {
        case 'receipt':
          return 'Receipt';
        case 'memo':
          return 'Memo';
        case 'accountingTag':
          return 'Accounting Tag';
        case 'categoryTag':
        case 'roomTag':
        default:
          return '';
      }
    };

    return Object.keys(missingFields).reduce((names, key) => {
      if (
        missingFields[key as keyof TransactionMissingFields] &&
        !['roomTag', 'categoryTag'].includes(key)
      ) {
        names = `${names}${names.length > 0 ? ', ' : ''}${getName(
          key as keyof TransactionMissingFields
        )}`;
      }

      return names;
    }, '');
  };

  const isReturn = zenaType === TransactionFeedTransactionType.return;
  const isSyncing = accountingSyncStatus === TransactionSyncStatus.syncing;

  const getQBOStatusIcon = () => {
    let icon = <CircleSkeleton width="small" />;
    if (!loading) {
      if (isSyncing && !isImportedTransaction) {
        icon = <RefreshCcw className="animate-spin" />;
      } else if (isSyncedWithQbo && !isImportedTransaction) {
        icon = <CircleCheck className="fill-forest-800" />;
      } else if (isImportedTransaction) {
        icon = <CircleCheck className="fill-secondary-300" />;
      } else if (isReturn) {
        icon = <CircleCheck className="fill-primary-300" />;
      } else if (
        !isSyncedWithQbo &&
        userNeedsToReconnectTheirQboAccount &&
        !isImportedTransaction
      ) {
        icon = <RefreshCwOff className="fill-primary-600" />;
      } else if (
        !isSyncedWithQbo &&
        !userNeedsToReconnectTheirQboAccount &&
        !isImportedTransaction
      ) {
        icon = <TriangleAlert />;
      }
    }

    return icon;
  };

  const syncText = isSyncedWithQbo
    ? transaction?.qboAccountingIntegrationSync?.syncedAt &&
      formatSimpleDate(new Date(transaction?.qboAccountingIntegrationSync?.syncedAt))
    : 'not synced';

  return (
    <div className={cn('grid gap-4', isImportedTransaction ? 'text-primary-600' : '', className)}>
      <div className="flex justify-between items-end">
        <div className={cn('text-xl', isReturn ? 'opacity-30' : '')}>Quickbooks sync status</div>
        {getQBOStatusIcon()}
      </div>

      {loading && <RectangleSkeleton rounded className="h-[100px]" width="full" />}

      {transaction && !loading && (
        <div className="py-4 px-3 rounded-xl border border-primary-400">
          {isImportedTransaction && (
            <p className="font-light text-sm">
              This transaction is imported from an external account. Zena does not have permission
              to push this transaction to Quickbooks at this time.
            </p>
          )}
          {!isImportedTransaction && (
            <>
              {isReturn ? (
                <p className="opacity-30 py-4">
                  This transaction is a return. Returns are not sent to QuickBooks as matching
                  expenses.
                </p>
              ) : (
                <>
                  {isSyncing ? (
                    <p className="text-center py-10">
                      <i>in progress</i>
                    </p>
                  ) : (
                    <div>
                      <div className="grid gap-4">
                        <Row>
                          <RowLabel>Synced with QBO</RowLabel>
                          <div>{syncText}</div>
                        </Row>
                        <Row>
                          <RowLabel>QBO expense:</RowLabel>
                          {transaction.qboAccountingIntegrationSync?.expenseId ? (
                            <span className="text-primary-600 w-max p-0">
                              #{transaction.qboAccountingIntegrationSync.expenseId}
                            </span>
                          ) : (
                            <div className="text-primary-600 text-sm">n/a</div>
                          )}
                        </Row>
                      </div>
                      {!isSyncedWithQbo && (
                        <div className="mt-4 pt-4 border-t border-primary-400 text-sm grid gap-2">
                          {transaction.missingFields && hasMissingInfo && (
                            <p>
                              This transaction is missing information. Before we can sync the
                              transaction into Quickbooks, your company settings require a{' '}
                              <span className="font-bold">
                                {getMissingFieldDisplayNames(transaction.missingFields)}
                              </span>{' '}
                              be added.
                            </p>
                          )}

                          {userNeedsToReconnectTheirQboAccount && (
                            <p>
                              Your Quickbooks account has been disconnected from Zena. You must
                              reconnect your account here before we can sync this transactions to
                              Quickbooks.
                            </p>
                          )}

                          {!userNeedsToReconnectTheirQboAccount &&
                            !isSyncedWithQbo &&
                            !hasMissingInfo && (
                              <p>
                                This transaction cannot be synced with Quickbooks because the
                                Quickbooks API is unavailable at this time. There's nothing for you
                                to do, we will sync with QBO as soon as their system becomes
                                available.
                              </p>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const Row = ({ children }: { children: ReactNode }) => {
  return <div className="grid grid-cols-[160px_1fr] items-end">{children}</div>;
};

const RowLabel = ({ children }: { children: ReactNode }) => {
  return <div className="text-sm font-light">{children}</div>;
};

import { gql } from '__generated__/gql';

export const QUERY_GET_ALL_PROJECT_TEAM_MEMBERS = gql(`
    query GetAllProjectTeamMembers($customerId: String!, $projectId: String!) {
        projectTeamMembers(customerId: $customerId, projectId: $projectId) {
            userId
            fullName {
                first
                last
            }
            email
            signedProfilePhotoUrl
            unsignedProfilePhotoUrl
        }
    }
`);

export const MUTATION_ADD_TEAM_MEMBER = gql(`
    mutation AddProjectTeamMember($customerId: String!, $projectId: String!, $teamMemberId: String!) {
        addProjectTeamMember(customerId: $customerId, projectId: $projectId, teamMemberId: $teamMemberId) {
            userId
            fullName {
                first
                last
            }
            email
            signedProfilePhotoUrl
            unsignedProfilePhotoUrl
        }
    }
`);

export const MUTATION_REMOVE_TEAM_MEMBER = gql(`
    mutation RemoveProjectTeamMember($customerId: String!, $projectId: String!, $teamMemberId: String!) {
        removeProjectTeamMember(customerId: $customerId, projectId: $projectId, teamMemberId: $teamMemberId) {
            removed
        }
    }
`);

export const QUERY_GET_CUSTOMER_TEAM_MEMBERS = gql(`
    query GetCustomerTeamMembersForAddTeamMembersForm($customerId:String!){
      getCustomerTeamMembers(customerId:$customerId) {
        userId
        fullName {
          first
          last
        }
        email
        signedProfilePhotoUrl
        role
      }
    }
`);

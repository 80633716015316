import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { TextField } from '@aws-amplify/ui-react';
import { gql } from '__generated__/gql';
import { RoundButton } from 'components/atoms/Buttons';
import { useForm } from 'react-hook-form';
import { AppState, useAppState } from 'stores/UserStore';
import { onApolloError, toCents, toDollarDisplay } from 'utils';
import { QUERY_GET_PAYMENTS } from './PaymentsTable';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const QUERY_GET_CUSTOMER_ACCOUNT = gql(`
  query GetCustomerAccount($customerId: String!) {
    getCustomerAccount(customerId: $customerId) {
      creditBalanceCents
    }
  }
`);

const QUERY_GET_PLAID_LINKED_ACCOUNT_SUMMARY = gql(`
  query GetPlaidLinkedAccountSummaryForRepayment($customerId: String!) {
    getPlaidLinkedAccountSummary(customerId: $customerId) {
      bank,
      last4,
      accountType
    }
  }
`);

export const MUTATION_CREATE_REPAYMENT = gql(`
  mutation CreateRepayment($customerId: String!, $repaymentAmount: Int!) {
    createRepayment(customerId: $customerId, repaymentAmount: $repaymentAmount) {
      repaymentId
      status
      message
    }
  }
`);

type ManualRepaymentFormProps = {
  customerId: string;
  setAlertMsg: (value: Record<string, any>) => void;
  onClose: () => void;
};
export const ManualRepaymentForm = (props: ManualRepaymentFormProps) => {
  const { customerId, setAlertMsg, onClose } = props;
  const [setErrorMsg] = useAppState((state: AppState) => [state.setErrorMsg]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const { data: customerAccountData, loading: customerAccountLoading } = useQuery(
    QUERY_GET_CUSTOMER_ACCOUNT,
    {
      variables: {
        customerId,
      },
    }
  );

  const { data: linkedAccountSummary } = useQuery(QUERY_GET_PLAID_LINKED_ACCOUNT_SUMMARY, {
    variables: {
      customerId,
    },
  });

  const [createRepayment, { loading: createRepaymentLoading }] = useMutation(
    MUTATION_CREATE_REPAYMENT,
    {
      refetchQueries: [{ query: QUERY_GET_PAYMENTS, variables: { customerId, pageNumber: 0 } }],
      onError: (error: ApolloError) => {
        onApolloError(error, setErrorMsg, [
          'RepaymentCreateError',
          'PlaidDisconnectedLinkedAccountError',
        ]);
      },
    }
  );

  const onSubmit = (formData: any) => {
    formData.repaymentAmount &&
      createRepayment({
        variables: {
          customerId: customerId,
          repaymentAmount: toCents(formData.repaymentAmount),
        },
      }).then((response) => {
        const repaymentResult = response.data?.createRepayment;
        if (repaymentResult?.repaymentId) {
          setAlertMsg({ text: 'Payment success!' });
          onClose();
        }
      });
  };

  if (customerAccountLoading) {
    return <div>Loading account balance...</div>;
  }

  const creditBalance = customerAccountData?.getCustomerAccount?.creditBalanceCents || 0;

  const accountSummary = linkedAccountSummary?.getPlaidLinkedAccountSummary;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-8 p-4">
      <div>
        <span>Current credit balance</span>
        <span>{toDollarDisplay(creditBalance / 100)}</span>
      </div>
      <div>
        <TextField
          label="Transfer from"
          className="rounded-lg"
          type="text"
          disabled
          value={[accountSummary?.bank, accountSummary?.last4].join(' ')}
        />
      </div>
      <div>
        <TextField
          label="Amount"
          className="rounded-lg"
          type="number"
          step=".01"
          hasError={!!errors.repaymentAmount}
          innerStartComponent={<div className="relative top-1/2 translate-y-[-50%] ml-4">$</div>}
          {...register('repaymentAmount', { required: true, min: 0.01, max: creditBalance / 100 })}
        />
        {errors.repaymentAmount?.type === 'required' && (
          <p role="alert">Payment amount is required</p>
        )}
        {errors.repaymentAmount?.type === 'min' && (
          <p role="alert">Payment must be greater than zero</p>
        )}
        {errors.repaymentAmount?.type === 'max' && (
          <p role="alert">Payment must be less than the current credit balance</p>
        )}
      </div>
      {isSubmitting || createRepaymentLoading ? (
        <LoadingSpinner />
      ) : (
        <RoundButton
          text="Make payment"
          disabled={isSubmitting || createRepaymentLoading}
          type="submit"
        />
      )}
    </form>
  );
};

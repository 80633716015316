import * as Sentry from '@sentry/react';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect, useState } from 'react';
import { PlaidLinkError, PlaidLinkOnExitMetadata, usePlaidLink } from 'react-plaid-link';
import { CREATE_PLAID_LINK_TOKEN_MUTATION } from './data';
import { useMutation } from '@apollo/client';

class PlaidLinkClientError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PlaidLinkClientError';
  }
}

export const PlaidLink = ({
  onConnectAccount,
  onFailure,
  onExit,
  accountType = 'deposit',
  isUpdatingAccount = false,
}: {
  onConnectAccount: (publicToken: string, accountId: string) => void;
  onFailure: () => void;
  onExit?: () => void;
  accountType?: 'deposit' | 'transactionFeed';
  isUpdatingAccount?: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [linkToken, setLinkToken] = useState<string>();

  const [generateLinkToken] = useMutation(CREATE_PLAID_LINK_TOKEN_MUTATION, {
    onCompleted: ({ createPlaidLinkToken }) => {
      if (createPlaidLinkToken?.token) {
        setLinkToken(createPlaidLinkToken.token);
      }
    },
  });

  useEffect(() => {
    if (activeCompanySlug) {
      generateLinkToken({
        variables: {
          companySlug: activeCompanySlug,
          updateAccount: isUpdatingAccount,
          accountType,
        },
      });
    }
  }, []);

  const { open, ready } = usePlaidLink({
    token: linkToken ?? '',
    onSuccess: (publicToken, metadata) => {
      onConnectAccount(publicToken, metadata.accounts[0].id);
    },
    onExit: (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        generateLinkToken({
          variables: {
            companySlug: activeCompanySlug,
            updateAccount: isUpdatingAccount,
            accountType,
          },
        });
      } else {
        if (error) {
          Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtras({ error, metadata });
            Sentry.captureException(
              new PlaidLinkClientError('Customer unable to link account in Plaid')
            );
          });
          analytics.track('User encountered an error in Plaid Link', {
            error,
            metadata,
            companySlug: activeCompanySlug,
          });
        } else {
          analytics.track('User exited Plaid Link without connecting an account', {
            error,
            metadata,
            companySlug: activeCompanySlug,
          });
        }
        onFailure();
      }
      onExit?.();
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  return (
    <div className="flex flex-col items-center">
      {!linkToken && (
        <div className="flex items-center">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

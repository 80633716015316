import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Transaction, TransactionTag } from '__generated__/graphql';
import { Workflow, WorkflowStep } from 'components/widgets/Workflow';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Memo from './steps/Memo';
import Preview from './steps/Preview';
import ProjectStep from './steps/Project';
import Receipt from './steps/Receipt';
import RoomPoCode from './steps/RoomPoCode';
import Summary from './steps/Summary';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const MUTATION_UPDATE_TRANSACTION = gql(`
  mutation UpdateTransactionFromTaggingWorkflow($companySlug: String!, $transactionId: String!, $transactionMetadata: TransactionMetadataInput!) {
    updateTransaction(companySlug: $companySlug, transactionId: $transactionId, transactionMetadata: $transactionMetadata) {
      id
      shortName
      amountWithDirection
      parentTransactionId
      amountWithDirection
      shortName
      projectName
      accountingSyncStatus
      projectId
      receipt {
        imageUrl
      }
      accountingTag {
        tagName
        value
      }
      rooms
      categories
      poCodeTag {
        tagName
        value
      }
      memo
      missingFields {
        memo
        receipt
        roomTag
        categoryTag
      }
    }
  }
`);

const TagPurchaseWorkflow = ({
  companySlug,
  model,
}: {
  companySlug: string;
  model: Transaction | null;
}) => {
  const [currentStep, setCurrentStep] = useState('preview');
  const [updateTransaction, { loading: updateTransactionLoading }] = useMutation(
    MUTATION_UPDATE_TRANSACTION,
    {
      refetchQueries: [
        'GetTransaction',
        'GetRecentTransactionAndAuthorizationsForReview',
        'GetProjectTransactionAmountsByTag',
      ],
    }
  );
  const [roomTags, setRoomTags] = useState<string[]>([]); // e.g., room_living_room or room_dining_room
  const [categoryTags, setCategoryTags] = useState<string[]>([]); // e.g., category_appliances or category_furniture
  const [poCodeTag, setPoCodeTag] = useState('');
  const [memoTag, setMemoTag] = useState('');
  const [projectData, setProjectData] = useState<{ projectId: string; tags: TransactionTag[] }>();
  const [progress, setProgress] = useState(0);
  const numSteps = 6;
  const navigate = useNavigate();
  const closeHref = '/transactions';
  const [roomsError, setRoomsError] = useState('');
  const [categoriesError, setCategoriesError] = useState('');

  useEffect(() => {
    switch (currentStep) {
      case 'preview':
        setProgress(1);
        break;
      case 'project':
        setProgress(2);
        break;
      case 'receipt':
        setProgress(3);
        break;
      case 'memo':
        setProgress(4);
        break;
      case 'room-po':
        setProgress(5);
        break;
      case 'summary':
        setProgress(6);
        break;
    }
  }, [currentStep]);

  useEffect(() => {
    setRoomTags(model?.rooms ?? []);
    setCategoryTags(model?.categories ?? []);
    setPoCodeTag(model?.poCodeTag?.value ?? '');
    setMemoTag(model?.memo ?? '');
  }, [model]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      } else if (event.key === 'Escape') {
        navigate(closeHref);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    roomTags.length > 10 ? setRoomsError('Maximum of 10 rooms') : setRoomsError('');
  }, [roomTags]);

  const addRoomTag = (roomName: string) => {
    if (roomTags.length >= 10) {
      setRoomsError('Maximum of 10 rooms');
    }
    if (roomTags.length < 10 && !roomTags.includes(roomName)) {
      const updatedRoomTags = [...roomTags, roomName];
      setRoomTags(updatedRoomTags);
    }
  };

  const addCategoryTag = (categoryName: string) => {
    if (categoryTags.length >= 10) {
      setCategoriesError('Maximum of 10 categories');
    }
    if (categoryTags.length < 10 && !categoryTags.includes(categoryName)) {
      const updatedCategoryTags = [...categoryTags, categoryName];
      setCategoryTags(updatedCategoryTags);
    }
  };

  const onClickCancel = (roomName: string, type: string) => {
    if (type === 'room') {
      const updatedRoomTags = roomTags.filter((roomTag) => roomTag !== roomName);
      setRoomTags(updatedRoomTags);
    } else if (type === 'category') {
      const updatedCategoryTags = categoryTags.filter((categoryTag) => categoryTag !== roomName);
      setCategoryTags(updatedCategoryTags);
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 'preview':
        setCurrentStep('project');
        break;
      case 'project':
        setCurrentStep('receipt');
        break;
      case 'receipt':
        setCurrentStep('memo');
        break;
      case 'memo':
        setCurrentStep('room-po');
        break;
      case 'room-po':
        setCurrentStep('summary');
        break;
      case 'summary':
        break;
    }
  };

  if (!model) {
    return <LoadingSpinner />;
  }

  const onSave = () => {
    updateTransaction({
      variables: {
        companySlug,
        transactionId: model.id,
        transactionMetadata: {
          poCode: poCodeTag,
          memo: memoTag,
          projectId: projectData?.projectId ?? null,
          rooms: roomTags,
          categories: categoryTags,
        },
      },
    }).then(() => {
      setCurrentStep('summary');
    });
  };

  return (
    <Workflow currentStep={currentStep} progress={[progress, numSteps]}>
      <WorkflowStep
        step="preview"
        currentStep={currentStep}
        ctaButton={{ text: 'Add details', onClick: handleNext }}
        backButton={{ hidden: true }}
        ref={buttonRef}
      >
        <Preview model={model} />
      </WorkflowStep>
      <WorkflowStep
        step="project"
        currentStep={currentStep}
        ctaButton={{ onClick: handleNext }}
        backButton={{ onClick: () => setCurrentStep('preview') }}
        ref={buttonRef}
      >
        <ProjectStep
          customerId={companySlug}
          projectId={projectData?.projectId ?? model.projectId ?? ''}
          setProjectData={setProjectData}
          ref={buttonRef}
        />
      </WorkflowStep>
      <WorkflowStep
        step="receipt"
        currentStep={currentStep}
        ctaButton={{ onClick: handleNext }}
        backButton={{ onClick: () => setCurrentStep('project') }}
        ref={buttonRef}
      >
        <Receipt customerId={companySlug} model={model} />
      </WorkflowStep>
      <WorkflowStep
        step="memo"
        currentStep={currentStep}
        ctaButton={{ onClick: handleNext }}
        backButton={{ onClick: () => setCurrentStep('receipt') }}
        ref={buttonRef}
      >
        <Memo memo={memoTag} setMemo={setMemoTag} />
      </WorkflowStep>
      <WorkflowStep
        step="room-po"
        currentStep={currentStep}
        ctaButton={{ onClick: onSave, disabled: updateTransactionLoading }}
        backButton={{
          onClick: () => setCurrentStep('memo'),
          disabled: updateTransactionLoading,
        }}
        ref={buttonRef}
      >
        <RoomPoCode
          loading={updateTransactionLoading}
          roomTags={roomTags}
          categoryTags={categoryTags}
          addRoomTag={addRoomTag}
          addCategoryTag={addCategoryTag}
          poCode={poCodeTag}
          setPoCode={setPoCodeTag}
          onClickCancel={onClickCancel}
          roomsError={roomsError}
          categoriesError={categoriesError}
          tags={projectData?.tags}
          projectId={projectData?.projectId}
        />
      </WorkflowStep>
      <WorkflowStep
        step="summary"
        currentStep={currentStep}
        ctaButton={{ hidden: true }}
        backButton={{ hidden: true }}
        ref={buttonRef}
      >
        <Summary model={model} />
      </WorkflowStep>
    </Workflow>
  );
};

export default TagPurchaseWorkflow;

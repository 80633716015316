import { RoundButton } from 'components/atoms/Buttons';
import { Button } from 'components/atoms/Buttons/Button';
import { UnderlineButton } from 'components/atoms/Buttons/UnderlineButton';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isUsingMobile } from 'utils';
import { ArrowLeft, CornerDownLeft } from 'lucide-react';

type ButtonContents = {
  text?: string;
  hidden?: boolean;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type WorkflowStepProps = {
  backButton: ButtonContents;
  buttonsCentered?: boolean;
  children: React.ReactNode;
  ctaButton: ButtonContents;
  currentStep: string;
  secondaryButton?: ButtonContents;
  step: string;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const WorkflowStep = React.forwardRef<HTMLButtonElement, WorkflowStepProps>((props, ref) => {
  const {
    currentStep,
    step,
    ctaButton,
    backButton,
    buttonsCentered,
    secondaryButton,
    children,
    onKeyDown,
  } = props;
  let ctaRef = React.useRef<HTMLButtonElement>(null);

  let isMobile = isUsingMobile();

  useEffect(() => {
    if (currentStep === step) {
      ctaRef?.current?.focus();
    }
  }, [currentStep, step]);

  return currentStep === step ? (
    <div className="flex flex-col mx-4 sm:mx-8 sm:w-[600px]" onKeyDown={onKeyDown}>
      {!backButton.hidden && (
        <div className="flex">
          {backButton.href ? (
            <Link to={backButton.href}>
              <ArrowLeft />
              <UnderlineButton
                className="font-semibold"
                text="Back"
                disabled={backButton.disabled || false}
              />
            </Link>
          ) : (
            <button
              className="mb-5"
              onClick={(e) => backButton.onClick && backButton.onClick(e)}
              disabled={backButton.disabled || false}
            >
              <div className="cursor-pointer flex justify-center gap-2">
                <ArrowLeft />
                <label className="underline cursor-pointer font-semibold">Back</label>
              </div>
            </button>
          )}
        </div>
      )}
      <div onKeyDown={onKeyDown}>{children}</div>
      <div
        className={`mt-5 flex flex-row ${buttonsCentered ? 'justify-center' : 'justify-between'}`}
      >
        <div className="flex items-center mt-6 mb-10 w-full justify-between">
          {!ctaButton.hidden && (
            <div className="flex flex-row">
              <div>
                {ctaButton.href ? (
                  <Link to={ctaButton.href}>
                    <RoundButton
                      className="font-semibold"
                      text={ctaButton.text || 'Next'}
                      ref={ref}
                    />
                  </Link>
                ) : (
                  <RoundButton
                    className="font-semibold"
                    text={ctaButton.text || 'Next'}
                    onClick={(e) => ctaButton.onClick && ctaButton.onClick(e)}
                    disabled={ctaButton.disabled || false}
                    ref={ref}
                  />
                )}
              </div>
              <div className="flex mt-2">
                {!isMobile && (
                  <>
                    <div className="bg-secondary-400 ml-6 mr-2 h-8 w-8 grid place-items-center rounded">
                      <CornerDownLeft />
                    </div>
                    <p className="text-asphalt">or Press Enter</p>
                  </>
                )}
              </div>
            </div>
          )}
          {secondaryButton &&
            (secondaryButton.href ? (
              <Link to={secondaryButton.href}>
                <Button
                  variant="secondary"
                  text={secondaryButton.text || ''}
                  disabled={secondaryButton.disabled || false}
                />
              </Link>
            ) : (
              <RoundButton
                variant="secondary"
                text={secondaryButton.text || ''}
                onClick={secondaryButton.onClick}
                disabled={secondaryButton.disabled || false}
              />
            ))}
        </div>
      </div>
    </div>
  ) : null;
});

export default WorkflowStep;

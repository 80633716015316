import { ProjectClientDetails } from './ProjectClientDetails';
import { Layout, SettingsCard } from './Layout';
import { UpdateProjectStatus } from './UpdateProjectStatus';
import { ProjectTags } from './ProjectTags';
import { ProjectDetails } from './ProjectDetails';
import { ProjectBudget } from './ProjectBudget';
import {
  AddressOutput,
  ClientInfoOutput,
  ProjectStatus,
  TransactionTag,
} from '__generated__/graphql';
import { TeamMembers } from './TeamMembers';

const Settings = ({
  customerId,
  projectId,
  status,
  name,
  budget,
  description,
  clientInfo,
  address,
  tags,
}: {
  customerId: string;
  cardId?: string;
  budget?: number;
  projectId: string;
  status: ProjectStatus;
  name: string;
  description: string;
  clientInfo?: ClientInfoOutput;
  address?: AddressOutput;
  tags: TransactionTag[];
}) => {
  return (
    <Layout>
      <Layout.LeftPanel>
        <SettingsCard>
          <UpdateProjectStatus
            customerId={customerId}
            projectId={projectId}
            currentStatus={status}
          />
        </SettingsCard>
      </Layout.LeftPanel>
      <Layout.RightPanel>
        <ProjectDetails
          projectId={projectId}
          name={name}
          description={description}
          customerId={customerId}
        />
        <ProjectClientDetails
          customerId={customerId}
          projectId={projectId}
          clientInfo={clientInfo}
          address={address}
        />
        <ProjectBudget projectId={projectId} budget={budget} customerId={customerId} />
        <ProjectTags customerId={customerId} projectId={projectId} tags={tags} />
        <SettingsCard>
          <div className="border-primary border-b pb-4 mb-7">
            <h3 className="text-black text-xl">Team Members</h3>
          </div>
          <TeamMembers customerId={customerId} projectId={projectId} />
        </SettingsCard>
      </Layout.RightPanel>
    </Layout>
  );
};

export default Settings;

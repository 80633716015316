import { HTMLAttributes, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { OutlinedWidgetWithSwitch } from './OutlinedWidgetWithSwitch';
import { MUTATION_UPDATE_SETTINGS } from './data';
import { SettingsSwitchProps } from './index';
import { onApolloError } from '../../../../../../utils';

export const RequireProjectApproval = ({
  loading,
  customerId,
  setErrorMsg,
  defaultEnabled = false,
  ...props
}: SettingsSwitchProps & HTMLAttributes<HTMLDivElement>) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);
  useEffect(() => {
    setIsEnabled(defaultEnabled);
  }, [defaultEnabled]);

  const [updateSettings, { loading: isUpdating, called }] = useMutation(MUTATION_UPDATE_SETTINGS, {
    onError: (error) => onApolloError(error, setErrorMsg, ['UpdateTeamSettingsFailed']),
  });
  const onUpdate = (checked: boolean) => {
    updateSettings({
      variables: {
        customerId,
        settings: {
          approvalRequiredProject: checked,
        },
      },
    });
  };
  return (
    <OutlinedWidgetWithSwitch
      title="Require admin approval for project creation"
      loading={loading}
      checked={isEnabled}
      isUpdating={isUpdating}
      hasUpdated={called}
      onChange={(checked) => {
        setIsEnabled(checked);
        onUpdate(checked);
      }}
    >
      <div className="">Admin approval for project creation</div>
    </OutlinedWidgetWithSwitch>
  );
};

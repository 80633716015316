import { useQuery, useSubscription } from '@apollo/client';
import { gql } from '__generated__/gql';

const GET_INTUIT_APP_CONNECTION_STATUS_QUERY = gql(`
  query GetIntuitAppConnectionForTransactionDetailsQboSyncStatus($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      disconnectedAt
    }
  }
`);

export const useIntuitAppConnection = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const userNeedsToReconnectTheirQboAccount =
    data?.getIntuitAppConnection?.id && data.getIntuitAppConnection.disconnectedAt;

  return { userNeedsToReconnectTheirQboAccount, loading, error };
};

export const QBO_SYNC_STATUS_TRANSACTION_DETAILS_QUERY = gql(`
  query GetTransactionDetailsForQboSyncStatus($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      type
      missingFields {
        memo
        receipt
        roomTag
        categoryTag
        accountingTag
      }
      qboAccountingIntegrationSync {
        id
        expenseId
        syncedAt
      }
      accountingSyncStatus
      zenaType
    }
  }
`);

export const useQboSyncTransactionDetails = ({
  transactionId,
  companySlug,
}: {
  companySlug: string;
  transactionId: string;
}) => {
  const { data, loading, error } = useQuery(QBO_SYNC_STATUS_TRANSACTION_DETAILS_QUERY, {
    variables: {
      companySlug,
      transactionId,
    },
    skip: !companySlug || !transactionId,
  });

  return {
    isSyncedWithQbo:
      data?.getTransaction?.qboAccountingIntegrationSync?.syncedAt &&
      data.getTransaction.qboAccountingIntegrationSync.expenseId,
    transaction: data?.getTransaction ?? null,
    isImportedTransaction: data?.getTransaction?.type === 'IMPORTED',
    accountingSyncStatus: data?.getTransaction?.accountingSyncStatus,
    zenaType: data?.getTransaction?.zenaType,
    loading,
    error,
  };
};

const SUBSCRIPTION_ON_SYNC_QBO_TXN = gql(`
  subscription OnSyncQboTxn($companySlug: String!) {
    onSyncQboTxn(resourceId: $companySlug, resourceType: "company") {
      resourceId
      resourceType
    }
  }
`);

export const useTransactionFeedSyncSubscription = ({
  companySlug,
  transactionId,
}: {
  companySlug: string;
  transactionId: string;
}) => {
  useSubscription(SUBSCRIPTION_ON_SYNC_QBO_TXN, {
    variables: { companySlug },
    onData: async ({ client }) => {
      client.query({
        query: QBO_SYNC_STATUS_TRANSACTION_DETAILS_QUERY,
        variables: { companySlug, transactionId: transactionId },
        fetchPolicy: 'network-only',
      });
    },
  });
};

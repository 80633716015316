import { RoundButton } from 'components/atoms/Buttons';
import { useState, useEffect } from 'react';
import { cn } from 'utils';
import { useReward } from 'react-rewards';
import { gql } from '__generated__';
import { ApolloError, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { onApolloError } from 'utils';
import { useAppState } from 'stores/UserStore';
import { useActiveCompany } from 'providers/ActiveCompany';

interface LoadTransactionsProps {
  setCurrentStep: Function;
  onClose: () => void;
  plaidDetails: { publicToken: string; accountId: string };
}

const LINK_PLAID_ACCOUNT_MUTATION = gql(`
  mutation LinkPlaidAccount($companySlug: String!, $publicToken: String!, $accountId: String!) {
    createPlaidLinkedAccount(companySlug: $companySlug, accountType: "transactionFeed", publicToken: $publicToken, accountId: $accountId) {
      message
      transactionFeedAccountIds
    }
  }
`);

const QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS = gql(`
  query GetAllTransactionFeedAccounts($companySlug: String!) {
    getPlaidTransactionFeedAccounts(companySlug: $companySlug) {
      id
      refreshedAt
    }
  }
`);

const SUBSCRIPTION_TRANSACTION_FEED_SYNC = gql(`
  subscription OnTransactionFeedSync($companySlug: String!) {
    onTransactionFeedSync(resourceId: $companySlug, resourceType: "company") {
      resourceId
      resourceType
    }
  }
`);

const REFETCH_PLAID_CONNECTION_COMPLETE_QUERY = gql(`
  query RefetchPlaidConnectionCompleteQuery($companySlug: String!)  {
    getPlaidConnectionStatus(companySlug: $companySlug) {
      connected
    }
  }
`);

const LoadTransactions = ({ setCurrentStep, onClose, plaidDetails }: LoadTransactionsProps) => {
  const [loadingPercent, setLoadingPercent] = useState('0%');
  const [transitionTime, setTransitionTime] = useState('8000ms');
  const [loaderColor, setLoaderColor] = useState('bg-lavender');
  const [error, setError] = useState('');
  const [newAccountIds, setNewAccountIds] = useState<string[]>([]);
  const [finishedSyncing, setFinishedSyncing] = useState<boolean>(false);
  const setErrorMsg = useAppState((state) => state.setErrorMsg);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [currentLoadingText, setCurrentLoadingText] = useState('Connecting your accounts');
  const handshakeEmoji = '\u{1F91D}';
  const raisedHandsEmoji = '\u{1F64C}';
  const [currentEmoji, setCurrentEmoji] = useState<string>(handshakeEmoji);

  const { reward } = useReward('add-account-loading-bar', 'confetti', {
    decay: 0.9,
    angle: 90,
    spread: 135,
    elementCount: 250,
    lifetime: 1000,
  });

  const handleError = (error: ApolloError) => {
    onApolloError(error, setErrorMsg, ['PlaidLinkAccountError']);
    setLoadingPercent('100%');
    setTransitionTime('500ms');
    setLoaderColor('bg-red-800');
    setError('Something went wrong. Please try again.');
  };

  const [fetchTransactionFeedAccounts, { client }] = useLazyQuery(
    QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS,
    {
      variables: {
        companySlug: activeCompanySlug,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (!finishedSyncing) {
          const connectedAccounts = data?.getPlaidTransactionFeedAccounts;
          const newAccounts = connectedAccounts?.filter((account: any) =>
            newAccountIds.includes(account.id)
          );
          let tmpStatus = true;
          if (!newAccounts.length) {
            tmpStatus = false;
          }
          newAccounts?.forEach((account: any) => {
            if (!account.refreshedAt) {
              tmpStatus = false;
            }
          });
          setFinishedSyncing(tmpStatus);
          if (tmpStatus) {
            onSuccess();
            //TODO - fetch transactions related to the connected accounts here
          }
        }
      },
      onError: (error: ApolloError) => handleError(error),
    }
  );

  const [addPlaidAccount] = useMutation(LINK_PLAID_ACCOUNT_MUTATION, {
    onCompleted: (data) => {
      const newIds = data?.createPlaidLinkedAccount?.transactionFeedAccountIds;
      if (newIds) {
        setNewAccountIds(newIds);
      }
      fetchTransactionFeedAccounts({ variables: { companySlug: activeCompanySlug } });
    },
    onError: (error: ApolloError) => handleError(error),
    refetchQueries: [
      'GetAllTransactionFeedAccounts',
      'GetAllPlaidTransactionFeedAccounts',
      'GetAllTransactionsToAllocate',
    ],
  });

  useSubscription(SUBSCRIPTION_TRANSACTION_FEED_SYNC, {
    variables: { companySlug: activeCompanySlug },
    onData: async () => {
      client.refetchQueries({
        include: [
          'GetAllTransactionFeedAccounts',
          'GetAllPlaidTransactionFeedAccounts',
          'GetAllTransactionsToAllocate',
        ],
      });
      fetchTransactionFeedAccounts({ variables: { companySlug: activeCompanySlug } });
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setLoadingPercent('95%');

      addPlaidAccount({
        variables: {
          companySlug: activeCompanySlug,
          publicToken: plaidDetails.publicToken,
          accountId: plaidDetails.accountId,
        },
        refetchQueries: [
          {
            query: REFETCH_PLAID_CONNECTION_COMPLETE_QUERY,
            variables: {
              companySlug: activeCompanySlug,
            },
          },
        ],
      });
    }, 200);

    setTimeout(() => {
      setCurrentEmoji(raisedHandsEmoji);
      setCurrentLoadingText('Syncing transactions');
    }, 2000);
  }, []);

  const onSuccess = () => {
    setTransitionTime('500ms');
    setLoadingPercent('100%');
    setLoaderColor('bg-forest');
    reward();
  };

  const isLoading = !finishedSyncing && !error;
  const headerText = isLoading ? 'Almost there...' : error ? 'Error' : 'Success!';
  const loadingText = isLoading ? currentLoadingText : 'Done!';
  const transactionsResultText = isLoading
    ? currentEmoji // TODO - place total transactions found text here
    : error
      ? error
      : 'Your transactions have been synced';

  return (
    <>
      <h3 className="mb-6">{headerText}</h3>
      <div className="flex flex-col gap-4">
        <h4 className="text-center">{loadingText}</h4>
        <div className="relative pt-1">
          <div className="overflow-hidden rounded-full bg-secondary-300">
            <div
              className={cn('h-7 rounded-full bg-lavender transition-all ease-in-out', loaderColor)}
              style={{ transitionDuration: transitionTime, width: loadingPercent }}
            >
              <span id="add-account-loading-bar" className="absolute left-1/2"></span>
            </div>
          </div>
        </div>
        <p
          className={cn(
            'font-bold max-w-52 text-center sm:h-12 m-auto',
            isLoading ? 'text-3xl' : ''
          )}
        >
          {transactionsResultText}
        </p>
        <p className="text-center max-w-80 mt-8">
          Be sure to add all your business accounts to get the whole picture!
        </p>
        <div className="flex flex-col gap-4 sm:h-[100px]">
          {!isLoading && (
            <>
              <RoundButton onClick={() => setCurrentStep(1)}>
                {error ? 'Try again' : 'Add another account'}
              </RoundButton>

              {!error && (
                <RoundButton variant="secondary" onClick={() => onClose()}>
                  I'm done, organize my data
                </RoundButton>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoadTransactions;

import { RewardTierName } from 'utils';

const bgMap = {
  Green: 'bg-rewards-green',
  Gold: 'bg-rewards-gold',
  Platinum: 'bg-marble',
  Diamond: 'bg-rewards-diamond',
  Elite: 'bg-primary text-white',
};
type RewardsProgressProps = {
  totalSpentCents: number;
  valuesCents: number[];
};

const RewardsProgressBar = ({ totalSpentCents, valuesCents }: RewardsProgressProps) => {
  const steps = ['Green', 'Gold', 'Platinum', 'Diamond', 'Elite'];
  const [goldSpendCents, platinumSpendCents, diamondSpendCents, eliteSpendCents] = valuesCents;

  const getFillPercentage = () => {
    // green: 0 <= totalSpentCents <= goldSpendCents: 0-10
    // gold: goldSpendCents <= totalSpentCents <= platinumSpendCents: 10-40
    // platinum: platinumSpendCents <= totalSpentCents <= diamondSpendCents: 40-70
    // diamond: diamondSpendCents <= totalSpentCents <= eliteSpendCents: 70-100
    let centsLeftOver;
    if (0 <= totalSpentCents && totalSpentCents < goldSpendCents) {
      return (totalSpentCents / goldSpendCents) * 25;
    } else if (goldSpendCents <= totalSpentCents && totalSpentCents < platinumSpendCents) {
      centsLeftOver = totalSpentCents - goldSpendCents;
      return 25 + (centsLeftOver / platinumSpendCents) * 25;
    } else if (platinumSpendCents <= totalSpentCents && totalSpentCents < diamondSpendCents) {
      centsLeftOver = totalSpentCents - platinumSpendCents;
      return 50 + (centsLeftOver / diamondSpendCents) * 25;
    } else if (diamondSpendCents <= totalSpentCents && totalSpentCents < eliteSpendCents) {
      centsLeftOver = totalSpentCents - diamondSpendCents;
      return 75 + (centsLeftOver / eliteSpendCents) * 25;
    } else if (totalSpentCents >= eliteSpendCents) {
      return 100;
    }
  };
  const fillPercentage = getFillPercentage();

  return (
    <div className="relative flex flex-col h-full mt-1">
      <div className="flex flex-col h-full justify-between">
        {steps.reverse().map((step, index) => (
          <div
            className={`z-20 w-3 h-3 rounded-full border-2 border-white ${
              bgMap[step as RewardTierName]
            }`}
            key={step}
          ></div>
        ))}
      </div>
      <div className="absolute w-full h-full flex flex-col justify-end left-1">
        <div className="w-1 h-full bg-gray-200 absolute centerVertical"></div>
        <div
          className={`w-1 z-10 bg-black mb-[2px]`}
          style={{ height: `${fillPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default RewardsProgressBar;

import { DaysDropdown } from 'components/atoms/DaysDropdown';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { ReactNode } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { cn, formatNumberAsDollars } from 'utils';
import { formatNumberAbbreviated } from 'utils/number';

type Days = 30 | 60 | 90 | 120;

export interface PaymentsVsSpendChartData {
  id: string;
  name: string;
  payments: number;
  spend: number;
}

export const PaymentsVsSpendChart = ({
  totalMargin,
  days,
  onChangeDays,
  isLoading,
  data,
  className = '',
  disabled,
}: {
  totalMargin: number;
  days: Days;
  onChangeDays: (day: Days) => void;
  isLoading?: boolean;
  data: PaymentsVsSpendChartData[];
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={cn('w-full grid gap-12', className)}>
      <div className="w-full grid gap-4">
        <div className="flex flex-row justify-between items-center">
          <CardTitle>Payments vs Spend</CardTitle>
          <DaysDropdown value={days} onChange={onChangeDays} disabled={disabled} />
        </div>

        <div className="flex flex-row justify-between items-end">
          <div>
            <div className="text-3xl">
              {isLoading ? (
                <RectangleSkeleton height="large" width="large" />
              ) : (
                `$${formatNumberAbbreviated({ amount: totalMargin })}`
              )}
            </div>
            <div className="text-sm text-muted">Margin (left over)</div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <DotContainer>
              <Dot className="bg-forest-700" />
              <div className="text-sm">Payments</div>
            </DotContainer>
            <DotContainer>
              <Dot className="bg-lavender" />
              <div className="text-sm">Spend</div>
            </DotContainer>
          </div>
        </div>
      </div>

      <div className="w-full aspect-[2.58/1]">
        {isLoading && (
          <div className="w-full h-full grid gap-4">
            <RectangleSkeleton />
            <RectangleSkeleton />
            <RectangleSkeleton />
            <RectangleSkeleton />
          </div>
        )}
        {!isLoading && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} barSize={80} barGap={10}>
              <XAxis dataKey="name" />
              <YAxis
                width={65}
                tickFormatter={(value) => `$${formatNumberAbbreviated({ amount: value })}`}
              />
              {!disabled && <Tooltip formatter={(value) => formatNumberAsDollars(Number(value))} />}
              <CartesianGrid
                verticalCoordinatesGenerator={() => {
                  return [];
                }}
              />

              <Bar dataKey="payments" className="fill-forest-700" radius={[8, 8, 0, 0]} />
              <Bar dataKey="spend" className="fill-lavender" radius={[8, 8, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

const Dot = ({ className }: { className: string }) => {
  return <div className={cn('w-3 h-3 rounded-full', className)} />;
};

const DotContainer = ({ children }: { children: ReactNode }) => (
  <div className="flex gap-1 flex-row justify-start items-center">{children}</div>
);

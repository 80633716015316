import { useLayoutEffect, useState } from 'react';
import { Maybe } from 'utils/types';

interface WindowSize {
  width: Maybe<number>;
  height: Maybe<number>;
}

export const useWindowSize = () => {
  const [size, setSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

import { RoundButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TabButton, TabsContainer } from 'components/atoms/Tab';
import { ProjectsCollection } from './ProjectsCollection';
import { CreateNewProjectModal } from './CreateNewProjectModal';
import { ProjectStatus } from '__generated__/graphql';
import { useActiveCompany } from 'providers/ActiveCompany';
import { companyRole } from 'hooks/access';
import { Notifications } from 'providers/notifications';

type ProjectsPageTab = 'ACTIVE' | 'COMPLETED';

const ProjectsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlTab = searchParams.get('tab');
  const [showOnboarding, setShowOnboarding] = useState(!!searchParams.get('new'));
  const [activeTab, setActiveTab] = useState<ProjectsPageTab>(
    ['ACTIVE', 'COMPLETED'].includes(urlTab ?? '') ? (urlTab as ProjectsPageTab) : 'ACTIVE'
  );
  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';

  // tab url serialization
  useEffect(() => {
    if (urlTab !== activeTab) {
      navigate({
        pathname: location.pathname,
        search: `?tab=${activeTab}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <PageTitle hidden text="Projects" />

      <div className="pt-7 px-4 lg:pt-11 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
        <section className="flex justify-between gap-8 align-baseline items-center">
          <h1 className="text-xl lg:text-4xl">Projects</h1>
          <Link to="/projects?new=true">
            {!isBookkeeper && (
              <RoundButton
                onClick={() => setShowOnboarding(true)}
                icon="plus"
                text="Create Project"
                className="whitespace-nowrap"
              />
            )}
          </Link>
        </section>

        <TabsContainer>
          <TabButton
            isActive={activeTab === 'ACTIVE'}
            onClick={() => {
              if (activeTab !== 'ACTIVE') {
                setActiveTab('ACTIVE');
              }
            }}
          >
            Active
          </TabButton>
          <TabButton
            isActive={activeTab === 'COMPLETED'}
            onClick={() => {
              if (activeTab !== 'COMPLETED') {
                setActiveTab('COMPLETED');
              }
            }}
          >
            Completed
          </TabButton>
        </TabsContainer>

        <section className="flex flex-row items-start justify-center md:justify-start grow overflow-scroll pb-8">
          <ProjectsCollection
            companySlug={activeCompanySlug}
            filterBy={activeTab === 'ACTIVE' ? ProjectStatus.active : ProjectStatus.completed}
            onCreateProject={() => setShowOnboarding(true)}
          />
        </section>
      </div>

      <Notifications />

      <CreateNewProjectModal
        isOpen={showOnboarding}
        onClose={() => {
          setShowOnboarding(false);
          navigate('/projects');
        }}
      />
    </>
  );
};

export default ProjectsPage;

import { RoundButton } from 'components/atoms/Buttons';
import { Donut } from './Donut/Donut';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { useState } from 'react';
import { Checklist } from './Checklist';
import { Link } from 'react-router-dom';
import { CircleSkeleton } from 'components/atoms/Skeletons';

export const StayOrganizedWidget = ({
  stepsComplete,
  hasProject,
  hasZenaCard,
  hasLinkedAccounts,
  hasTransactions,
  hasInvitedTeammates,
  allDone,
  stepsLoading,
  transactionsLoading,
  approvalsLoading,
  transactionsCount,
  approvalsCount,
  admin,
  enableClicks = false,
}: {
  stepsComplete: number;
  hasProject: boolean;
  hasZenaCard: boolean;
  hasLinkedAccounts: boolean;
  hasTransactions: boolean;
  hasInvitedTeammates: boolean;
  allDone: boolean;
  stepsLoading: boolean;
  transactionsLoading: boolean;
  approvalsLoading: boolean;
  transactionsCount: number;
  approvalsCount: number;
  admin: boolean;
  enableClicks?: boolean;
}) => {
  const [showFinishSettingUpAccount, setShowFinishSettingUpAccount] = useState(false);

  return showFinishSettingUpAccount ? (
    <Checklist
      onClose={() => setShowFinishSettingUpAccount(false)}
      stepsComplete={stepsComplete}
      hasProject={hasProject}
      hasZenaCard={hasZenaCard}
      hasLinkedAccounts={hasLinkedAccounts}
      hasTransactions={hasTransactions}
      hasInvitedTeammates={hasInvitedTeammates}
    />
  ) : (
    <div className="flex flex-col gap-4">
      <CardTitle>Stay Organized</CardTitle>
      {!allDone && admin && (
        <>
          <div className="flex justify-between">
            <div className="flex gap-4 w-48 items-center">
              <div className="w-12 h-12 min-w-12">
                {stepsLoading ? (
                  <CircleSkeleton size="large" />
                ) : (
                  <Donut segmentsCompleted={stepsComplete} />
                )}
              </div>
              <div className="text-sm">Finish setting up your account</div>
            </div>
            <div>
              <RoundButton
                disabled={stepsLoading || !enableClicks}
                variant="tertiary"
                onClick={() => enableClicks && setShowFinishSettingUpAccount(true)}
              >
                Review
              </RoundButton>
            </div>
          </div>
          <hr />
        </>
      )}
      <div className="flex justify-between">
        <div className="flex gap-4 w-48 items-center">
          {transactionsLoading ? (
            <CircleSkeleton size="large" className="min-w-12" />
          ) : (
            <div className="w-12 h-12 min-w-12 rounded-full bg-red-600 text-white flex items-center justify-center">
              {transactionsCount}
            </div>
          )}
          <div className="text-sm">Transactions need attention</div>
        </div>
        <Link to={enableClicks ? '/transactions/organize' : '#'}>
          <RoundButton disabled={transactionsLoading || !enableClicks} variant="tertiary">
            Review
          </RoundButton>
        </Link>
      </div>
      <hr />
      {admin && (
        <div className="flex justify-between">
          <div className="flex gap-4 w-48 items-center">
            {approvalsLoading ? (
              <CircleSkeleton size="large" className="min-w-12" />
            ) : (
              <div className="w-12 h-12 min-w-12 rounded-full bg-black text-white flex items-center justify-center">
                {approvalsCount}
              </div>
            )}
            <div className="text-sm">Approvals needed</div>
          </div>
          <Link to={enableClicks ? '/approval-requests' : '#'}>
            <RoundButton disabled={approvalsLoading || !enableClicks} variant="tertiary">
              Review
            </RoundButton>
          </Link>
        </div>
      )}
    </div>
  );
};

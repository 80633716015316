import { HTMLAttributes, ReactNode, forwardRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useWindowKeyboardInteraction } from '../WindowInteractionListeners/keyboard';
import { RectangleSkeleton } from '../Skeletons';
import { cn } from 'utils';

interface ListItem {
  id: string;
  value: string | number;
}

export const InputDropdown = ({
  list,
  loading = false,
  onSelectItem,
  onClose,
  className,
}: {
  list: ListItem[];
  loading?: boolean;
  onSelectItem: (id: string) => void;
  onClose: () => void;
  className?: string;
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const ref = useOnclickOutside(() => onClose());

  useWindowKeyboardInteraction({
    onKeyDown: (e) => {
      if ((e.key === 'ArrowDown' || e.key === 'Tab') && activeIndex < list.length - 1) {
        setActiveIndex(activeIndex + 1);
      }

      if (e.key === 'ArrowUp' && activeIndex > -1) {
        setActiveIndex(activeIndex - 1);
      }

      if (e.key === 'Enter' && list[activeIndex]?.id) {
        e.preventDefault();
        onSelectItem(list[activeIndex].id);
        onClose();
        setActiveIndex(-1);
      }

      if (e.key === 'Escape') {
        onClose();
      }
    },
  });

  return (
    <InputDropdownContainer className={className}>
      <InputDropdownContent ref={ref}>
        {loading && (
          <InputDropdownLoadingRow>
            <RectangleSkeleton rounded={false} height="large" />
          </InputDropdownLoadingRow>
        )}

        {!loading &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <InputDropdownResultRow
                key={item.id}
                isActive={index === activeIndex}
                onClick={() => {
                  onSelectItem(item.id);
                  onClose();
                  setActiveIndex(-1);
                }}
              >
                {item.value}
              </InputDropdownResultRow>
            );
          })}
      </InputDropdownContent>
    </InputDropdownContainer>
  );
};

export const InputDropdownResultRow = ({
  className,
  children,
  isActive = false,
  ...props
}: {
  children: ReactNode;
  isActive?: boolean;
} & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn(
        'w-full px-4 py-4 border-none text-black text-left flex justify-start rounded-none hover:!bg-tertiary-800 outline-accent-600',
        className
      )}
      style={{
        backgroundColor: isActive ? '#F7F8FC' : '#FFF',
      }}
      {...props}
    >
      {children}
    </button>
  );
};

export const InputDropdownContainer = ({
  className,
  children,
  ...props
}: {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('h-0 w-full absolute bottom-0', className)} {...props}>
      {children}
    </div>
  );
};

export const InputDropdownContent = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
  } & HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      className={cn('h-auto absolute w-full bg-white shadow-xl box-border z-50', className)}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  );
});

export const InputDropdownLoadingRow = ({
  className,
  children,
  ...props
}: {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('w-full px-2 py-4 flex flex-row items-center justify-center', className)}
      {...props}
    >
      {children}
    </div>
  );
};

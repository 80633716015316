import React from 'react';
import { cn } from 'utils';

export const TableHead = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <thead>
      <tr className={cn('font-medium font text-asphalt border-primary border-b-2', className)}>
        {children}
      </tr>
    </thead>
  );
};

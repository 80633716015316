import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { Link, useNavigate } from 'react-router-dom';
import { RoundButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { isAdmin } from 'hooks/access';
import whiteZenaCardWood from '../../images/whiteZenaCardWood.webp';
import { ProjectCard } from '../../components/widgets/ProjectCard';
import { ProjectStatus } from '__generated__/graphql';
import { LoadingProjectCard } from 'components/widgets/ProjectCard/LoadingProjectCard';

export const QUERY_GET_PROJECTS = gql(`
  query GetProjectsForCollection($companySlug: String!, $filters: ProjectFiltersInput) {
    getProjects(customerId: $companySlug, filters: $filters, sortBy: created_at) {
        projects {
           id
           name
           budget
           approved
           description
           rejectedAt
           users
           cardId
           cardLast4Digits
           amountSpent
           status
        }
    }
  }
`);

interface ProjectsCollectionProps {
  companySlug: string;
  filterBy?: ProjectStatus;
  onCreateProject?: () => void;
}

export const ProjectsCollection = ({
  companySlug,
  filterBy,
  onCreateProject,
}: ProjectsCollectionProps) => {
  const navigate = useNavigate();
  const adminAccess = isAdmin(companySlug);

  const { loading, error, data } = useQuery(QUERY_GET_PROJECTS, {
    variables: {
      companySlug,
      filters: {
        includeUnapproved: adminAccess,
        status: filterBy,
      },
    },
  });

  const projects = data?.getProjects?.projects || [];

  const handleCreateProject = () => {
    navigate('/projects?new=true');
    if (onCreateProject) {
      onCreateProject();
    }
  };

  const emptyView = () => {
    switch (filterBy) {
      case 'active':
        return <ActiveEmptyState handleCreateProject={handleCreateProject} />;
      case 'completed':
        return <CompletedEmptyState />;
    }
  };

  if (error) return <p>Error &#x1F614; {error.message}</p>;

  return (
    <div className="content-center w-full">
      {!loading && !projects.length && emptyView()}
      <ul
        data-testid="projects"
        className="grid grid-cols-1 gap-6 md:grid-cols-[350px] lg:grid-cols-[350px_350px] 2xl:grid-cols-[350px_350px_350px]"
      >
        {loading
          ? [...Array(6)].map((_project, index) => (
              <li className="col-span-1" key={index}>
                <LoadingProjectCard />
              </li>
            ))
          : projects.map((project) => (
              <li className="col-span-1" key={project.id}>
                <Link key={project.id} to={`/projects/${project?.id}`}>
                  <ProjectCard
                    projectName={project?.name || ''}
                    budget={project?.budget}
                    remainingBudget={(project.budget || 0) - (project.amountSpent || 0)}
                    amountSpent={project?.amountSpent || 0}
                    status={project?.status || 'active'}
                  />
                </Link>
              </li>
            ))}
      </ul>
    </div>
  );
};

const ActiveEmptyState = ({ handleCreateProject }: { handleCreateProject: () => void }) => {
  return (
    <>
      <PageTitle hidden text="Projects" />
      <div className="grid">
        <div className="place-self-center">
          <img className="mt-5 max-w-[229px]" src={whiteZenaCardWood} alt="Zena Card" />
        </div>
        <div className="text-center mt-8 place-self-center flex items-center flex-col">
          <h1>Let's create your first project</h1>
          <p className="text-lg mt-6 mb-6 max-w-[529px]">
            Your project spend, come to life. Each project you create in Zena comes with its own
            virtual card that seamlessly tracks, analyzes, and allocates every (virtual) swipe.
            Experience simplicity, convenience, and financial transparency -- all in one place.
          </p>
          <RoundButton onClick={handleCreateProject} text="Create project" className="my-5 mx-4" />
        </div>
      </div>
    </>
  );
};

const CompletedEmptyState = () => {
  return (
    <>
      <PageTitle hidden text="Completed" />
      <div className="grid">
        <div className="place-self-center">
          <img className="mt-20 max-w-[229px]" src={whiteZenaCardWood} alt="Zena Card" />
        </div>
        <div className="text-center mt-8 place-self-center flex items-center flex-col">
          <h1>No completed projects... Yet</h1>
          <p className="text-lg mt-6 mb-6 max-w-[529px]">
            When a project is marked as completed it will be here - with all of its data - if you
            need it.
          </p>
          <Link to="/projects">
            <RoundButton text="View projects" className="my-5 mx-4" />
          </Link>
        </div>
      </div>
    </>
  );
};

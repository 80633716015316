import { useQuery } from '@apollo/client';
import { ListItem } from 'components/atoms/ListItem';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Searchbar } from 'components/atoms/Searchbar';
import { QUERY_GET_CUSTOMER_TEAM_MEMBERS } from './data';
import useCustomAuth from 'hooks/useCustomAuth';
import React, { useEffect, useState } from 'react';
import { nonNullable } from 'utils';
import { User } from 'types';

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  customerId: string;
  placeholder: string;
  selectedUsers: User[];
  onUserSelect: (user: User) => void;
  onUserRemove?: (user: User) => void;
  selectOneMax?: boolean;
  inputClassName?: string;
  userListClassName?: string;
  disabled?: boolean;
}

const SearchUsers: React.FC<SearchProps> = ({
  customerId,
  placeholder,
  selectedUsers,
  onUserSelect,
  onUserRemove,
  inputClassName,
  userListClassName,
  selectOneMax = false,
  disabled = false,
}) => {
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { user: currentUser } = useCustomAuth();

  const { data: getCustomerTeamMembersData, loading: getCustomerTeamMembersLoading } = useQuery(
    QUERY_GET_CUSTOMER_TEAM_MEMBERS,
    {
      variables: {
        customerId: customerId,
      },
    }
  );

  useEffect(() => {
    const selectedUserIds = selectedUsers.map((user) => user.id || '');
    setAllUsers(allUsers.filter((user) => !selectedUserIds.includes(user.id || '')));
  }, [selectedUsers]);

  useEffect(() => {
    let teamMembers = getCustomerTeamMembersData?.getCustomerTeamMembers || [];
    if (teamMembers) {
      const filteredTeamMembers = teamMembers.filter(nonNullable).map<User>((user) => ({
        id: user.userId,
        name: `${user.fullName.first} ${user.fullName.last}`,
        email: user.email,
      }));

      setAllUsers(filteredTeamMembers);
    }
  }, [getCustomerTeamMembersData]);

  const onClickItem = (item: User) => {
    if (selectedUsers.length >= 1 && selectOneMax) {
      return;
    }
    onUserSelect(item);
  };

  const onRemove = (item: User) => {
    onUserRemove && onUserRemove(item);
    if (item.name === 'You' && item.id === currentUser?.attributes?.sub) {
      setAllUsers([item, ...allUsers]);
    } else {
      setAllUsers([...allUsers, item]);
    }
  };

  return (
    <>
      <Searchbar
        data={allUsers}
        loading={getCustomerTeamMembersLoading}
        onClickItem={onClickItem}
        placeholder={placeholder}
        inputClassName={inputClassName}
        selectOneMax
        disabled={disabled}
      />
      <div className={`mt-4 h-52 overflow-y-auto ${userListClassName}`}>
        {selectedUsers.length === 0 && getCustomerTeamMembersLoading && (
          <div className="flex justify-center items-center">
            <LoadingSpinner />
          </div>
        )}
        {selectedUsers.map((user, index) => (
          <ListItem
            name={user.name}
            email={user.email}
            key={index}
            onRemove={disabled ? undefined : () => onRemove(user)}
            url={''}
          />
        ))}
      </div>
    </>
  );
};

export default SearchUsers;

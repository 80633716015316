import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { useEffect, useState } from 'react';

const TIMEOUT_SECONDS = 60;

const LOADING_BLURBS = [
  'Designing a room is like painting a masterpiece, just with furniture.',
  'Pillows are like jewelry for your couch—decorate accordingly.',
  'Pillow fights can really mess up the aesthetics of a well-designed bed.',
  'Debating the difference between teal and turquoise…',
  'Just a few more, non-billable seconds…',
  'Fashionably loading…',
  'Rearranging a few things, please hold…',
  'Just a sec, this page needed some Feng Shui..',
  'Warm tones or cool tones? That is the question.',
  'Is this page Chantilly Lace, White Heron, White Dove, Cloud White, or Simply White?',
];

const CustomerInitPage = () => {
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);
  const [currentLoadingBlurbIndex, setCurrentLoadingBlurbIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowTimeoutMessage(true);
    }, TIMEOUT_SECONDS * 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCurrentLoadingBlurbIndex =
        currentLoadingBlurbIndex + 1 > LOADING_BLURBS.length - 1 ? 0 : currentLoadingBlurbIndex + 1;
      setCurrentLoadingBlurbIndex(newCurrentLoadingBlurbIndex);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentLoadingBlurbIndex]);

  return (
    <div className="w-full h-screen flex flex-col justify-center content-center items-center bg-ivory">
      {showTimeoutMessage ? (
        <>
          <h2 className="mb-6">Something went wrong</h2>
          <p className="text-black">Unable to verify account status.</p>
          <p className="text-black mt-2">
            Please try refreshing the page. If the problem persists, reach out to{' '}
            <a href="mailto:support@getzena.com">
              <span className="text-red-500"> Zena support</span> for assistance.
            </a>
          </p>
        </>
      ) : (
        <>
          <h2 className="mb-4">Initializing Zena Account</h2>
          <h4 className="mb-4">{LOADING_BLURBS[currentLoadingBlurbIndex]}</h4>
          <LoadingSpinner />
        </>
      )}
    </div>
  );
};

export default CustomerInitPage;

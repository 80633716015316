import { useQuery } from '@apollo/client';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { MultiSelect } from 'components/molecules/MultiSelect';
import { QUERY_GET_CUSTOMER_TEAM_MEMBERS } from './data';
import useCustomAuth from 'hooks/useCustomAuth';
import { useEffect, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { SelectableUser, useAppState } from 'stores/UserStore';
import { nonNullable } from 'utils';

type ProjectUserMultiSelectProps = {
  customerId: string;
  useFormRegister?: UseFormRegisterReturn;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

const ProjectUserMultiSelect = ({
  customerId,
  useFormRegister,
  onKeyDown,
}: ProjectUserMultiSelectProps) => {
  const { user } = useCustomAuth();
  const [allUsers, setAllUsers] = useState<SelectableUser[]>([]);
  const [projectUsers, setProjectUsers] = useAppState((state) => [
    state.projectUsers,
    state.setProjectUsers,
  ]);
  const { data: getCustomerTeamMembersData, loading: getCustomerTeamMembersLoading } = useQuery(
    QUERY_GET_CUSTOMER_TEAM_MEMBERS,
    {
      variables: {
        customerId: customerId,
      },
    }
  );

  useEffect(() => {
    if (user && user.attributes) {
      setProjectUsers([
        { id: user?.attributes?.sub || '', name: 'You', email: user.attributes.email },
      ]);
    }
  }, [user]);

  useEffect(() => {
    let teamMembers = getCustomerTeamMembersData?.getCustomerTeamMembers || [];
    if (teamMembers) {
      const currentUserId = user && user.attributes ? user.attributes.sub : null;

      const filteredTeamMembers = teamMembers.filter(nonNullable).map<SelectableUser>((user) => ({
        id: user.userId,
        name:
          user.userId === currentUserId ? 'You' : `${user.fullName.first} ${user.fullName.last}`,
        email: user.email,
        profilePictureUrl: user.signedProfilePhotoUrl || undefined,
      }));

      filteredTeamMembers.sort((a, b) => {
        if (a.name === 'You') {
          return -1;
        } else if (b.name === 'You') {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });

      setAllUsers(filteredTeamMembers);
    }
  }, [getCustomerTeamMembersData]);

  return getCustomerTeamMembersLoading ? (
    <div className="w-full flex justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <MultiSelect
      selectableUsers={allUsers}
      setUsers={setProjectUsers}
      initialSelectedUsers={projectUsers}
      useFormRegister={useFormRegister}
      onKeyDown={onKeyDown}
    />
  );
};

export default ProjectUserMultiSelect;

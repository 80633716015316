import { clearAccessFromLocalStorage, clearCustomerTokenFromLocalStorage } from './access';
import {
  clearHasPlaidConnectionCompleteFromLocalStorage,
  clearRepaymentConnectionCompleteFromLocalStorage,
} from './plaid';

export const removeAccess = () => {
  clearAccessFromLocalStorage();
  clearCustomerTokenFromLocalStorage();
  clearHasPlaidConnectionCompleteFromLocalStorage();
  clearRepaymentConnectionCompleteFromLocalStorage();
};

import { useQuery, ApolloError } from '@apollo/client';
import { onApolloError } from 'utils';
import { gql } from '__generated__/gql';
import { useAppState } from 'stores/UserStore';

export const categoryDemoData = [
  {
    key: 'Category 1',
    spendByTag: 300000,
    count: 10,
  },
  {
    key: 'Category 2',
    spendByTag: 100000,
    count: 10,
  },
  {
    key: 'Category 3',
    spendByTag: 80000,
    count: 10,
  },
  {
    key: 'Category 4',
    spendByTag: 50000,
    count: 10,
  },
  {
    key: 'Category 5',
    spendByTag: 60000,
    count: 10,
  },
  {
    key: 'Unassigned',
    spendByTag: 150000,
    count: 10,
  },
];

export const roomDemoData = [
  {
    key: 'Room 1',
    spendByTag: 300000,
    count: 10,
  },
  {
    key: 'Room 2',
    spendByTag: 100000,
    count: 10,
  },
  {
    key: 'Room 3',
    spendByTag: 80000,
    count: 10,
  },
  {
    key: 'Room 4',
    spendByTag: 50000,
    count: 10,
  },
  {
    key: 'Room 5',
    spendByTag: 60000,
    count: 10,
  },
  {
    key: 'Unassigned',
    spendByTag: 150000,
    count: 10,
  },
];

export const GET_PROJECT_TAG_AGGREGATIONS = gql(`
    query GetProjectTransactionAmountsByTag($companySlug: String!, $projectId: String!) {
    getProjectTransactionAmountsByTag(companySlug: $companySlug, projectId: $projectId) {
      categories {
        key
        count
        spendByTag
      }
      rooms {
        key
        count
        spendByTag
      }
    }
  }
`);

export const useGetProjectTagAggregations = ({
  companySlug,
  projectId,
  onSuccess,
  onError,
}: {
  companySlug: string;
  projectId: string;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}) => {
  const setErrorMsg = useAppState((state) => state.setErrorMsg);

  const { data, loading, error } = useQuery(GET_PROJECT_TAG_AGGREGATIONS, {
    variables: {
      companySlug,
      projectId,
    },
    onCompleted: onSuccess,
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, []);

      if (onError) {
        onError(error);
      }
    },
  });

  return {
    loading,
    error,
    tagAggregations: data?.getProjectTransactionAmountsByTag ?? { categories: [], rooms: [] },
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/index';
import { GenericLoader } from 'components/atoms/Loader';
import { HTMLAttributes } from 'react';
import { useAppState } from '../../../../../stores/UserStore';
import { LargeTransactionAlert, LowBudgetAlert } from './Switches';
import { useActiveCompany } from '../../../../../providers/ActiveCompany';

const QUERY_GET_NOTIFICATION_SETTINGS = gql(`
  query GetNotificationSettings($customerId: String!) {
    getNotificationSettings(customerId: $customerId) {
        projectBudget,
        largeTransactions
    }
  }
`);

interface AlertSettingsProps extends HTMLAttributes<HTMLDivElement> {}

export const AlertSettings = (props: AlertSettingsProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { loading, data: getDataResults } = useQuery(QUERY_GET_NOTIFICATION_SETTINGS, {
    variables: {
      customerId: activeCompanySlug,
    },
  });
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);

  if (loading)
    return (
      <div className="w-full flex justify-center mt-8">
        <GenericLoader />
      </div>
    );

  return (
    <div {...props}>
      <div className="flex">
        <div className="flex-1 w-1/3 pr-12">
          <div className="font-bold mb-2">Alerts</div>
          <div className="text-gray-500">
            Set up helpful alerts that will inform you of major purchases or activity across your
            company account.
          </div>
        </div>
        <div className="flex-2 w-2/3">
          <form className="grid gap-8">
            <LowBudgetAlert
              customerId={activeCompanySlug}
              setErrorMsg={setErrorMsg}
              loading={loading}
              initialValue={getDataResults?.getNotificationSettings?.projectBudget || 10}
              defaultEnabled={getDataResults?.getNotificationSettings?.projectBudget !== null}
            />
            <LargeTransactionAlert
              customerId={activeCompanySlug}
              setErrorMsg={setErrorMsg}
              loading={loading}
              initialValue={getDataResults?.getNotificationSettings?.largeTransactions || 1000}
              defaultEnabled={getDataResults?.getNotificationSettings?.largeTransactions !== null}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AlertSettings;

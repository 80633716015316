import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { cn } from '../../../utils';

export const Layout = ({
  children,
  className,
  ...rest
}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <div className={cn('flex flex-wrap gap-6 py-8', className)} {...rest}>
      {children}
    </div>
  );
};

const LeftPanel = ({
  children,
  className,
  ...rest
}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <div className={cn('flex flex-col gap-5 flex-auto', className)} {...rest}>
      {children}
    </div>
  );
};

const RightPanel = ({
  children,
  className,
  ...rest
}: PropsWithChildren<ComponentPropsWithoutRef<'div'>>) => {
  return (
    <div className={cn('flex flex-col gap-5 flex-auto', className)} {...rest}>
      {children}
    </div>
  );
};

Layout.LeftPanel = LeftPanel;
Layout.RightPanel = RightPanel;

export const SettingsCard = ({
  children,
  className,
  editing,
  ...rest
}: PropsWithChildren<ComponentPropsWithoutRef<'div'> & { editing?: boolean }>) => {
  return (
    <div
      className={cn('border-feather border rounded-3xl p-8', editing && 'bg-ivory', className)}
      {...rest}
    >
      {children}
    </div>
  );
};

import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { formatNumberAsDollars } from 'utils';
import { Link, useLocation } from 'react-router-dom';
import { PercentageBar } from 'components/atoms/PercentageBar';
import { Pencil } from 'lucide-react';

export const ProjectSpendVsBudget = ({
  totalSpent,
  budget,
}: {
  totalSpent: number;
  budget: number;
}) => {
  const location = useLocation();

  let budgetRemaining: number = budget - totalSpent;
  if (budgetRemaining < 0) {
    budgetRemaining = 0;
  }

  return (
    <OutlinedWidget>
      <div className="flex flex-col lg:flex-row sm:divide-x sm:space-x-2">
        <div className="flex flex-col sm:px-2">
          <div className="flex justify-between">
            <OutlinedWidget.Subtitle>Client budget </OutlinedWidget.Subtitle>
            <Link className="self-center" to={`${location.pathname}?tab=settings`}>
              <Pencil size={16} />
            </Link>
          </div>
          {budget ? (
            <div className="text-4xl">{formatNumberAsDollars(budget)}</div>
          ) : (
            <div className="text-2xl">Not set</div>
          )}
        </div>
        <hr className="my-5 lg:hidden" />

        <div className="flex flex-col justify-center w-full sm:px-4">
          <div className="flex justify-between w-full">
            <div>
              <OutlinedWidget.Subtitle>
                <span></span>Budget used
              </OutlinedWidget.Subtitle>
              <div className="text-4xl">{formatNumberAsDollars(totalSpent)}</div>
            </div>
            <div className="text-right">
              <OutlinedWidget.Subtitle>Remaining budget</OutlinedWidget.Subtitle>
              <div className="text-4xl">{formatNumberAsDollars(budgetRemaining)}</div>
            </div>
          </div>
          <div className="mt-6">
            <PercentageBar used={totalSpent} total={budget} />
          </div>
          <div className="flex justify-between w-full mt-2">
            <div className="flex ">
              <div className="self-center rounded-full w-3.5 h-3.5 bg-black mr-2"></div>
              <div>
                Spend <span className="font-semibold">{formatNumberAsDollars(totalSpent)}</span>
              </div>
              <div>{/* markup */}</div>
            </div>
          </div>
        </div>
      </div>
    </OutlinedWidget>
  );
};

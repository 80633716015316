import { cn } from 'utils';
import { HTMLAttributes } from 'react';

interface LoadingSpinnerProps extends HTMLAttributes<HTMLDivElement> {}

const LoadingSpinner = ({ className, ...props }: LoadingSpinnerProps) => {
  return (
    <div
      className={cn(
        'border-gray-300 h-6 w-6 animate-spin rounded-full border-4 border-t-lavender',
        className
      )}
      {...props}
    />
  );
};

export default LoadingSpinner;

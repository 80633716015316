import { ProjectWidget } from '../widgets/ProjectSpendWidget/ProjectSpendWidget';
import { DashboardContainer } from '../DashboardLayout/DashboardContainer';
import { UpsellZena } from './UpsellZena';
import { PaymentsVsSpendMarketingGraph } from './PaymentsVsSpendMarketingGraph';
import { IncomeByProjectMarketingGraph } from './IncomeByProjectChart';
import { SpendBreakdownMarketingGraph } from './SpendBreakdownChart';
import { StayOrganizedMarketingWidget } from './StayOrganizedMarketingWidget';

export const MarketingDashboard = () => {
  return (
    <>
      <DashboardContainer gridColumnsWidth={2} className="opacity-[0.5]">
        <PaymentsVsSpendMarketingGraph />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={1} className="opacity-[0.5]">
        <SpendBreakdownMarketingGraph />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={3} className="p-0 border-none">
        <UpsellZena />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={2} className="opacity-[0.5]">
        <IncomeByProjectMarketingGraph />
      </DashboardContainer>

      <DashboardContainer>
        <StayOrganizedMarketingWidget />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={3}>
        <ProjectWidget />
      </DashboardContainer>
    </>
  );
};

import { QueryReference, useQuery, useReadQuery } from '@apollo/client';
import { GetPlaidLinkedAccountSummaryQuery, PlaidLinkedAccountStatus } from '__generated__/graphql';
import { Button } from 'components/atoms/Buttons';
import { ApplyForZenaCard } from 'components/widgets/marketing/ApplyForZenaCard';
import { gql } from '__generated__/gql';
import { FinishApplyingForZenaCard } from 'components/widgets/marketing/FinishApplyingForZenaCard';
import { GenericLoader } from 'components/atoms/Loader';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Link2Off, RotateCcw } from 'lucide-react';

export type LinkedBankAccountCardProps = {
  queryRef: QueryReference<GetPlaidLinkedAccountSummaryQuery>;
  initChangeAccountMode: () => void;
  initReconnectAccountMode: () => void;
  suspend: boolean;
};

const COMPANY_TIER_QUERY = gql(`
  query GetCompanyTierForDashboard($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      tier
    }
  }
`);

export const LinkedBankAccountCard = ({
  queryRef,
  initChangeAccountMode,
  initReconnectAccountMode,
  suspend,
}: LinkedBankAccountCardProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  if (suspend) {
    throw new Promise(() => undefined);
  }

  const {
    data: { getPlaidLinkedAccountSummary: accountSummary },
  } = useReadQuery(queryRef);

  const { data: companyData, loading: companyLoading } = useQuery(COMPANY_TIER_QUERY, {
    variables: {
      companyId: activeCompanySlug,
    },
    skip: !activeCompanySlug,
  });

  if (!accountSummary) {
    if (companyLoading) {
      return <GenericLoader />;
    }

    return (
      <div className="w-full border border-primary-400 rounded-3xl bg-accent-200 p-8 my-8">
        <div className="block m-auto w-fit">
          {companyData?.getCompany?.tier === 'luxe' ? (
            <FinishApplyingForZenaCard />
          ) : (
            <ApplyForZenaCard />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col p-4 lg:p-8 border-none rounded-xl bg-ivory">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="mb-8 lg:mb-0">
            {accountSummary?.id ? (
              <>
                <div className="text-black text-xl">
                  {[accountSummary?.bank, accountSummary?.last4].join(' ')}
                </div>
                <div className="text-asphalt text-lg">{accountSummary?.accountType}</div>
              </>
            ) : (
              <div>
                <em>Bank account not linked yet</em>
              </div>
            )}
          </div>
          <div className="flex flex-col lg:flex-row gap-4 lg:ml-5 lg:my-auto justify-between items-center">
            {accountSummary?.status === PlaidLinkedAccountStatus.disconnected ? (
              <Button
                className="bg-rustic font-normal text-white rounded-full w-64 flex items-center"
                icon={<div className="bg-[#E89887] h-3 w-3 mx-4 rounded-full" />}
                text="Reconnect bank account"
                onClick={initReconnectAccountMode}
              />
            ) : (
              <Button
                className="!bg-[#D2F8C8] font-normal text-black rounded-full w-64 flex items-center hover:bg-[#D2F8C8]"
                icon={<div className="bg-[#2EDB00] h-3 w-3 mx-4 rounded-full" />}
                text="Bank account connected"
                disabled
              />
            )}
          </div>
        </div>
        <hr className="my-6" />
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="text-asphalt text-lg max-w-xs mb-8 lg:mb-0">
            If you would like to change your current bank account, you may do so here.
          </div>
          <div>
            <Button
              className="bg-secondary-400 font-normal text-black rounded-full w-64 flex items-center"
              text="Change bank account"
              icon={<RotateCcw className={`h-5 w-5 mx-4 float-left`} />}
              onClick={initChangeAccountMode}
            />
          </div>
        </div>
      </div>
      {accountSummary?.status === 'disconnected' && (
        <div className="py-6 px-8 flex flex-row gap-4 border-none rounded-xl bg-rustic bg-opacity-[.08]">
          <Link2Off />
          <div className="text-rustic">
            <div className="text-lg font-semibold">Your bank account has been disconnected.</div>
            <div>
              Please click the 'Reconnect bank account' button above to relink your bank account to
              Zena.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const SELECT_PROJECT_ROOMS_QUERY = gql(`
  query SelectProjectRoomsQuery($companySlug: String! $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      rooms
    }
  }
`);

export const useProjectRooms = ({
  companySlug,
  projectId,
}: {
  companySlug: string;
  projectId: string;
}) => {
  const { data, loading, error } = useQuery(SELECT_PROJECT_ROOMS_QUERY, {
    variables: {
      companySlug,
      projectId,
    },
    skip: !companySlug || !projectId,
  });

  const rooms = data?.getProject?.rooms ?? [];

  return { rooms, loading, error };
};

import { Card } from '__generated__/graphql';
import { Button } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import whiteGoldCardOverlay from '../../../../../images/whiteGoldCardOverlay.webp';
import { Check } from 'lucide-react';

export interface CloseCardModalProps {
  show: boolean;
  onClose: () => void;
  cardData: Card;
  title: string;
  message: string;
  submessage: string;
}

const CloseCardModal = ({
  show,
  onClose,
  cardData,
  title,
  message,
  submessage,
}: CloseCardModalProps) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="font-['Helvetica'] leading-normal">
        <div className="font-bold text-[14px] text-gray-500">{title}</div>
        <div className="rounded-[50%] w-8 h-8 p-2.5 bg-green-400 m-auto mb-4">
          <Check />
        </div>
        <h2 className="font-bold text-2xl mb-4 text-center">{message}</h2>
        <div className="text-base text-gray-500 mb-12 text-center">{submessage}</div>
        <div className="w-full">
          <div
            className={
              'bg-cover h-[220px] w-[350px] rounded-xl shadow-[0px_0px_12px_darkgray] m-auto'
            }
            style={{ backgroundImage: `url(${whiteGoldCardOverlay})` }}
          >
            <div className="p-4 text-left font-['Helvetica'] h-full flex justify-between flex-col">
              <div className="mt-16 text-md">
                <div>
                  {cardData.cardholderFullName?.first} {cardData.cardholderFullName?.last}
                </div>
                <div className="font-['Verdana'] max-h-6">
                  <div>{'•••• •••• •••• '.concat(cardData.last4Digits || '')}</div>
                </div>
              </div>
              <div className="flex gap-9">
                <div className="flex h-5 text-[10px] gap-1.5">
                  <div className="w-8 leading-none">GOOD THRU</div>
                  <div className="font-['Verdana'] text-sm">•••</div>
                </div>
                <div className="flex h-5 text-[10px] gap-1.5">
                  <div className="leading-5">CVV</div>
                  <div className="font-['Verdana'] text-sm max-h-5">
                    <div>•••</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button className="mt-4 w-full" onClick={() => onClose()}>
          Back
        </Button>
      </div>
    </Modal>
  );
};

export default CloseCardModal;

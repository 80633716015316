import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const COMPANY_TIER_QUERY = gql(`
    query GetCompanyTierForTransactionsPageLayout($companySlug: String!) {
      getCompany(companyId: $companySlug) {
        id
        tier
      }
    }
  `);

export const useCompanyTier = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(COMPANY_TIER_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return { loading, error, companyTier: data?.getCompany?.tier ?? null };
};

const PLAID_CONNECTION_COMPLETE_QUERY = gql(`
    query PlaidConnectionCompleteQueryForTransactionsPageLayout($companySlug: String!)  {
      getPlaidConnectionStatus(companySlug: $companySlug) {
        connected
      }
    }
  `);

export const useHasPlaidConnection = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(PLAID_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return { loading, error, hasPlaidConnection: !!data?.getPlaidConnectionStatus?.connected };
};

const COUNT_CARDS_QUERY = gql(`
    query GetCards($companySlug: String!) {
      getCards(companySlug: $companySlug) {
        data {
          id
        }
      }
    }
  `);

export const useGetCards = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(COUNT_CARDS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });
  return {
    loading,
    error,
    hasZenaCards: !!data?.getCards?.data?.length,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { RewardStatus } from '__generated__/graphql';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { PageTitle } from 'components/atoms/PageTitle';
import { RewardsHeader } from './RewardsHeader';
import moment from 'moment';
import { RewardTierName } from 'utils';

const QUERY_GET_REWARD_STATUS = gql(`
  query GetRewardStatus($customerId: String!) {
    getCustomerRewardStatus(customerId: $customerId) {
      id
      customerId
      tiersAchieved
      beginningSpendRolloverCents
      totalSpendCents
      endDate
    }
  }
`);

export const textMap = {
  Green: 'text-black fill-primary',
  Gold: 'text-black fill-primary',
  Platinum: 'text-black fill-primary',
  Diamond: 'text-black fill-primary',
  Elite: 'text-white fill-white',
};

const colorMap = {
  Green: '#D2F8C8',
  Gold: '#D8BA8C',
  Platinum: '#AEAEAE',
  Diamond: '#DAD6C7',
  Elite: '#212121',
};

const chevronRight = (tier: RewardTierName) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    className={textMap[tier]}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.37499 13.0002L0.299986 11.9252L5.24999 6.9752L0.299988 2.0252L1.37499 0.950196L7.39999 6.9752L1.37499 13.0002Z" />
  </svg>
);

const rewardsBadge = (tier: RewardTierName) => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0841 28L7.57955 23.6667L2.60341 22.6333L3.16364 17.7333L0 14L3.16364 10.3L2.60341 5.4L7.57955 4.36667L10.0841 0L14.5 2.06667L18.9159 0L21.4534 4.36667L26.3966 5.4L25.8364 10.3L29 14L25.8364 17.7333L26.3966 22.6333L21.4534 23.6667L18.9159 28L14.5 25.9333L10.0841 28ZM13.083 18.4333L20.5636 10.9333L19.0807 9.56667L13.083 15.5667L9.95227 12.2667L8.43636 13.7667L13.083 18.4333Z"
      fill={colorMap[tier]}
    />
  </svg>
);

const Rewards = ({ customerId }: { customerId: string }) => {
  const goldValueCents = 50_000_00;
  const platinumValueCents = 150_000_00;
  const diamondValueCents = 500_000_00;
  const eliteValueCents = 2_000_000_00;
  const centsValues = [goldValueCents, platinumValueCents, diamondValueCents, eliteValueCents];

  const { loading, data } = useQuery(QUERY_GET_REWARD_STATUS, {
    variables: {
      customerId,
    },
  });
  const status = data?.getCustomerRewardStatus as RewardStatus;

  const totalSpentCents = status?.totalSpendCents || 0;

  const currentTier =
    totalSpentCents < goldValueCents
      ? 'Green'
      : totalSpentCents < platinumValueCents
        ? 'Gold'
        : totalSpentCents < diamondValueCents
          ? 'Platinum'
          : totalSpentCents < eliteValueCents
            ? 'Diamond'
            : 'Elite';
  const nextTierValueCents =
    currentTier === 'Green'
      ? goldValueCents
      : currentTier === 'Gold'
        ? platinumValueCents
        : currentTier === 'Platinum'
          ? diamondValueCents
          : currentTier === 'Diamond'
            ? eliteValueCents
            : 1;
  const nextTier =
    currentTier === 'Green'
      ? 'Gold'
      : currentTier === 'Gold'
        ? 'Platinum'
        : currentTier === 'Platinum'
          ? 'Diamond'
          : currentTier === 'Diamond'
            ? 'Elite'
            : null;

  return (
    <div className="w-full flex flex-col">
      {loading ? (
        <>
          <PageTitle hidden text="Loading rewards..." />
          <div className="w-full grid place-content-center text-center pt-10">
            <div className="flex justify-center items-center">
              <LoadingSpinner />
            </div>
          </div>
        </>
      ) : (
        <RewardsHeader
          chevronRight={chevronRight}
          currentTier={currentTier}
          deadlineDate={
            status?.endDate ? status.endDate : moment().endOf('year').format('YYYY-MM-DD')
          }
          nextTier={nextTier}
          nextTierValueCents={nextTierValueCents}
          rewardsBadge={rewardsBadge}
          tiersCents={centsValues}
          totalSpentCents={totalSpentCents}
        />
      )}
    </div>
  );
};

export default Rewards;

import SettingsForm from 'components/molecules/SettingsForm';
import { MultiPillSelect } from 'components/atoms/MultiPillSelect';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { onApolloError } from 'utils';
import { useAppState } from 'stores/UserStore';
import { Pill } from 'components/atoms/Pill';
import { useSearchParams } from 'react-router-dom';
import { TransactionTag } from '__generated__/graphql';

const roomPresets = ['Kitchen', 'Basement', 'Bathroom', 'Closet'];
const categoryPresets = [
  'Appliances',
  'Flooring',
  'Paint',
  'Furniture',
  'Light Fixtures',
  'Cabinetry',
  'Shipping',
];

export const MUTATION_UPDATE_PROJECT = gql(`
  mutation UpdateProjectSettingsTags($customerId: String!, $projectId: String!, $attributes: ProjectInput!) {
    updateProject(projectId: $projectId, customerId: $customerId, attributes: $attributes) {
      id
      name
      description
      budget
      users
    },
  }
`);

const REFETCH_PROJECT = gql(`
  query RefetchProjectFromProjectTags($customerId: String!, $projectId: String!) {
    getProject(customerId: $customerId, projectId: $projectId) {
      id
      name
      description
      budget
      approved
      approvedAt
      rejectedAt
      users
      cardId
      amountSpent
      status
      address {
        street
        street2
        city
        state
        postalCode
      }
      clientInfo {
        firstName
        lastName
        email
        phone
      }
      tags {
        name
        type
      }
    }
  }
`);

export const ProjectTags = ({
  customerId,
  projectId,
  tags,
}: {
  customerId: string;
  projectId: string;
  tags: TransactionTag[];
}) => {
  const [inputRoomTags, setInputRoomTags] = useState<string[]>([]);
  const [roomTags, setRoomTags] = useState<string[]>([]);
  const [inputCategoryTags, setInputCategoryTags] = useState<string[]>([]);
  const [categoryTags, setCategoryTags] = useState<string[]>([]);
  const [setSuccessMsg, setErrorMsg] = useAppState((state) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const [searchParams] = useSearchParams();

  const tagsRef = useRef<HTMLDivElement>(null);
  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT, {
    onError: (error) => onApolloError(error, setErrorMsg, ['UpsertFailed']),
    onCompleted: () => setSuccessMsg(`Project client details updated.`),
  });

  const resetData = () => {
    const projectRoomTags = tags.filter((tag) => tag.type === 'room').map((tag) => tag.name);

    const projectCategoryTags = tags
      .filter((tag) => tag.type === 'category')
      .map((tag) => tag.name);

    setRoomTags(projectRoomTags);
    setInputRoomTags(projectRoomTags);
    setCategoryTags(projectCategoryTags);
    setInputCategoryTags(projectCategoryTags);
  };

  useEffect(() => {
    if (searchParams.get('scrollTo') === 'tags') {
      const tagsElement = tagsRef.current;
      if (tagsElement) {
        tagsElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    resetData();
  }, [tags]);

  const onSubmit = () => {
    const formattedRoomTags = inputRoomTags.map((tag) => {
      return { name: tag, type: 'room' };
    });
    const formattedCategoryTags = inputCategoryTags.map((tag) => {
      return { name: tag, type: 'category' };
    });

    const variables = {
      customerId,
      projectId,
      attributes: {
        tags: [...formattedRoomTags, ...formattedCategoryTags],
      },
    };

    updateProject({
      variables,
      refetchQueries: [
        {
          query: REFETCH_PROJECT,
          variables: {
            customerId,
            projectId,
          },
        },
      ],
    });
    return true;
  };

  const displayedRoomTags = roomTags.length ? (
    roomTags.map((pillText) => {
      return <Pill key={pillText} text={pillText} className="h-7 leading-[normal] bg-violet-100" />;
    })
  ) : (
    <h6 className="text-sm text-gray-500 my-1.5">
      Create custom room tags for your project to stay organized.
    </h6>
  );

  const displayedCategoryTags = categoryTags.length ? (
    categoryTags.map((pillText) => {
      return <Pill key={pillText} text={pillText} className="h-7 leading-[normal] bg-indigo-50" />;
    })
  ) : (
    <h6 className="text-sm text-gray-500 my-1.5">
      Create custom spend categories for your project.
    </h6>
  );

  return (
    <div ref={tagsRef}>
      <SettingsForm formTitle="Tags" onSubmit={onSubmit} onCancel={resetData}>
        {({ editing }) => (
          <div>
            <label>Rooms</label>

            {editing ? (
              <>
                <h6 className="text-sm text-gray-500 my-1.5">
                  Create custom room tags for your project to stay organized.
                </h6>
                <MultiPillSelect
                  placeholder="Enter tag name..."
                  presets={roomPresets}
                  pillClassName="bg-violet-100"
                  onChange={(tags) => setInputRoomTags(tags)}
                  value={inputRoomTags}
                />
              </>
            ) : (
              <div className="flex flex-wrap gap-2 mt-2">{displayedRoomTags}</div>
            )}
            <div className="border-b my-5" />
            <label>Spend Categories</label>
            {editing ? (
              <>
                <h6 className="text-sm text-gray-500 my-1.5">
                  Create custom spend categories for your project.
                </h6>
                <MultiPillSelect
                  placeholder="Enter tag name..."
                  presets={categoryPresets}
                  pillClassName="bg-indigo-50"
                  onChange={(tags) => setInputCategoryTags(tags)}
                  value={inputCategoryTags}
                />
              </>
            ) : (
              <div className="flex flex-wrap gap-2 mt-2">{displayedCategoryTags}</div>
            )}
          </div>
        )}
      </SettingsForm>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { isAdmin } from 'hooks/access';

const REPAYMENT_CONNECTION_COMPLETE_QUERY = gql(`
  query PlaidConnectionCompleteQueryOnLuxeTeamMemberDashboard($companySlug: String!)  {
    getRepaymentConnectionStatus(companySlug: $companySlug) {
      id
      connected
    }
  }
`);

export const useHasPlaidConnection = ({ companySlug }: { companySlug: string }) => {
  const admin = isAdmin(companySlug);
  const { data, loading, error } = useQuery(REPAYMENT_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug,
    },
    skip: !admin,
  });

  return {
    hasPlaidConnection: !!data?.getRepaymentConnectionStatus?.connected,
    loading,
    error,
  };
};

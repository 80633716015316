import { useAppState } from '../../../stores/UserStore';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { useCreateIntuitAppConnection, useGetAuthorizeUrl } from 'components/widgets/Quickbooks';
import OAuthPopup from 'components/atoms/OAuthPopup';
import { RoundButton } from 'components/atoms/Buttons';

export const ChangeQuickBooksAccount = () => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { authorizeUrl } = useGetAuthorizeUrl({ companySlug });
  const { createIntuitAppConnection } = useCreateIntuitAppConnection();

  const onCode = async (response: string): Promise<void> => {
    const { code, realmId, csrfToken } = JSON.parse(response);
    try {
      createIntuitAppConnection({
        variables: { companySlug, code, realmId, csrfToken },
        refetchQueries: ['GetIntuitAppConnection'],
        onError: (error) => setErrorMsg(error.message),
      });
    } catch (e) {
      console.error(e);
    } finally {
      window.localStorage.removeItem('qboOAuthResponse');
    }
  };

  return (
    <OAuthPopup
      url={authorizeUrl}
      onCode={onCode}
      title="Connect to QuickBooks® Online"
      width={700}
      height={700}
    >
      <RoundButton variant="tertiary">Change Quickbooks account</RoundButton>
    </OAuthPopup>
  );
};

import React, { ReactNode } from 'react';
import { cn } from 'utils';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  children?: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
}

const UnderlineButton = (props: ButtonProps) => {
  const { className, disabled, onClick, text, isLoading = false, children } = props;
  return (
    <button
      {...props}
      className={cn(
        'bg-transparent border-none underline px-3 py-6 text-black text-base outline-accent-600',
        className
      )}
      onClick={onClick}
      disabled={disabled || false}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {text}
          {children}
        </>
      )}
    </button>
  );
};

export default UnderlineButton;

import { Transaction } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { TransactionReceiptImage } from 'components/atoms/TransactionReceiptImage';
import { Link } from 'react-router-dom';
import { formatCentsToDollarsString, isUsingMobile } from 'utils';

export const getTagNamesWithPrefix = (model: Transaction | null, prefix: string): string[] => {
  if (!model) {
    return [];
  }

  const tagNames = model?.tags!.filter((tag) => tag!.tagName.startsWith(prefix));
  const cleanedTagNames = tagNames
    .filter((tag) => tag)
    .map(
      (tag) =>
        tag?.tagName
          .replace(prefix, '')
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    );

  // Just get the first one for now, we can handle multi-select later
  return cleanedTagNames;
};

const Summary = ({ model }: { model: Transaction }) => {
  const roomNames = getTagNamesWithPrefix(model, 'room_').join(', ');
  const categoryNames = getTagNamesWithPrefix(model, 'category_').join(', ');
  const isMobile = isUsingMobile();

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-center">Thanks for reviewing this purchase</h1>
      <p className="text-lg text-asphalt text-center max-w-xl mt-6">
        We have successfully updated your purchase details on Zena.
      </p>
      {!isMobile && (
        <Link className="mt-6" to={`/transactions`}>
          <RoundButton text="See recent purchases" />
        </Link>
      )}
      <div className="bg-white w-80 sm:w-[25rem] p-8 rounded-3xl shadow-xl mt-12">
        <div>
          <p className="font-bold">Transaction</p>
          <p className="text-black text-xl mt-2">
            {model.name.substring(0, model.name.indexOf('|'))}
          </p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Amount</p>
          <p className="text-black text-xl mt-2">{formatCentsToDollarsString(model.amount)}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Card</p>
          <p className="text-black text-xl mt-2">{model.card?.name}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        {model.projectName && (
          <div>
            <p className="font-bold">Project</p>
            <p className="text-black text-xl mt-2">{model.projectName}</p>
            <div className="border-t border-secondary-400 my-3"></div>
          </div>
        )}
        <div>
          <p className="font-bold">Date</p>
          <p className="text-black text-xl mt-2">
            <RelativeDate date={model.date} />
          </p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Receipt</p>
          {model.receipt ? (
            <div className="mt-2">
              <TransactionReceiptImage transactionReceipt={model.receipt} />
            </div>
          ) : (
            <p className="text-black text-xl mt-2">—</p>
          )}
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Memo</p>
          <p className="text-black text-xl mt-2">{model.memo || '—'}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Room</p>
          <p className="text-black text-xl mt-2">{roomNames || '—'}</p>
        </div>
        <div>
          <p className="font-bold">Category</p>
          <p className="text-black text-xl mt-2">{categoryNames || '—'}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">PO Code</p>
          <p className="text-black text-xl mt-2">{model.poCodeTag?.value || '—'}</p>
        </div>
      </div>
    </div>
  );
};

export default Summary;

import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { CsvExportType, TransactionFiltersInput } from '__generated__/graphql';
import { Button, RoundButton } from 'components/atoms/Buttons';
import { Checkbox } from 'components/atoms/Checkbox';
import { Drawer } from 'components/atoms/Drawer';
import { StyledTooltip } from 'pages/Settings/tabs/IntegrationsTab/QboIntegration/QboConfigurationSection/helperComponents';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { Info, X } from 'lucide-react';
import { useGetTransactionsWithChildrenCount } from './data';

export const TransactionDownload = ({
  visible,
  onClose,
  email,
  projectId,
  transactionCount,
  allowSplitTransactions = false,
  filters,
  csvExportType,
}: {
  visible: boolean;
  transactionCount: number;
  allowSplitTransactions?: boolean;
  email: string;
  projectId?: string;
  onClose: () => void;
  filters: TransactionFiltersInput;
  csvExportType?: CsvExportType;
}) => {
  const [setSuccessMsg, setErrorMsg] = useAppState((state) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [shouldIncludeImportedTransactions, setShouldIncludeImportedTransactions] = useState(true);
  const [shouldIncludeSplitTransactions, setShouldIncludeSplitTransactions] = useState(true);
  const { count: countWithChildren } = useGetTransactionsWithChildrenCount({
    companySlug: activeCompanySlug,
    filters,
  });
  const [createTransactionCsv, { loading }] = useMutation(CREATE_CSV);

  return (
    <Drawer
      isVisible={visible}
      className="flex flex-col px-4 md:h-[calc(100%-76px)] md:mt-[76px] relative"
      onClose={onClose}
    >
      <div className="grid gap-5 overflow-y-auto pt-20 pb-32">
        <TitleContainer>
          <TitleRow>
            <h2 className="text-3xl truncate">Download CSV file</h2>
            {onClose && <CloseButton onClick={onClose} />}
          </TitleRow>
        </TitleContainer>

        <div className="font-medium mt-4">
          This download will include only the transactions in this table, including any filters
          applied.
        </div>

        <div className="flex flex-col gap-6 mt-2">
          <div className="flex justify-between mx-2">
            <div className="flex flex-col">
              <div>Date Range:</div>
              <div className="text-sm italic font-light">
                Update page filters to change time range
              </div>
            </div>
            <div className="font-medium">All time</div>
          </div>
          <div className="flex justify-between mx-2">
            <div className="flex gap-2">
              <div>Include imported transactions?</div>
              <Info className="cursor-pointer" data-tooltip-id="download-checkbox" />
              <StyledTooltip id="download-checkbox">
                <div className="grid gap-4">
                  <p>
                    You can choose to import historical transactions from your other business bank
                    or credit card accounts. Checking this box will include any imported
                    transactions in the download.
                  </p>
                </div>
              </StyledTooltip>
            </div>
            <Checkbox
              checked={shouldIncludeImportedTransactions}
              onChange={(checked) => setShouldIncludeImportedTransactions(checked)}
            />
          </div>
          {allowSplitTransactions && (
            <div className="flex justify-between mx-2">
              <div className="flex gap-2">
                <div>Include split transactions?</div>
                <Info className="cursor-pointer" data-tooltip-id="download-checkbox-2" />
                <StyledTooltip id="download-checkbox-2">
                  <div className="grid gap-4">
                    <p>
                      You can choose to split a transaction across multiple categories or projects.
                      Include them in your download to get the most granular data.
                    </p>
                  </div>
                </StyledTooltip>
              </div>
              <Checkbox
                checked={shouldIncludeSplitTransactions}
                onChange={(checked) => setShouldIncludeSplitTransactions(checked)}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4">
          <h2 className="font-semibold text-lg mt-8">Check your email!</h2>

          <div>
            Downloading a lot of data can take time. Once this data is ready, we’ll send an email to{' '}
            {email} with a link to download the CSV file.
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-8">
          <RoundButton
            isLoading={loading}
            text="Download CSV file"
            variant="primary"
            onClick={async () => {
              if (csvExportType) {
                await createTransactionCsv({
                  variables: {
                    companySlug: activeCompanySlug,
                    projectId,
                    includeChildren: shouldIncludeSplitTransactions,
                    includeImportedTransactions: shouldIncludeImportedTransactions,
                    filters,
                    csvExportType,
                  },
                  onCompleted: () => {
                    setSuccessMsg(
                      <Link to="/transactions/exports">
                        <div>
                          CSV file is being generated. Check your email for the download link or go
                          to the <span className="underline">export dashboard.</span>
                        </div>
                      </Link>
                    );
                  },
                  onError: (error) => {
                    setErrorMsg('There was an error downloading the CSV file');
                  },
                });
              }
              onClose?.();
            }}
            icon="download"
          />
          <div className="flex justify-between mx-2 font-normal text-primary-600">
            <p>Transactions that will download</p>
            <div>{shouldIncludeSplitTransactions ? countWithChildren : transactionCount}</div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const TitleContainer = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 border-b border-primary pb-4 min-w-0">{children}</div>;
};

const TitleRow = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between items-start gap-4 min-w-0">{children}</div>;
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="ghost" className="p-0" onClick={onClick}>
      <X />
    </Button>
  );
};

const CREATE_CSV = gql(`
  mutation CreateTransactionCsv(
    $companySlug: String!
    $projectId: String
    $includeChildren: Boolean!
    $includeImportedTransactions: Boolean!
    $csvExportType: CsvExportType!
    $filters: TransactionFiltersInput    
  ) {
    createTransactionsCsv(
      companySlug: $companySlug
      projectId: $projectId
      includeChildren: $includeChildren
      includeImportedTransactions: $includeImportedTransactions
      csvExportType: $csvExportType
      filters: $filters
    )
  }
`);

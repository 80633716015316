import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const CARD_TRANSACTION_DETAILS_QUERY = gql(`
  query GetTransactionCardDetails($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      card {
        id
        name
      }
      date
      user {
        id: email
        fullName {
          first
          last
        }
      }
      authorizationCreatedAt
    }
  }
`);

export const useCardTransactionDetails = ({
  transactionId,
  activeCompanySlug,
}: {
  transactionId: string;
  activeCompanySlug: string;
}) => {
  const { data, loading, error } = useQuery(CARD_TRANSACTION_DETAILS_QUERY, {
    variables: {
      transactionId,
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || !transactionId,
  });

  return { transaction: data?.getTransaction ?? null, loading, error };
};

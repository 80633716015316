import { CurrencyInput } from 'components/atoms/Inputs/CurrencyInput';
import { useTransactionDetailsContext, validateTransactionDetailsState } from '../../state';
import { PercentageInput } from 'components/atoms/Inputs/PercentageInput';
import { Field } from 'components/atoms/Inputs/Field';
import { SelectProject } from 'components/widgets/TransactionDetailsDrawer/SelectProject';
import { SelectAccountingTag } from '../../SelectAccountingTag';
import { SelectProjectCategories } from '../../SelectProjectCategories';
import { SelectProjectRooms } from '../../SelectProjectRooms';
import { Input } from 'components/atoms/Inputs/Input';
import { toCents, toDollars } from 'utils';
import { Button } from 'components/atoms/Buttons';

export const EditTransactionSplitDetails = ({
  splitId,
  hasActiveQboConnection = false,
  transactionTotalInCents,
  showMissingRequirements = false,
}: {
  splitId: string;
  hasActiveQboConnection?: boolean;
  transactionTotalInCents: number;
  showMissingRequirements: boolean;
}) => {
  const { state, dispatch } = useTransactionDetailsContext();
  const index = state.splits.findIndex((stateSplit) => stateSplit.transactionId === splitId);
  const split = state.splits[index];
  const validationErrors = validateTransactionDetailsState({
    state,
    transactionTotalAmountInCents: transactionTotalInCents,
  });

  const showAccountingCategoriesSection = hasActiveQboConnection;

  const selectedAccountingTagName = split.accountingTag?.name ?? '';

  if (split) {
    return (
      <div className="grid gap-8 py-6 px-5 rounded-lg border border-primary-400 bg-secondary-300">
        <div className="flex justify-between items-center">
          <div className="font-medium">{`Split #${index + 1}`}</div>

          {state.splitMethod === 'AMOUNT' && (
            <Field
              className="w-[142px]"
              errorMessage={
                state.showErrors && validationErrors.splits ? validationErrors.splits : ''
              }
            >
              <CurrencyInput
                value={toDollars(split.amount)}
                className="bg-white"
                onChange={(amount) =>
                  dispatch({
                    type: 'UPDATE_SPLIT',
                    payload: { ...split, amount: amount ? toCents(amount) : 0 },
                  })
                }
              />
            </Field>
          )}

          {state.splitMethod === 'PERCENT' && (
            <Field
              className="w-[142px]"
              errorMessage={
                state.showErrors && validationErrors.splits ? validationErrors.splits : ''
              }
            >
              <PercentageInput
                value={split.amount}
                className="bg-white"
                onChange={(amount) =>
                  dispatch({
                    type: 'UPDATE_SPLIT',
                    payload: { ...split, amount: amount ?? 0 },
                  })
                }
              />
            </Field>
          )}
        </div>

        <div className="grid gap-6">
          <Field label="Select project or business area">
            <SelectProject
              className="bg-white"
              projectId={split.projectId}
              onChange={(updatedProjectId) => {
                dispatch({
                  type: 'UPDATE_SPLIT',
                  payload: { ...split, projectId: updatedProjectId },
                });
              }}
            />
          </Field>

          {showAccountingCategoriesSection && (
            <Field label="Accounting tag for Quickbooks">
              <SelectAccountingTag
                selectedAccountingTagName={split.accountingTag?.name ?? ''}
                isMissing={
                  showMissingRequirements &&
                  state.missingFields?.accountingTag &&
                  !selectedAccountingTagName
                }
                onChangeAccountingTag={(accountingTag) => {
                  dispatch({
                    type: 'UPDATE_SPLIT',
                    payload: {
                      ...split,
                      accountingTag: accountingTag
                        ? {
                            id: accountingTag.accountingTagId,
                            name: accountingTag.accountingTagName,
                          }
                        : null,
                    },
                  });
                }}
              />
            </Field>
          )}

          <Field
            label="Add category tag"
            clarifier={split.projectId ? '' : 'Select a project to assign categories'}
          >
            <SelectProjectCategories
              projectId={split.projectId}
              onChangeCategories={(newCategories) => {
                dispatch({
                  type: 'UPDATE_SPLIT',
                  payload: { ...split, categories: newCategories },
                });
              }}
              categories={split.categories}
            />
          </Field>

          <Field
            label="Add room tag"
            clarifier={split.projectId ? '' : 'Select a project to assign rooms'}
          >
            <SelectProjectRooms
              projectId={split.projectId}
              onChangeRooms={(newRooms) => {
                dispatch({
                  type: 'UPDATE_SPLIT',
                  payload: { ...split, rooms: newRooms },
                });
              }}
              rooms={split.rooms}
            />
          </Field>

          <Field label="PO/Invoice #">
            <Input
              value={split.invoiceNumber}
              className="bg-white"
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_SPLIT',
                  payload: { ...split, invoiceNumber: e.currentTarget.value },
                })
              }
              maxLength={100}
            />
          </Field>
          <Button
            variant="ghost"
            className="text-muted text-sm py-0 px-1 justify-start"
            onClick={() => {
              dispatch({
                type: 'REMOVE_SPLIT',
                payload: {
                  splitId: split.transactionId,
                },
              });
            }}
          >
            Delete this split
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetAllTransactionsToAllocateQuery, ZenaSuggestedAssignment } from '__generated__/graphql';
import { formatGraphqlDate } from 'utils/date';
import { Maybe } from 'utils/types';

export const TRANSACTIONS_QUERY = gql(`
  query GetAllTransactionsToAllocate(
    $companySlug: String!
    $pageOffset: Int!
    $pageLimit: Int!
    $searchTerm: String
    $fromDate: String
    $zenaSuggestedAssignment: ZenaSuggestedAssignment
  ) {
    getUnmappedTransactionFeedTransactions(
      companySlug: $companySlug
      pageLimit: $pageLimit
      pageOffset: $pageOffset
      searchTerm: $searchTerm
      fromDate: $fromDate
      zenaSuggestedAssignment: $zenaSuggestedAssignment
    ) {
      meta {
        pageNumber
        pageSize
        total
      }
      data {
        id
        bank {
          id
          name
        }
        date
        name: shortName
        spendCategory
        amountWithDirection
      }
    }
  }
`);

export const useTransactionsToAllocate = ({
  companySlug,
  pageOffset = 0,
  pageLimit = 12,
  onCompleted,
  searchTerm,
  fromDate,
  zenaSuggestedAssignment,
}: {
  companySlug: string;
  pageOffset?: number;
  pageLimit?: number;
  searchTerm?: string;
  fromDate?: Date;
  onCompleted: (data: GetAllTransactionsToAllocateQuery) => void;
  zenaSuggestedAssignment: Maybe<ZenaSuggestedAssignment>;
}) => {
  const { data, loading, error, refetch } = useQuery(TRANSACTIONS_QUERY, {
    variables: {
      companySlug,
      pageOffset,
      pageLimit,
      searchTerm,
      fromDate: fromDate ? formatGraphqlDate(fromDate) : undefined,
      zenaSuggestedAssignment,
    },
    skip: !companySlug,
    onCompleted,
  });

  const transactions = data?.getUnmappedTransactionFeedTransactions?.data ?? [];

  return {
    transactions,
    totalTransactions: data?.getUnmappedTransactionFeedTransactions?.meta?.total ?? 0,
    loading,
    error,
    refetch,
  };
};

export const QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS = gql(`
  query GetTransactionFeedAccounts($companySlug: String!) {
    getPlaidTransactionFeedAccounts(companySlug: $companySlug) {
      id
    }
  }
`);

export const useGetTransactionFeedAccounts = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const accounts = data?.getPlaidTransactionFeedAccounts ?? [];
  return {
    accounts,
    loading,
    error,
  };
};

export const ALLOCATE_ONE_TRANSACTION_TO_PROJECT_MUTATION = gql(`
  mutation AllocateOneTransactionToProject(
    $companySlug: String!
    $projectId: ID!
    $transactionId: ID!
  ) {
    connectTransactionToProject(
      companySlug: $companySlug
      projectId: $projectId
      transactionId: $transactionId
    ) {
      id
      date
      name: shortName
      amountWithDirection
      spendCategory
      bank {
        id
        name
      }
    }
  }
`);

export const ALLOCATE_BULK_TRANSACTIONS_TO_PROJECT_MUTATION = gql(`
  mutation AllocateBulkTransactionsToProject(
    $companySlug: String!
    $projectId: ID!
    $transactionIds: [ID!]!
  ) {
    bulkConnectTransactionsToProject(
      companySlug: $companySlug
      projectId: $projectId
      transactionIds: $transactionIds
    ) {
      id
      date
      name: shortName
      amountWithDirection
      spendCategory
      bank {
        id
        name
      }
    }
  }
`);

export const DISMISS_ONE_TRANSACTION_MUTATION = gql(`
  mutation DismissOneTransaction(
    $companySlug: String!
    $transactionId: ID!
  ) {
    rejectImportedTransaction(
      companySlug: $companySlug
      transactionId: $transactionId
    )
  }
`);

export const DISMISS_BULK_TRANSACTIONS_MUTATION = gql(`
  mutation DismissBulkTransactions(
    $companySlug: String!
    $transactionIds: [ID!]!
  ) {
    bulkRejectImportedTransactions(
      companySlug: $companySlug
      transactionIds: $transactionIds
    )
  }
`);

export const GET_PROJECT_TRANSACTIONS_REFETCH_QUERY = gql(`
  query RefetchProjectTransactions($companySlug: String!, $filters: TransactionFiltersInput) {
    getAuthorizationsAndTransactions(customerId: $companySlug, pageNumber: 0, pageSize: 30, filters: $filters) {
      data {
        id
        name
        type
        shortName
        amount
        amountWithDirection
        date
        accountId
        card {
          name
        }
        bank {
            name
            last4Digits
        }
        tags {
          tagName
          value
        }
        accountingTag {
          tagName
          value
        }
        receipt {
          imageUrl
        }
        user {
          fullName {
            first
            last
          }
        }
        projectId
        projectName
        lineItems {
          externalId
          description
          totalCents
        }
        parentTransactionId
      }
    }
  }
`);

export const REFETCH_COUNT_TRANSACTIONS_QUERY = gql(`
  query RefetchCountTransactionsForTransactionsLayout($companySlug: String!) {
    getAuthorizationsAndTransactions(customerId: $companySlug, pageNumber: 0, pageSize: 1) {
      meta {
        total
      }
    }
  }
`);

import { useUserCreditData } from './data';
import { ReactNode } from 'react';
import { CardTitle } from '../../../../components/widgets/cards/CardTitle';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { formatCentsToDollarStringWithDecimal } from 'utils';
import { PercentageBar } from 'components/atoms/PercentageBar';
import { useActiveCompany } from 'providers/ActiveCompany';

export const DashboardCreditWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { companyLimits, loading } = useUserCreditData({
    companySlug: activeCompanySlug,
  });

  if (loading) {
    return (
      <Container>
        <CardTitle>Credit</CardTitle>
        <JustifyBetween>
          <div className="grid gap-2">
            <AvailableToSpendTitle>Available to spend</AvailableToSpendTitle>
            <RectangleSkeleton rounded={false} className="h-8 min-w-36" />
          </div>
          <div className="grid gap-2 justify-items-end">
            <SecondaryText>Credit hold</SecondaryText>
            <RectangleSkeleton rounded={false} className="h-6 min-w-14" />
          </div>
        </JustifyBetween>
        <div className="grid gap-1">
          <RectangleSkeleton className="h-8" />
          <JustifyBetween>
            <RectangleSkeleton rounded={false} className="w-auto min-w-40" />
            <RectangleSkeleton rounded={false} className="w-auto min-w-16" />
          </JustifyBetween>
        </div>
      </Container>
    );
  }

  if (companyLimits) {
    return (
      <Container>
        <CardTitle>Credit</CardTitle>
        <JustifyBetween>
          <div className="grid gap-2">
            <AvailableToSpendTitle>Available to spend</AvailableToSpendTitle>
            <div className="font-[450] text-3xl">
              {formatCentsToDollarStringWithDecimal(companyLimits?.creditAvailableCents ?? 0)}
            </div>
          </div>
          <div className="grid gap-2 justify-items-end">
            <div className="flex flex-row gap-1 items-center">
              <div className="rounded-full bg-warning w-2 h-2" />
              <SecondaryText>Credit hold</SecondaryText>
            </div>
            <div className="text-lg">
              {formatCentsToDollarStringWithDecimal(companyLimits.creditHoldCents)}
            </div>
          </div>
        </JustifyBetween>
        <div className="grid gap-1">
          <PercentageBar
            used={companyLimits.creditBalanceCents}
            total={companyLimits.creditLimitCents}
          />
          <JustifyBetween>
            <SecondaryText>
              {`${formatCentsToDollarStringWithDecimal(
                companyLimits.creditBalanceCents
              )} current balance`}
            </SecondaryText>
            <SecondaryText>
              {`${formatCentsToDollarStringWithDecimal(companyLimits.creditLimitCents)} limit`}
            </SecondaryText>
          </JustifyBetween>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <CardTitle>Credit</CardTitle>
      <div>Somthing went wrong</div>
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className="grid gap-6">{children}</div>
);

const JustifyBetween = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-row justify-between items-start">{children}</div>
);

const AvailableToSpendTitle = ({ children }: { children: ReactNode }) => (
  <div className="text-base text-black font-medium">{children}</div>
);

const SecondaryText = ({ children }: { children: ReactNode }) => (
  <div className="text-sm text-black font-light">{children}</div>
);

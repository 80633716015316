import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { RoundButton } from 'components/atoms/Buttons';
import { hasRepaymentConnectionCompleteFromLocalStorage } from 'hooks/plaid';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { isAdmin } from 'hooks/access';
import { Link } from 'react-router-dom';

export const MUTATION_CREATE_PROJECT_CARD = gql(`
  mutation CreateProjectCard($companySlug: String!, $projectId: String!) {
    createProjectCard(companySlug: $companySlug, projectId: $projectId) {
      id
    }
  }
`);

export const ProjectVirtualCardButton = ({
  projectId,
  companySlug,
}: {
  projectId: string;
  companySlug: string;
}) => {
  const admin = isAdmin(companySlug);
  const hasRepaymentConnection = hasRepaymentConnectionCompleteFromLocalStorage();
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [createProjectCard, { loading: createProjectCardLoading }] = useMutation(
    MUTATION_CREATE_PROJECT_CARD,
    {
      refetchQueries: ['GetProjectsForCollection'],
      onError: (error: ApolloError) => {
        onApolloError(error, setErrorMsg, ['CardLimitError']);
      },
    }
  );

  const handleCreateProjectCard = () => {
    if (projectId) {
      createProjectCard({
        variables: {
          companySlug,
          projectId,
        },
        refetchQueries: ['GetProjectForSingleView'],
      });
    }
  };

  return hasRepaymentConnection ? (
    <RoundButton
      className="inline-block"
      onClick={handleCreateProjectCard}
      isLoading={createProjectCardLoading}
    >
      Create project virtual card
    </RoundButton>
  ) : (
    <Link to="/connect-bank-account" className="flex justify-center">
      <RoundButton disabled={!admin}>Link payment account</RoundButton>
    </Link>
  );
};

import { Helmet } from 'react-helmet-async';

type PageTitleProps = {
  className?: string;
  hidden?: boolean;
  text: string;
};

const PageTitle = ({ className, hidden = false, text }: PageTitleProps) => {
  const helmetElement = (
    <Helmet titleTemplate="Zena - %s" defaultTitle="Zena">
      <title>{text}</title>
    </Helmet>
  );
  if (hidden) {
    return helmetElement;
  }
  return (
    <>
      {helmetElement}
      <h1 className={`"mt-4 mb-4 leading-loose tracking-tight" ${className || ''}`}>{text}</h1>
    </>
  );
};

export default PageTitle;

import { ApolloError, useMutation } from '@apollo/client';
import { SelectField } from '@aws-amplify/ui-react';
import { gql } from '__generated__';
import { ProfilePhotoImage } from 'components/atoms/ProfilePhotoImage';
import useCustomAuth from 'hooks/useCustomAuth';
import { useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { useGetProfileImageUrlsForAuthorizedCard } from './data';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Trash2 } from 'lucide-react';

export const MUTATION_REMOVE_COMPANY_USERS = gql(`
  mutation RemoveCompanyUsers($companySlug: String!, $emails: [String!]!) {
    removeCompanyUsers(companySlug: $companySlug, emails: $emails) {
      fullName {
        first
        last
      }
      email
    }
  }
`);

const MUTATION_UPDATE_USER_ROLE = gql(`
  mutation UpdateUserRole($userIdToUpdate: String!, $customerId: String!, $role: String!) {
    updateUserRole(userIdToUpdate: $userIdToUpdate, customerId: $customerId, role: $role) {
      userId
      customerId
      role
    }
  }
`);

type AuthorizedUserCardProps = {
  customerId: string;
  name: string;
  userId: string;
  email: string;
  signedProfilePhotoUrl: string;
  role: string;
};

export const AuthorizedUserCard = (props: AuthorizedUserCardProps) => {
  const { customerId: companySlug, name, userId, email, signedProfilePhotoUrl, role } = props;
  const { newPermissons } = useFlags();
  const { user: userGql } = useGetProfileImageUrlsForAuthorizedCard({
    activeCompanySlug: companySlug,
  });
  const { user } = useCustomAuth();
  const isCurrentUser = user?.attributes?.sub === userId;
  const profilePhotoUrl = isCurrentUser ? userGql?.smallImageUrl ?? null : signedProfilePhotoUrl;
  const [selectedRole, setSelectedRole] = useState(role);

  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [removeAuthorizedUser, { data, loading }] = useMutation(MUTATION_REMOVE_COMPANY_USERS, {
    variables: {
      companySlug,
      emails: [email],
    },
    onError: (error: ApolloError) => onApolloError(error, setErrorMsg, ['RemovingSelf']),
  });

  const [updateUserRole] = useMutation(MUTATION_UPDATE_USER_ROLE, {
    onError: (error: ApolloError) => onApolloError(error, setErrorMsg, ['CannotUpdateOwnRole']),
  });

  const handleDeleteClick = () => {
    removeAuthorizedUser();
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      {data && data.removeCompanyUsers ? (
        <p>Removed</p>
      ) : (
        <div className="flex justify-between my-4">
          <div className="flex">
            <div className="hidden sm:block">
              <ProfilePhotoImage signedProfilePhotoUrl={profilePhotoUrl} size={10} />
            </div>
            <div className="px-2">
              <p className="font-medium">{name}</p>
              <p className="text-sm text-gray-500">{email}</p>
            </div>
          </div>
          <div className="flex">
            <div className="px-2 w-44">
              <SelectField
                labelHidden
                label=""
                className="border-0"
                disabled={isCurrentUser}
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                  updateUserRole({
                    variables: {
                      userIdToUpdate: userId,
                      customerId: companySlug,
                      role: e.target.value,
                    },
                  });
                }}
              >
                <option value="member">User</option>
                <option value="admin">Admin</option>
                {newPermissons && <option value="bookkeeper">Bookkeeper</option>}
              </SelectField>
            </div>
            <div className="w-8">
              <button onClick={handleDeleteClick} className="pt-2 pb-2">
                <span className="px-2 inline-block w-4 h-4 mr-1">
                  <Trash2 />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

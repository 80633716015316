import { useQuery } from '@apollo/client';
import { SingleSelect } from 'components/molecules/SingleSelect';
import React from 'react';
import { gql } from '__generated__/gql';
import { TransactionTag } from '__generated__/graphql';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const QUERY_GET_PROJECTS = gql(`
  query GetProjectsForTransaction($customerId: String!, $filters: ProjectFiltersInput) {
    getProjects(customerId: $customerId, filters: $filters) {
        projects {
           id
           name
           tags {
              name
              type
            }
        }
    }
  }
`);

type ProjectStepProps = {
  customerId: string;
  projectId: string;
  setProjectData: ({ projectId, tags }: { projectId: string; tags: TransactionTag[] }) => void;
};

const ProjectStep = React.forwardRef<HTMLButtonElement, ProjectStepProps>((props, ref) => {
  const { customerId, projectId, setProjectData } = props;

  const { data: projectsData, loading: projectsLoading } = useQuery(QUERY_GET_PROJECTS, {
    variables: {
      customerId,
      filters: {
        includeUnapproved: false,
      },
    },
  });

  const handleSetSelected = (id: string) => {
    const selectedProject = projectsData?.getProjects?.projects?.find(
      (project) => project.id === id
    );

    if (selectedProject) {
      setProjectData({ projectId: selectedProject?.id, tags: selectedProject.tags ?? [] });
    }
  };

  if (projectsLoading) {
    return (
      <div className="flex gap-2">
        <LoadingSpinner /> Loading projects...
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col mb-6">
        <h1 className="text-asphalt text-2xl">Was this purchase related to a project?</h1>
      </div>
      <SingleSelect
        initialSelected={projectId}
        values={projectsData?.getProjects?.projects || []}
        setSelected={handleSetSelected}
      />
    </>
  );
});

export default ProjectStep;

import { useForm } from 'react-hook-form';
import ProjectDetailsForm from './ProjectDetailsForm';
import { onApolloError } from '../../../../utils';
import { AppState, useAppState } from '../../../../stores/UserStore';
import { useMutation } from '@apollo/client';
import SettingsForm from 'components/molecules/SettingsForm';
import { useState } from 'react';
import DisplayedProjectDetails from './DisplayedProjectDetails';
import { MUTATION_UPDATE_PROJECT } from '../gql';

type ProjectDetailsFormData = {
  name: string;
  description: string;
};

export const ProjectDetails = ({
  customerId,
  projectId,
  name,
  description,
}: {
  projectId: string;
  customerId: string;
  name: string;
  description: string;
}) => {
  const defaultValues: ProjectDetailsFormData = {
    name,
    description,
  };
  const [, setErrorMsg] = useAppState((state: AppState) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT, {
    onError: (error) => onApolloError(error, setErrorMsg, ['UpsertFailed']),
  });
  const [optimisticValues, setOptimisticValues] = useState<ProjectDetailsFormData>(defaultValues);
  const onSubmit = () => {
    const data = getValues();
    setOptimisticValues(data);
    updateProject({
      variables: {
        customerId,
        projectId,
        attributes: {
          name: data.name || name,
          description: data.description,
        },
      },
    });
  };

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ProjectDetailsFormData>({
    defaultValues,
  });
  const onFormReset = () => {
    reset({
      name,
      description,
    });
  };

  return (
    <SettingsForm
      formTitle="Project details"
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onFormReset}
    >
      {({ editing }) =>
        editing ? (
          <ProjectDetailsForm register={register} errors={errors} />
        ) : (
          <DisplayedProjectDetails
            name={optimisticValues.name}
            description={optimisticValues.description}
          />
        )
      }
    </SettingsForm>
  );
};

import { Invitation } from '__generated__/graphql';
import { useEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import whiteZenaCardWood from '../../../images/whiteZenaCardWood.webp';
import zenaLogo from '../../../images/zenaLogo.webp';
import { PendingInvitationCard } from './PendingInvitationCard';

type PendingInvitationCollectionProps = {
  pendingInvitations: Invitation[];
};

const PendingInvitationsCollection = (props: PendingInvitationCollectionProps) => {
  const { pendingInvitations } = props;
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const attributes = await fetchUserAttributes();
      if (attributes.email) {
        setEmail(attributes.email);
      }
    };
    fetchData();
  }, [email]);
  if (!pendingInvitations.length) return <></>;
  return (
    <div className="grid h-screen w-screen bg-secondary-400">
      <div className="grid grid-cols-1 lg:grid-cols-3 h-full">
        <div className="hidden lg:block"></div>
        <div className="place-self-center p-12 bg-ivory rounded-4xl">
          <img className="h-4" src={zenaLogo} alt="Zena Logo"></img>
          <h1 className="mt-10">Join your team on Zena</h1>
          <p className="mt-4"></p>
          <div className="h-48 overflow-y-auto mt-8">
            {pendingInvitations.map((invitation, index) => {
              return (
                <PendingInvitationCard
                  key={index}
                  name={invitation.teamName ?? invitation.companySlug}
                  invitationLink={invitation.invitationLink || ''}
                />
              );
            })}
          </div>
        </div>
        <div className="place-self-end hidden lg:block">
          <img className="p-10" src={whiteZenaCardWood} alt="Zena Card"></img>
        </div>
      </div>
    </div>
  );
};

export default PendingInvitationsCollection;

import { PageTitle } from 'components/atoms/PageTitle';
import MyProfileSettings from 'pages/UserProfile/MyProfileSettings';

export const UserProfile = () => {
  return (
    <>
      <PageTitle text="Manage Profile" hidden />
      <div className="bg-primary text-white p-8 text-3xl">Manage Profile</div>
      <div className="grid p-6 lg:p-12 max-w-screen-2xl">
        <MyProfileSettings />
      </div>
    </>
  );
};

import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { Pill } from 'components/atoms/Pill';
import { SelectAccountingTag } from '../../SelectAccountingTag';
import { ReactNode, useState } from 'react';
import { useTransactionDetailsContext } from '../../state';
import { cn } from 'utils';
import { CircleCheck, Pencil, TriangleAlert } from 'lucide-react';

export const TransactionSplitDetails = ({
  splitIndex,
  formattedAmount,
  projectTitle,
  projectCategories,
  projectRooms,
  invoiceNumber,
  status,
  onChangeAccountingTag,
  selectedAccountingTagName,
  isWidgetOpenByDefault = false,
  hasActiveQboConnection = false,
  isAccountingTagRequiredForQbo = false,
  showMissingRequirements,
}: {
  splitIndex: number;
  formattedAmount: string;
  projectTitle: string;
  projectCategories: string[];
  projectRooms: string[];
  invoiceNumber: string;
  status: 'DONE' | 'NEEDS_ATTENTION';
  onChangeAccountingTag: (
    data: { accountingTagId: string; accountingTagName: string } | null
  ) => void;
  selectedAccountingTagName: string;
  isWidgetOpenByDefault?: boolean;
  hasActiveQboConnection?: boolean;
  isAccountingTagRequiredForQbo?: boolean;
  showMissingRequirements: boolean;
}) => {
  const [forceExpandedContentOpen, setForceExpandedContentOpen] = useState(isWidgetOpenByDefault);
  const [showEditAccountingTagSection, setShowEditAccountingTagSection] = useState(false);
  const showAccountingCategoriesSection = hasActiveQboConnection;
  const { state } = useTransactionDetailsContext();

  return (
    <OutlinedWidget className="p-5 rounded-lg max-w-full min-w-0">
      <OutlinedWidget.Collapsable
        openExpandedContent={forceExpandedContentOpen}
        onClose={() => {
          setForceExpandedContentOpen(false);
        }}
      >
        {({ isOpen }) => (
          <>
            <div
              onClick={() => {
                setForceExpandedContentOpen(!forceExpandedContentOpen);
              }}
              className="cursor-pointer grid grid-cols-[auto_1fr_auto] gap-8 pr-10 pt-1 items-center"
            >
              <div>{`Split #${splitIndex}`}</div>
              <div>
                {status === 'DONE' && <CircleCheck className="fill-forest-800" />}
                {status === 'NEEDS_ATTENTION' && <TriangleAlert />}
              </div>
              <div className="font-medium">{formattedAmount}</div>
            </div>
            <OutlinedWidget.ExpandedContent isOpen={isOpen}>
              <div className="pt-8 mt-7 border-t border-primary-400 grid gap-8">
                <SplitDetailsContentRow>
                  <SmallTitle>Project</SmallTitle>
                  <div>{projectTitle ?? 'N/A'}</div>
                </SplitDetailsContentRow>

                {showAccountingCategoriesSection && (
                  <SplitDetailsContentRow>
                    <SmallTitle>
                      Accounting tag
                      <div className="text-xs text-muted">for Quickbooks</div>
                    </SmallTitle>
                    {!showEditAccountingTagSection && (
                      <div
                        className="flex justify-between items-start gap-4 cursor-pointer max-w-full min-w-0"
                        onClick={() => setShowEditAccountingTagSection(true)}
                      >
                        {!selectedAccountingTagName && (
                          <div
                            className={cn(
                              'text-sm font-bold',
                              isAccountingTagRequiredForQbo ? 'text-danger' : 'text-muted'
                            )}
                          >
                            Required for QuickBooks
                          </div>
                        )}

                        {selectedAccountingTagName && (
                          <PillsContainer className="max-w-[80%] overflow-hidden">
                            <Pill
                              variant="pending"
                              key={selectedAccountingTagName}
                              text={selectedAccountingTagName}
                              className="text-sm bg-forest-400 max-w-full"
                            />
                          </PillsContainer>
                        )}

                        <Pencil />
                      </div>
                    )}

                    {showEditAccountingTagSection && (
                      <SelectAccountingTag
                        selectedAccountingTagName={selectedAccountingTagName}
                        onChangeAccountingTag={(accountingTag) => {
                          onChangeAccountingTag(accountingTag);
                          setShowEditAccountingTagSection(false);
                        }}
                        isMissing={
                          showMissingRequirements &&
                          state.missingFields?.accountingTag &&
                          !selectedAccountingTagName
                        }
                      />
                    )}
                  </SplitDetailsContentRow>
                )}

                <SplitDetailsContentRow>
                  <SmallTitle>Project categories</SmallTitle>

                  {projectCategories.length > 0 && (
                    <PillsContainer>
                      {projectCategories.map((category) => (
                        <Pill
                          variant="accent"
                          key={category}
                          text={category}
                          className="text-sm bg-accent-300 capitalize"
                        />
                      ))}
                    </PillsContainer>
                  )}

                  {projectCategories.length === 0 && <div>N/A</div>}
                </SplitDetailsContentRow>

                <SplitDetailsContentRow>
                  <SmallTitle>Project rooms</SmallTitle>

                  <div>
                    {projectRooms.length > 0 && (
                      <PillsContainer>
                        {projectRooms.map((category) => (
                          <Pill
                            variant="accent"
                            key={category}
                            text={category}
                            className="text-sm bg-lavender-300 capitalize"
                          />
                        ))}
                      </PillsContainer>
                    )}

                    {projectRooms.length === 0 && <div>N/A</div>}
                  </div>
                </SplitDetailsContentRow>

                <SplitDetailsContentRow>
                  <SmallTitle>PO/Invoice #</SmallTitle>

                  <div>{invoiceNumber ?? 'N/A'}</div>
                </SplitDetailsContentRow>
              </div>
            </OutlinedWidget.ExpandedContent>
          </>
        )}
      </OutlinedWidget.Collapsable>
    </OutlinedWidget>
  );
};

const SplitDetailsContentRow = ({ children }: { children: ReactNode }) => {
  return <div className="grid grid-cols-[125px_1fr] gap-4 items-start">{children}</div>;
};

const SmallTitle = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-1 text-sm">{children}</div>;
};

const PillsContainer = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={cn('flex gap-4 flex-wrap', className)}>{children}</div>;
};

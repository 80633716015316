import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { toDollars } from 'utils';

export const QUERY_PROJECT_OVERVIEW = gql(`
  query GetProjectForProjectOverview($companySlug: String!, $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      amountSpent
      budget
      description
      address {
        street
        street2
        city
        state
        postalCode
      }
      cardId
      tags {
        name
        type
      }
    }
  }
`);

export const useGetProjectOverview = ({
  companySlug,
  projectId,
}: {
  companySlug?: string;
  projectId?: string;
}) => {
  const { loading, data, error } = useQuery(QUERY_PROJECT_OVERVIEW, {
    variables: {
      companySlug: companySlug || '',
      projectId: projectId || '',
    },
    skip: !projectId || !companySlug,
  });

  const totalSpent = toDollars(data?.getProject?.amountSpent || 0);

  return {
    project: data?.getProject,
    totalSpent,
    loading,
    error,
  };
};

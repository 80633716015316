import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  TransactionFiltersInput,
  GetTransactionsNeedingAttentionQuery,
} from '__generated__/graphql';

const GET_INTUIT_APP_CONNECTION_STATUS_QUERY = gql(`
  query GetIntuitAppConnectionForTransactionNeedingAttentionTable($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
    }
  }
`);

export const useIntuitAppConnectionStatus = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const isIntuitAppConnectedAndActive =
    data?.getIntuitAppConnection?.id &&
    data.getIntuitAppConnection.qboAccountId &&
    data.getIntuitAppConnection.startedAt;

  return { isIntuitAppConnectedAndActive, loading, error };
};

const GET_TRANSACTIONS_NEEDING_ATTENTION = gql(`
query GetTransactionsNeedingAttention(
  $customerId: String!
  $pageNumber: Int!
  $pageSize: Int
  $filters: TransactionFiltersInput
  $excludeChildren: Boolean
) {
  getAuthorizationsAndTransactions(
    customerId: $customerId
    pageNumber: $pageNumber
    pageSize: $pageSize
    excludeChildren: $excludeChildren
    filters: $filters
  ) {
    meta {
      pageNumber
      pageSize
      total
    }
    data {
      id
      name
      type
      shortName
      amount
      amountWithDirection
      spendCategory
      direction
      date
      accountId
      zenaType
      rooms
      categories
      card {
        id
        name
      }
      bank {
        id
        name
        last4Digits
      }
      tags {
        tagName
        value
      }
      roomTags {
        tagName
        value
      }
      categoryTags {
        tagName
        value
      }
      poCodeTag {
        tagName
        value
      }
      receipt {
        imageUrl
      }
      user {
        email
        fullName {
          first
          last
        }
      }
      projectId
      projectName
      lineItems {
        externalId
        description
        totalCents
      }
      parentTransactionId
      isAuthorization
      zenaType
      missingFields {
        receipt
        memo
        roomTag
        categoryTag
        accountingTag
      }
      childTransactionIds
      accountingSyncStatus
      qboAccountingIntegrationSync {
        id
        accountingIntegrationType
        syncedAt
        expenseId
      }
    }
  }
}
`);

interface UseGetTransactionsNeedingAttentionProps {
  customerId: string;
  pageNumber: number;
  pageSize: number;
  excludeChildren: boolean;
  filters: TransactionFiltersInput;
  onCompleted: (data: GetTransactionsNeedingAttentionQuery) => void;
}

export const useGetTransactionsNeedingAttention = ({
  customerId,
  pageNumber,
  pageSize,
  filters,
  excludeChildren,
  onCompleted,
}: UseGetTransactionsNeedingAttentionProps) => {
  const { data, loading, fetchMore, error, refetch } = useQuery(
    GET_TRANSACTIONS_NEEDING_ATTENTION,
    {
      variables: {
        customerId,
        pageNumber,
        pageSize,
        filters,
        excludeChildren,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted,
    }
  );

  return { data, loading, fetchMore, error, refetch };
};

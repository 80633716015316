import { Theme } from '@aws-amplify/ui-react';

const black = '#212121';
const pearl = '#EDE9E4';
const marble = '#AEAEAE';
const textLight = '#FFF';
const danger = '#DC1C1C';
const success = '#24A148';
const textDark = '#121212';
const warning = '#DFA102';

export const customAmplifyTheme: Theme = {
  name: 'tabs-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: 'Neue Haas Grotesk Display Pro, sans-serif' },
        static: { value: 'Neue Haas Grotesk Display Pro, sans-serif' },
      },
    },
    components: {
      alert: {
        error: {
          backgroundColor: danger,
          color: textLight,
        },
        success: {
          color: textLight,
          backgroundColor: success,
        },
        warning: {
          backgroundColor: warning,
          color: textDark,
        },
      },
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '450' },
        // style the primary variation
        primary: {
          color: { value: textLight },
          backgroundColor: { value: black },
          _hover: {
            backgroundColor: { value: black },
            color: { value: textLight },
          },
          _focus: {
            backgroundColor: { value: black },
          },
          _active: {
            backgroundColor: { value: black },
          },
        },
      },
      tabs: {
        borderColor: { value: pearl },
        item: {
          color: { value: marble },
          fontSize: { value: '22px' },
          fontWeight: { value: '450' },

          _hover: {
            color: { value: black },
          },
          _focus: {
            color: { value: black },
          },
          _active: {
            color: { value: black },
            borderColor: { value: black },
            backgroundColor: { value: '#transparent' },
          },
          _disabled: {
            color: { value: marble },
            backgroundColor: { value: 'transparent' },
          },
        },
      },
      selectfield: {
        color: { value: black },
        fontSize: { value: '16px' },
        borderColor: { value: black },
        _focus: {
          borderColor: { value: black },
        },
        label: {
          color: { value: black },
        },
      },
      radiogroup: {
        legend: {
          color: { value: black },
        },
        radio: {
          borderWidth: { value: '{borderWidths.small}' },
          borderColor: { value: black },
          backgroundColor: { value: 'white' },
          _checked: {
            color: { value: black },
          },
          label: {
            color: { value: black },
          },
        },
      },
      loader: {
        strokeEmpty: { value: marble },
        strokeFilled: { value: black },
      },
    },
  },
};

import { Input, InputProps } from '../Input';

export const EmailInput = ({
  placeholder = 'Email',
  ...props
}: Omit<InputProps, 'max' | 'type'>) => {
  return (
    <Input maxLength={160} name="email" autoComplete="email" placeholder={placeholder} {...props} />
  );
};

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Button, RoundButton } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import HeroSplitWithImage from 'components/widgets/HeroSplitWithImage';
import heroLaptopInDaylitRoom from 'images/heroLaptopInDaylitRoom.webp';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { PlaidLink } from 'components/widgets/PlaidLink';
import { CUSTOMER_CREDIT_QUERY } from './(dashboard)/widgets/CreditWidget/data';

const CREATE_PLAID_LINKED_ACCOUNT_MUTATION = gql(`
  mutation CreatePlaidLinkedAccount($companySlug: String!, $publicToken: String!, $accountId: String!) {
    createPlaidLinkedAccount(companySlug: $companySlug, accountType: "deposit", publicToken: $publicToken, accountId: $accountId) {
      message
    }
  }
`);

const REFETCH_GET_PLAID_LINKED_ACCOUNT_SUMMARY = gql(`
  query RefetchPlaidLinkedAccountSummary($companySlug: String!) {
    getPlaidLinkedAccountSummary(customerId: $companySlug) {
      id,
      bank,
      last4,
      accountType,
      status
    }
  }
`);

const REFETCH_REPAYMENT_CONNECTION_COMPLETE_QUERY = gql(`
  query RefetchRepaymentConnectionCompleteQueryAfterConnectingAccount($companySlug: String!)  {
    getRepaymentConnectionStatus(companySlug: $companySlug) {
      id
      connected
    }
  }
`);

const ConnectBankAccount = () => {
  const [searchParams] = useSearchParams();
  const update = searchParams.get('update') === 'true';
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPlaidLink, setShowPlaidLink] = useState(false);
  const [showPlaidError, setShowPlaidError] = useState(false);
  const setErrorMsg = useAppState((state) => state.setErrorMsg);

  const [refetchRepaymentConnectionStatus] = useLazyQuery(
    REFETCH_REPAYMENT_CONNECTION_COMPLETE_QUERY,
    {
      variables: {
        companySlug: activeCompanySlug,
      },
      fetchPolicy: 'network-only',
    }
  );

  const [createPlaidLinkedAccount, { loading: loadingCreateLinkedAccount }] = useMutation(
    CREATE_PLAID_LINKED_ACCOUNT_MUTATION,
    {
      onCompleted() {
        refetchRepaymentConnectionStatus().then(() => {
          navigate(`/dashboard`);
        });
      },
      onError: (error: ApolloError) => {
        onApolloError(error, setErrorMsg, [
          'CounterpartyNameVerificationError',
          'InsufficientCounterpartyBalance',
          'PlaidLinkAccountError',
        ]);
        setShowPlaidError(true);
        setShowPlaidLink(false);
      },
      refetchQueries: [
        {
          query: REFETCH_GET_PLAID_LINKED_ACCOUNT_SUMMARY,
          variables: {
            companySlug: activeCompanySlug,
          },
        },
      ],
    }
  );

  return (
    <>
      <HeroSplitWithImage
        image={heroLaptopInDaylitRoom}
        imageAlt="Person using laptop in daylit room"
        allowSignout
      >
        {loadingCreateLinkedAccount ? (
          <div className="flex flex-col items-center md:pt-24">
            <h2 className="mb-8">Setting up your account ...</h2>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <h1 className="mt-8 text-4xl max-w-[26rem] tracking-tight text-gray-900 sm:mt-14 sm:text-5xl">
              {showPlaidError
                ? 'Unable to connect bank account'
                : 'Let Zena remodel your project financials'}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-900 max-w-[28rem]">
              {showPlaidError ? (
                <span>
                  Plaid was unable to establish a secure connection. This could happen for a variety
                  of reasons and we're sorry this happened. Please try again. If this happens again
                  send us an email (<a href="mailto:help@getzena.com">help@getzena.com</a>) and
                  we'll do our best to solve this issue with Plaid.
                </span>
              ) : (
                'Zena Visa cards sort your client purchases into projects. To get started, link your business checking account.'
              )}
            </p>
            <div className="mt-10 flex flex-col">
              <div>
                {showPlaidError ? (
                  <RoundButton
                    text="Try again"
                    onClick={() => {
                      setShowPlaidError(false);
                    }}
                  />
                ) : (
                  <RoundButton
                    text="Link my bank"
                    onClick={() => setShowPlaidLink(true)}
                    variant="primary"
                    className="min-w-[10rem]"
                  />
                )}
              </div>
              <div className="mt-6">
                <Button onClick={() => setShowModal(true)} variant="link">
                  Why do we link to your bank?
                </Button>
              </div>
            </div>
          </>
        )}
        <div className="mt-16 sm:mt-20 lg:mt-28 flex flex-col">
          <hr />
          <p className="mt-4 text-xs text-marble">
            Zena provides a type of credit card called a charge card. You need to connect your
            business checking account to use your card. With Zena, the card balance is paid in full
            every cycle, and there is no interest.
          </p>
        </div>
      </HeroSplitWithImage>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <div className="">
          <div className="flex flex-col">
            <div className="text-3xl">Why do we link to your bank?</div>
            <div className="mt-6">
              <div className="font-semibold">Automatic Limits</div>
              <p className="">
                Zena links to your business checking bank account to fund your card purchases. To
                use Zena, your bank account must be linked at all times.
              </p>
            </div>
            <div className="mt-6">
              <div className="font-semibold">Secure & Encrypted</div>
              <p className="">
                Zena partners with Plaid to securely link your bank account. We use{' '}
                <a
                  href="https://www.govinfo.gov/app/details/GOVPUB-C13-5b70c4f21283f175408d9560480f73b3"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="underline decoration-0 underline-offset-1">
                    AES 256-bit encryption to keep your data safe
                  </span>
                </a>
                .
              </p>
            </div>
            <div className="mt-6">
              <div className="font-semibold">Daily Pay</div>
              <p className="">
                Your account balance will be withdrawn from your linked bank account each day.
              </p>
            </div>
          </div>
        </div>
      </Modal>
      {showPlaidLink && (
        <PlaidLink
          isUpdatingAccount={update}
          onConnectAccount={(publicToken: string, accountId: string) => {
            createPlaidLinkedAccount({
              variables: { companySlug: activeCompanySlug, publicToken, accountId },
              refetchQueries: [
                {
                  query: CUSTOMER_CREDIT_QUERY,
                  variables: {
                    companySlug: activeCompanySlug,
                  },
                },
              ],
            });
          }}
          onFailure={() => {
            setShowPlaidError(true);
            setShowPlaidLink(false);
          }}
          onExit={() => setShowPlaidLink(false)}
        />
      )}
    </>
  );
};

export default ConnectBankAccount;

import { ReactNode } from 'react';
import { useTransactionDetailsContext } from '../../state';
import { formatCentsToDollarsString } from 'utils';
import { formatPercentage } from 'utils/number';
import { EditTransactionSplitDetails } from './EditTransactionSplitDetails';

export const EditSplits = ({
  transactionTotalInCents,
  hasActiveQboConnection,
  showMissingRequirements = false,
}: {
  transactionTotalInCents: number;
  hasActiveQboConnection?: boolean;
  showMissingRequirements: boolean;
}) => {
  const { state, dispatch } = useTransactionDetailsContext();

  const splitsTotal = state.splits.reduce((total, split) => {
    total = total + split.amount;
    return total;
  }, 0);

  return (
    <div className="grid gap-6">
      <div
        className="w-[80px] h-[40px] border border-primary-400 rounded-lg overflow-hidden grid grid-cols-2 items-center"
        style={{
          backgroundImage:
            state.splitMethod !== 'AMOUNT'
              ? '-webkit-linear-gradient(-30deg, #FFF 50%, #121212 50%)'
              : '-webkit-linear-gradient(-30deg, #121212 50%, #FFF 50%)',
        }}
      >
        <ToggleButton
          onClick={() => {
            if (state.splitMethod !== 'AMOUNT') {
              dispatch({
                type: 'UPDATE_SPLIT_METHOD',
                payload: 'AMOUNT',
              });
            }
          }}
          isActive={state.splitMethod === 'AMOUNT'}
        >
          $
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            if (state.splitMethod !== 'PERCENT') {
              dispatch({
                type: 'UPDATE_SPLIT_METHOD',
                payload: 'PERCENT',
              });
            }
          }}
          isActive={state.splitMethod === 'PERCENT'}
        >
          %
        </ToggleButton>
      </div>

      <div className="py-6 border-b border-t border-primary-400 text-muted flex justify-between items-center font-medium px-1">
        <div>Amount left to assign</div>

        {state.splitMethod === 'AMOUNT' && (
          <div>{formatCentsToDollarsString(transactionTotalInCents - splitsTotal)}</div>
        )}

        {state.splitMethod === 'PERCENT' && (
          <div>{formatPercentage({ percentage: splitsTotal >= 100 ? 0 : 100 - splitsTotal })}</div>
        )}
      </div>

      {state.splits.map((split) => (
        <EditTransactionSplitDetails
          key={split.transactionId}
          splitId={split.transactionId}
          hasActiveQboConnection={hasActiveQboConnection}
          transactionTotalInCents={transactionTotalInCents}
          showMissingRequirements={showMissingRequirements}
        />
      ))}
    </div>
  );
};

const ToggleButton = ({
  isActive = false,
  children,
  onClick,
}: {
  children: ReactNode;
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className="border-none cursor-pointer flex justify-center h-full items-center font-medium"
      style={{
        color: isActive ? '#FFF' : '#121212',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

import { ReactNode } from 'react';
import { ZenaLogo } from 'icons/zena';
import { cn } from 'utils';

export const ImageCard = ({
  children,
  imageUrl,
  onClick,
}: {
  children: ReactNode;
  imageUrl?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        'bg-white border border-primary-400 grid grid-rows-2 h-full overflow-hidden relative rounded-3xl',
        onClick && 'cursor-pointer'
      )}
      onClick={onClick}
    >
      {imageUrl ? (
        // This image has not been tested
        <img src={imageUrl} alt="card" />
      ) : (
        <div className="bg-forest flex items-center justify-center h-44">
          <ZenaLogo />
        </div>
      )}
      {children}
    </div>
  );
};

import { Payment } from '__generated__/graphql';
import { Drawer, DrawerTitle } from 'components/atoms/Drawer';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { toDollarDisplay } from 'utils';

export const PaymentDetails = ({
  paymentDetails,
  onClose,
  show,
}: {
  paymentDetails: Payment;
  show: boolean;
  onClose: () => void;
}) => {
  return (
    <Drawer
      isVisible={show}
      className="flex flex-col px-4 md:h-[calc(100%-76px)] md:mt-[76px] relative"
      onClose={onClose}
    >
      <div className="overflow-y-auto pt-4 pb-32">
        <DrawerTitle title="Payment Details" onClose={onClose} />
        <div className="border-b-2 border-gray-100 px-4 py-2">
          <div className="mt-6 px-4">
            <h2 className="font-semibold text-lg">Account</h2>
          </div>
          <div className="px-4">{paymentDetails.account}</div>
        </div>
        <div className="border-b-2 border-gray-100 px-4 py-2">
          <div className="mt-6 px-4">
            <h2 className="font-semibold text-lg">Amount</h2>
          </div>
          <div className="px-4">{toDollarDisplay((paymentDetails.amountCents || 0) / 100)}</div>
        </div>
        <div className=" border-b-2 border-gray-100 px-4 py-2">
          <div className="mt-6 px-4">
            <h2 className="font-semibold text-lg">Initiated By</h2>
          </div>
          <div className="px-4">{paymentDetails.initiatedBy}</div>
        </div>
        <div className="border-b-2 border-gray-100 px-4 py-2">
          <div className="mt-6 px-4">
            <h2 className="font-semibold text-lg">Initiated</h2>
          </div>
          <div className="text-gray-500 px-4">
            <RelativeDate date={paymentDetails.dateInitiated} />
          </div>
        </div>
        <div className=" px-4 py-2">
          <div className="mt-6 px-4">
            <h2 className="font-semibold text-lg">Statement</h2>
          </div>
          <div className="px-4">{paymentDetails.statementDate}</div>
        </div>
      </div>
    </Drawer>
  );
};

import { useSubscription } from '@apollo/client';
import { Divider, Flex, Text } from '@aws-amplify/ui-react';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { StorageFile } from '@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types';
import { ProfilePhotoImage } from 'components/atoms/ProfilePhotoImage';
import useCustomAuth from 'hooks/useCustomAuth';
import { SetAlertParams } from 'pages/UserProfile/MyProfileSettings';
import { useCallback, useState } from 'react';
import { useGetProfileImageUrlsForUpload } from './data';
import { gql } from '__generated__/gql';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

type ProfilePhotoUploaderProps = {
  setAlert: (alertParams: SetAlertParams) => void;
  setProfileButtonText: (buttonText: string) => void;
};

const SUBSCRIPTION_UPDATED_PROFILE_PICTURE = gql(`
  subscription OnProfilePictureUpdate($userId: String!) {
    onProfilePictureUpdate(resourceId: $userId, resourceType: "user") {
      resourceId
      resourceType
    }
  }
`);

export const ProfilePhotoUploader = ({
  setAlert,
  setProfileButtonText,
}: ProfilePhotoUploaderProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { user } = useCustomAuth();
  const userSub = user?.attributes?.sub;
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const {
    user: userGql,
    refetch,
    loading,
  } = useGetProfileImageUrlsForUpload({
    activeCompanySlug: activeCompanySlug,
  });

  // Add the user's sub and file name to the key
  const processFile = ({
    file,
    key,
  }: Required<Pick<StorageFile, 'file' | 'key'>>): Required<Pick<StorageFile, 'file' | 'key'>> => {
    return {
      file,
      key: `user/${userSub}/profile-photo.${file.name.split('.').pop()}`,
    };
  };

  const onUploadError = useCallback((error: string, file: { key: string }) => {
    console.error(error, file);
  }, []);

  useSubscription(SUBSCRIPTION_UPDATED_PROFILE_PICTURE, {
    variables: { userId: userSub ?? '' },
    skip: !userSub,
    onData: async ({ data }) => {
      const subscriptionEvent = data.data?.onProfilePictureUpdate;
      if (subscriptionEvent?.resourceId) {
        setIsUploading(false);
        await refetch();
        setAlert({
          message: 'Profile photo uploaded successfully!',
          variation: 'success',
        });
        setProfileButtonText('Save');
      }
    },
  });

  return (
    <>
      <StorageManager
        acceptedFileTypes={['image/*']}
        accessLevel="private"
        maxFileCount={1}
        maxFileSize={5 * 1024 * 1024}
        processFile={processFile}
        onUploadStart={() => {
          setIsUploading(true);
        }}
        onUploadSuccess={() => {
          setIsUploading(false);
        }}
        onUploadError={onUploadError}
        displayText={{
          browseFilesText: 'Update',
        }}
        components={{
          DropZone({ children, displayText, inDropZone, ...rest }) {
            return (
              <Flex
                alignItems="center"
                direction="column"
                padding="medium"
                backgroundColor={inDropZone ? 'brand.primary.10' : ''}
                {...rest}
              >
                {isUploading || loading ? (
                  <LoadingSpinner />
                ) : (
                  <ProfilePhotoImage
                    signedProfilePhotoUrl={userGql?.smallImageUrl ?? null}
                    size={20}
                  />
                )}
                <Text>Drop files here</Text>
                <Divider size="small" label="or" maxWidth="10rem" />
                {children}
              </Flex>
            );
          },
        }}
      />
    </>
  );
};

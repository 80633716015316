import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY = gql(`
query GetProfileImageUrlsForAuthorizedCard {
    getMe {
        id
        smallImageUrl
        largeImageUrl
    }
}
`);

export const useGetProfileImageUrlsForAuthorizedCard = ({
  activeCompanySlug,
}: {
  activeCompanySlug: string;
}) => {
  const { data, loading, error } = useQuery(QUERY, {
    skip: !activeCompanySlug,
  });

  return {
    user: data?.getMe ?? null,
    loading,
    error,
  };
};

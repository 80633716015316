import { Statement } from '__generated__/graphql';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import { formatCentsToDollarsString } from 'utils';
import { TableRow } from 'components/atoms/table/TableRow';
import { Link } from 'react-router-dom';

export const StatementTableRow = ({ data }: { data: Statement }) => {
  const [formattedDate, setFormattedDate] = useState<string>('');
  const date = moment.utc(data.date);
  const adjustmentTotalCents = data.adjustments.reduce(
    (partialSum, adjustment) => (partialSum as number) + Number(adjustment?.amountCents),
    0
  );

  useEffect(() => {
    const formatted = data.capture === 'month' ? date.format('YYYY-MM') : date.format('YYYY-MM-DD');
    setFormattedDate(formatted);
  }, []);

  const href = `/statements/${date.format('YYYY-MM-DD')}`;
  return (
    <TableRow key={data.id} className="hover:cursor-pointer hover:bg-accent-200">
      <TableRowLink href={href}>
        <div className="flex items-center">{formattedDate}</div>
      </TableRowLink>
      <TableRowLink href={href}>{formatCentsToDollarsString(data.beginningBalance)}</TableRowLink>
      <TableRowLink href={href}>{formatCentsToDollarsString(data.statementAmount)}</TableRowLink>
      <TableRowLink href={href}>{formatCentsToDollarsString(adjustmentTotalCents)}</TableRowLink>
      <TableRowLink href={href}>
        {data.paymentMade ? formatCentsToDollarsString(data.paymentMade) : null}
      </TableRowLink>
      <TableRowLink href={href}>{formatCentsToDollarsString(data.endingBalance)}</TableRowLink>
    </TableRow>
  );
};

const TableRowLink = ({ children, href }: { children: ReactNode; href: string }) => {
  return (
    <td className="p-0">
      <Link to={href} className="block p-4">
        {children}
      </Link>
    </td>
  );
};

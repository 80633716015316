import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';

import { PageTitle } from 'components/atoms/PageTitle';
import { LoginLayout } from '../../LoginLayout';
import useCustomAuth from 'hooks/useCustomAuth';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { useWindowKeyboardInteraction } from 'components/atoms/WindowInteractionListeners/keyboard';
import { PasswordInput } from 'components/atoms/Inputs/PasswordInput';
import { Field } from 'components/atoms/Inputs/Field';
import { Input } from 'components/atoms/Inputs/Input';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { validateResetPasswordFormState, useResetPasswordFormReducer } from './state';
import { Alert } from 'components/atoms/Alert';

export const ResetPasswordPage = () => {
  const location = useLocation();
  const email = location.state?.resetPasswordEmail ?? undefined;
  const navigate = useNavigate();
  const { resendForgotPassword, forgotPasswordSubmit } = useCustomAuth();
  const [state, dispatch] = useResetPasswordFormReducer();
  const [showFormErrors, setShowFormErrors] = useState(false);
  const formValidation = useMemo(() => {
    return validateResetPasswordFormState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [{ ...state }]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [resendCodeMessage, setResendCodeMessage] = useState('');

  const buttonRef = useRef<HTMLButtonElement>(null);
  useWindowKeyboardInteraction({
    onKeyPress: (event) => {
      if (buttonRef.current && event.key === 'Enter') {
        buttonRef.current.click();
      }
    },
  });

  useEffect(() => {
    if (!email) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onSubmit = async () => {
    const { verificationCode, password } = state;
    setIsSubmitting(true);

    if (email && verificationCode && password && formValidation.isValid) {
      try {
        const response = await forgotPasswordSubmit(email, verificationCode, password);
        if (response.success) {
          setErrorMessage('');
          navigate('/login', {
            replace: true,
          });
        } else {
          setErrorMessage(
            response.errorMessage || 'Error during updating password, please contact support'
          );
        }
      } catch (error) {
        setErrorMessage('Unknown error, please contact support');
      }
      setIsSubmitting(false);
    } else {
      setShowFormErrors(true);
      setIsSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    if (email) {
      setIsResending(true);
      const response = await resendForgotPassword(email);
      if (response.success) {
        setResendCodeMessage('Code has been sent');
      } else {
        setErrorMessage('Error resending code to email');
      }
      setIsResending(false);
    } else {
      setErrorMessage('Error resending code to email');
    }
  };

  if (email) {
    return (
      <>
        <LoginLayout className="grid gap-8" hideQuotes>
          <PageTitle text="Reset password" hidden />

          <div className="grid gap-2">
            <h1 className="text-2xl">Set new password</h1>
            <p className="text-muted text-lg">{`If ${email} is associated with a Zena account, your code is on the way. To set your new password, enter the code we emailed to you. It may take a minute to arrive.`}</p>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className="grid gap-8"
          >
            <div className="grid gap-4">
              <Field
                label="Code"
                errorMessage={showFormErrors ? formValidation.verificationCode : undefined}
              >
                <Input
                  value={state.verificationCode}
                  onChange={(e) => {
                    setShowFormErrors(false);
                    dispatch({
                      type: 'UPDATE_VERIFICATION_CODE',
                      payload: e.currentTarget.value,
                    });
                  }}
                  placeholder="Code"
                  inputState={
                    showFormErrors && formValidation.verificationCode ? 'error' : 'normal'
                  }
                />
              </Field>
              <Field
                label="New Password"
                errorMessage={showFormErrors ? formValidation.password : undefined}
              >
                <PasswordInput
                  value={state.password}
                  onChange={(e) => {
                    setShowFormErrors(false);
                    dispatch({
                      type: 'UPDATE_PASSWORD',
                      payload: e.currentTarget.value,
                    });
                  }}
                  placeholder="New Password"
                  inputState={showFormErrors && formValidation.password ? 'error' : 'normal'}
                />
              </Field>
              <UnderlineButton
                className={`text-muted text-sm py-0 px-1 w-max`}
                onClick={(e) => {
                  e.preventDefault();
                  handleResendCode();
                }}
                disabled={isResending}
                type="button"
              >
                Resend code
              </UnderlineButton>
            </div>
            <RoundButton
              variant="primary"
              type="submit"
              className="w-full"
              text="Set password"
              disabled={isSubmitting}
              ref={buttonRef}
            />
          </form>
        </LoginLayout>

        {(errorMessage || resendCodeMessage) && (
          <ToastContainer>
            {errorMessage && (
              <Alert
                variation="error"
                onDismiss={() => setErrorMessage('')}
                title="Something went wrong"
                description={errorMessage}
              />
            )}
            {resendCodeMessage && (
              <Alert
                variation="success"
                onDismiss={() => setResendCodeMessage('')}
                title={resendCodeMessage}
              />
            )}
          </ToastContainer>
        )}
      </>
    );
  }

  return null;
};

import { ApolloError, useMutation } from '@apollo/client';
import { RoundButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { ProjectReviewSuccess } from './ProjectReviewSuccess';
import { SearchUsers } from 'components/molecules/SearchUsers';
import { useEffect, useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError, toCents, toDollars } from 'utils';
import { gql } from '__generated__/gql';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { User } from 'types';

const MUTATION_APPROVE_PROJECT = gql(`
  mutation ApproveProject($customerId: String!, $projectId: String!, $name: String!, $budget: Int, $users: [String!]!) {
    approveProject(customerId: $customerId, projectId: $projectId, name: $name, budget: $budget, users: $users) {
      id
      name
      budget
      approved
      cardLast4Digits
    }
  }
`);

const MUTATION_REJECT_PROJECT = gql(`
  mutation RejectProject($customerId: String!, $projectId: String!) {
    rejectProject(customerId: $customerId, projectId: $projectId) {
      id
      name
    }
  }
`);

export const ReviewProject = ({
  customerId,
  onApproveProject,
  projectName,
  projectId,
  authorizedUsers,
  budget,
  users = [],
}: {
  customerId: string;
  onApproveProject: () => void;
  projectName: string;
  projectId: string;
  authorizedUsers: User[];
  budget?: number;
  users?: string[];
}) => {
  const [name, setName] = useState<string>(projectName);
  const [budgetDollars, setBudgetDollars] = useState<string | undefined>(
    budget ? toDollars(budget).toString() : undefined
  );
  const [selectedProjectUserIds, setSelectedProjectUserIds] = useState<string[]>([]);
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);

  const [approveProject, { data: approveProjectData, loading: approveProjectLoading }] =
    useMutation(MUTATION_APPROVE_PROJECT, {
      fetchPolicy: 'no-cache',
      refetchQueries: ['GetProjectForSingleView'],
    });
  const [rejectProject, { data: rejectProjectData, loading: rejectProjectLoading }] = useMutation(
    MUTATION_REJECT_PROJECT,
    { fetchPolicy: 'no-cache' }
  );

  const onSubmit = (approved: boolean) => {
    approved
      ? approveProject({
          variables: {
            customerId: customerId,
            projectId,
            name: name,
            budget: budgetDollars?.length ? toCents(parseInt(budgetDollars)) : null,
            users: selectedProjectUserIds,
          },
          onError: (error: ApolloError) => onApolloError(error, setErrorMsg, []),
        })
      : rejectProject({
          variables: {
            customerId: customerId,
            projectId,
          },
          onError: (error: ApolloError) => onApolloError(error, setErrorMsg, []),
        });
  };

  useEffect(() => {
    if (users.length) {
      setSelectedProjectUserIds(
        users.filter((userId) =>
          authorizedUsers.some((authorizedUser) => authorizedUser.id === userId)
        )
      );
    }
  }, [authorizedUsers, users]);

  if (approveProjectLoading || rejectProjectLoading) {
    return (
      <>
        <div className="grid place-items-center mt-52">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Loading ...</p>
          </div>
        </div>
      </>
    );
  }
  if (approveProjectData?.approveProject) {
    const data = approveProjectData.approveProject;
    return (
      <ProjectReviewSuccess
        customerId={customerId}
        approved={true}
        name={data?.name}
        budget={data?.budget ?? undefined}
        cardLast4Digits={data.cardLast4Digits ?? undefined}
        projectId={data?.id}
        onApproveProject={onApproveProject}
      />
    );
  } else if (rejectProjectData?.rejectProject) {
    const data = rejectProjectData.rejectProject;
    return (
      <ProjectReviewSuccess
        customerId={customerId}
        approved={false}
        name={data.name}
        budget={undefined}
        cardLast4Digits={undefined}
        projectId={data.id}
        onApproveProject={onApproveProject}
      />
    );
  } else {
    return (
      <div className="w-full h-full bg-ivory">
        <div className="w-1/2 mx-auto pt-12">
          <h1 className="pb-6">Review the project</h1>
          <p className="pb-6">Please review the project details below and make any changes.</p>

          <TextInput
            label="Project Name"
            value={name}
            className="mb-4"
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label="Estimated project budget (optional)"
            prefix="$"
            placeholder="0.00"
            value={budgetDollars}
            className="mb-4"
            onChange={(e) => {
              setBudgetDollars(e.target.value);
            }}
          />
          <p className="mb-4 mt-2">You can adjust it later if needed.</p>

          <h3 className="mb-4">People assigned to the project</h3>
          <p className="mb-6">
            Anyone you add will have access to the project card and the ability to see all project
            details.
          </p>
          <SearchUsers
            customerId={customerId || ''}
            placeholder="Add people by email or name"
            selectedUsers={authorizedUsers.filter((user: User) =>
              selectedProjectUserIds.includes(user.id)
            )}
            onUserSelect={(user: User) =>
              setSelectedProjectUserIds([...selectedProjectUserIds, user.id])
            }
            onUserRemove={(user) =>
              setSelectedProjectUserIds(
                selectedProjectUserIds.filter((userId: string) => userId !== user.id)
              )
            }
            userListClassName="h-auto"
          />
          <RoundButton
            className="mt-12"
            text="Approve project"
            variant="primary"
            onClick={() => onSubmit(true)}
          />
          <RoundButton
            className="mt-12 ml-4"
            text="Reject"
            variant="secondary"
            onClick={() => onSubmit(false)}
          />
        </div>
      </div>
    );
  }
};

import { ProjectStatus } from '__generated__/graphql';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { cn, formatNumberAsDollars } from 'utils';
import { formatNumberAbbreviated } from 'utils/number';

const projectStatuses = ['Completed', 'Active'];

export interface IncomeByProjectChartData {
  id: string;
  projectName: string;
  income: number;
}

export const IncomeByProjectChart = ({
  totalIncome,
  projectStatus,
  onChangeProjectStatus,
  isLoading,
  data,
  className = '',
  disabled = false,
}: {
  totalIncome: number;
  projectStatus: ProjectStatus;
  onChangeProjectStatus: (projectStatus: ProjectStatus) => void;
  isLoading?: boolean;
  data: IncomeByProjectChartData[];
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div className={cn('w-full grid gap-12', className)}>
      <div className="w-full grid gap-4">
        <div className="flex flex-row justify-between items-center">
          <CardTitle>Payments by project</CardTitle>
          <select
            value={String(projectStatus)}
            onChange={(e) => {
              onChangeProjectStatus(e.currentTarget.value as ProjectStatus);
            }}
            disabled={disabled}
          >
            {projectStatuses.map((projectStatus) => {
              return (
                <option key={projectStatus} value={`${projectStatus.toLowerCase()}`}>
                  {projectStatus}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-row justify-between items-end">
          <div>
            <div className="text-3xl">
              {isLoading ? (
                <RectangleSkeleton height="large" width="large" />
              ) : (
                `$${formatNumberAbbreviated({ amount: totalIncome })}`
              )}
            </div>
            <div className="text-sm text-muted">Total amount</div>
          </div>
        </div>
      </div>

      <div className="w-full aspect-[2.58/1]">
        {isLoading && (
          <div className="w-full h-full grid gap-4">
            <RectangleSkeleton />
            <RectangleSkeleton />
            <RectangleSkeleton />
            <RectangleSkeleton />
          </div>
        )}
        {!isLoading && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} barCategoryGap={16} barSize={100} barGap={10}>
              <XAxis dataKey="projectName" />
              <YAxis
                width={65}
                tickFormatter={(value) => `$${formatNumberAbbreviated({ amount: value })}`}
              />
              {!disabled && <Tooltip formatter={(value) => formatNumberAsDollars(Number(value))} />}
              <CartesianGrid
                verticalCoordinatesGenerator={() => {
                  return [];
                }}
              />

              <Bar dataKey="income" className="fill-forest-700" radius={[8, 8, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

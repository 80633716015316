import { useEffect, useState } from 'react';
import { SelectField } from '@aws-amplify/ui-react';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { Pill } from 'components/atoms/Pill';
import { TransactionTag } from '__generated__/graphql';

interface RoomPoCodeProps {
  loading: boolean;
  roomTags: string[];
  categoryTags: string[];
  addRoomTag: (value: string) => void;
  addCategoryTag: (value: string) => void;
  poCode: string;
  setPoCode: (value: string) => void;
  onClickCancel: (value: string, type: string) => void;
  roomsError: string;
  categoriesError: string;
  tags?: TransactionTag[];
  projectId?: string;
}

type TagOptions = {
  [key: string]: string;
};

const RoomPoCode = ({
  loading,
  roomTags,
  categoryTags,
  addRoomTag,
  addCategoryTag,
  poCode,
  setPoCode,
  onClickCancel,
  roomsError,
  categoriesError,
  projectId,
  tags = [],
}: RoomPoCodeProps) => {
  const [availableRoomTags, setAvailableRoomTags] = useState<TagOptions>({});
  const [availableCategoryTags, setAvailableCategoryTags] = useState<TagOptions>({});

  const setAvailableOptions = () => {
    if (tags.length > 0) {
      const formattedProjectRoomTags: { [key: string]: string } = {};
      const formattedProjectCategoryTags: { [key: string]: string } = {};

      tags.forEach((tag: TransactionTag) => {
        if (tag.type === 'room') {
          const value = tag.name.toLowerCase().replace(/ /g, '_');
          formattedProjectRoomTags[tag.name] = value;
        } else if (tag.type === 'category') {
          const value = tag.name.toLowerCase().replace(/ /g, '_');
          formattedProjectCategoryTags[tag.name] = value;
        }
      });
      setAvailableRoomTags(formattedProjectRoomTags);
      setAvailableCategoryTags(formattedProjectCategoryTags);
    } else {
      setAvailableRoomTags({});
      setAvailableCategoryTags({});
    }
  };

  useEffect(() => {
    setAvailableOptions();
  }, []);

  const roomSubheaderString = !projectId
    ? 'Select a project to add room tags'
    : Object.keys(availableRoomTags).length
      ? ''
      : 'No room tags available. Assign tags in project settings.';

  const categorySubheaderString = !projectId
    ? 'Select a project to add category tags'
    : Object.keys(availableCategoryTags).length
      ? ''
      : 'No category tags available. Assign tags in project settings.';

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-asphalt text-2xl">
          Which room was this purchase related to?
          <br />
          <span className="text-marble">(optional)</span>
          <br />
          <span className="text-neutral-400 text-[12px]">{roomSubheaderString}</span>
        </h1>
      </div>
      <div className="mt-6">
        <SelectField
          label="Room"
          labelHidden
          placeholder="Select a room"
          className="rounded-lg"
          value={''}
          disabled={Object.keys(availableRoomTags).length === 0}
          errorMessage={roomsError}
          hasError={roomsError.length > 0}
          onChange={(e) => {
            addRoomTag(e.target.value);
          }}
        >
          {Object.keys(availableRoomTags).map((roomName) => (
            <option key={availableRoomTags[roomName]} value={roomName}>
              {roomName}
            </option>
          ))}
        </SelectField>
        {roomTags.map((roomName) => (
          <Pill
            key={roomName}
            text={roomName}
            variant={'cancelable'}
            className={'m-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel(roomName, 'room')}
          />
        ))}
      </div>
      <div className="flex flex-col mt-16">
        <h1 className="text-asphalt text-2xl">
          Which category was this purchase related to?
          <br />
          <span className="text-marble">(optional)</span>
          <br />
          <span className="text-neutral-400 text-[12px]">{categorySubheaderString}</span>
        </h1>
      </div>
      <div className="mt-6">
        <SelectField
          label="Category"
          labelHidden
          placeholder="Select a category"
          className="rounded-lg"
          value={''}
          disabled={Object.keys(availableCategoryTags).length === 0}
          errorMessage={categoriesError}
          hasError={categoriesError.length > 0}
          onChange={(e) => {
            addCategoryTag(e.target.value);
          }}
        >
          {Object.keys(availableCategoryTags).map((categoryName) => (
            <option key={availableCategoryTags[categoryName]} value={categoryName}>
              {categoryName}
            </option>
          ))}
        </SelectField>
        {categoryTags.map((categoryName) => (
          <Pill
            key={categoryName}
            text={categoryName}
            variant={'cancelable'}
            className={'m-1 flex-shrink-0'}
            onClickCancel={() => onClickCancel(categoryName, 'category')}
          />
        ))}
      </div>
      <div className="flex flex-col mt-16">
        <h1 className="text-asphalt text-2xl">
          Add invoice code or PO code <span className="text-marble">(optional)</span>
        </h1>
      </div>
      <div className="mt-6">
        <TextInput
          className="block border border-[#B1B1B3] rounded-lg px-3 py-3 w-full"
          type="text"
          label="Max 256 characters"
          value={poCode}
          disabled={loading}
          maxLength={256}
          onChange={(e) => {
            setPoCode(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export default RoomPoCode;

import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';

export const CREATE_COMPANY_MUTATION = gql(`
  mutation CreateCompanyFromOnboardingFlow(
		$companyName: String!
		$teamSize: Int!
		$tier: CompanyTier!
		$purchaseOnBehalfOfClients: Boolean!
    $firstName: String!
    $lastName: String!
	) {
    initializeCompanyAccount(
      companyName: $companyName
      teamSize: $teamSize
      tier: $tier
      purchaseOnBehalfOfClients: $purchaseOnBehalfOfClients
      firstName: $firstName
      lastName: $lastName
	  ) {
      id
      name
      status
      tier
      slug
    }
  }
`);

const GET_PENDING_INVITATIONS_QUERY = gql(`
  query GetUserPendingInvitations  {
    getUserPendingInvitations {
      email
      companySlug
      status
    }
  }
`);

export const useGetPendingInvitations = () => {
  const { loading, data, error } = useQuery(GET_PENDING_INVITATIONS_QUERY);

  return {
    pendingInvitations: data?.getUserPendingInvitations ?? [],
    loading,
    error,
  };
};

export const GetActiveCompanyQueryRefetch = gql(`
  query RefetchActiveCompany {
    getMe {
      id
      defaultCompany {
        id
        name
        status
        tier
        slug
      }
    }
  }
`);

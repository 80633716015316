import { Alert, AlertTextContainer } from 'components/atoms/Alert';
import { Header } from './Header';
import { Navbar } from './Navbar';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { useState } from 'react';
import { AppState, LocalUserStore, useAppState, useLocalStore } from 'stores/UserStore';

interface ZenaRouteProps {
  children: JSX.Element;
  withHeader?: boolean;
  withNavbar?: boolean;
}

export function ZenaRoute({
  children,
  withHeader = true,
  withNavbar = true,
}: ZenaRouteProps): JSX.Element | null {
  const [errorMsg, setErrorMsg, successMsg, setSuccessMsg] = useAppState((state: AppState) => [
    state.errorMsg,
    state.setErrorMsg,
    state.successMsg,
    state.setSuccessMsg,
  ]);
  const [impersonationEmail] = useLocalStore((state: LocalUserStore) => [state.impersonationEmail]);
  const [impersonatingMsg, setImpersonationMessage] = useState<string>(
    impersonationEmail ? `Impersonating as ${impersonationEmail}` : ''
  );

  const orContactSupport = (
    <p className="">
      Try again later or contact
      <a href="mailto:support@getzena.com">
        <span className="text-danger"> Zena support.</span>
      </a>
    </p>
  );

  return (
    <div>
      {!withNavbar && !withHeader && children}
      {withHeader && (
        <div className="w-screen top-0">
          <Header />
        </div>
      )}
      {!withNavbar && withHeader && <div className="w-full">{children}</div>}
      {withNavbar && (
        <>
          <div className="md:left-0 left-[-232px] fixed w-[232px]">
            <Navbar />
          </div>
          <div className="w-screen bg-secondary-300 md:w-[calc(100vw-232px)] h-[calc(100vh-4rem)] fixed md:left-[232px]">
            <div className="w-full h-full bg-white overflow-y-auto md:rounded-tl-4xl">
              {children}
            </div>
          </div>
        </>
      )}

      {(errorMsg || impersonatingMsg.length > 0 || successMsg) && (
        <ToastContainer>
          <>
            {errorMsg && (
              <Alert variation="error" onDismiss={() => setErrorMsg(null)}>
                <AlertTextContainer>
                  <b>{errorMsg}</b>
                  {orContactSupport}
                </AlertTextContainer>
              </Alert>
            )}
            {!!successMsg && (
              <Alert variation="success" onDismiss={() => setSuccessMsg(null)}>
                {successMsg}
              </Alert>
            )}
          </>
        </ToastContainer>
      )}

      {impersonatingMsg.length > 0 && (
        <div className="fixed top-5 left-1/4 w-2/4 flex justify-center">
          <Alert
            variation="warning"
            title={impersonatingMsg}
            description={`You will continue impersonating ${impersonationEmail} even after closing this warning`}
            onDismiss={() => setImpersonationMessage('')}
          />
        </div>
      )}
    </div>
  );
}

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Input } from 'components/atoms/Inputs/Input';
import { Field } from 'components/atoms/Inputs/Field';

type ProjectDetailsFormData = {
  name: string;
  description: string;
};

type ProjectDetailsFormProps = {
  register: UseFormRegister<ProjectDetailsFormData>;
  errors: FieldErrors<ProjectDetailsFormData>;
};

const ProjectDetailsForm = ({ register, errors }: ProjectDetailsFormProps) => {
  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-2">
        <Field label="Project name" errorMessage={errors.name ? errors.name.message : undefined}>
          <Input
            className="bg-white"
            {...register('name', { required: 'Project name is required' })}
            inputState={errors.name ? 'error' : 'normal'}
          />
        </Field>
      </div>
      <div className="flex flex-col space-y-2">
        <label className="text-asphalt text-sm">Project description (optional)</label>
        <textarea
          className="w-full h-24 border border-[#B1B1B3] rounded-lg px-3 py-3"
          placeholder="A description of the client project"
          maxLength={400}
          {...register('description')}
        ></textarea>
      </div>
    </div>
  );
};

export default ProjectDetailsForm;

import { ProfilePhotoImage } from 'components/atoms/ProfilePhotoImage';
import { TeamMember } from './TeamMembersProvider';

export const TeamMemberProfile = ({ member }: { member: TeamMember }) => {
  return (
    <div className="flex min-w-0 gap-x-4 items-center">
      <ProfilePhotoImage signedProfilePhotoUrl={member.signedProfilePhotoUrl ?? null} size={12} />
      <div className="min-w-0 flex-auto justify-center">
        <p className="text-lg font-semibold leading-6 text-primary-800">
          {member.fullName.first} {member.fullName.last}
        </p>
        <p className="truncate text-base leading-5 text-primary-600">{member.email}</p>
      </div>
    </div>
  );
};

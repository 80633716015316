import { ProjectStatus } from '__generated__/graphql';
import { IncomeByProjectChart } from '../DashboardCharts/IncomeByProjectChart';

export const IncomeByProjectMarketingGraph = () => {
  return (
    <IncomeByProjectChart
      totalIncome={16_000}
      projectStatus={'active' as ProjectStatus}
      onChangeProjectStatus={() => {}}
      isLoading={false}
      disabled
      data={[
        {
          id: '5a7e26dd-42b6-4b31-a35e-c0a471b6e6ce',
          projectName: 'Project 1',
          income: 7_000,
        },
        {
          id: '4e2d0e79-74de-4682-8e60-42806fd07994',
          projectName: 'Project 2',
          income: 10_000,
        },
        {
          id: 'a3cd36ec-84c9-48a0-8c8c-9d04ae221dc0',
          projectName: 'Project 3',
          income: 6_000,
        },
        {
          id: '7a0e4356-de8c-4327-9d96-854ae4a37143',
          projectName: 'Project 4',
          income: 2_000,
        },
        {
          id: '99a1b63d-ab0b-4e2a-aa2a-508da07ecfbd',
          projectName: 'Project 5',
          income: 7_000,
        },
        {
          id: 'ffe75aa2-e5e1-4561-bb61-bce17532b448',
          projectName: 'No project',
          income: 3_000,
        },
      ]}
    />
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const INCOME_BY_PROJECTS_QUERY = gql(`
  query GetIncomeByProjects($companySlug: String!) {
    getIncomeByProjects(companySlug: $companySlug) {
      activeProjectsTotalIncomeAmountCents
      completedProjectsTotalIncomeAmountCents
      activeProjects {
        id
        totalIncomeAmountCents
        projectName
        projectStatus
      }
      completedProjects {
        id
        totalIncomeAmountCents
        projectName
        projectStatus
      }
    }
  }
`);

export const useIncomeByProjects = (companySlug: string) => {
  const { data, loading, error } = useQuery(INCOME_BY_PROJECTS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return { data: data?.getIncomeByProjects, loading, error };
};

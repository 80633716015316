import '@aws-amplify/ui-react/styles.css';
import { PageTitle } from 'components/atoms/PageTitle';
import useCustomAuth from 'hooks/useCustomAuth';
import React, { ReactNode } from 'react';
import { CreditCard, MailQuestion, MessageCircleQuestion } from 'lucide-react';

const supportOptions = [
  {
    title: 'Help Center',
    description:
      'Need help getting started or have other questions about Zena? Get answers to your questions 24/7.',
    icon: <MessageCircleQuestion />,
    href: 'https://support.getzena.com/',
    linkProps: {
      target: '_blank',
      rel: 'noreferrer',
    },
  },
  {
    title: 'Zena Support',
    description:
      'Email or request a call back. Our support team specialists are available Monday — Friday from 8am to 5pm PT.',
    icon: <MailQuestion />,
    href: 'mailto:support@getzena.com',
  },
  {
    title: 'Contact Visa',
    description: (
      <>
        <a
          href="https://support.getzena.com/article/48-reporting-a-lost-or-stolen-card"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          Replace lost or stolen cards
        </a>
        : <a href="tel:8008472911">(800) 847-2911</a>
      </>
    ),
    icon: <CreditCard />,
  },
];

const Support = () => {
  const { user } = useCustomAuth();

  const CardBody = (supportOption: {
    title: string;
    description: React.ReactNode;
    icon: ReactNode;
  }) => {
    return (
      <div className="bg-white hover:bg-ivory border-feather border rounded-3xl p-8 h-full">
        <div className="h-14 w-14 rounded-full bg-secondary flex justify-center items-center">
          {supportOption.icon}
        </div>
        <h3 className="mt-4">{supportOption.title}</h3>
        <div>
          <p className="text-base text-asphalt mt-2">{supportOption.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full w-full">
      <div className="flex flex-col gap-y-12 p-10">
        <div>
          <PageTitle hidden text="Support" />
          <h1>
            Hi {user?.attributes?.given_name || 'there'}.<br />
            How can we help?
          </h1>
          <p className="text-lg text-asphalt max-w-xl mt-6">
            We're here to help and answer all of your questions.
          </p>
        </div>
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 content-stretch">
          {supportOptions.map((supportOption) =>
            supportOption.href ? (
              <a href={supportOption.href} {...supportOption.linkProps} key={supportOption.title}>
                <CardBody
                  title={supportOption.title}
                  description={supportOption.description}
                  icon={supportOption.icon}
                />
              </a>
            ) : (
              <CardBody
                title={supportOption.title}
                description={supportOption.description}
                icon={supportOption.icon}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Support;

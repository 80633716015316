import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const SELECT_PROJECT_CATEGORIES_QUERY = gql(`
  query SelectProjectCategoriesQuery($companySlug: String! $projectId: String!) {
    getProject(customerId: $companySlug, projectId: $projectId) {
      id
      categories
    }
  }
`);

export const useProjectCategories = ({
  companySlug,
  projectId,
}: {
  companySlug: string;
  projectId: string;
}) => {
  const { data, loading, error } = useQuery(SELECT_PROJECT_CATEGORIES_QUERY, {
    variables: {
      companySlug,
      projectId,
    },
    skip: !companySlug || !projectId,
  });

  const categories = data?.getProject?.categories ?? [];

  return { categories, loading, error };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { GenericLoader } from 'components/atoms/Loader';
import useCustomAuth from 'hooks/useCustomAuth';
import { Link } from 'react-router-dom';

const GET_OR_CREATE_APPLICATION_FORM_URL = gql(`
  query GetOrCreateApplicationFormUrl {
    getOrCreateApplicationFormUrl {
      url
    }
  }
`);

export const ApplicationForm = () => {
  const { signOut } = useCustomAuth();
  const { loading, error, data } = useQuery(GET_OR_CREATE_APPLICATION_FORM_URL);

  const signOutAndRemoveAccess = () => {
    signOut();
  };

  if (loading)
    return (
      <div className="w-full flex justify-center mt-32">
        <GenericLoader />
      </div>
    );
  if (error) return <p>Error &#x1F614;</p>;

  const applicationFormUrl = data?.getOrCreateApplicationFormUrl?.url || '';
  return (
    <>
      <Link
        to="/dashboard"
        className="absolute top-12 right-44 mobile:top-0 mobile:right-8 mobile:h-9 mobile:pt-2"
      >
        <RoundButton variant="secondary" text="Back to dashboard" />
      </Link>
      <UnderlineButton
        className={`absolute top-9 right-16 mobile:hidden`}
        onClick={signOutAndRemoveAccess}
        text="Logout"
      />
      <iframe className="w-screen h-screen" src={applicationFormUrl} title="ZenaApplication" />
    </>
  );
};

import { RoundButton } from 'components/atoms/Buttons';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'utils';
import { ZenaCardSilouette } from 'components/atoms/ZenaCard/ZenaCardSilhouette';
import { useApplication } from 'hooks/useApplication';
import { ConnectRepaymentAccount } from 'components/widgets/marketing/ConnectRepaymentAccount';
import { LoadingWidget } from '../widgets/LoadingWidget';
import { isAdmin } from 'hooks/access';
import { usePlaidConnectionComplete } from '../data';
import { useActiveCompany } from 'providers/ActiveCompany';

export const UpsellZena = ({ className = '' }: { className?: string }) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { loading: loadingPlaidConnection, plaidConnectionStatus } = usePlaidConnectionComplete({
    companySlug: activeCompanySlug,
  });
  const { loading: loadingApplication, application } = useApplication();
  const applicationDenied = application?.status
    ? ['Denied', 'Canceled'].includes(application.status)
    : false;
  const admin = isAdmin(activeCompanySlug);

  if (loadingApplication || loadingPlaidConnection) return <LoadingWidget />;

  if (applicationDenied && plaidConnectionStatus?.connected) return <></>;

  return (
    <section
      className={cn(
        'bg-accent-200 rounded-3xl px-8 py-12 flex flex-col justify-center items-center gap-4',
        className
      )}
    >
      <h2 className="text-black text-center text-4xl">Zena organizes all your spend.</h2>
      <h3 className="text-black text-center text-3xl">Start today.</h3>

      <div className="flex flex-wrap xl:flex-nowrap gap-16 xl:gap-0 w-full">
        {!applicationDenied && (
          <ContentContainer
            className={cn('xl:border-r border-solid', 'border-primary-400 flex-auto')}
          >
            <ZenaCardSilouette
              showDetails={false}
              last4Digits="0000"
              firstName="My"
              lastName="Name"
              cardId="marketing-dashboard"
            />
            <Paragraph>
              Get the Zena purchasing card and never reconcile another transaction!
            </Paragraph>
            <div className="flex flex-col gap-2">
              <Link to="/what-to-expect">
                <RoundButton disabled={!admin} variant="primary">
                  Apply for the Zena card
                </RoundButton>
              </Link>
              {!admin && (
                <p className="text-sm text-gray-500">* Must be completed by a company admin</p>
              )}
            </div>
          </ContentContainer>
        )}
        <ConnectRepaymentAccount hasConnectedAccounts={!!plaidConnectionStatus?.connected} />
      </div>
    </section>
  );
};

const ContentContainer = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={cn('grid gap-6 px-4 content-end justify-items-center', className)}>
    {children}
  </div>
);

const Paragraph = ({ children }: { children: ReactNode }) => (
  <div className="text-black text-center">{children}</div>
);

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY_FOR_QBO_CATEGORIES = gql(`
  query GetQboCategoriesForSelectAccountingTag($companySlug: String!) {
    listQBOExpenseAccounts(companySlug: $companySlug) {
      id: accountId
      accountName
    }
  }
`);

export const useQboCategories = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY_FOR_QBO_CATEGORIES, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const qboCategories = data?.listQBOExpenseAccounts ?? [];

  return { qboCategories, loading, error };
};

import { Breadcrumbs } from 'components/atoms/Breadcrumbs';
import { Pill } from 'components/atoms/Pill';
import { SettingsCard } from '../../../../(project)/ProjectSettings/Layout';
import { ComboboxOption, MultiPillCombobox } from 'components/atoms/Comboboxes';
import {
  SpendCategoryTag,
  useGetAccountingCategoryProjectCategoryTags,
  useGetAllProjectSpendCategories,
  useListQBOExpenseAccounts,
  useUpdateAccountingTagPairing,
} from './data';
import { useActiveCompany } from '../../../../../providers/ActiveCompany';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { RectangleSkeleton } from 'components/atoms/Skeletons/Shapes';
import { cn } from '../../../../../utils';

const breadcrumbs = [
  { name: 'Integrations', url: '/settings/integrations' },
  { name: 'Quickbooks Online', url: '/settings/integrations/qbo' },
  { name: 'Automation set up', url: '/settings/integrations/qbo/configure' },
];

export const ConfigureAutomation = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { tags, loading: catLoading } = useGetAllProjectSpendCategories(activeCompanySlug);
  const [status, setStatus] = useState<'saving' | 'saved' | null>(null);

  const [availableSpendCategories, setAvailableSpendCategories] = useState<SpendCategoryTag[]>(
    tags || []
  );
  useEffect(() => {
    setAvailableSpendCategories(tags);
  }, [tags.length]);
  // pull pairings from backend
  const { data: listData, loading: listLoading } = useListQBOExpenseAccounts(activeCompanySlug);

  const listenForNetworkRequest = (fetching: 'saving' | 'saved' | null) => {
    setStatus(fetching);
  };
  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div>
        <h3>Automatically set accounting category</h3>
        <p>
          When you tag a project transaction with a spend category, Zena can automatically add the
          right Accounting Category for you. Example:
        </p>
        <div className="py-6 space-y-3">
          <span className="flex items-center">
            When you tag a project transaction with{' '}
            <Pill text="Paint" variant="accent" className="font-normal mx-2" /> or{' '}
            <Pill text="Fixtures" variant="accent" className="font-normal mx-2" />
          </span>
          <span className="flex items-center">
            Zena will add the accounting category automatically:{' '}
            <Pill text="COGS" variant="forest" className="font-normal mx-2" />
          </span>
        </div>
        <SettingsCard editing className="mt-12">
          <div className="flex">
            <h3>Map project category tags to accounting categories</h3>
            <div className="ml-auto">
              {status && (
                <p
                  className={cn(
                    'ml-auto text-gray-400 opacity-80 transition ease-in-out',
                    status === 'saved' && 'opacity-0 duration-500 delay-1000'
                  )}
                >
                  {status === 'saving' ? 'Saving...' : 'Saved'}
                </p>
              )}
            </div>
          </div>
          <hr className="mt-3 pb-4" />
          <div className="mt-4">
            <div className="grid grid-cols-5 gap-32 items-center font-medium">
              <div className="col-span-3">
                <p className="text-base">Project category tags</p>
              </div>
              <div className="col-span-2">
                <p className="text-base">Accounting categories</p>
              </div>
            </div>
            <ul className="*:pt-6 *:pb-6 divide-y">
              {listLoading || catLoading ? (
                <LoadingAccountingTagPairings />
              ) : listData ? (
                listData.map((qboExpenses, i) => {
                  return (
                    <li key={i}>
                      <AccountingTagPairing
                        selectedExpenseCategory={qboExpenses.accountName}
                        expenseId={qboExpenses.accountId}
                        changeAvailableSpendCategories={setAvailableSpendCategories}
                        availableSpendCategories={availableSpendCategories.map((cat) => ({
                          label: cat.name,
                          value: cat.name,
                        }))}
                        networkRequestHook={listenForNetworkRequest}
                      />
                    </li>
                  );
                })
              ) : (
                <div>No QBO information</div>
              )}
            </ul>
          </div>
        </SettingsCard>
      </div>
    </>
  );
};

interface AccountingTagPair {
  availableSpendCategories: ComboboxOption[];
  selectedExpenseCategory: string;
  expenseId: string;
  changeAvailableSpendCategories: Dispatch<SetStateAction<SpendCategoryTag[]>>;
  networkRequestHook?: (status: 'saving' | 'saved' | null) => void;
}

const AccountingTagPairing = ({
  selectedExpenseCategory,
  availableSpendCategories,
  expenseId,
  changeAvailableSpendCategories,
  networkRequestHook,
}: AccountingTagPair) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { pairedTags, loading } = useGetAccountingCategoryProjectCategoryTags({
    accountingCategoryId: expenseId,
  });

  useEffect(() => {
    const newAvailableSpendCategories = availableSpendCategories.filter(
      (category) => !pairedTags.find((v) => v.label === category.label)
    );
    changeAvailableSpendCategories(newAvailableSpendCategories.map((cat) => ({ name: cat.label })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairedTags?.length]);

  const {
    create,
    remove,
    loading: sendingRequest,
  } = useUpdateAccountingTagPairing({ accountingCategoryId: expenseId });
  const status: 'saving' | 'saved' | null = useMemo(() => {
    if (sendingRequest) {
      return 'saving';
    } else if (!sendingRequest) {
      return 'saved';
    }
    return null;
  }, [sendingRequest]);

  const onChange = async (options: ComboboxOption[]) => {
    // changeAvailableSpendCategories(options);
    const lastElement = options.slice(-1)[0];
    await create({
      variables: {
        companySlug: activeCompanySlug,
        accountingCategoryId: expenseId,
        accountingCategoryName: selectedExpenseCategory,
        projectCategoryTagName: lastElement?.label || '',
      },
    });
  };

  const onRemove = async (option: ComboboxOption) => {
    const existingPair = pairedTags.find(
      (pair) => pair.value === option.value || pair.label === option.value
    );
    if (existingPair) {
      await remove({
        variables: {
          accountingCategoryProjectCategoryTagId: existingPair.value,
        },
      });
    }
    // add option back to available spend categories
    changeAvailableSpendCategories((current) => [...current, { name: option.label }]);
  };

  useEffect(() => {
    networkRequestHook && networkRequestHook(status);
  }, [status]);

  return (
    <div className="grid grid-cols-7 gap-16 items-center">
      <div className="col-span-4">
        {loading ? (
          <div className="flex gap-6">
            <RectangleSkeleton height="medium" width="small" />
            <RectangleSkeleton height="medium" width="small" />
            <RectangleSkeleton height="medium" width="small" />
          </div>
        ) : (
          <MultiPillCombobox
            initialValue={pairedTags}
            options={availableSpendCategories}
            onChange={onChange}
            onRemove={onRemove}
            allowCreate={false}
          />
        )}
      </div>
      <div className="col-span-3">
        <Pill text={selectedExpenseCategory || ''} variant="forest" className="font-normal" />
      </div>
    </div>
  );
};

const LoadingAccountingTagPairings = () => {
  return (
    <>
      {[...Array(5)].map((_, i) => (
        <li key={i}>
          <div className="grid grid-cols-5 gap-32 items-center">
            <div className="col-span-3">
              <div className="flex gap-6">
                <RectangleSkeleton height="medium" width="small" />
                <RectangleSkeleton height="medium" width="small" />
                <RectangleSkeleton height="medium" width="small" />
              </div>
            </div>
            <div className="col-span-2">
              <RectangleSkeleton width="medium" height="medium" />
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

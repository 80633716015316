import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { useReward } from 'react-rewards';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import {
  AllocateTransactionsState,
  useAllocateTransactionsContext,
  useImportTransactionsUrlQueryPersistence,
} from './state';
import { useDebounce } from 'hooks/use-debounce';
import {
  ALLOCATE_BULK_TRANSACTIONS_TO_PROJECT_MUTATION,
  ALLOCATE_ONE_TRANSACTION_TO_PROJECT_MUTATION,
  DISMISS_BULK_TRANSACTIONS_MUTATION,
  DISMISS_ONE_TRANSACTION_MUTATION,
  GET_PROJECT_TRANSACTIONS_REFETCH_QUERY,
  REFETCH_COUNT_TRANSACTIONS_QUERY,
  TRANSACTIONS_QUERY,
  useGetTransactionFeedAccounts,
  useTransactionsToAllocate,
} from './data';
import { GET_PROJECT_FINACIALS } from 'pages/(project)/ProjectOverview/ProjectFinancials/data';
import {
  AllocateTransactionsTableRowHeaderRow,
  AnimatedAllocateTransactionsTableRow,
} from './AllocateTransactionTableRow';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { DISMISSED_TRANSACTION_CATEGORY_ID } from 'components/widgets/AllocateTransactions/AllocateTransactionTableRow/AllocateTransactionCategoryDropdown';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { Alert } from 'components/atoms/Alert';
import { Link } from 'react-router-dom';
import { formatGraphqlDate } from 'utils/date';
import { AllocateTransactionsFilters } from './Filters';
import { cn } from 'utils';
import { useActiveCompany } from 'providers/ActiveCompany';
import {
  ConfirmCloseTransactionDetailsDrawer,
  TransactionDetailsDrawerContent,
} from '../../widgets/TransactionDetailsDrawer';
import { useTransactionDetailsContext } from '../../widgets/TransactionDetailsDrawer/state';
import { Pagination } from 'components/atoms/Pagination';
import { GET_TRANSACTIONS_REQUIRE_ATTENTION_NOTIFICATION } from '../TransactionsRequireAttentionNotification/data';

export const AllocateTransactions = ({
  onCompleteAllocationOfTransactions,
  openAddAccountModal,
  disableUrlQuerySerialization = false,
  disableTransactionDetailsDrawer = false,
}: {
  onCompleteAllocationOfTransactions?: () => void;
  openAddAccountModal?: () => void;
  disableUrlQuerySerialization?: boolean;
  disableTransactionDetailsDrawer?: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { state, dispatch } = useAllocateTransactionsContext();
  const debouncedState: AllocateTransactionsState = useDebounce(state, 250);
  const { state: transactionDetailsState } = useTransactionDetailsContext();

  useImportTransactionsUrlQueryPersistence({
    searchState: {
      pageLimit: state.pagination.pageLimit,
      pageOffset: state.pagination.pageOffset,
      searchTerm: state.searchFilters.searchTerm,
      systemSuggestion: state.searchFilters.systemSuggestion,
      lastDays: state.searchFilters.lastDays,
      isViewingTransactionDetailsTransactionId: state.isViewingTransactionDetailsTransactionId,
    },
    dispatch,
    disabled: disableUrlQuerySerialization,
  });
  const today = new Date();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { reward } = useReward('bulk-allocation-of-transactions', 'confetti', {
    decay: 0.9,
    angle: 90,
    spread: 100,
    elementCount: 250,
    lifetime: 1000,
  });

  const fromDate =
    debouncedState.searchFilters.lastDays === 'ALL'
      ? undefined
      : new Date(today.setDate(today.getDate() - debouncedState.searchFilters.lastDays));
  const {
    transactions,
    loading: transactionsLoading,
    error: transactionsError,
    totalTransactions,
    refetch,
  } = useTransactionsToAllocate({
    companySlug: activeCompanySlug,
    pageLimit: debouncedState.pagination.pageLimit,
    pageOffset: debouncedState.pagination.pageOffset,
    searchTerm: debouncedState.searchFilters.searchTerm || undefined,
    fromDate,
    zenaSuggestedAssignment: debouncedState.searchFilters.systemSuggestion,
    onCompleted: (data) => {
      if (data?.getUnmappedTransactionFeedTransactions?.meta?.total) {
        dispatch({
          type: 'UPDATE_PAGINATION',
          payload: {
            ...state.pagination,
            totalPages: Math.ceil(totalTransactions / state.pagination.pageLimit),
          },
        });
      }
    },
  });

  const {
    accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useGetTransactionFeedAccounts({ companySlug: activeCompanySlug });

  const isSelectAllTransactionsSelected =
    state.selectedTransactionIds.length > 0 &&
    state.selectedTransactionIds.length === transactions.length;

  // mutations
  const [allocateOneTransactionToProject, { loading: allocateOneTransactionLoading }] = useMutation(
    ALLOCATE_ONE_TRANSACTION_TO_PROJECT_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: ['GetTransactionsRequireAttentionNotification'],
    }
  );
  const [allocateBulkTransactionsToProject, { loading: allocateBulkTransactionsLoading }] =
    useMutation(ALLOCATE_BULK_TRANSACTIONS_TO_PROJECT_MUTATION, {
      awaitRefetchQueries: true,
    });
  const [dismissOneTransaction, { loading: dismissOneTransactionLoading }] = useMutation(
    DISMISS_ONE_TRANSACTION_MUTATION,
    {
      awaitRefetchQueries: true,
    }
  );
  const [dismissBulkTransactions, { loading: dismissBulkTransactionsLoading }] = useMutation(
    DISMISS_BULK_TRANSACTIONS_MUTATION,
    {
      awaitRefetchQueries: true,
    }
  );

  const mutationsLoading =
    allocateOneTransactionLoading ||
    allocateBulkTransactionsLoading ||
    dismissOneTransactionLoading ||
    dismissBulkTransactionsLoading;

  const confirmSelectedTransactionForBulkAllocation = async () => {
    interface CategorizedBulkTransactions {
      projects: Record<string, string[]>;
      dismissedTransactions: string[];
    }

    const categorizedTransactions = state.selectedTransactionIds.reduce(
      (categories: CategorizedBulkTransactions, transactionId) => {
        const assignedTransaction = state.assignedCategories[transactionId];
        if (assignedTransaction.type === 'PROJECT') {
          const projectId = state.assignedCategories[transactionId].id;

          if (!categories.projects[projectId]) {
            categories.projects[projectId] = [];
          }

          const projectList = categories.projects[projectId];
          projectList.push(transactionId);
        }

        if (assignedTransaction.type === DISMISSED_TRANSACTION_CATEGORY_ID) {
          categories.dismissedTransactions.push(transactionId);
        }

        return categories;
      },
      {
        projects: {},
        dismissedTransactions: [],
      }
    );

    await Promise.all([
      ...Object.keys(categorizedTransactions.projects).map((projectId) => {
        return allocateBulkTransactionsToProject({
          variables: {
            companySlug: activeCompanySlug,
            projectId,
            transactionIds: categorizedTransactions.projects[projectId],
          },
          refetchQueries: [
            {
              query: TRANSACTIONS_QUERY,
              variables: {
                companySlug: activeCompanySlug,
                pageLimit: state.pagination.pageLimit,
                pageOffset: state.pagination.pageOffset,
                searchTerm: debouncedState.searchFilters.searchTerm || undefined,
                fromDate: fromDate ? formatGraphqlDate(fromDate) : undefined,
              },
            },
            {
              query: GET_PROJECT_TRANSACTIONS_REFETCH_QUERY,
              variables: {
                companySlug: activeCompanySlug,
                filters: {
                  zena: {
                    projectId,
                  },
                },
              },
            },
            {
              query: REFETCH_COUNT_TRANSACTIONS_QUERY,
              variables: {
                companySlug: activeCompanySlug,
              },
            },
            {
              query: GET_PROJECT_FINACIALS,
              variables: {
                companySlug: activeCompanySlug,
                projectId: projectId,
                timezone,
              },
            },
            {
              query: GET_TRANSACTIONS_REQUIRE_ATTENTION_NOTIFICATION,
              variables: {
                customerId: activeCompanySlug,
                pageNumber: 0,
                pageSize: 30,
              },
            },
          ],
          onError: () => {
            dispatch({
              type: 'UPDATE_ALERT_ERROR_MESSAGE',
              payload: 'Failed to allocate transactions. Please try again.',
            });
          },
        });
      }),
      ...(categorizedTransactions.dismissedTransactions.length > 0
        ? [
            dismissBulkTransactions({
              variables: {
                companySlug: activeCompanySlug,
                transactionIds: categorizedTransactions.dismissedTransactions,
              },
              refetchQueries: [
                {
                  query: TRANSACTIONS_QUERY,
                  variables: {
                    companySlug: activeCompanySlug,
                    pageLimit: state.pagination.pageLimit,
                    pageOffset: state.pagination.pageOffset,
                    searchTerm: debouncedState.searchFilters.searchTerm || undefined,
                    fromDate: fromDate ? formatGraphqlDate(fromDate) : undefined,
                  },
                },
                {
                  query: REFETCH_COUNT_TRANSACTIONS_QUERY,
                  variables: {
                    companySlug: activeCompanySlug,
                  },
                },
              ],
              onError: () => {
                dispatch({
                  type: 'UPDATE_ALERT_ERROR_MESSAGE',
                  payload: 'Failed to allocate transactions. Please try again.',
                });
              },
            }),
          ]
        : []),
    ]);

    dispatch({
      type: 'CONFIRMED_SELECTED_TRANSACTIONS',
    });

    onCompleteAllocationOfTransactions?.();

    reward();
  };

  const loading = transactionsLoading || accountsLoading;
  const error = transactionsError || accountsError;

  return (
    <>
      <div
        className={cn(
          'flex flex-col h-full overflow-hidden pb-4 pt-8',
          'lg:h-auto lg:overflow-visible'
        )}
      >
        <AllocateTransactionsFilters
          companySlug={activeCompanySlug}
          mutationsLoading={mutationsLoading}
          onConfirmSelectedTransactionForBulkAllocation={
            confirmSelectedTransactionForBulkAllocation
          }
        />

        <AllocateTransactionsTableRowHeaderRow
          checked={isSelectAllTransactionsSelected}
          onClickSelectAll={() => {
            dispatch({
              type: 'UPDATE_SELECTED_TRANSACTION_IDS',
              payload: isSelectAllTransactionsSelected
                ? []
                : transactions.map((transaction) => transaction.id),
            });
          }}
          className="lg:sticky lg:top-0 bg-white z-40"
        />

        <div className={cn('pb-8', 'flex-grow overflow-scroll', 'lg:overflow-visible')}>
          {loading && (
            <div className="grid gap-1 mt-1">
              <RectangleSkeleton className="h-[5em]" rounded={false} />
              <RectangleSkeleton className="h-[5em]" rounded={false} />
              <RectangleSkeleton className="h-[5em]" rounded={false} />
            </div>
          )}

          {!loading && !accountsLoading && !error && (
            <EmptyListContent
              transactionsCount={transactions.length}
              accountsCount={accounts.length}
              openAddAccountModal={openAddAccountModal}
              hasAppliedFiltersOrSearch={
                !!debouncedState.searchFilters.searchTerm ||
                !!debouncedState.searchFilters.systemSuggestion
              }
            />
          )}

          {error && transactions.length === 0 && (
            <div className="flex justify-center items-center mt-16">
              <p className="text-base text-danger">
                Something wen't wrong fetching transactions. Please refresh and try again.
              </p>
            </div>
          )}

          {transactions.map((transaction) => {
            const isSelected = !!state.selectedTransactionIds.find(
              (selectedTransactionId) => transaction.id === selectedTransactionId
            );
            const assignedCategory = state.assignedCategories[transaction.id];

            return (
              <motion.div key={transaction.id} layout>
                <AnimatedAllocateTransactionsTableRow
                  key={transaction.id}
                  source={transaction.bank?.name ?? ''}
                  isSelected={isSelectAllTransactionsSelected || isSelected}
                  date={new Date(transaction.date)}
                  merchantName={transaction.name}
                  spendCategory={transaction.spendCategory}
                  amountInCents={transaction.amountWithDirection}
                  onConfirm={async () => {
                    if (assignedCategory) {
                      if (assignedCategory.type === 'PROJECT') {
                        await allocateOneTransactionToProject({
                          variables: {
                            projectId: assignedCategory.id,
                            transactionId: transaction.id,
                            companySlug: activeCompanySlug,
                          },
                          refetchQueries: [
                            {
                              query: TRANSACTIONS_QUERY,
                              variables: {
                                companySlug: activeCompanySlug,
                                pageLimit: state.pagination.pageLimit,
                                pageOffset: state.pagination.pageOffset,
                                searchTerm: debouncedState.searchFilters.searchTerm || undefined,
                                fromDate: fromDate ? formatGraphqlDate(fromDate) : undefined,
                              },
                            },
                            {
                              query: GET_PROJECT_TRANSACTIONS_REFETCH_QUERY,
                              variables: {
                                companySlug: activeCompanySlug,
                                filters: {
                                  zena: {
                                    projectId: assignedCategory.id,
                                  },
                                },
                              },
                            },
                            {
                              query: GET_TRANSACTIONS_REQUIRE_ATTENTION_NOTIFICATION,
                              variables: {
                                customerId: activeCompanySlug,
                                pageNumber: 0,
                                pageSize: 30,
                              },
                            },
                            {
                              query: REFETCH_COUNT_TRANSACTIONS_QUERY,
                              variables: {
                                companySlug: activeCompanySlug,
                              },
                            },
                            {
                              query: GET_PROJECT_FINACIALS,
                              variables: {
                                companySlug: activeCompanySlug,
                                projectId: assignedCategory.id,
                                timezone,
                              },
                            },
                          ],
                          onCompleted: () => {
                            dispatch({
                              type: 'CONFIRMED_TRANSACTION',
                              payload: transaction.id,
                            });
                            onCompleteAllocationOfTransactions?.();
                          },
                          onError: () => {
                            dispatch({
                              type: 'UPDATE_ALERT_ERROR_MESSAGE',
                              payload:
                                'Failed to allocate transaction to project. Please try again.',
                            });
                          },
                        });
                      }

                      if (assignedCategory.type === 'NA') {
                        await dismissOneTransaction({
                          variables: {
                            companySlug: activeCompanySlug,
                            transactionId: transaction.id,
                          },
                          refetchQueries: [
                            {
                              query: TRANSACTIONS_QUERY,
                              variables: {
                                companySlug: activeCompanySlug,
                                pageLimit: state.pagination.pageLimit,
                                pageOffset: state.pagination.pageOffset,
                                searchTerm: debouncedState.searchFilters.searchTerm || undefined,
                                fromDate: fromDate ? formatGraphqlDate(fromDate) : undefined,
                              },
                            },
                            {
                              query: REFETCH_COUNT_TRANSACTIONS_QUERY,
                              variables: {
                                companySlug: activeCompanySlug,
                              },
                            },
                          ],
                          onCompleted: () => {
                            dispatch({
                              type: 'CONFIRMED_TRANSACTION',
                              payload: transaction.id,
                            });
                            onCompleteAllocationOfTransactions?.();
                          },
                          onError: (err) => {
                            dispatch({
                              type: 'UPDATE_ALERT_ERROR_MESSAGE',
                              payload: 'Failed to dismiss transaction. Please try again.',
                            });
                          },
                        });
                      }
                    }
                  }}
                  onConfirmDisabled={!assignedCategory || mutationsLoading}
                  selectedProjectId={assignedCategory ? assignedCategory.id : undefined}
                  onClickTransaction={() => {
                    if (transactionDetailsState.hasChangesToSave) {
                      dispatch({
                        type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS',
                        nextTransactionId: transaction.id,
                      });
                    } else if (transaction.id !== state.isViewingTransactionDetailsTransactionId) {
                      dispatch({
                        type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID',
                        payload: transaction.id,
                      });
                    } else {
                      dispatch({
                        type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID',
                        payload: undefined,
                      });
                    }
                  }}
                  onSelect={() => {
                    dispatch({
                      type: 'UPDATE_SELECTED_TRANSACTION_IDS',
                      payload: isSelected
                        ? state.selectedTransactionIds.filter(
                            (selectedTransactionId) => selectedTransactionId !== transaction.id
                          )
                        : [...state.selectedTransactionIds, transaction.id],
                    });
                  }}
                  onSelectNoCategory={() => {
                    dispatch({
                      type: 'UPDATE_ASSIGNED_CATEGORIES',
                      payload: {
                        ...state.assignedCategories,
                        [transaction.id]: {
                          type: DISMISSED_TRANSACTION_CATEGORY_ID,
                          id: DISMISSED_TRANSACTION_CATEGORY_ID,
                        },
                      },
                    });
                  }}
                  onSelectProject={(projectId) => {
                    dispatch({
                      type: 'UPDATE_ASSIGNED_CATEGORIES',
                      payload: {
                        ...state.assignedCategories,
                        [transaction.id]: {
                          type: 'PROJECT',
                          id: projectId,
                        },
                      },
                    });
                  }}
                />
              </motion.div>
            );
          })}
          <div className="flex my-12">
            <div className="ml-auto">
              <Pagination
                pageLimit={state.pagination.pageLimit}
                onChangePageLimit={(newPageLimit) => {
                  dispatch({
                    type: 'UPDATE_PAGINATION',
                    payload: { ...state.pagination, pageLimit: newPageLimit, pageOffset: 0 },
                  });
                }}
                totalPages={state.pagination.totalPages}
                currentPage={
                  Math.floor(state.pagination.pageOffset / state.pagination.pageLimit) + 1
                }
                onPreviousPage={() => {
                  dispatch({
                    type: 'UPDATE_PAGINATION',
                    payload: {
                      ...state.pagination,
                      pageOffset: state.pagination.pageOffset - state.pagination.pageLimit,
                    },
                  });
                }}
                onNextPage={() => {
                  dispatch({
                    type: 'UPDATE_PAGINATION',
                    payload: {
                      ...state.pagination,
                      pageOffset: state.pagination.pageOffset + state.pagination.pageLimit,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {state.alertErrorMessage && (
        <ToastContainer>
          <Alert
            title="An error occurred"
            variation="error"
            description={state.alertErrorMessage}
            onDismiss={() =>
              dispatch({
                type: 'UPDATE_ALERT_ERROR_MESSAGE',
                payload: undefined,
              })
            }
          />
        </ToastContainer>
      )}

      {!disableTransactionDetailsDrawer &&
        !!state.isViewingTransactionDetailsTransactionId &&
        transactions &&
        transactions.length > 0 &&
        !!transactions.find((t) => t.id === state.isViewingTransactionDetailsTransactionId) && (
          <TransactionDetailsDrawerContent
            transactionId={state.isViewingTransactionDetailsTransactionId ?? ''}
            isVisible={!!state.isViewingTransactionDetailsTransactionId}
            showMissingRequirements={false}
            onClose={() => {
              if (transactionDetailsState.hasChangesToSave) {
                dispatch({
                  type: 'SHOW_CONFIRM_LOSE_TRANSACTION_DETAILS',
                });
              } else {
                dispatch({
                  type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID',
                  payload: undefined,
                });
              }
            }}
            onUpdateTransaction={() => {
              if (state.isViewingTransactionDetailsTransactionId) {
                dispatch({
                  type: 'CONFIRMED_TRANSACTION',
                  payload: state.isViewingTransactionDetailsTransactionId,
                });
              }
              refetch();
            }}
          />
        )}

      {state.showConfirmLoseTransactionDetails && (
        <ConfirmCloseTransactionDetailsDrawer
          onCancel={() =>
            dispatch({
              type: 'CANCEL_LOSE_TRANSACTION_DETAILS',
            })
          }
          onConfirmDangerously={() => {
            if (
              state.confirmLoseTransactionDetailsNextTransactionId &&
              state.confirmLoseTransactionDetailsNextTransactionId !==
                state.isViewingTransactionDetailsTransactionId
            ) {
              dispatch({
                type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID',
                payload: state.confirmLoseTransactionDetailsNextTransactionId,
              });
            } else {
              dispatch({
                type: 'UPDATE_IS_VIEWING_TRANSACTION_DETAILS_TRANSACTION_ID',
                payload: undefined,
              });
            }
          }}
        />
      )}
    </>
  );
};

const EmptyListContent = ({
  transactionsCount,
  accountsCount,
  openAddAccountModal,
  hasAppliedFiltersOrSearch = false,
}: {
  transactionsCount: number;
  accountsCount: number;
  openAddAccountModal?: () => void;
  hasAppliedFiltersOrSearch?: boolean;
}) => {
  if (transactionsCount === 0 && accountsCount === 0) {
    return (
      <NoResultsContainer>
        <NoResultsTitle>Missing transactions?</NoResultsTitle>
        {openAddAccountModal && (
          <RoundButton className="w-fit" onClick={() => openAddAccountModal?.()}>
            Link accounts
          </RoundButton>
        )}
        {!openAddAccountModal && (
          <div className="flex flex-col items-center gap-4">
            <p className="text-sm text-center text-muted">
              Not seeing transactions that should be here?
            </p>
            <Link to="/settings/bank-accounts">
              <UnderlineButton className="text-muted border-muted text-sm py-0">
                Update your linked accounts
              </UnderlineButton>
            </Link>
          </div>
        )}
      </NoResultsContainer>
    );
  }

  if (transactionsCount === 0 && accountsCount > 0 && !hasAppliedFiltersOrSearch) {
    return (
      <NoResultsContainer>
        <NoResultsTitle>You're all caught up!</NoResultsTitle>
        <div className="flex flex-col items-center gap-4">
          <p className="text-sm text-center text-muted">
            Not seeing transactions that should be here?
          </p>
          {openAddAccountModal && (
            <UnderlineButton
              onClick={() => openAddAccountModal?.()}
              className="text-muted border-muted text-sm py-0"
            >
              Update your linked accounts
            </UnderlineButton>
          )}
          {!openAddAccountModal && (
            <Link to="/settings/bank-accounts">
              <UnderlineButton className="text-muted border-muted text-sm py-0">
                Update your linked accounts
              </UnderlineButton>
            </Link>
          )}
        </div>
      </NoResultsContainer>
    );
  }

  if (hasAppliedFiltersOrSearch && transactionsCount === 0 && accountsCount > 0) {
    return (
      <NoResultsContainer>
        <NoResultsTitle>No results</NoResultsTitle>
      </NoResultsContainer>
    );
  }

  return null;
};

const NoResultsContainer = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col gap-10 items-center mt-20">{children}</div>;
};

const NoResultsTitle = ({ children }: { children: ReactNode }) => {
  return <h2 className="text-3xl text-center font-normal">{children}</h2>;
};

import { cn } from 'utils';
import { HTMLAttributes } from 'react';
import { X } from 'lucide-react';

const pillColors = {
  success: 'bg-success text-black',
  pending: 'bg-secondary-400 text-black',
  selected: 'bg-primary text-white',
  cancelable: 'bg-accent-300 text-black',
  reject: 'bg-rustic bg-opacity-[16%] text-rustic',
  accent: 'bg-accent-300',
  forest: 'bg-forest-400',
};

export type Color = keyof typeof pillColors;

type PillProps = {
  text: string;
  className?: string;
  variant?: Color;
  onClickCancel?: Function;
} & HTMLAttributes<HTMLDivElement>;

const Pill = ({ text, className, variant = 'success', onClickCancel, ...props }: PillProps) => {
  const baseStyle = 'rounded-5xl w-fit px-4 py-1 whitespace-nowrap';
  const isCanceable = onClickCancel && variant !== 'cancelable';
  const handleCancel = () => {
    onClickCancel && onClickCancel(text);
  };

  const pillStyle = cn(baseStyle, pillColors[variant], className);
  return (
    <div className={pillStyle} {...props}>
      <div className={cn('flex')}>
        <span>{text}</span>
        {variant === 'cancelable' && (
          <div>
            <button className="ml-2 h-2.5 w-2.5" type="button" onClick={handleCancel}>
              <X />
            </button>
          </div>
        )}
        {isCanceable && (
          <div>
            <button className="ml-2 h-2.5 w-2.5" type="button" onClick={handleCancel}>
              <X />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pill;

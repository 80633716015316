import { useActiveCompany } from 'providers/ActiveCompany';
import { useQboCategories } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { TransactionTagSelection } from '../TransactionTagSelection';

export const SelectAccountingTag = ({
  selectedAccountingTagName,
  onChangeAccountingTag,
  className,
  isMissing = false,
}: {
  selectedAccountingTagName: string;
  onChangeAccountingTag: (
    data: { accountingTagId: string; accountingTagName: string } | null
  ) => void;
  className?: string;
  isMissing?: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { qboCategories, loading } = useQboCategories({ companySlug: activeCompanySlug });

  if (loading) {
    return <RectangleSkeleton width="full" height="large" />;
  }

  return (
    <TransactionTagSelection
      onSelectTag={(tag) => {
        const selectedTag = qboCategories.find((category) => category.accountName === tag);
        if (selectedTag) {
          onChangeAccountingTag({
            accountingTagId: selectedTag.id,
            accountingTagName: selectedTag.accountName,
          });
        }
      }}
      tags={qboCategories.map((category) => category.accountName)}
      selectedTags={selectedAccountingTagName ? [selectedAccountingTagName] : []}
      onRemoveTag={() => onChangeAccountingTag(null)}
      tagColor="bg-forest-400"
      placeholder="Select an accounting tag"
      className={className}
      isMissing={isMissing}
    />
  );
};

import { SpendBreakdownChart } from '../DashboardCharts/SpendBreakdownChart';

export const SpendBreakdownMarketingGraph = () => {
  return (
    <SpendBreakdownChart
      totalSpending={5_207}
      days={120}
      onChangeDays={() => {}}
      isLoading={false}
      disabled
      data={[
        {
          id: '0c9fc6f8-49ed-416c-b72d-226d27d48dc9',
          name: 'Project 1',
          amount: 3_357,
        },
        {
          id: '4b59b812-292c-46c9-b395-2655928ebe04',
          name: 'Project 2',
          amount: 850,
        },
        {
          id: 'e55ada31-ecd8-41ad-bb64-8941fdd008b2',
          name: 'Project 3',
          amount: 1_000,
        },
      ]}
    />
  );
};

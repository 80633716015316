import { ZenaSuggestedAssignment } from '__generated__/graphql';
import { UnderlineButton } from 'components/atoms/Buttons';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'utils';
import { Maybe } from 'utils/types';

export const FilterBySystemSuggestionsWithPrompts = ({
  systemSuggestion,
  onChange,
}: {
  systemSuggestion: Maybe<ZenaSuggestedAssignment>;
  onChange: (value: Maybe<ZenaSuggestedAssignment>) => void;
}) => {
  return (
    <div className="grid gap-4 py-8 px-10 rounded-3xl border-primary-400 border max-w-[1100px]">
      <CardTitle>Filter by system suggestions</CardTitle>
      <div className="flex flex-col gap-6 xl:grid xl:grid-cols-[1fr_auto] xl:gap-40">
        <div className="pt-5 border-t border-t-primary-400 grid gap-8 xl:content-baseline">
          <p className="text-base text-black italic">Show me transactions that seem to be:</p>
          <div className="flex flex-row gap-4">
            <StyledButton
              isActive={systemSuggestion === ZenaSuggestedAssignment.project}
              onClick={() => {
                if (systemSuggestion === ZenaSuggestedAssignment.project) {
                  onChange(undefined);
                } else {
                  onChange(ZenaSuggestedAssignment.project);
                }
              }}
            >
              Project related
            </StyledButton>
            <StyledButton
              isActive={systemSuggestion === ZenaSuggestedAssignment.business}
              onClick={() => {
                if (systemSuggestion === ZenaSuggestedAssignment.business) {
                  onChange(undefined);
                } else {
                  onChange(ZenaSuggestedAssignment.business);
                }
              }}
            >
              Business related
            </StyledButton>
            <StyledButton
              isActive={systemSuggestion === ZenaSuggestedAssignment.unrelated}
              onClick={() => {
                if (systemSuggestion === ZenaSuggestedAssignment.unrelated) {
                  onChange(undefined);
                } else {
                  onChange(ZenaSuggestedAssignment.unrelated);
                }
              }}
            >
              Not applicable
            </StyledButton>
          </div>
        </div>

        <div className="grid gap-4">
          <p className="text-base text-black hidden xl:block">
            You can assign these transactions to any project in Zena. If you don't assign them, they
            won't be included anywhere.
          </p>
          <p className="text-base text-black hidden xl:block">
            Want to remove them from this view? Simply mark them as Not Applicable.{' '}
          </p>
          <Link to={'/settings/bank-accounts'}>
            <UnderlineButton className="text-muted px-0">View my linked accounts</UnderlineButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

const StyledButton = ({
  children,
  onClick,
  isActive,
  className = '',
}: {
  isActive?: boolean;
  onClick: () => void;
  children: ReactNode;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'w-max rounded-3xl px-6 py-2 outline-accent-600 flex justify-center items-center flex-row gap-2 border border-secondary text-black',
        className
      )}
      style={{
        backgroundColor: isActive ? '#E1DAD6' : '#FFF',
      }}
    >
      {children}
    </button>
  );
};

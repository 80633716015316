import { PageTitle } from 'components/atoms/PageTitle';
import { TransactionsPageLayout } from '../TransactionsLayout';
import { TransactionsTable } from 'components/widgets/transaction-tables/TransactionsTable';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useState } from 'react';
import { Notifications } from 'providers/notifications';
import { CsvExportType } from '__generated__/graphql';

export const AllTransactionsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [showDownloadDrawer, setShowDownloadDrawer] = useState(false);

  return (
    <>
      <PageTitle hidden text="Transactions" />
      <Notifications />
      <TransactionsPageLayout
        pageName="ALL_TRANSACTIONS"
        title="Transactions"
        className="mt-8"
        showDownloadButton
        onClickDownload={() => setShowDownloadDrawer(true)}
      >
        <TransactionsTable
          companySlug={activeCompanySlug}
          showDownloadDrawer={showDownloadDrawer}
          onCloseDownloadDrawer={() => setShowDownloadDrawer(false)}
          showSplitTransactionsOnDownload
          csvExportType={CsvExportType.transactions}
        />
      </TransactionsPageLayout>
    </>
  );
};

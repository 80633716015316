import { PieChart, Pie } from 'recharts';

const data = [
  { name: 'first', value: 120 },
  { name: 'second', value: 120 },
  { name: 'third', value: 120 },
  // { name: 'fourth', value: 90 },
  // { name: 'fifth', value: 72 },
];

export const Donut = ({
  segmentsCompleted,
  large,
}: {
  segmentsCompleted: number;
  large?: boolean;
}) => {
  const mappedData = data.map((item, index) => ({
    ...item,
    fill: index < segmentsCompleted ? '#697CC7' : '#EDE9E4',
  }));

  return (
    <PieChart width={large ? 65 : 50} height={large ? 65 : 50}>
      <text className="text-sm" x={large ? 30 : 25} y={large ? 36 : 30} textAnchor="middle">
        {segmentsCompleted} / 3
      </text>
      <Pie
        data={mappedData}
        dataKey="value"
        cx={large ? 26 : 20}
        cy={large ? 28 : 20}
        innerRadius={large ? 24 : 16}
        outerRadius={large ? 32 : 24}
        startAngle={90}
        endAngle={-450}
      />
    </PieChart>
  );
};

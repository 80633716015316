import { Amplify } from 'aws-amplify';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const isStaging = Boolean(window.location.hostname === 'demo.getzena.com');

export const initAmplify = () => {
  let redirectUrl = 'https://app.getzena.com/';
  if (isLocalhost) {
    redirectUrl = 'http://localhost:3000/';
  } else if (isStaging) {
    redirectUrl = 'https://demo.getzena.com/';
  }

  Amplify.configure({
    API: {
      GraphQL: {
        endpoint: process.env.REACT_APP_GRAPHQL_API_URL,
        region: process.env.REACT_APP_AWS_REGION,
        defaultAuthMode: 'userPool',
      },
    },
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,

        loginWith: {
          oauth: {
            domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
            scopes: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [redirectUrl],
            redirectSignOut: [redirectUrl],
            responseType: 'code',
          },
        },
      },
    },
    Storage: {
      S3: {
        bucket: process.env.REACT_APP_TRANSACTION_RECEIPTS_S3_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
      },
    },
  });
};

import React, { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { cn } from 'utils';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  inputState?: 'error' | 'normal' | 'success';
  className?: string;
  useFormRegister?: UseFormRegisterReturn;
  children: ReactNode;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ inputState = 'normal', className = '', useFormRegister, children, ...selectProps }, ref) => {
    return (
      <select
        {...selectProps}
        {...useFormRegister}
        ref={ref}
        className={cn(
          'w-full bg-transparent outline-accent-600 px-5 py-4 border rounded-lg border-solid text-black text-base placeholder:text-muted bg-white',
          'disabled:cursor-not-allowed disabled:bg-primary-200',
          inputState === 'normal'
            ? 'border-primary-400'
            : inputState === 'success'
              ? 'border-success'
              : 'border-danger',
          className
        )}
      >
        {children}
      </select>
    );
  }
);

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomAuth from './useCustomAuth';

export const useRedirectIfLoggedIn = () => {
  const { isLoading, user } = useCustomAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && user?.id) {
      navigate('/', { replace: true });
    }
  }, [user, navigate, isLoading]);
};

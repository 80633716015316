import { Tooltip } from 'react-tooltip';
import { ReactNode } from 'react';
import { cn } from 'utils';

export const Subtitle = ({ children }: { children: ReactNode }) => {
  return <span className="text-xl">{children}</span>;
};

export const SmallSection = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={cn('grid gap-4', className)}>{children}</div>;
};

export const ToggleRow = ({ children }: { children: ReactNode }) => {
  return (
    <div className={cn('flex flex-col', 'lg:flex-row lg:justify-between lg:items-center')}>
      {children}
    </div>
  );
};

export const Example = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-row font-medium gap-2 items-start">{children}</div>;
};

export const StyledTooltip = ({ id, children }: { id: string; children: ReactNode }) => {
  return (
    <Tooltip
      id={id}
      openOnClick
      clickable
      positionStrategy="absolute"
      opacity={1}
      className="max-w-[50vw] md:max-w-[200px] shadow-3xl"
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        color: '#121212',
      }}
    >
      {children}
    </Tooltip>
  );
};

export const CompanySettingsLink = () => {
  return (
    <a className="underline" href="/settings/settings">
      company settings
    </a>
  );
};

import { Transaction } from '__generated__/graphql';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { formatCentsToDollarsString } from 'utils';

const Preview = ({ model }: { model: Transaction }) => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-center">New purchase on your card</h1>
      <p className="text-lg text-asphalt text-center max-w-xl mt-6">
        Please add the requested details according to your organization’s expense policies.
      </p>
      <div className="bg-white w-80 sm:w-[25rem] p-8 rounded-3xl shadow-xl mt-6">
        <div>
          <p className="font-bold">Transaction</p>
          <p className="text-black text-xl mt-2">
            {model.name.substring(0, model.name.indexOf('|'))}
          </p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Amount</p>
          <p className="text-black text-xl mt-2">{formatCentsToDollarsString(model.amount)}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        <div>
          <p className="font-bold">Card</p>
          <p className="text-black text-xl mt-2">{model.card?.name}</p>
        </div>
        <div className="border-t border-secondary-400 my-3"></div>
        {model.projectName && (
          <div>
            <p className="font-bold">Project</p>
            <p className="text-black text-xl mt-2">{model.projectName}</p>
            <div className="border-t border-secondary-400 my-3"></div>
          </div>
        )}
        <div>
          <p className="font-bold">Date</p>
          <p className="text-black text-xl mt-2">
            <RelativeDate date={model.date} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Preview;

import { cn } from 'utils';
import { motion } from 'framer-motion';

export const Toggle = ({
  isActive,
  disabled,
  onToggle,
}: {
  isActive: boolean;
  disabled?: boolean;
  onToggle: (updatedValue: boolean) => void;
}) => {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        onToggle(!isActive);
      }}
      className={cn(
        'w-[44px] h-[24px] flex items-center rounded-2xl px-1',
        isActive ? 'justify-end' : 'justify-start',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        isActive && !disabled ? 'bg-forest-800' : '',
        isActive && disabled ? 'bg-forest-400' : '',
        !isActive ? 'bg-primary-400' : ''
      )}
      style={{
        transition: 'all .3s',
      }}
    >
      <motion.div layout className="h-[20px] w-[20px] rounded-full bg-white" />
    </button>
  );
};

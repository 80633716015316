import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { onApolloError } from 'utils';

export const GET_USER_CUSTOMER_ACCESS = gql(`
  query GetUserCustomerAccessForJoinTeam {
    getUserCompanyRole {
      customerSlug
      role
    }
  }
`);

export const useGetCompanyRolesToJoinTeam = () => {
  const { data, loading, error, refetch } = useQuery(GET_USER_CUSTOMER_ACCESS);

  return {
    companyRoles: data?.getUserCompanyRole ?? [],
    loading,
    error,
    refetch,
  };
};

const INVITATION_QUERY = gql(`
  query InvitationByToken($token: String!) {
    getAuthorizedUserInvitationByToken(invitationToken: $token) {
      email
      companySlug
      status
    }
  }
`);

export const useGetAuthorizedInvitation = ({
  tokenParam,
  setErrorMessage,
}: {
  tokenParam: string;
  setErrorMessage: (message: string) => void;
}) => {
  const { data, loading, error } = useQuery(INVITATION_QUERY, {
    variables: {
      token: tokenParam,
    },
    onError: (error) => onApolloError(error, setErrorMessage, ['InvalidToken']),
  });

  return {
    authorizedInvitation: data?.getAuthorizedUserInvitationByToken,
    loading,
    error,
  };
};

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PageTitle } from 'components/atoms/PageTitle';
import { Workflow, WorkflowStep } from 'components/widgets/Workflow';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { onApolloError, toCents } from 'utils';
import CardApprovalDecision from './CardApprovalDecision';
import { Finish } from './FinishStep';

const QUERY_GET_CARD_REQUEST = gql(`
  query GetCardRequest($customerId: String!, $cardRequestId: String!) {
    getCardRequest(customerId: $customerId, cardRequestId: $cardRequestId) {
      id
      name
      requesterUserId
      requesterUser {
        email
        fullName {
          first
          last
        }
      }
      status
      virtual
    }
  }
`);

const MUTATION_APPROVE_CARD_REQUEST = gql(`
  mutation ApproveCardRequest($customerId: String!, $approveCardData: ApproveCardRequest!) {
    approveCardRequest(customerId: $customerId, approveCardData: $approveCardData) {
      id
    }
  }
`);

const MUTATION_REJECT_CARD_REQUEST = gql(`
  mutation RejectCardRequest($customerId: String!, $cardRequestId: String!) {
    rejectCardRequest(customerId: $customerId, cardRequestId: $cardRequestId) {
      message
    }
  }
`);

export const CardRequestApproval = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { cardRequestId } = useParams();
  const [currentStep, setCurrentStep] = useState('editDetails');
  const [cardName, setCardName] = useState('');
  const [cardNameError] = useState<string | undefined>(undefined);
  const [dailyLimit, setDailyLimit] = useState<number | null>(null);
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);
  const navigate = useNavigate();
  const closeHref = '/cards';

  const { data: cardRequestData, loading: cardRequestLoading } = useQuery(QUERY_GET_CARD_REQUEST, {
    variables: { customerId: activeCompanySlug, cardRequestId: cardRequestId ?? '' },
    skip: !cardRequestId,
  });

  useEffect(() => {
    if (cardRequestData?.getCardRequest?.name) {
      setCardName(cardRequestData.getCardRequest?.name);
    }
  }, [cardRequestData]);

  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [approveCardRequest, { loading: approveRequestLoading, error: approveRequestError }] =
    useMutation(MUTATION_APPROVE_CARD_REQUEST, {
      onError: (error: ApolloError) =>
        onApolloError(error, setErrorMsg, ['ApproveCardRequestFailed']),
      refetchQueries: ['GetAdminApprovalRequests', 'GetCustomerCards'],
    });
  const [rejectCardRequest, { loading: rejectRequestLoading, error: rejectRequestError }] =
    useMutation(MUTATION_REJECT_CARD_REQUEST, {
      onError: (error: ApolloError) =>
        onApolloError(error, setErrorMsg, ['RejectCardRequestFailed']),
      refetchQueries: ['GetAdminApprovalRequests'],
    });

  const cardRequestApproved = cardRequestData?.getCardRequest?.status === 'approved';
  const cardRequestRejected = cardRequestData?.getCardRequest?.status === 'rejected';

  // This handles the "press Enter" for next step in multi-form
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      } else if (event.key === 'Escape') {
        navigate(closeHref);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>
      <PageTitle hidden text="Approve card request" />
      <Workflow
        rootUrl="/"
        closeHref={closeHref}
        currentStep={currentStep}
        loading={cardRequestLoading}
      >
        {cardRequestApproved || cardRequestRejected ? (
          <h1>
            The request for this card was already {cardRequestApproved ? 'approved' : 'rejected'}!
          </h1>
        ) : (
          <>
            <WorkflowStep
              currentStep={currentStep}
              step="editDetails"
              backButton={{
                hidden: true,
              }}
              ctaButton={{
                disabled: approveRequestLoading || rejectRequestLoading,
                onClick: () => {
                  if (cardRequestId) {
                    approveCardRequest({
                      variables: {
                        customerId: activeCompanySlug,
                        approveCardData: {
                          id: cardRequestId,
                          name: cardName,
                          dailyLimitCents: dailyLimit ? toCents(dailyLimit) : null,
                          monthlyLimitCents: monthlyLimit ? toCents(monthlyLimit) : null,
                        },
                      },
                    });
                    setCurrentStep('finish');
                  }
                },
                text: 'Approve card',
              }}
              secondaryButton={{
                disabled: approveRequestLoading || rejectRequestLoading,
                text: 'Reject',
                onClick: () => {
                  if (cardRequestId) {
                    rejectCardRequest({
                      variables: {
                        customerId: activeCompanySlug,
                        cardRequestId,
                      },
                    });
                    setCurrentStep('finish');
                  }
                },
              }}
              ref={buttonRef}
            >
              {cardRequestData?.getCardRequest && (
                <CardApprovalDecision
                  cardOwnerName={`${cardRequestData.getCardRequest.requesterUser?.fullName.first} ${cardRequestData.getCardRequest.requesterUser?.fullName.last}`}
                  cardType={cardRequestData.getCardRequest.virtual ? 'virtual' : 'physical'}
                  approveRequestLoading={approveRequestLoading}
                  approveRequestError={approveRequestError}
                  rejectRequestLoading={rejectRequestLoading}
                  rejectRequestError={rejectRequestError}
                  cardName={cardName}
                  cardNameError={cardNameError}
                  setCardName={setCardName}
                  dailyLimit={dailyLimit}
                  setDailyLimit={setDailyLimit}
                  monthlyLimit={monthlyLimit}
                  setMonthlyLimit={setMonthlyLimit}
                />
              )}
            </WorkflowStep>
            <WorkflowStep
              currentStep={currentStep}
              step="finish"
              buttonsCentered
              backButton={{
                hidden: true,
              }}
              ctaButton={{
                href: '/cards',
                text: 'Back to overview',
              }}
              ref={buttonRef}
            >
              <Finish />
            </WorkflowStep>
          </>
        )}
      </Workflow>
    </>
  );
};

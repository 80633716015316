import { useActiveCompany } from '../../../providers/ActiveCompany';
import { useAppState } from '../../../stores/UserStore';
import { useEffect, useState } from 'react';
import { useGetIntuitAppConnection, useListQBOAccounts } from 'components/widgets/Quickbooks';

export const QBOPairedAccount = () => {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined | null>(null);
  const [selectedAccountName, setSelectedAccountName] = useState<string | undefined>(undefined);
  const { appConnection } = useGetIntuitAppConnection({
    companySlug,
    onError: (error) => setErrorMsg(error.message),
  });

  const { qboAccounts } = useListQBOAccounts({ companySlug });
  useEffect(() => {
    setSelectedAccountId(appConnection?.qboAccountId);
    const qboAccountName = qboAccounts.find(
      (account) => account.accountId === appConnection?.qboAccountId
    )?.accountName;
    setSelectedAccountName(qboAccountName);
  }, [appConnection?.qboAccountId, qboAccounts.toString()]);

  return <>{selectedAccountId ? selectedAccountName : 'No account linked'}</>;
};

import { cn } from 'utils';
import whiteGoldCardOverlay from '../../../images/whiteGoldCardOverlay.webp';
import moment from 'moment';

export const ZenaCardSilouette = ({
  frozen,
  unactivated,
  firstName,
  lastName,
  cardId,
  last4Digits,
  showDetails,
  expirationDate,
  className,
}: {
  frozen?: boolean;
  unactivated?: boolean;
  firstName: string;
  lastName: string;
  cardId: string;
  last4Digits: string;
  showDetails: boolean;
  expirationDate?: string;
  className?: string;
}) => {
  let formattedExpirationDate = expirationDate && moment(expirationDate, 'YYYY/MM').format('MM/YY');

  return (
    <div
      className={cn(
        'bg-cover h-[187px] w-[298px] md:h-[220px] md:w-[350px] rounded-xl shadow-[0px_0px_12px_darkgray]',
        unactivated && 'opacity-30',
        className || ''
      )}
      style={{
        ...(frozen
          ? {
              backgroundImage: `linear-gradient(
                rgb(173, 173, 173, .7), 
                rgb(173, 173, 173, .7)
              ), url(${whiteGoldCardOverlay})`,
            }
          : {
              backgroundImage: `url(${whiteGoldCardOverlay})`,
            }),
      }}
    >
      <div className="p-4 text-left font-['Helvetica'] h-full flex justify-between flex-col">
        <div className="mt-16 text-md">
          <div>
            {firstName} {lastName}
          </div>
          <div className={`max-h-6`}>
            <div>
              <div
                id={`card-${cardId}-number`}
                className={showDetails ? 'w-[210px] inline-block' : 'hidden'}
              />
              <div
                id={`copy-${cardId}-number`}
                className={showDetails ? 'h-6 w-6 absolute inline-block' : 'hidden'}
              />
            </div>
            <div className={showDetails ? 'hidden' : 'text-lg'} style={{ fontFamily: 'PT Mono' }}>
              {'•••• •••• •••• '.concat(last4Digits)}
            </div>
          </div>
        </div>
        <div className="flex gap-9">
          <div className="flex h-5 gap-1.5">
            <div className="w-8 leading-none text-[10px]">GOOD THRU</div>
            <div className="text-[13px] font-bold" style={{ fontFamily: 'PT Mono' }}>
              {showDetails ? formattedExpirationDate : '••/••'}
            </div>
          </div>
          <div className="flex h-5 gap-1.5">
            <div className="leading-5 text-[10px]">CVV</div>
            <div className="text-[13px] max-h-5" style={{ fontFamily: 'PT Mono' }}>
              <div id={`card-${cardId}-cvv`} className={showDetails ? '' : 'hidden'} />
              <div className={showDetails ? 'hidden' : ''}>•••</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { ApprovalRequest, Card } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons/RoundButton';
import { NotificationBanner } from 'components/atoms/NotificationBanner';
import { PageTitle } from 'components/atoms/PageTitle';
import { TabButton, TabsContainer } from 'components/atoms/Tab';
import { CardsList } from './CardsList';
import { companyRole, isAdmin } from 'hooks/access';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import whiteZenaCardWood from '../../images/whiteZenaCardWood.webp';
import { GET_ADMIN_APPROVAL_REQUESTS } from 'pages/AdminApprovalRequestsPage/data';
import { useGetCards, useGetCompanyStatus } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Notifications } from 'providers/notifications';

type CardsPageTab = 'ACTIVE' | 'COMPLETED';

const CardsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const urlTab = searchParams.get('tab');

  const [activeCardList, setActiveCardList] = useState<Card[]>([]);
  const [archivedCardList, setArchivedCardList] = useState<Card[]>([]);
  const [activeTab, setActiveTab] = useState<CardsPageTab>(
    ['ACTIVE', 'COMPLETED'].includes(urlTab ?? '') ? (urlTab as CardsPageTab) : 'ACTIVE'
  );

  const { cards, loading, refetch, error } = useGetCards({
    customerId: activeCompanySlug,
  });

  const { company } = useGetCompanyStatus();

  const { data: approvalRequestData, startPolling: startPollingApprovalRequests } = useQuery(
    GET_ADMIN_APPROVAL_REQUESTS,
    {
      variables: {
        customerId: activeCompanySlug,
      },
      skip: !isAdmin(activeCompanySlug),
    }
  );

  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';

  // tab url serialization
  useEffect(() => {
    if (urlTab !== activeTab) {
      navigate({
        pathname: location.pathname,
        search: `?tab=${activeTab}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    // Poll for pending approval requests every 15s
    startPollingApprovalRequests(15000);

    //event made available by unit and is triggered on change of status via unit whitelabel dropdown list status change
    window.addEventListener('unitCardStatusChanged', handleCardStatusChange);
    return () => window.removeEventListener('unitCardStatusChanged', handleCardStatusChange);
  }, []);

  useEffect(() => {
    if (cards.length) {
      const activeCards: Card[] = [];
      const archivedCards: Card[] = [];
      cards.forEach((card: Card) => {
        if (card.status === 'Active' || card.status === 'Inactive' || card.status === 'Frozen') {
          activeCards.push(card);
        } else {
          archivedCards.push(card);
        }
      });

      setActiveCardList(activeCards);
      setArchivedCardList(archivedCards);
    }
  }, [cards]);

  const handleCardStatusChange = async (event?: any) => {
    if (event) {
      event.detail.then(() => {
        refetch({
          companySlug: activeCompanySlug,
          corporateOnly: true,
        });
      });
    } else {
      refetch({
        companySlug: activeCompanySlug,
        corporateOnly: true,
      });
    }
  };

  if (loading)
    return (
      <>
        <PageTitle hidden text="Loading cards..." />
        <div className="grid place-items-center mt-52">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Loading...</p>
          </div>
        </div>
      </>
    );

  if (error) return <p>Error &#x1F614; {error.message}</p>;

  const cardApprovalRequests = approvalRequestData?.getAdminApprovalRequests?.filter(
    (request: ApprovalRequest) => request.type === 'cardRequest'
  );

  if (cards.length === 0) {
    return (
      <>
        <PageTitle hidden text="Business Spend" />
        <Notifications />
        <div className="grid">
          <div className="place-self-center">
            <img className="mt-20 max-w-[229px]" src={whiteZenaCardWood} alt="Zena Card" />
          </div>
          <div
            className="text-center mt-8 place-self-center flex flex-col items-center"
            data-testid="cards"
          >
            <h1>Order your new card</h1>
            <p className="text-lg mt-6 mb-6 max-w-[529px]">
              Reinvest countless hours saved back into your business.
              <br />
              Obtain complete visibility into your team's purchase history.
              <br />
              Get support every step of the way.
            </p>
            {!isBookkeeper && (
              <Link to="/cards/new">
                <RoundButton text="Create card" className="my-5 mx-4" />
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <PageTitle text="Business Spend" hidden />

      <div className="pt-7 px-4 lg:pt-11 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
        <section className="flex justify-between gap-8 align-baseline items-center">
          <h1 className="text-xl lg:text-4xl">Business Spend</h1>
          {!isBookkeeper && (
            <Link
              to="/cards/new"
              onClick={() => {
                analytics.track('User clicks on the Create Card button');
              }}
            >
              <RoundButton disabled={company?.isFrozen} text="Create card" icon="plus" />{' '}
            </Link>
          )}
        </section>
        {!!cardApprovalRequests?.length && (
          <NotificationBanner
            variation="pending"
            message={
              <p>
                You have{' '}
                <Link to="/approval-requests">
                  <span className="font-semibold underline">
                    {cardApprovalRequests.length} card request
                    {cardApprovalRequests.length > 1 && 's'}
                  </span>
                </Link>{' '}
                to review.
              </p>
            }
          />
        )}

        <TabsContainer>
          <TabButton
            isActive={activeTab === 'ACTIVE'}
            onClick={() => {
              if (activeTab !== 'ACTIVE') {
                setActiveTab('ACTIVE');
              }
            }}
          >
            Active
          </TabButton>
          <TabButton
            isActive={activeTab === 'COMPLETED'}
            onClick={() => {
              if (activeTab !== 'COMPLETED') {
                setActiveTab('COMPLETED');
              }
            }}
          >
            Completed
          </TabButton>
        </TabsContainer>

        <section className="grow overflow-scroll pb-8">
          <CardsList
            cardsData={activeTab === 'ACTIVE' ? activeCardList : archivedCardList}
            customerId={activeCompanySlug}
            showSummary
            showTransactions
            archived={activeTab === 'COMPLETED'}
          />
        </section>
      </div>
    </>
  );
};
export default CardsPage;

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PageTitle } from 'components/atoms/PageTitle';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { onApolloError, toCents } from 'utils';
import CardApprovalDecision from './CardApprovalDecision';
import { RoundButton } from 'components/atoms/Buttons';

const QUERY_GET_CARD_REQUEST = gql(`
  query GetCardRequest($customerId: String!, $cardRequestId: String!) {
    getCardRequest(customerId: $customerId, cardRequestId: $cardRequestId) {
      id
      name
      requesterUserId
      requesterUser {
        email
        fullName {
          first
          last
        }
      }
      status
      virtual
    }
  }
`);

const MUTATION_APPROVE_CARD_REQUEST = gql(`
  mutation ApproveCardRequest($customerId: String!, $approveCardData: ApproveCardRequest!) {
    approveCardRequest(customerId: $customerId, approveCardData: $approveCardData) {
      id
    }
  }
`);

const MUTATION_REJECT_CARD_REQUEST = gql(`
  mutation RejectCardRequest($customerId: String!, $cardRequestId: String!) {
    rejectCardRequest(customerId: $customerId, cardRequestId: $cardRequestId) {
      message
    }
  }
`);

export const CardMgmtRequestApproval = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { cardRequestId } = useParams();
  const [cardName, setCardName] = useState('');
  const [cardNameError] = useState<string | undefined>(undefined);
  const [dailyLimit, setDailyLimit] = useState<number | null>(null);
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);
  const navigate = useNavigate();

  const { data: cardRequestData, loading: cardRequestLoading } = useQuery(QUERY_GET_CARD_REQUEST, {
    variables: { customerId: activeCompanySlug, cardRequestId: cardRequestId ?? '' },
    skip: !cardRequestId,
  });

  useEffect(() => {
    if (cardRequestData?.getCardRequest?.name) {
      setCardName(cardRequestData.getCardRequest?.name);
    }
  }, [cardRequestData]);

  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [approveCardRequest, { loading: approveRequestLoading, error: approveRequestError }] =
    useMutation(MUTATION_APPROVE_CARD_REQUEST, {
      onError: (error: ApolloError) =>
        onApolloError(error, setErrorMsg, ['ApproveCardRequestFailed']),
      refetchQueries: ['GetAdminApprovalRequests', 'GetCustomerCards'],
      onCompleted: () => navigate('/cards-management/approvals'),
    });
  const [rejectCardRequest, { loading: rejectRequestLoading, error: rejectRequestError }] =
    useMutation(MUTATION_REJECT_CARD_REQUEST, {
      onError: (error: ApolloError) =>
        onApolloError(error, setErrorMsg, ['RejectCardRequestFailed']),
      refetchQueries: ['GetAdminApprovalRequests'],
      onCompleted: () => navigate('/cards-management/approvals'),
    });

  return (
    <>
      <PageTitle hidden text="Approve card request" />
      <div className="flex justify-center items-center p-16 lg:mt-36">
        <div>
          {cardRequestData?.getCardRequest && (
            <CardApprovalDecision
              cardOwnerName={`${cardRequestData.getCardRequest.requesterUser?.fullName.first} ${cardRequestData.getCardRequest.requesterUser?.fullName.last}`}
              cardType={cardRequestData.getCardRequest.virtual ? 'virtual' : 'physical'}
              approveRequestLoading={approveRequestLoading}
              approveRequestError={approveRequestError}
              rejectRequestLoading={rejectRequestLoading}
              rejectRequestError={rejectRequestError}
              cardName={cardName}
              cardNameError={cardNameError}
              setCardName={setCardName}
              dailyLimit={dailyLimit}
              setDailyLimit={setDailyLimit}
              monthlyLimit={monthlyLimit}
              setMonthlyLimit={setMonthlyLimit}
            />
          )}
          {!cardRequestLoading && (
            <div className="flex gap-4">
              <RoundButton
                variant="secondary"
                disabled={approveRequestLoading || rejectRequestLoading}
                onClick={() => {
                  if (cardRequestId) {
                    rejectCardRequest({
                      variables: {
                        customerId: activeCompanySlug,
                        cardRequestId,
                      },
                    });
                  }
                }}
              >
                Reject
              </RoundButton>
              <RoundButton
                disabled={approveRequestLoading || rejectRequestLoading}
                onClick={() => {
                  if (cardRequestId) {
                    approveCardRequest({
                      variables: {
                        customerId: activeCompanySlug,
                        approveCardData: {
                          id: cardRequestId,
                          name: cardName,
                          dailyLimitCents: dailyLimit ? toCents(dailyLimit) : null,
                          monthlyLimitCents: monthlyLimit ? toCents(monthlyLimit) : null,
                        },
                      },
                    });
                  }
                }}
              >
                Approve card
              </RoundButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import { ColumnDef } from '@tanstack/react-table';

import { cardType, statuses } from './data';
import { Header } from './Header';
import { Checkbox } from 'components/atoms/Checkbox';
import { BadgeFlat, VariantOptions } from 'components/ui/Badge';
import { Card } from '__generated__/graphql';

export const columns: ColumnDef<Card>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onChange={(value) => table.toggleAllPageRowsSelected(value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={(value) => row.toggleSelected(value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'last4Digits',
    header: ({ column }) => <Header column={column} title="Last Four" />,
    cell: ({ row }) => <div className="w-[80px]">{row.getValue('last4Digits')}</div>,
  },
  {
    accessorKey: 'projectName',
    accessorFn: (row) => row.projectName ?? '',
    header: ({ column }) => <Header column={column} title="Assigned" />,
    cell: ({ row }) => {
      const proj = row.getValue('projectName');
      if (!proj) {
        return '-';
      }
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate">{row.getValue('projectName')}</span>
        </div>
      );
    },
  },
  {
    id: 'cardholderName',
    accessorFn: (row) => `${row.cardholderFullName?.first} ${row.cardholderFullName?.last}`,
    header: ({ column }) => <Header column={column} title="Cardholder name" />,
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate">{row.getValue('cardholderName')}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'type',
    header: ({ column }) => <Header column={column} title="Type" />,
    cell: ({ row }) => {
      const card = cardType.find((status) => status.value === row.getValue('type'));

      if (!card) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          <span>{card.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'name',
    header: ({ column }) => <Header column={column} title="Card nickname" />,
    cell: ({ row }) => {
      const name = row.getValue('name');
      if (!name) {
        return '-';
      }
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate">{row.getValue('name')}</span>
        </div>
      );
    },
  },
  {
    id: 'status',
    accessorFn: (card) => {
      switch (card?.status) {
        case 'Active':
          return 'Active';
        case 'Stolen':
        case 'ClosedByCustomer':
        case 'Lost':
          return 'Closed';
        case 'Frozen':
        case 'SuspectedFraud':
          return 'Frozen';
        case 'Inactive':
          return 'Inactive';
        default:
      }
    },
    header: ({ column }) => <Header column={column} title="Status" />,
    cell: ({ row }) => {
      const status = statuses.find((status) => status.value === row.getValue('status'));

      if (!status) {
        return null;
      }

      let badgeVariant: VariantOptions = 'default';
      switch (status.value.toLowerCase()) {
        case 'closed':
          badgeVariant = 'danger';
          break;
        case 'inactive':
          badgeVariant = 'info';
          break;
        case 'frozen':
          badgeVariant = 'warning';
          break;
        case 'active':
          badgeVariant = 'success';
          break;
      }
      return (
        <div className="flex w-[100px] items-center">
          <BadgeFlat variant={badgeVariant}>{status.label}</BadgeFlat>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
];

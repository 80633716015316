import { motion, useAnimation } from 'framer-motion';
import { ReactNode, useEffect } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { cn } from 'utils';
import { Button } from '../Buttons';
import { useWindowKeyboardInteraction } from '../WindowInteractionListeners/keyboard';
import { X } from 'lucide-react';

export const Drawer = ({
  isVisible,
  children,
  className = '',
  onClose,
}: {
  isVisible: boolean;
  children: ReactNode;
  className?: string;
  onClose: () => void;
}) => {
  const animationControls = useAnimation();
  const { width: windowWidth } = useWindowSize();

  const sidebarWidth = windowWidth && windowWidth < 640 ? '100vw' : 450;

  useEffect(() => {
    animationControls.start(isVisible ? 'active' : 'inactive');
  }, [isVisible, animationControls]);

  const sidebarAnimatedVariants = {
    active: {
      x: 0,
    },
    inactive: {
      x: sidebarWidth,
    },
  };

  useWindowKeyboardInteraction({
    onKeyDown: (e) => {
      if (e.key === 'Escape' && isVisible) {
        onClose?.();
      }
    },
  });

  return (
    <div className="fixed top-0 right-0 pointer-events-none z-40">
      <motion.section
        style={{
          width: sidebarWidth,
          pointerEvents: 'all',
          maxWidth: sidebarWidth,
        }}
        className="relative z-10 h-screen box-border bg-transparent"
        animate={animationControls}
        variants={sidebarAnimatedVariants}
        transition={{ type: 'spring', bounce: 0 }}
        initial={{
          x: sidebarWidth,
        }}
      >
        <DrawerContentContainer isVisible={isVisible} className={className}>
          {children}
        </DrawerContentContainer>
      </motion.section>
    </div>
  );
};

const DrawerContentContainer = ({
  children,
  className = '',
  isVisible,
}: {
  children: ReactNode;
  className?: string;
  isVisible: boolean;
}) => {
  return (
    <div
      className={cn('bg-white w-full h-full', className)}
      style={{
        boxShadow: isVisible ? '-4px 1px 4px 0 rgb(0 0 0 / 0.1)' : 'none',
      }}
    >
      {children}
    </div>
  );
};

export const DrawerTitle = ({ title, onClose }: { title: string; onClose: () => void }) => {
  return (
    <TitleContainer>
      <TitleRow>
        <h2 className="text-3xl truncate">{title}</h2>
        <CloseButton onClick={onClose} />
      </TitleRow>
    </TitleContainer>
  );
};

const TitleContainer = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 border-b border-primary pb-4 min-w-0">{children}</div>;
};

const TitleRow = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between items-start gap-4 min-w-0">{children}</div>;
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="ghost" className="p-0" onClick={onClick}>
      <X />
    </Button>
  );
};

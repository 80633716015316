import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const CARD_DETAILS_DISPLAY_QUERY = gql(`
  query GetCardDetailsDisplay($customerId: String!, $cardId: String!) {
    getCard(customerId: $customerId, cardId: $cardId) {
      id
      name
      status
      type
      last4Digits
      cardholderId
      cardholderFullName {
        first
        last
      }
      billingAddress {
        city
        country
        postalCode
        state
        street
        street2
      }
    }
  }
`);

export const useCardDetails = ({
  companySlug,
  cardId,
}: {
  companySlug: string;
  cardId: string;
}) => {
  const { data, loading, error } = useQuery(CARD_DETAILS_DISPLAY_QUERY, {
    variables: {
      customerId: companySlug,
      cardId: cardId,
    },
    skip: !companySlug || !cardId,
  });

  return { card: data?.getCard || undefined, loading, error };
};

import { ReactNode } from 'react';
import { useTransactionDetailsMemo } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useTransactionDetailsContext } from '../state';
import { TextArea } from 'components/atoms/Inputs/TextArea';

export const TransactionDetailsMemo = ({
  showMissingRequirements,
}: {
  showMissingRequirements: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { state, dispatch } = useTransactionDetailsContext();

  const { loading, isMissingMemo } = useTransactionDetailsMemo({
    transactionId: state.transactionId,
    activeCompanySlug,
    onCompleted: (data) => {
      if (data?.getTransaction?.memo && data.getTransaction.memo !== state.memo) {
        dispatch({
          type: 'POPULATE_MEMO',
          payload: data.getTransaction.memo,
        });
      }
    },
  });

  return (
    <Container>
      <div className="text-xl">Memo</div>
      {loading && <RectangleSkeleton className="h-[78px]" width="full" />}
      {!loading && (
        <TextArea
          value={state.memo}
          className={
            showMissingRequirements && isMissingMemo && !state.memo
              ? 'border-accent-800 border-4'
              : ''
          }
          placeholder="Sometimes we just forget things. Add some notes for later!"
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_MEMO',
              payload: e.currentTarget.value,
            });
          }}
        />
      )}
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-4 max-w-full min-w-0">{children}</div>;
};

import { ApprovalRequest } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { Pill } from 'components/atoms/Pill';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { Link } from 'react-router-dom';
import { useGetAdminApprovalRequests } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const AdminApprovalRequestsPage = ({
  approvalType,
}: {
  approvalType?: 'project' | 'card';
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { approvalRequests, loading } = useGetAdminApprovalRequests({
    customerId: activeCompanySlug,
    type: approvalType,
  });

  if (loading) {
    return (
      <>
        <div className="grid place-items-center mt-52">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Loading ...</p>
          </div>
        </div>
      </>
    );
  }

  if (!approvalRequests.length) {
    return (
      <>
        <PageTitle hidden text="Pending Approvals" />
        <div className="grid h-full">
          <div className="text-center mt-8 place-self-center">
            <h1>You have no requests to review.</h1>
            <p className="text-lg text-gray-500 my-6 mx-auto max-w-[529px]">
              We will notify you when there are new projects or cards to review. Requests awaiting
              approval will appear here.
            </p>
            <Link to="/">
              <RoundButton text="Home" className="my-5 mx-4" />
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full">
      <PageTitle hidden text="Pending Approvals" />
      <div className="m-10">
        <h1>Waiting for your review</h1>
        <div className="flex flex-col gap-4 mt-10">
          {approvalRequests.map((request: ApprovalRequest) => {
            const requestTypeDisplay = request.type === 'project' ? 'project' : 'card';
            const requestUrl =
              request.type === 'project'
                ? `/projects/${request.id}`
                : `/cardRequests/${request.id}`;
            return (
              <div
                className="flex flex-col border border-feather rounded-3xl p-6"
                key={`${request.type}-${request.id}`}
              >
                <Pill variant="pending" text={`Pending ${requestTypeDisplay}`} />
                <div className="flex justify-between mt-4">
                  <h3 className="">{request.title}</h3>
                  <Link to={requestUrl}>
                    <RoundButton text={`Review ${requestTypeDisplay}`} />
                  </Link>
                </div>
                <span className="text-gray-500">
                  <RelativeDate date={request.createdAt} />
                  <span className="mx-2">·</span>
                  {request.requesterUserName || 'Team Member'}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Menu, Transition } from '@headlessui/react';
import { Card } from '__generated__/graphql';
import { Fragment, ReactNode, useMemo, useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';
import { ActionSuccessModal } from './ActionSuccessModal';
import { CloseCardModal } from './CloseCardModal';
import { LostOrStolenModal } from './LostOrStolenModal';
import { ReplaceCardModal } from './ReplaceCardModal';
import { Ban, OctagonAlert, RefreshCcw, Lock, Ellipsis } from 'lucide-react';

export interface ActionMenuProps {
  cardId: string;
  customerId: string;
  cardData: Card;
  handleCardStatusChange?: Function;
  alwaysShowActionButton?: boolean;
  children?: ReactNode;
}

export interface ActionItem {
  actionName: string;
  icon: ReactNode;
  function: Function;
  types?: [String];
  closeFunction?: Function;
  message: string;
  submessage: string;
}
export interface MenuActions {
  activate: ActionItem;
  freeze: ActionItem;
  unfreeze: ActionItem;
  replace: ActionItem;
  report: ActionItem;
  close: ActionItem;
}

// query and mutation definitions
const MUTATION_FREEZE_CARD = gql(`
  mutation FreezeZenaCard($customerId: String!, $cardId: String!) {
    freezeZenaCard(customerId: $customerId, cardId: $cardId) {
      id
    }
  }
`);

const MUTATION_UNFREEZE_CARD = gql(`
  mutation UnfreezeZenaCard($customerId: String!, $cardId: String!) {
    unfreezeZenaCard(customerId: $customerId, cardId: $cardId) {
      id
    }
  }
`);

const ActionMenu = ({
  cardId,
  customerId,
  cardData,
  handleCardStatusChange,
  alwaysShowActionButton = false,
  children,
}: ActionMenuProps) => {
  const [showCloseCardModal, setShowCloseCardModal] = useState(false);
  const [showLostOrStolenModal, setShowLostOrStolenModal] = useState(false);
  const [showReplaceCardModal, setShowReplaceCardModal] = useState(false);
  const [showActionSuccessModal, setShowActionSuccessModal] = useState(false);
  const [performedAction, setPerformedAction] = useState('freeze');
  const [successCustomText, setSuccessCustomText] = useState('');

  const [, setErrorMessage] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);

  const [freezeCard] = useMutation(MUTATION_FREEZE_CARD, {
    variables: {
      cardId,
      customerId,
    },
    onCompleted: () => {
      showSuccessModal('freeze');
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  const [unfreezeCard] = useMutation(MUTATION_UNFREEZE_CARD, {
    variables: {
      cardId,
      customerId,
    },
    onCompleted: () => {
      showSuccessModal('unfreeze');
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  const menuActions: MenuActions = {
    activate: {
      actionName: 'Card Activation',
      icon: <Lock />,
      function: () => {},
      message: 'The card has been activated',
      submessage: 'Your card is ready for use',
    },
    freeze: {
      actionName: 'Freeze',
      icon: <Lock />,
      function: freezeCard,
      message: 'The card has been frozen',
      submessage: 'You can unfreeze the card in the archived tab',
    },
    unfreeze: {
      actionName: 'Unfreeze',
      icon: <Lock />,
      function: unfreezeCard,
      message: 'The card has been unfrozen',
      submessage: 'Your card can now be used again',
    },
    replace: {
      actionName: 'Replace Card',
      icon: <RefreshCcw />,
      types: ['businessCreditCard'],
      function: () => setShowReplaceCardModal(true),
      closeFunction: () => setShowReplaceCardModal(false),
      message: 'Your replacement card is on the way',
      submessage: 'Expect your card to arrive in 5-7 business days',
    },
    report: {
      actionName: 'Report Lost or Stolen',
      icon: <OctagonAlert />,
      function: () => setShowLostOrStolenModal(true),
      closeFunction: () => setShowLostOrStolenModal(false),
      message: 'Your card was closed',
      submessage:
        "Your card was reported |customText| and you won't be able to make transactions using it",
    },
    close: {
      actionName: 'Close Card',
      icon: <Ban />,
      function: () => setShowCloseCardModal(true),
      closeFunction: () => setShowCloseCardModal(false),
      message: 'Your card is closed',
      submessage: 'It can no longer be used',
    },
  };

  const statusMap = {
    Active: ['freeze', 'replace', 'report', 'close'],
    Inactive: ['replace', 'report', 'close'],
    Stolen: [],
    Lost: [],
    Frozen: ['unfreeze', 'report', 'close'],
    ClosedByCustomer: [],
    SuspectedFraud: [],
  };

  const showSuccessModal = (actionKey: string) => {
    setPerformedAction(actionKey);
    setShowActionSuccessModal(true);
  };

  const closeSuccessModal = () => {
    setShowActionSuccessModal(false);
    if (handleCardStatusChange) {
      handleCardStatusChange();
    }
  };

  const handleActionModalClose = (action: string, showSuccess: boolean, customText?: string) => {
    const closeFunction = menuActions[action as keyof MenuActions]?.closeFunction;
    if (closeFunction) {
      closeFunction();
    }
    if (customText) {
      setSuccessCustomText(customText);
    }

    if (showSuccess) {
      showSuccessModal(action);
    }
  };

  const cardActions = useMemo(() => {
    // determine which actions should be displayed based on the current card status
    return statusMap[cardData.status as keyof typeof statusMap]
      .map((action) => {
        return menuActions[action as keyof typeof menuActions];
      })
      .filter((actionInfo) => {
        if (actionInfo.types) {
          if (actionInfo.types.includes(cardData.type || '')) {
            return actionInfo;
          }
        } else {
          return actionInfo;
        }
      });
  }, [cardData.status]);

  if (cardActions.length || alwaysShowActionButton) {
    return (
      <>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>{children ? children : <Ellipsis className="w-8 h-8" />}</Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 top-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
              <div className="h-10 px-4 pt-4 font-semibold text-[12px] leading-normal">Actions</div>
              {Object.values(cardActions).map((menuAction) => {
                return (
                  <Menu.Item key={menuAction.actionName}>
                    <button
                      className="group flex w-full items-center border-none h-10 px-2 py-2 text-sm hover:bg-tertiary-800 active:bg-tertiary-800"
                      onClick={() => menuAction.function()}
                    >
                      <div className="flex gap-2">
                        <span className="h-5">{menuAction.icon}</span>
                        <span className="leading-6">{menuAction.actionName}</span>
                      </div>
                    </button>
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
        <CloseCardModal
          show={showCloseCardModal}
          onClose={(showSuccess: boolean) => handleActionModalClose('close', showSuccess)}
          cardData={cardData}
          cardId={cardId}
          customerId={customerId}
        />
        <LostOrStolenModal
          show={showLostOrStolenModal}
          onClose={(showSuccess: boolean, customText: string) =>
            handleActionModalClose('report', showSuccess, customText)
          }
          cardData={cardData}
          cardId={cardId}
          customerId={customerId}
        />
        <ReplaceCardModal
          show={showReplaceCardModal}
          onClose={(showSuccess: boolean) => handleActionModalClose('replace', showSuccess)}
          cardData={cardData}
          cardId={cardId}
          customerId={customerId}
        />
        <ActionSuccessModal
          show={showActionSuccessModal}
          onClose={() => closeSuccessModal()}
          cardData={cardData}
          title={menuActions[performedAction as keyof MenuActions].actionName}
          message={menuActions[performedAction as keyof MenuActions].message}
          submessage={menuActions[performedAction as keyof MenuActions].submessage.replace(
            '|customText|',
            successCustomText
          )}
        />
      </>
    );
  } else {
    return <div className="h-16" />;
  }
};

export default ActionMenu;

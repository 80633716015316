import { PageTitle } from 'components/atoms/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveCompany } from '../../providers/ActiveCompany';
import { Notifications } from '../../providers/notifications';
import { CardsPageLayout } from './CardsLayout';
import { CardDetailsDrawerContent } from 'components/organisms/CardDetailsDrawer';
import { useGetCards } from './Table/data';
import { CardsMgmtTable } from './Table/CardMgmtTable';
import { columns } from './Table/Columns';
import { AdminApprovalRequests } from './AdminApprovalRequests/AdminApprovalRequests';

export const CardsManagementPage = ({ currentTab }: { currentTab?: 'all' | 'approvals' }) => {
  const { activeCompany } = useActiveCompany();
  const customerId = activeCompany?.slug ?? '';

  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/cards-management', { replace: true });
  };
  const { cardId } = useParams();
  const { cards } = useGetCards({ customerId });
  return (
    <>
      <PageTitle hidden text="Cards" />
      <Notifications />
      <CardsPageLayout
        title="My Zena Cards"
        className="mt-8 pb-16"
        currentTab={currentTab || 'all'}
      >
        {currentTab === 'approvals' ? (
          <AdminApprovalRequests />
        ) : (
          <>
            <CardsMgmtTable columns={columns} data={cards} />
            <CardDetailsDrawerContent
              isVisible={!!cardId}
              cardId={cardId ?? ''}
              onClose={handleClose}
            />
          </>
        )}
      </CardsPageLayout>
    </>
  );
};

import { Link } from 'react-router-dom';
import { DonutChart } from './DonutChart';
import { ReactNode } from 'react';
import { cn } from '../../../utils';

export const OrganizeTableHeader = ({ qboActive = false }: { qboActive: boolean }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 mb-12">
      <div className="lg:col-span-3 border rounded-3xl flex flex-col p-12">
        {qboActive ? (
          <>
            <div className="text-xl pb-4">Transactions not ready for Quickbooks</div>
            <div className="border-b mr-20" />
            <div className="space-y-3">
              <p className="pt-6">
                These transactions need more information before Zena can send them to Quickbooks.
              </p>
              <p>
                The information required on these transactions are based on your company settings.{' '}
                <Link to="/settings/settings" className="underline">
                  Update your settings
                </Link>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="text-xl pb-4">Transactions that need more information</div>
            <div className="border-b mr-20" />
            <div className="space-y-3">
              <p className="pt-6">
                The information required on these transactions are based on your company settings.{' '}
                <Link to="/settings/settings" className="underline">
                  Update your settings
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
      <div className="border rounded-3xl lg:col-span-2 py-12 relative">
        <DonutChart onlyZenaTransactions={qboActive} />
      </div>
    </div>
  );
};

export const TableHeaderWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'border border-solid rounded-lg grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-2 p-6',
        className
      )}
    >
      {children}
    </div>
  );
};

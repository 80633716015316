import { RectangleSkeleton, waveAnimationClasses } from 'components/atoms/Skeletons';
import { cn } from '../../../utils';

export const LoadingProjectCard = () => {
  return (
    <div className="bg-white border border-primary-400 grid grid-rows-2 h-full overflow-hidden relative rounded-3xl">
      <div className={cn(waveAnimationClasses, 'w-full relative bg-gray-300/60 h-44')} />
      <div className="p-4 mt-4 space-y-4">
        <RectangleSkeleton width="large" className="bg-primary-400/80" />
        <RectangleSkeleton width="medium" className="bg-primary-400/40" />
      </div>
    </div>
  );
};

import { useState } from 'react';
import { RoundButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { Modal } from 'components/atoms/Modal';
import { gql } from '__generated__';
import { ApolloError, useMutation } from '@apollo/client';
import { onApolloError } from 'utils';
import { useAppState } from 'stores/UserStore';
import useCustomAuth from 'hooks/useCustomAuth';
import { useNavigate } from 'react-router-dom';
import { ModalFooter } from 'components/atoms/Modal/Modal';
import { isAdmin } from 'hooks/access';
import { ProjectStatus } from '__generated__/graphql';
import { useActiveCompany } from 'providers/ActiveCompany';

interface CreateNewProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MUTATION_CREATE_PROJECT = gql(`
  mutation CreateOnboardingProject($customerId: String!, $name: String!, $users: [String!]!) {
    createProject(customerId: $customerId, name: $name, users: $users) {
      id
      approved
    }
  }
`);

const PROJECT_LIST_REFETCH_QUERY = gql(`
  query RefetchActiveProjectList($companySlug: String!, $filters: ProjectFiltersInput) {
    getProjects(customerId: $companySlug, filters: $filters, sortBy: created_at) {
      projects {
        id
        name
      }
    }
  }
`);

const CreateNewProjectModal = ({ isOpen, onClose }: CreateNewProjectModalProps) => {
  const [projectName, setProjectName] = useState('');
  const [projectNameError, setProjectNameError] = useState('');
  const [needsApproval, setNeedsApproval] = useState(false);
  const [, setErrorMsg] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { user } = useCustomAuth();
  const navigate = useNavigate();
  const adminAccess = isAdmin(activeCompanySlug);

  const [createProject, { loading }] = useMutation(MUTATION_CREATE_PROJECT, {
    refetchQueries: ['GetProjectsForCollection'],
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['CardLimitError', 'InvalidCharacterError']);
    },
  });

  const handleCreateProject = (event: React.FormEvent | React.MouseEvent) => {
    event.preventDefault();
    if (!projectName) {
      setProjectNameError('Project name is required');
      return;
    }

    setProjectNameError('');
    createProject({
      variables: {
        customerId: activeCompanySlug,
        name: projectName,
        users: [user?.attributes?.sub || ''],
      },
      refetchQueries: [
        {
          query: PROJECT_LIST_REFETCH_QUERY,
          variables: {
            companySlug: activeCompanySlug,
            filters: {
              includeUnapproved: adminAccess,
              status: ProjectStatus.active,
            },
          },
        },
      ],
      onCompleted: (data) => {
        if (data?.createProject?.approved) {
          navigate(`${data?.createProject?.id}?new=true`);
        } else {
          setNeedsApproval(true);
        }
      },
    });
  };

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      title={!needsApproval ? "Let's create a project!" : 'Great job!'}
    >
      {!needsApproval ? (
        <>
          <label>Project name</label>
          <form onSubmit={(e) => handleCreateProject(e)}>
            <TextInput
              label=""
              testId="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              hasError={!!projectNameError}
              errorMessage={projectNameError}
              className="w-full md:w-96"
            />

            <ModalFooter className="flex justify-end">
              <RoundButton onClick={(e) => handleCreateProject(e)} isLoading={loading}>
                Create
              </RoundButton>
            </ModalFooter>
          </form>
        </>
      ) : (
        <>
          <div className="rounded-xl bg-slate-50 text-center my-5 py-6 sm:px-16 flex flex-col items-center justify-center">
            <h4 className="font-bold mb-1.5">Project sent for approval</h4>
            <p className="text-neutral-600 mb-5">
              We'll create your project as soon as your company admin approves it.
            </p>
          </div>
          <ModalFooter className="flex justify-end">
            <RoundButton onClick={onClose}>Continue</RoundButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default CreateNewProjectModal;

import { RoundButton } from 'components/atoms/Buttons';
import { Link } from 'react-router-dom';

export const PendingInvitationCard = (props: { name: string; invitationLink: string }) => {
  const { name, invitationLink } = props;
  return (
    <>
      <div className="flex border border-feather rounded-3xl py-10 px-6 my-2 gap-3">
        <div className="flex items-center gap-1">
          <p>You have been invited to join</p>
          <p> {name}</p>
        </div>
        <p>
          <Link to={invitationLink}>
            <RoundButton text="Accept" />
          </Link>
        </p>
      </div>
    </>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const TRANSACTION_DETAILS_HEADER_QUERY = gql(`
  query GetTransactionDetailsHeaderData($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      shortName
      amountWithDirection
      date
      card {
        id
        last4Digits
        name
      }
      parentTransaction {
        id
        amountWithDirection
      }
    }
  }
`);

export const useTransactionHeaderDetails = ({
  transactionId,
  activeCompanySlug,
}: {
  transactionId: string;
  activeCompanySlug: string;
}) => {
  const { data, loading, error } = useQuery(TRANSACTION_DETAILS_HEADER_QUERY, {
    variables: {
      transactionId,
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || !transactionId,
  });

  return { transaction: data?.getTransaction, loading, error };
};

import { ShippingAddress } from '__generated__/graphql';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { getAllStates } from 'utils/us-states';
import useGooglePlaceAutoComplete from 'hooks/googlePlaceAutocomplete';
import { useEffect, useRef } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

type CardShippingAddressStepProps = {
  formRegister: UseFormRegister<ShippingAddress>;
  setValue: UseFormSetValue<ShippingAddress>;
  getValues: UseFormGetValues<ShippingAddress>;
  addressStreetError?: string;
  addressCityError?: string;
  addressStateError?: string;
  addressPostalCodeError?: string;
};

const CardShippingAddressStep = ({
  formRegister,
  setValue,
  getValues,
  addressStreetError,
  addressCityError,
  addressStateError,
  addressPostalCodeError,
}: CardShippingAddressStepProps) => {
  const states = getAllStates();
  const address1Ref = useRef<HTMLInputElement>(null);
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
  let autoComplete = '';
  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

    if (address1Ref.current) {
      address1Ref.current.value = addressObj.address1;
    }

    setValue('street', addressObj.address1);
    setValue('street2', '');
    setValue('city', addressObj.locality || addressObj.sublocality);
    setValue('state', addressObj.adminArea1Short);
    setValue('postalCode', addressObj.postalCode);

    googleAutoCompleteSvc.clearResult(autoComplete);
  };
  useEffect(() => {
    if (address1Ref.current) {
      address1Ref.current.value = getValues('street') || '';
    }

    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
  }, []);
  return (
    <>
      <h1 className="text-asphalt text-2xl mb-10">Where should the card be shipped?</h1>
      <div className="flex flex-col space-y-6">
        <TextInput
          label="Street"
          ref={address1Ref}
          onChange={(e) => setValue('street', e.target.value)}
          hasError={!!addressStreetError}
          errorMessage={addressStreetError}
        />
        <div className="grid grid-cols-2 space-x-6 w-full">
          <TextInput
            label="Apartment, unit, suite (Optional)"
            useFormRegister={formRegister('street2')}
          />
          <TextInput
            label="City"
            useFormRegister={formRegister('city')}
            hasError={!!addressCityError}
            errorMessage={addressCityError}
          />
        </div>
        <div className="grid grid-cols-2 space-x-6 w-full">
          <div>
            <div
              className={`w-full flex flex-col rounded-lg border ${
                !!addressStateError ? 'border-rustic' : 'border-marble'
              } px-4 pt-2 pb-1.5`}
            >
              <label className="block mb-2 text-sm font-medium text-gray-900">State</label>
              <select
                className="bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-lavender"
                {...formRegister('state')}
              >
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.isoCode}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {!!addressStateError && <div className="mt-2 text-rustic">{addressStateError}</div>}
          <TextInput
            label="Zip Code"
            useFormRegister={formRegister('postalCode')}
            hasError={!!addressPostalCodeError}
            errorMessage={addressPostalCodeError}
          />
        </div>
      </div>
    </>
  );
};

export default CardShippingAddressStep;

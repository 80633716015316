import { Notifications } from 'providers/notifications';
import { DashboardTitle } from './DashboardTitle';
import { ReactNode } from 'react';

export const DashboardLayout = ({ children }: { children?: ReactNode }) => {
  return (
    <>
      <DashboardTitle />
      <Notifications />
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 p-6 max-w-screen-2xl">{children}</div>
    </>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const UPCOMING_PAYMENT_QUERY = gql(`
  query GetUpcomingAutopaymentForDashboard($companySlug: String!) {
    getNextAutopayment(customerId: $companySlug) {
      amountCents
      date
    }
  }
`);

const QUERY_GET_PLAID_LINKED_ACCOUNT_DETAILS = gql(`
  query GetPlaidLinkedAccountSummaryForDashboard($companySlug: String!) {
    getPlaidLinkedAccountSummary(customerId: $companySlug) {
      id
      bank
      last4
    }
  }
`);

export const useDashboardUpcomingPayment = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(UPCOMING_PAYMENT_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const upcomingPayment = data?.getNextAutopayment;

  return { upcomingPayment, loading, error };
};

export const useDashboardLinkedAccount = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY_GET_PLAID_LINKED_ACCOUNT_DETAILS, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const linkedAccount = data?.getPlaidLinkedAccountSummary;

  return { linkedAccount, loading, error };
};

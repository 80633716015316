import { RectangleSkeleton } from 'components/atoms/Skeletons';

export const LoadingWidget = () => {
  return (
    <div className="grid gap-4">
      <RectangleSkeleton height="large" />
      <RectangleSkeleton />
      <RectangleSkeleton />
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const useGetProjectsForWidget = ({ customerId }: { customerId?: string }) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      customerId: customerId ?? '',
    },
    skip: !customerId,
  });
  return {
    projects: data?.getProjects?.projects,
    loading,
    error,
  };
};

const QUERY = gql(`
query GetProjectForDashboardWidget($customerId: String!) {
    getProjects(customerId: $customerId, filters: {
      status: active
    }) {
      projects {
        id
        name
        budget
        amountSpent
        status
      }
    }
  }
`);

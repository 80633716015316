import { gql } from '__generated__/gql';
import { useQuery } from '@apollo/client';
import { TransactionFiltersInput } from '__generated__/graphql';

const QUERY = gql(`
    query GetTransactionsWithChildrenCount($companySlug: String!, $filters: TransactionFiltersInput) {
        getTransactionsWithChildrenCount(companySlug: $companySlug, filters: $filters) 
    }
`);

export const useGetTransactionsWithChildrenCount = ({
  companySlug,
  filters,
}: {
  companySlug: string;
  filters: TransactionFiltersInput;
}) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      companySlug,
      filters,
    },
  });

  return {
    count: data?.getTransactionsWithChildrenCount,
    loading,
    error,
  };
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { isAdmin } from 'hooks/access';
import { useActiveCompany } from 'providers/ActiveCompany';

const ONBOARDING_STATUS_QUERY = gql(`
  query OnboardingStatusQuery($companySlug: String!)  {
    getProjects(customerId: $companySlug) {
      projects {
         id
      }
    }
    getCompanyUsers(companySlug: $companySlug) {
      email
    }
    getPendingInvitations(customerId: $companySlug) {
      email
    }
    getCorporateCards(companySlug: $companySlug) {
      id
    }
    getAuthorizationsAndTransactions(customerId: $companySlug, pageNumber: 1, filters: {}) {
      meta {
        total
      }
    }
    getCompany(companyId: $companySlug) {
      id
      tier
    }
  }
`);

export const useGetOnboardingStatus = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);
  const { data, loading, error } = useQuery(ONBOARDING_STATUS_QUERY, {
    variables: {
      companySlug: activeCompanySlug,
    },
    skip: !admin,
  });

  const hasMultipleTeamMembers = (data?.getCompanyUsers?.length ?? 0) > 1 ?? false;
  // The application creator counts as 1 authorized user
  const pendingAuthorizedUser = !!(
    data?.getPendingInvitations && data?.getPendingInvitations.length > 0
  );

  // Step 1
  const hasProject = (data?.getProjects?.projects.length ?? 0) > 0 ?? false;

  // Step 2
  const hasZenaCard = data?.getCompany?.tier === 'luxe';

  // Step 3
  const hasLinkedAccounts = (data?.getCorporateCards?.length ?? 0) > 0 ?? false;

  // Step 4
  const hasTransactions = (data?.getAuthorizationsAndTransactions?.meta?.total ?? 0) > 0 ?? false;

  // Step 5
  const hasInvitedTeammates = pendingAuthorizedUser || hasMultipleTeamMembers;

  const allDone = hasProject && hasZenaCard && hasInvitedTeammates;

  return {
    hasProject,
    hasZenaCard,
    hasLinkedAccounts,
    hasTransactions,
    hasInvitedTeammates,
    stepsComplete:
      (hasProject ? 1 : 0) +
      (hasZenaCard ? 1 : 0) +
      // TODO - This will be added back in at a later time
      // (hasLinkedAccounts ? 1 : 0) +
      // (hasTransactions ? 1 : 0) +
      (hasInvitedTeammates ? 1 : 0),
    allDone,
    loading,
    error,
  };
};

const GET_ADMIN_APPROVALS = gql(`
query GetAdminApprovalRequestsForUserStatus($customerId: String!) {
  getAdminApprovalRequests(customerId: $customerId) {
    id
  }
}
`);

export const useGetAdminApprovalCount = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);
  const { data, loading, error } = useQuery(GET_ADMIN_APPROVALS, {
    variables: {
      customerId: activeCompanySlug,
    },
    skip: !admin,
  });

  return {
    approvals: data?.getAdminApprovalRequests.length ?? 0,
    loading,
    error,
  };
};

const GET_TRANSACTIONS = gql(`
query GetTransactionsAndAuthorizationsForUserStatus($customerId: String!) {
  getAuthorizationsAndTransactions(customerId: $customerId, pageNumber: 0, filters: {
    onlyMissingData: true
  }) {
    meta {
      total
    }
  }
}
`);

export const useGetTransactionsCount = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { data, loading, error } = useQuery(GET_TRANSACTIONS, {
    variables: {
      customerId: activeCompanySlug,
    },
  });

  return {
    transactions: data?.getAuthorizationsAndTransactions?.meta?.total ?? 0,
    loading,
    error,
  };
};

import { useState } from 'react';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import TransactionImportAccountsTable from './TransactionImportAccountsTable';
import { RoundButton } from 'components/atoms/Buttons';
import { AddAccountModal } from 'components/widgets/AddAccountModal';

const TransactionImportAccountsSection = ({ companySlug }: { companySlug: string }) => {
  const [showConnectAccountsModal, setShowConnectAccountsModal] = useState<boolean>(false);

  const handleConnectAccounts = () => {
    setShowConnectAccountsModal(true);
  };

  return (
    <div className="flex flex-col lg:flex-row mt-8 mb-8">
      <div className="bg-white border-primary-400 border rounded-3xl p-8 w-full">
        <div className="flex flex-row justify-between items-center">
          <CardTitle>Accounts linked for importing transactions</CardTitle>
          <RoundButton variant="secondary" icon="plus" onClick={handleConnectAccounts}>
            Link account
          </RoundButton>
        </div>
        <TransactionImportAccountsTable companySlug={companySlug} />
      </div>
      <AddAccountModal
        isOpen={showConnectAccountsModal}
        onClose={() => setShowConnectAccountsModal(false)}
      />
    </div>
  );
};

export default TransactionImportAccountsSection;

import { AuthorizedUserInvitationForm } from './AuthorizedUserInvitationForm';
import { AuthorizedUsersCollection } from './AuthorizedUsersCollection';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { useActiveCompany } from '../../../../providers/ActiveCompany';

export const TeamMembers = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  return (
    <OutlinedWidget>
      <div className="border-b pb-4 min-w-64 text-graphite text-xl">Team</div>
      <div className="flex mt-12">
        <div className="flex-1 w-1/3 pr-12">
          <div className="font-bold mb-2">Team members</div>
          <div className="text-gray-500">
            Add/remove team members and modify user roles and privileges.
          </div>
        </div>
        <div className="flex-2 w-2/3">
          <div>
            <AuthorizedUserInvitationForm customerId={activeCompanySlug} />
            <AuthorizedUsersCollection customerId={activeCompanySlug} />
          </div>
        </div>
      </div>
    </OutlinedWidget>
  );
};

import { Month } from '__generated__/graphql';

export const getMonthShortNameFromMonthEnum = (month: Month) => {
  switch (month) {
    case Month.january:
      return 'Jan';
    case Month.february:
      return 'Feb';
    case Month.march:
      return 'Mar';
    case Month.april:
      return 'Apr';
    case Month.may:
      return 'May';
    case Month.june:
      return 'Jun';
    case Month.july:
      return 'Jul';
    case Month.august:
      return 'Aug';
    case Month.september:
      return 'Sep';
    case Month.october:
      return 'Oct';
    case Month.november:
      return 'Nov';
    case Month.december:
    default:
      return 'Dec';
  }
};

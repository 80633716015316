import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { CustomerTeamMember, Invitation } from '__generated__/graphql';
import useCustomAuth from 'hooks/useCustomAuth';
import { useEffect, useState } from 'react';
import { AuthorizedUserCard } from './AuthorizedUserCard';
import PendingInviteCard from './PendingInviteCard';

const QUERY_GET_CUSTOMER_TEAM_MEMBERS = gql(`
    query GetCustomerTeamMembers($customerId:String!){
      getCustomerTeamMembers(customerId:$customerId) {
        userId
        fullName {
          first
          last
        }
        email
        signedProfilePhotoUrl
        role
      }
    }
`);

const GET_PENDING_INVITATIONS_QUERY = gql(`
  query GetPendingInvitations($customerId: String!)  {
    getPendingInvitations(customerId: $customerId) {
      email
      companySlug
      status
      role
    }
  }
`);

export const AuthorizedUsersCollection = ({ customerId }: { customerId: string }) => {
  const { user } = useCustomAuth();
  const [sortedTeamMembers, setSortedTeamMembers] = useState<CustomerTeamMember[]>([]);
  const { data: getPendingInvitationsData } = useQuery(GET_PENDING_INVITATIONS_QUERY, {
    variables: { customerId },
  });
  const {
    loading: customerTeamMembersLoading,
    error: customerTeamMembersError,
    data: customerTeamMembersData,
  } = useQuery(QUERY_GET_CUSTOMER_TEAM_MEMBERS, {
    variables: {
      customerId,
    },
  });

  useEffect(() => {
    const teamMembers = customerTeamMembersData?.getCustomerTeamMembers;
    if (teamMembers) {
      setSortedTeamMembers(
        [...teamMembers].sort((a: CustomerTeamMember, b: CustomerTeamMember) => {
          if (a.userId === user?.attributes?.sub) {
            return -1;
          } else {
            const lastNameCompare = (a.fullName.last || '').localeCompare(b.fullName.last || '');
            return lastNameCompare === 0
              ? (a.fullName.first || '').localeCompare(b.fullName.first || '')
              : lastNameCompare;
          }
        })
      );
    }
  }, [customerTeamMembersData]);

  if (customerTeamMembersLoading) return <p>Loading...</p>;
  if (customerTeamMembersError) return <p>Error &#x1F614; {customerTeamMembersError?.message}</p>;

  return (
    <div className="w-full">
      {getPendingInvitationsData?.getPendingInvitations &&
        getPendingInvitationsData?.getPendingInvitations.map((item: Invitation, index: number) => {
          return (
            <PendingInviteCard
              key={`${item.companySlug}-${item.email}`}
              customerId={customerId}
              email={item?.email || ''}
            />
          );
        })}
      {sortedTeamMembers.map((item: CustomerTeamMember, index: number) => {
        return (
          <AuthorizedUserCard
            key={item.userId}
            customerId={customerId}
            name={`${item.fullName.first} ${item.fullName.last}` || ''}
            email={item.email || ''}
            userId={item.userId}
            signedProfilePhotoUrl={item.signedProfilePhotoUrl || ''}
            role={item.role}
          />
        );
      })}
    </div>
  );
};

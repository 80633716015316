import { CompanyTier } from '__generated__/graphql';
import { useCompany } from 'hooks/useCompany';
import { useAppState } from 'stores/UserStore';
import { useGetZenaCompanyName, useUpdateCompanyName } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { UnitCompanyName } from './UnitCompanyName';
import SettingsForm from 'components/molecules/SettingsForm';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { useState } from 'react';
import { onApolloError } from 'utils';
import { ApolloError } from '@apollo/client';
import { useActiveCompany } from 'providers/ActiveCompany';

export const SettingsCompanyName = () => {
  const [newCompanyName, setNewCompanyName] = useState<string>('');
  const [newCompanyNameError, setNewCompanyNameError] = useState<string>('');
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [setSuccessMsg, setErrorMsg] = useAppState((state) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const { company: zenaCompany, loading: zenaCompanyNameLoading } = useGetZenaCompanyName({
    companySlug: activeCompanySlug,
    onSuccess: () => {
      setNewCompanyName(zenaCompany.name ?? '');
    },
  });
  const { company } = useCompany({
    companySlug: activeCompanySlug,
  });

  const { updateCompanyName } = useUpdateCompanyName({
    companySlug: activeCompanySlug,
    companyName: newCompanyName,
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMsg, ['UpdateCompanyError']);
    },
    onSuccess: () => {
      setSuccessMsg('Company name updated.');
    },
  });

  const isLuxeCompany = company?.tier === CompanyTier.luxe;

  const onSubmit = () => {
    if (!newCompanyName) {
      setNewCompanyNameError('Company name is required');
      return false;
    }
    updateCompanyName();
    setNewCompanyNameError('');
  };

  const onCancel = () => {
    setNewCompanyNameError('');
    setNewCompanyName(zenaCompany.name ?? '');
  };

  return (
    <SettingsForm formTitle="Company details" onSubmit={onSubmit} onCancel={onCancel}>
      {({ editing }) => (
        <div className="flex gap-24 mt-8 mb-4">
          <p className="whitespace-nowrap">Company Name</p>
          <div className="flex flex-col gap-12 w-full">
            <div className="flex">
              <div>
                <div className="font-semibold">Company display name</div>
                <div className="text-gray-500">
                  This is how your company name will be displayed within your Zena Account.
                </div>
              </div>
              <div className="ml-auto mr-24">
                {editing ? (
                  <TextInput
                    label=""
                    value={newCompanyName}
                    onChange={(event) => setNewCompanyName(event.target.value)}
                    className="bg-white"
                    hasError={!!newCompanyNameError}
                    errorMessage={newCompanyNameError}
                    testId="companyNameInput"
                  />
                ) : zenaCompanyNameLoading ? (
                  <div className="w-64">
                    <RectangleSkeleton />
                  </div>
                ) : (
                  zenaCompany.name
                )}
              </div>
            </div>
            {isLuxeCompany && <UnitCompanyName editing={editing} />}
          </div>
        </div>
      )}
    </SettingsForm>
  );
};

import { useActiveCompany } from 'providers/ActiveCompany';
import { useProjectCategories } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { TransactionTagSelection } from '../TransactionTagSelection';

export const SelectProjectCategories = ({
  categories,
  onChangeCategories,
  projectId,
  className = '',
}: {
  categories: string[];
  onChangeCategories: (categories: string[]) => void;
  projectId: string;
  className?: string;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { categories: allProjectCategories, loading } = useProjectCategories({
    companySlug: activeCompanySlug,
    projectId,
  });

  if (loading) {
    return <RectangleSkeleton width="full" height="large" />;
  }

  return (
    <TransactionTagSelection
      onSelectTag={(tag) => {
        onChangeCategories([...categories, tag]);
      }}
      tags={allProjectCategories}
      selectedTags={categories}
      onRemoveTag={(tagName) =>
        onChangeCategories(categories.filter((category) => category !== tagName))
      }
      tagColor="bg-accent-300"
      placeholder="Select project categories"
      className={className}
      disabled={!projectId}
    />
  );
};

import { Meta, Transaction } from '__generated__/graphql';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { TableHead } from 'components/atoms/table/TableHead';
import { SearchInput } from 'components/molecules/SearchInput';
import { PageLimit, Pagination } from 'components/atoms/Pagination';
import React from 'react';
import { FiltersState, TransactionFilterDropdown } from '../TransactionFilters';
import { useActiveCompany } from 'providers/ActiveCompany';
import { QboLogo } from 'icons/qboLogo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cn } from 'utils';
import { useIntuitAppConnectionStatus } from './data';
import { TableBody } from './TableBody';

export const TableContainer = <T extends Transaction>({
  loading,
  showSearch,
  showPagination,
  meta,
  selectedTransactionId,
  data,
  errorMessage,
  overrides,
  totalPages,
  currentPageIndex,
  pageLimit,
  setPageLimit,
  onClickTransactionRow,
  onPrevious,
  onNext,
  className,
  children,
  showTransactionFiltersDropdown,
  showFilters,
  onChangeFilters,
  onChangeSearchTerm,
}: {
  loading: boolean;
  showFilters: boolean;
  showSearch: boolean;
  showPagination: boolean;
  meta?: Meta;
  selectedTransactionId?: string;
  data: T[];
  errorMessage?: string;
  overrides?: Object;
  totalPages?: number;
  currentPageIndex?: number;
  pageLimit?: PageLimit;
  onClickTransactionRow?: (transactionId: string) => void;
  onPrevious?: () => void;
  onNext?: () => void;
  setPageLimit: (limit: PageLimit) => void;
  className?: string;
  children?: React.ReactNode;
  showTransactionFiltersDropdown?: boolean;
  onChangeFilters?: (filters: FiltersState) => void;
  onChangeSearchTerm?: (value: string) => void;
}) => {
  const { transactionTableQboColumn } = useFlags();
  const { activeCompany } = useActiveCompany();
  const { isIntuitAppConnectedAndActive } = useIntuitAppConnectionStatus({
    companySlug: activeCompany?.slug || '',
  });

  const showQboColumn = !!isIntuitAppConnectedAndActive && transactionTableQboColumn;

  return (
    <div className={cn('w-full', className)}>
      <div className={`mb-8 grid gap-4 lg:flex lg:justify-between`}>
        <div className="grid gap-4 lg:flex grow">
          <div className="flex w-full lg:max-w-[500px]">
            {showSearch && onChangeSearchTerm && (
              <SearchInput
                callbackFn={(v: string) => {
                  onChangeSearchTerm(v);
                }}
                placeholder="Search merchant, amount, card number"
              />
            )}
          </div>
          {showTransactionFiltersDropdown && showFilters && onChangeFilters && (
            <TransactionFilterDropdown onChange={onChangeFilters} />
          )}
        </div>
        {showPagination && (
          <Pagination
            pageLimit={pageLimit ?? 30}
            onChangePageLimit={(newPageLimit) => setPageLimit(newPageLimit)}
            totalPages={totalPages ?? 0}
            currentPage={(currentPageIndex ?? 0) + 1}
            onPreviousPage={() => {
              onPrevious?.();
            }}
            onNextPage={() => {
              onNext?.();
            }}
          />
        )}
      </div>

      <div className="">
        {children ? (
          children
        ) : (
          <TableWithPagination loading={loading} meta={meta}>
            <TableHead className="font-normal text-black">
              <td>Date</td>
              <td>Merchant</td>
              {showQboColumn && (
                <td className="flex gap-4">
                  <QboLogo />
                  QBO Sync
                </td>
              )}
              <td className="hidden lg:table-cell">Assigned</td>
              <td className="hidden lg:table-cell">Source</td>
              <td>Amount</td>
            </TableHead>
            <tbody>
              <TableBody
                loading={loading}
                data={data}
                selectedTransactionId={selectedTransactionId}
                onClickTransactionRow={onClickTransactionRow}
                errorMessage={errorMessage}
                overrides={overrides}
                qboActive={showQboColumn}
              />
            </tbody>
          </TableWithPagination>
        )}
      </div>
      <div className="flex mb-12">
        <div className="ml-auto">
          {showPagination && (
            <Pagination
              pageLimit={pageLimit ?? 30}
              onChangePageLimit={(newPageLimit) => setPageLimit(newPageLimit)}
              totalPages={totalPages ?? 0}
              currentPage={(currentPageIndex ?? 0) + 1}
              onPreviousPage={() => {
                onPrevious?.();
              }}
              onNextPage={() => {
                onNext?.();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const getPath = (url: string) => {
  const splitBySlash = url.split('/');
  if (splitBySlash.length > 1) {
    return splitBySlash[1];
  }
  if (splitBySlash.length === 1) {
    return splitBySlash[0];
  }
  return '';
};

export const routesEnabledForLite = ['projects', 'settings'];

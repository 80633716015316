import { loadVGSCollect } from '@vgs/collect-js';
import { Button } from 'components/atoms/Buttons';
import { error } from 'console';
import { stat } from 'fs';
import { getCustomerTokenFromLocalStorage } from 'hooks/access';
import { useEffect, useState } from 'react';

export interface ActivateFormProps {
  cardId: string;
  onClose: Function;
}

export interface FormResponse {
  submit: Function;
}

export interface ValidationErrorMessage {
  errorMessages: Array<string>;
}

export interface ValidationErrors {
  expirationDate?: ValidationErrorMessage;
  cvv2?: ValidationErrorMessage;
}

export interface ActivationError {
  status: number;
  title: string;
}

declare global {
  interface Window {
    VGSCollect: {
      create: Function;
    };
  }
}

const css = {
  lineHeight: '1.5em',
  fontSize: '14px',
  fontWeight: '200',
  border: 'none',
  height: '24px',
  width: '50%',
  color: '#31325F',
  '&::placeholder': {
    color: '#CFD7E0',
  },
};

const ActivateForm = ({ cardId, onClose }: ActivateFormProps) => {
  const [form, setForm] = useState<FormResponse>();
  const [expirationError, setExpirationError] = useState('');
  const [cvvError, setCVVError] = useState('');
  const [activationError, setActivationError] = useState('');

  const localCustomerToken = getCustomerTokenFromLocalStorage();

  const getValidationErrors = (errors: ValidationErrors) => {
    if (errors['expirationDate']) {
      setExpirationError('Expiration Date ' + errors['expirationDate'].errorMessages[0]);
    } else {
      setExpirationError('');
    }
    if (errors['cvv2']) {
      setCVVError('CVV2 ' + errors['cvv2'].errorMessages[0]);
    } else {
      setCVVError('');
    }
  };

  const getActivationErrors = (errors: Array<ActivationError>) => {
    const transformed = errors.map((e) => {
      if (e.status == 400 && e.title.includes('Information provided did not match')) {
        return 'Information does not match; update and try again.';
      } else {
        return 'Sorry! Something went wrong and your card was not activated. Try again later';
      }
    });
    setActivationError(transformed.join('\n'));
  };

  const onSubmit = () => {
    if (form) {
      form.submit(
        '/cards/' + cardId + '/activate',
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Authorization: 'Bearer ' + localCustomerToken,
          },
          data: (formValues: any) => {
            return {
              data: {
                attributes: {
                  expirationDate: formValues['expirationDate'],
                  cvv2: formValues['cvv2'],
                },
              },
            };
          },
        },
        (status: number, response: any) => {
          if (status != 200) {
            getActivationErrors(response.errors);
          } else {
            onClose();
          }
        },
        (validationErrors: ValidationErrors) => {
          // runs pre-submit based on 'validations'
          getValidationErrors(validationErrors);
        }
      );
    }
  };

  useEffect(() => {
    const loadForm = async () => {
      const vgs_collect = await loadVGSCollect({
        vaultId: process.env.REACT_APP_VGS_SHOW_ENV_KEY || '',
        environment: process.env.REACT_APP_VGS_SHOW_ENV || '',
        version: '2.21.0',
      }).catch((e) => {
        console.log('error loading VGSCollect', e);
      });
      if (
        !document.getElementById('card-cvc')?.hasChildNodes() &&
        !document.getElementById('card-expiry')?.hasChildNodes()
      ) {
        initForm(vgs_collect);
      }
    };

    loadForm();
  }, []);

  const initForm = (vgs_collect: any) => {
    const form = vgs_collect.init();
    form.field('#card-cvc', {
      type: 'card-security-code',
      name: 'cvv2',
      successColor: '#4F8A10',
      errorColor: '#D8000C',
      placeholder: 'Enter CVV2',
      maxLength: 3,
      validations: ['required', 'validCardSecurityCode'],
      css,
    });
    form.field('#card-expiry', {
      type: 'card-expiration-date',
      name: 'expirationDate',
      successColor: '#4F8A10',
      errorColor: '#D8000C',
      placeholder: 'MM / YYYY',
      validations: ['required', 'validCardExpirationDate'],
      autoComplete: 'cc-exp',
      yearLength: 4,
      // serializer included from Unit docs with VGS
      serializers: [form.SERIALIZERS.replace('(\\d{2})\\s\\/\\s(\\d{4})', '$2-$1')],
      css,
    });
    setForm(form);
  };

  return (
    <>
      <div className="flex gap-3 mb-3">
        <div className="w-1/2">
          <div className="flex"></div>
          <div className={'flex flex-col px-4 pt-2 pb-1.5 rounded-lg border border-marble h-16'}>
            <label className={`text-sm mb-1 text-asphalt`}>Expiration Date</label>
            <span id="card-expiry" />
          </div>
          {expirationError && <div className="mt-2 text-rustic">{expirationError}</div>}
        </div>
        <div className="w-1/2">
          <div className={'flex flex-col px-4 pt-2 pb-1.5 rounded-lg border border-marble h-16'}>
            <label className={`text-sm mb-1 text-asphalt`}>CVV2</label>
            <span id="card-cvc" />
          </div>
          {cvvError && <div className="mt-2 text-rustic">{cvvError}</div>}
        </div>
      </div>
      {activationError && <div className="mt-2 text-rustic">{activationError}</div>}
      <Button className="mt-8 w-full" onClick={onSubmit}>
        Proceed
      </Button>
    </>
  );
};

export default ActivateForm;

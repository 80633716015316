import { useState } from 'react';
import { Modal } from 'components/atoms/Modal';
import { AllocateTransactions } from '.';
import { cn } from 'utils';
import { AddAccountModal } from '../../widgets/AddAccountModal';
import { AllocateTransactionsStateProvider } from './state';

export const AllocateTransactionsModal = ({
  isOpen,
  onClose,
  onCompleteAllocationOfTransactions,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCompleteAllocationOfTransactions?: () => void;
}) => {
  const [addAccountModalOpen, setAddAccountModalOpen] = useState(false);
  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        className={cn(
          'absolute top-0 left-0 h-screen rounded-none md:rounded-none',
          'w-screen max-w-[100vw] sm:w-screen sm:max-w-[100vw]',
          'xl:w-[80vw] xl:max-w-[1280px] xl:h-[80vh] xl:max-h-[1020px] xl:rounded-lg xl:static',
          'overflow-hidden'
        )}
        title="Let's organize these transactions"
      >
        <div className={cn('flex flex-col h-full w-full overflow-hidden')}>
          <section className="grow overflow-y-auto ">
            <AllocateTransactionsStateProvider>
              <AllocateTransactions
                onCompleteAllocationOfTransactions={onCompleteAllocationOfTransactions}
                openAddAccountModal={() => {
                  onClose();
                  setAddAccountModalOpen(true);
                }}
                disableUrlQuerySerialization
                disableTransactionDetailsDrawer
              />
            </AllocateTransactionsStateProvider>
          </section>
        </div>
      </Modal>
      <AddAccountModal isOpen={addAccountModalOpen} onClose={() => setAddAccountModalOpen(false)} />
    </>
  );
};

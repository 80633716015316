import { RoundButton } from 'components/atoms/Buttons';
import { CardPreview } from 'components/atoms/CardPreview';
import { Link } from 'react-router-dom';

type FinishStepProps = {
  cardLast4Digits?: string;
  cardName: string;
  cardType: 'physicalCard' | 'virtualCard';
  cardId?: string;
  isAdmin: boolean;
  referringPage: '/cards' | '/cards-management';
};

const FinishStep = ({
  cardLast4Digits,
  cardName,
  cardType,
  cardId,
  isAdmin,
  referringPage,
}: FinishStepProps) => {
  const newCardSvg = (
    <CardPreview cardName={cardName} cardType={cardType} last4Digits={cardLast4Digits || ''} />
  );

  return (
    <>
      <div className="text-center flex flex-col items-center">
        <div className="grid place-items-center">{newCardSvg}</div>
        <h1 className="mt-16">
          {isAdmin
            ? `${cardType === 'physicalCard' ? "We're printing your card" : 'Your card is ready'}`
            : 'Card request has been sent for approval'}
        </h1>
        <p className="text-asphalt mt-6">
          {isAdmin
            ? `${
                cardType === 'physicalCard'
                  ? 'Your physical card will arrive in 4-7 business days. Need to spend now? Create a virtual card to use right away.'
                  : 'You may now access and manage your new virtual card.'
              }`
            : "We'll notify you as soon as we hear back."}
        </p>
        <div className="mt-8">
          <Link
            to={isAdmin && cardId ? `${referringPage}/${cardId}` : referringPage}
            aria-label="View card"
          >
            <RoundButton text={isAdmin ? 'View card' : 'Back to cards'} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default FinishStep;

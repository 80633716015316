import { RoundedButtonProps } from 'components/atoms/Buttons/RoundButton/RoundButton';
import { useAppState } from '../../../stores/UserStore';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { useRevokeIntuitAppConnection } from 'components/widgets/Quickbooks';
import { RoundButton } from 'components/atoms/Buttons';

export const DeactivateQuickbooksAccountButton = (props: RoundedButtonProps) => {
  const [setSuccessMsg] = useAppState((state) => [state.setSuccessMsg]);
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';

  const { revokeIntuitAppConnection, loading: deactivationLoading } =
    useRevokeIntuitAppConnection();

  return (
    <RoundButton
      variant="danger"
      isLoading={deactivationLoading}
      onClick={() =>
        revokeIntuitAppConnection({
          variables: {
            companySlug,
          },
          onCompleted: () => setSuccessMsg(`Success! Your QBO integration is now deactivated.`),
          onError: (error) => setErrorMsg(error.message),
          refetchQueries: ['GetIntuitAppConnection'],
        })
      }
      {...props}
    >
      Deactivate
    </RoundButton>
  );
};

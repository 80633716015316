import { PageTitle } from 'components/atoms/PageTitle';
import {
  LayoutRouteProps,
  Navigate,
  NavigateProps,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { CompanyTab } from './tabs/CompanyTab';
import { Fragment, ReactNode, Suspense, useState } from 'react';
import { useFlags } from '../../providers/FeatureFlags';
import { ZenaRoute } from '../../providers/ZenaRoute';
import { RequireAuth } from '../../providers/RequireAuth';
import { RequireActiveCompany } from '../../providers/RequireActiveCompany';
import { RequireRepaymentConnection } from '../../providers/RequireRepaymentConnection';
import { Tab } from '@headlessui/react';
import { cn } from '../../utils';
import { SettingsTab } from './tabs/SettingsTab';
import BankSettings from './tabs/BankSettingsTab';
import { IntegrationLayout } from './tabs/IntegrationsTab';
import { MainIntegrations } from './tabs/IntegrationsTab/MainIntegrations';
import { ConfigureAutomation } from './tabs/IntegrationsTab/ConfigureAutomation';
import { QboIntegration } from './tabs/IntegrationsTab/QboIntegration';
import { TabPanelLayout } from './tabs/Layout';
import { useActiveCompany } from '../../providers/ActiveCompany';
import useCustomAuth from '../../hooks/useCustomAuth';
import { useQuery } from '@apollo/client';
import { QUERY_GET_CUSTOMER_TEAM_MEMBERS } from './data';
import { companyRole } from 'hooks/access';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Notifications } from 'providers/notifications';

const LoadingComponent = () => (
  <>
    <PageTitle hidden text="Loading settings..." />
    <div className="grid place-items-center mt-52">
      <div className="flex items-center">
        <LoadingSpinner /> <p className="ml-2">Loading ...</p>
      </div>
    </div>
  </>
);
const Error = () => {
  return (
    <div className="h-full w-full relative px-2 sm:px-12">
      <PageTitle text="Settings" />
      <p>Error unable to load settings. Reload and try again.</p>
    </div>
  );
};
const SettingsLayout = ({
  filteredTabs,
  defaultTabIdx,
}: {
  filteredTabs: TabBase[];
  defaultTabIdx?: number;
}) => {
  const { activeCompany } = useActiveCompany();
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split('/').filter(Boolean);

  const activeCompanySlug = activeCompany?.slug ?? '';
  const role = companyRole(activeCompanySlug);
  const filterTabsByRole = filteredTabs.filter((tab) => role && tab.roles?.includes(role));

  const [currentTabIdx, setCurrentTabIdx] = useState(defaultTabIdx || 0);

  if (paths.length === 1 && paths[0] === 'settings') {
    return <Navigate to={`${location.pathname}/${filterTabsByRole[0].slug}`} />;
  }

  return (
    <div className="w-full h-fit relative px-2 sm:px-12 sm:mb-56 mt-10">
      <PageTitle text="Settings" hidden />
      <h1>Settings</h1>
      <Tab.Group
        selectedIndex={currentTabIdx}
        onChange={(v) => {
          setCurrentTabIdx(v);
          navigate(filterTabsByRole[v].slug);
        }}
        defaultIndex={defaultTabIdx}
      >
        <Tab.List>
          <div className="border-b-2 border-gray-200">
            <div className="-mb-px flex gap-4" aria-label="Tabs">
              {filterTabsByRole.map((tab) => (
                <Tab as={Fragment} key={`tab-${tab.slug}`}>
                  {({ selected }) => (
                    <a
                      className={cn(
                        'hover:border-primary hover:text-gray-700 border-b-2 pb-1 pt-4 px-1 text-center cursor-pointer',
                        selected
                          ? 'border-primary text-black font-semibold'
                          : 'border-transparent text-marble'
                      )}
                      aria-current={selected ? 'page' : 'false'}
                    >
                      {tab.title}
                    </a>
                  )}
                </Tab>
              ))}
            </div>
          </div>
          {currentTabIdx === 2 && <Notifications showActionButtons={false} />}
        </Tab.List>
        <Tab.Panels>
          <TabPanelLayout>
            <Outlet />
          </TabPanelLayout>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

interface TabBase {
  title: string;
  slug: string;
  roles?: string[];
  featureFlag?: string;
}

const tabs: TabBase[] = [
  {
    title: 'Company & Team',
    slug: 'company',
    roles: ['admin'],
  },
  {
    title: 'Settings',
    slug: 'settings',
    roles: ['admin', 'bookkeeper'],
  },
  {
    title: 'Bank Accounts',
    slug: 'bank-accounts',
    roles: ['admin'],
  },
  {
    title: 'Integrations',
    slug: 'integrations',
    roles: ['admin', 'bookkeeper'],
  },
];

export const SettingsRoutes = (props: LayoutRouteProps) => {
  const flags = useFlags();
  const location = useLocation();
  const paths = location.pathname.split('/').filter(Boolean);

  const filteredTabs = tabs.filter((tab) => (tab.featureFlag ? flags[tab.featureFlag] : true));
  const tabExists = filteredTabs.findIndex((tab) => tab.slug === paths[1]);

  return (
    <Route
      element={
        <ZenaRoute>
          <RequireAuth>
            <RequireActiveCompany>
              <RequireRepaymentConnection>
                {/* restrict all settings routes to the admin or bookkeeper permissions */}
                <ProtectedSetting roles={['admin', 'bookkeeper']} redirectTo="/">
                  <SettingsLayout
                    filteredTabs={filteredTabs}
                    defaultTabIdx={tabExists === -1 ? 0 : tabExists}
                  />
                </ProtectedSetting>
              </RequireRepaymentConnection>
            </RequireActiveCompany>
          </RequireAuth>
        </ZenaRoute>
      }
      {...props}
    >
      <Route
        index
        element={
          <ProtectedSetting roles={['admin']} redirectTo="/settings">
            <CompanyTab />
          </ProtectedSetting>
        }
      />

      <Route
        path="company"
        element={
          // restrict the company tab to the admin role only - overriding the default permissions
          <ProtectedSetting roles={['admin']} redirectTo="/settings">
            <CompanyTab />
          </ProtectedSetting>
        }
      />
      <Route path="settings" element={<SettingsTab />} />
      <Route
        path="bank-accounts"
        element={
          <Suspense fallback={<></>}>
            <BankSettings />
          </Suspense>
        }
      />
      <Route path="integrations" element={<IntegrationLayout />}>
        <Route index element={<MainIntegrations />} />
        <Route path="qbo">
          <Route index element={<QboIntegration />} />
          <Route path="configure" element={<ConfigureAutomation />} />
        </Route>
      </Route>
    </Route>
  );
};
export default SettingsLayout;

const ProtectedSetting = ({
  roles,
  redirectTo = '/',
  children,
  ...props
}: {
  roles: string[];
  redirectTo: string;
  children: ReactNode;
} & Omit<NavigateProps, 'to'>) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { user } = useCustomAuth();

  const { data: customerTeamMembersData, error: customerTeamMembersError } = useQuery(
    QUERY_GET_CUSTOMER_TEAM_MEMBERS,
    {
      variables: {
        customerId: activeCompanySlug,
      },
    }
  );

  if (customerTeamMembersError) {
    const isPermissionsError = customerTeamMembersError.message.includes('permissions');
    if (isPermissionsError) {
      return <Error />;
    }
  }

  const teamMembers = customerTeamMembersData?.getCustomerTeamMembers;
  if (!teamMembers) {
    return <LoadingComponent />;
  }
  const userId = user?.attributes?.sub;

  const you = teamMembers.find((tm) => tm?.userId === userId);

  if (!you) {
    return <LoadingComponent />;
  }

  const hasPermission = roles.some((role) => you.role === role);
  if (!hasPermission) {
    return <Navigate to={redirectTo} replace {...props} />;
  }

  return <>{children}</>;
};

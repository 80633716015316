import { Button, RoundButton } from 'components/atoms/Buttons';
import React, { cloneElement, FormHTMLAttributes, ReactElement, useState } from 'react';
import { SettingsCard } from '../../pages/(project)/ProjectSettings/Layout';
import { Ban, Pencil } from 'lucide-react';

interface SettingsFormProps extends Omit<FormHTMLAttributes<HTMLFormElement>, 'children'> {
  formTitle: string;
  children: JSX.Element | ((props: any) => JSX.Element);
  onSubmit: (e: React.FormEvent<any>) => any;
  onCancel?: Function;
}

const SettingsForm = ({ formTitle, children, onCancel, onSubmit }: SettingsFormProps) => {
  const [editing, setEditing] = useState(false);

  const handleCancel = () => {
    if (onCancel && editing) {
      onCancel();
    }
    setEditing(!editing);
  };

  const handleSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    // onSubmit() returns false if there is an error
    if (onSubmit(e) !== false) {
      setEditing(!editing);
    }
  };

  return (
    <SettingsCard editing={editing}>
      <div className="flex justify-between border-graphite border-b pb-4 mb-4">
        <h3 className="text-graphite text-xl">{formTitle}</h3>
        <button type="button" className="p-4 m-[-1rem]" onClick={handleCancel}>
          {editing ? <Ban /> : <Pencil />}
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <div className="flex flex-col">
          {typeof children === 'function'
            ? (children as (props: any) => JSX.Element)({ editing })
            : cloneElement(children as ReactElement, { editing })}
          {editing && (
            <div className="flex gap-4 justify-end items-center w-full mt-6">
              <Button
                className="font-medium p-4 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  handleCancel();
                }}
                variant="ghost"
              >
                Cancel
              </Button>
              <RoundButton variant="primary" text="Save" type="submit" />
            </div>
          )}
        </div>
      </form>
    </SettingsCard>
  );
};

export default SettingsForm;

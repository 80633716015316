import { Button } from 'components/atoms/Buttons';
import { DateRangeCalendar, DateRangeValue } from './DateRangeCalendar';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { cn } from 'utils';

export const DateRange = ({
  value,
  onChange,
  minDate,
  maxDate,
  onClose,
}: {
  onChange: (value: DateRangeValue) => void;
  value: DateRangeValue;
  minDate?: Date;
  maxDate?: Date;
  onClose: () => void;
  className?: string;
}) => {
  const setDateToStartOfDay = (date: Date): Date => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  };

  const setDateToEndOfDay = (date: Date): Date => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return date;
  };

  const previousMonday = useMemo(() => {
    let date = new Date();
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return setDateToStartOfDay(date);
  }, []);

  const firstDayOfTheCurrentMonth = useMemo(() => {
    const date = new Date(new Date().setDate(1));
    return setDateToStartOfDay(date);
  }, []);

  const ninetyDaysAgo = useMemo(() => {
    const date = new Date(new Date().setDate(new Date().getDate() - 90));
    return setDateToStartOfDay(date);
  }, []);

  const oneYearAgo = useMemo(() => {
    const date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    return setDateToStartOfDay(date);
  }, []);

  const todayEndOfDay = useMemo(() => {
    let date = new Date();
    return setDateToEndOfDay(date);
  }, []);

  return (
    <div className="md:flex flex-col justify-between items-start py-4 pl-4 pr-8">
      <div className="grid gap-0">
        <StyledButton
          className="text-[#293050]"
          onClick={() => {
            onChange([setDateToStartOfDay(new Date()), todayEndOfDay]);
          }}
        >
          Today
        </StyledButton>
        <StyledButton
          className="text-[#293050]"
          onClick={() => {
            onChange([previousMonday, todayEndOfDay]);
          }}
        >
          This week
        </StyledButton>
        <StyledButton
          className="text-[#293050]"
          onClick={() => {
            onChange([firstDayOfTheCurrentMonth, todayEndOfDay]);
          }}
        >
          This month
        </StyledButton>
        <StyledButton
          className="text-[#293050]"
          onClick={() => {
            onChange([ninetyDaysAgo, todayEndOfDay]);
          }}
        >
          Last 90 days
        </StyledButton>
        <StyledButton
          className="text-[#293050]"
          onClick={() => {
            onChange([oneYearAgo, todayEndOfDay]);
          }}
        >
          Last 12 months
        </StyledButton>
      </div>
      <StyledButton
        className="text-accent-800 font-semibold"
        onClick={() => {
          onChange([null, null]);
          onClose();
        }}
      >
        Cancel
      </StyledButton>

      <div>
        <DateRangeCalendar
          value={value}
          onChange={(values) => {
            onChange(values);
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

const StyledButton = ({
  children,
  className = '',
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Button
      className={cn('px-4 py-2 justify-start hover:bg-accent-200', className)}
      variant="ghost"
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

import Calendar from 'react-calendar';
import { Range } from 'react-calendar/dist/cjs/shared/types';
import './calendar.css';

export type DateRangeValue = Range<Date | null>;

export const DateRangeCalendar = ({
  value,
  onChange,
  minDate,
  maxDate,
}: {
  onChange: (value: DateRangeValue) => void;
  value: DateRangeValue;
  minDate?: Date;
  maxDate?: Date;
}) => {
  return (
    <Calendar
      selectRange
      value={value}
      onChange={(newValue, event) => {
        if (newValue) {
          onChange(newValue as DateRangeValue);
        }
      }}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

import { CustomerTeamMember, ProjectTeamMember } from '__generated__/graphql';
import {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  MUTATION_ADD_TEAM_MEMBER,
  MUTATION_REMOVE_TEAM_MEMBER,
  QUERY_GET_ALL_PROJECT_TEAM_MEMBERS,
} from './gql';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';

export interface FilterContext {
  loading: boolean;
  projectTeamMembers: (ProjectTeamMember | CustomerTeamMember)[];
  addTeamMember: (member: TeamMember, options?: RequestOptions) => void;
  removeTeamMember: (userId: string, options?: RequestOptions) => void;
  restoreLastCommit: Function;
}

const FilterCtx = createContext<FilterContext | null>(null);

export const useProjectTeamMembers = () => {
  const context = useContext(FilterCtx);
  if (!context) {
    throw new Error('useTransactionFilter must be used within a TransactionFilterProvider');
  }
  return context;
};

export type TeamMember = ProjectTeamMember | CustomerTeamMember;
export type TeamMembers = TeamMember[];
interface ProjectTeamMembersProviderProps extends PropsWithChildren {
  customerId: string;
  projectId: string;
}

export function ProjectTeamMembersProvider({
  customerId,
  projectId,
  children,
}: ProjectTeamMembersProviderProps) {
  const [, setErrorMessage] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);

  const [projectTeamMembers, setProjectTeamMembers] = useState<TeamMembers>([]);

  const { loading, data } = useQuery(QUERY_GET_ALL_PROJECT_TEAM_MEMBERS, {
    variables: {
      customerId,
      projectId,
    },
  });
  useEffect(() => {
    if (data?.projectTeamMembers) {
      setProjectTeamMembers(data.projectTeamMembers);
    }
  }, [loading]);

  const previousTeamMembers = useRef<TeamMembers>([]);

  const [addTeamMemberRequest] = useMutation(MUTATION_ADD_TEAM_MEMBER, {
    refetchQueries: ['GetAllProjectTeamMembers'],
    onError: (error) => {
      restoreLastCommit();
      onApolloError(error, setErrorMessage, []);
    },
  });

  const [removeTeamMemberRequest] = useMutation(MUTATION_REMOVE_TEAM_MEMBER, {
    refetchQueries: ['GetAllProjectTeamMembers'],
    onError: (error) => {
      restoreLastCommit();
      onApolloError(error, setErrorMessage, []);
    },
  });

  const addTeamMember = async (member: TeamMember) => {
    updateTeamMembers((prev) => [...prev, member]);

    addTeamMemberRequest({
      variables: {
        customerId,
        projectId,
        teamMemberId: member.userId,
      },
    });
  };

  const removeTeamMember = async (userId: string, { onComplete }: RequestOptions = {}) => {
    updateTeamMembers((prev) => prev.filter((member) => member.userId !== userId));

    removeTeamMemberRequest({
      variables: {
        customerId,
        projectId,
        teamMemberId: userId,
      },
      onCompleted: () => onComplete && onComplete(),
    });
  };

  const updateTeamMembers = (members: SetStateAction<TeamMembers>): void => {
    previousTeamMembers.current = projectTeamMembers;

    let teamMembers: TeamMembers;

    // members can be a function or a new state object
    if (typeof members === 'function') {
      // Call it with a dummy state to get the resolved filters
      teamMembers = (members as (prevState: TeamMembers) => TeamMembers)(projectTeamMembers);
    } else {
      // 'filters' is not a function, so we assume it's the new state object
      teamMembers = members;
    }

    setProjectTeamMembers(teamMembers);
  };

  const restoreLastCommit = () => {
    setProjectTeamMembers(previousTeamMembers.current);
  };

  return (
    <FilterCtx.Provider
      value={{
        loading,
        projectTeamMembers,
        addTeamMember,
        removeTeamMember,
        restoreLastCommit,
      }}
    >
      {children}
    </FilterCtx.Provider>
  );
}

interface RequestOptions {
  onComplete?: Function;
}

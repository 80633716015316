import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import HeroSplitWithImage from 'components/widgets/HeroSplitWithImage';
import womanWithLaptopOnCouch from 'images/womanOnCouchWithLaptop.webp';
import { Link, useNavigate } from 'react-router-dom';
import { useApplication } from 'hooks/useApplication';
import { useEffect } from 'react';
import { CreditCard, Link as LinkIcon } from 'lucide-react';

const WhatToExpect = () => {
  const navigate = useNavigate();
  const { loading: loadingApplication, application } = useApplication();

  useEffect(() => {
    if (application?.formId) {
      navigate('/application', { replace: true });
    }
  }, [application, navigate]);

  if (loadingApplication) return <></>;

  return (
    <HeroSplitWithImage
      image={womanWithLaptopOnCouch}
      imageAlt="Woman using laptop on couch in a room"
      allowSignout
    >
      <div className="h-full flex flex-col justify-center mt-4 lg:mt-0">
        <h1 className="text-4xl sm:text-5xl text-black mb-4 mt-4">Welcome to Zena</h1>
        <h2 className="text-lg sm:text-2xl mb-6">
          Zena reconciles your business expense and project spend - automatically
        </h2>
        <h3 className="font-bold tracking-wider">What to expect</h3>
        <hr className="my-5 border-primary-400 border-1" />
        <div className="flex">
          <span className="mr-5">
            <div className="bg-black rounded-full h-11 w-11 flex items-center justify-center p-1.5 text-white">
              <CreditCard className="brightness-0 invert" />
            </div>
          </span>
          <div className="flex flex-col">
            <h4 className="font-bold text-lg tracking-wider mb-2">Fill out the application</h4>
            <div className="text-base tracking-wider">
              This does not impact your credit score. You will need to provide your business details
              and social security number to complete the credit application.
            </div>
          </div>
        </div>
        <hr className="my-5 border-primary-400 border-1" />
        <div className="flex">
          <span className="mr-5">
            <div className="bg-black rounded-full h-11 w-11 flex items-center justify-center p-1.5 text-white">
              <LinkIcon className="brightness-0 invert" />
            </div>
          </span>
          <div className="flex flex-col">
            <h4 className="font-bold text-lg tracking-wider mb-2">Link your account</h4>
            <div className="text-base tracking-wider mb-3">
              You will need to link your business checking account. Your bank account will need a
              minimum balance of $150 to be approved.
            </div>
            <div>
              Zena membership is free. We just need to ensure your account has the funds to use the
              daily charge card.
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start md:flex-row sm:items-center sm:justify-center mt-12 gap-5">
          <Link to={`/application`}>
            <RoundButton variant="primary" className="text-xl" text="Start Application" />
          </Link>
          <Link to={'/dashboard'}>
            <UnderlineButton className="text-lg" text="Back to the dashboard" />
          </Link>
        </div>
      </div>
    </HeroSplitWithImage>
  );
};

export default WhatToExpect;

import { formatNumberAsDollars } from '../../../utils';
import {
  CompanySettingsLink,
  StyledTooltip,
  ToggleRow,
} from '../../../pages/Settings/tabs/IntegrationsTab/QboIntegration/QboConfigurationSection/helperComponents';
import { Toggle } from 'components/atoms/Inputs/Toggle';
import { useCompanyTransactionSettings } from '../../../pages/Settings/tabs/IntegrationsTab/QboIntegration/QboConfigurationSection/data';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { Info } from 'lucide-react';

export const RequireReceiptsSwitch = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const {
    requireReceiptOver,
    requireReceipt: isReceiptRequired,
    loading,
  } = useCompanyTransactionSettings({ companySlug: activeCompanySlug });

  if (loading) {
    return (
      <div>
        <div className="font-medium flex gap-2 items-center">
          Receipts are required on all transactions
          <RectangleSkeleton className="w-16 h-5" />
        </div>
        <div className="flex flex-row gap-3 items-center text-sm">
          <RectangleSkeleton className="w-6 h-5" />
        </div>
      </div>
    );
  }
  return (
    <ToggleRow>
      <div className="font-medium flex gap-2 items-center">
        {`Receipts are required on all transactions${
          requireReceiptOver ? ` over ${formatNumberAsDollars(requireReceiptOver, true)}` : ''
        }`}
        <Info className="cursor-pointer" data-tooltip-id="qbo-settings-2" />
        <StyledTooltip id="qbo-settings-2">
          <div className="grid gap-4">
            <p>
              In your <CompanySettingsLink /> you can require receipts for anything over a certain
              dollar amount.
            </p>

            <p>
              If you require a receipt on your <CompanySettingsLink /> then it is required before
              sending a transaction to Quickbooks.
            </p>
          </div>
        </StyledTooltip>
      </div>
      <div className="flex flex-row gap-3 items-center text-sm">
        <Toggle
          isActive={isReceiptRequired}
          disabled
          onToggle={() => {
            // no op
          }}
        />
        <div className="w-6">{isReceiptRequired ? 'Yes' : 'No'}</div>
      </div>
    </ToggleRow>
  );
};

import { DashboardCreditWidget } from '../widgets/CreditWidget';
import { ProjectWidget } from '../widgets/ProjectSpendWidget/ProjectSpendWidget';
import { DashboardContainer } from '../DashboardLayout/DashboardContainer';
import { useHasPlaidConnection } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { StayOrganized } from '../DashboardCharts/StayOrganized';
import { useActiveCompany } from 'providers/ActiveCompany';

export const LuxeTeamMemberDashboard = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { hasPlaidConnection, loading } = useHasPlaidConnection({ companySlug: activeCompanySlug });

  return (
    <>
      {loading && (
        <>
          <DashboardContainer gridColumnsWidth={2}>
            <LoadingWidget />
          </DashboardContainer>

          <DashboardContainer>
            <LoadingWidget />
          </DashboardContainer>
        </>
      )}

      {hasPlaidConnection && (
        <DashboardContainer gridColumnsWidth={2}>
          <DashboardCreditWidget />
        </DashboardContainer>
      )}

      <DashboardContainer>
        <StayOrganized />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={3}>
        <ProjectWidget />
      </DashboardContainer>
    </>
  );
};

const LoadingWidget = () => {
  return (
    <div className="grid gap-4">
      <RectangleSkeleton height="large" />
      <RectangleSkeleton />
      <RectangleSkeleton />
    </div>
  );
};

import zenaLogo from 'images/zenaLogo.webp';
import { RoundButton } from 'components/atoms/Buttons';
import { Lock, Plus, Search } from 'lucide-react';
import { PlaidCircleIcon } from 'icons/plaidCircle';

interface AddAccountOverviewProps {
  setCurrentStep: Function;
}

const AddAccountOverview = ({ setCurrentStep }: AddAccountOverviewProps) => {
  return (
    <div className="max-w-[460px]">
      <h3 className="mb-6">Link your business accounts</h3>
      <div className="flex flex-col gap-4 items-center">
        <div className="flex gap-6 justify-center items-center">
          <img src={zenaLogo} alt="Zena Logo" className="h-6" />
          <span className="h-3">
            <Plus />
          </span>
          <PlaidCircleIcon />
        </div>
        <div className="rounded-3xl border bg-slate-50 text-center p-6 flex items-center justify-center gap-6">
          <div className="flex flex-col max-w-36">
            <span className="h-10">
              <Lock />
            </span>
            <h4 className="text-base my-2">Secure</h4>
            <p className="text-sm text-neutral-600">
              Connecting through Plaid keeps your data safe
            </p>
          </div>
          {/* <div className="border-l h-full w-2" /> */}
          <div className="flex flex-col max-w-36 pl-6 border-l">
            <span className="h-10">
              <Search />
            </span>
            <h4 className="text-base my-2">Private</h4>
            <p className="text-sm text-neutral-600">We don’t sell or share any of your data</p>
          </div>
        </div>
        <p className="text-center max-w-[320px]">
          Zena needs read-only access to give you up to date insights and help you track your
          projects.
        </p>
        <RoundButton onClick={() => setCurrentStep(1)} className="mt-4">
          Connect Accounts
        </RoundButton>
      </div>
    </div>
  );
};

export default AddAccountOverview;

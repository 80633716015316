import React, { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type UnderlineTextInputProps = {
  label: string;
  placeholder?: string;
  useFormRegister?: UseFormRegisterReturn;
  prefix?: string;
  disabled?: boolean;
  type?: 'text' | 'number' | undefined;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  hasError?: boolean;
  errorMessage?: ReactNode;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  disablePasswordManager?: boolean;
};

const UnderlineTextInput = React.forwardRef<HTMLInputElement, UnderlineTextInputProps>(
  (props, ref) => {
    const {
      label,
      disablePasswordManager,
      placeholder,
      useFormRegister,
      onChange,
      type = 'text',
      value,
      className,
      hasError,
      prefix,
      errorMessage,
      onKeyDown,
    } = props;

    const borderUnderline = hasError ? 'border-rustic' : 'border-marble';
    return (
      <div className={`flex flex-col ${className}`}>
        <div>
          <label className="text-2xl">{label}</label>
          <div className="mt-10">
            {prefix ? <span className="mr-1 text-marble text-4xl">{prefix}</span> : null}
            <input
              type={type}
              className={`bg-transparent focus:outline-none focus:ring-0 pb-2 border-b text-4xl ${borderUnderline}`}
              placeholder={placeholder}
              ref={ref}
              value={value === 'NaN' ? '' : value}
              onChange={onChange}
              disabled={props.disabled}
              onKeyDown={onKeyDown}
              data-1p-ignore={!!disablePasswordManager}
              data-lpignore={!!disablePasswordManager}
              {...useFormRegister}
            />
          </div>
          {hasError && <div className="text-rustic mt-2">{errorMessage}</div>}
        </div>
      </div>
    );
  }
);

export default UnderlineTextInput;

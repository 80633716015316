import { TextInput } from 'components/atoms/Inputs/TextInput';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { cn } from 'utils';
import { getAllStates } from 'utils/us-states';
import useGooglePlaceAutoComplete from 'hooks/googlePlaceAutocomplete';
import { useEffect, useRef } from 'react';

export interface DetailsFormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

interface FormErrors {
  addressError: string;
  cityError: string;
  stateError: string;
  zipCodeError: string;
  emailError: string;
}

interface ClientDetailsFormProps {
  setValue: UseFormSetValue<DetailsFormData>;
  getValues: UseFormGetValues<DetailsFormData>;
  register: UseFormRegister<DetailsFormData>;
  watch: UseFormWatch<DetailsFormData>;
  errors: FormErrors;
}

const ClientDetailsForm = ({
  getValues,
  register,
  setValue,
  watch,
  errors,
}: ClientDetailsFormProps) => {
  const stateValue = watch('state');
  const states = getAllStates();
  const address1Ref = useRef<HTMLInputElement>(null);
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
  let autoComplete = '';

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

    if (address1Ref.current) {
      address1Ref.current.value = addressObj.address1;
    }

    setValue('address', addressObj.address1);
    setValue('address2', '');
    setValue('city', addressObj.locality || addressObj.sublocality);
    setValue('state', addressObj.adminArea1Short);
    setValue('zipCode', addressObj.postalCode);

    googleAutoCompleteSvc.clearResult(autoComplete);
  };

  useEffect(() => {
    if (address1Ref.current) {
      address1Ref.current.value = getValues('address') || '';
    }

    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
  }, []);

  return (
    <>
      <div className="flex flex-wrap gap-2 mb-5">
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Client first name</label>
          <TextInput
            label=""
            placeholder="First name"
            useFormRegister={register('firstName')}
            className="bg-white"
          />
        </div>
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Client last name</label>
          <TextInput
            label=""
            placeholder="Last name"
            useFormRegister={register('lastName')}
            className="bg-white"
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mb-5">
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Email address</label>
          <TextInput
            label=""
            placeholder="your.email@your.domain.com"
            hasError={!!errors.emailError}
            errorMessage={errors.emailError}
            useFormRegister={register('emailAddress')}
            className="bg-white"
          />
        </div>
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Phone number</label>
          <TextInput
            label=""
            placeholder="(000) 000-0000"
            useFormRegister={register('phoneNumber')}
            className="bg-white"
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mb-5">
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Project address</label>
          <TextInput
            label=""
            ref={address1Ref}
            onChange={(e) => setValue('address', e.target.value)}
            placeholder="Address 1"
            hasError={!!errors.addressError}
            errorMessage={errors.addressError}
            className="bg-white"
          />
        </div>
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Apt, suite, unit</label>
          <TextInput
            label=""
            placeholder="Address 2"
            useFormRegister={register('address2')}
            className="bg-white"
          />
        </div>
      </div>
      <div className="flex gap-2 mb-5 flex-wrap">
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">City</label>
          <TextInput
            label=""
            placeholder="City name"
            hasError={!!errors.cityError}
            errorMessage={errors.cityError}
            useFormRegister={register('city')}
            className="bg-white"
          />
        </div>
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="block text-sm text-gray-900">State</label>
          <div
            className={`flex flex-col rounded-lg border px-4 pt-3 pb-1.5 h-[46px] bg-white ${
              errors.stateError ? 'border-rustic' : 'border-marble'
            }`}
          >
            <select
              defaultValue={''}
              className={cn(
                'bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-lavender',
                stateValue ? '' : 'text-[darkgray]'
              )}
              {...register('state')}
            >
              <option value="">Select</option>
              {states.map((stateOpt) => (
                <option key={stateOpt.isoCode} value={stateOpt.isoCode}>
                  {stateOpt.isoCode}
                </option>
              ))}
            </select>
          </div>
          {errors.stateError && <div className="mt-2 text-rustic">{errors.stateError}</div>}
        </div>
        <div className="flex flex-col flex-1 min-w-fit">
          <label className="text-asphalt text-sm">Zip code</label>
          <TextInput
            label=""
            placeholder="00000"
            hasError={!!errors.zipCodeError}
            errorMessage={errors.zipCodeError}
            useFormRegister={register('zipCode')}
            className="bg-white"
          />
        </div>
      </div>
    </>
  );
};

export default ClientDetailsForm;

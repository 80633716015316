import { useLazyQuery } from '@apollo/client';
import { Alert } from 'components/atoms/Alert';
import { gql } from '__generated__';
import { Payment } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { PaymentDetails } from './PaymentDetails';
import { ManualRepaymentForm } from './ManualRepaymentForm';
import { PaymentsTable } from './PaymentsTable';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useAppState } from 'stores/UserStore';
import { Notifications } from 'providers/notifications';
import { Drawer, DrawerTitle } from 'components/atoms/Drawer';

export const HAS_STATEMENT = gql(`
  query HasStatement($customerId: String!) {
    hasStatement(customerId: $customerId) {
      hasStatement
    }
  }
`);

const PaymentsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [searchParams, setSearchParams] = useSearchParams();
  const [makePaymentOpen, setMakePaymentOpen] = useState(
    searchParams.get('makePaymentOpen') === 'true'
  );
  const [alertMsg, setAlertMsg] = useState<Record<string, string> | null>(null);

  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const [getHasStatement] = useLazyQuery(HAS_STATEMENT);
  const [storeHasStatement, setStoreHasStatement] = useAppState((state) => [
    state.hasStatement,
    state.setHasStatement,
  ]);
  let hasStatement = useRef<boolean | null>(storeHasStatement);
  useEffect(() => {
    if (storeHasStatement == null) {
      getHasStatement({
        variables: {
          customerId: activeCompanySlug,
        },
      }).then((hasStatementData) => {
        if (hasStatementData?.data?.hasStatement?.hasStatement != null) {
          hasStatement.current = hasStatementData?.data?.hasStatement?.hasStatement;
          setStoreHasStatement(hasStatement.current);
        }
      });
    }
  }, [storeHasStatement]);

  const [paymentDetails, setPaymentDetails] = useState<Payment>({
    id: '',
    initiatedBy: '',
    dateInitiated: '',
    amountCents: 0,
    status: '',
    statementDate: '',
    account: '',
  });

  useEffect(() => {
    if (makePaymentOpen) {
      setSearchParams({ ...searchParams, makePaymentOpen: 'true' });
    } else {
      searchParams.delete('makePaymentOpen');
      setSearchParams(searchParams);
    }
  }, [makePaymentOpen]);

  return (
    <div className="h-full w-full">
      <Notifications />
      <div className="flex justify-between p-10 items-center">
        <PageTitle hidden text="Payments" />
        <h1 className="text-xl lg:text-4xl">Payments</h1>
        <div>
          {!hasStatement.current && (
            <Tooltip id="make-payment-no-statement">
              You cannot make a payment before a statement exists
            </Tooltip>
          )}
          <div data-tooltip-id="make-payment-no-statement">
            <RoundButton
              text="Make payment"
              icon="money"
              variant={!hasStatement.current ? 'disabled' : 'primary'}
              disabled={!hasStatement.current}
              onClick={() => setMakePaymentOpen(true)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex px-10">
        <PaymentsTable
          customerId={activeCompanySlug}
          setPaymentDetails={setPaymentDetails}
          setShowPaymentDetails={(show) => {
            if (makePaymentOpen && show) {
              setMakePaymentOpen(false);
            }
            setShowPaymentDetails(show);
          }}
        />
      </div>

      <PaymentDetails
        onClose={() => setShowPaymentDetails(false)}
        paymentDetails={paymentDetails}
        show={showPaymentDetails}
      />

      <Drawer
        onClose={() => setMakePaymentOpen(false)}
        isVisible={makePaymentOpen}
        className="flex flex-col px-4 md:h-[calc(100%-76px)] md:mt-[76px] relative"
      >
        <div className="overflow-y-auto pt-4 pb-32">
          <DrawerTitle title="Make a payment" onClose={() => setMakePaymentOpen(false)} />
          <ManualRepaymentForm
            customerId={activeCompanySlug}
            setAlertMsg={setAlertMsg}
            onClose={() => setMakePaymentOpen(false)}
          />
        </div>
      </Drawer>

      {alertMsg && (
        <div className="w-3/4 py-4 z-10 fixed bottom-0 right-0 mr-8">
          <Alert
            variation={alertMsg.error ? 'error' : 'success'}
            onDismiss={() => setAlertMsg(null)}
          >
            {alertMsg.text}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default PaymentsPage;

import { PageTitle } from 'components/atoms/PageTitle';
import { TransactionsPageLayout } from '../TransactionsLayout';
import { AllocateTransactions } from 'components/widgets/AllocateTransactions';
import { useState } from 'react';
import { AddAccountModal } from 'components/widgets/AddAccountModal';
import { AllocateTransactionsStateProvider } from 'components/widgets/AllocateTransactions/state';
import { TransactionDetailsStateProvider } from 'components/widgets/TransactionDetailsDrawer/state';

export const ImportTransactionsPage = () => {
  const [showConnectAccountsModal, setShowConnectAccountsModal] = useState(false);

  return (
    <>
      <PageTitle hidden text="Import Transactions" />
      <TransactionsPageLayout pageName="IMPORT_TRANSACTIONS" title="Import transactions into Zena">
        <AllocateTransactionsStateProvider>
          <TransactionDetailsStateProvider>
            <AllocateTransactions openAddAccountModal={() => setShowConnectAccountsModal(true)} />
          </TransactionDetailsStateProvider>
        </AllocateTransactionsStateProvider>
      </TransactionsPageLayout>

      {showConnectAccountsModal && (
        <AddAccountModal
          isOpen
          disableAllocateTransactionsModal
          onClose={() => setShowConnectAccountsModal(false)}
        />
      )}
    </>
  );
};

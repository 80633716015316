import { ThemeProvider } from '@aws-amplify/ui-react';
import * as Sentry from '@sentry/react';
import { customAmplifyTheme } from 'amplifyTheme';
import CreateCardWorkflow from 'pages/CreateCardWorkflow/CreateCardWorkflow';
import { NewProjectForm } from './pages/NewProjectForm';
import { PendingInvitationOrApplicationForm } from 'components/widgets/PendingInvitationOrApplicationForm';
import { AdminApprovalRequestsPage } from 'pages/AdminApprovalRequestsPage/AdminApprovalRequestsPage';
import { ApplicationFormPage } from 'pages/ApplicationFormPage';
import CardsPage from 'pages/Cards/Cards';
import ConnectBankAccount from 'pages/ConnectBankAccount';
import CustomerInitPage from 'pages/CustomerInitPage';
import { Invitation } from 'pages/Invitation';
import { JoinTeam } from 'pages/JoinTeam';
import OAuthLanding from 'pages/OAuthLanding';
import { PaymentsPage } from 'pages/PaymentsPage';
import { ProjectsPage } from 'pages/ProjectsPage';
import { Statements } from 'pages/Statements';
import { Support } from 'pages/Support';
import TagAuthorizationPage from 'pages/TagAuthorizationPage';
import { TagTransactionPage } from 'pages/TagTransactionPage';
import { TwoFactorAuth } from 'pages/TwoFactorAuth';
import WhatToExpect from 'pages/WhatToExpect';
import { FeatureFlag } from 'providers/FeatureFlag';
import { Redirecter } from 'providers/Redirecter';
import { RequireAuth } from 'providers/RequireAuth';
import { RequireRepaymentConnection } from 'providers/RequireRepaymentConnection';
import { RequireTwoFactorAuth } from 'providers/RequireTwoFactorAuth';
import UserAuthProvider from 'providers/UserAuthProvider';
import { ZenaRoute } from 'providers/ZenaRoute';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/Home';
import { ApolloWrapper } from './providers/ApolloWrapper';
import { LoginPage } from 'pages/(onboarding)/Login';
import { ForgotPasswordPage } from 'pages/(onboarding)/Login/ForgotPassword';
import { ResetPasswordPage } from 'pages/(onboarding)/Login/ForgotPassword/ResetPassword';
import { RequireNoAuth } from 'providers/RequireNoAuth';
import StatementDetail from 'pages/StatementDetail';
import { UserRedirects } from 'providers/UserRedirects';
import { DashboardPage } from 'pages/(dashboard)';
import { RequirePlaidConnection } from 'providers/RequirePlaidConnection';
import { RequireActiveCompany } from 'providers/RequireActiveCompany';
import { AllTransactionsPage } from 'pages/(transactions)/AllTransactions';
import { OrganizeTransactionsPage } from 'pages/(transactions)/OrganizeTransactions';
import { ImportTransactionsPage } from 'pages/(transactions)/ImportTransactions';
import { UserProfile } from 'pages/UserProfile';
import { ActiveCompanyProvider } from 'providers/ActiveCompany';
import { SettingsRoutes } from './pages/Settings/SettingsLayout';
import { PerksRewards } from 'pages/(perks)/Rewards';
import { PerksDiscounts } from 'pages/(perks)/Discounts';
import { Role } from '__generated__/graphql';
import { ProjectOverviewPage } from 'pages/(project)/ProjectOverview';
import { ProjectSettingsPage } from 'pages/(project)/ProjectSettings';
import { ProjectTransactionsPage } from 'pages/(project)/ProjectTransactions';
import { ShowIfEnabled } from 'providers/FeatureFlags/helpers';
import { StripeGhostRoute } from 'pages/StripeGhostRoute';
import { ExportTransactionsPage } from 'pages/(transactions)/ExportTransactions';
import { CardsManagementPage } from './pages/(cards)';
import { CardRequestApproval } from 'pages/CardRequestApproval';
import { CardMgmtRequestApproval } from 'pages/(cards)/CardRequestApproval';
import { CreateCompanyAccountPage } from 'pages/(onboarding)/CreateAccount/CreateCompany';

function App() {
  let location = useLocation();

  useEffect(() => {
    // Record route changes in Segment analytics
    analytics.page();
  }, [location.pathname]);

  return (
    <ThemeProvider theme={customAmplifyTheme}>
      <ApolloWrapper>
        <div className="App">
          <UserAuthProvider>
            <>
              <UserRedirects />
              <ActiveCompanyProvider>
                <FeatureFlag>
                  <main>
                    <Routes>
                      {/* Unauthenticated Routes */}
                      <Route
                        path="/login"
                        element={
                          <RequireNoAuth>
                            <LoginPage />
                          </RequireNoAuth>
                        }
                      />
                      <Route path="/signup" element={<Navigate to="/login" />} />
                      <Route
                        path="/forgot-password"
                        element={
                          <RequireNoAuth>
                            <ForgotPasswordPage />
                          </RequireNoAuth>
                        }
                      />
                      <Route
                        path="/reset-password"
                        element={
                          <RequireNoAuth>
                            <ResetPasswordPage />
                          </RequireNoAuth>
                        }
                      />

                      <Route path="/invitation" element={<Invitation />} />
                      <Route path="/customer-init" element={<CustomerInitPage />} />

                      <Route path="/signup/confirmation" element={<Navigate to="/login" />} />
                      <Route path="/signup/business" element={<CreateCompanyAccountPage />} />
                      <Route path="/signup/personal" element={<Navigate to="/login" />} />

                      {/* Authenticated Routes */}
                      <Route
                        path="/"
                        element={
                          <RequireAuth>
                            <Home />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/join-team"
                        element={
                          <RequireAuth>
                            <JoinTeam />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/what-to-expect"
                        element={
                          <RequireActiveCompany>
                            <RequireAuth>
                              <WhatToExpect />
                            </RequireAuth>
                          </RequireActiveCompany>
                        }
                      />
                      <Route
                        path="/application"
                        element={
                          <RequireActiveCompany>
                            <RequireAuth>
                              <ApplicationFormPage />
                            </RequireAuth>
                          </RequireActiveCompany>
                        }
                      />
                      <Route
                        path="/pendingInvitations"
                        element={
                          <RequireAuth>
                            <PendingInvitationOrApplicationForm />
                          </RequireAuth>
                        }
                      />
                      <Route path="oauth-landing" element={<OAuthLanding />} />

                      <Route path="/invitation" element={<Invitation />} />

                      {/* Zena Routes */}
                      <Route
                        path="/connect-bank-account"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth allowRoleAccess={[Role['admin']]}>
                              <RequireActiveCompany>
                                <ConnectBankAccount />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <DashboardPage />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/:id(\\d+)"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <Redirecter />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/:id/"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <Redirecter />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/:id/*"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <Redirecter />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/verification"
                        element={
                          <ZenaRoute withNavbar={false}>
                            <RequireAuth>
                              <TwoFactorAuth />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/projects"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <ProjectsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/projects/new"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <NewProjectForm />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/projects/:projectId"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <ProjectOverviewPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/projects/:projectId/transactions"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <ProjectTransactionsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/projects/:projectId/settings"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <ProjectSettingsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/transactions"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <AllTransactionsPage />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/transactions/organize"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <OrganizeTransactionsPage />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/transactions/import"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['member'], Role['admin']]}>
                              <RequireActiveCompany>
                                <ImportTransactionsPage />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/transactions/exports"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <ExportTransactionsPage />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/transactions/:transactionId/tag"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequirePlaidConnection>
                                  <RequireTwoFactorAuth>
                                    <TagTransactionPage />
                                  </RequireTwoFactorAuth>
                                </RequirePlaidConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/authorizations/:authorizationId/tag"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <TagAuthorizationPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      {SettingsRoutes({ path: '/settings' })}
                      <Route
                        path="/support"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <Support />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/support/popout"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth>
                              <Support />
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards-management"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsManagementPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards-management/:cardId"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsManagementPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards-management/approvals"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsManagementPage currentTab="approvals" />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards-management/approvals/:cardRequestId"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardMgmtRequestApproval />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards-management/new"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth allowRoleAccess={[Role['member'], Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <CreateCardWorkflow referringPage="/cards-management" />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/business-spend"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <Redirecter newSubUrl="/cards" />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/activate"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <Redirecter newSubUrl="/cards" />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/activate/:cardId"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/payments"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['bookkeeper'], Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <PaymentsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards/:cardId"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cardRequests/:cardRequestId"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth allowRoleAccess={[Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardRequestApproval />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/approval-requests"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <AdminApprovalRequestsPage />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards/:cardId/transactions/:transactionId"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <RequireTwoFactorAuth>
                                    <CardsPage />
                                  </RequireTwoFactorAuth>
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/cards/new"
                        element={
                          <ZenaRoute withNavbar={false} withHeader={false}>
                            <RequireAuth allowRoleAccess={[Role['member'], Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <CreateCardWorkflow referringPage="/cards" />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/statements"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['bookkeeper'], Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <Statements />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                      <Route
                        path="/statements/:statementDate"
                        element={
                          <ZenaRoute>
                            <RequireAuth allowRoleAccess={[Role['bookkeeper'], Role['admin']]}>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <StatementDetail />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/perks/rewards"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <PerksRewards />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/perks/discounts"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <RequireRepaymentConnection>
                                  <PerksDiscounts />
                                </RequireRepaymentConnection>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/manage-profile"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <UserProfile />
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />

                      <Route
                        path="/secret-stripe-route"
                        element={
                          <ZenaRoute>
                            <RequireAuth>
                              <RequireActiveCompany>
                                <ShowIfEnabled flag="useStripe">
                                  <StripeGhostRoute />
                                </ShowIfEnabled>
                              </RequireActiveCompany>
                            </RequireAuth>
                          </ZenaRoute>
                        }
                      />
                    </Routes>
                  </main>
                </FeatureFlag>
              </ActiveCompanyProvider>
            </>
          </UserAuthProvider>
        </div>
      </ApolloWrapper>
    </ThemeProvider>
  );
}

export default Sentry.withErrorBoundary(App, { fallback: <p>an error has occurred</p> });

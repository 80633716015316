import { Button, RoundButton } from 'components/atoms/Buttons';
import { Pill } from 'components/atoms/Pill';
import { ProgressBar } from 'components/atoms/ProgressBar';
import { Link } from 'react-router-dom';
import { toDollarDisplay } from 'utils';
import closeButton from '../../images/closeButton.webp';
import zenaLogo from '../../images/zenaLogo.webp';

type ProjectForSuccess = {
  id: string;
  name: string;
  budgetCents?: number | null | undefined;
  approved: boolean;
};

const ProjectSuccess = ({
  customerId,
  cardLast4Digits,
  onClose,
  project,
}: {
  customerId: string;
  cardLast4Digits: string;
  onClose: () => void;
  project: ProjectForSuccess;
}) => {
  const cardType = 'Virtual';
  const successAdminTitle = 'Your project has been created';
  const successMemberTitle = 'Project sent for approval';
  const successAdminBody = cardLast4Digits
    ? 'Congrats on the new project! It comes with a dedicated virtual card to use for all project purchases.'
    : 'Congrats on the new project!';
  const successMemberBody = "We'll create your project as soon as we hear back.";

  return (
    <div className="bg-ivory border border-transparent">
      <div className="h-[10vh]">
        <ProgressBar step={3} numSteps={3} />
        <div className="flex justify-between mt-9 ml-6">
          <img className="object-contain h-6 " src={zenaLogo} alt="Zena Logo" />
          <button onClick={onClose} aria-label="Close" className="outline-accent-600">
            <div className="flex items-center">
              <div className="underline">Close</div>
              <img
                className="w-3 h-3 ml-3.5 mr-9 cursor-pointer"
                src={closeButton}
                alt="Close icon"
              />
            </div>
          </button>
        </div>
      </div>

      <div className="grid h-[90vh] place-content-center">
        <div className="mt-14">
          <div className="flex flex-col items-center">
            <div className="bg-white p-8 h-[265px] w-[360px] rounded-3xl">
              {project.approved ? (
                <Pill variant="success" text="New Project" />
              ) : (
                <Pill variant="pending" text="Waiting approval" />
              )}
              <p className="text-2xl mt-6">{project.name ?? 'New Project'}</p>
              <div className="border-t border-secondary-400 mt-10"></div>
              <div className="flex justify-between items-end mt-4">
                {project.budgetCents && project.budgetCents > 0 ? (
                  <div>
                    <p className="text-3xl">{toDollarDisplay(project.budgetCents / 100)}</p>
                    <p className="text-sm text-asphalt">Remaining Budget</p>
                  </div>
                ) : (
                  <div>
                    <Link to={`/projects/${project.id}/settings`}>
                      <Button text="Set Budget" variant="tertiary" />
                    </Link>
                  </div>
                )}
                {project.approved && cardLast4Digits && (
                  <p className="text-sm text-asphalt">
                    {cardType} · {cardLast4Digits}
                  </p>
                )}
              </div>
            </div>
            <h2 className="pt-16 pb-4 text-4xl text-center success-heading">
              {project.approved ? successAdminTitle : successMemberTitle}
            </h2>
            <p className="text-lg text-asphalt mx-6 md:w-[600px] h-16 text-center">
              {project.approved ? successAdminBody : successMemberBody}
            </p>
            <div className="my-24 md:my-8">
              {project.approved ? (
                <a href={`/projects/${project.id}`}>
                  <RoundButton text="View project" />
                </a>
              ) : (
                <a href="/projects">
                  <RoundButton text="Back to projects" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSuccess;

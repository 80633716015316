import { Pill } from 'components/atoms/Pill';
import { cn, formatCentsToAbsDollarsString } from 'utils';
import { TableRow } from 'components/atoms/table/TableRow';
import {
  TransactionFeedTransactionType,
  TransactionMissingFields,
  TransactionSyncStatus,
} from '__generated__/graphql';
import { TransactionAssignedPill } from '../../TransactionAssignedPill';
import { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TransactionRowSyncStatus } from './TransactionRowSyncStatus';
import { Split } from 'lucide-react';
import { formatSimpleDate } from 'utils/date';

interface IProject {
  name: string;
}

type TransactionTableRowProps = {
  id: string;
  name: string;
  amountWithDirection: number;
  date: string;
  source: string;
  project: IProject | null;
  onClick: () => void;
  zenaType: TransactionFeedTransactionType;
  isAuthorization?: boolean;
  isHighlighted?: boolean;
  isIncome?: boolean;
  children?: ReactNode;
  childTransactionIds: string[];
  isImported: boolean;
  syncStatus?: TransactionSyncStatus | undefined;
  syncedAt?: string;
  expenseId?: string;
  missingFields?: TransactionMissingFields;
  qboActive: boolean;
};

const TransactionTableRow = ({
  id,
  name,
  amountWithDirection,
  date,
  source,
  project,
  onClick,
  zenaType,
  isAuthorization,
  isHighlighted,
  isIncome = false,
  childTransactionIds,
  isImported,
  syncStatus,
  syncedAt,
  expenseId,
  children,
  qboActive,
}: TransactionTableRowProps) => {
  const { transactionDetailsDrawer2 } = useFlags();

  return (
    <TableRow
      key={id}
      onClick={onClick}
      className={cn(
        'hover:bg-tertiary-800',
        isAuthorization ? 'text-marble' : 'text-asphalt',
        isHighlighted && 'bg-tertiary-800',
        (isIncome || zenaType === 'return') && 'bg-success-600'
      )}
    >
      {children ? (
        children
      ) : (
        <>
          {/* date column */}
          <td className="text-primary-700">{formatSimpleDate(new Date(date))}</td>

          {/* merchant column */}
          <td>
            <span className={`${!isAuthorization ? 'text-black' : ''}`}>{name}</span>
            {isAuthorization && (
              <Pill variant="pending" className="inline-block ml-3" text="Pending" />
            )}
          </td>

          {/* qbo sync column */}
          {qboActive && (
            <td>
              <TransactionRowSyncStatus
                isImported={isImported}
                syncStatus={syncStatus}
                syncedAt={syncedAt}
                expenseId={expenseId}
              />
            </td>
          )}

          {/* assigned column */}
          <td className="hidden lg:table-cell text-primary-700">
            {transactionDetailsDrawer2 && childTransactionIds.length ? (
              <div className="flex gap-3">
                <Split />
                {childTransactionIds.length} split transactions
              </div>
            ) : (
              // TODO - update logic to determine type when more types are available (today 'not related' gets filtered out and there are no business expense categories)
              <TransactionAssignedPill
                assignedCategoryType={project?.name ? 'project' : 'business'}
                categoryName={project?.name || 'Business expense'}
              />
            )}
          </td>

          {/* source column */}
          <td className="hidden lg:table-cell text-black">{source}</td>

          {/* amount column */}
          <td className="max-w-[80px] relative text-primary-700 font-medium">
            {(isIncome || zenaType === 'return') && (
              <span className="absolute top-0 -ml-4 text-base font-bold flex items-center h-full text-forest-900">
                +
              </span>
            )}
            <span>{formatCentsToAbsDollarsString(amountWithDirection)}</span>
          </td>
        </>
      )}
    </TableRow>
  );
};

export default TransactionTableRow;

import { useParams } from 'react-router-dom';
import { ProjectLayout } from '../ProjectLayout';
import { useGetProjectSettings } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import Settings from './Settings';

export const ProjectSettingsPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { projectId } = useParams();

  const { project, loading } = useGetProjectSettings({
    projectId,
    companySlug: activeCompanySlug,
  });

  return (
    <ProjectLayout pageName="SETTINGS">
      {project && (
        <Settings
          customerId={activeCompanySlug}
          projectId={project.id}
          status={project.status}
          name={project.name}
          budget={project.budget ?? undefined}
          description={project.description ?? ''}
          tags={project.tags ?? []}
          clientInfo={project.clientInfo ?? undefined}
          address={project.address ?? undefined}
        />
      )}

      {(loading || !project || !projectId) && (
        <div className="pt-6 flex justify-center">
          <LoadingSpinner />
        </div>
      )}
    </ProjectLayout>
  );
};

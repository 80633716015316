import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const COMPANY_DETAILS_QUERY = gql(`
  query GetCompanyDetailsForDashboard($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      name
    }
  }
`);

export const useGetCompanyTitle = ({ companySlug }: { companySlug?: string }) => {
  const { data, loading, error } = useQuery(COMPANY_DETAILS_QUERY, {
    variables: {
      companySlug: companySlug ?? '',
    },
    skip: !companySlug,
  });

  return {
    companyTitle: data?.getCompany?.name ?? '',
    loading,
    error,
  };
};

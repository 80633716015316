import { Disclosure } from 'components/atoms/Disclosure';
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import { CircleCheck } from 'lucide-react';

interface ActivationStepsProps {
  children: ReactNode;
  displayStepNumbers?: boolean;
}

export const ActivationSteps = ({ children, displayStepNumbers }: ActivationStepsProps) => {
  const newChildren = Children.map(children, (child, i) => {
    if (isActivationStep(child)) {
      const props = {
        ...(displayStepNumbers && { stepNumber: i + 1 }),
      };
      return cloneElement(child, props);
    }
    return child;
  });

  return (
    <div className="w-full">
      <div className="mx-auto w-full bg-white divide-y-2">{newChildren}</div>
    </div>
  );
};

export interface ActivationStepProps {
  title: string;
  isCompleted: boolean;
  children: ReactNode;
  stepNumber?: number;
  open?: boolean;
}
export const ActivationStep = ({
  children,
  isCompleted,
  title,
  stepNumber,
  open,
}: ActivationStepProps) => {
  return (
    <div>
      <Disclosure defaultOpen={open ? open : false}>
        <Disclosure.TitleWithChevron className="bg-accent-300 hover:bg-accent-400 py-6 px-4 ui-open:border-b">
          <div className="w-full flex items-center gap-6">
            {stepNumber && <div className="font-bold">#{stepNumber}</div>}
            <div className="font-bold">{title}</div>
            <div className="ml-auto mr-8">
              {isCompleted ? (
                <CircleCheck className="fill-success-700" />
              ) : (
                <CircleCheck className="fill-gray-400" />
              )}
            </div>
          </div>
        </Disclosure.TitleWithChevron>
        <Disclosure.Body className="bg-white py-6 px-4">{children}</Disclosure.Body>
      </Disclosure>
    </div>
  );
};
ActivationSteps.Step = ActivationStep;

// utility function to check if a child is an ActivationStep
function isActivationStep(element: ReactNode): element is ReactElement<ActivationStepProps> {
  return isValidElement(element) && element.type === ActivationStep;
}

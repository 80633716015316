import { ListItem } from 'components/atoms/ListItem';
import React, { useEffect, useRef, useState } from 'react';

interface SearchbarData {
  id: string;
  name: string;
  email: string;
  url?: string;
}

interface SearchbarProps extends React.InputHTMLAttributes<HTMLInputElement> {
  data: SearchbarData[];
  onNotFound?: (input: string) => void;
  onClickItem: (item: SearchbarData) => void;
  loading?: boolean;
  selectOneMax?: boolean;
  inputClassName?: string;
  disabled?: boolean;
}

const Searchbar: React.FC<SearchbarProps> = ({
  data,
  onClickItem,
  onNotFound,
  loading = false,
  selectOneMax = false,
  disabled = false,
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchbarData[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [itemClicked, setItemClicked] = useState(false);
  const searchbarRef = useRef<HTMLInputElement>(null);
  const { inputClassName } = rest;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemClicked(false);
    setSearchQuery(event.target.value);
    setShowResults(event.target.value !== '');
  };

  const handleClick = (item: SearchbarData) => {
    onClickItem(item);
    setItemClicked(true);
    searchbarRef.current?.querySelector('input')?.focus();
    if (selectOneMax) setShowResults(false);
  };

  useEffect(() => {
    const searchItems = (query: string) => {
      const filteredItems = data.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.email.toLowerCase().includes(query.toLowerCase())
      );
      return filteredItems;
    };

    setSearchResults(searchItems(searchQuery));
  }, [searchQuery, data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchbarRef.current && !searchbarRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchbarRef} className="relative">
      <input
        type="text"
        className={inputClassName ?? 'rounded-lg p-3 border border-[#B1B1B3] w-full'}
        value={searchQuery}
        onChange={handleSearch}
        data-1p-ignore="true"
        data-lpignore="true"
        disabled={disabled}
      />
      {showResults && (
        <div className="fixed w-80 z-10 mt-2 border-2 border-primary-400 rounded-lg p-2 bg-white">
          <h6 className="text-base text-asphalt">Suggested teammates</h6>
          <ul className="py-4 max-h-80 overflow-y-scroll">
            {!searchResults.length && !itemClicked && !loading && (
              <li>
                <div className="mb-4">Not Found! Make sure this is a valid email.</div>
                {onNotFound && (
                  <div>
                    Click{' '}
                    <button
                      className="underline cursor-pointer"
                      onClick={() => onNotFound(searchQuery)}
                    >
                      here
                    </button>{' '}
                    to invite this user to the project/organization.
                  </div>
                )}
              </li>
            )}
            {loading
              ? 'Loading...'
              : searchResults.map((item: SearchbarData) => (
                  <li
                    key={item.email}
                    className="p-2.5 border-b border-primary-400 last:border-b-0"
                    onClick={() => handleClick(item)}
                  >
                    <ListItem name={item.name} email={item.email} url={item?.url || ''} />
                  </li>
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Searchbar;

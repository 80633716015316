import { useActiveCompany } from 'providers/ActiveCompany';
import { useProjectRooms } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { TransactionTagSelection } from '../TransactionTagSelection';

export const SelectProjectRooms = ({
  rooms,
  onChangeRooms,
  projectId,
  className = '',
}: {
  rooms: string[];
  onChangeRooms: (rooms: string[]) => void;
  projectId: string;
  className?: string;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { rooms: allProjectRooms, loading } = useProjectRooms({
    companySlug: activeCompanySlug,
    projectId,
  });

  if (loading) {
    return <RectangleSkeleton width="full" height="large" />;
  }

  return (
    <TransactionTagSelection
      onSelectTag={(tag) => {
        onChangeRooms([...rooms, tag]);
      }}
      tags={allProjectRooms}
      selectedTags={rooms}
      onRemoveTag={(tagName) => onChangeRooms(rooms.filter((room) => room !== tagName))}
      tagColor="bg-lavender-300"
      placeholder="Select project rooms"
      className={className}
      disabled={!projectId}
    />
  );
};

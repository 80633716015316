import { RelativeDate } from 'components/atoms/RelativeDate';
import { TableRow } from 'components/atoms/table/TableRow';
import { formatCentsToDollarsString } from 'utils';

export type PaymentTableRowProps = {
  id: string;
  dateInitiated: string;
  amountCents: number;
  status: string;
  user?: string;
  onClick: () => void;
};

export const PaymentTableRow = ({
  id,
  dateInitiated,
  amountCents,
  status,
  user,
  onClick,
}: PaymentTableRowProps) => {
  return (
    <TableRow className="cursor-pointer" key={id} onClick={onClick}>
      <td>
        <span className="font-medium">
          <RelativeDate date={dateInitiated} />
        </span>
      </td>
      <td className="max-w-[80px]">
        <span>{formatCentsToDollarsString(amountCents)}</span>
      </td>
      <td>{status}</td>
      <td>{user || 'Autopay'}</td>
    </TableRow>
  );
};

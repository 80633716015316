import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const PLAID_CONNECTION_STATUS_QUERY = gql(`
  query GetPlaidLinkedAccountStatusForDashboard($companySlug: String!) {
    getPlaidLinkedAccountSummary(customerId: $companySlug) {
      status
    }
  }
`);

export const useHasPlaidConnection = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(PLAID_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    fetchPolicy: 'network-only',
    skip: !companySlug,
  });

  return {
    plaidConnectionStatus: data?.getPlaidLinkedAccountSummary?.status,
    loading,
    error,
  };
};

import { gql } from '__generated__/gql';

export const MUTATION_UPDATE_SETTINGS = gql(`
  mutation UpdateTransactionalSettings($customerId: String!, $settings: AdminSettingsInput!) {
    updateSettings(customerId: $customerId, settings: $settings) {
        requireMemoOver,
        requireReceiptOver,
        visited
    }
  }
`);

export const MUTATION_UPDATE_NOTIFICATION_SETTINGS = gql(`
  mutation UpdateNotificationSettings($customerId: String!, $notificationSettings: AdminNotificationSettingsInput!) {
    updateNotificationSettings(customerId: $customerId, notificationSettings: $notificationSettings) {
      projectBudget,
      largeTransactions
    }
  }
`);

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { isAdmin } from 'hooks/access';

const COMPANY_TIER_QUERY = gql(`
  query GetCompanyTierForDashboard($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      tier
    }
  }
`);

export const useCompany = ({ companyId }: { companyId: string }) => {
  const { data, loading, error } = useQuery(COMPANY_TIER_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return { loading, error, company: data?.getCompany ?? null };
};

const COMPANY_TRANSACTIONS_COUNT_QUERY = gql(`
  query GetTransactionsCountForDashboardTier($companyId: String!) {
    getAuthorizationsAndTransactions(customerId: $companyId, pageNumber: 0) {
      meta {
        total
      }
    }
  }
`);

export const useCompanyTransactionsCount = ({ companyId }: { companyId: string }) => {
  const { data, loading, error } = useQuery(COMPANY_TRANSACTIONS_COUNT_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const transactionsCount = data?.getAuthorizationsAndTransactions?.meta?.total ?? 0;
  const hasTransactions = transactionsCount > 0;

  return { loading, error, hasTransactions };
};

const PLAID_CONNECTION_COMPLETE_QUERY = gql(`
  query PlaidConnectionCompleteQueryForDashboard($companySlug: String!)  {
    getPlaidConnectionStatus(companySlug: $companySlug) {
      connected
    }
  }
`);

export const usePlaidConnectionComplete = ({ companySlug }: { companySlug: string }) => {
  const admin = isAdmin(companySlug);
  const { data, loading, error } = useQuery(PLAID_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug,
    },
    skip: !admin,
  });

  return { loading, error, plaidConnectionStatus: data?.getPlaidConnectionStatus ?? null };
};

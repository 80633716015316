import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetTransactionDetailsMemoQuery } from '__generated__/graphql';

const TRANSACTION_DETAILS_MEMO_QUERY = gql(`
  query GetTransactionDetailsMemo($companySlug: String! $transactionId: String!) {
    getTransaction(customerId: $companySlug transactionId: $transactionId) {
      id
      memo
      missingFields {
        memo
      }
    }
  }
`);

export const useTransactionDetailsMemo = ({
  transactionId,
  activeCompanySlug,
  onCompleted,
}: {
  transactionId: string;
  activeCompanySlug: string;
  onCompleted: (data: GetTransactionDetailsMemoQuery) => void;
}) => {
  const { data, loading, error } = useQuery(TRANSACTION_DETAILS_MEMO_QUERY, {
    variables: {
      transactionId,
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || !transactionId,
    onCompleted(data) {
      onCompleted(data);
    },
  });

  return {
    memo: data?.getTransaction?.memo ?? '',
    isMissingMemo: data?.getTransaction?.missingFields?.memo,
    loading,
    error,
  };
};

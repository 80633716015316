import { RoundButton } from 'components/atoms/Buttons';
import { ZenaCardSilouette } from 'components/atoms/ZenaCard/ZenaCardSilhouette';
import { ProjectZenaCard } from 'components/widgets/ProjectZenaCard';
import { Link } from 'react-router-dom';
import { AddressOutput, TransactionTag } from '__generated__/graphql';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { ProjectFinancials } from './ProjectFinancials';
import { ProjectTagCharts } from './ProjectTagCharts';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useCompany } from 'hooks/useCompany';
import { CompanyTier } from '__generated__/graphql';
import { ProjectSpendVsBudget } from './ProjectSpendVsBudget';
import { useActiveCompany } from 'providers/ActiveCompany';
import { companyRole } from 'hooks/access';
import { ProjectVirtualCardButton } from './ProjectVirtualCardButton';
import { ProjectVirtualCardButtonText } from './ProjectVirtualCardButtonText';

const OverviewLoading = () => {
  return (
    <>
      <div className="flex gap-6 py-8">
        <RectangleSkeleton className="h-96 w-96" />
        <RectangleSkeleton className="h-96" />
      </div>
      <div className="flex flex-col gap-6">
        <RectangleSkeleton className="h-48" />
        <RectangleSkeleton className="h-48" />
        <RectangleSkeleton className="h-48" />
      </div>
    </>
  );
};

const Overview = ({
  totalSpent,
  projectId,
  budget: initialBudget,
  description,
  cardId,
  address,
  tags = [],
}: {
  totalSpent: number;
  projectId: string;
  budget?: number;
  description: string;
  cardId: string;
  address?: AddressOutput;
  tags: TransactionTag[];
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const role = companyRole(activeCompanySlug);
  const showCardOnly = role === 'bookkeeper';

  const { company } = useCompany({
    companySlug: activeCompanySlug,
  });

  const isLuxeCompany = company?.tier === CompanyTier.luxe;

  if (!projectId) {
    return <OverviewLoading />;
  }

  const budget = initialBudget ? initialBudget / 100 : null;

  const renderedProjectDescription: React.ReactNode[] = [];
  if (description) {
    description.split('\n').forEach((str: string, index: number) => {
      renderedProjectDescription.push(str);
      renderedProjectDescription.push(<br key={`proj-desc-${index}`} />);
    });
  }

  return (
    <>
      <div className="flex flex-col gap-6 py-8">
        <div className="flex flex-wrap xl:flex-nowrap gap-6">
          <OutlinedWidget className="pb-12 basis-[350px]">
            <h3 className={`${showCardOnly ? 'mb-2.5' : 'mb-[-2.5rem]'} `}>Project virtual card</h3>
            {cardId ? (
              <ProjectZenaCard
                cardId={cardId}
                projectAddress={address ?? undefined}
                showCardOnly={showCardOnly}
              />
            ) : (
              <>
                <div className="relative py-14 opacity-50 border-b text-center">
                  <ZenaCardSilouette
                    className="mx-auto"
                    cardId="11111"
                    firstName="My"
                    lastName="Name"
                    last4Digits="5080"
                    showDetails={false}
                  />
                </div>
                <div className="py-14">
                  <div className="text-center">
                    {isLuxeCompany ? (
                      <ProjectVirtualCardButton
                        companySlug={activeCompanySlug}
                        projectId={projectId}
                      />
                    ) : (
                      <Link to="/application" className="inline-block">
                        <RoundButton variant="secondary">Apply for the Zena card</RoundButton>
                      </Link>
                    )}
                  </div>
                  <div className="text-center pt-6">
                    <ProjectVirtualCardButtonText isLuxeCompany={isLuxeCompany} />
                  </div>
                </div>
              </>
            )}
          </OutlinedWidget>
          <ProjectFinancials projectId={projectId} />
        </div>
      </div>
      <div>
        <div className="flex flex-grow flex-col gap-6">
          <ProjectSpendVsBudget totalSpent={totalSpent} budget={budget ?? 0} />
          <ProjectTagCharts projectId={projectId} tags={tags || []} />
        </div>
      </div>
    </>
  );
};

export default Overview;

import { formatCentsToDollarStringWithDecimal } from 'utils';

interface DefaultBarChartTooltipProps {
  payload?: { value: number }[];
  label?: string;
}

const TooltipContent = ({ label, payload, ...props }: DefaultBarChartTooltipProps) => {
  const value = payload?.[0]?.value;
  const formattedLabel = label ? label.charAt(0).toUpperCase() + label.slice(1) : '';
  return (
    <div className="bg-white rounded-xl shadow-normal py-1.5 text-sm">
      <div className="mx-4 font-medium">{formattedLabel}</div>
      <hr className="my-1.5" />
      <div className="mx-4 flex">
        <div>Total: </div>
        <div className="font-medium ml-1">{formatCentsToDollarStringWithDecimal(value || 0)}</div>
      </div>
    </div>
  );
};
export default TooltipContent;

import BoxSelect from 'components/atoms/BoxSelect/BoxSelect';

const ChooseCardStep = ({
  onCardSelect,
  isAdmin = false,
}: {
  onCardSelect: (value: 'physicalCard' | 'virtualCard') => void;
  isAdmin: boolean;
}) => {
  const chooseCardOptions = [
    {
      value: 'physicalCard',
      title: 'Physical corporate card',
      body: "It's Zena, in your wallet! Best for in-person purchases, but can be used anywhere Visa is accepted.",
      fineprint: '1 card per user limit',
    },
    {
      value: 'virtualCard',
      title: 'Virtual Card',
      body: 'Create single-use or multi-use virtual cards as needed.',
    },
  ];

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl mb-10">What kind of corporate card would you like to create?</h1>
      <BoxSelect options={chooseCardOptions} onSelect={onCardSelect} />
    </div>
  );
};

export default ChooseCardStep;

import { useEffect, useState } from 'react';
import { cn } from 'utils';

const NotificationDot = () => {
  const [pingActive, setPingActive] = useState(true);

  useEffect(() => {
    //disable ping after 3 seconds
    setTimeout(() => {
      setPingActive(false);
    }, 3000);
  }, []);

  return (
    <span className="relative flex h-2 w-2">
      <span
        className={cn(
          pingActive ? 'animate-ping' : '',
          'absolute inline-flex h-full w-full rounded-full bg-accent-500 opacity-75'
        )}
      ></span>
      <span className="relative inline-flex rounded-full h-2 w-2 bg-accent-500"></span>
    </span>
  );
};

export default NotificationDot;

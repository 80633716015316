import { TextField } from '@aws-amplify/ui-react';
import { TextInput } from 'components/atoms/Inputs/TextInput';

type CardApprovalDecisionProps = {
  cardOwnerName: string;
  cardType: 'virtual' | 'physical';
  approveRequestLoading: boolean;
  approveRequestError: any;
  rejectRequestLoading: boolean;
  rejectRequestError: any;
  cardName: string;
  cardNameError: string | undefined;
  setCardName: (cardName: string) => void;
  dailyLimit: number | null;
  setDailyLimit: (dailyLimit: number | null) => void;
  monthlyLimit: number | null;
  setMonthlyLimit: (monthlyLimit: number | null) => void;
};

const CardApprovalDecision = ({
  cardOwnerName,
  cardType,
  approveRequestLoading,
  approveRequestError,
  rejectRequestLoading,
  rejectRequestError,
  cardName,
  cardNameError,
  setCardName,
  dailyLimit,
  setDailyLimit,
  monthlyLimit,
  setMonthlyLimit,
}: CardApprovalDecisionProps) => {
  return (
    <>
      <h1 className="text-3xl font-bold mb-2">Any edits? Want to add spending limits?</h1>
      <p className="text-[#68686B]">Please review the details below and make any changes.</p>

      {cardOwnerName && (
        <>
          <div className="mt-8">
            <label>
              Card requested by: <strong>{cardOwnerName}</strong>
            </label>
          </div>
          <div className="mt-8">
            <TextField
              placeholder="Card type"
              label="Card type"
              type="text"
              errorMessage={cardNameError}
              hasError={!!cardNameError}
              onChange={(e) => {
                setCardName(e.target.value);
              }}
              value={cardType === 'virtual' ? 'Virtual' : 'Physical'}
              disabled
            />
          </div>
          <div className="mt-8">
            <TextField
              placeholder="Card name"
              label="Card name"
              type="text"
              errorMessage={cardNameError}
              hasError={!!cardNameError}
              onChange={(e) => {
                setCardName(e.target.value);
              }}
              value={cardName}
            />
          </div>
          <div className="flex flex-row space-x-6 mt-4">
            <div className="grow">
              <TextInput
                type="number"
                required={false}
                prefix="$"
                label="Daily spending limit"
                placeholder="2,000"
                value={dailyLimit?.toString()}
                onChange={(e) => setDailyLimit(parseInt(e.target.value))}
              />
            </div>
            <div className="grow">
              <TextInput
                type="number"
                required={false}
                prefix="$"
                label="Monthly spending limit"
                placeholder="12,000"
                value={monthlyLimit?.toString()}
                onChange={(e) => setMonthlyLimit(parseInt(e.target.value))}
              />
            </div>
          </div>
          <p className="mt-2 mb-8">
            You can modify daily and monthly spending limits later in the settings.
          </p>
          {approveRequestLoading && <p>Waiting on approval...</p>}
          {rejectRequestLoading && <p>Waiting on rejection...</p>}

          {approveRequestError && <p>{approveRequestError.message}</p>}
          {rejectRequestError && <p>{rejectRequestError.message}</p>}
        </>
      )}
    </>
  );
};

export default CardApprovalDecision;

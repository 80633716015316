import { useState } from 'react';
import { CreateAccountLayout } from '../CreateAccountLayout';
import { RoundButton } from 'components/atoms/Buttons';
import { useCreateCompanyFormReducer, validateCreateCompanyFormState } from './state';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  CREATE_COMPANY_MUTATION,
  GetActiveCompanyQueryRefetch,
  useGetPendingInvitations,
} from './data';
import { CompanyTier } from '__generated__/graphql';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { Input } from 'components/atoms/Inputs/Input';
import { Field } from 'components/atoms/Inputs/Field';
import { NumberInput } from 'components/atoms/Inputs/NumberInput';
import { Alert } from 'components/atoms/Alert';
import { useCompany } from 'hooks/useCompany';
import { useActiveCompany } from 'providers/ActiveCompany';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const CreateCompanyAccountPage = () => {
  const navigate = useNavigate();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [errorMessage, setErrorMessage] = useState('');
  const [state, dispatch] = useCreateCompanyFormReducer();
  const [showFormErrors, setShowFormErrors] = useState(false);
  const { companyName, teamSize, companyMakesPurchasesOnBehalfOfClients, firstName, lastName } =
    state;
  const formValidation = validateCreateCompanyFormState(state);

  const [initializeCompanyAccount, { loading: loadingCreateCompany }] =
    useMutation(CREATE_COMPANY_MUTATION);

  const { loading, company } = useCompany({
    companySlug: activeCompanySlug,
  });

  const { pendingInvitations, loading: getUserPendingInvitationLoading } =
    useGetPendingInvitations();

  const onSubmit = async () => {
    setErrorMessage('');
    setShowFormErrors(true);

    if (
      formValidation.isValid &&
      state.companyName &&
      state.teamSize !== undefined &&
      state.firstName &&
      state.lastName &&
      state.companyMakesPurchasesOnBehalfOfClients !== undefined
    ) {
      if (!!errorMessage) {
        setErrorMessage('');
      }

      initializeCompanyAccount({
        variables: {
          companyName: state.companyName,
          teamSize: state.teamSize,
          tier: CompanyTier.lite,
          purchaseOnBehalfOfClients: state.companyMakesPurchasesOnBehalfOfClients,
          firstName: state.firstName,
          lastName: state.lastName,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GetActiveCompanyQueryRefetch }],
        onCompleted: (data) => {
          if (data?.initializeCompanyAccount) {
            navigate('/dashboard', { replace: true });
          }
        },
        onError: () => {
          setErrorMessage('Something went wrong. Please try again');
        },
      });
    }
  };

  if (loading || getUserPendingInvitationLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center min-h-80">
        <LoadingSpinner />
      </div>
    );
  }

  if (company) {
    return <Navigate to="/dashboard" replace />;
  }

  if (pendingInvitations.length > 0 && !company) {
    return <Navigate to="/pendingInvitations" replace />;
  }

  return (
    <>
      <CreateAccountLayout className="pt-12" hideBackButton allowSignout>
        <form
          className="grid gap-12"
          onSubmit={(e) => {
            e.preventDefault();
            // onSubmit();
          }}
        >
          <div className="grid gap-4">
            <p className="text-base font-medium">Tell us a little about you and your business</p>
            <Field
              label="First name"
              errorMessage={showFormErrors ? formValidation.firstName : undefined}
            >
              <Input
                placeholder="First name"
                value={firstName ?? ''}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_FIRST_NAME',
                    payload: e.currentTarget.value,
                  });
                }}
                inputState={showFormErrors && formValidation.firstName ? 'error' : 'normal'}
              />
            </Field>
            <Field
              label="Last name"
              errorMessage={showFormErrors ? formValidation.lastName : undefined}
            >
              <Input
                placeholder="Last name"
                value={lastName ?? ''}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_LAST_NAME',
                    payload: e.currentTarget.value,
                  });
                }}
                inputState={showFormErrors && formValidation.lastName ? 'error' : 'normal'}
              />
            </Field>
            <Field
              label="Company name"
              errorMessage={showFormErrors ? formValidation.companyName : undefined}
            >
              <Input
                placeholder="Company name"
                value={companyName ?? ''}
                onChange={(e) => {
                  dispatch({
                    type: 'UPDATE_COMPANY_NAME',
                    payload: e.currentTarget.value,
                  });
                }}
                inputState={showFormErrors && formValidation.companyName ? 'error' : 'normal'}
              />
            </Field>
            <Field
              label="Team size"
              errorMessage={showFormErrors ? formValidation.teamSize : undefined}
            >
              <NumberInput
                placeholder="Team size"
                value={teamSize !== undefined ? teamSize : undefined}
                onChange={(teamSize) => {
                  dispatch({
                    type: 'UPDATE_TEAM_SIZE',
                    payload: teamSize,
                  });
                }}
                min={0}
                maxDecimalPlaces={0}
                inputState={showFormErrors && formValidation.teamSize ? 'error' : 'normal'}
              />
            </Field>
          </div>

          <Field
            errorMessage={
              showFormErrors ? formValidation.companyMakesPurchasesOnBehalfOfClients : undefined
            }
            className="pb-12 border-b border-primary-400 border-solid"
          >
            <div className="grid gap-6">
              <p className="text-base font-medium w-2/3">
                As an Interior Designer, do you purchase on behalf of your clients?
              </p>
              <div className="flex flex-row gap-2">
                <RoundButton
                  variant="tertiary"
                  className={`px-12 ${
                    companyMakesPurchasesOnBehalfOfClients ? 'border-primary' : 'border-primary-400'
                  }`}
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: 'UPDATE_COMPANY_MAKES_PURCHASES_ON_BEHALF_OF_CLIENTS',
                      payload: true,
                    });
                  }}
                >
                  Yes
                </RoundButton>
                <RoundButton
                  variant="tertiary"
                  className={`px-12 ${
                    companyMakesPurchasesOnBehalfOfClients === false
                      ? 'border-primary'
                      : 'border-primary-400'
                  }`}
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: 'UPDATE_COMPANY_MAKES_PURCHASES_ON_BEHALF_OF_CLIENTS',
                      payload: false,
                    });
                  }}
                >
                  No
                </RoundButton>
              </div>
            </div>
          </Field>
          <div className="grid gap-6">
            <RoundButton text="Next" variant="primary" disabled={true} type="submit" />
            <div className="text-center text-sm">
              Currently we are not allowing new signups. Please check back later.
            </div>
          </div>
        </form>
      </CreateAccountLayout>

      {errorMessage && (
        <ToastContainer>
          <Alert
            variation="error"
            onDismiss={() => setErrorMessage('')}
            title="Something went wrong"
            description={errorMessage}
          />
        </ToastContainer>
      )}
    </>
  );
};

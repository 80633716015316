import { SettingsCompanyName } from './SettingsCompanyName';
import { TeamMembers } from './TeamMembers';

export const CompanyTab = () => {
  return (
    <>
      <div>
        <SettingsCompanyName />
      </div>
      <div>
        <TeamMembers />
      </div>
    </>
  );
};

import whiteGoldCardOverlay from '../../../images/whiteGoldCardOverlay.webp';

interface CardPreviewProps {
  cardName: string;
  cardType: string;
  last4Digits: string;
}

const CardPreview = ({ cardName, cardType, last4Digits }: CardPreviewProps) => {
  const last4 = last4Digits || '1234';
  const name = cardName || (cardType === 'physicalCard' ? 'Physical Card' : 'Virtual Card');
  return (
    <div className="relative">
      <img className="rounded-3xl h-[213px]" src={whiteGoldCardOverlay} alt="ZenaCard" />
      <p className="absolute bottom-20 left-6">{name}</p>
      <p className="absolute bottom-12 left-6 text-lg font-medium">
        <span className="mr-2 tracking-widest">••••</span>
        <span className="mr-2 tracking-widest">••••</span>
        <span className="mr-2 tracking-widest">••••</span>
        {last4}
      </p>
      <p className="absolute bottom-5 left-6 text-sm ">
        <span className="mr-2">EXP</span>
        <span className="tracking-widest mr-6">••/••</span>
        <span className="mr-2">CVV</span>
        <span className="tracking-widest"> •••</span>
      </p>
    </div>
  );
};

export default CardPreview;

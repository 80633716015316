import SlideOver, { SlideOverProps } from 'components/molecules/SlideOvers/SlideOver';
import { Transition } from '@headlessui/react';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';

interface SavablePanelProps extends SlideOverProps {
  onSave?: () => void;
  onClear?: () => void;
  hasErrors?: boolean;
}
function SavablePanel({ onSave, onClear, hasErrors, ...slideOverProps }: SavablePanelProps) {
  // hasErrorsPresence is used to determine if the hasErrors prop is present. If it is not present, then we do not want to render the error banner.
  const hasErrorsPresence = hasErrors !== undefined && hasErrors !== null;
  const footer = (
    <>
      {hasErrorsPresence && (
        <Transition
          show={hasErrors}
          enter="transform transition ease-in-out duration-300 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-300 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="z-5 bg-red-400 px-4 py-8">
            <div>
              <span className="text-2xl font-bold text-white">Uh oh!</span>
            </div>
            <div>
              <span className="text-lg text-white">
                Something doesnt look right. Please review.
              </span>
            </div>
          </div>
        </Transition>
      )}

      <div className="z-10 flex flex-shrink-0 justify-end bg-white px-4 py-4 items-center">
        <UnderlineButton text="Clear all" onClick={onClear} />
        <RoundButton
          text="Save Changes"
          disabled={hasErrorsPresence && hasErrors}
          onClick={() => {
            onSave && onSave();
          }}
        />
      </div>
    </>
  );
  return <SlideOver {...slideOverProps} footer={footer} />;
}
export default SavablePanel;

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useActiveCompany } from './ActiveCompany';

interface FeatureFlagProps {
  children: JSX.Element;
}

export function FeatureFlag({ children }: FeatureFlagProps) {
  const ldClient = useLDClient();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  useEffect(() => {
    if (ldClient && activeCompanySlug) {
      ldClient.identify({
        kind: 'customer',
        key: activeCompanySlug,
      });
    }
  }, [ldClient, activeCompanySlug]);

  return children;
}

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

// prettier-ignore-start
import './index.css';
// prettier-ignore-end
import { initAmplify } from 'aws-config';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import reportWebVitals from './reportWebVitals';

initAmplify();

Sentry.init({
  environment: process.env.NODE_ENV,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID!,
    options: {
      logger: basicLogger({ level: 'error' }),
    },
  });

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider>
          <LDProvider>
            <App />
          </LDProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { RoundButton } from 'components/atoms/Buttons';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { useEffect, useState } from 'react';
import { cn, formatNumberAsDollars, onApolloError } from 'utils';
import {
  CompanySettingsLink,
  Example,
  SmallSection,
  StyledTooltip,
  Subtitle,
  ToggleRow,
} from './helperComponents';
import { Pill } from 'components/atoms/Pill';
import { Toggle } from 'components/atoms/Inputs/Toggle';
import {
  useCompanyQboConfigurationSettings,
  useCompanyTransactionSettings,
  useQboCategories,
  useUpdateCompanyIntuitTagSettings,
} from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useNavigate } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { RequireAccountingTag } from 'components/widgets/Quickbooks';
import { Info } from 'lucide-react';

export const QboConfigurationSection = ({ classNames = '' }: { classNames?: string }) => {
  const navigate = useNavigate();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const {
    requireMemoOver,
    requireReceiptOver,
    requireMemo: isMemoRequired,
    requireReceipt: isReceiptRequired,
  } = useCompanyTransactionSettings({ companySlug: activeCompanySlug });
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);

  const [isZenaAutoTaggingEnabled, setIsZenaAutoTaggingEnabled] = useState(true);
  const {
    isQboAutoTaggingEnabled,
    requireAccountingTag,
    defaultExpenseCategoryId,
    loading: autoTaggingLoading,
  } = useCompanyQboConfigurationSettings({ companySlug: activeCompanySlug });
  const { updateCompanyIntuitTagSettings } = useUpdateCompanyIntuitTagSettings();
  const { qboCategories, loading: cateogoriesLoading } = useQboCategories({
    companySlug: activeCompanySlug,
    onError: (error) => {
      onApolloError(
        error,
        (errorMessage: string, errorType: string) => {
          if (errorType === 'TokenRefreshError') {
            setErrorMsg(
              'Your Quickbooks connection has become invalid. Please visit settings to reconnect.'
            );
          } else {
            setErrorMsg(errorMessage);
          }
        },
        ['TokenRefreshError']
      );
    },
  });

  useEffect(() => {
    if (
      isZenaAutoTaggingEnabled !== isQboAutoTaggingEnabled &&
      isQboAutoTaggingEnabled !== undefined &&
      activeCompanySlug
    ) {
      updateCompanyIntuitTagSettings({
        variables: {
          companySlug: activeCompanySlug,
          isQboAutoTaggingEnabled: isZenaAutoTaggingEnabled,
          requireAccountingTag: !!requireAccountingTag,
          defaultExpenseCategoryId: defaultExpenseCategoryId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZenaAutoTaggingEnabled]);

  useEffect(() => {
    if (
      isQboAutoTaggingEnabled !== undefined &&
      isQboAutoTaggingEnabled !== isZenaAutoTaggingEnabled
    ) {
      setIsZenaAutoTaggingEnabled(isQboAutoTaggingEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQboAutoTaggingEnabled]);

  return (
    <section className={cn('', classNames)}>
      <OutlinedWidget>
        <OutlinedWidget.Title>Configuration</OutlinedWidget.Title>
        <div className="grid gap-16 mt-6">
          <div className="grid gap-6">
            <SmallSection>
              <Subtitle>
                The following data is required on all transactions in Zena before they are sent to
                QBO
              </Subtitle>
              <p className="lg:max-w-[711px] font-light text-sm">
                These settings decide what makes a transaction “ready” to be sent to Quickbooks. We
                recommend business owners discuss this with their bookkeepers and accountants to
                ensure that transactions sent to QBO have the necessary information.
              </p>
            </SmallSection>
            <div className="grid gap-4">
              <ToggleRow>
                <div className="font-medium flex gap-2 items-center">
                  {`Receipts are required on all transactions${
                    requireReceiptOver
                      ? ` over ${formatNumberAsDollars(requireReceiptOver, true)}`
                      : ''
                  }`}
                  <Info className="cursor-pointer" data-tooltip-id="qbo-settings-2" />
                  <StyledTooltip id="qbo-settings-2">
                    <div className="grid gap-4">
                      <p>
                        In your <CompanySettingsLink /> you can require receipts for anything over a
                        certain dollar amount.
                      </p>

                      <p>
                        If you require a receipt on your <CompanySettingsLink /> then it is required
                        before sending a transaction to Quickbooks.
                      </p>
                    </div>
                  </StyledTooltip>
                </div>
                <div className="flex flex-row gap-3 items-center text-sm">
                  <Toggle
                    isActive={isReceiptRequired}
                    disabled
                    onToggle={() => {
                      // no op
                    }}
                  />
                  <div className="w-6">{isReceiptRequired ? 'Yes' : 'No'}</div>
                </div>
              </ToggleRow>
              <ToggleRow>
                <div className="font-medium flex gap-2 items-center">
                  {`Memos are required on all transactions${
                    requireMemoOver ? ` over ${formatNumberAsDollars(requireMemoOver, true)}` : ''
                  }`}
                  <Info className="cursor-pointer" data-tooltip-id="qbo-settings-3" />
                  <StyledTooltip id="qbo-settings-3">
                    <div className="grid gap-4">
                      <p>
                        In your <CompanySettingsLink /> you can require memos for anything over a
                        certain dollar amount.
                      </p>

                      <p>
                        If you require a memo on your <CompanySettingsLink /> then it is required
                        before sending a transaction to Quickbooks.
                      </p>
                    </div>
                  </StyledTooltip>
                </div>
                <div className="flex flex-row gap-3 items-center text-sm">
                  <Toggle
                    isActive={isMemoRequired}
                    disabled
                    onToggle={() => {
                      // no op
                    }}
                  />
                  <div className="w-6">{isMemoRequired ? 'Yes' : 'No'}</div>
                </div>
              </ToggleRow>
              <RequireAccountingTag />
            </div>
          </div>

          <div className="grid gap-8">
            <SmallSection>
              <Subtitle>Accounting tags</Subtitle>
              <p className="max-w-[600px] font-light text-sm">
                Accounting tags are how an accountant groups all transactions into the right
                accounting bucket. In Quickbooks. These are the “Expense Categories” on the General
                Ledger. Every transaction must have an accounting tag attached to it in order to be
                categorized.
              </p>
            </SmallSection>

            <SmallSection>
              <div className="font-bold">Inherited accounting categories from QBO</div>
              <p className="max-w-[600px] font-light text-sm">
                Zena inherits the accounting categories already set up in your QBO account. To
                update categories in Zena, you need to change them in Quickbooks.{' '}
              </p>
              <div className="flex gap-4 max-w-[740px] flex-wrap">
                {cateogoriesLoading && (
                  <>
                    <RectangleSkeleton width="small" height="large" />
                    <RectangleSkeleton width="small" height="large" />
                    <RectangleSkeleton width="small" height="large" />
                    <RectangleSkeleton width="small" height="large" />
                  </>
                )}
                {!cateogoriesLoading &&
                  qboCategories.length > 0 &&
                  qboCategories.map((category) => (
                    <Pill
                      variant="pending"
                      key={category.id}
                      text={category.accountName}
                      className="text-sm bg-forest-400"
                    />
                  ))}
                {!cateogoriesLoading && qboCategories.length === 0 && (
                  <p className="text-sm font-light">
                    Link your QBO account to see your QBO categories here
                  </p>
                )}
              </div>
            </SmallSection>
          </div>

          <SmallSection>
            <ToggleRow>
              <div className="font-bold">Zena auto tagging</div>
              <div className="flex flex-row gap-3 items-center text-sm">
                {autoTaggingLoading && <RectangleSkeleton width="medium" height="medium" rounded />}
                {!autoTaggingLoading && (
                  <>
                    <Toggle
                      isActive={isZenaAutoTaggingEnabled}
                      onToggle={(value) => {
                        setIsZenaAutoTaggingEnabled(value);
                      }}
                    />
                    <div className="w-6">{isZenaAutoTaggingEnabled ? 'Yes' : 'No'}</div>
                  </>
                )}
              </div>
            </ToggleRow>
            <p className="max-w-[600px] font-light text-sm">
              Your bookkeeper needs you to add an accounting tag to every transaction. This can get
              super tedious! Zena can add them automatically{' '}
              <span className="font-medium">on project transactions</span> if you set up a quick
              mapping.
            </p>
          </SmallSection>

          <SmallSection className="lg:pl-4">
            <p className="font-light text-sm">Example:</p>
            <div
              className={cn(
                'flex pl-4 flex-col gap-4',
                'lg:flex-row lg:justify-between lg:items-start lg:pl-8'
              )}
            >
              <div className="grid gap-4 items-start">
                <Example>
                  When you tag a project transaction with{' '}
                  <Pill
                    text="Paint"
                    variant="pending"
                    className="text-sm font-normal bg-accent-300"
                  />{' '}
                  or{' '}
                  <Pill
                    text="Fixtures"
                    variant="pending"
                    className="text-sm font-normal bg-accent-300"
                  />
                </Example>
                <Example>
                  Zena will add the accounting category automatically:{' '}
                  <Pill text="COGS" variant="pending" className="text-sm bg-forest-400" />
                </Example>
              </div>

              <RoundButton
                variant="secondary"
                onClick={() => navigate('configure')}
                disabled={!isZenaAutoTaggingEnabled}
              >
                Configure automation
              </RoundButton>
            </div>
          </SmallSection>
        </div>
      </OutlinedWidget>
    </section>
  );
};

import { ProjectWidget } from '../widgets/ProjectSpendWidget/ProjectSpendWidget';
import { DashboardContainer } from '../DashboardLayout/DashboardContainer';
import { StayOrganized } from '../DashboardCharts/StayOrganized';
import { SpendBreakdownWidget } from '../widgets/SpendBreakdownWidget';
import { ApplyForZenaCard } from '../../../components/widgets/marketing/ApplyForZenaCard';
import { PaymentsVsSpendWidget } from '../widgets/PaymentsVsSpendWidget';
import { IncomeWidget } from '../widgets/IncomeWidget';

export const LiteDashboard = () => {
  return (
    <>
      <DashboardContainer gridColumnsWidth={3} background="bg-tertiary-800">
        <div className="flex justify-center w-full">
          <ApplyForZenaCard />
        </div>
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={2}>
        <PaymentsVsSpendWidget />
      </DashboardContainer>

      <DashboardContainer>
        <SpendBreakdownWidget />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={2}>
        <IncomeWidget />
      </DashboardContainer>

      <DashboardContainer>
        <StayOrganized />
      </DashboardContainer>

      <DashboardContainer gridColumnsWidth={3}>
        <ProjectWidget />
      </DashboardContainer>
    </>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import { PhoneNumberField, SelectField, TextField } from '@aws-amplify/ui-react';
import { gql } from '__generated__';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { Button } from 'components/atoms/Buttons';
import { ProfilePhotoUploader } from './ProfilePhotoUploader';
import { getAllStates } from 'utils/us-states';
import useCustomAuth from 'hooks/useCustomAuth';
import { useAppState } from 'stores/UserStore';
import { cn, deformatPhoneNumber, formatPhoneNumber, onApolloError } from 'utils';
import useGooglePlaceAutoComplete from 'hooks/googlePlaceAutocomplete';
import { Input } from 'components/atoms/Inputs/Input';
import { Field } from 'components/atoms/Inputs/Field';
import { EmailInput } from 'components/atoms/Inputs/EmailInput';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Alert, AlertVariation } from 'components/atoms/Alert';

const MUTATION_UPDATE_USER = gql(`
  mutation UpdateUser($companySlug: String!, $userInput: AuthorizedUserInput!) {
    updateUser(companySlug: $companySlug, userInput: $userInput) {
        fullName {
            first,
            last
        },
        email,
        phone {
            countryCode,
            number
        },
        birthDate,
    }
  }
`);

const QUERY_USER_DETAILS = gql(`
  query UserDetails($companySlug: String!) {
    hasOAuthAccount(customerId: $companySlug) {
      hasOAuthAccount
    }
    isBusinessOwner {
      isBusinessOwner
    }
  }
`);

const getAddressObj = (
  cognitoAddress: string
): { street: string; street2: string; city: string; state: string; postalCode: string } => {
  // cognito address structure follows Open ID connect: https://openid.net/specs/openid-connect-core-1_0.html#AddressClaim
  const addressObj = cognitoAddress ? JSON.parse(cognitoAddress) : {};

  if (addressObj.street_address) {
    // Raw cognito address format
    const {
      street_address: fullStreetAddress,
      locality,
      region,
      postal_code: postalCode,
    } = addressObj;
    const street = fullStreetAddress ? fullStreetAddress.split('\n')[0] : '';
    const street2 =
      fullStreetAddress && fullStreetAddress.split('\n').length > 1
        ? fullStreetAddress.split('\n').slice(-1)[0]
        : '';

    return {
      street,
      street2,
      city: locality,
      state: region,
      postalCode,
    };
  } else {
    // CognitoUserAttributes address format
    const { street, street2, locality, region, postal_code } = addressObj;
    return {
      street,
      street2,
      city: locality,
      state: region,
      postalCode: postal_code,
    };
  }
};

const conditionallyWrapInTooltip = (
  condition: boolean,
  tooltipId: string,
  explanation: string,
  element: JSX.Element
) => {
  if (condition) {
    return (
      <>
        <div data-tooltip-id={tooltipId}>{element}</div>
        <Tooltip id={tooltipId}>{explanation}</Tooltip>
      </>
    );
  } else {
    return element;
  }
};

const MyProfileSettings = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { data: userDetailData } = useQuery(QUERY_USER_DETAILS, {
    variables: { companySlug: activeCompanySlug },
  });
  const googleAutoCompleteSvc = useGooglePlaceAutoComplete();

  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [updateUser] = useMutation(MUTATION_UPDATE_USER, {
    onError: (error) =>
      onApolloError(error, setErrorMsg, [
        'InvalidName',
        'UpdateUserFailed',
        'RestrictPIIChangesError',
        'InvalidCharacterError',
      ]),
  });
  const { user, isSSOUser, updateUserAttributes, fetchUser } = useCustomAuth();

  if (!user?.attributes) {
    throw new Error('Could not load user attributes from Cognito');
  }
  const {
    attributes: { email: cognitoEmail, address },
  } = user;

  const cognitoAddressObj = getAddressObj(address || '');

  // initialize the following values from Cognito
  const [firstName, setFirstName] = useState<string>(user?.attributes.given_name || '');
  const [lastName, setLastName] = useState<string>(user?.attributes.family_name || '');
  const [email, setEmail] = useState<string | undefined>(cognitoEmail);
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>(
    user?.attributes.phone_number || ''
  );
  const [countryCode, setCountryCode] = useState<string>('1');

  // Vermouth user attribute date of birth is saved as DateTime
  const address1Ref = useRef<HTMLInputElement>(null);
  let autoComplete = '';
  const [dob, setDOB] = useState<string>(user?.attributes.birthdate || '');
  const [street, setStreet] = useState<string>(cognitoAddressObj.street);
  const [apartmentNum, setApartmentNum] = useState<string>(cognitoAddressObj.street2);
  const [city, setCity] = useState<string>(cognitoAddressObj.city);
  const [state, setState] = useState<string>(cognitoAddressObj.state);
  const [zipCode, setZipCode] = useState<string>(cognitoAddressObj.postalCode);

  const [alert, setAlert] = useState<SetAlertParams>({ message: '', variation: 'success' });
  const [profileButtonText, setProfileButtonText] = useState<string>('Save');
  const [addressButtonText, setAddressButtonText] = useState<string>('Save');

  const [isResending, setIsResending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);

    if (address1Ref.current) {
      address1Ref.current.value = addressObj.address1;
    }

    setStreet(addressObj.address1);
    setApartmentNum('');
    setCity(addressObj.locality || addressObj.sublocality);
    setState(addressObj.adminArea1Short);
    setZipCode(addressObj.postalCode);

    googleAutoCompleteSvc.clearResult(autoComplete);
  };

  useEffect(() => {
    if (!user.attributes) {
      return;
    }
    setFirstName(user.attributes.given_name || '');
    setLastName(user.attributes.family_name || '');
    setEmail(user.attributes.email);

    let phoneNum = user.attributes.phone_number || '';
    const dialCode = phoneNum.substring(1, 2);
    phoneNum = phoneNum.substring(2);
    if (dialCode.length) {
      setCountryCode(dialCode);
    }
    setMobilePhoneNumber(formatPhoneNumber(phoneNum));

    setDOB(user.attributes.birthdate || '');
    const addressObj = getAddressObj(address || '');
    setStreet(addressObj.street);
    setApartmentNum(addressObj.street2);
    setCity(addressObj.city);
    setState(addressObj.state);
    setZipCode(addressObj.postalCode);

    if (address1Ref.current) {
      address1Ref.current.value = addressObj.street || '';
    }

    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
  }, []);
  const { resendForgotPassword, forgotPasswordSubmit } = useCustomAuth();
  const handleResendCode = async (username: string) => {
    setIsResending(true);
    const response = await resendForgotPassword(username);
    if (response.success) {
      setAlert({
        message: 'Code has been sent successfully!',
        variation: 'success',
      });
    } else {
      setAlert({
        message: response.errorMessage || 'Error sending code to email.',
        variation: 'error',
      });
    }
    setIsResending(false);
  };

  const onSubmitPassword = async (email: string) => {
    setIsSubmitting(true);
    try {
      const response = await forgotPasswordSubmit(email, code, password);
      if (response.success) {
        setAlert({
          message: 'Password updated successfully!',
          variation: 'success',
        });
      } else {
        setAlert({
          message:
            response.errorMessage || 'Error during updating password, please contact support',
          variation: 'error',
        });
      }
    } catch (error) {
      setAlert({
        message: 'Unknown error, please contact support',
        variation: 'error',
      });
    }
    setIsSubmitting(false);
  };

  const onSubmitProfile = () => {
    setProfileButtonText('Saving...');

    if (countryCode.length !== 1 || deformatPhoneNumber(mobilePhoneNumber).length !== 10) {
      setAlert({
        message: '+' + countryCode + mobilePhoneNumber + ' is not a valid phone number.',
        variation: 'error',
      });
      setProfileButtonText('Save');
      return;
    }
    if (email) {
      updateUser({
        variables: {
          companySlug: activeCompanySlug,
          userInput: {
            fullName: {
              first: firstName,
              last: lastName,
            },
            phone: {
              countryCode,
              number: deformatPhoneNumber(mobilePhoneNumber),
            },
            email,
            birthDate: dob,
          },
        },
      }).then((res) => {
        if (res.data?.updateUser) {
          setAlert({
            message: 'Profile information updated successfully!',
            variation: 'success',
          });
        }
        fetchUser();
        setProfileButtonText('Save');
      });
    }
  };

  const onSubmitAddress = async () => {
    setAddressButtonText('Saving...');
    const addressAttribute = {
      street_address: [street, apartmentNum].filter(Boolean).join('\n'),
      locality: city,
      region: state,
      postal_code: zipCode,
    };
    const userAttributes = { address: JSON.stringify(addressAttribute) };
    // update user data in cognito

    const response = await updateUserAttributes(userAttributes);

    if (!response.success) {
      console.error(`Error while updating cognito user attributes: ${response.errorMessage}`);
      setAlert({
        message: response.errorMessage || '',
        variation: 'error',
      });
    }
    if (response.success) {
      setAlert({
        message: 'Address updated successfully!',
        variation: 'success',
      });
    }
    setAddressButtonText('Save');
  };

  const hasOAuthAccount = !!userDetailData?.hasOAuthAccount?.hasOAuthAccount;
  const isBusinessOwner = !!userDetailData?.isBusinessOwner?.isBusinessOwner;
  const businessOwnerExplanation =
    'As the business owner, you will need to contact Zena support to change these values';

  return (
    <div>
      <SettingsSectionContainer className="pt-0">
        <SettingSectionTitleDescriptionContainer
          title="Profile information"
          description="Edit your profile settings."
        />
        <SectionFormContainer>
          <InputsRow>
            <Field label="First name">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <Input
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                />
              )}
            </Field>
            <Field label="Last name">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <Input
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                />
              )}
            </Field>
          </InputsRow>

          <Field label="Email">
            {conditionallyWrapInTooltip(
              hasOAuthAccount,
              'oauth-warning',
              'You are using Google to login. Please reach out to Zena support to change your email address',
              <EmailInput
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={hasOAuthAccount}
              />
            )}
          </Field>

          <Field label="Mobile phone number">
            <PhoneNumberField
              labelHidden
              label=""
              className="rounded-lg"
              defaultDialCode="+1"
              dialCodeList={['+1']}
              onDialCodeChange={(e) => setCountryCode(e.target.value.substring(1))}
              value={mobilePhoneNumber}
              onChange={(e) => {
                setMobilePhoneNumber(formatPhoneNumber(e.target.value));
              }}
            />
          </Field>
          <div className="p-4 border-2 rounded-lg border-grey-500">
            <div className="font-bold mb-1">Profile image</div>
            <div>Let your team members see your beautiful face.</div>
            <ProfilePhotoUploader setAlert={setAlert} setProfileButtonText={setProfileButtonText} />
          </div>
          {conditionallyWrapInTooltip(
            isBusinessOwner,
            'business-owner-warning',
            businessOwnerExplanation,
            <TextField
              label="Date of Birth"
              type={'date'}
              className="rounded-lg"
              value={dob}
              onChange={(e) => {
                setDOB(e.target.value);
              }}
              disabled={isBusinessOwner}
            />
          )}
          <Button
            text={profileButtonText}
            disabled={profileButtonText === 'Saving...'}
            onClick={onSubmitProfile}
          />
        </SectionFormContainer>
      </SettingsSectionContainer>
      <SettingsSectionContainer>
        <SettingSectionTitleDescriptionContainer
          title="Physical Address (optional)"
          description="Let us know where we can reach you. We'll keep snail mail to an absolute minimum."
        />
        <SectionFormContainer>
          <Field label="Street">
            {conditionallyWrapInTooltip(
              isBusinessOwner,
              'business-owner-warning',
              businessOwnerExplanation,
              <Input
                ref={address1Ref}
                className="rounded-lg"
                value={street}
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
                disabled={isBusinessOwner}
              />
            )}
          </Field>
          <InputsRow>
            <Field label="Apartment, unit, suite (Optional)">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <Input
                  className="rounded-lg"
                  value={apartmentNum}
                  onChange={(e) => {
                    setApartmentNum(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                />
              )}
            </Field>
            <Field label="City">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <Input
                  className="rounded-lg"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                />
              )}
            </Field>
          </InputsRow>

          <InputsRow>
            <Field label="State">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <SelectField
                  label="State"
                  labelHidden
                  className="rounded-lg"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                >
                  {getAllStates().map((s) => (
                    <option value={s.isoCode} key={s.name}>
                      {s.name}
                    </option>
                  ))}
                </SelectField>
              )}
            </Field>
            <Field label="Zip code">
              {conditionallyWrapInTooltip(
                isBusinessOwner,
                'business-owner-warning',
                businessOwnerExplanation,
                <Input
                  className="rounded-lg"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  disabled={isBusinessOwner}
                />
              )}
            </Field>
          </InputsRow>
          {conditionallyWrapInTooltip(
            isBusinessOwner,
            'business-owner-warning',
            businessOwnerExplanation,
            <Button
              text={addressButtonText}
              disabled={addressButtonText === 'Saving...' || isBusinessOwner}
              onClick={onSubmitAddress}
            />
          )}
        </SectionFormContainer>
        {alert.message.length > 0 && (
          <div className="w-3/4 py-4 z-10 fixed bottom-0 right-0 mr-8">
            <Alert
              variation={alert.variation}
              onDismiss={() => setAlert({ message: '', variation: 'success' })}
            >
              {alert.message}
            </Alert>
          </div>
        )}
      </SettingsSectionContainer>
      {!isSSOUser && (
        <>
          <SettingsSectionContainer className="border-none">
            <SettingSectionTitleDescriptionContainer
              title="Change Password"
              description="We will send a code to your email address to change your password."
            />
            <SectionFormContainer>
              <Field label="Email">
                <EmailInput value={email} disabled />
              </Field>
              <Button
                text="Send code"
                disabled={isResending}
                onClick={(e) => {
                  e.preventDefault();
                  if (email) {
                    handleResendCode(email);
                  }
                }}
              />
              <Field label="Verification Code">
                <Input
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  type="password"
                />
              </Field>
              <Field label="New Password">
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                />
              </Field>
              <Button
                text="Set password"
                disabled={isSubmitting}
                onClick={() => email && onSubmitPassword(email)}
              />
            </SectionFormContainer>
          </SettingsSectionContainer>
        </>
      )}
    </div>
  );
};

const SettingsSectionContainer = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'grid gap-8 lg:grid-cols-[1fr_2fr] lg:gap-4 py-8 border-b border-primary-400',
        className
      )}
    >
      {children}
    </div>
  );
};

const SettingSectionTitleDescriptionContainer = ({
  className,
  title,
  description,
}: {
  className?: string;
  title: string;
  description: string;
}) => {
  return (
    <div className={cn('grid gap-2 content-baseline', className)}>
      <div className="font-bold">{title}</div>
      <div className="text-gray-500">{description}</div>
    </div>
  );
};

const SectionFormContainer = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={cn('grid gap-4 content-baseline w-full', className)}>{children}</div>;
};

const InputsRow = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
  return <div className={cn('grid grid-cols-[1fr_1fr] gap-4', className)}>{children}</div>;
};

export type SetAlertParams = {
  message: string;
  variation: AlertVariation;
};

export default MyProfileSettings;

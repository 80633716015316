import { Table } from '@tanstack/react-table';

import { cardType, statuses, useMutationBulkCloseCards, useMutationBulkFreezeCards } from './data';
import { FilterMenu } from './FilterMenu';
import { Button, RoundButton } from 'components/atoms/Buttons';
import { ChevronDown, X, Lock, Ban } from 'lucide-react';
import { SearchInput } from 'components/molecules/SearchInput/SearchInput';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { Card } from '__generated__/graphql';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/DropdownMenu';
import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogDescription,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from 'components/ui/Dialog';
import { BadgeFlat } from 'components/ui/Badge';
import { Item } from '@radix-ui/react-dropdown-menu';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function Filters<TData>({
  table,
  globalFilter,
  setGlobalFilter,
}: DataTableToolbarProps<TData>) {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const isFiltered = table.getState().columnFilters.length > 0;

  const disabled = !table.getIsSomeRowsSelected();

  let cardIds = table.getSelectedRowModel().rows.map((row) => (row.original as Card).id);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <div className="w-[150px] lg:w-[350px]">
          <SearchInput
            placeholder="Search for a card..."
            searchTerm={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        {table.getColumn('status') && (
          <FilterMenu column={table.getColumn('status')} title="Status" options={statuses} />
        )}
        {table.getColumn('type') && (
          <FilterMenu column={table.getColumn('type')} title="Card type" options={cardType} />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger disabled={disabled}>
          <RoundButton disabled={disabled}>
            Bulk Actions <ChevronDown />
          </RoundButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <FreezeAction companySlug={companySlug} cardIds={cardIds} />
          <CloseAction companySlug={companySlug} cardIds={cardIds} />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

const DropdownMenuItemButton = React.forwardRef<
  React.ElementRef<typeof Item>,
  React.ComponentPropsWithoutRef<typeof Item>
>((props, ref) => {
  return (
    <DropdownMenuItem
      onSelect={(e) => {
        e.preventDefault();
      }}
      ref={ref}
      className="flex items-center gap-2 text-base p-3 cursor-pointer"
      {...props}
    />
  );
});

interface ActionsProps {
  companySlug: string;
  cardIds: string[];
}

function FreezeAction({ companySlug, cardIds }: ActionsProps) {
  const { trigger } = useMutationBulkFreezeCards();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBulkFreeze = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await trigger({
      variables: {
        companySlug,
        cardIds,
      },
      onCompleted: () => {
        setDialogOpen(false);
      },
    });
  };
  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <DropdownMenuItemButton>
          <Lock size={18} className="stroke-secondary-800" />
          Freeze Selected Cards
        </DropdownMenuItemButton>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure sure?</AlertDialogTitle>
          <AlertDialogDescription>
            Freezing a credit card will decline any future purchases. You can unfreeze a cards at
            any time.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleBulkFreeze}>
            Freeze Cards{' '}
            <BadgeFlat
              variant="secondary"
              className="rounded-full h-5 w-5 p-0 flex items-center justify-center bg-white"
            >
              {cardIds.length}
            </BadgeFlat>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function CloseAction({ companySlug, cardIds }: ActionsProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger } = useMutationBulkCloseCards();
  const handleBulkClose = async () => {
    await trigger({
      variables: {
        companySlug,
        cardIds,
      },
      onCompleted: () => {
        setDialogOpen(false);
      },
    });
  };
  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <DropdownMenuItemButton>
          <Ban size={18} className="stroke-secondary-800" />
          Close Selected Cards
        </DropdownMenuItemButton>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently close the selected cards.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleBulkClose} variant="danger">
            Freeze Cards{' '}
            <BadgeFlat
              variant="secondary"
              className="rounded-full h-5 w-5 p-0 flex items-center justify-center bg-white"
            >
              {cardIds.length}
            </BadgeFlat>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

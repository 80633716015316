import { useCallback, useEffect, useState } from 'react';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { RoundButton } from 'components/atoms/Buttons';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { cleanKey, processFile } from 'utils/upload';
import useCustomAuth from 'hooks/useCustomAuth';
import { useTransactionDetailsContext } from '../state';
import { useGetReceiptImage } from './data';
import { cn } from 'utils';
import { ArrowUpToLine, X } from 'lucide-react';

export const TransactionDetailsUploadReceipt = ({
  transactionId,
  showMissingRequirements,
}: {
  transactionId: string;
  showMissingRequirements: boolean;
}) => {
  const { receiptImageUrl, missingReceipt } = useGetReceiptImage({ transactionId });
  const { user } = useCustomAuth();
  const { dispatch } = useTransactionDetailsContext();
  const userSub = user?.attributes?.sub;
  const [fileKey, setFileKey] = useState<string>();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasUploadedReceipt, setHasUploadedReceipt] = useState(false);

  const onUploadError = useCallback((error: string, file: { key: string }) => {
    console.error(error, file);
  }, []);

  useEffect(() => {
    if (receiptImageUrl) {
      setHasUploadedReceipt(true);
      if (!!fileKey) {
        // uploaded receipt was updated so we clear this component's local state
        setFileKey(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptImageUrl]);

  return (
    <div className="grid gap-2 max-w-full min-w-0">
      <div className="text-xl">Upload receipt</div>
      <StorageManager
        acceptedFileTypes={['image/*', 'application/pdf']}
        accessLevel="private"
        maxFileSize={5 * 1024 * 1024} // 5.24288 MB
        maxFileCount={1}
        processFile={({ file, key }) =>
          processFile({
            file,
            modelId: transactionId,
            modelType: 'transaction',
            userSub: userSub ?? '',
          })
        }
        onUploadError={onUploadError}
        displayText={{
          browseFilesText: 'or click here',
          // others are functions that take an argument
          getFilesUploadedText(count) {
            return count > 0 ? '1 image uploaded' : '';
          },
        }}
        onFileRemove={() => setFileKey(undefined)}
        onUploadSuccess={({ key }) => {
          if (key) {
            setErrorMessage('');
            setHasUploadedReceipt(true);
            dispatch({
              type: 'UPDATE_RECEIPT_IMAGE_URL',
              payload: key,
            });
          }
        }}
        onUploadStart={({ key }) => setFileKey(key)}
        components={{
          Container({ children }) {
            return <div className="flex flex-col gap-2">{children}</div>;
          },
          FilePicker({ onClick }) {
            return (
              <button
                className={cn(
                  'border-2 rounded-lg p-4  bg-inherit',
                  showMissingRequirements && missingReceipt && !hasUploadedReceipt
                    ? 'border-accent-800 border-4'
                    : 'border-primary-400 border-2 border-dotted'
                )}
                style={{ width: '100%' }}
                onClick={onClick}
              >
                <div className="flex gap-4 items-center">
                  <ArrowUpToLine />
                  <div className="flex flex-col gap-2">
                    <div className="font-medium">Click to add or drag receipt here</div>
                    <div className="text-sm text-left">JPG, PNG, PDF - 5 MB max</div>
                  </div>
                </div>
              </button>
            );
          },
          DropZone({ children, displayText, inDropZone, ...rest }) {
            return (
              // Copy default amplify storage manager dropzone style
              <div
                className={cn(inDropZone && 'amplify-storagemanager__dropzone--active')}
                {...rest}
              >
                {children}
              </div>
            );
          },
          FileList({ files, ...rest }) {
            const error = files[files.length - 1]?.error ?? '';
            if (errorMessage !== error) {
              setErrorMessage(error);
            }
            return (
              <div className="grid w-full px-2 gap-4">
                {!!errorMessage && <div className="text-sm text-danger">{errorMessage}</div>}
                {files
                  .filter(({ key }) => {
                    const cleanedKey = cleanKey({ key });
                    const fileKeyPart = fileKey
                      ? fileKey.split('/')[fileKey.split('/').length - 1]
                      : '';
                    return fileKeyPart && fileKeyPart && cleanedKey === fileKeyPart;
                  })
                  .filter((f, index) => index === 0)
                  .map(({ file, key, progress, id }) =>
                    progress === 100 ? (
                      <div className="flex gap-4 items-center justify-between w-full" key={key}>
                        <img
                          className="object-cover w-12 h-12"
                          src={file ? URL.createObjectURL(file) : ''}
                          alt={key}
                        />
                        <div className="font-medium flex-auto">{id}</div>
                        <RoundButton
                          className="p-3"
                          variant="tertiary"
                          onClick={() => {
                            setHasUploadedReceipt(false);
                            rest.onDeleteUpload({ id: id });
                          }}
                        >
                          <X />
                        </RoundButton>
                        {progress < 100 && <LoadingSpinner />}
                      </div>
                    ) : (
                      <RectangleSkeleton className="h-[80px] rounded-md" />
                    )
                  )}
              </div>
            );
          },
          FileListHeader() {
            return <div></div>;
          },
        }}
      />
      {!fileKey && receiptImageUrl && (
        <div className="flex flex-col gap-1">
          <div>Current image</div>
          <div className="flex gap-4 items-center justify-between w-full">
            <a href={receiptImageUrl} target="_blank" rel="noreferrer">
              <img className="object-cover w-12" src={receiptImageUrl} alt={'receipt'} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Radio, RadioGroupField } from '@aws-amplify/ui-react';
import { Card } from '__generated__/graphql';
import { Button } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import { NotificationBanner } from 'components/atoms/NotificationBanner';
import { useState } from 'react';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';

export interface LostOrStolenModalProps {
  show: boolean;
  onClose: Function;
  cardId: string;
  customerId: string;
  cardData: Card;
}

const MUTATION_REPORT_LOST = gql(`
  mutation ReportLostCard($customerId: String!, $cardId: String!) {
    reportLostCard(customerId: $customerId, cardId: $cardId) {
      id
    }
  }
`);

const MUTATION_REPORT_STOLEN = gql(`
  mutation ReportStolenCard($customerId: String!, $cardId: String!) {
    reportStolenCard(customerId: $customerId, cardId: $cardId) {
      id
    }
  }
`);

const LostOrStolenModal = ({
  show,
  onClose,
  cardData,
  cardId,
  customerId,
}: LostOrStolenModalProps) => {
  const [confirmationSectionActive, setConfirmationSectionActive] = useState(false);
  const [reportType, setReportType] = useState('lost');
  const [, setErrorMessage] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);

  const [reportLost] = useMutation(MUTATION_REPORT_LOST, {
    variables: {
      cardId,
      customerId,
    },
    onCompleted: () => {
      onClose(true, reportType);
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  const [reportStolen] = useMutation(MUTATION_REPORT_STOLEN, {
    variables: {
      cardId,
      customerId,
    },
    onCompleted: () => {
      onClose(true, reportType);
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  const handleSubmit = () => {
    if (reportType === 'lost') {
      reportLost();
    } else {
      reportStolen();
    }
  };

  return (
    <Modal show={show} onClose={() => onClose(false)}>
      <div className="font-['Helvetica'] leading-normal">
        <div className="font-bold text-[14px] text-gray-500">Report card</div>
        {!confirmationSectionActive ? (
          <>
            <h2 className="font-bold text-2xl mb-4">
              Report card ending in **{cardData.last4Digits}
            </h2>
            <div className="text-base text-gray-500 mb-12">What happened to your card?</div>
            <RadioGroupField
              value={reportType}
              legend="Report Type"
              legendHidden
              name="reportType"
              onChange={(event) => setReportType(event.target.value)}
            >
              <Radio value="lost">My card was lost</Radio>
              <Radio value="stolen">My card was stolen</Radio>
            </RadioGroupField>
            <Button className="mt-8 w-full" onClick={() => setConfirmationSectionActive(true)}>
              Proceed
            </Button>
          </>
        ) : (
          <>
            <h2 className="font-bold text-2xl mb-4">Are you sure you want to report this card?</h2>
            <div className="text-base text-gray-500 mb-12">
              Reporting your card will close it permanently.
            </div>
            <NotificationBanner
              variation="warning"
              message="A Lost or Stolen card will be permanently closed."
            />
            <Button className="mt-4 w-full" onClick={handleSubmit}>
              Proceed
            </Button>
            <Button
              className="mt-4 w-full"
              variant="secondary"
              onClick={() => setConfirmationSectionActive(false)}
            >
              Back
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default LostOrStolenModal;

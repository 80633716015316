import { NotificationDot } from 'components/atoms/NotificationDot';
import { useGetTransactionsRequireAttentionNotification } from './data';
import { useActiveCompany } from 'providers/ActiveCompany';

const TransactionsRequireAttentionNotification = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { transactionsNeedingAttentionNotification } =
    useGetTransactionsRequireAttentionNotification({
      customerId: activeCompanySlug,
      pageNumber: 0,
      pageSize: 30,
    });

  if (!transactionsNeedingAttentionNotification) {
    return null;
  }

  return (
    <div className="flex items-center h-full">
      <NotificationDot />
    </div>
  );
};

export default TransactionsRequireAttentionNotification;

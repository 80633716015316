import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import whiteZenaCardWood from '../../images/whiteZenaCardWood.webp';
import zenaLogo from '../../images/zenaLogo.webp';
import useCustomAuth from 'hooks/useCustomAuth';
import { AuthFormStates } from 'types';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import ConfirmSignUpForm from './ConfirmSignUpForm';

const Invitation = () => {
  const [formState, setFormState] = useState('signUp');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get('email') || '';
  const tokenParam = searchParams.get('token') || '';

  const [username, setUsername] = useState(emailParam);

  const { user } = useCustomAuth();

  useEffect(() => {
    // Add use effect if user is signed in, go to join team form.
    if (user) {
      const urlSafeEmail = encodeURIComponent(emailParam);
      navigate(`/join-team?email=${urlSafeEmail}&token=${tokenParam}`, { replace: true });
    }
  });

  const changeFormState = (formState: AuthFormStates) => {
    setFormState(formState);
  };

  const handleSetUsername = (username: string) => {
    setUsername(username);
  };

  return (
    <div className="grid h-screen w-screen bg-secondary-400">
      <div className="flex flex-col xl:grid xl:grid-cols-3 h-full">
        <div className="m-8">
          <div className="flex w-full justify-center xl:block">
            <img className="h-6" src={zenaLogo} alt="Zena Logo"></img>
          </div>
        </div>
        <div className="w-full md:w-fit place-self-center ">
          <div className="p-12 bg-ivory rounded-4xl m-4 md:w-[500px]">
            <div>
              {formState === 'signUp' && (
                <SignUpForm
                  title="Join your team on Zena"
                  description="Finish creating your account by choosing a sign up method below."
                  changeFormState={changeFormState}
                  handleSetUsername={handleSetUsername}
                  prefillEmail={username}
                />
              )}
              {formState === 'confirmSignUp' && <ConfirmSignUpForm username={username} />}
              {formState === 'signIn' && (
                <SignInForm
                  changeFormState={changeFormState}
                  handleSetUsername={handleSetUsername}
                  description=""
                  prefillEmail={username}
                />
              )}
            </div>
          </div>
        </div>
        <div className="lg:flex flex-col justify-between">
          <div className="lg:my-8 xl:text-right xl:mr-8 xl:w-auto xl:block flex flex-col text-center w-full justify-center">
            <p className="text-asphalt">
              {formState === 'signUp' ? 'Already using Zena?' : 'New to Zena?'}
            </p>
            <button
              className="underline"
              onClick={(e) => {
                e.preventDefault();
                changeFormState(formState === 'signUp' ? 'signIn' : 'signUp');
              }}
            >
              {formState === 'signUp' ? 'Log in to your account' : 'Create an account'}
            </button>
          </div>
          <div className="w-[400px] hidden xl:flex self-end">
            <img className="p-10" src={whiteZenaCardWood} alt="Zena Card"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;

import { LuxeDashboard } from './LuxeDashboard';
import { LiteDashboard } from './LiteDashboard';
import { MarketingDashboard } from './MarketingDashboard';
import { useCompanyTransactionsCount } from './data';
import { DashboardLayout } from './DashboardLayout/DashboardLayout';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { DashboardContainer } from './DashboardLayout/DashboardContainer';
import { CompanyTier } from '__generated__/graphql';
import { PageTitle } from 'components/atoms/PageTitle';
import { isAdmin } from 'hooks/access';
import { LuxeTeamMemberDashboard } from './LuxeTeamMemberDashboard';
import { useCompany } from 'hooks/useCompany';
import { useActiveCompany } from 'providers/ActiveCompany';

export const DashboardPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const isAdminUser = isAdmin(activeCompanySlug);

  const { loading: loadingCompanyTier, company } = useCompany({
    companySlug: activeCompanySlug,
  });
  const { loading: loadingHasTransactions, hasTransactions } = useCompanyTransactionsCount({
    companyId: activeCompanySlug,
  });

  const isLoading = loadingCompanyTier || loadingHasTransactions;
  const isLuxeCompany = company?.tier === CompanyTier.luxe;

  return (
    <>
      <PageTitle text="Dashboard" hidden />
      <DashboardLayout>
        {isLoading && <BaseLoadingDashboard />}

        {!isLoading && isLuxeCompany && isAdminUser && <LuxeDashboard />}

        {!isLoading && isLuxeCompany && !isAdminUser && <LuxeTeamMemberDashboard />}

        {!isLoading && !isLuxeCompany && hasTransactions && <LiteDashboard />}

        {!isLoading && !isLuxeCompany && !hasTransactions && <MarketingDashboard />}
      </DashboardLayout>
    </>
  );
};

const BaseLoadingDashboard = () => {
  return (
    <>
      <DashboardContainer gridColumnsWidth={2} className="border-0 p-0">
        <RectangleSkeleton width="full" rounded className="h-60" />
      </DashboardContainer>
      <DashboardContainer gridColumnsWidth={1} className="border-0 p-0">
        <RectangleSkeleton width="full" rounded className="h-60" />
      </DashboardContainer>
      <DashboardContainer gridColumnsWidth={3} className="border-0 p-0">
        <RectangleSkeleton width="full" rounded className="h-60" />
      </DashboardContainer>
      <DashboardContainer gridColumnsWidth={3} className="border-0 p-0">
        <RectangleSkeleton width="full" rounded className="h-60" />
      </DashboardContainer>
    </>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from 'components/atoms/PageTitle';
import { LoginLayout } from '../LoginLayout';
import useCustomAuth from 'hooks/useCustomAuth';
import { RoundButton } from 'components/atoms/Buttons';
import { ToastContainer } from 'components/atoms/ToastContainer';
import { EmailInput } from 'components/atoms/Inputs/EmailInput';
import { Field } from 'components/atoms/Inputs/Field';
import { isValidEmail } from 'utils/email';
import { Alert } from 'components/atoms/Alert';

export const ForgotPasswordPage = () => {
  const { resendForgotPassword } = useCustomAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailErrorMessage =
    showFormErrors && !email
      ? 'Email is required'
      : showFormErrors && !isValidEmail(email)
        ? 'Invalid Email'
        : undefined;

  const onSubmit = async () => {
    setIsSubmitting(true);
    setShowFormErrors(true);
    setErrorMessage('');

    if (email && isValidEmail(email)) {
      try {
        const response = await resendForgotPassword(email);
        if (response.success || response.errorCode === 'UserNotFoundException') {
          setErrorMessage('');
          setShowFormErrors(false);
          navigate(`/reset-password`, {
            state: {
              resetPasswordEmail: email,
            },
          });
        } else {
          setErrorMessage('Error sending email, please contact support or try again');
        }
      } catch (error) {
        setErrorMessage('Unknown error, please contact support');
      }
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <LoginLayout className="grid gap-8" hideQuotes>
        <PageTitle text="Forgot password" hidden />
        <div className="grid gap-2">
          <h1 className="text-2xl">Forgot password?</h1>
          <p className="text-muted text-lg">No worries, we'll send you reset instructions.</p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="grid gap-4"
        >
          <Field label="Email" errorMessage={emailErrorMessage}>
            <EmailInput
              value={email}
              onChange={(e) => {
                setShowFormErrors(false);
                setEmail(e.currentTarget.value);
              }}
              inputState={!!emailErrorMessage ? 'error' : 'normal'}
            />
          </Field>
          <RoundButton
            type="submit"
            className="w-full"
            variant="primary"
            text="Send code"
            disabled={isSubmitting}
          />
        </form>
      </LoginLayout>

      {errorMessage && (
        <ToastContainer>
          <Alert
            variation="error"
            onDismiss={() => setErrorMessage('')}
            title="Something went wrong"
            description={errorMessage}
          />
        </ToastContainer>
      )}
    </>
  );
};

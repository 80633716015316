import { Select, SelectProps } from 'components/atoms/Inputs/Select';
import { Pill } from 'components/atoms/Pill';
import { cn } from 'utils';

export { default as DeprecatedTransactionTagSelection } from './TransactionTagSelection';

interface Props extends Omit<SelectProps, 'children'> {
  error?: string;
  onSelectTag: (tag: string) => void;
  tags: string[];
  selectedTags: string[];
  onRemoveTag: (tag: string) => void;
  tagColor?: string;
  placeholder?: string;
  className?: string;
  isMissing?: boolean;
}

export const TransactionTagSelection = ({
  onSelectTag,
  tags,
  selectedTags,
  onRemoveTag,
  tagColor = 'bg-accent-300',
  placeholder = 'Select tags',
  className = '',
  isMissing = false,
  ...props
}: Props) => {
  const availableTags = tags.filter((tag) => !selectedTags.includes(tag));

  return (
    <div className={cn('grid gap-2', className)}>
      <Select
        value={''}
        onChange={(e) => {
          const tag = e.currentTarget.value;

          if (tag) {
            onSelectTag(tag);
          }
        }}
        className={isMissing ? 'border-accent-800 border-4' : ''}
        {...props}
      >
        <option value="">{placeholder}</option>
        {availableTags.map((availableTag) => (
          <option key={availableTag} value={availableTag}>
            {availableTag}
          </option>
        ))}
      </Select>

      <div className="flex gap-4 flex-wrap">
        {selectedTags.map((selectedTag) => (
          <Pill
            key={selectedTag}
            text={selectedTag}
            variant={'cancelable'}
            className={cn('text-sm capitalize', tagColor)}
            onClickCancel={() => onRemoveTag(selectedTag)}
          />
        ))}
      </div>
    </div>
  );
};

import useCustomAuth from 'hooks/useCustomAuth';
import { RoundButton } from 'components/atoms/Buttons';
import { GoogleIcon } from 'icons/googleIcon';

type GoogleSignInButtonProps = {
  text: string;
};
export const GoogleSignInButton = (props: GoogleSignInButtonProps) => {
  const { text } = props;
  const { googleSignin } = useCustomAuth();
  return (
    <RoundButton
      variant="secondary"
      onClick={(e) => {
        e.preventDefault();
        googleSignin();
      }}
    >
      <GoogleIcon />
      <p className="ml-2 font-medium">{text}</p>
    </RoundButton>
  );
};

import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { motion } from 'framer-motion';
import { Button, RoundButton } from 'components/atoms/Buttons';
import {
  selectTransactionDetailsTransactionMetadata,
  TransactionDetailsStateProvider,
  useTransactionDetailsContext,
  validateTransactionDetailsState,
} from './state';
import { TransactionDetails, TransactionDetailsProps } from './TransactionDetails';
import { Drawer } from 'components/atoms/Drawer';
import {
  MUTATION_UPDATE_CHILD_TRANSACTION,
  MUTATION_UPDATE_STANDARD_TRANSACTION,
  useTransactionDetails,
} from './data';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useAppState } from 'stores/UserStore';
import { isCredit } from 'utils';
import { fetchAuthSession } from 'aws-amplify/auth';

interface Props extends Omit<TransactionDetailsProps, 'className'> {
  isVisible: boolean;
  transactionId: string;
  onUpdateTransaction?: () => void;
  showMissingRequirements?: boolean;
}

export const TransactionDetailsDrawer = (props: Props) => {
  return (
    <TransactionDetailsStateProvider>
      <TransactionDetailsDrawerContent {...props} />
    </TransactionDetailsStateProvider>
  );
};

export const TransactionDetailsDrawerContent = ({
  isVisible,
  onClose,
  transactionId,
  onUpdateTransaction,
  ...props
}: Props) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { setErrorMsg } = useAppState();
  const { state, dispatch } = useTransactionDetailsContext();

  const [updateChildTransaction, { loading: isChildTransactionUpdating }] = useMutation(
    MUTATION_UPDATE_CHILD_TRANSACTION
  );
  const [updateTransactions, { loading: isSavingTransaction }] = useMutation(
    MUTATION_UPDATE_STANDARD_TRANSACTION
  );

  const isLoading = isChildTransactionUpdating || isSavingTransaction;

  useEffect(() => {
    if (transactionId && state.transactionId !== transactionId) {
      dispatch({
        type: 'UPDATE_TRANSACTION_ID',
        payload: transactionId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const { transaction } = useTransactionDetails({
    transactionId: state.transactionId,
    activeCompanySlug,
  });
  const validationErrors = validateTransactionDetailsState({
    state,
    transactionTotalAmountInCents: transaction?.amountWithDirection ?? 0,
  });

  const isChildTransaction = !!transaction?.parentTransaction;
  const isParentTransaction =
    !!transaction?.childTransactions && transaction.childTransactions.length > 0;

  const onSave = async () => {
    dispatch({
      type: 'SUBMIT',
    });

    if (validationErrors.isValid && transaction) {
      const transactionMetadata = selectTransactionDetailsTransactionMetadata(state);
      if (isChildTransaction) {
        updateChildTransaction({
          variables: {
            companySlug: activeCompanySlug,
            childTransactionId: state.transactionId,
            transactionMetadata,
          },
          onError: () => {
            setErrorMsg("Something didn't go right. Please try again.");
          },
          onCompleted: () => {
            dispatch({
              type: 'SAVE_ALL_CHANGES',
            });
            onUpdateTransaction?.();
          },
        });
      } else {
        const isCreditTransaction = isCredit({
          transactionSpendCategory: transaction.spendCategory,
          transactionZenaType: transaction.zenaType,
        });

        const childTransactions =
          state.splits.length > 0 || isParentTransaction
            ? state.splits.map((split) => {
                const amountCents =
                  state.splitMethod === 'AMOUNT'
                    ? split.amount
                    : Math.floor((split.amount / 100) * transaction.amountWithDirection);

                return {
                  id: split.transactionId,
                  amountCents: isCreditTransaction ? -1 * amountCents : amountCents,
                  transactionMetadata: selectTransactionDetailsTransactionMetadata({ ...split }),
                };
              })
            : undefined;

        if (childTransactions && childTransactions?.length > 0 && state.splitMethod === 'PERCENT') {
          const remainder = childTransactions.reduce((remaining, childTransaction) => {
            remaining = remaining - Math.abs(childTransaction.amountCents);
            return remaining;
          }, Math.abs(transaction.amountWithDirection));

          if (remainder) {
            childTransactions[0].amountCents = childTransactions[0].amountCents + remainder;
          }
        }

        const session = await fetchAuthSession();
        const identityId = session.identityId;

        updateTransactions({
          variables: {
            companySlug: activeCompanySlug,
            transactionId: state.transactionId,
            transactionMetadata: {
              ...transactionMetadata,
              receiptImageFileKey: transactionMetadata.receiptImageUrl
                ? `private/${identityId}/${transactionMetadata.receiptImageUrl}`
                : undefined,
            },
            childTransactions,
          },
          onError: (error) => {
            setErrorMsg("Something didn't go right. Please try again.");
          },
          onCompleted: () => {
            dispatch({
              type: 'SAVE_ALL_CHANGES',
            });
            dispatch({
              type: 'CLEAR_TEMP_RECEIPT_URL',
            });
            onUpdateTransaction?.();
          },
        });
      }
    }
  };

  return (
    <Drawer
      isVisible={isVisible}
      className="flex flex-col px-4 md:h-[calc(100%-76px)] md:mt-[76px] relative"
      onClose={onClose}
    >
      <TransactionDetails onClose={onClose} className="overflow-y-auto pt-20 pb-32" {...props} />
      <motion.div
        className="absolute bottom-0 left-0 w-full py-5 px-6 border-t border-primary-400 bg-white grid gap-2"
        style={{
          boxShadow: '0px 4px 22px 0px #00000026',
        }}
        layout
      >
        <div className="flex justify-between items-center">
          <Button variant="ghost" onClick={onClose} className="px-0 text-sm">
            Cancel
          </Button>
          <RoundButton
            type="submit"
            variant="primary"
            onClick={onSave}
            disabled={!state.hasChangesToSave}
            isLoading={isLoading}
          >
            Save
          </RoundButton>
        </div>
        {!validationErrors.isValid && state.showErrors && (
          <div className="flex justify-end text-sm font-medium text-danger">
            {state.showErrors && !validationErrors.isValid && !!validationErrors.formSubmission
              ? validationErrors.formSubmission
              : 'Please fix the errors above before continuing'}
          </div>
        )}
      </motion.div>
    </Drawer>
  );
};

export const ConfirmCloseTransactionDetailsDrawer = ({
  onCancel,
  onConfirmDangerously,
}: {
  onCancel: () => void;
  onConfirmDangerously: () => void;
}) => {
  const { dispatch } = useTransactionDetailsContext();

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-black bg-opacity-25 z-50">
      <div className="bg-white py-4 px-5 rounded-lg grid gap-4 max-w-[80%] shadow-2xl">
        <div className="">Are you sure you want to lose your existing changes?</div>
        <div className="flex justify-between items-center">
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              dispatch({
                type: 'CLEAR_TRANSACTION_DETAILS_STATE',
              });
              onConfirmDangerously();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

import Discount from 'components/atoms/Discount';
import danielHouseLogo from '../../../images/danielHouse.webp';

type MerchantDiscount = {
  __typename?: 'MerchantDiscount';
  id: string;
  discountCode: string;
  customerId?: string | null;
  merchantName: string;
};

type DiscountProps = {
  merchantDiscounts: MerchantDiscount[];
};

export const Discounts = ({ merchantDiscounts }: DiscountProps) => {
  return (
    <div className="grid grid-cols-1 w-full max-w-[1500px] gap-11 mt-9">
      <div className="flex flex-col lg:col-span-2 pt-6">
        <div className="text-neutral-800 text-[26px] font-medium leading-[29.90px] max-w-[60%]">
          Receive exclusive discounts from design-minded <br />
          businesses that you won't find anywhere else.
        </div>
      </div>
      <div className="flex flex-col lg:col-span-3 gap-[30px]">
        {merchantDiscounts.map((merchantDiscount) => {
          const merchantName = merchantDiscount.merchantName
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          const discountCode = merchantDiscount.discountCode.toUpperCase();
          const discountHref = 'https://danielhouse.club/zena';

          return (
            <Discount
              discountTitle={merchantName}
              discountAbout={
                <div className="mt-9 xl:mt-9 mt-0">
                  <div className="mb-2">
                    <span className="font-medium">
                      Get an average 30% trade discount + Free Shipping at over 200+ premium
                      vendors.
                    </span>{' '}
                    at over 75+ premium vendors.
                  </div>
                  <div>
                    Use your code at{' '}
                    <a href={discountHref} target="_blank" rel="noreferrer" className="text-black">
                      danielhouse.club/zena
                    </a>
                  </div>
                </div>
              }
              discountCopy={
                <span className="text-marble">
                  Daniel House Club simplifies sourcing and logistics for interior designers.{' '}
                  <a href={discountHref} target="_blank" rel="noreferrer" className="text-black">
                    Learn more
                  </a>
                </span>
              }
              discountHref={discountHref}
              discountCode={discountCode}
              iconSrc={danielHouseLogo}
              iconAlt={merchantName}
            />
          );
        })}
      </div>
    </div>
  );
};

import { ZenaSuggestedAssignment } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { useState } from 'react';
import { Maybe } from 'utils/types';
import { motion } from 'framer-motion';
import { InputDropdown } from 'components/atoms/InputDropdown';
import { cn } from 'utils';
import { ChevronLeft } from 'lucide-react';

export const FilterBySystemSuggestionsDropdown = ({
  systemSuggestion,
  onChange,
  className,
}: {
  systemSuggestion: Maybe<ZenaSuggestedAssignment>;
  onChange: (value: Maybe<ZenaSuggestedAssignment>) => void;
  className?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputList = [
    {
      id: ZenaSuggestedAssignment.project,
      value: 'Project related',
    },
    {
      id: ZenaSuggestedAssignment.business,
      value: 'Business related',
    },
    {
      id: ZenaSuggestedAssignment.unrelated,
      value: 'Not applicable',
    },
  ];

  return (
    <div className={cn('relative w-40', className)}>
      <RoundButton variant="tertiary" className="gap-1" onClick={() => setIsOpen(true)}>
        <div className="text-black text-nowrap">
          {systemSuggestion
            ? inputList.find((item) => item.id === systemSuggestion)?.value
            : 'Suggestions'}
        </div>
        <motion.div
          animate={{
            rotate: isOpen ? 90 : -90,
          }}
        >
          <span className="fill-black">
            <ChevronLeft />
          </span>
        </motion.div>
      </RoundButton>

      {isOpen && (
        <InputDropdown
          list={inputList}
          onClose={() => setIsOpen(false)}
          onSelectItem={(itemId) => {
            if (!itemId) {
              onChange(undefined);
            }
            onChange(itemId as ZenaSuggestedAssignment);
          }}
          className="w-full"
        />
      )}
    </div>
  );
};

import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { PageTitle } from 'components/atoms/PageTitle';
import useCustomAuth from 'hooks/useCustomAuth';
import { Ref, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { GoogleSignInButton } from '../../components/widgets/GoogleSignInButton';
import { Alert } from 'components/atoms/Alert';
import { AuthFormStates, AuthReturnProps } from 'types';

type SignInFormProps = {
  description: string;
  changeFormState: (formState: AuthFormStates) => void;
  handleSetUsername: (username: string) => void;
  prefillEmail?: string;
  username?: string;
  enterKeyButtonRef?: Ref<HTMLButtonElement>;
};

type SignInReturn = {
  errorMessage: string;
  mustConfirmSignUp: boolean;
};

export const signInAndGetResults = async (
  signIn: (email: string, password: string, clientMetadata?: any) => Promise<AuthReturnProps>,
  email: string,
  password: string,
  sub?: string
): Promise<SignInReturn> => {
  const signInResults = {
    errorMessage: '',
    mustConfirmSignUp: false,
  };

  try {
    const response = await signIn(email, password, { sub });

    if (response.success) {
      signInResults.errorMessage = '';
    } else {
      let errorMessageSet = false;
      if (response.errorCode === 'UserNotConfirmedException') {
        signInResults.mustConfirmSignUp = true;
      } else if (response.errorCode === 'InvalidParameterException') {
        if (!password) {
          signInResults.errorMessage = 'Please enter a valid password';
          errorMessageSet = true;
        }
      } else if (response.errorCode === 'UserNotFoundException') {
        signInResults.errorMessage =
          'Email and password combination does not match a valid user. Please try again.';
        errorMessageSet = true;
      } else if (response.errorMessage === 'Username cannot be empty') {
        signInResults.errorMessage = 'Please enter a valid email';
        errorMessageSet = true;
      }

      if (!errorMessageSet) {
        signInResults.errorMessage =
          response.errorMessage || 'Error during sign in, please contact support';
      }
    }
  } catch (error) {
    signInResults.errorMessage = 'Unknown error, please contact support';
  }

  return signInResults;
};

const SignInForm = (props: SignInFormProps) => {
  const {
    description,
    changeFormState,
    handleSetUsername,
    prefillEmail,
    username,
    enterKeyButtonRef,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit } = useForm();
  const { signIn } = useCustomAuth();

  const [searchParams] = useSearchParams();

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const email = !!prefillEmail ? prefillEmail : data.email;
    handleSetUsername(email);

    const signInResults = await signInAndGetResults(
      signIn,
      email,
      data.password,
      searchParams.get('sub') ?? undefined
    );
    setErrorMessage(signInResults.errorMessage);
    if (signInResults.mustConfirmSignUp) {
      changeFormState('confirmSignUp');
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageTitle text="Log in" hidden />
      <h1 className="text-center text-3xl">Welcome back!</h1>

      <p className="mt-4">{description}</p>
      {errorMessage && (
        <div className="mt-4">
          <Alert variation="error">{errorMessage}</Alert>
        </div>
      )}
      <div className="mt-8 flex flex-col">
        <GoogleSignInButton text="Log in with Google" />
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-marble"></div>
          <span className="flex-shrink mx-4 text-marble">Or, log in with your email</span>
          <div className="flex-grow border-t border-marble"></div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextInput
            className={`mb-2 ${!!prefillEmail && 'text-feather'}`}
            label="Email"
            placeholder="Email"
            useFormRegister={register('email')}
            defaultValue={prefillEmail ? prefillEmail : username}
            disabled={!!prefillEmail}
            onBlur={(e) => {
              handleSetUsername(e.target.value);
            }}
          />
          <TextInput
            className="mb-2"
            label="Password"
            useFormRegister={register('password')}
            placeholder="Password"
            type="password"
          />
        </div>
        <div className="flex justify-end">
          <button
            className="underline text-asphalt"
            onClick={(e) => {
              e.preventDefault();
              changeFormState('forgotPassword');
            }}
          >
            Forgot password?
          </button>
        </div>
        <div className="flex items-center mt-8">
          <div className="w-full flex justify-between">
            <Link to="/signup">
              <UnderlineButton text="Create account" className="py-3" />
            </Link>
            <RoundButton
              className="py-3 px-8"
              text="Log In"
              variant="primary"
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              ref={enterKeyButtonRef}
              type="submit"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default SignInForm;

import { ReactNode, useEffect } from 'react';
import { cn } from 'utils';
import { RoundButton } from '../Buttons';
import { CircleCheck, Info } from 'lucide-react';

export type AlertVariation = 'info' | 'success' | 'error' | 'warning';

export const Alert = ({
  variation,
  onDismiss,
  children,
  className,
  title,
  description,
  actionButtonText = 'Close',
  timeoutSeconds = 10,
}: {
  variation: AlertVariation;
  onDismiss?: () => void;
  children?: ReactNode;
  className?: string;
  title?: string;
  description?: string;
  actionButtonText?: string;
  timeoutSeconds?: number;
}) => {
  const isDismissable = !!onDismiss;

  useEffect(() => {
    if (isDismissable) {
      const timeout = setTimeout(() => {
        onDismiss?.();
      }, timeoutSeconds * 1000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <AlertContainer variation={variation} className={className}>
      <AlertContentContainer>
        {variation === 'info' && <CircleCheck className="fill-accent" />}
        {variation === 'success' && <CircleCheck className="fill-success" />}
        {variation === 'error' && <Info className="fill-danger" />}
        {variation === 'warning' && <Info className="fill-warning" />}

        {children ? children : null}

        {!children && title && (
          <AlertTextContainer>
            <AlertTitle>{title}</AlertTitle>
            {description && <AlertDescription>{description}</AlertDescription>}
          </AlertTextContainer>
        )}

        {isDismissable && (
          <AlertActionContainer>
            <RoundButton variant="tertiary" onClick={onDismiss}>
              {actionButtonText}
            </RoundButton>
          </AlertActionContainer>
        )}
      </AlertContentContainer>
    </AlertContainer>
  );
};

export const AlertContainer = ({
  variation,
  children,
  className,
}: {
  variation: AlertVariation;
  children: ReactNode;
  className?: string;
}) => {
  const getAlertContainerColorProperties = (): string => {
    switch (variation) {
      case 'success':
        return 'bg-success-600 border-success';
      case 'error':
        return 'bg-danger-600 border-danger';
      case 'warning':
        return 'bg-warning-600 border-warning';
      case 'info':
      default:
        return 'bg-accent-300 border-accent';
    }
  };

  return (
    <div
      className={cn(
        'w-full px-4 py-4 rounded-lg border-l-4 border-solid shadow-normal',
        getAlertContainerColorProperties(),
        className
      )}
    >
      {children}
    </div>
  );
};

export const AlertContentContainer = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-cols-[20px_1fr] md:grid-cols-[20px_1fr_auto] gap-4 items-center">
    {children}
  </div>
);

export const AlertActionContainer = ({ children }: { children: ReactNode }) => (
  <div className="self-center ml-[36px] md:ml-0 col-span-2 md:col-span-1">{children}</div>
);

export const AlertTextContainer = ({ children }: { children: ReactNode }) => (
  <div className="grid gap-1">{children}</div>
);

export const AlertTitle = ({ children }: { children: ReactNode }) => (
  <div className="text-black text-base font-medium">{children}</div>
);

export const AlertDescription = ({ children }: { children: ReactNode }) => (
  <div className="text-primary-700 text-sm font-normal">{children}</div>
);

import { gql } from '__generated__/gql';

export const QUERY_GET_CUSTOMER_TEAM_MEMBERS = gql(`
    query GetCustomerTeamMembersForSettingsLayout($customerId:String!){
      getCustomerTeamMembers(customerId:$customerId) {
        userId
        fullName {
          first
          last
        }
        email
        signedProfilePhotoUrl
        role
      }
    }
`);

import { formatCentsToDollarsString } from 'utils';
import { ImageCard } from 'components/widgets/cards/ImageCard';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { StatusIndicator } from 'components/atoms/StatusIndicator';
import { ProjectStatus } from '__generated__/graphql';
import { CreditCard } from 'lucide-react';

interface ProjectCardProps {
  projectName: string;
  budget: number | null | undefined;
  remainingBudget?: number;
  amountSpent?: number;
  onClick?: () => void;
  status: keyof typeof ProjectStatus;
}

export const ProjectCard = ({
  onClick,
  projectName,
  budget,
  amountSpent,
  status,
  remainingBudget,
}: ProjectCardProps) => {
  let progressBarWidth = budget ? (amountSpent ?? 0) / budget : 1;
  if (progressBarWidth > 1) {
    progressBarWidth = 1;
  }
  const progressBarPercentage = progressBarWidth * 100;

  return (
    <ImageCard onClick={onClick}>
      <div className="absolute top-3 left-3">
        <StatusIndicator status={status} />
      </div>
      <div className="p-4 flex flex-col justify-between">
        <div className="grid gap-2 grid-cols-6">
          <div className="col-span-5">
            <CardTitle>{projectName}</CardTitle>
            {!!budget && (
              <div className="flex gap-1">
                <div className="font-medium">{formatCentsToDollarsString(budget)}</div>
                <div>Client budget</div>
              </div>
            )}
          </div>
          {/* TODO: if no card, hide the below*/}
          <div className="ml-auto">
            <CreditCard />
          </div>
        </div>

        <div className="mt-6">
          <div className="bg-secondary-900 w-full rounded-full">
            <div
              className="h-2 bg-black rounded-full"
              style={{
                width: `${progressBarPercentage}%`,
              }}
            ></div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="flex gap-1">
              <div className="text-sm">{formatCentsToDollarsString(amountSpent ?? 0, true)}</div>
              <div className="text-sm">Budget used</div>
            </div>
            <div className="flex gap-1">
              {typeof budget === 'number' ? (
                <>
                  <div className="text-sm">
                    {formatCentsToDollarsString(remainingBudget ?? 0, true)}
                  </div>
                  <div className="text-sm">Budget remaining</div>
                </>
              ) : (
                <>
                  <div className="text-sm">Budget not set</div>
                  <div className="text-sm" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ImageCard>
  );
};

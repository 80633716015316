import businessAssociatesInMeeting from 'images/businessAssociatesInMeeting.webp';
import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from '../OnboardingLayout';

export const LoginLayout = ({
  children,
  className = '',
  hideQuotes = false,
}: {
  children: ReactNode;
  className?: string;
  hideQuotes?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <OnboardingLayout
      image={businessAssociatesInMeeting}
      imageAlt="Two business associates in a meeting"
      onClickGoBack={
        location.pathname !== '/login'
          ? () => {
              navigate(-1);
            }
          : undefined
      }
      className={className}
      hideQuotes={hideQuotes}
    >
      {children}
    </OnboardingLayout>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { formatGraphqlDate } from 'utils/date';

const INCOME_VS_SPEND_WIDGET_QUERY = gql(`
  query GetIncomeAndSpendTransactionSummariesByMonth($companySlug: String!, $fromDate: String!) {
    getIncomeAndSpendTransactionSummariesByMonth(companySlug: $companySlug, fromDate: $fromDate) {
      months {
        totalIncomeAmountCents
        totalSpendAmountCents
        id
        monthIndex
        totalIncomeTransactionsCount
        totalSpendTransactionsCount
        month
      }
      totalIncomeAmountCents
      totalSpendAmountCents
      totalIncomeTransactionsCount
      totalSpendTransactionsCount
    }
  }
`);

export const useIncomeVsSpendData = ({
  companySlug,
  fromDate,
}: {
  companySlug: string;
  fromDate: Date;
}) => {
  const { data, loading, error } = useQuery(INCOME_VS_SPEND_WIDGET_QUERY, {
    variables: {
      companySlug,
      fromDate: formatGraphqlDate(fromDate),
    },
    skip: !fromDate || !companySlug,
  });

  return { data: data?.getIncomeAndSpendTransactionSummariesByMonth, loading, error };
};

import { useGetApplicationForm } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useActiveCompany } from 'providers/ActiveCompany';

export const UnitCompanyName = ({ editing }: { editing: boolean }) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { unitApplication, loading } = useGetApplicationForm({ companySlug: activeCompanySlug });

  return (
    <div className="flex">
      <div>
        <div className="font-semibold">Official company name</div>
        <div className="text-gray-500">This is the name used on your Zena card application</div>
      </div>
      <div className="ml-auto mr-24">
        {loading ? (
          <div className="w-64">
            <RectangleSkeleton />
          </div>
        ) : (
          unitApplication.companyName ?? `${unitApplication.firstName} ${unitApplication.lastName}`
        )}
        {editing && (
          <div className="text-gray-500 italic text-sm">Official name cannot be changed</div>
        )}
      </div>
    </div>
  );
};

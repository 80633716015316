import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY = gql(`
query GetCsvExports($companySlug: String!) {
  getCsvExports(companySlug: $companySlug) {
    id
    url
    includeChildren
    includeImportedTransactions
    dateTime
    status
    type
    projectName
  }
}
`);

const GET_CSV_EXPORT_URL_QUERY = gql(`
  query GetCsvExportUrlQuery($companySlug: String!, $csvExportId: String!)  {
    getCsvExportUrl(companySlug: $companySlug, csvExportId: $csvExportId) {
      url
    }
  }
`);

export const useCsvExports = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { companySlug },
    fetchPolicy: 'network-only',
  });

  return {
    csvExports: data?.getCsvExports ?? [],
    loading,
    error,
  };
};

export const useCsvExportUrl = ({
  companySlug,
  csvExportId,
}: {
  companySlug: string;
  csvExportId?: string;
}) => {
  const { data, loading, error } = useQuery(GET_CSV_EXPORT_URL_QUERY, {
    variables: { companySlug, csvExportId: csvExportId ?? '' },
    skip: !csvExportId,
  });

  return {
    csvExportUrl: data?.getCsvExportUrl?.url ?? null,
    loading,
    error,
  };
};

import { UnderlineButton } from 'components/atoms/Buttons';
import useCustomAuth from 'hooks/useCustomAuth';
import zenaLogo from 'images/zenaLogo.webp';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const HeroSplitWithImage = ({
  children,
  image,
  imageAlt,
  allowSignout,
  goBackTo,
  onClickGoBack,
}: {
  children: React.ReactNode;
  image: string;
  imageAlt: string;
  allowSignout?: boolean;
  goBackTo?: string;
  onClickGoBack?: () => void;
}) => {
  const { signOut } = useCustomAuth();

  return (
    <div className="relative bg-ivory">
      <div className="mx-auto lg:grid lg:grid-cols-12 lg:pl-[5.37rem]">
        <div className="sm:px-6 lg:col-span-7 lg:px-0 xl:col-span-6">
          <div className="flex flex-col">
            <div className="h-[5.69rem] px-6 sm:px-0 flex items-center">
              <div className="flex flex-col items-start sm:flex-row sm:items-end">
                <img className="h-6 pr-2" src={zenaLogo} alt="Zena Logo" />
                <div className="text-sm relative bottom-[-0.25rem] pr-20">
                  Built for Interior Designers —{' '}
                  <span className="inline-block">and only Interior Designers</span>
                </div>
              </div>
            </div>
            <div
              className={`relative bg-white pb-16 lg:min-h-[calc(100vh-5.69rem)] flex flex-row justify-center px-6 ${
                goBackTo || onClickGoBack ? 'pt-10' : 'pt-4'
              } sm:pt-10 sm:rounded-t-4xl lg:col-span-7 lg:px-0 xl:col-span-6 lg:rounded-tr-none`}
            >
              {goBackTo && (
                <Link className="absolute left-6 top-6 flex" to={goBackTo}>
                  <span className="h-6 pr-1">
                    <ChevronLeft />
                  </span>
                  Back
                </Link>
              )}

              {onClickGoBack && (
                <button className="absolute left-6 top-6 flex" onClick={onClickGoBack}>
                  <span className="h-6 pr-1">
                    <ChevronLeft />
                  </span>
                  Back
                </button>
              )}
              <div className="mx-auto w-full max-w-2xl lg:mx-0 lg:mr-6 lg:px-20">{children}</div>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src={image}
            alt={imageAlt}
          />
        </div>
        {allowSignout && (
          <UnderlineButton
            className="absolute top-4 right-6 text-lg sm:right-16 lg:text-white"
            onClick={signOut}
            text="Logout"
          />
        )}
      </div>
    </div>
  );
};

export default HeroSplitWithImage;

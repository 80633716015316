import {
  AlertActionContainer,
  AlertContainer,
  AlertContentContainer,
  AlertDescription,
  AlertTextContainer,
  AlertTitle,
} from 'components/atoms/Alert';
import { RoundButton } from 'components/atoms/Buttons';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Link } from 'react-router-dom';
import { useGetCompanyForNotifications, useHasPlaidConnection } from './data';
import { cn, formatCentsToAbsDollarsString } from 'utils';
import { PlaidLinkedAccountStatus } from '__generated__/graphql';
import { Info } from 'lucide-react';

export const Notifications = ({ showActionButtons = true }: { showActionButtons?: boolean }) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug;

  const { balance, isFrozen, loading } = useGetCompanyForNotifications({
    companySlug: activeCompanySlug,
  });

  const { plaidConnectionStatus, loading: plaidConnectionLoading } = useHasPlaidConnection({
    activeCompanySlug,
  });

  if (loading || plaidConnectionLoading) {
    return null;
  }

  return (
    <div
      className={cn(
        'px-12 pt-8 pb-4 flex-col gap-4 hidden',
        isFrozen && (balance ?? 0) > 0 && 'flex',
        isFrozen && plaidConnectionStatus === PlaidLinkedAccountStatus.disconnected && 'flex'
      )}
    >
      {isFrozen && (balance ?? 0) > 0 && (
        <AlertContainer variation="error">
          <AlertContentContainer>
            <Info className="fill-danger" />

            <AlertTextContainer>
              <AlertTitle>
                Account past due. Your account will remain frozen until the overdue balance has been
                paid in full.
              </AlertTitle>
              <AlertDescription>
                Your account is past due by {formatCentsToAbsDollarsString(balance ?? 0)}. You can
                review your <Link to="/statements">statements here</Link>. Please either transfer
                sufficient funds into your linked bank account or link a new bank account. If you
                link a new account, a payment will be initiated immediately.
              </AlertDescription>
            </AlertTextContainer>

            {showActionButtons && (
              <AlertActionContainer>
                <Link to="/settings/bank-accounts">
                  <RoundButton variant="tertiary">Link new bank account</RoundButton>
                </Link>
              </AlertActionContainer>
            )}
          </AlertContentContainer>
        </AlertContainer>
      )}
      {isFrozen && plaidConnectionStatus === PlaidLinkedAccountStatus.disconnected && (
        <AlertContainer variation="error">
          <AlertContentContainer>
            <Info className="fill-danger stroke-danger-600" />

            <AlertTextContainer>
              <AlertTitle>Account Frozen. Please link a bank account to keep spending.</AlertTitle>
              <AlertDescription>
                The bank account you use to pay off your Zena card balance has been disconnected.
                Please relink this account, or link a new bank account. Your account will remain
                frozen until your account is relinked
              </AlertDescription>
            </AlertTextContainer>

            {showActionButtons && (
              <AlertActionContainer>
                <Link to="/settings/bank-accounts">
                  <RoundButton variant="tertiary">Relink bank account</RoundButton>
                </Link>
              </AlertActionContainer>
            )}
          </AlertContentContainer>
        </AlertContainer>
      )}
    </div>
  );
};

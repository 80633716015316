import { ReactNode } from 'react';
import { motion } from 'framer-motion';

import useCustomAuth from 'hooks/useCustomAuth';
import zenaLogo from 'images/zenaLogo.webp';
import { cn } from 'utils';
import { DesignerQuotes } from './DesignerQuotes';
import { UnderlineButton } from 'components/atoms/Buttons';
import { ChevronLeft } from 'lucide-react';

export const OnboardingLayout = ({
  children,
  image,
  imageAlt,
  allowSignout,
  onClickGoBack,
  className = '',
  hideQuotes = false,
}: {
  children: ReactNode;
  image: string;
  imageAlt: string;
  allowSignout?: boolean;
  onClickGoBack?: () => void;
  className?: string;
  hideQuotes?: boolean;
}) => {
  const { signOut, user } = useCustomAuth();

  return (
    <>
      <div className="flex flex-col min-h-screen gap-y-8 relative py-4 px-4 w-full lg:px-0 lg:py-0 lg:gap-y-0 lg:flex lg:flex-col lg:bg-ivory lg:pl-[5.37rem] lg:h-screen lg:w-screen lg:overflow-hidden">
        {onClickGoBack && (
          <button
            className="absolute left-5 top-5 flex items-center text-sm lg:hidden"
            onClick={onClickGoBack}
          >
            <span className="h-4">
              <ChevronLeft />
            </span>
            Back
          </button>
        )}

        <header className="flex flex-row justify-center items-center lg:hidden">
          <img className="h-6 pr-2" src={zenaLogo} alt="Zena Logo" />
        </header>

        <header className="hidden lg:flex h-[5.69rem] px-6 sm:px-0 items-center lg:justify-between lg:pr-8">
          <div className="flex flex-col items-start sm:flex-row sm:items-end">
            <img className="pr-2 h-6" src={zenaLogo} alt="Zena Logo" />
            <div className="text-sm relative bottom-[-0.25rem] pr-20">
              Built for Interior Designers —{' '}
              <span className="inline-block">and only Interior Designers</span>
            </div>
          </div>

          {allowSignout && !!user && (
            <UnderlineButton className="hidden lg:block" onClick={signOut} text="Logout" />
          )}
        </header>

        <div className="w-full lg:bg-white lg:flex-grow lg:min-h-0 lg:rounded-tl-4xl lg:grid lg:grid-cols-2 lg:relative">
          <section className="w-full lg:w-auto lg:flex lg:flex-row lg:justify-center lg:items-start lg:relative lg:overflow-scroll lg:pb-6">
            <>
              {onClickGoBack && (
                <button
                  className="hidden lg:absolute lg:left-6 lg:top-6 lg:flex lg:items-center"
                  onClick={onClickGoBack}
                >
                  <span className="h-4">
                    <ChevronLeft />
                  </span>
                  Back
                </button>
              )}
              <motion.div
                layout
                className={cn(className, 'lg:mt-[15%] lg:w-full lg:max-w-md lg:px-4')}
              >
                {children}
              </motion.div>
            </>
          </section>
          <div className="hidden lg:block h-full relative min-h-0">
            <img className="object-cover h-full w-full" src={image} alt={imageAlt} />
            {!hideQuotes && (
              <div className="absolute bottom-6 w-full flex flex-row justify-center px-6">
                <DesignerQuotes className="w-full max-w-[640px] shadow-lg" />
              </div>
            )}
          </div>
        </div>

        {!hideQuotes && <DesignerQuotes className="lg:hidden" />}
      </div>
    </>
  );
};

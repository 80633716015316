import { TransactionReceipt } from '__generated__/graphql';

interface TransactionReceiptImageProps {
  transactionReceipt: TransactionReceipt;
}
const TransactionReceiptImage = ({ transactionReceipt }: TransactionReceiptImageProps) => {
  const url = new URL(transactionReceipt.imageUrl);
  const isPdf = url.pathname.endsWith('.pdf');

  if (isPdf) {
    return (
      <div>
        <iframe src={transactionReceipt.imageUrl} title="Transaction Receipt" />
      </div>
    );
  }

  return (
    <div>
      <img src={transactionReceipt.imageUrl} alt="Transaction Receipt" />
    </div>
  );
};

export default TransactionReceiptImage;

import { ReactNode } from 'react';

export const EmptyWidgetCard = ({
  children,
  ...props
}: { children?: ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className="bg-white border-primary-400 border rounded-3xl min-h-80 cursor-pointer hover:shadow-md"
      {...props}
    >
      {children}
    </div>
  );
};

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { cn } from 'utils';

export interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  inputState?: 'error' | 'normal' | 'success';
  className?: string;
  useFormRegister?: UseFormRegisterReturn;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { inputState = 'normal', className = '', type = 'text', useFormRegister, ...inputProps },
    ref
  ) => {
    return (
      <textarea
        {...inputProps}
        {...useFormRegister}
        ref={ref}
        className={cn(
          'w-full bg-transparent outline-accent-600 px-5 py-4 border rounded-lg border-solid text-black text-base placeholder:text-muted',
          'disabled:cursor-not-allowed disabled:bg-primary-200',
          inputState === 'normal'
            ? 'border-primary-400'
            : inputState === 'success'
              ? 'border-success'
              : 'border-danger',
          className
        )}
      />
    );
  }
);

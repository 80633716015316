import { Tooltip } from 'react-tooltip';
import { useCopyContents } from 'utils';
import { Button } from './Buttons';
import { TextInput } from './Inputs/TextInput';
import { Copy } from 'lucide-react';

const CopyControl = ({
  fullLengthLabel,
  className,
  label,
  id,
}: {
  fullLengthLabel?: string;
  className?: string;
  label: string;
  id: string;
}) => {
  const { copyContents, contentsRef } = useCopyContents();

  const tooltipId = `copy-text-tooltip-${id}`;
  const textToCopy = fullLengthLabel || label;

  return (
    <div className={className}>
      <div className="m-auto w-fit flex border-[1px] border-marble rounded-lg p-3">
        <span
          ref={contentsRef}
          className="absolute top-[-5000px] left-[-5000px] full-copy-string-text"
          id={`id-text-${label}`}
        >
          {textToCopy}
        </span>
        <div className="inline-block align-top">
          <label htmlFor={`copy-label-${id}`} className="">
            Copy code:
          </label>
          <TextInput
            id={`copy-label-${id}`}
            value={label}
            label=""
            className="rounded-none border-none h-[29px] !pt-0 w-36 !px-0 weight"
            inputClassName="text-black font-normal"
          />
        </div>
        <Button
          onClick={() => {
            copyContents();
          }}
          toolTipId={tooltipId}
          className="bg-secondary-400 rounded-full w-10 h-10 flex justify-center items-center ml-2 mt-1 !p-2"
        >
          <Copy />
        </Button>
        <Tooltip id={tooltipId} openOnClick>
          Copied
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyControl;

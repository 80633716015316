import { gql } from '__generated__/gql';

export const MUTATION_UPDATE_PROJECT = gql(`
  mutation UpdateProjectDetails($customerId: String!, $projectId: String!, $attributes: ProjectInput!) {
    updateProject(projectId: $projectId, customerId: $customerId, attributes: $attributes) {
      id
      name
      description
      budget
      approved
      approvedAt
      rejectedAt
      users
      cardId
      amountSpent
      status
      address {
        street
        street2
        city
        state
        postalCode
      }
      clientInfo {
        firstName
        lastName
        email
        phone
      }
      tags {
        name
        type
      }
    },
  }
`);

import React from 'react';

export const TableRow = ({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <tr
      className={`cursor-pointer hover:border-b-2 border-b border-feather ${className}`}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

import AddTeamMembersForm from './AddTeamMembersForm';
import TeamMembersCollection from './TeamMembersCollection';
import { ProjectTeamMembersProvider } from './TeamMembersProvider';

export const TeamMembers = ({
  customerId,
  projectId,
}: {
  customerId: string;
  projectId: string;
}) => {
  return (
    <ProjectTeamMembersProvider customerId={customerId} projectId={projectId}>
      <AddTeamMembersForm customerId={customerId} />
      <TeamMembersCollection />
    </ProjectTeamMembersProvider>
  );
};

import { ReactNode } from 'react';
import { cn } from 'utils';

export const DashboardContainer = ({
  children,
  gridColumnsWidth = 1,
  background = 'bg-white',
  className,
}: {
  children: ReactNode;
  gridColumnsWidth?: 1 | 2 | 3;
  background?: 'bg-white' | 'bg-tertiary-800';
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'bg-white border-primary-400 border rounded-3xl p-8',
        gridColumnsWidth === 2 && 'col-span-1 xl:col-span-2',
        gridColumnsWidth === 3 && 'col-span-1 xl:col-span-3',
        background,
        className
      )}
    >
      <div className="w-full h-full">{children}</div>
    </div>
  );
};

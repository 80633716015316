import { RoundButton } from 'components/atoms/Buttons';
import { ZenaCardSilouette } from 'components/atoms/ZenaCard/ZenaCardSilhouette';
import { isAdmin } from 'hooks/access';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Link } from 'react-router-dom';

export const FinishApplyingForZenaCard = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const admin = isAdmin(activeCompanySlug);
  return (
    <div className="flex flex-col lg:flex-row gap-12 items-center justify-center">
      <ZenaCardSilouette
        cardId="finish card application"
        firstName="My"
        lastName="Name"
        last4Digits="0000"
        showDetails={false}
      />
      <div className="flex flex-col max-w-80 justify-center gap-6">
        <div className="text-center">
          You're almost done! You just need to set up your automatic payments.
        </div>
        <Link to="/connect-bank-account" className="flex justify-center">
          <RoundButton disabled={!admin}>Link payment account</RoundButton>
        </Link>
        {!admin && (
          <p className="text-sm text-gray-500 text-center">
            * Must be completed by a company admin
          </p>
        )}
      </div>
    </div>
  );
};

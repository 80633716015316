import { ProjectStatus } from '__generated__/graphql';
import { cn } from '../../../utils';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useState } from 'react';
import { Field as HeadlessField } from '@headlessui/react';
import { Label } from 'components/atoms/fieldset';
import { Listbox, ListboxLabel, ListboxOption } from 'components/atoms/listbox';

const StatusIndicator = ({ status }: { status: ProjectStatus }) => {
  const statusColor: Record<keyof typeof ProjectStatus, string> = {
    active: 'bg-green-500',
    // hold: 'bg-yellow-500',
    completed: 'bg-blue-500',
    // canceled: 'bg-red-500',
  };
  return <div className={cn('rounded-full size-2 bg-green-500', statusColor[status])} />;
};

export const MUTATION_UPDATE_PROJECT_STATUS = gql(`
    mutation UpdateProjectStatus($customerId: String!, $projectId: String!, $status: ProjectStatus!) {
        updateProjectStatus(customerId: $customerId, projectId: $projectId, status: $status) {
            id
            status
        }
    }
`);

export const UpdateProjectStatus = ({
  customerId,
  projectId,
  currentStatus,
}: {
  customerId: string;
  projectId: string;
  currentStatus: ProjectStatus;
}) => {
  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT_STATUS);
  let [status, setStatus] = useState(currentStatus);
  const onChange = (v: ProjectStatus) => {
    setStatus(v);
    updateProject({
      variables: {
        customerId,
        projectId,
        status: v,
      },
    });
  };
  return (
    <HeadlessField className="flex items-baseline gap-6">
      <Label className="font-bold">Project status</Label>
      <Listbox name="status" className="max-w-32 ml-auto" value={status} onChange={onChange}>
        <ListboxOption value={ProjectStatus.active}>
          <StatusIndicator status={ProjectStatus.active} />
          <ListboxLabel>Active</ListboxLabel>
        </ListboxOption>
        {/* TODO: To be added in at a later time */}
        {/*<ListboxOption value="hold">*/}
        {/*  <StatusIndicator status="Hold" />*/}
        {/*  <ListboxLabel>On Hold</ListboxLabel>*/}
        {/*</ListboxOption>*/}
        <ListboxOption value={ProjectStatus.completed}>
          <StatusIndicator status={ProjectStatus.completed} />
          <ListboxLabel>Completed</ListboxLabel>
        </ListboxOption>
        {/* TODO: To be added in at a later time */}
        {/*<ListboxOption value="canceled">*/}
        {/*  <StatusIndicator status="Canceled" />*/}
        {/*  <ListboxLabel>Canceled</ListboxLabel>*/}
        {/*</ListboxOption>*/}
      </Listbox>
    </HeadlessField>
  );
};

import { Pill } from 'components/atoms/Pill';
import { useState, useEffect, useRef, FocusEventHandler } from 'react';
import { cn } from 'utils';
import { Plus } from 'lucide-react';

interface MultiPillSelectProps {
  disabled?: boolean;
  className?: string;
  value?: string[];
  presets?: string[];
  pillClassName?: string;
  placeholder?: string;
  allowDuplicates?: boolean;
  errorMessage?: string;
  onChange?: (value: string[]) => void;
}

const MultiPillSelect = ({
  disabled,
  className,
  pillClassName,
  placeholder,
  value,
  presets = [],
  allowDuplicates = false,
  errorMessage,
  onChange,
}: MultiPillSelectProps) => {
  const [selectedPills, setSelectedPills] = useState<string[]>(value || []);
  const [pillWasClosed, setPillWasClosed] = useState<boolean>(false);
  const [availablePresets, setAvailablePresets] = useState<string[]>([]);
  const [showPresets, setShowPresets] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [internalError, setInternalError] = useState<string>('');
  const borderOutline = disabled ? 'border-feather' : 'border-marble';
  const inputRef = useRef<HTMLInputElement>(null);
  const pillsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedPills(value || []);
  }, [value]);

  useEffect(() => {
    if (value) {
      setAvailablePresets(presets.filter((text) => !value.includes(text)));
    } else {
      setAvailablePresets(presets);
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(selectedPills);
    }
    if (!pillWasClosed) {
      adjustScroll();
    }
    setPillWasClosed(false);
  }, [selectedPills]);

  const adjustScroll = () => {
    const container = pillsRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth - container.clientWidth;
    }
  };

  const handleAddItem = () => {
    if (inputValue) {
      const newValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
      if (!allowDuplicates && selectedPills.includes(newValue)) {
        setInternalError('Duplicate item');
        setShowPresets(false);
      } else {
        setSelectedPills([...selectedPills, newValue]);
        setInputValue('');
        inputRef.current?.focus();
        setInternalError('');
      }
    }
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleAddItem();
    }
    if (e.key === 'Backspace' && !inputValue) {
      setSelectedPills(selectedPills.slice(0, -1));
    }
  };

  const handlePresetClick = (presetText: string) => {
    setSelectedPills([...selectedPills, presetText]);
    setAvailablePresets(availablePresets.filter((text) => text !== presetText));
    inputRef.current?.focus();
  };

  const handleRemoveItem = (removedText: string) => {
    setSelectedPills(selectedPills.filter((text) => text !== removedText));
    if (presets.includes(removedText)) {
      setAvailablePresets([...availablePresets, removedText]);
    }
    setPillWasClosed(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.relatedTarget?.className.includes('multi-pill-preset')) {
      setShowPresets(false);
    }
  };

  return (
    <div>
      <div
        className={`flex flex-col px-4 pt-2 pb-1.5 rounded-lg border bg-white relative ${borderOutline} ${className}`}
      >
        <div className="flex gap-2">
          <div
            ref={pillsRef}
            className="flex gap-2 overflow-y-hidden overflow-x-auto max-w-[500px]"
          >
            {selectedPills.map((pillText) => (
              <Pill
                key={pillText}
                text={pillText}
                variant="cancelable"
                className={cn('h-7 leading-[normal]', pillClassName)}
                onClickCancel={handleRemoveItem}
              />
            ))}
          </div>
          <input
            className="flex-auto border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-lavender min-w-[25%]"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onFocus={() => {
              setShowPresets(true);
            }}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
          <button className="flex-initial" type="button" onClick={handleAddItem}>
            <Plus />
          </button>
        </div>
        <div
          className="absolute shadow-[0_0_4px_-2px] top-full bg-white z-40 w-full left-0 rounded max-h-select overflow-y-auto svelte-5uyqqj"
          onBlur={handleBlur}
        >
          <div className="flex flex-col w-full">
            {showPresets &&
              availablePresets.map((preset) => {
                return (
                  <button
                    type="button"
                    onClick={() => handlePresetClick(preset)}
                    className="multi-pill-preset w-full border-gray-100 rounded-t border-b hover:bg-pearl"
                    key={`preset-${preset}`}
                  >
                    <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative">
                      <div className="w-full items-center flex">
                        <div className="mx-2 leading-6">{preset}</div>
                      </div>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      </div>
      {(errorMessage || internalError) && (
        <div className="mt-2 text-rustic">{errorMessage || internalError}</div>
      )}
    </div>
  );
};

export default MultiPillSelect;

import { PageTitle } from 'components/atoms/PageTitle';
import { StatementsTable } from './StatementsTable';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Notifications } from 'providers/notifications';

const Statements = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  return (
    <div className="w-full">
      <PageTitle hidden text="Statements" />
      <Notifications />
      <StatementsTable customerId={activeCompanySlug} />
    </div>
  );
};

export default Statements;

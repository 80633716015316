import { Field } from 'components/atoms/Inputs/Field';
import { CurrencyInput } from 'components/atoms/Inputs/CurrencyInput';
import { useState } from 'react';

const ProjectBudgetForm = ({
  budget,
  setBudget,
}: {
  budget?: number | undefined;
  setBudget: (budget: number) => void;
}) => {
  const [error, setError] = useState<string | null>(null);
  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-2">
        <Field label="Budget" errorMessage={error ? error : undefined}>
          <CurrencyInput
            type="text"
            className="bg-white"
            placeholder="0.00"
            value={budget}
            onChange={(v) => {
              if (v === undefined) {
                setError('Budget is required');
                return;
              } else if (v < 0) {
                setError('Budget cannot be negative');
                return;
              }
              setError(null);
              setBudget(v);
            }}
            min={0}
            maxDecimalPlaces={0}
            inputState={error ? 'error' : 'normal'}
          />
        </Field>
      </div>
    </div>
  );
};

export default ProjectBudgetForm;

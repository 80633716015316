import { useQuery } from '@apollo/client';
import {
  hasPlaidConnectionCompleteFromLocalStorage,
  setHasPlaidConnectionCompleteFromLocalStorage,
} from 'hooks/plaid';
import { useLocation } from 'react-router-dom';
import { gql } from '__generated__/gql';
import { useCompany } from 'hooks/useCompany';
import { useApplication } from 'hooks/useApplication';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { getPath, routesEnabledForLite } from './marketing/utils';
import {
  ApplyForZenaCardMarketing,
  ConnectPaymentAccountMarketing,
  FinishApplingForZenaCardMarketing,
} from './marketing/components';
import { UpsellZena } from 'pages/(dashboard)/MarketingDashboard/UpsellZena';
import { useState } from 'react';
import { useActiveCompany } from './ActiveCompany';

const PLAID_CONNECTION_COMPLETE_QUERY = gql(`
  query PlaidConnectionCompleteQuery($companySlug: String!)  {
    getPlaidConnectionStatus(companySlug: $companySlug) {
      connected
    }
  }
`);

export function RequirePlaidConnection({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [hasAnyPlaidConnection, setHasAnyPlaidConnection] = useState(
    hasPlaidConnectionCompleteFromLocalStorage()
  );

  const { loading: loadingCompanyTier, company } = useCompany({
    companySlug: activeCompanySlug,
  });
  const { loading: loadingApplication, application } = useApplication();

  const { loading } = useQuery(PLAID_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || hasAnyPlaidConnection,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getPlaidConnectionStatus?.connected === true) {
        setHasPlaidConnectionCompleteFromLocalStorage(true);
        setHasAnyPlaidConnection(true);
      }
    },
  });

  if (routesEnabledForLite.includes(getPath(location.pathname))) {
    return null;
  }

  if (loading || loadingCompanyTier || loadingApplication) {
    return (
      <div className="flex justify-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (company) {
    if (hasAnyPlaidConnection) {
      return children;
    } else {
      if (company.tier === 'lite') {
        if (['Denied', 'Canceled'].includes(application?.status ?? '')) {
          return <ConnectPaymentAccountMarketing />;
        }
        if (application?.formId && !application.complete) {
          return <FinishApplingForZenaCardMarketing />;
        } else {
          return getPath(location.pathname) === 'transactions' ? (
            <UpsellZena />
          ) : (
            <ApplyForZenaCardMarketing />
          );
        }
      }
      if (company.tier === 'luxe') {
        // Case where no plaid connection and luxe, show finish applying (connect plaid)
        return <FinishApplingForZenaCardMarketing />;
      }
    }
  }

  return children;
}

import { useMutation, useQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { gql } from '__generated__/gql';
import { RoundButton } from 'components/atoms/Buttons';
import { Field } from 'components/atoms/Inputs/Field';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ReactNode, useState } from 'react';

const QUERY = gql(`
  query GetCompanyDetailsForStripeGhostRoute($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      tier
      stripeAssociation {
        id
        stripeCustomerId
        defaultSetupIntentId
        defaultPaymentMethodId
      }
    }
  }
`);

const INITIATE_STRIPE_MUTATION = gql(`
  mutation InitiateStripeCompanyAssociation($companySlug: String!) {
    connectCompanyToStripe(companySlug: $companySlug) {
      id
      stripeAssociation {
        id
        stripeCustomerId
        defaultSetupIntentId
        defaultPaymentMethodId
      }
    }
  }
`);

const INITIATE_LINK_BANK_ACCOUNT_MUTATION = gql(`
  mutation InitiateLinkBankAccountViaStripe($companySlug: String!) {
    createStripeFinancialConnectionSession(companySlug: $companySlug) {
      # intentionally do not use id here so it is not cached
      clientSecret
    }
  }
`);

const CREATE_STRIPE_PAYMENT_ACCOUNTS_MUTATION = gql(`
  mutation CreateStripePaymentAccounts($companySlug: String! $stripeAccounts: [StripeAccountInput!]!) {
    createStripePaymentAccounts(
      companySlug: $companySlug
      stripeAccounts: $stripeAccounts
    ) {
      id
      tier
      stripeAssociation {
        id
        stripeCustomerId
        defaultSetupIntentId
        defaultPaymentMethodId
      }
    }
  }
`);

export const StripeGhostRoute = () => {
  const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [stripeLoading, setStripeLoading] = useState(false);

  const { data, loading } = useQuery(QUERY, {
    variables: {
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug,
  });

  const [initiateStripeAssociation, { loading: loadingInitiation }] =
    useMutation(INITIATE_STRIPE_MUTATION);
  const [initiateLinkBankAccount, { loading: loadingLinkBankAccount }] = useMutation(
    INITIATE_LINK_BANK_ACCOUNT_MUTATION
  );
  const [saveConnectedAccounts, { loading: savingAccounts }] = useMutation(
    CREATE_STRIPE_PAYMENT_ACCOUNTS_MUTATION
  );

  const launchStripe = async ({ clientSecret }: { clientSecret: string }) => {
    setStripeLoading(true);
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY ?? '');
    if (stripe && clientSecret) {
      const financialConnectionsSessionResult = await stripe.collectFinancialConnectionsAccounts({
        clientSecret,
      });

      if (
        financialConnectionsSessionResult.financialConnectionsSession?.accounts &&
        financialConnectionsSessionResult.financialConnectionsSession?.accounts?.length > 0
      ) {
        await saveConnectedAccounts({
          variables: {
            companySlug: activeCompanySlug,
            stripeAccounts:
              financialConnectionsSessionResult.financialConnectionsSession.accounts.map(
                (account) => {
                  return {
                    id: account.id,
                    institutionName: account.institution_name,
                    accountLast4: account.last4 ?? '',
                    displayName: account.display_name,
                    category: account.category,
                    subcategory: account.subcategory,
                  };
                }
              ),
          },
          onCompleted: (data) => {
            if (data.createStripePaymentAccounts?.id) {
              console.log('ID?', data.createStripePaymentAccounts?.id);
            }
          },
        });
      } else {
        // TODO => handle this in real life
        console.log('ERROR - failed to link any accounts');
      }
    }
    setStripeLoading(false);
  };

  const company = data?.getCompany;

  if (loading) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  if (company && company.tier === 'luxe') {
    if (!company.stripeAssociation) {
      return (
        <Container>
          <h1>Hello there</h1>
          <Field clarifier="This would normally be handled automatically after creating a company. It may however be handled in this way if existing users have to opt in to switching to Stripe.">
            <RoundButton
              onClick={() => {
                initiateStripeAssociation({
                  variables: {
                    companySlug: activeCompanySlug,
                  },
                });
              }}
              className="w-max"
              isLoading={loadingInitiation}
            >
              Initiate Stripe Process
            </RoundButton>
          </Field>
        </Container>
      );
    }

    if (company.stripeAssociation && !company.stripeAssociation.defaultSetupIntentId) {
      return (
        <Container>
          <h1>Hello there</h1>
          <RoundButton
            onClick={() => {
              initiateLinkBankAccount({
                variables: {
                  companySlug: activeCompanySlug,
                },
                onCompleted: (data) => {
                  if (data.createStripeFinancialConnectionSession?.clientSecret) {
                    launchStripe({
                      clientSecret: data.createStripeFinancialConnectionSession.clientSecret,
                    });
                  }
                },
              });
            }}
            className="w-max"
            isLoading={loadingLinkBankAccount || stripeLoading || savingAccounts}
          >
            Link a verified bank account
          </RoundButton>
        </Container>
      );
    }

    return (
      <Container>
        <h1>You are already linked up to Stripe!</h1>
      </Container>
    );
  }

  return (
    <Container>
      <p>Invalid Company. Upgrade to luxe to continue</p>
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="py-10 px-6 grid gap-6 justify-start">{children}</div>;
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { PageTitle } from 'components/atoms/PageTitle';
import TagPurchaseWorkflow from 'components/widgets/TagPurchaseWorkflow/TagPurchaseWorkflow';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const GET_TRANSACTION = gql(`
  query GetTransactionForAuthorizationTagging($companySlug: String!, $transactionId: String!) {
    getTransaction(customerId: $companySlug, transactionId: $transactionId) {
      id
      name
      shortName
      amount
      amountWithDirection
      spendCategory
      zenaType
      date
      direction
      accountId
      isAuthorization
      categories
      rooms
      card {
        id
        name
      }
      tags {
        tagName
        value
      }
      roomTags {
        tagName
        value
      }
      categoryTags {
        tagName
        value
      }
      poCodeTag {
        tagName
        value
      }
      user {
        email
        fullName {
          first
          last
        }
      }
      receipt {
        imageUrl
      }
      lineItems {
        externalId
        totalCents
        description
      }
      childTransactionIds
    }
  }
`);

const TagAuthorizationPage = () => {
  const navigate = useNavigate();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { authorizationId } = useParams();

  const { data } = useQuery(GET_TRANSACTION, {
    variables: {
      companySlug: activeCompanySlug,
      transactionId: authorizationId || '',
    },
  });

  useEffect(() => {
    const transactionId = data?.getTransaction?.id;
    if (transactionId) {
      navigate(`/transactions/${transactionId}/tag`);
    }
  }, [activeCompanySlug, data, navigate]);

  return (
    <>
      <PageTitle hidden text="Tag a purchase" />
      <TagPurchaseWorkflow companySlug={activeCompanySlug} model={data?.getTransaction || null} />
    </>
  );
};

export default TagAuthorizationPage;

import { Link } from 'react-router-dom';
import { Donut } from './Donut/Donut';
import { ArrowRight, CircleCheck, XIcon } from 'lucide-react';

export const Checklist = ({
  onClose,
  stepsComplete,
  hasProject,
  hasZenaCard,
  hasLinkedAccounts,
  hasTransactions,
  hasInvitedTeammates,
}: {
  onClose: () => void;
  stepsComplete: number;
  hasProject: boolean;
  hasZenaCard: boolean;
  hasLinkedAccounts: boolean;
  hasTransactions: boolean;
  hasInvitedTeammates: boolean;
}) => {
  return (
    <div>
      <div className="flex justify-end mb-2">
        <button
          type="button"
          onClick={onClose}
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent-600 focus:ring-offset-2"
        >
          <XIcon />
        </button>
      </div>
      <div className="flex justify-between mb-6 px-2 md:px-12 gap-4">
        <div className="w-16 h-16 min-w-16">
          <Donut segmentsCompleted={stepsComplete} large />
        </div>
        <div className="text-xl max-w-96">Finish setting up your account</div>
      </div>
      <div className="grid grid-cols-1 divide-y">
        {!hasProject ? (
          <Link
            to="/projects?new=true"
            className="first:rounded-t-xl last:rounded-b-xl items-center cursor-pointer bg-tertiary-800"
          >
            <div className="p-4 flex justify-between">
              <div className="font-medium">Create your first project</div>
              <div className="min-w-4">
                <ArrowRight className="stroke-black stroke-2" size={14} />
              </div>
            </div>
          </Link>
        ) : null}
        {!hasZenaCard ? (
          <Link
            to="/application"
            className="first:rounded-t-xl last:rounded-b-xl items-center cursor-pointer bg-tertiary-800"
          >
            <div className="p-4 flex justify-between">
              <div className="font-medium">Apply for the Zena purchase card</div>
              <div className="min-w-4">
                <ArrowRight className="stroke-black stroke-2" size={14} />
              </div>
            </div>
          </Link>
        ) : null}
        {/* TODO - This will be added back in at a later time */}
        {!hasLinkedAccounts && false ? (
          <Link
            to="/cards"
            className="first:rounded-t-xl last:rounded-b-xl items-center cursor-pointer bg-tertiary-800"
          >
            <div className="p-4 flex justify-between">
              <div className="font-medium">Link your business credit cards and bank account</div>
              <div className="min-w-4">
                <ArrowRight className="stroke-black stroke-2" size={14} />
              </div>
            </div>
          </Link>
        ) : null}
        {/* This will be added back in after import transactions are shipped */}
        {!hasTransactions && false ? (
          <Link
            to="/transactions"
            className="first:rounded-t-xl last:rounded-b-xl items-center cursor-pointer bg-tertiary-800"
          >
            <div className="p-4 flex justify-between">
              <div className="font-medium">
                Import transactions to see how your projects are performing
              </div>
              <div className="min-w-4">
                <ArrowRight className="stroke-black stroke-2" size={14} />
              </div>
            </div>
          </Link>
        ) : null}
        {!hasInvitedTeammates ? (
          <Link
            to="/settings/company"
            className="first:rounded-t-xl last:rounded-b-xl items-center cursor-pointer bg-tertiary-800"
          >
            <div className="p-4 flex justify-between">
              <div className="font-medium">Invite teammates</div>
              <div className="min-w-4">
                <ArrowRight className="stroke-black stroke-2" size={14} />
              </div>
            </div>
          </Link>
        ) : null}
        <div className="border-t-4 border-black">
          {hasProject ? (
            <div className="flex items-center p-4 gap-4">
              <div className="min-w-6">
                <CircleCheck />
              </div>
              <div>Create your first project</div>
            </div>
          ) : null}
          {hasZenaCard ? (
            <div className="flex items-center p-4 gap-4">
              <div className="min-w-6">
                <CircleCheck />
              </div>
              <div>Apply for the Zena purchase card</div>
            </div>
          ) : null}
          {/* TODO - This will be added back in at a later time */}
          {hasLinkedAccounts && false ? (
            <div className="flex items-center p-4 gap-4">
              <div className="min-w-6">
                <CircleCheck />
              </div>
              <div>Link your business credit cards and bank account</div>
            </div>
          ) : null}
          {/* TODO - This will be added back in at a later time */}
          {hasTransactions && false ? (
            <div className="flex items-center p-4 gap-4">
              <div className="min-w-6">
                <CircleCheck />
              </div>
              <div>Import transactions to see how your projects are performing</div>
            </div>
          ) : null}
          {hasInvitedTeammates ? (
            <div className="flex items-center p-4 gap-4">
              <div className="min-w-6">
                <CircleCheck />
              </div>
              <div>Invite teammates</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

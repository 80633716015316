import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY = gql(`
  query GetProfileImageUrlsForUpload {
    getMe {
      id
      smallImageUrl
    }
  }
`);

export const useGetProfileImageUrlsForUpload = ({
  activeCompanySlug,
}: {
  activeCompanySlug: string;
}) => {
  const { data, loading, error, refetch } = useQuery(QUERY, {
    skip: !activeCompanySlug,
  });

  return {
    user: data?.getMe ?? null,
    loading,
    error,
    refetch,
  };
};

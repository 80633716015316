import { SavableSlideOver } from 'components/molecules/SlideOvers';
import { UnSavedChangesModal } from 'components/molecules/Modals';
import { useState } from 'react';
import { DateFilter } from 'components/molecules/DateFilter';
import { useStatementFilter } from '../StatementFilter';

interface StatementFilterProps {
  visible: boolean;
  onClose: () => void;
}

const StatementFiltersPanel = () => {
  const { selectedFilters, updateFilters } = useStatementFilter();

  return (
    <>
      <div className="mt-8">
        <DateFilter addTopBar filters={selectedFilters} setFilters={updateFilters} />
      </div>
    </>
  );
};

const StatementFilterPanelWrapper = ({ visible, onClose }: StatementFilterProps) => {
  const [openModal, setModalState] = useState(false);
  const {
    saveFilters,
    clearFilters,
    discardChanges: discard,
    hasUnsavedChanges,
    errors,
  } = useStatementFilter();

  const checkForUnsavedChanges = () => {
    if (hasUnsavedChanges) {
      setModalState(true);
    } else {
      onClose();
    }
  };
  const saveChanges = () => {
    saveFilters();
    setModalState(false);
    onClose();
  };

  const discardChanges = () => {
    discard();
    setModalState(false);
    onClose();
  };
  const hasErrors = errors ? Object.keys(errors).length > 0 : false;
  return (
    <>
      <SavableSlideOver
        open={visible}
        onClose={checkForUnsavedChanges}
        onSave={saveChanges}
        onClear={clearFilters}
        hasErrors={hasErrors}
      >
        <StatementFiltersPanel />
      </SavableSlideOver>
      <UnSavedChangesModal
        show={openModal}
        setShow={setModalState}
        onSaveChanges={saveChanges}
        onDiscardChanges={discardChanges}
      />
    </>
  );
};

export default StatementFilterPanelWrapper;

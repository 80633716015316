interface GasMeterProps {
  total: number;
  used: number;
  usedYellow?: number;
  color?: string;
  thickness?: string;
}

const GasMeter = ({ total, used, usedYellow, color, thickness }: GasMeterProps) => {
  usedYellow ||= 0;
  color ||= 'bg-lavender';
  thickness ||= 'h-1.5';
  const percentage = ((used + usedYellow) / total) * 100;
  const amountLeft = total - used - usedYellow;
  let meterClass = '';

  if (total <= 0) {
    meterClass = 'bg-secondary-400 w-full';
  } else if (percentage >= 100) {
    meterClass = 'bg-forest w-full';
  }

  const fillWidth = total > 0 ? `${(100 * amountLeft) / total}%` : '0%';

  let colorSection = (
    <div
      className={`${color} relative ${thickness} rounded-md ${meterClass}`}
      style={{ width: fillWidth }}
    ></div>
  );
  if (usedYellow > 0) {
    // If there is yellow, show yellow behind lavender's rounded corners
    colorSection = (
      <div
        className={`bg-[#FEF1B1] relative ${thickness} rounded-l-md`}
        style={{ width: fillWidth }}
      >
        <div className={`${color} relative ${thickness} rounded-md ${meterClass}`} />
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-start ${thickness} rounded-md bg-secondary-400">
      {colorSection}
      <div
        className={`bg-[#FEF1B1] relative ${thickness} ${
          amountLeft > 0 ? 'rounded-r-md' : 'rounded-md'
        }`}
        style={{ width: `${total > 0 ? (usedYellow / total) * 100 : 0}%` }}
      ></div>
    </div>
  );
};

export default GasMeter;

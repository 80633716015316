import { ReactNode } from 'react';
import { PageTitle } from 'components/atoms/PageTitle';
import { TabLink, TabsContainer } from 'components/atoms/Tab';

export const PerksLayoutPage = ({
  children,
  pageName,
}: {
  children: ReactNode;
  pageName: 'REWARDS' | 'DISCOUNTS';
}) => {
  return (
    <>
      <PageTitle text="Perks" hidden />
      <div className="pt-7 px-4 lg:pt-11 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
        <TabsContainer>
          <TabLink isActive={pageName === 'REWARDS'} href="/perks/rewards">
            Rewards
          </TabLink>
          <TabLink isActive={pageName === 'DISCOUNTS'} href="/perks/discounts">
            Discounts
          </TabLink>
        </TabsContainer>

        <section className="grow overflow-scroll">{children}</section>
      </div>
    </>
  );
};

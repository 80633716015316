import { ReviewProject } from './ReviewProject';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useAuthorizedUsers, useGetProjectReview } from './data';

export const ProjectReviewPage = ({
  projectId,
  onApproveProject,
}: {
  projectId: string;
  onApproveProject: () => void;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { project } = useGetProjectReview({
    companySlug: activeCompanySlug,
    projectId,
  });
  const { authorizedUsers } = useAuthorizedUsers({
    companySlug: activeCompanySlug,
  });

  if (project && authorizedUsers) {
    return (
      <ReviewProject
        customerId={activeCompanySlug}
        projectName={project.name}
        projectId={project.id}
        budget={project.budget ?? undefined}
        users={project.users}
        authorizedUsers={authorizedUsers.map((user) => ({
          id: user.userId,
          name: `${user.fullName.first} ${user.fullName.last}`,
          email: user.email,
        }))}
        onApproveProject={onApproveProject}
      />
    );
  }

  return null;
};

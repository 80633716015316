import { useState, useMemo } from 'react';
import { useActiveCompany } from 'providers/ActiveCompany';
import { RoundButton } from 'components/atoms/Buttons';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { StatusIndicator } from 'components/atoms/StatusIndicator';
import intuitQuickbooksLogo from 'images/intuitQuickbooksLogo.webp';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import { CompanyTier } from '__generated__/graphql';
import { useGetIntuitAppConnection } from './data';
import { Link } from 'react-router-dom';
import { isAdmin } from 'hooks/access';
import { Tooltip } from 'react-tooltip';

const MainIntegrations = () => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { appConnection } = useGetIntuitAppConnection({
    companySlug,
  });

  const isLuxeCompany = activeCompany?.tier === CompanyTier.luxe;
  const isActive = !!appConnection?.startedAt;
  const admin = isAdmin(companySlug);
  const tooltipId = 'qbo-integration-tooltip';

  const status = useMemo(() => {
    if (appConnection && !appConnection.disconnectedAt && appConnection?.connectionInvalidatedAt) {
      return 'invalid';
    } else if (isActive) {
      return 'active';
    } else {
      return 'inactive';
    }
  }, [appConnection?.connectionInvalidatedAt, isActive]);

  return (
    <>
      <div>Enable integrations between Zena and your other business tools.</div>
      <div className="flex gap-6 w-full flex-wrap">
        <div
          className="flex-1"
          data-tooltip-id={tooltipId}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <OutlinedWidget className="p-12">
            <div className="flex w-full flex-wrap mb-8">
              <img
                src={intuitQuickbooksLogo}
                alt="Intuit QuickBooks Logo"
                className="w-full max-w-64"
              />
              <p className="text-sm text-gray-500 sm:ml-auto max-w-36 text-center">
                Send categorized transactions to QBO
              </p>
            </div>
            <div className="flex flex-wrap justify-between sm:flex-nowrap">
              <StatusIndicator
                status={status}
                className={cn(
                  'border w-32 flex justify-center h-[42px]',
                  isActive ? '' : 'bg-primary-200 text-primary-500'
                )}
                text={status === 'invalid' ? 'Invalid' : undefined}
              />
              {isLuxeCompany ? (
                <RoundButton
                  variant="secondary"
                  className="w-32 sm:ml-auto sm:mt-0 mt-4"
                  onClick={() => navigate('qbo')}
                >
                  Configure
                </RoundButton>
              ) : (
                <Link to="/what-to-expect">
                  <RoundButton
                    className="sm:ml-auto sm:mt-0 mt-4"
                    disabled={!admin}
                    variant="primary"
                  >
                    Apply for the Zena card
                  </RoundButton>
                </Link>
              )}
            </div>
            {!isLuxeCompany && (
              <Tooltip id={tooltipId} place="bottom" isOpen={tooltipOpen}>
                Requires the Zena card. <Link to="/what-to-expect">Apply now.</Link>
              </Tooltip>
            )}
          </OutlinedWidget>
        </div>
        <OutlinedWidget className="flex-1 p-12 flex flex-col justify-between">
          <div className="text-xl text-gray-500 mb-8">Coming soon...</div>
          <div id="test" className="flex flex-wrap sm:flex-nowrap">
            <div className="text-xl italic max-w-72">
              Not seeing an integration you need? Let us know!
            </div>
            <RoundButton variant="tertiary" className="sm:ml-auto mt-4 sm:mt-0 h-fit text-nowrap">
              Send Feedback
            </RoundButton>
          </div>
        </OutlinedWidget>
      </div>
    </>
  );
};

export default MainIntegrations;

interface ProgressBarProps {
  step: number;
  numSteps: number;
  height?: number;
  rounded?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  step,
  numSteps,
  rounded = false,
  height = 2.5,
}) => {
  const stepWidth = 100 / numSteps; // width of each step
  const progressWidth = stepWidth * step; // width of completed steps

  return (
    <>
      <div className={`bg-secondary-400 h-${height} relative ${rounded ? 'rounded-xl' : null}`}>
        {!rounded &&
          [...Array(numSteps)].map(
            (_, index) =>
              index > 0 && (
                <div
                  key={index}
                  className="absolute top-0 h-full z-10 w-2 bg-ivory"
                  style={{
                    left: `${index * stepWidth}%`,
                  }}
                />
              )
          )}
        <div
          className={`absolute left-0 top-0 h-full bg-lavender ${rounded ? 'rounded-xl' : null}`}
          style={{
            width: `${progressWidth}%`,
          }}
        />
      </div>
    </>
  );
};

export default ProgressBar;

import React, { FocusEventHandler, KeyboardEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

let debounceTime: NodeJS.Timeout;
const debounce = function (callback: (...args: any[]) => void, delay = 1000) {
  return (...args: any[]) => {
    clearTimeout(debounceTime);
    debounceTime = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export type TextInputProps = {
  id?: string;
  label: string;
  placeholder?: string;
  useFormRegister?: UseFormRegisterReturn;
  onChangeText?: (value: string) => void;
  prefix?: string;
  suffix?: string;
  debounce?: number;
  disabled?: boolean;
  type?: 'text' | 'number' | 'password' | 'date' | undefined;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  hasError?: boolean;
  errorMessage?: string;
  disablePasswordManager?: boolean;
  step?: number;
  min?: number | string;
  max?: number | string;
  maxLength?: number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onInput?: KeyboardEventHandler<HTMLInputElement>;
  defaultValue?: string;
  testId?: string;
};

// @deprecated User <Input /> instead => Wrap your inputs with the <Field /> component if you want style guide appropriate labels, error messages, etc.
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    id,
    label,
    placeholder,
    useFormRegister,
    onChange,
    value,
    className,
    inputClassName,
    prefix = undefined,
    suffix = undefined,
    type = 'text',
    hasError = false,
    errorMessage,
    disabled = false,
    step,
    min,
    max,
    maxLength,
    onBlur,
    onKeyDown,
    onInput,
    defaultValue,
    testId,
  } = props;
  let { required, disablePasswordManager } = props;
  if (required === undefined) {
    required = true;
  }
  const onChangeText = (text: string) => {
    props.debounce &&
      debounce(() => {
        props.onChangeText && props.onChangeText(text);
      }, props.debounce)();
  };

  const borderOutline = hasError ? 'border-rustic' : disabled ? 'border-feather' : 'border-marble';
  const labelTextColor = disabled ? 'text-feather' : 'text-asphalt';
  return (
    <div>
      <div
        onChange={(e) => onChangeText((e.target as HTMLInputElement).value)}
        className={`flex flex-col px-4 pt-2 pb-1.5 rounded-lg border ${borderOutline} ${className}`}
      >
        <label className={`text-sm mb-1 ${labelTextColor}`}>{label}</label>
        {!required && <span className="text-asphalt ml-1">(optional)</span>}
        <div className="flex">
          {prefix ? <span className="mr-1 text-asphalt">{prefix}</span> : null}
          <input
            id={id}
            type={type}
            className={`w-full bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-lavender ${inputClassName}`}
            placeholder={placeholder}
            ref={ref}
            value={value === 'NaN' ? '' : value}
            onChange={onChange}
            disabled={disabled}
            step={step}
            min={min}
            max={max}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            onInput={onInput}
            data-1p-ignore={!!disablePasswordManager}
            data-lpignore={!!disablePasswordManager}
            data-testid={testId}
            defaultValue={defaultValue}
            {...useFormRegister}
            onBlur={(e) => {
              useFormRegister && useFormRegister.onBlur(e);
              onBlur && onBlur(e);
            }}
          />
          {suffix ? <span className="mr-1 text-asphalt">{suffix}</span> : null}
        </div>
      </div>
      {hasError && <div className="mt-2 text-rustic">{errorMessage}</div>}
    </div>
  );
});

export default TextInput;

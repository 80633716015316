import moment from 'moment';
import { cn, formatNumberAsDollars } from 'utils';

interface TooltipProps {
  payload?: { value: number }[];
  label?: string;
}

const TooltipContent = (props: TooltipProps) => {
  const { label, payload } = props;
  const value = payload?.[0]?.value;
  if (value === undefined) {
    return null;
  }
  const formattedValue = formatNumberAsDollars(value);
  const formattedDate = moment(label).format('MMM DD, YYYY');

  return (
    <div className="bg-white rounded-xl shadow-normal py-1.5 text-sm">
      <div className="mx-4 font-medium">{formattedDate}</div>
      <hr className="my-1.5" />
      <div className="mx-4 flex">
        <div
          className={cn('w-3.5 h-3.5 rounded-full', value < 0 ? 'bg-red-700' : 'bg-green-700')}
        />
        <div className="font-medium ml-1">{formattedValue}</div>
        <div className="text-gray-500 ml-4">Remaining income</div>
      </div>
    </div>
  );
};

export default TooltipContent;

import { useQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ApolloError } from '@apollo/client';

const QUERY_GET_ZENA_COMPANY_NAME = gql(`
  query GetZenaCompanyName($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      name
    }
  }
`);

export const useGetZenaCompanyName = ({
  companySlug,
  onSuccess,
  onError,
}: {
  companySlug: string;
  onSuccess?: () => void;
  onError?: (error: ApolloError) => void;
}) => {
  const { data, loading, error } = useQuery(QUERY_GET_ZENA_COMPANY_NAME, {
    variables: {
      companySlug,
    },
    onCompleted: onSuccess,
    onError,
  });

  return {
    company: data?.getCompany ?? { name: null, id: null },
    loading,
    error,
  };
};

const MUTATION_UPDATE_COMPANY_NAME = gql(`
  mutation UpdateCompanyName($companySlug: String!, $companyName: String!) {
    updateCompany(companySlug: $companySlug, name: $companyName) {
      id
      name
    }
  }
`);

export const useUpdateCompanyName = ({
  companySlug,
  companyName,
  onSuccess,
  onError,
}: {
  companySlug: string;
  companyName: string;
  onSuccess?: () => void;
  onError?: (error: ApolloError) => void;
}) => {
  const [updateCompanyName, { loading, error }] = useMutation(MUTATION_UPDATE_COMPANY_NAME, {
    variables: {
      companySlug,
      companyName,
    },
    onCompleted: onSuccess,
    onError,
    refetchQueries: ['GetZenaCompanyName'],
  });

  return {
    updateCompanyName,
    loading,
    error,
  };
};

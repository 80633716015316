import React from 'react';
import { cn } from 'utils';
import Button, { ButtonProps, getLoaderStyle } from '../Button/Button';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import {
  ChevronDown,
  ChevronLeft,
  DollarSign,
  ArrowDownToLine,
  ListFilter,
  Plus,
  Settings,
} from 'lucide-react';

export interface RoundedButtonProps extends Omit<ButtonProps, 'icon'> {
  icon?: 'plus' | 'money' | 'filter' | 'cog' | 'download' | 'chevronLeft' | 'chevronDown';
}

const RoundButton = React.forwardRef<HTMLButtonElement, RoundedButtonProps>(
  (
    { icon, children, text, className, variant = 'primary', disabled, isLoading = false, ...props },
    ref
  ) => {
    const iconClassName = ['primary', 'danger'].includes(variant)
      ? 'fill-white'
      : disabled
        ? 'fill-muted'
        : 'fill-primary';

    return (
      <Button
        className={cn(
          'rounded-[20px] outline-accent-600 flex justify-center items-center flex-row gap-2',
          className
        )}
        ref={ref}
        disabled={disabled}
        variant={variant}
        icon={
          icon && !isLoading ? (
            <>
              {icon === 'plus' && <Plus className={iconClassName} />}
              {icon === 'money' && <DollarSign className={iconClassName} />}
              {icon === 'filter' && <ListFilter className={iconClassName} />}
              {icon === 'cog' && <Settings className={iconClassName} />}
              {icon === 'download' && <ArrowDownToLine className={iconClassName} size={16} />}
              {icon === 'chevronLeft' && <ChevronLeft className={iconClassName} />}
              {icon === 'chevronDown' && <ChevronDown className={iconClassName} />}
            </>
          ) : undefined
        }
        text={isLoading ? '' : text}
        {...props}
      >
        {isLoading ? (
          <LoadingSpinner className={getLoaderStyle(variant)} />
        ) : (
          !!children && children
        )}
      </Button>
    );
  }
);

export default RoundButton;

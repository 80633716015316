import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { useActiveCompany } from 'providers/ActiveCompany';

const QUERY = gql(`
query GetReceiptImage($customerId: String!, $transactionId: String!) {
    getTransaction(customerId: $customerId, transactionId: $transactionId) {
      id
      receipt {
        imageUrl
      }
      missingFields {
        receipt
      }
    }
  }
`);

export const useGetReceiptImage = ({ transactionId }: { transactionId: string }) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      customerId: activeCompanySlug,
      transactionId,
    },
  });

  return {
    receiptImageUrl: data?.getTransaction?.receipt?.imageUrl,
    missingReceipt: data?.getTransaction?.missingFields?.receipt,
    loading,
    error,
  };
};

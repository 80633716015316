import React from 'react';

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  animation?: 'wave' | 'pulse';
  width?: 'small' | 'medium' | 'large' | 'full';
  height?: 'small' | 'medium' | 'large' | 'full';
  rounded?: boolean;
  vertical?: boolean;
}

export const waveAnimationClasses =
  'isolate overflow-hidden before:absolute before:inset-0 before:-translate-x-full before:animate-[wave_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent';

export { CardSkeleton } from 'components/atoms/Skeletons/Cards';
export { DataBarsSkeleton } from 'components/atoms/Skeletons/Data';
export {
  RectangleSkeleton,
  SquareSkeleton,
  CircleSkeleton,
} from 'components/atoms/Skeletons/Shapes';
export {
  HeadingSkeleton,
  ParagraphSkeleton,
  TextSkeleton,
} from 'components/atoms/Skeletons/Typography';
export { DataWidgetSkeleton, TextWidgetSkeleton } from 'components/atoms/Skeletons/Widgets';

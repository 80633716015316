import { useMutation } from '@apollo/client';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { StorageFile } from '@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types';
import { gql } from '__generated__';
import { Transaction, TransactionReceipt } from '__generated__/graphql';
import { TransactionReceiptImage } from 'components/atoms/TransactionReceiptImage';
import useCustomAuth from 'hooks/useCustomAuth';
import { useCallback, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { cn } from 'utils';
import { FileUp, Image } from 'lucide-react';

const MUTATION_FINALIZE_MODEL_RECEIPT_UPLOAD = gql(`
  mutation FinalizeModelReceiptUpload($modelId: String!, $modelType: String!, $key: String!, $customerId: String!) {
    finalizeModelReceiptUpload(customerId: $customerId, key: $key, modelId: $modelId, modelType: $modelType) {
      imageUrl
    }
  }
`);

export const TransactionReceiptUploader = ({
  model,
  customerId,
  displayStaticTooltip,
  showMissingRequirements = false,
}: {
  model: Transaction;
  customerId: string;
  displayStaticTooltip?: boolean;
  showMissingRequirements?: boolean;
}) => {
  const { user } = useCustomAuth();
  const userSub = user?.attributes?.sub;
  const [receiptImageUrl, setReceiptImageUrl] = useState<TransactionReceipt | null | undefined>();
  const [finalizeTransactionReceiptUpload, { error: finalizeTransactionReceiptUploadError }] =
    useMutation(MUTATION_FINALIZE_MODEL_RECEIPT_UPLOAD, {});
  let modelType: 'authorization' | 'transaction';
  if (model.__typename === 'Transaction') {
    if (model.type === 'TRANSACTION') {
      modelType = 'transaction';
    } else {
      modelType = 'authorization';
    }
  } else {
    modelType = 'authorization';
  }

  // Add the user's sub, project ID, and file name to the key
  const processFile = ({
    file,
    key,
  }: Required<Pick<StorageFile, 'file' | 'key'>>): Required<Pick<StorageFile, 'file' | 'key'>> => {
    // remove all non-alphanumeric characters from the file name
    const cleanedFilename = file?.name?.replace(/[^a-zA-Z0-9.]/g, '');
    return {
      file,
      key: `user/${userSub}/${modelType}/${model.id}/${cleanedFilename}`,
    };
  };

  const onUploadSuccess = useCallback(
    async ({ key }: { key?: string }) => {
      const session = await fetchAuthSession();
      const identityId = session.identityId;
      const result = await finalizeTransactionReceiptUpload({
        variables: {
          customerId: customerId,
          modelId: model.id,
          modelType,
          key: `private/${identityId}/${key}`,
        },
      });
      setReceiptImageUrl(result.data?.finalizeModelReceiptUpload);
    },
    [finalizeTransactionReceiptUpload, customerId, model.id, modelType]
  );

  const onUploadError = useCallback((error: string, file: { key: string }) => {
    console.error(error, file);
  }, []);

  return (
    <div className="w-full">
      <StorageManager
        acceptedFileTypes={['image/*', 'application/pdf']}
        accessLevel="private"
        maxFileSize={10 * 1024 * 1024}
        maxFileCount={1}
        processFile={processFile}
        onUploadSuccess={onUploadSuccess}
        onUploadError={onUploadError}
        components={{
          DropZone({ children, displayText, inDropZone, ...rest }) {
            // @ts-ignore
            return (
              // Copy default amplify storage manager dropzone style
              <div
                className={cn(
                  'amplify-storagemanager__dropzone',
                  showMissingRequirements &&
                    '!border-4 !border-solid !border-accent-700 !rounded-lg',
                  inDropZone && 'amplify-storagemanager__dropzone--active'
                )}
                {...rest}
              >
                {/* Desktop */}
                <div className="hidden md:flex flex-col items-center" {...rest}>
                  <FileUp />
                  <div className="text-[22px] m-2">Drag and drop a receipt or</div>
                  {children}
                  <div
                    className={`text-zinc-600 text-sm mt-4 ${
                      displayStaticTooltip ? 'block' : 'hidden'
                    }`}
                  >
                    JPG, PNG, PDF – 5 MB max
                  </div>
                </div>
                {/* Mobile */}
                <div className="flex md:hidden flex-col items-center" {...rest}>
                  <Image />
                  <div className={`text-zinc-600 text-sm leading-[18.20px] m-2`}>
                    JPG, PNG, PDF – 5 MB max
                  </div>
                  {children}
                </div>
              </div>
            );
          },
        }}
      />
      {finalizeTransactionReceiptUploadError && (
        <div>Error uploading receipt: {finalizeTransactionReceiptUploadError.message}</div>
      )}
      {receiptImageUrl && (
        <div className="mt-1">
          <TransactionReceiptImage transactionReceipt={receiptImageUrl} />
        </div>
      )}
    </div>
  );
};

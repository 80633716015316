import { UnderlineButton } from 'components/atoms/Buttons';
import { ProgressBar } from 'components/atoms/ProgressBar';
import useCustomAuth from 'hooks/useCustomAuth';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import closeButton from '../../../images/closeButton.webp';
import zenaLogo from '../../../images/zenaLogo.webp';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Alert } from 'components/atoms/Alert';

// This is a full page workflow component
type WorkflowProps = {
  rootUrl?: string;
  closeHref?: string;
  children: React.ReactNode;
  currentStep: string;
  alertMsg?: string;
  progress?: [number, number];
  loading?: boolean;
};

const Workflow = ({
  children,
  closeHref,
  currentStep,
  rootUrl,
  alertMsg,
  progress,
  loading,
}: WorkflowProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { signOut } = useCustomAuth();
  const [progressStep, progressTotal] = progress || [0, 0];

  useEffect(() => {
    if (!searchParams.has('step') || searchParams.get('step') !== currentStep) {
      setSearchParams({ ...searchParams, step: currentStep });
    }
  }, [currentStep, searchParams, setSearchParams]);

  return (
    <div className="bg-ivory">
      <header className="min-h-[10vh]">
        <div>
          <ProgressBar step={progressStep} numSteps={progressTotal} height={2} />
        </div>
        <div className="flex justify-between mt-9 ml-6">
          <div>
            <a href={`${rootUrl || '/'}`}>
              <img className="object-contain h-6" src={zenaLogo} alt="Zena" />
            </a>
          </div>
          {alertMsg && <Alert variation="error">{alertMsg}</Alert>}
          {!!closeHref ? (
            <Link className="flex items-center" to={closeHref}>
              <div className="underline">Close</div>
              <img className="w-3 h-3 ml-3.5 mr-9 cursor-pointer" src={closeButton} alt="Close" />
            </Link>
          ) : (
            <UnderlineButton
              className="absolute top-4 right-6 sm:right-16"
              onClick={signOut}
              text="Logout"
            />
          )}
        </div>
      </header>
      <div className="grid min-h-[90vh] place-content-center">
        {loading ? (
          <div className="grid place-items-center">
            <div className="flex items-center">
              <LoadingSpinner /> <p className="ml-2">Loading ...</p>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Workflow;

import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ApplicationStatus } from '__generated__/graphql';
import { GenericLoader } from 'components/atoms/Loader';
import { PageTitle } from 'components/atoms/PageTitle';
import { ApplicationForm } from './ApplicationForm';
import useCustomAuth from 'hooks/useCustomAuth';
import { useActiveCompany } from 'providers/ActiveCompany';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GET_COMPANY_AFTER_PLAID_CONNECT = gql(`
  query GetCompanyAfterPlaidConnect($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      tier
    }
  }
`);

const APPLICATION_STATUS_QUERY = gql(`
  query ApplicationStatusQuery  {
    getApplicationStatus {
      status
    }
  }
`);

const SUBSCRIPTION_APPLICATION_FORM_COMPLETE = gql(`
  subscription OnApplicationFormComplete($userId: String!) {
    onApplicationFormComplete(resourceId: $userId, resourceType: "user") {
      resourceId,
      resourceType
    }
  }
`);

const REPAYMENT_CONNECTION_COMPLETE_QUERY = gql(`
  query RepaymentConnectionCompleteQueryOnApplicationPage($companySlug: String!)  {
    getRepaymentConnectionStatus(companySlug: $companySlug) {
      id
      connected
    }
  }
`);

const ApplicationFormPage = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { user } = useCustomAuth();
  const userSub = user?.attributes?.sub;
  const [getCompany] = useLazyQuery(GET_COMPANY_AFTER_PLAID_CONNECT);
  const [applicationComplete, setApplicationComplete] = useState(false);
  const [applicationFormStatus, setApplicationFormStatus] = useState<
    ApplicationStatus | null | undefined
  >();
  const navigate = useNavigate();

  const { data } = useQuery(REPAYMENT_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug: activeCompanySlug,
    },
    fetchPolicy: 'network-only',
  });

  const [getApplicationStatus, { loading, data: applicationStatusData }] = useLazyQuery(
    APPLICATION_STATUS_QUERY,
    {
      onCompleted: ({ getApplicationStatus }) => {
        setApplicationFormStatus(getApplicationStatus?.status);
      },
    }
  );

  useSubscription(SUBSCRIPTION_APPLICATION_FORM_COMPLETE, {
    variables: { userId: userSub || '' },
    skip: !userSub,
    shouldResubscribe: true,
    onData: (result) => {
      const complete = !!result.data.data?.onApplicationFormComplete?.resourceId;
      if (complete) {
        getCompany({
          fetchPolicy: 'network-only',
          variables: {
            companySlug: activeCompanySlug,
          },
        }).then(() => {
          setApplicationComplete(true);
        });
      }
    },
  });

  useEffect(() => {
    if (applicationComplete && !applicationFormStatus) {
      getApplicationStatus();
    }
    // If the user has company IDs and application is already complete, redirect to dashboard
    if (
      applicationComplete &&
      applicationFormStatus &&
      ['Approved'].includes(applicationFormStatus)
    ) {
      if (data?.getRepaymentConnectionStatus?.connected) {
        // redirect to dashboard if user ends up here and already has an account linked with plaid
        navigate('/dashboard');
      } else {
        // redirect to connect a bank account after completing the application
        navigate('/connect-bank-account', { replace: true });
      }
    }
    // Redirect to dashboard if application is denied/canceled
    if (applicationFormStatus && ['Denied', 'Canceled'].includes(applicationFormStatus)) {
      navigate('/dashboard');
    }
    // Otherwise, do nothing and render form.
  }, [applicationComplete, applicationFormStatus]);

  useEffect(() => {
    if (applicationStatusData?.getApplicationStatus?.status === 'Approved') {
      navigate('/connect-bank-account', { replace: true });
    } else if (
      ['Canceled', 'Denied'].includes(applicationStatusData?.getApplicationStatus?.status || '')
    ) {
      navigate('/dashboard');
    }
  }, [applicationStatusData]);

  if (loading)
    return (
      <div className="w-full flex justify-center mt-32">
        <GenericLoader />
      </div>
    );
  return (
    <>
      <PageTitle hidden text="Sign up" />
      <ApplicationForm />
    </>
  );
};

export default ApplicationFormPage;

import { GasMeter } from 'components/atoms/GasMeter';
import { toDollarDisplay } from 'utils';

interface AmountSpentProps {
  spent: number;
  total: number | undefined;
  text: string;
}

export const AmountSpent = ({ spent, total, text }: AmountSpentProps) => {
  let content;
  if (Number.isNaN(total) || total === undefined) {
    content = (
      <div className="align-center text-center m-2 mt-4">
        <div className={'text-gray-500 font-semibold'}>{text + ' spent'}</div>
        {toDollarDisplay(spent)}
      </div>
    );
  } else {
    content = (
      <div className="rounded-3xl p-6 h-fit">
        <div className="flex justify-between">
          <div className="text-left">
            <div className="text-asphalt mb-2">{text + ' spent'}</div>
            {toDollarDisplay(spent)}
          </div>
          <div className="text-right">
            <div className="text-asphalt mb-2">{text + ' remaining'}</div>
            {toDollarDisplay(total - spent)}
          </div>
        </div>
        <div className="mt-6">
          <GasMeter used={spent} total={total} />
        </div>
      </div>
    );
  }
  return <div className="border border-feather rounded-3xl">{content}</div>;
};

import { onApolloError, toCents } from '../../../../utils';
import { AppState, useAppState } from '../../../../stores/UserStore';
import { useMutation } from '@apollo/client';
import SettingsForm from 'components/molecules/SettingsForm';
import { useState } from 'react';
import { MUTATION_UPDATE_PROJECT } from '../gql';
import ProjectBudgetForm from './ProjectBudgetForm';
import { toDollarDisplay } from '../../../../utils';

export const ProjectBudget = ({
  customerId,
  projectId,
  budget: initialBudget,
}: {
  customerId: string;
  projectId: string;
  budget?: number;
}) => {
  const [, setErrorMsg] = useAppState((state: AppState) => [
    state.setSuccessMsg,
    state.setErrorMsg,
  ]);
  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT, {
    onError: (error) => onApolloError(error, setErrorMsg, ['UpsertFailed']),
  });
  const [budget, setBudget] = useState<number | undefined>(
    initialBudget ? initialBudget / 100 : undefined
  );
  const onSubmit = () => {
    const b = budget ? toCents(budget) : budget;
    updateProject({
      variables: {
        customerId,
        projectId,
        attributes: {
          budget: b,
        },
      },
    });
  };
  const onFormReset = () => {
    setBudget(initialBudget ? initialBudget / 100 : undefined);
  };

  const ProjectBudgetDisplay = ({ budget }: { budget?: number }) => {
    return budget !== undefined ? toDollarDisplay(budget) : <div>Not set</div>;
  };

  return (
    <SettingsForm formTitle="Project budget" onSubmit={onSubmit} onCancel={onFormReset}>
      {({ editing }) =>
        editing ? (
          <ProjectBudgetForm budget={budget} setBudget={setBudget} />
        ) : (
          <ProjectBudgetDisplay budget={budget} />
        )
      }
    </SettingsForm>
  );
};

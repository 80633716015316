import { RectangleSkeleton } from 'components/atoms/Skeletons';

export const LoadingWidgetCard = () => {
  return (
    <div className="bg-white border-primary-400 border rounded-3xl h-80">
      <div className="wave animate-pulse rounded-2xl w-full h-32 relative bg-gray-300/60 mb-4" />
      <ul className="space-y-3 p-4">
        {[...Array(3)].map((_, i) => (
          <li key={i}>
            <div className="grid grid-cols-4 gap-2">
              <RectangleSkeleton width="large" className="col-span-2" />
              <RectangleSkeleton width="medium" className="justify-self-end" />
              <RectangleSkeleton width="large" className="justify-self-end" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

import { useBackgroundQuery, useReadQuery } from '@apollo/client';
import { Suspense } from 'react';
import { gql } from '__generated__';
import { TableHead } from 'components/atoms/table/TableHead';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { TableRow } from 'components/atoms/table/TableRow';
import { nonBlankable, toFirstLetterUpcase } from 'utils';
import { RelativeDate } from 'components/atoms/RelativeDate';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

const QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS = gql(`
  query GetAllPlaidTransactionFeedAccounts($companySlug: String!) {
    getPlaidTransactionFeedAccounts(companySlug: $companySlug) {
      id
      companySlug
      plaidItemId
      plaidInstitutionId
      refreshedAt
      disconnectedAt
      createdAt
      name
      mask
      accountType
      accountSubtype
    }
  }
`);

const TransactionImportAccountsTable = ({ companySlug }: { companySlug: string }) => {
  const [transactionFeedsQueryRef] = useBackgroundQuery(QUERY_GET_PLAID_TRANSACTION_FEED_ACCOUNTS, {
    variables: {
      companySlug,
    },
  });

  const {
    data: { getPlaidTransactionFeedAccounts: transactionFeedAccounts },
  } = useReadQuery(transactionFeedsQueryRef);

  return (
    <TableWithPagination
      loading={false}
      meta={{ pageNumber: 1, pageSize: 10, total: transactionFeedAccounts?.length || 0 }}
      viewMoreButtonCopy=""
      loadNextPage={() => {}}
    >
      <TableHead>
        <td>Account</td>
        <td>Type</td>
        <td>Status</td>
        <td>Last Import</td>
      </TableHead>
      <tbody>
        <Suspense fallback={<div>Loading accounts...</div>}>
          {transactionFeedAccounts.map((account) => {
            return (
              <TableRow key={account.id}>
                <td>{`${[account.name, account.mask].filter(nonBlankable).join(' ')}`}</td>
                <td>
                  {[account.accountType, account.accountSubtype]
                    .filter(nonBlankable)
                    .map(toFirstLetterUpcase)
                    .join(' ')}
                </td>
                <td>{account.disconnectedAt ? 'Disconnected' : 'Connected'}</td>
                <td>
                  {account.refreshedAt ? (
                    <RelativeDate date={account.refreshedAt} includeTime></RelativeDate>
                  ) : (
                    <LoadingSpinner />
                  )}
                </td>
              </TableRow>
            );
          })}
        </Suspense>
      </tbody>
    </TableWithPagination>
  );
};

export default TransactionImportAccountsTable;

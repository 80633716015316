import { formatNumber } from 'utils/number';

interface PercentageBarProps {
  used: number;
  total: number;
}

const PercentageBar = ({ used, total }: PercentageBarProps) => {
  const amountSpentFraction = used / total;
  const amountSpentPercentage = amountSpentFraction * 100;

  return (
    <div className="grid gap-1">
      <div className="h-8 w-full relative rounded-4xl bg-primary-400">
        <div
          className={`h-8 absolute left-0 top-0 bg-primary rounded-l-4xl`}
          style={{
            width: `${Math.min(amountSpentPercentage > 0 ? amountSpentPercentage : 0, 100)}%`,
            borderTopRightRadius: `${amountSpentPercentage > 90 ? '32px' : ''}`,
            borderBottomRightRadius: `${amountSpentPercentage > 90 ? '32px' : ''}`,
          }}
        />

        <div className="flex flex-row justify-between items-center h-full w-full absolute top-0 left-0 px-4 z-10">
          <div>
            {amountSpentPercentage > 10 && (
              <div className="text-base text-white tracking-wider">{`${formatNumber({
                amount: amountSpentPercentage,
                decimalPlaces: 0,
              })}%`}</div>
            )}
          </div>
          {amountSpentPercentage < 90 && (
            <div className="text-base text-black text-right tracking-wider">{`${formatNumber({
              amount: 100 - amountSpentPercentage,
              decimalPlaces: 0,
            })}%`}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PercentageBar;

import { Alert } from 'components/atoms/Alert';
import { RoundButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import useCustomAuth from 'hooks/useCustomAuth';
import { Ref, useState } from 'react';
import { useForm } from 'react-hook-form';

type ConfirmSignUpFormProps = {
  username: string;
  enterKeyButtonRef?: Ref<HTMLButtonElement>;
};

const ConfirmSignUpForm = (props: ConfirmSignUpFormProps) => {
  const { username, enterKeyButtonRef } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [resendMsg, setResendMsg] = useState('');
  const { register, handleSubmit } = useForm();

  const { resendConfirmationCode, confirmSignUp } = useCustomAuth();

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      const response = await confirmSignUp(username, data.verificationCode);

      if (response.success) {
        setErrorMessage('');
      } else {
        setErrorMessage(
          response.errorMessage || 'Error during verification, please contact support'
        );
        setIsSubmitting(false);
      }
    } catch (error) {
      setErrorMessage('Unknown error, please contact support');
      setIsSubmitting(false);
    }
  };

  const handleResendCode = async (username: string) => {
    setIsResending(true);
    const response = await resendConfirmationCode(username);
    if (response.success) {
      setResendMsg('Code has been sent');
    } else {
      setErrorMessage('Error resending code to email');
    }
    setIsResending(false);
  };

  return (
    <>
      <h1 className="text-2xl">We emailed you a code</h1>
      <p className="mt-4 text-asphalt text-lg">{`Your code is on the way. To sign in, enter the code we emailed to ${username}. It may take a minute to arrive.`}</p>
      {errorMessage && (
        <div className="mt-4">
          <Alert variation="error">{errorMessage}</Alert>
        </div>
      )}
      {resendMsg && (
        <div className="mt-4">
          <Alert variation="success">{resendMsg}</Alert>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-8">
          <TextInput
            className="mb-2"
            label="Confirmation Code"
            useFormRegister={register('verificationCode')}
            type="password"
            testId="verificationCode"
          />
        </div>
        <div className="mb-4">
          <button
            type="button"
            className={`underline ${isResending ? 'text-feather' : 'text-asphalt'} `}
            onClick={(e) => {
              e.preventDefault();
              handleResendCode(username);
            }}
            disabled={isResending}
          >
            Resend code
          </button>
        </div>
        <div className="flex items-center">
          <div className="mr-2 w-full">
            <RoundButton
              className="w-full"
              variant="primary"
              text="Confirm email"
              disabled={isSubmitting}
              onClick={() => handleSubmit(onSubmit)}
              ref={enterKeyButtonRef}
              type="submit"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ConfirmSignUpForm;

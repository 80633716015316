import { ReactNode } from 'react';
import { useGetCompanyRoles } from './data';
import { getCompanySlugsFromLocalStorage, updateCompaniesToLocalStorage } from 'hooks/access';
import { useActiveCompany } from 'providers/ActiveCompany';

export const RequireActiveCompany = ({ children }: { children: ReactNode }) => {
  const companySlugs = getCompanySlugsFromLocalStorage();
  const { companyRoles, loading } = useGetCompanyRoles({ companySlugs });
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  if (!loading && companySlugs.length === 0 && companyRoles.length > 0) {
    updateCompaniesToLocalStorage(
      companyRoles.map((company) => ({
        customerId: company.customerSlug,
        roles: [company.role] ?? [],
      }))
    );
  }

  if (loading || !activeCompanySlug || companySlugs.length === 0) {
    return null;
  }

  return <>{children}</>;
};

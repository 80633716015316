import { Role } from '__generated__/graphql';
import { fetchAuthSession, decodeJWT } from 'aws-amplify/auth';

export type UserCustomerAccesses = {
  customerId: string;
  roles: string[];
};

type UserCustomerAccess = {
  [key: string]: string;
};

export const getUserId = async () => {
  const info = await fetchAuthSession();
  const idToken = info.tokens?.idToken;
  const jwtValues = decodeJWT(idToken?.toString() ?? '');
  if (jwtValues?.payload?.impersonating) {
    const impersonatingUserAttributes = JSON.parse(jwtValues.payload.impersonating as string);
    return impersonatingUserAttributes.sub;
  }
  return jwtValues.payload.sub;
};

/**
 *
 * @param impersonating Impersonating is a JSON stringify'd object from the JWT
 * @returns
 */
export const getImpersonatingUserEmail = (impersonating: string) => {
  if (impersonating) {
    const impersonatingUserAttributes = JSON.parse(impersonating);
    return impersonatingUserAttributes.attributes.email;
  }
  return null;
};

/**
 *
 * @param teams Teams is a JSON stringify'd object from the JWT
 */
export const setCompaniesToLocalStorage = (teams: string) => {
  if (teams) {
    localStorage.setItem('access', teams);
  }
};

export const updateCompaniesToLocalStorage = (accesses: UserCustomerAccesses[]) => {
  const customerAccess: UserCustomerAccess = {};
  accesses.forEach((access) => {
    if (access.roles.length > 0) {
      customerAccess[access.customerId] = access.roles[0];
    }
  });
  localStorage.setItem('access', JSON.stringify(customerAccess));
};

export const getCompanySlugsFromLocalStorage = () => {
  const access = localStorage.getItem('access');

  return access ? Object.keys(JSON.parse(access)) : [];
};

export const isAdmin = (companySlug: string): boolean => {
  const accessString = localStorage.getItem('access');
  if (!accessString) return false;

  const accessObj = JSON.parse(accessString);
  const role = accessObj[companySlug];
  // legacy, role was stored in array
  if (Array.isArray(role)) {
    return accessObj[companySlug]?.includes('admin') ?? false;
  } else {
    return role === 'admin';
  }
};

export const companyRole = (companySlug: string): Role | null => {
  const accessString = localStorage.getItem('access');
  if (!accessString) return null;

  const accessObj = JSON.parse(accessString);
  const role = accessObj[companySlug];
  // legacy, role was stored in array
  return Array.isArray(role) ? (role[0] as Role) : (role as Role);
};

export const clearAccessFromLocalStorage = () => {
  localStorage.removeItem('access');
};

export const setCustomerTokenToLocalStorage = (token: string) => {
  // 23 hrs from now
  const expirationDate = Date.now() + 23 * 60 * 60 * 1000;
  localStorage.setItem(
    'customerToken',
    JSON.stringify({ token: token || '', expires: expirationDate })
  );
};

export const getCustomerTokenFromLocalStorage = () => {
  const customerToken = localStorage.getItem('customerToken');
  let token: string | null;
  try {
    token = customerToken ? JSON.parse(customerToken)?.token : null;
    return token;
  } catch (e) {
    // Clear token, as it stored in local storage in bad format
    clearCustomerTokenFromLocalStorage();
    return null;
  }
};

export const clearCustomerTokenFromLocalStorage = () => {
  localStorage.removeItem('customerToken');
};

export const checkCustomerTokenExpiration = () => {
  const customerToken = localStorage.getItem('customerToken');
  let expiration: number | null;
  try {
    expiration = customerToken ? JSON.parse(customerToken)?.expires : null;
    if (expiration === null || Date.now() > expiration) {
      clearCustomerTokenFromLocalStorage();
    }
  } catch (e) {
    clearCustomerTokenFromLocalStorage();
  }
};

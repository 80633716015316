import { SpendCategory } from '__generated__/graphql';
import { ReactNode } from 'react';
import { cn, formatCentsToDollarsString } from 'utils';
import { formatSimpleDate } from 'utils/date';
import { AllocateTransactionCategoryDropdown } from './AllocateTransactionCategoryDropdown';
import { useAnimate, usePresence } from 'framer-motion';
import { Check, ChevronRight, Square, SquareCheck, SquareMinus } from 'lucide-react';

interface AllocateTransactionsTableRowProps {
  isSelected?: boolean;
  date: Date;
  merchantName: string;
  spendCategory: SpendCategory;
  amountInCents: number;
  onConfirm: () => void;
  onConfirmDisabled?: boolean;
  selectedProjectId?: string;
  onClickTransaction: () => void;
  onSelect: () => void;
  onSelectProject: (projectId: string) => void;
  onSelectNoCategory: () => void;
  source: string;
}

export const AnimatedAllocateTransactionsTableRow = ({
  onConfirm,
  ...props
}: AllocateTransactionsTableRowProps) => {
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();

  const exitAnimation = async () => {
    await animate(scope.current, { opacity: 0, y: -100, x: 400, scale: 0.8 });
    if (!isPresent) {
      safeToRemove();
    }
  };

  return (
    <div ref={scope}>
      <AllocateTransactionsTableRow
        onConfirm={() => {
          exitAnimation();
          onConfirm();
        }}
        {...props}
      />
    </div>
  );
};

export const AllocateTransactionsTableRow = ({
  isSelected,
  date,
  merchantName,
  spendCategory,
  amountInCents,
  onConfirm,
  onConfirmDisabled,
  selectedProjectId,
  onClickTransaction,
  onSelect,
  onSelectProject,
  onSelectNoCategory,
  source,
}: AllocateTransactionsTableRowProps) => {
  const isIncome = spendCategory === SpendCategory.income;

  return (
    <AllocateTableRowContainer
      className={`${isIncome ? 'bg-success-600' : ''} ${isSelected ? 'bg-tertiary-800' : ''}`}
      onClickTransaction={onClickTransaction}
    >
      <CheckboxColumn checked={isSelected} onClick={onSelect} />
      <DateColumn>{formatSimpleDate(date)}</DateColumn>
      <MerchantNameColumn merchantName={merchantName} date={date} />
      <SourceColumn>{source}</SourceColumn>
      <AmountColumn>{`${isIncome ? '+ ' : ''}${formatCentsToDollarsString(
        isIncome ? Math.abs(amountInCents) : amountInCents
      )}`}</AmountColumn>
      <AssignColumn>
        <AllocateTransactionCategoryDropdown
          selectedProjectId={selectedProjectId}
          onSelectNoCategory={onSelectNoCategory}
          onSelectProject={onSelectProject}
        />
      </AssignColumn>
      <ConfirmColumn disabled={onConfirmDisabled} onClick={onConfirm} />
    </AllocateTableRowContainer>
  );
};

export const AllocateTransactionsTableRowHeaderRow = ({
  onClickSelectAll,
  checked = false,
  className = '',
}: {
  onClickSelectAll: () => void;
  checked?: boolean;
  className?: string;
}) => {
  return (
    <AllocateTableHeaderContainer className={cn('border-primary pb-1 items-baseline', className)}>
      <CheckboxColumn checked={checked} onClick={onClickSelectAll} showMinus />
      <DateColumn className="text-black">Date</DateColumn>
      <MerchantNameHeaderColumn className="text-black">Merchant</MerchantNameHeaderColumn>
      <SourceColumn className="text-black">Source</SourceColumn>
      <AmountHeaderColumn className="text-black">Amount</AmountHeaderColumn>
      <AssignColumn className="text-black font-bold">Assign</AssignColumn>
      <ConfirmColumnContainer className="text-black font-bold">Confirm</ConfirmColumnContainer>
    </AllocateTableHeaderContainer>
  );
};

const hiddenInMobileClassname = 'hidden lg:flex';
const columnBaseClassname = 'flex-row justify-start items-center';

export const CheckboxColumn = ({
  checked = false,
  onClick,
  className = '',
  showMinus,
}: {
  className?: string;
  checked?: boolean;
  onClick: () => void;
  showMinus?: boolean;
}) => {
  return (
    <button
      className={cn('cursor-pointer', hiddenInMobileClassname, className)}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {checked && !showMinus && <SquareCheck size={20} />}
      {checked && showMinus && <SquareMinus size={20} />}
      {!checked && <Square size={20} className="stroke-primary-400" />}
    </button>
  );
};

export const DateColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={cn(
        'text-base text-primary-700',
        hiddenInMobileClassname,
        columnBaseClassname,
        className
      )}
    >
      {children}
    </div>
  );
};

export const MerchantNameHeaderColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return <div className={cn('text-base', columnBaseClassname, className)}>{children}</div>;
};

export const MerchantNameColumn = ({
  className = '',
  merchantName = '',
  date,
}: {
  className?: string;
  merchantName: string;
  date?: Date;
}) => {
  return (
    <div className={cn('text-base', columnBaseClassname, className)}>
      {merchantName}
      {date && <div className="flex lg:hidden text-base font-normal">{formatSimpleDate(date)}</div>}
    </div>
  );
};

export const SourceColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={cn(
        hiddenInMobileClassname,
        'lg:block text-base justify-center truncate',
        className
      )}
    >
      {children}
    </div>
  );
};

export const AmountHeaderColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={cn('text-base text-primary-700 mr-5 lg:mr-0', columnBaseClassname, className)}>
      {children}
    </div>
  );
};

export const AmountColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <>
      <div
        className={cn(
          'font-medium text-primary-700',
          columnBaseClassname,
          'hidden lg:flex',
          className
        )}
      >
        {children}
      </div>

      <button
        className={cn(
          'text-base text-primary-700',
          columnBaseClassname,
          'flex flex-row gap-2 lg:hidden',
          className
        )}
      >
        {children}
        <ChevronRight className="lg:hidden" />
      </button>
    </>
  );
};

export const AssignColumn = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={cn('text-base', hiddenInMobileClassname, columnBaseClassname, className)}>
      {children}
    </div>
  );
};

export const ConfirmColumnContainer = ({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={cn(
        'text-base',
        hiddenInMobileClassname,
        columnBaseClassname,
        className,
        'justify-center'
      )}
    >
      {children}
    </div>
  );
};

export const ConfirmColumn = ({
  className = '',
  onClick,
  disabled,
}: {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <ConfirmColumnContainer className={className}>
      <button
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        className={cn(
          'w-10 h-10 rounded-full border border-solid flex items-center justify-center',
          'border-accent-500 bg-accent-200',
          'disabled:border-primary-300 disabled:bg-primary-200'
        )}
      >
        <Check size={17} />
      </button>
    </ConfirmColumnContainer>
  );
};

export const AllocateTableRowContainer = ({
  children,
  className = '',
  onClickTransaction,
}: {
  children: ReactNode;
  className?: string;
  onClickTransaction?: () => void;
}) => {
  return (
    <div
      className={cn(
        'grid grid-cols-[1fr_auto] px-2 py-5 gap-3',
        'lg:grid-cols-[auto_5rem_1fr_8rem_3.25em_18.5rem_3.25em] lg:gap-6',
        'xl:grid-cols-[auto_5rem_1fr_8rem_8em_18.5rem_3.25em] lg:gap-6',
        'border-b border-primary-400',
        'items-center',
        'cursor-pointer hover:bg-accent-200',
        className
      )}
      onClick={() => {
        onClickTransaction?.();
      }}
    >
      {children}
    </div>
  );
};

export const AllocateTableHeaderContainer = ({
  children,
  className = '',
  onClickTransaction,
}: {
  children: ReactNode;
  className?: string;
  onClickTransaction?: () => void;
}) => {
  return (
    <div
      className={cn(
        'grid grid-cols-[1fr_auto] px-2 py-5 gap-3',
        'lg:grid-cols-[auto_5rem_1fr_8rem_3.25rem_18.5em_3.25em] lg:gap-6',
        'xl:grid-cols-[auto_5rem_1fr_8rem_8rem_18.5em_3.25em] lg:gap-6',
        'border-b border-primary-400',
        'items-center',
        className
      )}
      onClick={() => {
        onClickTransaction?.();
      }}
    >
      {children}
    </div>
  );
};

import useCustomAuth from 'hooks/useCustomAuth';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import zenaLogo from '../../images/zenaLogo.webp';
import { Navbar } from './Navbar';
import { UserMenu } from 'components/atoms/UserMenu';
import { Button } from 'components/atoms/Buttons';
import { RequireActiveCompany } from 'providers/RequireActiveCompany';
import { Menu, X } from 'lucide-react';

export const Header = () => {
  const { user, signOut } = useCustomAuth();
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  const signOutAndRemoveAccess = () => {
    signOut();
  };

  const handleClick = () => {
    setShowSidebar(!showSidebar);
  };

  const onClose = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  return (
    <header className="bg-secondary-300 h-[76px] pl-2 sm:pl-8 m-0 flex items-center">
      <div className="md:hidden mr-4 md:mr-0 flex items-center">
        <Button
          className="p-3"
          variant="ghost"
          aria-label="Toggle dropdown menu"
          onClick={handleClick}
        >
          <Menu />
        </Button>
      </div>
      {showSidebar && (
        <div className="z-10 left-0 top-[76px] fixed w-full bg-secondary-300">
          <div className="flex flex-row-reverse">
            <Button onClick={onClose} aria-label="Close dropdown menu" variant="ghost">
              <span className="w-4 h-4">
                <X />
              </span>
            </Button>
          </div>
          <Navbar />
        </div>
      )}
      <div className="">
        <Link className="no-underline w-fit" to="/">
          <img className="h-6" src={zenaLogo} alt="Zena Logo" />
        </Link>
      </div>
      {user && (
        <div className="flex-1 text-right pr-5">
          <RequireActiveCompany>
            <UserMenu onSignOut={signOutAndRemoveAccess} />
          </RequireActiveCompany>
        </div>
      )}
    </header>
  );
};

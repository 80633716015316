import { useActiveCompany } from 'providers/ActiveCompany';
import { useCardDetails } from './data';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { cn, useCopyContents } from 'utils';
import voca from 'voca';
import { ReactNode } from 'react';
import { Button } from 'components/atoms/Buttons/Button';
import { Copy, X } from 'lucide-react';
import { ZenaCard } from 'components/atoms/ZenaCard';
import { Card } from '__generated__/graphql';
import { Tooltip } from 'react-tooltip';
import { companyRole } from 'hooks/access';
import { RoundButton } from 'components/atoms/Buttons/RoundButton';
import { ActionMenu } from 'components/atoms/ZenaCard/ActionMenu';

export interface CardDetailsProps {
  onClose: () => void;
  cardId: string;
  cardData?: Card;
  className?: string;
}

interface ButtonProps {
  status: string;
  disabled: boolean;
}

export const CardDetails = ({ onClose, cardId, className = '' }: CardDetailsProps) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { copyContents: copyBillingAddress, contentsRef: billingAddressRef } = useCopyContents();
  const billingTooltipId = `copy-billing-address-tooltip-${cardId}`;

  const { card, loading } = useCardDetails({
    cardId: cardId,
    companySlug: activeCompany?.slug || '',
  });

  const cardType = card?.type?.includes('Virtual') ? 'Virtual Card' : 'Physical Card';
  const role = companyRole(activeCompanySlug);
  const showCardOnly = role === 'bookkeeper';

  const consolidatedCardData = card;
  if (!consolidatedCardData) {
    return (
      <div className={cn('flex justify-center', className)}>
        <LoadingSpinner />
      </div>
    );
  }

  let billingAddressString1 = '';
  let billingAddressString2 = '';
  if (card?.billingAddress) {
    const { street, street2, city, state, postalCode } = card.billingAddress;
    billingAddressString1 = street + (street2 ? ` ${street2}` : '');
    billingAddressString2 = `${city}, ${state}, ${postalCode}`;
  }

  if (loading) {
    return (
      <div className={cn('flex justify-center', className)}>
        <LoadingSpinner />
      </div>
    );
  }

  const statusMap: { [key: string]: { status: string; disabled: boolean } } = {
    Active: { status: 'active', disabled: false },
    Inactive: { status: 'inactive', disabled: true },
    Stolen: { status: 'closed', disabled: true },
    Lost: { status: 'closed', disabled: true },
    Frozen: { status: 'frozen', disabled: false },
    ClosedByCustomer: { status: 'closed', disabled: true },
    SuspectedFraud: { status: 'closed', disabled: true },
  };
  const cardStatus = card.status || 'Closed';

  const props = {
    status: statusMap[cardStatus].status,
    disabled: statusMap[cardStatus]?.disabled,
  } satisfies ButtonProps;

  return (
    <div className={cn('grid gap-5', className)}>
      <Container>
        <TitleContainer>
          <TitleRow>
            <h2 className="text-3xl truncate">
              {cardType} {card?.last4Digits}
            </h2>
            <CloseButton onClick={onClose} />
          </TitleRow>
        </TitleContainer>
        <AmountRow>
          <div>Card Status</div>
          <CardStatus companySlug={activeCompanySlug} card={consolidatedCardData} props={props} />
        </AmountRow>
        <DetailRow>
          <div>Card Holder</div>
          <div>
            {card?.cardholderFullName?.first} {card?.cardholderFullName?.last}
          </div>
        </DetailRow>
        <DetailRow>
          <div>Card type</div>
          <div>{cardType}</div>
        </DetailRow>
        <DetailRow>
          <div>Card name</div>
          <div>{card.name}</div>
        </DetailRow>
        <ZenaCard
          cardId={cardId}
          hideCardName
          showCardOnly={showCardOnly}
          hiddenCardActions={true}
        />
        <h4 className="text-sm mb-2">Billing Address</h4>
        <div className="inline-block">
          <span className="text-base font-semibold" ref={billingAddressRef}>
            {billingAddressString1}
            <br />
            {billingAddressString2}
          </span>
          <button
            className="float-right rounded-[50%] h-10 w-10 p-2.5 bg-ivory m-auto mb-4 transition ease-in-out active:bg-lavender active:delay-0 active:duration-0 delay-1000 duration-1000"
            onClick={copyBillingAddress}
            data-tooltip-id={billingTooltipId}
          >
            <Tooltip id={billingTooltipId} openOnClick>
              Copied Billing Address
            </Tooltip>
            <span
              style={{
                filter:
                  'invert(86%) sepia(15%) saturate(143%) hue-rotate(351deg) brightness(94%) contrast(89%)',
              }}
            >
              <Copy size={18} />
            </span>
          </button>
        </div>
      </Container>
    </div>
  );
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 max-w-full min-w-0">{children}</div>;
};

const TitleContainer = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-2 border-b border-primary pb-4 min-w-0">{children}</div>;
};

const TitleRow = ({ children }: { children: ReactNode }) => {
  return <div className="flex justify-between items-start gap-4 min-w-0">{children}</div>;
};

const AmountRow = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-between py-4 font-semibold gap-4 border-b border-primary-400 tracking-wide items-center">
      {children}
    </div>
  );
};

const DetailRow = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-between py-4 gap-4 border-b border-primary-400 tracking-wide items-center">
      {children}
    </div>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="ghost" className="p-0" onClick={onClick}>
      <X />
    </Button>
  );
};

const StatusIndicator = ({ status }: { status: string }) => {
  const statusColor: Record<string, string> = {
    active: 'bg-green-500',
    frozen: 'bg-yellow-500',
    inactive: 'bg-yellow-500',
    closed: 'bg-red-500',
  };
  return <div className={cn('rounded-full size-2 bg-green-500', statusColor[status])} />;
};

const CardStatus = ({
  companySlug,
  card,
  props,
}: {
  companySlug: string;
  card: Card;
  props: ButtonProps;
}) => {
  return (
    <div>
      <ActionMenu
        cardId={card.id}
        cardData={card}
        customerId={companySlug}
        alwaysShowActionButton={true}
      >
        <RoundButton
          type="button"
          variant="tertiary"
          icon="chevronDown"
          disabled={props.disabled}
          isIconLeft={false}
        >
          <StatusIndicator status={props.status} />
          {voca.capitalize(props.status)}
        </RoundButton>
      </ActionMenu>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const QUERY_GET_CUSTOMER_CARDS = gql(`
  query GetCustomerCards($companySlug: String!, $corporateOnly: Boolean = false) {
    getCards(companySlug: $companySlug, corporateOnly: $corporateOnly) {
      data {
        id
        name
        type
        status
        last4Digits
        cardholderFullName {
          first
          last
        }
        expirationDate
        shippingAddress {
          street
          street2
          city
          state
          postalCode
          country
        }
      }
    }
  }
`);

export const useGetCards = ({ customerId }: { customerId: string }) => {
  // Note: This query has side effects defined in CreateCardWorkflow
  const { data, loading, error, refetch } = useQuery(QUERY_GET_CUSTOMER_CARDS, {
    variables: {
      companySlug: customerId,
      corporateOnly: true,
    },
  });

  return {
    cards: data?.getCards?.data ?? [],
    loading,
    error,
    refetch,
  };
};

export const QUERY_GET_COMPANY_STATUS = gql(`
query getCompanyStatus {
  getMe {
    id
    defaultCompany {
      id
      isFrozen
    }
  }
}  
`);

export const useGetCompanyStatus = () => {
  const { data, loading, error } = useQuery(QUERY_GET_COMPANY_STATUS);

  return {
    company: data?.getMe?.defaultCompany,
    loading,
    error,
  };
};

import { Cell, Pie, PieChart, ResponsiveContainer, Sector, SectorProps } from 'recharts';
import { useGetTransactionCount } from './data';
import { useEffect, useState } from 'react';
import { useActiveCompany } from 'providers/ActiveCompany';

export const DonutChart = ({ onlyZenaTransactions = false }: { onlyZenaTransactions: boolean }) => {
  const [initialCount, setInitialCount] = useState(0);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { transactionCount } = useGetTransactionCount({
    customerId: activeCompanySlug,
    onlyZenaTransaction: onlyZenaTransactions,
  });

  const start = Math.floor(((transactionCount ?? 0) / initialCount) * 100);
  const current = Math.floor(((initialCount - (transactionCount ?? 0)) / initialCount) * 100);

  const data = [
    {
      name: 'Complete transactions',
      // Used for pie chart - Always have at least 5 percent showing
      value: current > 5 ? current : 5,
      // Used for copy
      textValue: transactionCount,
    },
    { name: 'Total transactions', value: start },
  ];

  useEffect(() => {
    if (transactionCount && !initialCount) {
      setInitialCount(transactionCount);
    }
  }, [transactionCount, initialCount]);

  return (
    <ResponsiveContainer width="100%" className="min-h-48">
      <PieChart>
        <Pie
          data={data}
          activeIndex={0}
          innerRadius={80}
          outerRadius={95}
          fill="#D3E3D9"
          paddingAngle={0}
          dataKey="value"
          activeShape={renderActiveShape}
          startAngle={0}
          endAngle={-450}
        >
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill="#D3E3D9" />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const getConfig = (transactionCount: number): [text: string, fill: string] => {
  if (transactionCount === 0) {
    return ['All caught up!', '#89B498'];
  } else if (transactionCount > 0 && transactionCount < 11) {
    return ['Almost there!', '#89B498'];
  } else {
    return ['You can do it!', '#54639F'];
  }
};

const renderActiveShape = (props: SectorProps) => {
  // @ts-ignore
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, textValue } = props;
  const [text, fill] = getConfig(textValue);

  return (
    <g>
      <text x={cx} y={(cy ?? 0) - 20} textAnchor="middle" fill={fill} className="text-sm">
        {text}
      </text>
      <text x={cx} y={(cy ?? 0) + 10} dy={8} textAnchor="middle" fill={fill} className="text-4xl">
        {textValue === 0 ? '100%' : textValue}
      </text>
      <text x={cx} y={(cy ?? 0) + 32} dy={8} textAnchor="middle" fill={fill} className="text-lg">
        {textValue === 0 ? 'done' : 'Left'}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

import { motion } from 'framer-motion';
import { HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { cn } from 'utils';
import { InputDropdown } from '../InputDropdown';
import { Button } from '../Buttons';
import { ChevronLeft } from 'lucide-react';

export const Pagination = ({
  pageLimit,
  onChangePageLimit,
  totalPages,
  currentPage,
  onPreviousPage,
  onNextPage,
}: {
  pageLimit: PageLimit;
  onChangePageLimit: (newPageLimit: PageLimit) => void;
  totalPages: number;
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}) => {
  return (
    <PaginationContainer>
      <CountPerPage pageLimit={pageLimit} onChangePageLimit={onChangePageLimit} />
      <PageOfPages
        totalPages={totalPages}
        currentPage={currentPage}
        onPreviousPage={onPreviousPage}
        onNextPage={onNextPage}
      />
    </PaginationContainer>
  );
};

export const PaginationContainer = ({
  className,
  children,
  ...props
}: {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'grid w-max h-10 grid-cols-[auto_auto] rounded-5xl border border-solid border-primary-400',
        '[&>:not(:first-child)]:border-l [&>:not(:first-child)]:border-primary-400',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const PaginationContentContainer = ({
  className,
  children,
  ...props
}: {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn(
        'px-4 flex flex-row items-center justify-center gap-3 h-full text-sm text-black',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const pageLimitList = [30, 50, 100] as const;
export type PageLimit = (typeof pageLimitList)[number];

export const CountPerPage = ({
  pageLimit,
  onChangePageLimit,
}: {
  pageLimit: PageLimit;
  onChangePageLimit: (newPageLimit: PageLimit) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputList = useMemo(() => {
    return pageLimitList.map((pl) => ({ id: String(pl), value: pl }));
  }, []);

  return (
    <PaginationContentContainer className="relative">
      <button
        className="flex flex-row items-center justify-center gap-3 h-full text-sm text-black outline-accent-600 rounded-xl"
        onClick={() => setIsOpen(true)}
      >
        <div className="whitespace-nowrap">{`${pageLimit} per page`}</div>
        <motion.div
          animate={{
            rotate: isOpen ? 90 : -90,
          }}
        >
          <ChevronLeft />
        </motion.div>
      </button>

      {isOpen && (
        <InputDropdown
          list={inputList}
          onClose={() => setIsOpen(false)}
          onSelectItem={(item) => {
            onChangePageLimit(Number(item) as PageLimit);
          }}
        />
      )}
    </PaginationContentContainer>
  );
};

export const PageOfPages = ({
  totalPages,
  currentPage,
  onPreviousPage,
  onNextPage,
}: {
  totalPages: number;
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}) => {
  return (
    <PaginationContentContainer>
      <Button
        onClick={onPreviousPage}
        disabled={currentPage <= 1}
        variant="ghost"
        className="px-0 bg-transparent border-none"
        overrideDisabledStyled
      >
        <ChevronLeft />
      </Button>
      <div className="whitespace-nowrap">{`${currentPage} of ${totalPages}`}</div>
      <Button
        onClick={onNextPage}
        disabled={currentPage === totalPages}
        variant="ghost"
        className="px-0"
        overrideDisabledStyled
      >
        <ChevronLeft className="rotate-180 mt-[-2px]" />
      </Button>
    </PaginationContentContainer>
  );
};

import { ReactNode } from 'react';
import { useActiveCompany } from 'providers/ActiveCompany';
import { companyRole } from 'hooks/access';
import { Link } from 'react-router-dom';
import { RoundButton } from 'components/atoms/Buttons';
import { TabLink, TabsContainer } from 'components/atoms/Tab';
import { cn } from '../../utils';
import { useCompanyTier, useHasPlaidConnection, useGetCards } from './data';

import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { UpsellZena } from 'pages/(dashboard)/MarketingDashboard/UpsellZena';

export const CardsPageLayout = ({
  children,
  title,
  className = '',
  currentTab,
}: {
  children: ReactNode;
  title: string;
  className?: string;
  currentTab: 'all' | 'approvals';
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { companyTier, loading } = useCompanyTier({ companySlug: activeCompanySlug });
  const { hasPlaidConnection: hasSyncedAccounts, loading: syncedAccountsLoading } =
    useHasPlaidConnection({ companySlug: activeCompanySlug });
  const { loading: hasCardsLoading, hasZenaCards } = useGetCards({
    companySlug: activeCompanySlug,
  });
  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';
  const isAdmin = companyRole(activeCompanySlug) === 'admin';
  const showPageContent =
    companyTier === 'luxe' ||
    (hasSyncedAccounts && hasZenaCards) ||
    (hasSyncedAccounts && !hasZenaCards && currentTab === 'all');

  return (
    <div className="pt-7 px-4 lg:pt-11 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
      <section className="flex justify-between gap-8 align-baseline">
        <h1 className="text-xl lg:text-4xl">{title}</h1>
        {!isBookkeeper && (
          <Link
            to="/cards-management/new"
            onClick={() => {
              analytics.track('User clicks on the Create Card button');
            }}
          >
            <RoundButton text="Create new card" icon="plus" />{' '}
          </Link>
        )}
      </section>
      <section className="flex flex-col grow overflow-hidden">
        <section className="flex flex-col grow overflow-hidden">
          <TabsContainer>
            <TabLink isActive={currentTab === 'all'} href={`/cards-management`}>
              All
            </TabLink>
            <TabLink
              isActive={currentTab === 'approvals'}
              href={`/cards-management/approvals`}
              disabled={!isAdmin}
            >
              Approvals
            </TabLink>
          </TabsContainer>
          <section className={cn('grow overflow-y-auto', className)}>
            {loading && (
              <div className="w-full flex justify-center mt-8">
                <LoadingSpinner />
              </div>
            )}

            {!loading && !syncedAccountsLoading && !hasCardsLoading && (
              <>
                {showPageContent && <>{children}</>}
                {!showPageContent && <UpsellZena className="bg-white" />}
              </>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};

import { useGetCompanyTitle } from './data';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { ReactNode } from 'react';
import { useActiveCompany } from 'providers/ActiveCompany';

export const DashboardTitle = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { companyTitle, loading } = useGetCompanyTitle({ companySlug: activeCompanySlug });

  if (loading) {
    return (
      <Container>
        <RectangleSkeleton height="large" rounded width="medium" />
      </Container>
    );
  }

  if (companyTitle) {
    return <Container>{companyTitle}</Container>;
  }

  return <Container>My Company</Container>;
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className="bg-forest text-white p-8 text-3xl">{children}</div>
);

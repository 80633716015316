import { cn } from '../../../utils';

const statusText: Record<string, string> = {
  active: 'Active',
  // hold: 'Hold',
  completed: 'Completed',
  // canceled: 'Canceled',
  invalid: 'Invalid',
  inactive: 'Inactive',
};

const statusColor: Record<string, string> = {
  active: 'bg-green-500',
  // hold: 'bg-yellow-500',
  completed: 'bg-blue-500',
  // canceled: 'bg-red-500',
  invalid: 'bg-orange-500',
  inactive: 'bg-primary-500',
};

type StatusIndicatorProps = {
  status: 'active' | 'completed' | 'inactive' | 'hold' | 'invalid';
  className?: string;
  text?: string;
};

export const StatusIndicator = ({ status, className, text }: StatusIndicatorProps) => {
  return (
    <div className={cn('flex gap-2 items-center py-1 px-2 bg-white rounded-full', className)}>
      <div className={cn('rounded-full w-4 h-4', statusColor[status])}></div>
      <div>{text ?? statusText[status]}</div>
    </div>
  );
};

import { Maybe, TransactionMetadataInput } from '__generated__/graphql';
import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface TransactionDetailsState {
  transactionId: string;
  memo: string;
  hasChangesToSave: boolean;
  accountingTag: AccountingTag | null;
  rooms: string[];
  categories: string[];
  invoiceNumber: string;
  splits: Split[];
  projectId: string;
  splitMethod: SplitMethod;
  showErrors: boolean;
  receiptImageUrl: string | undefined;
  isEditingSplits: boolean;
  missingFields?: MissingFields;
}

type SplitMethod = 'AMOUNT' | 'PERCENT';

type Split = Omit<
  TransactionDetailsState,
  | 'splits'
  | 'splitMethod'
  | 'hasChangesToSave'
  | 'memo'
  | 'showErrors'
  | 'receiptImageUrl'
  | 'isEditingSplits'
> & {
  amount: number;
};

export const defaultTransactionDetailsState: TransactionDetailsState = {
  transactionId: '',
  memo: '',
  hasChangesToSave: false,
  accountingTag: null,
  rooms: [],
  categories: [],
  invoiceNumber: '',
  splits: [],
  splitMethod: 'AMOUNT',
  projectId: '',
  showErrors: false,
  receiptImageUrl: '',
  isEditingSplits: false,
};

export const defaultSplit: Split = {
  transactionId: '',
  accountingTag: null,
  rooms: [],
  categories: [],
  invoiceNumber: '',
  amount: 0,
  projectId: '',
};

// ACTIONS
interface ClearState {
  type: 'CLEAR_TRANSACTION_DETAILS_STATE';
}

interface UpdateTransactionId {
  type: 'UPDATE_TRANSACTION_ID';
  payload: string;
}

interface UpdateMemo {
  type: 'UPDATE_MEMO';
  payload: string;
}

interface PopulateMemo {
  type: 'POPULATE_MEMO';
  payload: string;
}

interface SaveAllChanges {
  type: 'SAVE_ALL_CHANGES';
}

interface AccountingTag {
  id: string;
  name: string;
}

interface MissingFields {
  memo: boolean;
  receipt: boolean;
  roomTag: boolean;
  categoryTag: boolean;
  accountingTag: boolean;
}

interface UpdateAccountingTag {
  type: 'UPDATE_ACCOUNTING_TAG';
  payload: AccountingTag | null;
}

interface UpdateSplit {
  type: 'UPDATE_SPLIT';
  payload: Split;
}

interface AddSplit {
  type: 'ADD_SPLIT';
}

interface RemoveSplit {
  type: 'REMOVE_SPLIT';
  payload: {
    splitId: string;
  };
}

interface UpdateSplitMethod {
  type: 'UPDATE_SPLIT_METHOD';
  payload: SplitMethod;
}

interface UpdateProject {
  type: 'UPDATE_PROJECT';
  payload: string;
}

interface UpdateInvoiceNumber {
  type: 'UPDATE_INVOICE_NUMBER';
  payload: string;
}

interface UpdateProjectRooms {
  type: 'UPDATE_PROJECT_ROOMS';
  payload: string[];
}

interface UpdateProjectCategories {
  type: 'UPDATE_PROJECT_CATEGORIES';
  payload: string[];
}
interface UpdateEditingSplits {
  type: 'UPDATE_EDITING_SPLITS';
  payload: boolean;
}
interface UpdateReceiptImageUrl {
  type: 'UPDATE_RECEIPT_IMAGE_URL';
  payload: string;
}

interface Submit {
  type: 'SUBMIT';
}

interface ClearTempReceiptUrl {
  type: 'CLEAR_TEMP_RECEIPT_URL';
}

interface PopulateTagsAndSplits {
  type: 'POPULATE_TAGS_AND_SPLITS';
  payload: {
    rooms: string[];
    categories: string[];
    invoiceNumber: string;
    accountingTag: AccountingTag | null;
    projectId: string;
    missingFields?: MissingFields;
    splits?: Split[];
  };
}

type TransactionDetailsAction =
  | ClearState
  | UpdateMemo
  | SaveAllChanges
  | UpdateTransactionId
  | UpdateAccountingTag
  | PopulateTagsAndSplits
  | UpdateSplit
  | AddSplit
  | RemoveSplit
  | UpdateSplitMethod
  | UpdateProject
  | UpdateInvoiceNumber
  | UpdateProjectRooms
  | UpdateProjectCategories
  | UpdateEditingSplits
  | UpdateReceiptImageUrl
  | Submit
  | PopulateMemo
  | ClearTempReceiptUrl;

const TransactionDetailsReducer = (
  prevState: TransactionDetailsState,
  action: TransactionDetailsAction
): TransactionDetailsState => {
  switch (action.type) {
    case 'CLEAR_TRANSACTION_DETAILS_STATE':
      return { ...defaultTransactionDetailsState };
    case 'UPDATE_MEMO':
      return { ...prevState, memo: action.payload, hasChangesToSave: true, showErrors: false };
    case 'POPULATE_MEMO':
      return { ...prevState, memo: action.payload };
    case 'SAVE_ALL_CHANGES':
      return {
        ...prevState,
        hasChangesToSave: false,
        splitMethod: 'AMOUNT',
        showErrors: false,
        isEditingSplits: false,
      };
    case 'UPDATE_TRANSACTION_ID':
      return { ...defaultTransactionDetailsState, transactionId: action.payload };
    case 'UPDATE_ACCOUNTING_TAG':
      return {
        ...prevState,
        accountingTag: action.payload,
        hasChangesToSave: true,
        showErrors: false,
      };
    case 'POPULATE_TAGS_AND_SPLITS':
      return {
        ...prevState,
        accountingTag: action.payload.accountingTag,
        rooms: action.payload.rooms,
        categories: action.payload.categories,
        invoiceNumber: action.payload.invoiceNumber,
        projectId: action.payload.projectId,
        missingFields: action.payload.missingFields,
        splits: action.payload.splits ?? [],
      };
    case 'ADD_SPLIT':
      return {
        ...prevState,
        splits: [{ ...defaultSplit, transactionId: uuidv4() }, ...prevState.splits],
        hasChangesToSave: true,
        showErrors: false,
      };
    case 'REMOVE_SPLIT':
      return {
        ...prevState,
        splits: prevState.splits.filter((split) => split.transactionId !== action.payload.splitId),
        hasChangesToSave: true,
        showErrors: false,
      };
    case 'UPDATE_SPLIT':
      return {
        ...prevState,
        hasChangesToSave: true,
        showErrors: false,
        splits: prevState.splits.map((split) => {
          if (split.transactionId === action.payload.transactionId) {
            return { ...action.payload };
          }

          return split;
        }),
      };
    case 'UPDATE_INVOICE_NUMBER':
      return {
        ...prevState,
        invoiceNumber: action.payload,
        hasChangesToSave: true,
        showErrors: false,
      };
    case 'UPDATE_EDITING_SPLITS':
      return {
        ...prevState,
        isEditingSplits: action.payload,
      };
    case 'UPDATE_PROJECT_CATEGORIES':
      return {
        ...prevState,
        categories: action.payload,
        hasChangesToSave: true,
        showErrors: false,
      };
    case 'UPDATE_PROJECT_ROOMS':
      return { ...prevState, rooms: action.payload, hasChangesToSave: true, showErrors: false };
    case 'UPDATE_PROJECT':
      return {
        ...prevState,
        projectId: action.payload,
        hasChangesToSave: true,
        showErrors: false,
        rooms: [],
        categories: [],
      };
    case 'UPDATE_SPLIT_METHOD':
      return {
        ...prevState,
        splitMethod: action.payload,
        splits: prevState.splits.map((split) => ({ ...split, amount: 0 })),
        showErrors: false,
      };
    case 'UPDATE_RECEIPT_IMAGE_URL':
      return { ...prevState, receiptImageUrl: action.payload, hasChangesToSave: true };
    case 'CLEAR_TEMP_RECEIPT_URL':
      return { ...prevState, receiptImageUrl: undefined };
    case 'SUBMIT':
      return { ...prevState, showErrors: true };
    default:
      return { ...prevState };
  }
};

const useTransactionDetailsReducer = (defaults?: TransactionDetailsState) => {
  return useReducer(TransactionDetailsReducer, {
    ...defaultTransactionDetailsState,
    ...defaults,
  });
};

// CONTEXT / PROVIDER

const TransactionDetailsContext = createContext<{
  state: TransactionDetailsState;
  dispatch: Dispatch<TransactionDetailsAction>;
}>({
  state: {
    ...defaultTransactionDetailsState,
  },
  dispatch: () => {
    // no op
  },
});

export const TransactionDetailsStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useTransactionDetailsReducer();

  return (
    <TransactionDetailsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TransactionDetailsContext.Provider>
  );
};

export const useTransactionDetailsContext = () => {
  return useContext(TransactionDetailsContext);
};

export const selectTransactionDetailsTransactionMetadata = (state: {
  memo?: string;
  rooms: string[];
  categories: string[];
  invoiceNumber?: string;
  projectId: string;
  receiptImageUrl?: string;
  accountingTag: AccountingTag | null;
}): TransactionMetadataInput => {
  return {
    projectId: state.projectId,
    memo: state.memo,
    rooms: state.rooms,
    categories: state.categories,
    poCode: state.invoiceNumber,
    accountingTagId: state.accountingTag?.id || undefined,
    accountingTagName: state.accountingTag?.name || undefined,
    receiptImageUrl: state.receiptImageUrl || undefined,
  };
};

// VALIDATION
interface Errors {
  splits: Maybe<string>;
  formSubmission: Maybe<string>;
  isValid: boolean;
}

export const validateTransactionDetailsState = ({
  state,
  transactionTotalAmountInCents,
}: {
  state: TransactionDetailsState;
  transactionTotalAmountInCents: number;
}) => {
  let errors: Errors = {
    splits: null,
    isValid: true,
    formSubmission: null,
  };

  if (state.splits.length > 0) {
    const total = state.splits.reduce((sum, split) => {
      sum = sum + split.amount;
      return sum;
    }, 0);
    if (state.splits.find((split) => split.amount === 0)) {
      errors.splits = 'Update split amount';
      errors.formSubmission = `Split amounts cannot be ${
        state.splitMethod === 'AMOUNT' ? '$0' : '0%'
      }`;
      errors.isValid = false;
    } else if (state.splitMethod === 'AMOUNT' && total !== transactionTotalAmountInCents) {
      errors.splits = 'Update split amount';
      errors.formSubmission = 'Split amounts must equal full transaction amount';
      errors.isValid = false;
    } else if (state.splitMethod === 'PERCENT' && total !== 100) {
      errors.splits = 'Update split amount';
      errors.formSubmission = 'Split amounts must equal full transaction amount';
      errors.isValid = false;
    }
  }

  return errors;
};

import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { cn, useCopyContents } from 'utils';
import { ProfilePhotoImage } from '../ProfilePhotoImage';
import { UPDATE_USER_DEFAULT_MUTATION, useGetCompaniesForUserMenu, useGetMe } from './data';
import { useMutation } from '@apollo/client';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ChevronDown, ChevronRight, LogOut, Store } from 'lucide-react';

type UserMenuProps = {
  onSignOut: () => void;
};

const UserMenu = (props: UserMenuProps) => {
  const { onSignOut } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCompanyMenu, setShowCompanyMenu] = useState(false);
  const [firstName, setFirstName] = useState('');
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const { user: userGql, loading: userLoading } = useGetMe({
    activeCompanySlug,
  });
  const [updateUserCompanySlug] = useMutation(UPDATE_USER_DEFAULT_MUTATION);

  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  const { companies, loading } = useGetCompaniesForUserMenu();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    setFirstName(userGql?.firstName ?? '');
  }, [userGql]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleCompanyMenu = () => {
    setShowCompanyMenu(!showCompanyMenu);
  };

  const onChangeCompany = (newActiveCompanySlug: string) => {
    updateUserCompanySlug({
      variables: {
        companySlug: newActiveCompanySlug,
      },
      onCompleted: () => {
        navigate('/');
      },
    });
  };

  const { copyContents, contentsRef } = useCopyContents();
  const tooltipId = `copy-text-tooltip-customer-id`;

  if (loading) return <></>;

  return (
    <div className="h-full flex items-center justify-end">
      <div className="text-sm relative" ref={menuRef}>
        <button
          onClick={toggleDropdown}
          className="cursor-pointer flex items-center justify-center hover:bg-secondary-600 rounded-3xl p-1"
        >
          {!userLoading && (
            <ProfilePhotoImage signedProfilePhotoUrl={userGql?.smallImageUrl ?? null} size={10} />
          )}
          <p className="text-base pl-2 pr-1 float-right text-black normal-case">{firstName}</p>
          <ChevronDown />
        </button>

        {showDropdown && (
          <div
            className={
              'flex flex-col items-start rounded-md bg-white shadow-normal absolute top-14 z-10 right-0 py-2 ' +
              (companies.length > 1 ? 'w-52' : 'w-48')
            }
          >
            <DropdownButton
              onClick={() => {
                copyContents();
              }}
              data-tooltip-id={tooltipId}
            >
              <span className="pr-2">
                Company ID: <span ref={contentsRef}>{activeCompanySlug}</span>
              </span>
              <Tooltip id={tooltipId} openOnClick>
                Copied company ID
              </Tooltip>
            </DropdownButton>
            {companies.length > 1 && (
              <button
                onClick={toggleCompanyMenu}
                className={`my-0.5 hover:bg-tertiary-800 py-2 px-3 w-full flex items-center gap-4`}
              >
                <Store />
                <span className="pr-2">Switch company</span>
                <span className="p-1 pl-2">
                  {showCompanyMenu ? <ChevronDown /> : <ChevronRight />}
                </span>
              </button>
            )}
            {showCompanyMenu && (
              <>
                {companies.map((company) => (
                  <div
                    className={`hover:bg-tertiary-800 py-2 px-3 w-full cursor-pointer flex items-center gap-4`}
                    onClick={() => {
                      onChangeCompany(company.slug);
                    }}
                    key={company.id}
                  >
                    <span>{company.name}</span>
                    <span className=" p-1 pl-2">
                      <ChevronRight />
                    </span>
                  </div>
                ))}
              </>
            )}
            <DropdownButton
              onClick={() => {
                navigate('/manage-profile');
              }}
            >
              Manage profile
            </DropdownButton>
            <div className="flex justify-center w-full px-3 py-1">
              <div className={`bg-primary-400 h-[1px] w-full`} />
            </div>
            <DropdownButton
              onClick={onSignOut}
              className="hover:bg-tertiary-800 py-2 px-3 w-full flex items-center gap-4"
            >
              <LogOut />
              <span className="pr-2">Sign out</span>
            </DropdownButton>
          </div>
        )}
      </div>
    </div>
  );
};

const DropdownButton = ({
  className,
  ...props
}: { className?: string } & HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn(
        'hover:bg-tertiary-800 py-2 px-3 w-full flex rounded items-center gap-4',
        className
      )}
      {...props}
    />
  );
};

export default UserMenu;

import { useApolloClient } from '@apollo/client';
import { HubCallback } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';
import { removeAccess } from 'hooks';
import {
  getCompanySlugsFromLocalStorage,
  getImpersonatingUserEmail,
  getUserId,
  setCompaniesToLocalStorage,
} from 'hooks/access';
import { clearApolloCache } from 'hooks/apollo';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalUserStore, useLocalStore } from 'stores/UserStore';
import {
  resetPassword,
  autoSignIn,
  getCurrentUser,
  fetchUserAttributes,
  fetchAuthSession,
  confirmSignUp as confirm,
  signOut as out,
  signUp as up,
  signIn as signin,
  updateUserAttributes as update,
  resendSignUpCode,
  confirmResetPassword,
  FetchUserAttributesOutput,
  decodeJWT,
  AuthSession,
  signInWithRedirect,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { AuthReturnProps } from 'types';

export type User = {
  id: string;
  username: string;
  attributes: FetchUserAttributesOutput;
};

interface IUserContext {
  isLoading: boolean;
  user?: User | null;
  isSSOUser?: boolean | null;
  googleSignin: () => Promise<any>;
  signOut: () => Promise<any>;
  signIn: (username: string, password: string, clientMetadata?: any) => Promise<AuthReturnProps>;
  signUp: (username: string, email: string, password: string) => Promise<AuthReturnProps>;
  confirmSignUp: (username: string, verificationCode: string) => Promise<AuthReturnProps>;
  resendConfirmationCode: (username: string) => Promise<AuthReturnProps>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string
  ) => Promise<AuthReturnProps>;
  resendForgotPassword: (username: string) => Promise<AuthReturnProps>;
  updateUserAttributes: (attributes: Record<string, any>) => Promise<AuthReturnProps>;
  fetchUser: () => void;
}

const defaultContext: IUserContext = {
  isLoading: true,
  user: null,
  isSSOUser: null,
  googleSignin: () => Promise.resolve({}),
  signOut: () => Promise.resolve({}),
  signIn: () => Promise.resolve({ success: false }),
  signUp: () => Promise.resolve({ success: false }),
  confirmSignUp: () => Promise.resolve({ success: false }),
  resendConfirmationCode: () => Promise.resolve({ success: false }),
  forgotPasswordSubmit: () => Promise.resolve({ success: false }),
  resendForgotPassword: () => Promise.resolve({ success: false }),
  updateUserAttributes: () => Promise.resolve({ success: false }),
  fetchUser: () => Promise.resolve(null),
};

export const UserContext = createContext<IUserContext>(defaultContext);

export const persistValuesToLocalStorageFromJWT = (
  jwtValues: { [id: string]: any },
  impersonationEmail: string,
  setImpersonationEmail: (impersonationEmail: string) => void
) => {
  const companySlugs = getCompanySlugsFromLocalStorage();
  // We check local storage for the use case of a person who has just created their account and has refreshed their browser
  // At that point, there will still not be any access info attached to the jwt
  if (jwtValues.teams && companySlugs.length === 0) {
    setCompaniesToLocalStorage(jwtValues.teams);
  }

  if (!impersonationEmail) {
    const impersonatingEmail = getImpersonatingUserEmail(jwtValues.impersonating);
    setImpersonationEmail(impersonatingEmail || '');
  }
};

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [isSSOUser, setIsSSOUser] = useState<boolean>(false);
  const [impersonationEmail, setImpersonationEmail] = useLocalStore((state: LocalUserStore) => [
    state.impersonationEmail,
    state.setImpersonationEmail,
  ]);
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const setImpersonatingCognitoUserIfExists = async () => {
    const currentSession = await fetchAuthSession();
    const idToken = currentSession.tokens?.idToken;
    const jwtValues = decodeJWT(idToken?.toString() ?? '');
    if (jwtValues?.payload?.impersonating) {
      const impersonatingUser = JSON.parse(jwtValues.payload.impersonating as string);
      await setCognitoUser(impersonatingUser, currentSession);
      setIsSSOUser(impersonatingUser.attributes?.username.startsWith('Google_'));
      return impersonatingUser;
    }
    return null;
  };

  const setCognitoUser = async (cognitoUser: User, cognitoSession?: AuthSession) => {
    let user = { ...cognitoUser };

    cognitoSession ||= await fetchAuthSession();
    const jwtValues = decodeJWT(cognitoSession.tokens?.idToken?.toString() ?? '');

    persistValuesToLocalStorageFromJWT(
      jwtValues.payload,
      impersonationEmail,
      setImpersonationEmail
    );

    // Identify user in Segment analytics
    getUserId().then((id) => {
      analytics.identify(id);
      Sentry.configureScope((scope) => {
        scope.setTag('environment', process.env.NODE_ENV || 'dev-local');
        scope.setTag('user_id', id);
      });
    });

    setUser(user);
    setIsSSOUser(user.username?.startsWith('Google_'));
    setIsLoading(false);
  };

  const fetchUser = async () => {
    const [{ userId, username }, attributes] = await Promise.all([
      getCurrentUser(),
      fetchUserAttributes(),
    ]);

    setCognitoUser({
      id: userId,
      username,
      attributes,
    });
  };

  const initVerifyAccountStatus = async () => {
    // verify we are able to pull account information of the logged in user every 30 seconds
    try {
      user && (await getCurrentUser());
    } catch (err) {
      await signOut();
      console.error(err);
    }
    setTimeout(initVerifyAccountStatus, 30000);
  };

  useEffect(() => {
    initVerifyAccountStatus();

    const hubCallback: HubCallback = async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          await setImpersonatingCognitoUserIfExists();
          break;
        case 'autoSignIn':
          const [{ userId, username }, attributes] = await Promise.all([
            getCurrentUser(),
            fetchUserAttributes(),
          ]);
          await setCognitoUser({
            id: userId,
            username,
            attributes,
          });
          break;
        case 'autoSignIn_failure':
          navigate('/login');
          break;
      }
    };
    const hubListenerCancelToken = Hub.listen('auth', hubCallback);

    setIsLoading(true);
    Promise.all([getCurrentUser(), fetchUserAttributes()])
      .then(([{ userId, username }, attributes]) => {
        setIsLoading(false);

        setImpersonatingCognitoUserIfExists()
          .then((user) => {
            if (!user) {
              setCognitoUser({ id: userId, username, attributes });
            }
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });

    // callback to remove Hub when the component is unmounted
    return () => {
      // This stops the listener.
      hubListenerCancelToken();
    };
  }, []);

  const googleSignin = async () => await signInWithRedirect({ provider: 'Google' });

  const signIn = async (
    username: string,
    password: string,
    clientMetadata?: any
  ): Promise<AuthReturnProps> => {
    try {
      await signin({
        username,
        password,
        options: {
          clientMetadata,
        },
      });

      const [{ userId }, attributes] = await Promise.all([getCurrentUser(), fetchUserAttributes()]);

      const isImpersonating = await setImpersonatingCognitoUserIfExists();
      if (!isImpersonating) {
        setCognitoUser({
          id: userId,
          username,
          attributes,
        });
      }

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
        errorCode: error.code,
      };
    }
  };

  const signUp = async (
    username: string,
    email: string,
    password: string
  ): Promise<AuthReturnProps> => {
    try {
      await up({
        username,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: {
            // enables auto sign in after user is confirmed
            enabled: true,
          },
        },
      });
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
      };
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    password: string
  ): Promise<AuthReturnProps> => {
    try {
      await confirmResetPassword({
        confirmationCode: code,
        newPassword: password,
        username,
      });
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
      };
    }
  };

  const confirmSignUp = async (
    username: string,
    verificationCode: string
  ): Promise<AuthReturnProps> => {
    try {
      const { isSignUpComplete } = await confirm({
        username,
        confirmationCode: verificationCode,
      });

      if (isSignUpComplete) {
        await autoSignIn();
        const [{ userId, username }, attributes] = await Promise.all([
          getCurrentUser(),
          fetchUserAttributes(),
        ]);

        setCognitoUser({
          id: userId,
          username,
          attributes,
        });
      }

      return { success: isSignUpComplete };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
      };
    }
  };

  const resendForgotPassword = async (username: string): Promise<AuthReturnProps> => {
    try {
      await resetPassword({
        username,
      });
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
        errorCode: error.code,
      };
    }
  };

  const resendConfirmationCode = async (username: string): Promise<AuthReturnProps> => {
    try {
      await resendSignUpCode({
        username,
      });
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
      };
    }
  };

  const signOut = async () => {
    setUser(null);
    removeZustandLocalStorage();
    setImpersonationEmail('');
    removeAccess();
    clearApolloCache(apolloClient);
    navigate('/', { replace: true });
    await out();
  };

  const removeZustandLocalStorage = () => {
    useLocalStore.persist.clearStorage();
  };

  const updateUserAttributes = async (
    attributes: Record<string, any>
  ): Promise<AuthReturnProps> => {
    try {
      await update({
        userAttributes: attributes,
      });
      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        errorMessage: error.message,
      };
    }
  };

  const values = useMemo(
    () => ({
      isLoading,
      user,
      isSSOUser,
      signOut,
      googleSignin,
      signIn,
      signUp,
      confirmSignUp,
      resendConfirmationCode,
      forgotPasswordSubmit,
      resendForgotPassword,
      updateUserAttributes,
      fetchUser,
    }),
    [isLoading, user, isSSOUser]
  );
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default UserProvider;

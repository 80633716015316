import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { DefaultBarChartTooltip } from './DefaultBarChartTooltip';

interface BarChartProps {
  //default structure of data is Array<{name: string, value: number}>.
  data: Array<{
    [key: string]: string | number;
  }>;
  barClassName?: string;
  height?: string | number;
  width?: string | number;
  layout?: 'horizontal' | 'vertical';
  yDataKey?: string; //modifies structure of data to Array<{name: string, [yDataKey]: number}>
  xDataKey?: string; //modifies structure of data to Array<{[xDataKey]: string, value: number}>
  xAxisOrientation?: 'top' | 'bottom';
  yAxisOrientation?: 'left' | 'right';
  xTickFormatter?: (value: string) => string;
  yTickFormatter?: (value: string) => string;
  tooltipContent?: React.ReactElement;
}

const BarChart = ({
  data,
  barClassName = 'text-lavender',
  height = '100%',
  width = '100%',
  layout = 'horizontal',
  xDataKey = 'name',
  yDataKey = 'value',
  xAxisOrientation = 'bottom',
  yAxisOrientation = 'left',
  xTickFormatter,
  yTickFormatter,
  tooltipContent,
}: BarChartProps) => {
  const categoryProps = { type: 'category' as const, dataKey: xDataKey };
  const numberProps = { type: 'number' as const, dataKey: yDataKey };
  const layoutXAxisProps = layout === 'horizontal' ? categoryProps : numberProps;
  const layoutYAxisProps = layout === 'horizontal' ? numberProps : categoryProps;
  const layoutRadius: [number, number, number, number] =
    layout === 'horizontal' ? [8, 8, 0, 0] : [0, 8, 8, 0];

  const defaultYTickFormatter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <RechartsBarChart data={data} layout={layout} margin={{ left: 50 }} barCategoryGap={10}>
        <CartesianGrid stroke="none" fill="#F5F4F0" />
        <XAxis
          {...layoutXAxisProps}
          orientation={xAxisOrientation}
          tickFormatter={xTickFormatter}
        />
        <YAxis
          {...layoutYAxisProps}
          orientation={yAxisOrientation}
          tickFormatter={yTickFormatter || defaultYTickFormatter}
        />
        <Tooltip content={tooltipContent || <DefaultBarChartTooltip />} />
        <Bar
          dataKey={yDataKey}
          fill="currentColor"
          className={barClassName}
          radius={layoutRadius}
          maxBarSize={50}
        />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const QUERY_GET_COMPANIES_FOR_USER_MENU = gql(`
  query GetCompaniesForUserMenu {
    getCompanies {
      id
      name
      slug
    }
  }
`);

export const useGetCompaniesForUserMenu = () => {
  const { data, loading, error } = useQuery(QUERY_GET_COMPANIES_FOR_USER_MENU);

  return {
    companies: data?.getCompanies ?? [],
    loading,
    error,
  };
};

const QUERY = gql(`
  query GetProfileImageUrls {
    getMe {
        id
        firstName
        lastName
        smallImageUrl
    }
  }
`);

export const useGetMe = ({ activeCompanySlug }: { activeCompanySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY, {
    skip: !activeCompanySlug,
  });

  return {
    user: data?.getMe ?? null,
    loading,
    error,
  };
};

export const UPDATE_USER_DEFAULT_MUTATION = gql(`
  mutation UpdateUsersDefaultCompany($companySlug: String!) {
    updateUserDefaultCompany(companySlug: $companySlug) {
      id
      firstName
      lastName
      smallImageUrl
      largeImageUrl
      defaultCompany {
        id
        name
        slug
        tier
      }
    }
  }
`);

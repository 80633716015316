import { useEffect, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { cn } from '../../../utils';
import { ComboboxOption, ComboboxProps } from 'components/atoms/Comboboxes/types';

export const MultipleCombobox = ({
  onChange,
  initialValue,
  selectedComponent,
  listItemComponent,
  options,
  placeholder,
  inputProps,
  allowCreate = true,
}: ComboboxProps<ComboboxOption[]>) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [query, setQuery] = useState('');
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ComboboxOption[]>(initialValue || []);

  const wrapperRef = useRef<HTMLElement>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  const inputRef = useRef<HTMLInputElement>(null);

  let filteredOptions = options.filter((option) => {
    return !selectedOptions.find((op) => op.value === option.value);
  });
  filteredOptions =
    query === ''
      ? filteredOptions
      : filteredOptions.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const onChangeWrapper = (values: ComboboxOption[]) => {
    updateSelectedOptions(values);
    onChange(values);
  };
  const updateSelectedOptions = (options: ComboboxOption[]) => {
    const lastAdded = options[options.length - 1];
    const optionsWithoutLast = options.slice(0, options.length - 1);
    if (optionsWithoutLast.find((op) => op.value === lastAdded.value)) {
      setErrorMsg(`${lastAdded.label} already selected.`);
      return;
    }
    setErrorMsg(null);
    setSelectedOptions([...options]);
  };

  const { className: inputClassName, ...restInputProps } = inputProps || {};
  return (
    // @ts-ignore
    <Combobox value={selectedOptions} onChange={onChangeWrapper} multiple ref={wrapperRef}>
      <div className="relative w-full">
        <div
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
          onClick={() => {
            inputRef?.current?.focus();
            setOptionsVisible(true);
          }}
        >
          <ul className="flex flex-wrap gap-2">
            <>
              {selectedOptions.length > 0 &&
                selectedOptions.map((option) => (
                  <li key={option.value}>
                    {selectedComponent
                      ? selectedComponent({ option, setSelected: setSelectedOptions })
                      : option.value}
                  </li>
                ))}
              <li className="flex-shrink">
                <Combobox.Input
                  className={cn(
                    'focus:outline-none focus:ring-0 focus:border-0 h-full',
                    inputClassName
                  )}
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setQuery('');
                    if (e.key === 'escape') setQuery('');
                  }}
                  autoComplete="off"
                  ref={inputRef}
                  placeholder={placeholder ? placeholder : 'Select an option'}
                  {...restInputProps}
                />
              </li>
            </>
          </ul>
        </div>

        {(filteredOptions.length > 0 || query.length > 0) && (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            static={optionsVisible}
          >
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option}
                className={cn(
                  'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                  'ui-selected:bg-indigo-600 ui-selected:text-white'
                )}
                onClick={() => {
                  setOptionsVisible(false);
                  setQuery('');
                }}
              >
                {listItemComponent ? (
                  listItemComponent(option)
                ) : (
                  <span className={cn('block truncate', true && 'font-semibold')}>
                    {option.label}
                  </span>
                )}
              </Combobox.Option>
            ))}
            {query.length > 0 && allowCreate && (
              <Combobox.Option
                value={{ label: query, value: query } satisfies ComboboxOption}
                className={cn(
                  'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                  'ui-selected:bg-indigo-600 ui-selected:text-white'
                )}
                onClick={() => setQuery('')}
              >
                Create "{query}"
              </Combobox.Option>
            )}
          </Combobox.Options>
        )}
        {errorMsg && <div className="text-danger">{errorMsg}</div>}
      </div>
    </Combobox>
  );
};

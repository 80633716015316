import { useState } from 'react';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { AreaChart } from 'components/atoms/AreaChart';
import { formatNumberAsDollars, toDollars, cn } from 'utils';
import { useGetProjectFinancials } from './data';
import { GetProjectFinancialsQuery } from '__generated__/graphql';
import moment from 'moment';
import { TooltipContent } from './TooltipContent';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useActiveCompany } from 'providers/ActiveCompany';

interface ProjectFinancialsGraphData {
  name: string;
  value: number;
}

export const ProjectFinancials = ({ projectId }: { projectId: string }) => {
  const [paymentsAmountDollars, setPaymentsAmountDollars] = useState(0);
  const [projectSpendAmountDollars, setProjectSpendDollars] = useState(0);
  const [remainingAmountDollars, setRemainingDollars] = useState(0);
  const [graphData, setGraphData] = useState<ProjectFinancialsGraphData[]>([]);
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const handleSingleDataPoint = (formattedData: ProjectFinancialsGraphData[]) => {
    const existingDataPoint = formattedData[0];
    const newDataPoint = {
      name: moment(existingDataPoint.name).subtract(1, 'day').format('YYYY-MM-DD'),
      value: existingDataPoint.value,
    };

    return [newDataPoint, existingDataPoint];
  };

  const onGetProjectFinancialsSuccess = (res: GetProjectFinancialsQuery) => {
    const data = res.getProjectFinancials;

    let formattedData = data.timeFrameTotals.map((point) => {
      return {
        name: point.date,
        value: toDollars(point.cumulativeSum * -1),
      };
    });

    if (formattedData.length === 1) {
      formattedData = handleSingleDataPoint(formattedData);
    }

    setPaymentsAmountDollars(toDollars(data.totalPayments));
    setProjectSpendDollars(toDollars(data.totalSpend));
    setRemainingDollars(toDollars(data.totalPayments - data.totalSpend));
    setGraphData(formattedData);
  };

  // get all transactions associated with the project
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { loading, error } = useGetProjectFinancials({
    companySlug: activeCompanySlug,
    projectId,
    timezone,
    onSuccess: onGetProjectFinancialsSuccess,
  });

  const getDollarClass = (value: number) => {
    if (value < 0) {
      return 'text-red-700';
    }
    return 'text-green-700';
  };

  const yTickFormatter = (value: string) => {
    const tickNum = parseInt(value);
    return formatNumberAsDollars(tickNum, true);
  };

  const xTickFormatter = (value: string) => {
    return moment(value).format('MMM DD');
  };

  return (
    <OutlinedWidget className="w-full">
      <div className="flex flex-col h-full">
        <div>
          <OutlinedWidget.Title>Project financials</OutlinedWidget.Title>
        </div>
        <div className="h-full">
          {loading && <RectangleSkeleton className="h-[400px]" />}
          {graphData.length ? (
            <AreaChart
              data={graphData}
              yTickFormatter={yTickFormatter}
              yTick={{ fontWeight: 'bold' }}
              xTickFormatter={xTickFormatter}
              tooltipContent={<TooltipContent />}
              xTick={{ fontSize: 12 }}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              {error ? (
                <h3 className="text-red-700">Unable to retrieve Project Financials</h3>
              ) : (
                <h3 className="text-center">Start spending or add historical transactions</h3>
              )}
            </div>
          )}
        </div>
        <div>
          <hr className="my-5" />
          <div className="flex flex-wrap justify-between gap-10">
            <div className="flex-1 border-r">
              <h4>Payments</h4>
              <div className={cn('text-4xl', getDollarClass(paymentsAmountDollars))}>
                {formatNumberAsDollars(paymentsAmountDollars)}
              </div>
            </div>
            <div className="flex-1 border-r">
              <h4>Project spend</h4>
              <div className="text-4xl">{formatNumberAsDollars(projectSpendAmountDollars)}</div>
            </div>
            <div className="flex-1">
              <h4>Remaining</h4>
              <div className={cn('text-4xl', getDollarClass(remainingAmountDollars))}>
                {formatNumberAsDollars(remainingAmountDollars)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutlinedWidget>
  );
};

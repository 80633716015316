import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { gql } from '__generated__/gql';
import { GetCompanyQboConfigurationSettingsQuery } from '__generated__/graphql';

export const QUERY_GET_TRANSACTION_SETTINGS = gql(`
  query GetTransactionalSettingsForQboTransactionsSettings($companySlug: String!) {
    getSettings(customerId: $companySlug) {
      id
      requireMemoOver
      requireReceiptOver
    }
  }
`);

export const useCompanyTransactionSettings = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(QUERY_GET_TRANSACTION_SETTINGS, {
    variables: { companySlug },
    skip: !companySlug,
  });

  return {
    requireMemoOver: data?.getSettings?.requireMemoOver,
    requireReceiptOver: data?.getSettings?.requireReceiptOver,
    requireMemo:
      data?.getSettings?.requireMemoOver && data.getSettings.requireMemoOver > 0 ? true : false,
    requireReceipt:
      data?.getSettings?.requireReceiptOver && data.getSettings.requireReceiptOver > 0
        ? true
        : false,
    loading,
    error,
  };
};

export const QUERY_COMPANY_QBO_CONFIGURATION_SETTINGS = gql(`
  query GetCompanyQboConfigurationSettings($companySlug: String!) {
    getCompany(companyId: $companySlug) {
      id
      intuitConnection {
        id
        isQboAutoTaggingEnabled
        requireAccountingTag
        defaultExpenseCategoryId
      }
    }
  }
`);

export const useCompanyQboConfigurationSettings = ({
  companySlug,
  onCompleted,
}: {
  companySlug: string;
  onCompleted?: (data: GetCompanyQboConfigurationSettingsQuery) => void;
}) => {
  const { data, loading, error } = useQuery(QUERY_COMPANY_QBO_CONFIGURATION_SETTINGS, {
    variables: { companySlug },
    skip: !companySlug,
    onCompleted,
  });

  const isQboAutoTaggingEnabled = data?.getCompany?.intuitConnection?.isQboAutoTaggingEnabled;
  const requireAccountingTag = data?.getCompany?.intuitConnection?.requireAccountingTag;
  const defaultExpenseCategoryId = data?.getCompany?.intuitConnection?.defaultExpenseCategoryId;

  return {
    loading,
    error,
    isQboAutoTaggingEnabled,
    requireAccountingTag,
    defaultExpenseCategoryId,
  };
};

const MUTATION_UPDATE_IS_QBO_AUTO_TAGGING_ENABLED = gql(`
  mutation UpdateCompanyQboConfigurationAutoTaggingEnabled(
    $companySlug: String!
    $isQboAutoTaggingEnabled: Boolean!
    $requireAccountingTag: Boolean!
    $defaultExpenseCategoryId: String
  ) {
    updateCompanyIntuitTagSettings(
      companySlug: $companySlug
      isQboAutoTaggingEnabled: $isQboAutoTaggingEnabled
      requireAccountingTag: $requireAccountingTag
      defaultExpenseCategoryId: $defaultExpenseCategoryId
    ) {
      id
      intuitConnection {
        id
        isQboAutoTaggingEnabled
      }
    }
  }
`);

export const useUpdateCompanyIntuitTagSettings = () => {
  const [updateCompanyIntuitTagSettings, { data, loading, error }] = useMutation(
    MUTATION_UPDATE_IS_QBO_AUTO_TAGGING_ENABLED
  );

  return {
    updateCompanyIntuitTagSettings,
    data,
    loading,
    error,
  };
};

const QUERY_FOR_QBO_CATEGORIES = gql(`
  query GetQboCategoriesForQboConfigurationSettings($companySlug: String!) {
    listQBOExpenseAccounts(companySlug: $companySlug) {
      id: accountId
      accountName
    }
  }
`);

export const useQboCategories = ({
  companySlug,
  onError,
}: {
  companySlug: string;
  onError?: (error: ApolloError) => void;
}) => {
  const { data, loading, error } = useQuery(QUERY_FOR_QBO_CATEGORIES, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
    onError,
  });

  const qboCategories = data?.listQBOExpenseAccounts ?? [];

  return { qboCategories, loading, error };
};

import { useState } from 'react';
import { Button } from 'components/atoms/Buttons';
import { Input, InputProps } from '../Input';
import { Eye, EyeOff } from 'lucide-react';

export const PasswordInput = ({ placeholder = 'Password', ...props }: Omit<InputProps, 'type'>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative w-full">
      <Input
        maxLength={100}
        name="password"
        autoComplete="password"
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        {...props}
      />
      <Button
        className="absolute right-0 top-0 bottom-0 px-3 rounded-r-lg flex justify-center items-center"
        onClick={() => setShowPassword(!showPassword)}
        type="button"
        variant="ghost"
      >
        {showPassword ? (
          <Eye className="stroke-muted" size={20} />
        ) : (
          <EyeOff className="stroke-muted" size={20} />
        )}
      </Button>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PageTitle } from 'components/atoms/PageTitle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import PendingInvitationsCollection from './PendingInvitationsCollection';

export const PendingInvitationOrApplicationForm = () => {
  const { applicationStarted, loading: applicationExistsLoading } = useGetApplicationExistsStatus();
  const { pendingCustomerIds, pendingInvitations, loading } = useGetPendingCustomers();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading === false) {
      if (!pendingCustomerIds.length && !applicationExistsLoading) {
        if (!applicationStarted) {
          navigate('/what-to-expect');
        } else {
          navigate('/application');
        }
      }
    }
  }, [navigate, applicationExistsLoading, applicationStarted, loading, pendingCustomerIds.length]);

  if (loading)
    return (
      <>
        <PageTitle hidden text="Loading ..." />
        <div className="grid place-items-center mt-52">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Loading ...</p>
          </div>
        </div>
      </>
    );

  return (
    <>
      {pendingCustomerIds.length && (
        <PendingInvitationsCollection pendingInvitations={pendingInvitations} />
      )}
    </>
  );
};

export const GET_APPLICATION_FORM = gql(`
    query GetApplicationExistStatus {
        getApplication {
            formId
        }
    }
`);

export const useGetApplicationExistsStatus = () => {
  const { data, loading, error } = useQuery(GET_APPLICATION_FORM);

  return {
    applicationStarted: data?.getApplication?.formId,
    loading,
    error,
  };
};

export const QUERY_GET_AUTHORIZED_USER_INVITATIONS = gql(`
  query GetAuthorizedUserInvitations {
    getAuthorizedUserInvitations {
      pendingInvitations {
        email
        companySlug
        teamName
        status
        token
        invitationLink
        role
      }
    }
  }
`);

export const useGetPendingCustomers = () => {
  const { loading, data, error } = useQuery(QUERY_GET_AUTHORIZED_USER_INVITATIONS);

  const pendingInvitations = data?.getAuthorizedUserInvitations?.pendingInvitations ?? [];
  const pendingCompanySlugs = pendingInvitations.map((invitation) => invitation.companySlug);

  return {
    pendingInvitations,
    pendingCustomerIds: pendingCompanySlugs,
    error,
    loading,
  };
};

import { QueryReference, useReadQuery } from '@apollo/client';
import { GetPlaidLinkedAccountSummaryQuery, PlaidLinkedAccountStatus } from '__generated__/graphql';

export type LinkedBankDisconnectedCardProps = {
  queryRef: QueryReference<GetPlaidLinkedAccountSummaryQuery>;
  children: JSX.Element;
  suspend: boolean;
};
export const LinkedBankDisconnectedCard = ({
  queryRef,
  suspend,
  children,
}: LinkedBankDisconnectedCardProps) => {
  if (suspend) {
    throw new Promise(() => undefined);
  }
  const {
    data: { getPlaidLinkedAccountSummary: accountSummary },
  } = useReadQuery(queryRef);
  return accountSummary?.status === PlaidLinkedAccountStatus.disconnected ? children : null;
};

import clsx from 'clsx';
import { SkeletonProps, waveAnimationClasses } from 'components/atoms/Skeletons';

export function RectangleSkeleton({
  animation = 'wave',
  rounded = true,
  width = 'full',
  height = 'small',
  className,
  ...props
}: SkeletonProps) {
  const widths = {
    small: 'w-1/4',
    medium: 'w-2/5',
    large: 'w-2/3',
    full: 'w-full',
  };
  const heights = {
    small: 'h-4',
    medium: 'h-6',
    large: 'h-8',
    full: 'h-full',
  };
  return (
    <div
      className={clsx(
        animation === 'wave' ? waveAnimationClasses : 'animate-pulse',
        rounded && 'rounded-2xl',
        widths && widths[width],
        heights && heights[height],
        'h-4 relative bg-gray-300/60',
        className
      )}
      {...props}
    />
  );
}

interface SquareSkeletonProps extends SkeletonProps {
  size?: 'small' | 'medium' | 'large' | 'full';
  circle?: boolean;
}
export function SquareSkeleton({
  animation = 'wave',
  rounded = true,
  size = 'medium',
  circle = false,
  className,
  ...props
}: SquareSkeletonProps) {
  const sizes = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    large: 'w-12 h-12',
    full: 'w-full h-full',
  };

  return (
    <div
      className={clsx(
        animation === 'wave' ? waveAnimationClasses : 'animate-pulse',
        circle ? 'rounded-full' : rounded && 'rounded-2xl',
        size && sizes[size],
        'relative bg-gray-300/60',
        className
      )}
      {...props}
    />
  );
}

export function CircleSkeleton(props: SquareSkeletonProps) {
  return <SquareSkeleton circle={true} {...props} />;
}

export function PillSkeleton({ size = 'small', ...props }: SquareSkeletonProps) {
  const sizes = {
    small: 'w-8 h-4',
    medium: 'w-10 h-6',
    large: 'w-16 h-12',
    full: 'w-full h-full',
  };

  return <SquareSkeleton circle={true} className={sizes[size]} {...props} />;
}

import * as Sentry from '@sentry/react';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { fetchAuthSession } from 'aws-amplify/auth';

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await fetchAuthSession()).tokens?.idToken?.toString(), // Required when you use Cognito UserPools OR OpenID Connect
};
const url = process.env.REACT_APP_GRAPHQL_API_URL!;
const region = process.env.REACT_APP_AWS_REGION!;
const httpLink = new HttpLink({ uri: url });

const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error;
      if (process.env.REACT_APP_REPORT_ERRORS === 'true') {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
      }
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}; Full object: ${JSON.stringify(error)}`,
        'error'
      );
    });
  }
  if (networkError) {
    networkError.message = `[Network error]: ${networkError.message}, operation: ${operation}, response: ${response}`;
    Sentry.captureMessage(JSON.stringify(networkError), 'error');
  }
});

const link = ApolloLink.from([
  //@ts-ignore
  errorLink,
  //@ts-ignore
  createAuthLink({ url, region, auth }),
  //@ts-ignore
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

interface ApolloWrapperProps {
  children: React.ReactNode;
}
export const ApolloWrapper = ({ children }: ApolloWrapperProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { useWindowKeyboardInteraction } from '../WindowInteractionListeners/keyboard';
import { cn } from 'utils';
import { Button } from '../Buttons';
import { X } from 'lucide-react';

type ModalProps = {
  show: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
  title?: string;
};
const Modal = ({ show, onClose, children, title = '', className = '' }: ModalProps) => {
  useWindowKeyboardInteraction({
    onKeyDown: (event) => {
      if (event.key === 'Escape') {
        onClose?.();
      }
    },
  });

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        open={show}
        onClose={onClose}
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#808080] bg-opacity-60 transition-opacity"></div>
        </Transition.Child>

        <div className="fixed flex w-screen h-screen items-center justify-center p-4 top-0 left-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            as={Fragment}
          >
            <Dialog.Panel
              onClick={(e) => e.stopPropagation()}
              className={cn(
                'relative transform overflow-hidden  bg-white text-left shadow-xl transition-all',
                'w-full max-w-[100vw] px-6 py-6 rounded-xl',
                'w-auto md:px-10 md:py-8 md:rounded-3xl',
                'xl:w-auto xl:max-w-[1280px] xl:h-auto xl:max-h-[1020px] xl:rounded-lg',
                className
              )}
            >
              {!title && (
                <Button
                  variant="ghost"
                  className="py-2 px-2 w-max flex items-center justify-center hover:bg-primary-200 absolute right-4 top-4"
                  onClick={onClose}
                >
                  <X />
                </Button>
              )}
              {title && <ModalHeader title={title} onClose={onClose} />}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const ModalHeader = ({
  title,
  onClose,
  className = '',
}: {
  title: string;
  onClose: () => void;
  className?: string;
}) => {
  return (
    <div className={cn('flex items-center justify-between pb-4 gap-4', className)}>
      <Dialog.Title className="text-xl text-black">{title}</Dialog.Title>
      <Button
        variant="ghost"
        className="py-2 px-2 flex items-center justify-center hover:bg-primary-200"
        onClick={onClose}
      >
        <X />
      </Button>
    </div>
  );
};

export const ModalFooter = ({
  className = '',
  children,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={cn('pt-4 border-t border-primary-400 mt-10', className)}>{children}</div>;
};

export default Modal;

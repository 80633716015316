import { useReducer } from 'react';
import { Maybe } from 'utils/types';

interface CreateCompanyFormState {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  companyName: Maybe<string>;
  teamSize: Maybe<number>;
  companyMakesPurchasesOnBehalfOfClients: Maybe<boolean>;
}

export const defaultCreateCompanyFormState: CreateCompanyFormState = {
  firstName: undefined,
  lastName: undefined,
  companyName: undefined,
  teamSize: undefined,
  companyMakesPurchasesOnBehalfOfClients: undefined,
};

// Actions
interface UpdateCompanyName {
  type: 'UPDATE_COMPANY_NAME';
  payload: string;
}

interface UpdateTeamSize {
  type: 'UPDATE_TEAM_SIZE';
  payload: number | undefined;
}

interface UpdateCompanyMakesPurchasesOnBehalfOfClients {
  type: 'UPDATE_COMPANY_MAKES_PURCHASES_ON_BEHALF_OF_CLIENTS';
  payload: boolean;
}

interface UpdateUserFirstName {
  type: 'UPDATE_FIRST_NAME';
  payload: string;
}

interface UpdateUserLastName {
  type: 'UPDATE_LAST_NAME';
  payload: string;
}

type CreateCompanyFormAction =
  | UpdateCompanyName
  | UpdateTeamSize
  | UpdateCompanyMakesPurchasesOnBehalfOfClients
  | UpdateUserFirstName
  | UpdateUserLastName;

const CreateCompanyFormReducer = (
  prevState: CreateCompanyFormState,
  action: CreateCompanyFormAction
): CreateCompanyFormState => {
  switch (action.type) {
    case 'UPDATE_COMPANY_NAME':
      return { ...prevState, companyName: action.payload };
    case 'UPDATE_TEAM_SIZE':
      return { ...prevState, teamSize: action.payload };
    case 'UPDATE_COMPANY_MAKES_PURCHASES_ON_BEHALF_OF_CLIENTS':
      return { ...prevState, companyMakesPurchasesOnBehalfOfClients: action.payload };
    case 'UPDATE_FIRST_NAME':
      return { ...prevState, firstName: action.payload };
    case 'UPDATE_LAST_NAME':
      return { ...prevState, lastName: action.payload };
    default:
      return { ...prevState };
  }
};

export const useCreateCompanyFormReducer = (defaults?: CreateCompanyFormState) => {
  return useReducer(CreateCompanyFormReducer, {
    ...defaultCreateCompanyFormState,
    ...defaults,
  });
};

// Error Validation
interface CreateCompanyFormStateErrors {
  companyName: Maybe<string>;
  teamSize: Maybe<string>;
  companyMakesPurchasesOnBehalfOfClients: Maybe<string>;
  lastName: Maybe<string>;
  firstName: Maybe<string>;
  isValid: boolean;
}

export const validateCreateCompanyFormState = (
  state: CreateCompanyFormState
): CreateCompanyFormStateErrors => {
  const errors: CreateCompanyFormStateErrors = {
    companyName: undefined,
    teamSize: undefined,
    companyMakesPurchasesOnBehalfOfClients: undefined,
    lastName: undefined,
    firstName: undefined,
    isValid: true,
  };

  if (!state.companyName) {
    errors.companyName = 'Company name is required';
    errors.isValid = false;
  }

  if (state.teamSize === undefined) {
    errors.teamSize = 'Team size is required';
    errors.isValid = false;
  }

  if (state.teamSize === 0) {
    errors.teamSize = 'Team size must be at least 1';
    errors.isValid = false;
  }

  if (state.companyMakesPurchasesOnBehalfOfClients === undefined) {
    errors.companyMakesPurchasesOnBehalfOfClients = 'This is required';
    errors.isValid = false;
  }

  if ((state.firstName?.length ?? 0) < 2) {
    errors.firstName = 'First name has to be at least 2 characters';
    errors.isValid = false;
  }

  if ((state.lastName?.length ?? 0) < 2) {
    errors.lastName = 'Last name has to be at least 2 characters';
    errors.isValid = false;
  }

  return errors;
};

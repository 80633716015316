import { ReactNode } from 'react';

export const AssignHeaderContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-xl">Assign</div>
      {children}
    </div>
  );
};

export const AssignContainer = ({ children }: { children: ReactNode }) => {
  return <div className="grid gap-4 max-w-full min-w-0">{children}</div>;
};

import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';

import { Button } from 'components/atoms/Buttons';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/Select';
import { PaginationContainer, PaginationContentContainer } from 'components/atoms/Pagination';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
}

export function Pagination<TData>({ table }: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center justify-between px-2">
      <div className="flex-1 text-sm text-muted-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{' '}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <PaginationContainer>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="border-none bg-transparent">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
              <span className="ml-1">per page</span>
            </SelectTrigger>
            <SelectContent side="top">
              {[20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <PaginationContentContainer>
            <Button
              variant="ghost"
              className="h-8 w-8 p-0 bg-transparent border-none"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              overrideDisabledStyled
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <div className="whitespace-nowrap">{`${
              table.getState().pagination.pageIndex + 1
            } of ${table.getPageCount()}`}</div>
            <Button
              variant="ghost"
              className="h-8 w-8 p-0 bg-transparent border-none"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              overrideDisabledStyled
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
          </PaginationContentContainer>
        </PaginationContainer>
      </div>
    </div>
  );
}

export const ZenaLogo = ({ fill = 'white' }: { fill?: 'white' | 'black' }) => {
  return (
    <svg
      width="102"
      height="24"
      viewBox="0 0 102 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3712 16.5124C24.0123 16.5124 22.7384 17.2337 22.0664 18.435C20.7875 20.7195 18.7891 21.8877 16.6784 21.8877H8.90728C8.90728 21.8877 25.9819 3.4304 28.4062 0.740845H12.6692C7.72201 0.752273 2.95969 2.58467 1.18115 7.08504H5.39143C6.14456 7.08504 6.8265 6.63297 7.13624 5.93455C8.37272 3.14087 10.5734 1.70974 12.909 1.70974H20.6801C20.6801 1.70974 3.60415 20.1658 1.18115 22.8554H16.9169C21.8653 22.844 26.6277 21.0115 28.4049 16.5124H25.3699H25.3712Z"
        fill={fill}
      />
      <path
        d="M47.8286 16.4477C47.0667 16.4477 46.3723 16.8947 46.075 17.5944C44.8598 20.4541 42.7553 22.3779 40.4185 22.3779C37.4821 22.3779 35.2265 20.5023 34.5446 17.3645C33.7864 13.8724 36.5741 10.567 41.2902 10.567H51.7666C51.7666 4.40564 47.7199 0.302734 40.8131 0.302734C33.3568 0.302734 28.8867 6.01962 28.8867 12.0756C28.8867 19.4356 34.3659 23.1563 40.6545 23.1563C45.6179 23.1563 50.2279 20.8693 52.0114 16.4477H47.8298H47.8286ZM40.973 9.54728C37.5296 9.54728 35.4163 10.4997 34.4759 11.4457C34.4671 6.19867 37.1187 1.82275 41.1828 1.82275C43.8781 1.82275 46.1912 5.34533 46.1912 9.54728H40.973Z"
        fill={fill}
      />
      <path
        d="M66.3998 0.29895C62.0834 0.29895 59.7016 3.01517 59.7016 3.01517V1.04055H54.376V22.6954H59.7016V5.4825C59.7016 5.4825 61.9972 1.99421 65.4881 1.99421C69.6684 1.99421 71.282 6.47045 71.282 11.4737V20.7893C71.282 21.8421 72.1376 22.6954 73.1942 22.6954H76.6001V11.6172C76.6001 5.78092 74.1971 0.29895 66.3998 0.29895Z"
        fill={fill}
      />
      <path
        d="M100.64 22.8604H97.2269C96.1703 22.8604 95.3147 21.9906 95.3147 20.9163C95.3147 20.9163 92.4334 23.7011 88.5653 23.7011C82.1918 23.7011 78.6098 21.1144 78.586 16.3893C78.5648 12.1263 81.8833 9.47743 86.4883 9.47743H91.9125C94.5878 9.47743 96.0104 8.13011 96.0104 5.46341C96.0104 2.79672 94.2082 1.5967 91.6628 1.5967C89.5907 1.5967 88.3692 2.39163 86.7668 4.30658C85.7976 5.46468 84.0041 5.34913 83.181 4.08054L81.0128 0.738281H93.5175C97.1857 0.738281 100.64 1.9129 100.64 6.1758V22.8604ZM95.3147 19.6807V9.47743C95.3147 9.47743 93.761 10.9898 90.2364 10.9898C86.7118 10.9898 84.3888 13.2578 84.3888 16.5696C84.3888 21.3468 88.3105 22.2966 89.4471 22.2966C92.3085 22.2966 95.316 19.6807 95.316 19.6807H95.3147Z"
        fill={fill}
      />
    </svg>
  );
};

import { useEffect, useState } from 'react';
import { ToggleRow } from '../../../pages/Settings/tabs/IntegrationsTab/QboIntegration/QboConfigurationSection/helperComponents';
import { Toggle } from 'components/atoms/Inputs/Toggle';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import {
  useCompanyQboConfigurationSettings,
  useQboCategories,
  useUpdateCompanyIntuitTagSettings,
} from '../../../pages/Settings/tabs/IntegrationsTab/QboIntegration/QboConfigurationSection/data';
import { useActiveCompany } from '../../../providers/ActiveCompany';
import { Modal } from 'components/atoms/Modal';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { TableRow } from 'components/atoms/table/TableRow';
import { RoundButton } from 'components/atoms/Buttons';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { cn } from '../../../utils';

const RequireAccountingTag = ({ disabled }: { disabled?: boolean }) => {
  const [toggleActive, setToggleActive] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [defaultExpenseCategoryName, setSelectedAccountName] = useState<string>('');

  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';

  const {
    isQboAutoTaggingEnabled,
    defaultExpenseCategoryId,
    loading: autoTaggingLoading,
  } = useCompanyQboConfigurationSettings({
    companySlug: activeCompanySlug,
    onCompleted: (data) => {
      setToggleActive(!!data.getCompany?.intuitConnection?.requireAccountingTag);
    },
  });
  const { updateCompanyIntuitTagSettings, loading: updateSettingsLoading } =
    useUpdateCompanyIntuitTagSettings();
  const { qboCategories, loading: cateogoriesLoading } = useQboCategories({
    companySlug: activeCompanySlug,
  });

  useEffect(() => {
    const matchedCategory = qboCategories.find(
      (category) => category.id === defaultExpenseCategoryId
    );
    setSelectedAccountName(matchedCategory?.accountName ?? '');
  }, [defaultExpenseCategoryId, qboCategories]);

  const handleRequireAccountingCategoryChange = () => {
    if (toggleActive) {
      setAccountModalOpen(true);
    } else {
      setToggleActive(true);
      updateCompanyIntuitTagSettings({
        variables: {
          companySlug: activeCompanySlug,
          isQboAutoTaggingEnabled: !!isQboAutoTaggingEnabled,
          requireAccountingTag: true,
          defaultExpenseCategoryId: defaultExpenseCategoryId,
        },
        refetchQueries: ['GetCompanyQboConfigurationSettings'],
      });
    }
  };

  const handleSelectAccount = (newDefaultExpenseCategoryId: string) => {
    updateCompanyIntuitTagSettings({
      variables: {
        companySlug: activeCompanySlug,
        isQboAutoTaggingEnabled: !!isQboAutoTaggingEnabled,
        requireAccountingTag: false,
        defaultExpenseCategoryId: newDefaultExpenseCategoryId,
      },
      refetchQueries: ['GetCompanyQboConfigurationSettings'],
      onCompleted: () => {
        setToggleActive(false);
        setAccountModalOpen(false);
      },
    });
  };

  return (
    <>
      <ToggleRow>
        <div className="font-medium">Require accouting category</div>
        <div className="flex flex-row gap-3 items-center text-sm">
          {autoTaggingLoading && <RectangleSkeleton width="medium" height="medium" rounded />}
          {!autoTaggingLoading && (
            <>
              <Toggle
                isActive={toggleActive}
                onToggle={handleRequireAccountingCategoryChange}
                disabled={disabled ? disabled : false}
              />
              <div className="w-6">{toggleActive ? 'Yes' : 'No'}</div>
            </>
          )}
        </div>
      </ToggleRow>
      <div className="flex">
        <p className="max-w-[600px] font-light text-sm">
          When enabled, all transactions must have an accounting tag assigned. When disabled,
          transactions with no accounting tag will be assigned to the default category.
        </p>
        <div className="flex flex-wrap ml-auto gap-4">
          {defaultExpenseCategoryName && (
            <p className={cn('flex items-center text-gray-500', toggleActive ? 'opacity-30' : '')}>
              {defaultExpenseCategoryName}*{defaultExpenseCategoryId}
            </p>
          )}
          <RoundButton
            variant="secondary"
            disabled={toggleActive}
            onClick={() => setAccountModalOpen(true)}
          >
            Change default account
          </RoundButton>
        </div>
      </div>
      <Modal
        show={accountModalOpen}
        onClose={() => setAccountModalOpen(false)}
        className="max-h-1/2"
      >
        <div className="overflow-auto max-h-96">
          {cateogoriesLoading || updateSettingsLoading ? (
            <div className="h-96 w-96 flex justify-center items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <h3>Select a default accounting tag.</h3>
              <p className="max-w-[600px] font-light text-sm">
                All transactions without an accounting tag assigned will be mapped to this category
                in QuickBooks.
              </p>
              <TableWithPagination>
                {qboCategories.map((account) => {
                  return (
                    <TableRow key={account.id}>
                      <td>{account.accountName}</td>
                      <td className="text-gray-400 italic text-left p-2 sm:p-12">{account.id}</td>
                      <td>
                        <RoundButton
                          variant="primary"
                          onClick={() => handleSelectAccount(account.id)}
                        >
                          Select
                        </RoundButton>
                      </td>
                    </TableRow>
                  );
                })}
              </TableWithPagination>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default RequireAccountingTag;

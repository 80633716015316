import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type RedirecterProps = {
  newSubUrl?: string;
};

export function Redirecter({ newSubUrl }: RedirecterProps): JSX.Element | null {
  const navigate = useNavigate();
  const location = useLocation();

  newSubUrl ||= location.pathname;
  useEffect(() => {
    // check if location.pathname has a pathname in the first position
    const firstPath = location.pathname.split('/').filter(Boolean)[0];
    // check if firstPath contains only digits
    if (firstPath && firstPath.match(/^\d+$/)) {
      // take the rest of the location.pathname and redirect to that URL
      let newPath = location.pathname.replace(`/${firstPath}`, '');
      // takes care of if /${firstPath} is the only thing in the pathname
      newPath ||= '/';
      navigate(`${newPath}${location.search}`, { replace: true });
    } else {
      navigate(`${newSubUrl || ''}${location.search}`, { replace: true });
    }
  }, []);

  return null;
}

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const GET_TRANSACTION_COUNT_FOR_GRAPHIC = gql(`
query GetTransactionsAndAuthorizationsForGraphic(
    $customerId: String!
    $pageNumber: Int!
    $showZenaTransactionsOnly: Boolean!
  ) {
    getAuthorizationsAndTransactions(
      customerId: $customerId
      pageNumber: $pageNumber
      filters: { onlyMissingData: true, onlyZenaTransaction: $showZenaTransactionsOnly}
    ) {
      meta {
        total
      }
    }
  }
`);

export const useGetTransactionCount = ({
  customerId,
  onlyZenaTransaction,
}: {
  customerId: string;
  onlyZenaTransaction: boolean;
}) => {
  const { data, loading, error } = useQuery(GET_TRANSACTION_COUNT_FOR_GRAPHIC, {
    variables: {
      customerId,
      pageNumber: 0,
      showZenaTransactionsOnly: onlyZenaTransaction,
    },
  });

  return {
    transactionCount: data?.getAuthorizationsAndTransactions?.meta?.total,
    loading,
    error,
  };
};

const GET_INTUIT_APP_CONNECTION_QUERY = gql(`
  query GetIntuitAppConnectionForConfiguration($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
    }
  }
`);

export const useGetIntuitAppConnection = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_QUERY, {
    variables: { companySlug: companySlug },
  });

  const appConnection = data?.getIntuitAppConnection || null;
  return {
    isActive: !!appConnection?.startedAt,
    appConnection,
    loading,
    error,
  };
};

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'utils';

export const TabLink = ({
  isActive,
  href,
  children,
  disabled,
}: {
  isActive: boolean;
  children: ReactNode;
  href: string;
  disabled?: boolean;
}) => {
  return (
    <Link
      to={href}
      className={cn(
        'border-b-2 pb-1 pt-4 px-1 text-center cursor-pointer flex items-end justify-center',
        'hover:border-primary hover:text-gray-700',
        isActive ? 'border-primary text-black font-semibold' : 'border-transparent text-muted',
        disabled && 'cursor-not-allowed text-muted pointer-events-none'
      )}
      aria-disabled={disabled}
    >
      {children}
    </Link>
  );
};

export const TabButton = ({
  isActive,
  onClick,
  children,
}: {
  isActive: boolean;
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'border-b-2 pb-1 pt-4 px-1 text-center cursor-pointer flex items-end justify-center',
        'hover:border-primary hover:text-gray-700',
        isActive ? 'border-primary text-black font-semibold' : 'border-transparent text-muted'
      )}
    >
      {children}
    </button>
  );
};

export const TabsContainer = ({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'border-b border-primary-400 w-full flex justify-start items-stretch gap-6',
        className
      )}
    >
      {children}
    </div>
  );
};

import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { cn } from 'utils';

export const HowQboIntegrationWorksSection = ({ classNames = '' }: { classNames?: string }) => {
  return (
    <section className={cn('', classNames)}>
      <OutlinedWidget className="h-full">
        <div className="border-b pb-5 mb-7 flex items-center">
          <h3 className="text-black text-xl">How it works</h3>
        </div>
        <ul className="list-disc px-8 font-medium space-y-4 mb-6">
          <li>What the integration does</li>
          <li>How we bridge Zena and QuickBooks data</li>
          <li>How transactions in QuickBooks are updated.</li>
        </ul>
        <div className="flex justify-center">
          <a
            href="https://support.getzena.com/article/86-how-does-the-quickbooks-online-integration-work"
            target="_blank"
            rel="noreferrer"
            className="underline text-lg"
          >
            click to read more
          </a>
        </div>
      </OutlinedWidget>
    </section>
  );
};

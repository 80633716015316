import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { gql } from '__generated__/gql';
import { useCompany } from 'hooks/useCompany';
import {
  setRepaymentConnectionCompleteFromLocalStorage,
  hasRepaymentConnectionCompleteFromLocalStorage,
  setHasPlaidConnectionCompleteFromLocalStorage,
} from 'hooks/plaid';
import {
  ApplyForZenaCardMarketing,
  FinishApplingForZenaCardMarketing,
} from './marketing/components';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { getPath, routesEnabledForLite } from './marketing/utils';
import { UpsellZena } from 'pages/(dashboard)/MarketingDashboard/UpsellZena';
import { ReactNode, useState } from 'react';
import { useActiveCompany } from './ActiveCompany';

const REPAYMENT_CONNECTION_COMPLETE_QUERY = gql(`
  query ConnectionCompleteQuery($companySlug: String!)  {
    getRepaymentConnectionStatus(companySlug: $companySlug) {
      id
      connected
    }
  }
`);

export function RequireRepaymentConnection({ children }: { children: ReactNode }) {
  const location = useLocation();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [hasRepaymentConnection, setHasRepaymentConnection] = useState(
    hasRepaymentConnectionCompleteFromLocalStorage()
  );

  const { loading: loadingCompanyTier, company } = useCompany({
    companySlug: activeCompanySlug,
  });

  const { loading } = useQuery(REPAYMENT_CONNECTION_COMPLETE_QUERY, {
    variables: {
      companySlug: activeCompanySlug,
    },
    skip: !activeCompanySlug || hasRepaymentConnection,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getRepaymentConnectionStatus?.connected === true) {
        setHasRepaymentConnection(true);
        setRepaymentConnectionCompleteFromLocalStorage(true);
        setHasPlaidConnectionCompleteFromLocalStorage(true);
      }
    },
  });

  if (routesEnabledForLite.includes(getPath(location.pathname))) {
    return <>{children}</>;
  }

  if (loading || loadingCompanyTier) {
    return (
      <div className="flex justify-center mt-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (company) {
    if (hasRepaymentConnection) {
      return <>{children}</>;
    } else {
      if (company.tier === 'lite') {
        return getPath(location.pathname) === 'transactions' ? (
          <UpsellZena />
        ) : (
          <ApplyForZenaCardMarketing />
        );
      }
      if (company.tier === 'luxe') {
        // Case where no plaid connection and luxe, show finish applying (connect plaid)
        return <FinishApplingForZenaCardMarketing />;
      }
    }
  }

  return <>{children}</>;
}

import { useQuery, useSubscription } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  GetAuthorizationsAndTransactionsQuery,
  TransactionFiltersInput,
} from '__generated__/graphql';

const GET_AUTHORIZATIONS_AND_TRANSACTIONS = gql(`
  query GetAuthorizationsAndTransactions($customerId: String!, $pageNumber: Int!, $pageSize: Int, $filters: TransactionFiltersInput, $excludeChildren: Boolean) {
    getAuthorizationsAndTransactions(customerId: $customerId, pageNumber: $pageNumber, pageSize: $pageSize, filters: $filters, excludeChildren: $excludeChildren) {
        meta {
          pageNumber
          pageSize
          total
        }
        data {
          id
          name
          type
          shortName
          amount
          amountWithDirection
          spendCategory
          zenaType
          direction
          date
          accountId
          card {
            id
            name
          }
          bank {
            id
            name
            last4Digits
          }
          tags {
            tagName
            value
          }
          rooms
          categories
          roomTags {
            tagName
            value
          }
          categoryTags {
            tagName
            value
          }
          poCodeTag {
            tagName
            value
          }
          accountingTag {
            tagName
            value
          }
          receipt {
            imageUrl
          }
          user {
            email
            fullName {
              first
              last
            }
          }
          projectId
          projectName
          lineItems {
            externalId
            description
            totalCents
          }
          parentTransactionId
          isAuthorization
          childTransactionIds
          accountingSyncStatus
          qboAccountingIntegrationSync {
            id
            accountingIntegrationType
            syncedAt
            expenseId
          }
      }
    }
  }
`);

interface UseGetAuthorizationsAndTransactionsProps {
  customerId: string;
  pageNumber: number;
  pageSize: number;
  excludeChildren: boolean;
  filters: TransactionFiltersInput;
  onCompleted: (data: GetAuthorizationsAndTransactionsQuery) => void;
}

export const useGetAuthorizationsAndTransactions = ({
  customerId,
  pageNumber,
  pageSize,
  excludeChildren,
  filters,
  onCompleted,
}: UseGetAuthorizationsAndTransactionsProps) => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_AUTHORIZATIONS_AND_TRANSACTIONS,
    {
      variables: {
        customerId,
        pageNumber,
        pageSize,
        excludeChildren,
        filters,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted,
    }
  );

  return { data, loading, error, refetch, fetchMore };
};

const SUBSCRIPTION_ON_SYNC_QBO_TXN = gql(`
  subscription OnSyncQboTxnTable($companySlug: String!) {
    onSyncQboTxn(resourceId: $companySlug, resourceType: "company") {
      resourceId
      resourceType
    }
  }
`);

export const useTransactionFeedSyncSubscription = ({
  companySlug,
  onData,
}: {
  companySlug: string;
  onData: () => void;
}) => {
  useSubscription(SUBSCRIPTION_ON_SYNC_QBO_TXN, {
    variables: { companySlug },
    onData,
  });
};

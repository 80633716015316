import { useState } from 'react';
import { RoundButton } from 'components/atoms/Buttons';
import { PlaidLink } from 'components/widgets/PlaidLink';

interface ConnectThroughPlaidProps {
  setCurrentStep: Function;
  setPlaidDetails: Function;
}

const ConnectThroughPlaid = ({ setCurrentStep, setPlaidDetails }: ConnectThroughPlaidProps) => {
  const [showPlaidError, setShowPlaidError] = useState(false);

  const handleConnectAccount = (publicToken: string, accountId: string) => {
    setPlaidDetails({
      publicToken,
      accountId,
    });
    setCurrentStep(2);
  };

  if (showPlaidError) {
    return (
      <>
        <p>
          Plaid was unable to establish a secure connection. This could happen for a variety of
          reasons and we're sorry this happened. Please try again. If this happens again send us an
          email (<a href="mailto:help@getzena.com">help@getzena.com</a>) and we'll do our best to
          solve this issue with Plaid.
        </p>
        <RoundButton
          text="Try again"
          className="m-auto mt-4"
          onClick={() => {
            setShowPlaidError(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="h-12">
        <PlaidLink
          accountType="transactionFeed"
          onConnectAccount={handleConnectAccount}
          onFailure={() => {
            setShowPlaidError(true);
          }}
        />
      </div>
      <h4>Connecting to Plaid...</h4>
    </>
  );
};

export default ConnectThroughPlaid;

import { CardDetails, CardDetailsProps } from './CardDetails';
import { Drawer } from 'components/atoms/Drawer';
import { motion } from 'framer-motion';

interface Props extends Omit<CardDetailsProps, 'className'> {
  isVisible: boolean;
  cardId: string;
}

export const CardDetailsDrawerContent = ({ isVisible, onClose, cardId }: Props) => {
  return (
    <Drawer
      isVisible={isVisible}
      className="flex flex-col px-4 md:h-[calc(100%-76px)] md:mt-[76px] relative"
      onClose={onClose}
    >
      <CardDetails onClose={onClose} className="overflow-y-auto pt-20 pb-32" cardId={cardId} />
      <motion.div
        className="absolute bottom-0 left-0 w-full py-5 px-6 border-t border-primary-400 bg-white grid gap-2"
        style={{
          boxShadow: '0px 4px 22px 0px #00000026',
        }}
        layout
      ></motion.div>
    </Drawer>
  );
};

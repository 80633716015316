import { useQuery } from '@apollo/client';
import { CustomerTeamMember } from '__generated__/graphql';
import { Fragment, useEffect, useState } from 'react';
import { QUERY_GET_CUSTOMER_TEAM_MEMBERS } from './gql';
import { Combobox } from '@headlessui/react';
import { useProjectTeamMembers } from './TeamMembersProvider';
import { TeamMemberProfile } from './TeamMemberProfile';
import { ChevronDown } from 'lucide-react';
import { cn } from 'utils';

const comboboxWindowClasses =
  'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm';
interface AddTeamMembersFormProps {
  customerId: string;
}
const AddTeamMembersForm = ({ customerId }: AddTeamMembersFormProps) => {
  const [query, setQuery] = useState('');
  const [allUsers, setAllUsers] = useState<[] | CustomerTeamMember[]>([]);

  const { projectTeamMembers, addTeamMember } = useProjectTeamMembers();

  const { loading, data } = useQuery(QUERY_GET_CUSTOMER_TEAM_MEMBERS, {
    variables: {
      customerId,
    },
  });

  const handleAddTeamMember = async (userId: string) => {
    const person = filteredPeople.find((person) => person.userId === userId)!;
    addTeamMember(person);
    setQuery('');
  };
  useEffect(() => {
    if (data?.getCustomerTeamMembers) {
      setAllUsers(data.getCustomerTeamMembers);
    }
  }, [loading]);

  const people = allUsers.filter(
    (person) => !projectTeamMembers?.some((teamMember) => teamMember.userId === person.userId)
  );
  const fullName = ({
    first,
    last,
  }: {
    first?: string | null | undefined;
    last?: string | null | undefined;
  }): string => {
    return `${first ?? ''} ${last ?? ''}`;
  };
  const filteredPeople =
    query === '' && !loading
      ? people
      : people.filter((person) => {
          return fullName(person.fullName).toLowerCase().includes(query.toLowerCase());
        });
  return (
    <Combobox
      as={Fragment}
      immediate
      value={query}
      onChange={handleAddTeamMember}
      onClose={() => setQuery('')}
    >
      <div className="relative">
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md border-0 focus:border-accent bg-white py-3 pl-5 pr-12 text-primary-600 ring-1 ring-inset ring-primary-400 sm:text-base sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search team members"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDown />
          </Combobox.Button>
        </div>
        {filteredPeople.length > 0 && (
          <Combobox.Options className={cn(comboboxWindowClasses)}>
            {loading && <div>Loading...</div>}
            {!loading &&
              filteredPeople.length &&
              filteredPeople.map((person) => (
                <Combobox.Option
                  key={person.userId}
                  value={person.userId}
                  className={cn(
                    'relative select-none py-2 pl-3 pr-9 cursor-pointer hover:bg-tertiary-800 text-gray-900'
                  )}
                >
                  <TeamMemberProfile member={person} />
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
        {query !== '' && filteredPeople.length === 0 && (
          <div className={cn(comboboxWindowClasses)}>
            <p className="p-4 text-base text-gray-500">No team members found.</p>
          </div>
        )}
      </div>
    </Combobox>
  );
};

export default AddTeamMembersForm;

import { useActiveCompany } from 'providers/ActiveCompany';
import { useTransactionDetailsContext } from '../../state';
import {
  useChildTransactionDetails,
  useIntuitAppConnectionStatus,
  useIsAccountingTagRequired,
} from './data';
import { AssignContainer, AssignHeaderContainer } from '../lib';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { RoundButton } from 'components/atoms/Buttons';
import { TransactionSplitDetails } from '../TransactionSplitDetails';
import { formatCentsToDollarsString } from 'utils';
import { NonChildTransactionDetails } from '../NoChildTransactionDetails';
import { EditSplits } from './EditSplits';
import { Pencil, Split } from 'lucide-react';

export const AssignParentTransaction = ({
  showMissingRequirements,
}: {
  showMissingRequirements: boolean;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { state, dispatch } = useTransactionDetailsContext();

  const { isAccountingTagRequired, loading: companySettingsLoading } = useIsAccountingTagRequired({
    companySlug: activeCompanySlug,
  });

  const { transaction, loading } = useChildTransactionDetails({
    transactionId: state.transactionId,
    activeCompanySlug,
    onCompleted: (data) => {
      if (data.getTransaction) {
        dispatch({
          type: 'POPULATE_TAGS_AND_SPLITS',
          payload: {
            accountingTag: data.getTransaction.accountingTag
              ? {
                  id: data.getTransaction.accountingTag.value ?? '',
                  name: data.getTransaction.accountingTag.tagName,
                }
              : null,
            rooms: data.getTransaction.rooms,
            categories: data.getTransaction.categories,
            invoiceNumber: data.getTransaction.poCodeTag?.value ?? '',
            projectId: data.getTransaction.projectId ?? '',
            missingFields: data.getTransaction.missingFields || undefined,
            splits:
              data.getTransaction.childTransactions?.map((childTransaction) => {
                return {
                  transactionId: childTransaction.id,
                  accountingTag: childTransaction.accountingTag
                    ? {
                        id: childTransaction.accountingTag.value ?? '',
                        name: childTransaction.accountingTag.tagName,
                      }
                    : null,
                  rooms: childTransaction.rooms,
                  categories: childTransaction.categories,
                  invoiceNumber: childTransaction.poCodeTag?.value ?? '',
                  projectId: childTransaction.projectId ?? '',
                  amount: childTransaction.amountWithDirection,
                };
              }) ?? [],
          },
        });
      }
    },
  });

  const { isIntuitAppConnectedAndActive } = useIntuitAppConnectionStatus({
    companySlug: activeCompanySlug,
  });

  if (loading || !transaction || companySettingsLoading) {
    return (
      <AssignContainer>
        <AssignHeaderContainer>
          <RectangleSkeleton height="large" width="medium" />
        </AssignHeaderContainer>
        <RectangleSkeleton className="h-[300px]" width="full" />
      </AssignContainer>
    );
  }

  const hasChildrenTransactions =
    transaction.childTransactions && transaction.childTransactions?.length > 0;

  return (
    <AssignContainer>
      <AssignHeaderContainer>
        {state.isEditingSplits && (
          <div className="flex gap-4">
            <RoundButton
              variant="primary"
              onClick={() =>
                dispatch({
                  type: 'ADD_SPLIT',
                })
              }
            >
              Add another split <Split className="fill-white" />
            </RoundButton>
          </div>
        )}

        {!state.isEditingSplits && hasChildrenTransactions && (
          <RoundButton
            variant="secondary"
            onClick={() =>
              dispatch({
                type: 'UPDATE_EDITING_SPLITS',
                payload: true,
              })
            }
          >
            Edit splits <Pencil />
          </RoundButton>
        )}

        {!state.isEditingSplits && !hasChildrenTransactions && (
          <RoundButton
            variant="secondary"
            onClick={() => {
              if (state.splits.length === 0) {
                dispatch({
                  type: 'ADD_SPLIT',
                });
              }
              dispatch({
                type: 'UPDATE_EDITING_SPLITS',
                payload: true,
              });
            }}
          >
            Split <Split />
          </RoundButton>
        )}
      </AssignHeaderContainer>

      {state.isEditingSplits && (
        <EditSplits
          transactionTotalInCents={transaction.amountWithDirection}
          hasActiveQboConnection={isIntuitAppConnectedAndActive}
          showMissingRequirements={showMissingRequirements}
        />
      )}

      {!state.isEditingSplits &&
        hasChildrenTransactions &&
        transaction.childTransactions?.map((childTransaction, index) => {
          const isSplitMissingAccountingTag =
            isIntuitAppConnectedAndActive && !childTransaction.accountingTag?.tagName;

          const childTransactionMutableState = state.splits.find(
            (split) => split.transactionId === childTransaction.id
          );

          return (
            <TransactionSplitDetails
              key={childTransactionMutableState?.transactionId ?? index}
              splitIndex={index + 1}
              formattedAmount={formatCentsToDollarsString(childTransaction.amountWithDirection)}
              projectTitle={childTransaction.projectName ?? 'N/A'}
              projectRooms={childTransaction.rooms}
              projectCategories={childTransaction.categories}
              invoiceNumber={childTransaction.poCodeTag?.value ?? ''}
              status={
                isSplitMissingAccountingTag && isAccountingTagRequired ? 'NEEDS_ATTENTION' : 'DONE'
              }
              showMissingRequirements={showMissingRequirements}
              hasActiveQboConnection={isIntuitAppConnectedAndActive}
              onChangeAccountingTag={(tag) => {
                if (childTransactionMutableState) {
                  dispatch({
                    type: 'UPDATE_SPLIT',
                    payload: {
                      ...childTransactionMutableState,
                      accountingTag: tag
                        ? {
                            id: tag?.accountingTagId,
                            name: tag?.accountingTagName,
                          }
                        : null,
                    },
                  });
                }
              }}
              isAccountingTagRequiredForQbo={isAccountingTagRequired}
              selectedAccountingTagName={childTransactionMutableState?.accountingTag?.name ?? ''}
            />
          );
        })}

      {!state.isEditingSplits && !hasChildrenTransactions && (
        <NonChildTransactionDetails
          projectId={state.projectId}
          onChangeProjectId={(updatedProjectId) =>
            dispatch({
              type: 'UPDATE_PROJECT',
              payload: updatedProjectId,
            })
          }
          showMissingRequirements={showMissingRequirements}
          projectCategories={state.categories}
          projectRooms={state.rooms}
          invoiceNumber={state.invoiceNumber ?? ''}
          hasActiveQboConnection={isIntuitAppConnectedAndActive}
          missingFields={state.missingFields}
          onChangeInvoiceNumber={(updatedInvoiceNumber) =>
            dispatch({
              type: 'UPDATE_INVOICE_NUMBER',
              payload: updatedInvoiceNumber,
            })
          }
          onChangeProjectRooms={(newRoomsList) =>
            dispatch({
              type: 'UPDATE_PROJECT_ROOMS',
              payload: newRoomsList,
            })
          }
          onChangeProjectCategories={(newCategoriesList) =>
            dispatch({
              type: 'UPDATE_PROJECT_CATEGORIES',
              payload: newCategoriesList,
            })
          }
          onChangeAccountingTag={(tag) => {
            dispatch({
              type: 'UPDATE_ACCOUNTING_TAG',
              payload: tag
                ? {
                    id: tag.accountingTagId,
                    name: tag.accountingTagName,
                  }
                : null,
            });
          }}
          selectedAccountingTagName={state.accountingTag?.name ?? ''}
        />
      )}
    </AssignContainer>
  );
};

import { hasRepaymentConnectionCompleteFromLocalStorage } from 'hooks/plaid';

export const ProjectVirtualCardButtonText = ({ isLuxeCompany }: { isLuxeCompany: boolean }) => {
  const hasRepaymentConnection = hasRepaymentConnectionCompleteFromLocalStorage();
  if (isLuxeCompany) {
    if (hasRepaymentConnection) {
      return <>Welcome to Zena! You are now able to create a card for this project.</>;
    } else {
      return (
        <>
          You're almost done! You just need to link a payment account to get a project virtual card.
        </>
      );
    }
  } else {
    return <>Get the Zena purchasing card and never reconcile another transaction!</>;
  }
};

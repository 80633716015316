import { TextInput } from 'components/atoms/Inputs/TextInput';

const Memo = ({ memo, setMemo }: { memo: string; setMemo: (value: string) => void }) => {
  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-asphalt text-2xl">Add a few notes or details about this purchase</h1>
      </div>
      <div className="mt-6">
        <TextInput
          label="Max 256 characters"
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          maxLength={256}
        />
      </div>
    </>
  );
};

export default Memo;

import { ProgressBar } from 'components/atoms/ProgressBar';
import closeButton from '../../images/closeButton.webp';
import zenaLogo from '../../images/zenaLogo.webp';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

const ProjectCreateLoading = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="bg-ivory">
      <div className="h-[10vh]">
        <div>
          <ProgressBar step={3} numSteps={3} height={2} />
        </div>
        <div className="flex justify-between mt-9 ml-6">
          <img className="object-contain h-6 " src={zenaLogo} alt="Zena Logo" />
          <button onClick={onClose} aria-label="Close dropdown menu" className="outline-accent-600">
            <div className="flex items-center">
              <div className="underline">Close</div>
              <img
                className="w-3 h-3 ml-3.5 mr-9 cursor-pointer"
                src={closeButton}
                alt="Close icon"
              />
            </div>
          </button>
        </div>
      </div>

      <div className="grid h-[90vh] place-content-center">
        <div className="mt-14">
          <div className="flex items-center">
            <LoadingSpinner /> <p className="ml-2">Creating Project ...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreateLoading;

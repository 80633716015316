import { HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { OutlinedWidget } from 'components/atoms/OutlinedWidget';
import { cn, onApolloError } from '../../../../../../utils';
import { ActivationSteps } from '../ActivationSteps';
import { useActiveCompany } from '../../../../../../providers/ActiveCompany';
import { ActivationStepProps } from '../ActivationSteps';
import { Link } from 'react-router-dom';
import { RoundButton } from 'components/atoms/Buttons';
import OAuthPopup from 'components/atoms/OAuthPopup';
import { RectangleSkeleton } from 'components/atoms/Skeletons';
import { useAppState } from '../../../../../../stores/UserStore';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Modal } from 'components/atoms/Modal';
import {
  RequireAccountingTag,
  RequireMemoSwitch,
  RequireReceiptsSwitch,
  useCreateIntuitAppConnection,
  useGetAuthorizeUrl,
  useGetIntuitAppConnection,
  useLazyQueryListQBOAccounts,
  useUpdateIntuitAppAccountId,
  useUpdateIntuitAppConnectionIsActive,
} from 'components/widgets/Quickbooks';
import { Calendar } from 'components/atoms/Inputs/Calendar';
import moment from 'moment-timezone';
import { StatusIndicator } from 'components/atoms/StatusIndicator';

interface ActivationStepsProps extends HTMLAttributes<HTMLDivElement> {}

export const ActivationStepsWidget = ({ ...props }: ActivationStepsProps) => {
  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { appConnection, loading } = useGetIntuitAppConnection({ companySlug });
  const isDisabled = appConnection === null || !!appConnection?.disconnectedAt;

  return (
    <OutlinedWidget {...props}>
      <div className="border-b pb-5 mb-7 flex items-center">
        <h3 className="text-black text-xl">Activation</h3>
      </div>
      <div className="font-bold pb-4">
        <p>Complete these steps (in order) to activate your QuickBooks Integration:</p>
      </div>
      {loading ? (
        <div className="space-y-2">
          {[...Array(5)].map((_, i) => (
            <div key={i}>
              <RectangleSkeleton height="large" />
            </div>
          ))}
        </div>
      ) : (
        <ActivationSteps displayStepNumbers={true}>
          <StepOne
            isCompleted={appConnection !== null && !appConnection.disconnectedAt}
            setError={setErrorMsg}
          />
          <StepTwo
            isConnected={!!appConnection && !isDisabled}
            isCompleted={appConnection !== null && !appConnection.disconnectedAt}
            setError={setErrorMsg}
          />
          <StepThree
            isCompleted={!!appConnection?.qboAccountId}
            disabled={isDisabled}
            setError={setErrorMsg}
          />
          <StepFour
            isCompleted={!!appConnection?.startedAt}
            setError={setErrorMsg}
            disabled={isDisabled}
          />
        </ActivationSteps>
      )}
    </OutlinedWidget>
  );
};

interface ExtendedStepProps extends Omit<ActivationStepProps, 'title' | 'children'> {
  setError: (error: string) => void;
}

const StepOne = (props: ExtendedStepProps) => {
  return (
    <ActivationSteps.Step title="1. Add Zena to your QuickBooks Bank Feed" {...props}>
      <StepPanelContainer>
        <p>
          Watch a short{' '}
          <Link to="/" className="underline">
            video
          </Link>{' '}
          of these steps, or send{' '}
          <a
            href="https://support.getzena.com/article/86-how-does-the-quickbooks-online-integration-work"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            this document
          </a>{' '}
          to your Bookkeeper.
        </p>
        <ol className="list-decimal pb-6 space-y-2 px-8 mt-8">
          <li>Log into your QuickBooks Online (QBO) account.</li>
          <li>
            Go to the{' '}
            <a
              href="https://app.qbo.intuit.com/app/banking"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Bank Feed
            </a>{' '}
            page in QuickBooks.
          </li>
          <li>
            Click <strong>Link Account</strong>, or <strong>Connect Account</strong> if you have a
            new QuickBooks account.
          </li>
          <li>Type “Zena” in the search bar.</li>
          <li>Click on the Zena result.</li>
          <li>
            Sign into your Zena account using the phone number associated with your Zena account.
          </li>
          <li>
            Choose the Zena account you want added to QuickBooks.
            <ul className="list-disc px-8 py-4 text-gray-600/80">
              <li>
                This is the Zena account, and not your individual Zena cards. If you’re a bookkeeper
                who services multiple Zena customers, then you’ll want to make sure you’re adding
                the right Zena account.
              </li>
            </ul>
          </li>
          <li>
            Be sure the account is added as a new Credit Card account. This is to ensure Zena
            transactions show up as credit card transactions.
          </li>
          <li>
            Click <strong>Connect</strong>.
          </li>
        </ol>
        <p className="font-bold">
          Your Zena transactions will now appear automatically in your QuickBooks bank feed.
        </p>
      </StepPanelContainer>
    </ActivationSteps.Step>
  );
};

const StepTwo = ({
  setError,
  isConnected = false,
  ...props
}: ExtendedStepProps & { isConnected?: boolean }) => {
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const { authorizeUrl } = useGetAuthorizeUrl({ companySlug });

  const { createIntuitAppConnection } = useCreateIntuitAppConnection();

  const onCode = async (response: string): Promise<void> => {
    const { code, realmId, csrfToken } = JSON.parse(response);
    try {
      createIntuitAppConnection({
        variables: { companySlug, code, realmId, csrfToken },
        refetchQueries: ['GetIntuitAppConnection'],
        onError: (error) => setError(error.message),
      });
    } catch (e) {
      console.error(e);
    } finally {
      window.localStorage.removeItem('qboOAuthResponse');
    }
  };
  return (
    <ActivationSteps.Step title="2. Allow Zena to sync data to your QuickBooks account" {...props}>
      <StepPanelContainer className="flex justify-center items-center">
        <OAuthPopup
          url={authorizeUrl}
          onCode={onCode}
          title="Connect to QuickBooks® Online"
          width={700}
          height={700}
        >
          {isConnected ? (
            <StatusIndicator
              status="active"
              className="border px-6 whitespace-nowrap"
              text="Account connected"
            />
          ) : (
            <button className="bg-success rounded px-8 py-2 text-white">
              Connect to quickbooks
            </button>
          )}
        </OAuthPopup>
      </StepPanelContainer>
    </ActivationSteps.Step>
  );
};

const StepThree = ({
  disabled,
  setError,
  ...props
}: {
  disabled: boolean;
} & ExtendedStepProps) => {
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined | null>(null);
  const [potentialZenaAccountId, setPotentialZenaAccountNameId] = useState<string | null>();
  const [selectedAccountName, setSelectedAccountName] = useState<string | undefined>(undefined);
  const [potentialZenaAccountName, setPotentialZenaAccountName] = useState<string | null>();
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';

  const { appConnection } = useGetIntuitAppConnection({
    companySlug,
    onError: (error) => setError(error.message),
  });
  const {
    listQBOAccounts,
    qboAccounts,
    loading: listAccountsLoading,
    client,
  } = useLazyQueryListQBOAccounts({ companySlug });
  const { updateIntuitAppAccountId, loading: updateAccountIdLoading } =
    useUpdateIntuitAppAccountId();

  useEffect(() => {
    if (accountModalOpen || appConnection?.qboAccountId) {
      listQBOAccounts({
        onCompleted: (res) => {
          const accounts = res?.listQBOLiabilityAccounts ?? [];
          const zenaAccount = accounts.find((account) =>
            account.accountName.toLowerCase().includes('zena')
          );
          if (zenaAccount) {
            setPotentialZenaAccountNameId(zenaAccount.accountId);
            setPotentialZenaAccountName(zenaAccount.accountName);
          }
        },
        onError: (error) => {
          client.refetchQueries({ include: ['GetIntuitAppConnection'] });
          onApolloError(
            error,
            (errorMessage: string, errorType: string) => {
              if (errorType === 'TokenRefreshError') {
                setError(
                  'Your Quickbooks connection has become invalid. Please visit settings to reconnect.'
                );
              } else {
                setError(errorMessage);
              }
            },
            ['TokenRefreshError']
          );
        },
      });
    }
  }, [accountModalOpen, appConnection?.qboAccountId]);

  useEffect(() => {
    setSelectedAccountId(appConnection?.qboAccountId);
    const qboAccountName = qboAccounts.find(
      (account) => account.accountId === appConnection?.qboAccountId
    )?.accountName;
    setSelectedAccountName(qboAccountName);
  }, [appConnection?.qboAccountId, qboAccounts.toString()]);

  const handleSelectAccount = (qboAccountId: string) => {
    updateIntuitAppAccountId({
      variables: { companySlug, qboAccountId },
      refetchQueries: ['GetIntuitAppConnection'],
      onError: (error) => setError(error.message),
    });
    setAccountModalOpen(false);
  };

  const handleViewOtherAccounts = () => {
    setPotentialZenaAccountNameId(null);
    setPotentialZenaAccountName(null);
  };

  return (
    <ActivationSteps.Step title="3. Pair Zena to the right Bank Feed account" {...props}>
      <StepPanelContainer>
        <p>
          In order for this integration to work, Zena needs to match up exactly with the
          transactions in your QuickBooks bank feed for Zena. To do this, we need you to select the
          correct Zena account to pair.
        </p>

        {selectedAccountId ? (
          <div className="flex items-center text-gray-500">
            <div className="mr-2 font-bold">Selected account:</div>
            <div>{selectedAccountName}</div>
          </div>
        ) : (
          <RoundButton
            variant="tertiary"
            disabled={disabled}
            onClick={() => setAccountModalOpen(true)}
          >
            Pair Zena Accounts
          </RoundButton>
        )}
        <Modal
          show={accountModalOpen}
          onClose={() => setAccountModalOpen(false)}
          className="max-h-1/2"
        >
          <div className="overflow-auto max-h-96">
            {listAccountsLoading || updateAccountIdLoading ? (
              <div className="h-96 w-96 flex justify-center items-center">
                <LoadingSpinner />
              </div>
            ) : potentialZenaAccountId && !selectedAccountId ? (
              <div>
                <h3 className="text-center">Match Found!</h3>
                <p className="text-center text-gray-500">
                  We found a Zena account in your QuickBooks Online account
                </p>
                <p className="text-xl w-full text-center mt-8">{potentialZenaAccountName}</p>
                <div className="flex w-full justify-center gap-4 mt-12">
                  <RoundButton onClick={() => handleSelectAccount(potentialZenaAccountId || '')}>
                    Confirm
                  </RoundButton>
                  <RoundButton variant="secondary" onClick={handleViewOtherAccounts}>
                    View other accounts
                  </RoundButton>
                </div>
              </div>
            ) : (
              <ul>
                {qboAccounts.map((account) => {
                  return (
                    <li key={account.accountId} className="flex items-center border-b">
                      <div className="text-left">{account.accountName}</div>
                      <div className="ml-auto flex items-center">
                        <div className="p-2 sm:p-12">
                          <RoundButton
                            variant="primary"
                            onClick={() => handleSelectAccount(account.accountId)}
                          >
                            Select
                          </RoundButton>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Modal>
        {disabled && <DisabledBlurredContainer />}
      </StepPanelContainer>
    </ActivationSteps.Step>
  );
};

const StepFour = ({
  setError,
  disabled,
  ...props
}: {
  disabled: boolean;
} & ExtendedStepProps) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const { activeCompany } = useActiveCompany();
  const companySlug = activeCompany?.slug ?? '';
  const [setSuccessMsg] = useAppState((state) => [state.setSuccessMsg, state.setErrorMsg]);
  const { appConnection } = useGetIntuitAppConnection({
    companySlug,
  });

  const { updateIntuitAppConnectionIsActive, loading: updateAppConnectionLoading } =
    useUpdateIntuitAppConnectionIsActive();

  return (
    <ActivationSteps.Step title="4. Configure and Activate Quickbooks" {...props}>
      <StepPanelContainer>
        <p>Choose the settings that are right for you and your business and then activate.</p>
        <div className="flex flex-col gap-6">
          <RequireReceiptsSwitch />
          <RequireMemoSwitch />
          <div className="space-y-4">
            <RequireAccountingTag disabled={disabled} />
          </div>
        </div>
        <div className="flex">
          <div>
            <div className="font-medium">Activation date</div>
            <div>
              <p>
                Choose the start date that Zena will sync transactions from. Transactions before
                this date will not be pushed to QuickBooks.
              </p>
              <p className="text-gray-600/90">
                Typically, Zena members go back to the start of the current accounting period.
              </p>
              <p></p>
            </div>
            <div className="flex items-center text-gray-600/90 mt-4">
              <div className="mr-2 font-bold">Current activation date:</div>
              <div>{moment(startDate).format('MM/DD/YYYY')}</div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="border rounded-lg overflow-hidden">
              <Calendar
                initialValue={startDate}
                onChange={(v) => setStartDate(v as Date | null)}
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-8">
            {!appConnection?.qboAccountId && (
              <p className="text-danger text-sm mb-2">Complete step #3 to activate</p>
            )}
            <RoundButton
              disabled={disabled || !appConnection?.qboAccountId || !!appConnection.startedAt}
              isLoading={updateAppConnectionLoading}
              onClick={() =>
                updateIntuitAppConnectionIsActive({
                  variables: {
                    companySlug,
                    isActive: true,
                    startDate: startDate?.toISOString() ?? new Date().toISOString(),
                  },
                  onCompleted: () => setSuccessMsg(`Success! Your QBO integration is now active.`),
                  onError: (error) => setError(error.message),
                })
              }
            >
              Activate
            </RoundButton>
          </div>
        </div>
        {disabled && <DisabledBlurredContainer />}
      </StepPanelContainer>
    </ActivationSteps.Step>
  );
};

interface StepPanelContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const StepPanelContainer = ({ children, className, ...props }: StepPanelContainerProps) => (
  <div className={cn('space-y-4 px-6 py-4 relative', className)} {...props}>
    {children}
  </div>
);

const DisabledBlurredContainer = () => (
  <div className="absolute inset-0 backdrop-blur-sm bg-white/30 flex items-center justify-center">
    <div className="bg-white font-bold">Complete step #2 before moving forward</div>
  </div>
);

import { GenericLoader } from 'components/atoms/Loader';
import { companyRole } from 'hooks/access';
import useCustomAuth from 'hooks/useCustomAuth';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useActiveCompany } from './ActiveCompany';
import { Role } from '__generated__/graphql';

interface RequireAuthProps {
  children: ReactNode;
  allowRoleAccess?: Role[];
}

export function RequireAuth({
  children,
  allowRoleAccess = [],
}: RequireAuthProps): JSX.Element | null {
  const location = useLocation();
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { isLoading, user } = useCustomAuth();

  const role = companyRole(activeCompanySlug);
  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-32">
        <GenericLoader />
      </div>
    );
  }

  if (!user) {
    return <Navigate to={'/login'} state={{ from: location }} replace />;
  }

  if (!allowRoleAccess.length || (role && allowRoleAccess.includes(role))) {
    return <>{children}</>;
  } else {
    return <Navigate to="/dashboard" replace />;
  }
}

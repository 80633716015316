import { useEffect, useState } from 'react';
import { OutlinedWidgetWithSwitch } from './OutlinedWidgetWithSwitch';
import { useMutation } from '@apollo/client';
import { onApolloError } from '../../../../../../utils';
import { MUTATION_UPDATE_NOTIFICATION_SETTINGS } from './data';
import { SettingsSwitchProps } from './index';
import { SelectField } from '@aws-amplify/ui-react';

export const LowBudgetAlert = ({
  loading,
  customerId,
  setErrorMsg,
  initialValue,
  defaultEnabled = false,
}: SettingsSwitchProps<number>) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);
  useEffect(() => {
    setIsEnabled(defaultEnabled);
  }, [defaultEnabled]);
  const [value, setValue] = useState(initialValue || 0);
  const [updateSettings, { loading: isUpdating, called: updated }] = useMutation(
    MUTATION_UPDATE_NOTIFICATION_SETTINGS,
    {
      onError: (error) => onApolloError(error, setErrorMsg, ['UpdateNotificationSettingsFailed']),
    }
  );
  const onUpdate = (checked: boolean, value: number) => {
    updateSettings({
      variables: {
        customerId,
        notificationSettings: {
          projectBudget: checked ? value : null,
        },
      },
    });
  };

  return (
    <OutlinedWidgetWithSwitch
      title="Low budget alert"
      loading={loading}
      checked={isEnabled}
      isUpdating={isUpdating}
      hasUpdated={updated}
      onChange={(checked) => {
        setIsEnabled(checked);
        onUpdate(checked, value);
      }}
    >
      <div className="flex items-center flex-col md:flex-row">
        <p>Triggers when less than</p>
        <div className="m-2">
          <SelectField
            label="Trigger Percentage Amount"
            labelHidden
            value={value.toString()}
            onChange={(e) => {
              const v = parseInt(e.target.value);
              setValue(v);
              onUpdate(isEnabled, v);
            }}
            disabled={!isEnabled}
          >
            <option value={10}>10%</option>
            <option value={25}>25%</option>
            <option value={50}>50%</option>
          </SelectField>
        </div>
        <p>of a project's budget is remaining</p>
      </div>
    </OutlinedWidgetWithSwitch>
  );
};

import { useActiveCompany } from 'providers/ActiveCompany';
import { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetProject } from './data';
import { RoundButton } from 'components/atoms/Buttons';
import { PageTitle } from 'components/atoms/PageTitle';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { cn } from 'utils';
import { ProjectReviewPage } from './ProjectReview';
import { TabLink, TabsContainer } from 'components/atoms/Tab';
import { companyRole } from 'hooks/access';

export const ProjectLayout = ({
  pageName,
  children,
  onClickDownload,
  onClickImportTransactions,
  className = '',
}: {
  pageName: 'OVERVIEW' | 'TRANSACTIONS' | 'SETTINGS';
  children: ReactNode;
  onClickDownload?: () => void;
  onClickImportTransactions?: () => void;
  className?: string;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { projectId } = useParams();

  const { project, loading, refetchProject } = useGetProject({
    companySlug: activeCompanySlug,
    projectId,
  });

  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';

  if (!project || loading || !projectId) {
    return (
      <>
        <PageTitle hidden text="Loading project..." />
        <div className="mt-52 grid place-items-center">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  if (project.rejectedAt) {
    return (
      <>
        <PageTitle hidden text="Loading project..." />
        <div className="pt-4 px-4 lg:pt-8 lg:px-10">
          <BackButton />
          <div className="flex h-full w-full flex-col items-center pt-[20%] gap-4">
            <h1>Project Rejected</h1>
            <p className="text-center">
              This project request has been rejected. Please contact your administrator if you think
              this is a mistake!
            </p>
          </div>
        </div>
      </>
    );
  }

  if (project && !project?.approved && !project.rejectedAt) {
    return (
      <>
        <PageTitle hidden text={`Review ${project.name}`} />
        <div className="pt-4 px-4 lg:pt-8 lg:px-10 grid gap-4">
          <BackButton />
          <ProjectReviewPage projectId={projectId} onApproveProject={() => refetchProject()} />
        </div>
      </>
    );
  }

  return (
    <>
      <PageTitle hidden text={project.name} />
      <div className="pt-4 px-4 lg:pt-8 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
        <section className="grid gap-2">
          <BackButton />
          <div className="flex flex-col md:flex-row md:justify-between gap-8 align-baseline">
            <h1 className="text-xl lg:text-4xl">{project.name}</h1>
            {pageName === 'TRANSACTIONS' && onClickDownload && onClickImportTransactions && (
              <div className="flex flex-col md:flex-row gap-4 md:items-start">
                <RoundButton variant="secondary" icon="download" onClick={onClickDownload}>
                  Download
                </RoundButton>
                {!isBookkeeper && (
                  <RoundButton variant="primary" onClick={onClickImportTransactions}>
                    Import transactions
                  </RoundButton>
                )}
              </div>
            )}
          </div>
        </section>

        <section className="flex flex-col grow overflow-hidden">
          <TabsContainer>
            <TabLink isActive={pageName === 'OVERVIEW'} href={`/projects/${projectId}`}>
              Overview
            </TabLink>
            <TabLink
              isActive={pageName === 'TRANSACTIONS'}
              href={`/projects/${projectId}/transactions`}
            >
              <div className="flex items-center">
                <span className="mr-2">Transactions</span>
              </div>
            </TabLink>
            <TabLink isActive={pageName === 'SETTINGS'} href={`/projects/${projectId}/settings`}>
              Settings
            </TabLink>
          </TabsContainer>
          <section className={cn('grow overflow-y-auto pb-8', className)}>
            <>{children}</>
          </section>
        </section>
      </div>
    </>
  );
};

const BackButton = () => {
  return (
    <Link to="/projects" className="w-max">
      <div className="flex gap-2 underline">Back to all projects</div>
    </Link>
  );
};

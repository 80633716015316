import { useFlags as ldUseFlags } from 'launchdarkly-react-client-sdk';

export interface ZenaFeatureFlags extends Record<string, boolean> {
  useStripe: boolean;
  cardManagement: boolean;
}

export const useFlags = () => {
  return ldUseFlags<ZenaFeatureFlags>();
};

import { gql } from '__generated__/gql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

const GET_INTUIT_APP_CONNECTION_QUERY = gql(`
  query GetIntuitAppConnection($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
      disconnectedAt
      connectionInvalidatedAt
    }
  }
`);

export const useGetIntuitAppConnection = ({
  companySlug,
  onError,
  onCompleted,
}: {
  companySlug: string;
  onError?: (error: any) => void;
  onCompleted?: () => void;
}) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_QUERY, {
    variables: { companySlug: companySlug },
    onError,
    onCompleted,
  });

  return {
    appConnection: data?.getIntuitAppConnection ?? null,
    loading,
    error,
  };
};

const LIST_ACCOUNTS_QUERY = gql(`
  query ListQBOAccountsForSelection($companySlug: String!) {
    listQBOLiabilityAccounts(companySlug: $companySlug) {
      accountId
      accountName
    }
  }
`);

export const useListQBOAccounts = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(LIST_ACCOUNTS_QUERY, {
    variables: { companySlug },
  });

  return {
    qboAccounts: data?.listQBOLiabilityAccounts ?? [],
    loading,
    error,
  };
};

export const useLazyQueryListQBOAccounts = ({ companySlug }: { companySlug: string }) => {
  const [listQBOAccounts, { data, loading, error, client }] = useLazyQuery(LIST_ACCOUNTS_QUERY, {
    variables: { companySlug },
  });

  return {
    listQBOAccounts,
    qboAccounts: data?.listQBOLiabilityAccounts ?? [],
    loading,
    error,
    client,
  };
};

const UPDATE_INTUIT_APP_ACCOUNT_ID_MUTATION = gql(`
  mutation UpdateIntuitAppAccountIdMutation($companySlug: String!, $qboAccountId: String!) {
    updateIntuitAppAccountId(companySlug: $companySlug, qboAccountId: $qboAccountId) {
      id
      qboAccountId
    }
  }
`);

export const useUpdateIntuitAppAccountId = () => {
  const [updateIntuitAppAccountId, { data, loading, error }] = useMutation(
    UPDATE_INTUIT_APP_ACCOUNT_ID_MUTATION
  );

  return {
    updateIntuitAppAccountId,
    data,
    loading,
    error,
  };
};

const UPDATE_INTUIT_APP_CONNECTION_IS_ACTIVE_MUTATION = gql(`
  mutation UpdateIntuitAppConnectionIsActiveMutation($companySlug: String!, $isActive: Boolean!, $startDate: String!) {
    updateIntuitAppConnectionIsActive(companySlug: $companySlug, isActive: $isActive, startDate: $startDate) {
      id
      startedAt
      disconnectedAt
    }
  }
`);

export const useUpdateIntuitAppConnectionIsActive = () => {
  const [updateIntuitAppConnectionIsActive, { data, loading, error }] = useMutation(
    UPDATE_INTUIT_APP_CONNECTION_IS_ACTIVE_MUTATION
  );

  return {
    updateIntuitAppConnectionIsActive,
    data,
    loading,
    error,
  };
};

const REVOKE_INTUIT_APP_CONNECTION_MUTATION = gql(`
  mutation RevokeIntuitAppConnectionMutation($companySlug: String!) {
    revokeIntuitAppConnection(companySlug: $companySlug) {
      id
    }
  }
`);

export const useRevokeIntuitAppConnection = () => {
  const [revokeIntuitAppConnection, { data, loading, error }] = useMutation(
    REVOKE_INTUIT_APP_CONNECTION_MUTATION
  );

  return {
    revokeIntuitAppConnection,
    data,
    loading,
    error,
  };
};

const GET_INTUIT_AUTHORIZE_URL_QUERY = gql(`
  query GetIntuitAuthorizeUrlQuery($companySlug: String!) {
    getIntuitAuthorizeUrl(companySlug: $companySlug) {
      authorizeUrl
    }
  }
`);

export const useGetAuthorizeUrl = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_AUTHORIZE_URL_QUERY, {
    variables: { companySlug: companySlug },
  });

  return {
    authorizeUrl: data?.getIntuitAuthorizeUrl?.authorizeUrl ?? '',
    loading,
    error,
  };
};

const CREATE_INTUIT_APP_CONNECTION_MUTATION = gql(`
  mutation CreateIntuitAppConnectionMutation($companySlug: String!, $code: String!, $csrfToken: String!, $realmId: String!) {
    createIntuitAppConnection(companySlug: $companySlug, code: $code, csrfToken: $csrfToken, realmId: $realmId) {
      message
    }
  }
`);

export const useCreateIntuitAppConnection = () => {
  const [createIntuitAppConnection, { data, loading, error }] = useMutation(
    CREATE_INTUIT_APP_CONNECTION_MUTATION
  );

  return {
    createIntuitAppConnection,
    data,
    loading,
    error,
  };
};

const RECONNECT_INTUIT_APP_CONNECTION_MUTATION = gql(`
  mutation ReconnectIntuitAppConnectionMutation($companySlug: String!, $code: String!, $csrfToken: String!, $realmId: String!) {
      reconnectIntuitAppConnection(companySlug: $companySlug, code: $code, csrfToken: $csrfToken, realmId: $realmId) {
        id
        startedAt
        connectionInvalidatedAt
    }
  }
`);

export const useReconnectIntuitAppConnection = () => {
  const [createIntuitAppConnection, { data, loading, error }] = useMutation(
    RECONNECT_INTUIT_APP_CONNECTION_MUTATION
  );

  return {
    createIntuitAppConnection,
    data,
    loading,
    error,
  };
};

import { cn } from 'utils';
import { NumberInput, NumberInputProps } from '../NumberInput';

export const PercentageInput = ({ className, ...props }: NumberInputProps) => {
  return (
    <div className="relative grid">
      <NumberInput className={cn('pr-9', className)} {...props} />
      <div className="absolute top-0 bottom-0 right-5 flex flex-col justify-center h-full">
        <span className="text-black text-base">%</span>
      </div>
    </div>
  );
};

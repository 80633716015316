import { Fragment, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { X } from 'lucide-react';

export interface SlideOverProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;

  footer?: JSX.Element;
  // locked is used to prevent the user from closing the slide over. Used if you want to force the user to do something before they can close the slide over.
  // Caution: use this sparingly. Most of the time you should allow the user to close the slide over.
  locked?: boolean;
  // backdrop is used to determine what type of backdrop to use. Dark is the default.
  backdrop?: 'blur' | 'dark' | 'none';
}
const SlideOver = ({
  open,
  onClose,
  locked,
  backdrop = 'dark',
  footer,
  children,
}: SlideOverProps) => {
  const _onClose = () => {
    if (!locked) {
      onClose();
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="pointer-events-none relative z-10" onClose={_onClose}>
          {backdrop && backdrop !== 'none' && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={clsx(backdrop === 'blur' ? 'opacity-0' : 'bg-black/0')}
              enterTo={clsx(backdrop === 'blur' ? 'opacity-100' : 'bg-black/30')}
              leave="ease-in duration-150"
              leaveFrom={clsx(backdrop === 'blur' ? 'opacity-100' : 'bg-black/30')}
              leaveTo={clsx(backdrop === 'blur' ? 'opacity-0' : 'bg-black/0')}
            >
              <div
                className={clsx(backdrop === 'blur' && 'backdrop-blur-sm', 'fixed inset-0')}
                aria-hidden="true"
              />
            </Transition.Child>
          )}

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-300 sm:duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Filters
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="focus:ring-lavender-500 relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2"
                                onClick={onClose}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <span className="h-4 w-4">
                                  <X />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">{children}</div>
                      </div>
                      {footer && footer}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SlideOver;

import { Button, RoundButton } from 'components/atoms/Buttons';
import { useGetProjectsForWidget } from './data';
import { Badge } from 'components/atoms/Badge/Badge';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyWidgetCard } from 'components/widgets/cards/EmptyWidgetCard';
import { CardTitle } from 'components/widgets/cards/CardTitle';
import { LoadingWidgetCard } from 'components/widgets/cards/LoadingWidgetCard';
import { useActiveCompany } from 'providers/ActiveCompany';
import { companyRole } from 'hooks/access';
import { ArrowRight } from 'lucide-react';
import { ProjectCard } from 'components/widgets/ProjectCard';

export const ProjectWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const navigate = useNavigate();

  const { projects, loading } = useGetProjectsForWidget({
    customerId: activeCompanySlug,
  });

  const takeThreeProjects = projects?.slice(0, 3);

  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-4 sm:gap-0">
        <div className="flex gap-2">
          <Badge>{(projects?.length ?? 0) > 9 ? '9+' : projects?.length}</Badge>
          <CardTitle>Project Spend</CardTitle>
        </div>
        {!isBookkeeper && (
          <RoundButton
            icon="plus"
            variant="secondary"
            onClick={() => navigate('/projects?new=true')}
            disabled={!!loading}
            className="w-max"
          >
            Create project
          </RoundButton>
        )}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 mt-4">
        {!loading && (
          <>
            {takeThreeProjects?.map((project) => {
              return (
                <ProjectCard
                  key={project.id}
                  onClick={() => navigate(`/projects/${project.id}`)}
                  projectName={project.name}
                  budget={project.budget}
                  remainingBudget={(project.budget || 0) - (project.amountSpent || 0)}
                  amountSpent={project.amountSpent ?? undefined}
                  status={project?.status || 'active'}
                />
              );
            })}
            {(projects?.length ?? 0) < 3 && (
              <EmptyWidgetCard>
                <div className="flex items-center justify-center h-full flex-col gap-2">
                  <RoundButton
                    icon="plus"
                    variant="tertiary"
                    onClick={() => navigate('/projects?new=true')}
                  />
                  <div>Create New Project</div>
                </div>
              </EmptyWidgetCard>
            )}
          </>
        )}

        {loading && (
          <>
            <LoadingWidgetCard />
            <LoadingWidgetCard />
            <LoadingWidgetCard />
          </>
        )}
      </div>

      <div className="mt-4">
        <div className="flex justify-end">
          <Link to="/projects">
            <Button variant="link" className="p-0 no-underline">
              View All
              <ArrowRight className="stroke-accent" size={16} />
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '__generated__';
import { RoundButton, UnderlineButton } from 'components/atoms/Buttons';
import { UnderlineTextInput } from 'components/atoms/Inputs/UnderlineTextInput';
import { PageTitle } from 'components/atoms/PageTitle';
import { ProgressBar } from 'components/atoms/ProgressBar';
import ProjectCreateLoading from './ProjectCreateLoading';
import ProjectSuccess from 'pages/NewProjectForm/ProjectSuccess';
import useCustomAuth from 'hooks/useCustomAuth';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppState } from 'stores/UserStore';
import { isUsingMobile, onApolloError, toCents } from 'utils';
import zenaLogo from '../../images/zenaLogo.webp';
import ProjectUserMultiSelect from './ProjectUserMultiSelect';
import { useActiveCompany } from 'providers/ActiveCompany';
import { ArrowLeft, CornerDownRight, XIcon } from 'lucide-react';

export const MUTATION_CREATE_PROJECT = gql(`
  mutation CreateProject($customerId: String!, $name: String!, $budget: Int!, $users: [String!]!) {
    createProject(customerId: $customerId, name: $name, budget: $budget, users: $users) {
      id
      name
      budget
      cardId
      approved
    }
  }
`);

export const QUERY_GET_CARD = gql(`
  query GetCard($customerId: String!, $cardId: String!) {
    getCard(customerId: $customerId, cardId: $cardId) {
      id
      last4Digits
      name
      type
    }
  }
`);

interface IFormInputs {
  name: string;
  budget: string;
  addTeammates: string;
}

export const NewProjectForm = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { register, getValues, setFocus } = useForm<IFormInputs>();
  const { user } = useCustomAuth();

  const [cardLast4Digits, setCardLast4Digits] = useState<string>('');
  const [errorMsg, setErrorMsg] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);
  const [getCard, { data: getCardData }] = useLazyQuery(QUERY_GET_CARD);
  const location = useLocation();
  const navigate = useNavigate();
  const prevPathname = location.state?.from?.pathname || null;
  const prevSearch = location.state?.from?.search || null;
  const from = prevSearch ? `${prevPathname}${prevSearch}` : prevPathname || `/projects`;

  const isMobile = isUsingMobile();

  const [projectUsers, setProjectUsers] = useAppState((state) => [
    state.projectUsers,
    state.setProjectUsers,
  ]);

  const [createProject, { data: createProjectData, loading: createProjectLoading }] = useMutation(
    MUTATION_CREATE_PROJECT,
    {
      refetchQueries: ['GetProjectsForCollection'],
      onError: (error: ApolloError) => {
        onApolloError(error, setErrorMsg, ['CardLimitError', 'InvalidCharacterError']);
      },
    }
  );
  const [step, setStep] = useState(1);
  const numSteps = 3;

  const resetProjectUsers = () => {
    if (user && user.attributes) {
      setProjectUsers([
        { id: user?.attributes?.sub || '', name: 'You', email: user.attributes.email },
      ]);
    }
  };

  const onSubmit = () => {
    const data = getValues();

    data.name &&
      createProject({
        variables: {
          customerId: activeCompanySlug,
          name: data.name,
          budget: toCents(Number(data.budget)),
          users: projectUsers.map((user) => user.id),
        },
      })
        .then(() => {
          resetProjectUsers();
        })
        .catch((err) => {
          resetProjectUsers();
        });
  };

  useEffect(() => {
    if (createProjectData) {
      if (createProjectData.createProject?.approved) {
        getCard({
          variables: {
            customerId: activeCompanySlug,
            cardId: createProjectData?.createProject?.cardId || '',
          },
        });
      }
    }
  }, [createProjectData]);

  useEffect(() => {
    if (getCardData && getCardData.getCard) {
      setCardLast4Digits(getCardData.getCard.last4Digits || '');
    }
  }, [getCardData]);

  useEffect(() => {
    if (step === 1) setFocus('name');
    else if (step === 2) setFocus('budget');
    else if (step === 3) {
      setFocus('addTeammates');
    }
  }, [setFocus, step]);

  const handleNext = () => {
    if (step < numSteps) {
      // project details case
      if (step === 1) {
        const data = getValues();
        if (!data.name) {
          setErrorMsg('Please enter a project name.');
        } else {
          setErrorMsg(null);
          setStep(step + 1);
        }
      } else if (step === 2) {
        const data = getValues();

        if (data.budget && (!Number.isInteger(Number(data.budget)) || Number(data.budget) === 0)) {
          setErrorMsg('Please enter a valid budget.');
        } else {
          setErrorMsg(null);
          setStep(step + 1);
        }
      }
    } else {
      // make sure at least one user is provided
      if (!projectUsers.length) {
        setErrorMsg('Please provide at least one project team member.');
      } else {
        setErrorMsg(null);
        onSubmit();
      }
    }
  };

  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onClose = () => {
    resetProjectUsers();
    navigate(from);
  };

  if (createProjectLoading) {
    return <ProjectCreateLoading onClose={onClose} />;
  }
  if (createProjectData && createProjectData.createProject) {
    const project = createProjectData.createProject;

    return (
      <ProjectSuccess
        customerId={activeCompanySlug}
        cardLast4Digits={cardLast4Digits}
        onClose={onClose}
        project={{
          id: project.id,
          name: project.name,
          budgetCents: project.budget,
          approved: project.approved || false,
        }}
      />
    );
  }
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNext();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  const steps = [
    <>
      <PageTitle hidden text="Create new project" />
      <div className="mt-10">
        <UnderlineTextInput
          label="What's the project name?"
          disablePasswordManager
          useFormRegister={register('name')}
          onKeyDown={handleKeyDown}
        />
      </div>
    </>,
    <>
      <div className="mt-10">
        <UnderlineTextInput
          type="number"
          label="What's the estimated project budget?"
          prefix="$"
          useFormRegister={register('budget')}
          onKeyDown={handleKeyDown}
          errorMessage={errorMsg}
        />
        <h6 className="my-4">If you don't wish to enter a budget, please leave blank.</h6>
      </div>
    </>,
    <>
      <h3 className="mb-10">Add any teammates who will be working on the project.</h3>
      <ProjectUserMultiSelect
        customerId={activeCompanySlug}
        useFormRegister={register('addTeammates')}
        onKeyDown={handleKeyDown}
      />
    </>,
  ];

  return (
    <div className="bg-ivory">
      <div className="min-h-[10vh]">
        <div>
          <ProgressBar step={step} numSteps={numSteps} height={2} />
        </div>
        <div className="flex justify-between mt-9 ml-6">
          <img className="object-contain h-6" src={zenaLogo} alt="Zena Logo" />
          <button onClick={onClose} aria-label="Close dropdown menu">
            <div className="flex items-center">
              <div className="underline">Close</div>
              <XIcon className="mx-2" />
            </div>
          </button>
        </div>
      </div>

      <div className="grid h-[90vh] place-content-center">
        {step > 1 && (
          <div className="flex">
            <button className="contents" onClick={handlePrev}>
              <ArrowLeft />
              <UnderlineButton text="Back" />
            </button>
          </div>
        )}
        <form id="project">{steps[step - 1]}</form>

        <div className="mt-14">
          <div className="flex items-center">
            <RoundButton
              type={step === numSteps ? 'submit' : 'button'}
              text={step === numSteps ? 'Submit' : 'Next'}
              onClick={handleNext}
            />
            {!isMobile && (
              <>
                <div className="bg-secondary-400 ml-6 mr-2 h-8 w-8 grid place-items-center rounded">
                  <CornerDownRight />
                </div>
                <p className="text-asphalt">or Press Enter</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

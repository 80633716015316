import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from '../../../utils';

interface TabPanelLayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const TabPanelLayout: FC<PropsWithChildren<TabPanelLayoutProps>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={cn('flex flex-col gap-16 pt-8 md:pt-16', className)} {...props}>
      {children}
    </div>
  );
};

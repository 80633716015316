import { UserContext } from 'providers/UserAuthProvider';
import { useContext } from 'react';

const useCustomAuth = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('`useCustomAuth` must be within a `UserProvider`');
  }
  return context;
};

export default useCustomAuth;

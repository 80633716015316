import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const GET_INTUIT_APP_CONNECTION_STATUS_QUERY = gql(`
  query GetIntuitAppConnectionForTransactionTable($companySlug: String!) {
    getIntuitAppConnection(companySlug: $companySlug) {
      id
      qboAccountId
      startedAt
    }
  }
`);

export const useIntuitAppConnectionStatus = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(GET_INTUIT_APP_CONNECTION_STATUS_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const isIntuitAppConnectedAndActive =
    data?.getIntuitAppConnection?.id &&
    data.getIntuitAppConnection.qboAccountId &&
    data.getIntuitAppConnection.startedAt;

  return { isIntuitAppConnectedAndActive, loading, error };
};

import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetCustomerApplicationForRouting,
  useGetPlaidStatusForRouting,
  useGetActiveCompany,
} from './data';
import useCustomAuth from 'hooks/useCustomAuth';

const routesRequireNoCompanySlug = [
  '/join-team',
  '/signup/business',
  '/signup',
  '/pendingInvitations',
];

export const UserRedirects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { data, loading: loadingUserInfoFromGql } = useGetActiveCompany();
  const activeCompanySlug = data?.getMe.defaultCompany?.slug;
  const { loading: loadingForApplication } = useGetCustomerApplicationForRouting({
    companySlug: activeCompanySlug,
  });
  const { loading: loadingPlaidStatus } = useGetPlaidStatusForRouting({
    companySlug: activeCompanySlug,
  });

  const { isLoading: loadingUserFromAuth, user } = useCustomAuth();

  if (routesRequireNoCompanySlug.includes(pathname)) {
    return null;
  }

  if (
    !user ||
    !data ||
    loadingUserInfoFromGql ||
    loadingUserFromAuth ||
    loadingForApplication ||
    loadingPlaidStatus
  ) {
    return null;
  }

  if (!activeCompanySlug && pathname !== '/signup/business') {
    navigate('/signup/business');
  }

  if (data && !activeCompanySlug && pathname !== '/signup/business') {
    navigate('/signup/business');
  }

  return null;
};

import { Alert } from 'components/atoms/Alert';
import { RoundButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { PageTitle } from 'components/atoms/PageTitle';
import useCustomAuth from 'hooks/useCustomAuth';
import { Ref, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GoogleSignInButton } from '../../components/widgets/GoogleSignInButton';
import { AuthFormStates, AuthReturnProps } from 'types';

type SignUpFormProps = {
  title: string;
  description: string;
  changeFormState: (formState: AuthFormStates) => void;
  handleSetUsername: (username: string) => void;
  prefillEmail?: string;
  username?: string;
  enterKeyButtonRef?: Ref<HTMLButtonElement>;
};

type SignUpReturn = {
  errorMessage: string;
  success: boolean;
};

export const signUpAndGetResults = async (
  signUp: (username: string, email: string, password: string) => Promise<AuthReturnProps>,
  email: string,
  password: string
): Promise<SignUpReturn> => {
  const signUpResults = {
    errorMessage: '',
    success: false,
  };

  try {
    const response = await signUp(email, email, password);
    if (response.success) {
      signUpResults.errorMessage = '';
      signUpResults.success = true;
    } else {
      signUpResults.errorMessage =
        response.errorMessage || 'Error during sign up, please contact support.';
      if (response.errorMessage === 'An account with the given email already exists.') {
        signUpResults.errorMessage =
          'This email is not valid. Please correct your email or try logging in.';
      }
    }
  } catch (error) {
    signUpResults.errorMessage = 'Unknown error, please contact support';
  }
  return signUpResults;
};

const SignUpForm = (props: SignUpFormProps) => {
  const {
    title,
    description,
    changeFormState,
    handleSetUsername,
    prefillEmail,
    username,
    enterKeyButtonRef,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit } = useForm();

  const { signUp } = useCustomAuth();

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    const email = !!prefillEmail ? prefillEmail : data.email;
    handleSetUsername(email);
    try {
      const response = await signUpAndGetResults(signUp, email, data.password);
      if (response.success) {
        changeFormState('confirmSignUp');
        setErrorMessage('');
      } else {
        setErrorMessage(response.errorMessage || 'Error during sign up, please contact support');
      }
    } catch (error) {
      setErrorMessage('Unknown error, please contact support');
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageTitle text="Sign up" hidden />
      <h1 className="text-center text-3xl">{title}</h1>

      <p className="mt-4">{description}</p>
      {errorMessage && (
        <div className="mt-4">
          <Alert variation="error">{errorMessage}</Alert>
        </div>
      )}
      <div className="mt-8">
        <GoogleSignInButton text="Sign up with Google" />
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-marble"></div>
          <span className="flex-shrink mx-4 text-marble">Or, sign up with your email</span>
          <div className="flex-grow border-t border-marble"></div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8">
          <TextInput
            className={`mb-2 ${!!prefillEmail && 'text-feather'}`}
            label="Email"
            placeholder="Email"
            useFormRegister={register('email')}
            defaultValue={prefillEmail ? prefillEmail : username}
            disabled={!!prefillEmail}
            onBlur={(e) => {
              handleSetUsername(e.target.value);
            }}
          />
          <TextInput
            className="mb-2"
            label="Password"
            useFormRegister={register('password')}
            placeholder="Password"
            type="password"
          />
        </div>
        <div className="flex items-center">
          <div className="mr-2 w-full">
            <RoundButton
              className="w-full"
              variant="primary"
              text="Create account"
              disabled={isSubmitting}
              onClick={() => handleSubmit(onSubmit)}
              ref={enterKeyButtonRef}
              type="submit"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default SignUpForm;

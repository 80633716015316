import { SkeletonProps } from 'components/atoms/Skeletons';
import { RectangleSkeleton } from 'components/atoms/Skeletons/Shapes';

export function HeadingSkeleton(props: SkeletonProps) {
  return <RectangleSkeleton width="medium" height="medium" className="bg-gray-300/90" {...props} />;
}

export function TextSkeleton(props: SkeletonProps) {
  return <RectangleSkeleton height="small" {...props} />;
}

export function ParagraphSkeleton(props: SkeletonProps) {
  return (
    <>
      <RectangleSkeleton height="small" {...props} />
      <RectangleSkeleton width="large" height="small" {...props} />
      <RectangleSkeleton height="small" className="w-4/5" {...props} />
    </>
  );
}

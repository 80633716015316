import { useMutation } from '@apollo/client';
import { gql } from '__generated__';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { fetchAuthSession, decodeJWT } from 'aws-amplify/auth';
import { PageTitle } from 'components/atoms/PageTitle';
import {
  getCustomerTokenFromLocalStorage,
  getImpersonatingUserEmail,
  setCustomerTokenToLocalStorage,
} from 'hooks/access';
import { formatPhone } from 'hooks/formatPhone';
import { LocalUserStore, useLocalStore } from 'stores/UserStore';
import { GenericLoader } from 'components/atoms/Loader';
import { useActiveCompany } from 'providers/ActiveCompany';
import { Alert } from 'components/atoms/Alert';

const CREATE_UNIT_VERIFICATION_CODE = gql(`
    mutation CreateUnitVerificationToken($customerId: String!) {
        createVerificationToken(customerId: $customerId){
          token,
          phone
        }
    }
`);

const CREATE_UNIT_CUSTOMER_TOKEN = gql(`
    mutation CreateUnitCustomerToken($customerId:String!, $verificationCode:String!, $verificationToken:String!){
        createCustomerToken(
            customerId:$customerId,
            verificationToken: $verificationToken ,
            verificationCode: $verificationCode
        ){
            token
        }
    }
`);

const TwoFactorAuth = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [
    createUnitVerificationToken,
    { data: unitVerificationToken, loading: verificationLoading },
  ] = useMutation(CREATE_UNIT_VERIFICATION_CODE);
  const [createUnitCustomerToken, unitCustomerToken] = useMutation(CREATE_UNIT_CUSTOMER_TOKEN);
  const [impersonationEmail] = useLocalStore((state: LocalUserStore) => [state.impersonationEmail]);

  const location = useLocation();
  const navigate = useNavigate();

  const prevPathname = location.state?.from?.pathname || null;
  const prevSearch = location.state?.from?.search || null;
  const from = prevSearch ? `${prevPathname}${prevSearch}` : prevPathname || '/';

  useEffect(() => {
    const localCustomerToken = getCustomerTokenFromLocalStorage();
    const createTokenOrRedirect = async () => {
      const impersonatingUser = impersonationEmail;
      let byPass = false;
      if (impersonatingUser) {
        const idToken = (await fetchAuthSession()).tokens?.idToken;
        const jwtValues = await decodeJWT(idToken?.toString() ?? '');
        const jwtImpersonating = await getImpersonatingUserEmail(
          jwtValues.payload.impersonating as string
        );

        if (jwtImpersonating) {
          byPass = true;
          setCustomerTokenToLocalStorage('false');
        }
      }

      if (!localCustomerToken && !byPass) {
        createUnitVerificationToken({
          variables: {
            customerId: activeCompanySlug,
          },
        });
      } else {
        navigate(from, { replace: true });
      }
    };
    createTokenOrRedirect();
  }, [createUnitVerificationToken, activeCompanySlug, from, navigate]);

  useEffect(() => {
    if (!!unitCustomerToken.data?.createCustomerToken?.token) {
      const token = unitCustomerToken.data?.createCustomerToken?.token || '';

      setCustomerTokenToLocalStorage(token);
      navigate(from, { replace: true });
    }
  }, [unitCustomerToken.data?.createCustomerToken?.token, navigate, from]);

  const submitCode = useCallback(
    (verificationCode: string) => {
      createUnitCustomerToken({
        variables: {
          customerId: activeCompanySlug,
          verificationCode: verificationCode || '',
          verificationToken: unitVerificationToken?.createVerificationToken?.token || '',
        },
      });
    },
    [activeCompanySlug, createUnitCustomerToken, unitVerificationToken?.createVerificationToken]
  );
  const resendVerificationToken = useCallback(
    () =>
      createUnitVerificationToken({
        variables: {
          customerId: activeCompanySlug,
        },
      }),
    [activeCompanySlug, createUnitVerificationToken]
  );

  const mfaPhone = unitVerificationToken?.createVerificationToken?.phone || 'N/A';

  return (
    <div className="grid place-content-center text-center">
      <PageTitle className="mt-8 font-semibold" text="Phone verification" />
      {unitCustomerToken.error && (
        <div className="mb-8">
          <Alert variation="error">
            It looks like the entered SMS code may be incorrect. Please double check the received
            code or tap "Resend Code"
          </Alert>
        </div>
      )}
      {verificationLoading ? (
        <div className="flex justify-center items-center">
          <GenericLoader />
        </div>
      ) : (
        <>
          <div className="text-gray-500">We sent an SMS code to: {formatPhone(mfaPhone)}</div>
          <div className="flex justify-center items-center">
            <VerificationInput
              onComplete={submitCode}
              autoFocus
              placeholder=""
              inputProps={{
                inputMode: 'numeric',
              }}
              classNames={{
                container: 'mt-4 mb-8',
                character: 'pt-1 rounded h-16 bg-white border-primary-400',
              }}
            />
          </div>
          <button onClick={resendVerificationToken} className="mt-4 underline text-sm text-left">
            Resend Code
          </button>
        </>
      )}
    </div>
  );
};

export default TwoFactorAuth;

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const PROJECTS_SELECT_QUERY = gql(`
  query GetProjectsForProjectSelect($companySlug: String!) {
    getProjects(customerId: $companySlug, sortBy: name, filters: {
      status: active,
      includeUnapproved: false
    }) {
      projects {
        id
        name
      }
    }
  }
`);

export const useProjects = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(PROJECTS_SELECT_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  return { projects: data?.getProjects?.projects ?? [], loading, error };
};

import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__';
import { Role } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { TextInput } from 'components/atoms/Inputs/TextInput';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppState } from 'stores/UserStore';
import { cn, onApolloError } from 'utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

export const MUTATION_CREATE_AUTHORIZED_USER_INVITATION = gql(`
  mutation CreateAuthorizedUserInvitation($companySlug: String!, $inviteeEmail: String!, $role: Role!) {
    createAuthorizedUserInvitation(companySlug: $companySlug, inviteeEmail: $inviteeEmail, role: $role) {
        inviteeEmail
    }
  }
`);

type AuthorizedUserInvitationFormProps = {
  customerId: string;
};

interface IFormInputs {
  email: string;
  role: Role;
}

const expectedErrorTypes = ['InvalidEmail', 'InvitingSelf', 'AlreadyInvited'];

export const AuthorizedUserInvitationForm = (props: AuthorizedUserInvitationFormProps) => {
  const { customerId } = props;
  const { newPermissons } = useFlags();
  const [setSuccessMsg] = useAppState((state) => [state.setSuccessMsg]);

  const [setErrorMsg] = useAppState((state) => [state.setErrorMsg]);
  const [inviteTeamMember, { data, loading }] = useMutation(
    MUTATION_CREATE_AUTHORIZED_USER_INVITATION,
    {
      refetchQueries: ['GetPendingInvitations'],
      onError: (error: ApolloError) => onApolloError(error, setErrorMsg, expectedErrorTypes),
    }
  );

  const { register, handleSubmit } = useForm<IFormInputs>();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (data.email) {
      setSuccessMsg(null);
      inviteTeamMember({
        variables: {
          inviteeEmail: data.email.toLowerCase(),
          companySlug: customerId,
          role: data.role,
        },
      });
    } else {
      setErrorMsg('Please enter an email address.');
    }
  };

  useEffect(() => {
    const invitedEmail = data?.createAuthorizedUserInvitation?.inviteeEmail;
    if (invitedEmail) {
      setSuccessMsg(`An invitation has been sent to ${invitedEmail}.`);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <form className="flex flex-col sm:flex-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="my-1 w-full">
            <TextInput
              label=""
              placeholder="Add people by email"
              useFormRegister={register('email')}
              disablePasswordManager
            />
          </div>
          <div className="flex my-1">
            <div className="w-full min-w-32 sm:ml-2 pr-2 border border-marble rounded-lg ">
              <select
                defaultValue={'member'}
                className={cn('h-full w-full px-2 bg-transparent focus:outline-none focus:ring-0')}
                {...register('role')}
              >
                <option value="member">User</option>
                <option value="admin">Admin</option>
                {newPermissons && <option value="bookkeeper">Bookkeeper</option>}
              </select>
            </div>
            <RoundButton
              type="submit"
              className="w-24 flex justify-center ml-2 sm:ml-6"
              text="Add"
              disabled={loading}
            />
          </div>
        </form>
      )}
    </>
  );
};

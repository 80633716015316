import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

export const CUSTOMER_CREDIT_QUERY = gql(`
  query GetCustomerCreditWidgetData($companySlug: String!) {
    getCustomerAccount(customerId: $companySlug) {
      creditLimitCents
      creditAvailableCents
      creditBalanceCents
      creditHoldCents
    }
  }
`);

export const useUserCreditData = ({ companySlug }: { companySlug: string }) => {
  const { data, loading, error } = useQuery(CUSTOMER_CREDIT_QUERY, {
    variables: {
      companySlug,
    },
    skip: !companySlug,
  });

  const companyLimits = data?.getCustomerAccount ?? null;

  return { loading, error, companyLimits };
};

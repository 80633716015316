import { ReactNode } from 'react';
import { useFlags, ZenaFeatureFlags } from './useFlags';

export const ShowIfEnabled = ({
  flag,
  children,
}: {
  flag: keyof ZenaFeatureFlags;
  children: ReactNode;
}): JSX.Element => {
  const flags = useFlags();

  if (flags[flag]) {
    return <>{children}</>;
  }

  return <></>;
};

export const ShowIfEnabledFallbackIfDisabled = ({
  flag,
  showIfEnabled,
  showIfDisabled,
}: {
  flag: keyof ZenaFeatureFlags;
  showIfEnabled: ReactNode;
  showIfDisabled: ReactNode;
}) => {
  const flags = useFlags();

  if (flags[flag]) {
    return <>{showIfEnabled}</>;
  }

  return <>{showIfDisabled}</>;
};

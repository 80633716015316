const useGooglePlaceAutoComplete = () => {
  const initAutoComplete = async (input: HTMLInputElement | null, callback: Function) => {
    // @ts-ignore
    const { Autocomplete } = await window.google.maps.importLibrary('places');
    let autoComplete = new Autocomplete(input, {
      // limit to United States for now
      componentRestrictions: { country: ['us'] },
      fields: ['address_component', 'geometry'],
      types: ['address'],
    });
    autoComplete.addListener('place_changed', () => {
      if (autoComplete.getPlace()) {
        callback();
      }
    });

    return autoComplete;
  };

  const getFullAddress = async (autoComplete: any) => {
    const place = autoComplete.getPlace();

    let address1,
      locality,
      sublocality,
      adminArea1Short,
      adminArea1Long,
      countryShort,
      countryLong,
      postalCode = '';

    // Get each component of the address from the place details,
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === 'street_number') {
        address1 = component.long_name;
      }
      if (componentType === 'route') {
        address1 = `${address1} ${component.long_name}`;
      }
      if (componentType === 'locality') {
        locality = component.long_name;
      }
      if (componentType === 'sublocality_level_1') {
        sublocality = component.long_name;
      }
      if (componentType === 'administrative_area_level_1') {
        adminArea1Short = component.short_name;
        adminArea1Long = component.long_name;
      }
      if (componentType === 'postal_code') {
        postalCode = component.long_name;
      }
      if (componentType === 'postal_code_suffix') {
        postalCode = `${postalCode}-${component.long_name}`;
      }
      if (componentType === 'country') {
        countryShort = component.short_name;
        countryLong = component.long_name;
      }
    }

    let resAddress = {
      address1: address1,
      locality: locality,
      sublocality: sublocality,
      adminArea1Short: adminArea1Short,
      adminArea1Long: adminArea1Long,
      postalCode: postalCode,
      countryShort: countryShort,
      countryLong: countryLong,
    };

    return resAddress;
  };

  const clearResult = async (autoComplete: any) => {
    autoComplete.set('place', null);
  };

  return {
    initAutoComplete,
    getFullAddress,
    clearResult,
  };
};

export default useGooglePlaceAutoComplete;

import moment from 'moment';

export const formatShortDate = (date: Date): string => {
  return moment(date).format('DD MMM');
};

export const formatSimpleDate = (date: Date): string => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatGraphqlDate = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatTime = (date: Date): string => {
  return moment(date).format('h:mmA');
};

export const formatDateWithTime = (date: Date): string => {
  return moment(date).format('MM/DD/YYYY h:mmA');
};

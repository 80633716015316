import React from 'react';
import { Table } from 'components/atoms/table/Table';

type Meta = {
  pageNumber: number; // this is zero indexed
  pageSize: number;
  total: number;
};

export const TableWithPagination = ({
  children,
  meta,
  viewMoreButtonCopy,
  loading,
  loadNextPage,
}: {
  children: React.ReactNode;
  meta?: Meta;
  loading?: boolean;
  viewMoreButtonCopy?: string;
  loadNextPage?: () => void;
}) => {
  let paginationComponent = null;
  if (!loading && meta) {
    const { pageNumber, pageSize, total: totalRecords } = meta;
    const indexEndOfPage = (pageNumber + 1) * pageSize;
    paginationComponent =
      totalRecords > indexEndOfPage ? (
        <button
          id="test"
          className="w-full h-full py-3 px-6 bg-primary-400 text-black mt-4 rounded-3xl"
          onClick={loadNextPage}
        >
          {viewMoreButtonCopy}
        </button>
      ) : null;
  }
  return (
    <Table>
      {children}
      <tfoot>
        {viewMoreButtonCopy ? (
          <tr>
            <td colSpan={6}>{paginationComponent}</td>
          </tr>
        ) : null}
      </tfoot>
    </Table>
  );
};

import { ApolloError, useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { Card } from '__generated__/graphql';
import { Button } from 'components/atoms/Buttons';
import { Modal } from 'components/atoms/Modal';
import { NotificationBanner } from 'components/atoms/NotificationBanner';
import { useAppState } from 'stores/UserStore';
import { onApolloError } from 'utils';

export interface CloseCardModalProps {
  show: boolean;
  onClose: Function;
  cardData: Card;
  cardId: string;
  customerId: string;
}

const MUTATION_CLOSE_CARD = gql(`
  mutation CloseCard($customerId: String!, $cardId: String!) {
    closeCard(customerId: $customerId, cardId: $cardId) {
      id
    }
  }
`);

const CloseCardModal = ({ show, onClose, cardData, cardId, customerId }: CloseCardModalProps) => {
  const [, setErrorMessage] = useAppState((state) => [state.errorMsg, state.setErrorMsg]);

  const [closeCard] = useMutation(MUTATION_CLOSE_CARD, {
    variables: {
      cardId,
      customerId,
    },
    onError: (error: ApolloError) => {
      onApolloError(error, setErrorMessage, ['CardOperationFailed']);
    },
    onCompleted: () => {
      onClose(true);
    },
    refetchQueries: ['GetCardDetailsDisplay'],
  });

  return (
    <Modal show={show} onClose={() => onClose(false)}>
      <div className="font-['Helvetica'] leading-normal">
        <div className="font-bold text-[14px] text-gray-500">Close card</div>
        <h2 className="font-bold text-2xl mb-4">
          Are you sure you want to close card ending with **{cardData.last4Digits}
        </h2>
        <div className="text-base text-gray-500 mb-12">
          You won't be able to activate this card later
        </div>
        <NotificationBanner
          variation="warning"
          message="Closing this card is permanent and cannot be reversed."
        />
        <Button className="mt-4 w-full" onClick={() => closeCard()}>
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default CloseCardModal;

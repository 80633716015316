import { Transaction } from '__generated__/graphql';
import { ReactNode } from 'react';
import { TransactionTableRow } from './TransactionTableRow';

export interface TableBodyRenderProp<T extends Transaction> {
  row: T;
  isIncome: boolean;
  isAuthorization: boolean;
  missingFields: (v: T) => string[];
  childTransactionIds: string[];
  qboActive: boolean;
}
export const TableBody = <T extends Transaction>({
  loading,
  errorMessage,
  data,
  selectedTransactionId,
  onClickTransactionRow,
  overrides,
  children,
  gridCols = 6,
  qboActive,
}: {
  loading: boolean;
  errorMessage?: string;
  data: T[];
  selectedTransactionId?: string;
  onClickTransactionRow?: (transactionId: string) => void;
  overrides?: Object;
  children?: (props: TableBodyRenderProp<T>) => ReactNode;
  gridCols?: number;
  qboActive: boolean;
}) => {
  const missingFields = (v: T): string[] => {
    if (!v.missingFields) return [];

    const optInFields = ['memo', 'receipt'];

    const fields: string[] = [];

    for (const [field, value] of Object.entries(v.missingFields)) {
      if (!optInFields.includes(field)) continue;
      if (value) fields.push(field);
    }
    return fields;
  };

  if (loading) {
    return (
      <tr>
        <td className="text-asphalt text-center" colSpan={gridCols}>
          <em>Loading transactions...</em>
        </td>
      </tr>
    );
  } else if (errorMessage) {
    return (
      <tr>
        <td className="text-asphalt text-center" colSpan={gridCols}>
          {errorMessage}
        </td>
      </tr>
    );
  } else if (data.length > 0) {
    return (
      <>
        {data.map((item) => {
          let amountWithDirection = item.amountWithDirection;
          const projectName = item.projectName ?? '';
          const source = item?.card ? item?.card.name || '' : item?.bank?.name || '';

          if (children && typeof children === 'function') {
            return (
              <TransactionTableRow
                id={item?.id || ''}
                key={item?.id || ''}
                name={item?.shortName || ''}
                amountWithDirection={amountWithDirection || item?.amount}
                date={item?.date || ''}
                source={source}
                project={{ name: projectName }}
                onClick={() => onClickTransactionRow?.(item.id)}
                zenaType={item.zenaType}
                isIncome={item.spendCategory === 'income'}
                isAuthorization={item.isAuthorization}
                isHighlighted={selectedTransactionId === item.id}
                childTransactionIds={item.childTransactionIds}
                isImported={item.type === 'IMPORTED'}
                syncStatus={item.accountingSyncStatus || undefined}
                syncedAt={item.qboAccountingIntegrationSync?.syncedAt}
                expenseId={item.qboAccountingIntegrationSync?.expenseId}
                missingFields={item.missingFields || undefined}
                qboActive={qboActive}
              >
                {children({
                  row: item,
                  isIncome: item.spendCategory === 'income',
                  isAuthorization: item.isAuthorization,
                  missingFields,
                  childTransactionIds: item.childTransactionIds,
                  qboActive,
                })}
              </TransactionTableRow>
            );
          }

          return (
            <TransactionTableRow
              id={item?.id || ''}
              key={item?.id || ''}
              name={item?.shortName || ''}
              amountWithDirection={amountWithDirection || item?.amount}
              date={item?.date || ''}
              source={source}
              project={{ name: projectName }}
              onClick={() => onClickTransactionRow?.(item.id)}
              zenaType={item.zenaType}
              isIncome={item.spendCategory === 'income'}
              isAuthorization={item.isAuthorization}
              isHighlighted={selectedTransactionId === item.id}
              childTransactionIds={item.childTransactionIds}
              isImported={item.type === 'IMPORTED'}
              syncStatus={item.accountingSyncStatus || undefined}
              syncedAt={item.qboAccountingIntegrationSync?.syncedAt}
              expenseId={item.qboAccountingIntegrationSync?.expenseId}
              missingFields={item.missingFields || undefined}
              qboActive={qboActive}
            />
          );
        })}
      </>
    );
  } else {
    return (
      <tr>
        <td className="text-asphalt text-center" colSpan={gridCols}>
          <p className="font-medium">No transactions to display.</p>
          {Object.keys(overrides || {}).length === 0 && (
            <p>Transactions will appear here every time you use your card.</p>
          )}
        </td>
      </tr>
    );
  }
};

import { UserSelect } from 'components/molecules/UserSelect';
import useCustomAuth from 'hooks/useCustomAuth';
import { useMemo } from 'react';
import { User } from 'types';

type SelectCardUserProps = {
  selectableUsers: User[];
  onUserSelect: (user: User | null) => void;
};

const SelectCardUser = ({ selectableUsers, onUserSelect }: SelectCardUserProps) => {
  const { user } = useCustomAuth();
  const initialSelectedUser = useMemo(
    () => ({ id: user?.attributes?.sub || '', name: 'You', email: user?.attributes?.email || '' }),
    [user]
  );
  return (
    <>
      <h1 className="text-2xl text-asphalt mb-10">Who is the card for?</h1>
      <UserSelect
        selectableUsers={selectableUsers}
        setUser={onUserSelect}
        initialSelectedUser={initialSelectedUser}
      />
    </>
  );
};

export default SelectCardUser;

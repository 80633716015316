import { useState } from 'react';
import { useIncomeByProjects } from './data';
import { toDollars } from 'utils';
import { IncomeByProjectChart } from 'pages/(dashboard)/DashboardCharts/IncomeByProjectChart';
import { ProjectStatus } from '__generated__/graphql';
import { useActiveCompany } from 'providers/ActiveCompany';

export const IncomeWidget = () => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>('active' as ProjectStatus);
  const { data: incomeByProjectsData, loading: loadingIncomeByProjects } =
    useIncomeByProjects(activeCompanySlug);

  const totalIncomeCents =
    projectStatus === 'active'
      ? incomeByProjectsData?.activeProjectsTotalIncomeAmountCents
      : incomeByProjectsData?.completedProjectsTotalIncomeAmountCents;

  const projectData =
    projectStatus === 'active'
      ? incomeByProjectsData?.activeProjects
      : incomeByProjectsData?.completedProjects;

  return (
    <IncomeByProjectChart
      totalIncome={toDollars(totalIncomeCents ?? 0)}
      projectStatus={projectStatus}
      onChangeProjectStatus={(projectStatus) => setProjectStatus(projectStatus)}
      isLoading={loadingIncomeByProjects}
      data={
        projectData?.map((project) => ({
          id: project.id,
          projectName: project.projectName,
          income: toDollars(project.totalIncomeAmountCents),
        })) ?? []
      }
    />
  );
};

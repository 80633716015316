// Add the user's sub, project ID, and file name to the key
export const processFile = ({
  file,
  userSub,
  modelType,
  modelId,
}: {
  file: File;
  userSub: string;
  modelType: 'authorization' | 'transaction';
  modelId: string;
}) => {
  const cleanedFilename = cleanKey({ key: file.name });
  return {
    file,
    key: `user/${userSub}/${modelType}/${modelId}/${cleanedFilename}`,
  };
};

// remove all non-alphanumeric characters from the file name
export const cleanKey = ({ key }: { key: string }) => {
  return key.replace(/[^a-zA-Z0-9.]/g, '');
};

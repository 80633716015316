import { QboLogo } from 'icons/qboLogo';
import { TransactionSyncStatus } from '__generated__/graphql';
import { RotateCcw, TriangleAlert } from 'lucide-react';

interface TransactionRowSyncStatusProps {
  isImported: boolean;
  syncStatus?: TransactionSyncStatus;
  syncedAt?: string;
  expenseId?: string;
}

export const TransactionRowSyncStatus = ({
  isImported,
  syncStatus,
  syncedAt,
  expenseId,
}: TransactionRowSyncStatusProps) => {
  if (!isImported) {
    if (syncStatus === TransactionSyncStatus.syncing) {
      return <RotateCcw size={20} className="animate-spin" />;
    } else {
      if (!!syncedAt && !!expenseId) {
        return <QboLogo size={20} />;
      } else {
        return <TriangleAlert size={20} color="#DFA102" />;
      }
    }
  }

  return <span className="text-muted font-medium">n/a</span>;
};

export default TransactionRowSyncStatus;

import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

const GET_ACTIVE_COMPANY = gql(`
query getActiveCompanyForUserRedirects {
  getMe {
    id
    defaultCompany {
      id      
      slug
    }
  }
}
`);

export const useGetActiveCompany = () => {
  const { data, loading, error } = useQuery(GET_ACTIVE_COMPANY);

  return {
    data,
    loading,
    error,
  };
};

const GET_CUSTOMER_APPLICATION_FOR_ROUTING = gql(`
query GetApplicationStatusFor {
  getApplication {
    id
    complete
  }
}
`);

export const useGetCustomerApplicationForRouting = ({ companySlug }: { companySlug?: string }) => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_APPLICATION_FOR_ROUTING, {
    fetchPolicy: 'network-only',
    skip: !companySlug,
  });

  return {
    application: data?.getApplication,
    loading,
    error,
  };
};

const GET_PLAID_STATUS_FOR_ROUTING = gql(`
query GetPlaidStatusForRouting($companySlug: String!) {
  getRepaymentConnectionStatus(companySlug: $companySlug) {
    id
    connected
  }
}
`);

export const useGetPlaidStatusForRouting = ({ companySlug }: { companySlug?: string }) => {
  const { data, loading, error } = useQuery(GET_PLAID_STATUS_FOR_ROUTING, {
    variables: {
      companySlug: companySlug ?? '',
    },
    skip: !companySlug,
  });

  return {
    plaidStatus: data?.getRepaymentConnectionStatus,
    loading,
    error,
  };
};

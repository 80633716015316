import { Button } from 'components/atoms/Buttons';

export function LinkedBankAccountCardLoading() {
  return (
    <div className="flex flex-col p-4 border-none rounded-xl bg-ivory">
      <div className="flex flex-row justify-between animate-pulse items-center">
        <div>
          <div>Loading...</div>
        </div>
        <div className="flex my-auto">
          <Button className="bg-secondary-400 text-black rounded-full" text="Loading Account" />
        </div>
      </div>
    </div>
  );
}

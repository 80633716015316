import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { Statement } from '__generated__/graphql';
import { RoundButton } from 'components/atoms/Buttons';
import { TableHead } from 'components/atoms/table/TableHead';
import {
  StatementFilterPanel,
  StatementFilterProvider,
  StatementSelectedFilters,
  useStatementFilter,
} from './StatementFilter';
import { StatementTableRow } from './StatementTableRow';
import { TableWithPagination } from 'components/molecules/TableWithPagination';
import { updatedDiff } from 'deep-object-diff';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePreviousValue } from '../../hooks/usePrevious';

const GET_STATEMENTS = gql(`
  query GetStatements($customerId: String!, $startDate: String!, $endDate: String!, $stepSize: String, $page: Int) {
    getStatements(customerId: $customerId, startDate: $startDate, endDate: $endDate, stepSize: $stepSize, page: $page) {
      data {
        id
        date
        beginningBalance
        statementAmount
        endingBalance
        paymentMade
        paymentCreatedAt
        status
        transactionIds
        repaymentIds
        adjustments {
          id
          amountCents
          reason
        }
      }
      meta {
        pageNumber
        pageSize
        total
      }
    }
  }
`);

interface TableProps {
  customerId: string;
  showFilters?: true;
}
const Table = ({ customerId, showFilters = true }: TableProps) => {
  const stepSize = 'day';
  const [statements, setStatements] = useState<Statement[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [filtersVisible, setFiltersVisible] = useState(false);

  const { filtersInput, selectedFilters } = useStatementFilter();
  const previousSelectedFilters = usePreviousValue(selectedFilters);
  const selectedDiff = updatedDiff(selectedFilters, previousSelectedFilters ?? {}) as Record<
    string,
    any
  >;
  const hasDiff = !!Object.keys(selectedDiff || {}).length;
  useEffect(() => {
    if (hasDiff && pageNumber !== 0) {
      setStatements([]);
      setPageNumber(0);
    }
  }, [hasDiff, pageNumber, setPageNumber]);

  const queryVariables = useMemo(
    () => ({
      customerId,
      page: pageNumber,
      stepSize,
      ...filtersInput,
    }),
    [customerId, pageNumber, filtersInput]
  );

  const fetchingMore = useRef(false);
  const {
    data: queryResult,
    loading,
    fetchMore,
    error,
  } = useQuery(GET_STATEMENTS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getStatements }) => {
      fetchingMore.current = false;
      setStatements(getStatements?.data || []);
    },
  });

  const loadNextPage = () => {
    fetchingMore.current = true;
    fetchMore({
      variables: { ...queryVariables, page: meta.pageNumber + 1 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const filteredData = [
          ...((previousResult.getStatements?.data as Statement[]) || []),
          ...((fetchMoreResult.getStatements?.data as Statement[]) || []),
        ].filter(
          (item, index, array) => index === array.findIndex((foundItem) => foundItem.id === item.id)
        );
        return {
          ...fetchMoreResult,
          getStatements: {
            ...fetchMoreResult.getStatements,
            data: filteredData,
          },
        };
      },
    });
  };

  const meta = queryResult?.getStatements?.meta!;

  let statementsBody = null;
  if (loading && !fetchingMore.current) {
    statementsBody = (
      <tr>
        <td className="text-asphalt text-center" colSpan={6}>
          <em>Loading statements...</em>
        </td>
      </tr>
    );
  } else if (error) {
    statementsBody = (
      <tr>
        <td className="text-asphalt text-center" colSpan={6}>
          {error.message}
        </td>
      </tr>
    );
  } else if (statements.length) {
    statementsBody = statements.map((statement, ind) => (
      <StatementTableRow data={statement} key={`${statement.date}-${ind}`} />
    ));
  } else {
    statementsBody = (
      <tr>
        <td className="text-asphalt text-center" colSpan={6}>
          <p className="font-medium">No statements to display.</p>
        </td>
      </tr>
    );
  }

  return (
    <div>
      <div className="m-10 flex justify-between">
        <h1>Statements</h1>
        {showFilters && (
          <RoundButton
            icon="filter"
            text="Filter"
            onClick={() => {
              setFiltersVisible(true);
            }}
          />
        )}
      </div>
      <div className="flex w-full">{showFilters && <StatementSelectedFilters />}</div>
      <div className="mx-10">
        <TableWithPagination
          loading={loading}
          meta={meta}
          viewMoreButtonCopy="View more statements"
          loadNextPage={loadNextPage}
        >
          <TableHead>
            <td>Date</td>
            <td>Beginning balance</td>
            <td>Amount spent</td>
            <td>Adjustments</td>
            <td>Payment made</td>
            <td>Remaining balance</td>
          </TableHead>
          <tbody>{statementsBody}</tbody>
        </TableWithPagination>
        {showFilters && (
          <StatementFilterPanel
            visible={filtersVisible}
            onClose={() => {
              setFiltersVisible(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export const StatementsTable = (props: TableProps) => {
  return (
    <StatementFilterProvider>
      <Table {...props} />
    </StatementFilterProvider>
  );
};

import { useReducer } from 'react';
import { isValidEmail } from 'utils/email';
import { Maybe } from 'utils/types';

export interface LoginFormState {
  email: string;
  password: string;
}

export const defaultLoginFormState: LoginFormState = {
  email: '',
  password: '',
};

// Actions
interface UpdateEmail {
  type: 'UPDATE_EMAIL';
  payload: string;
}

interface UpdatePassword {
  type: 'UPDATE_PASSWORD';
  payload: string;
}

type LoginFormAction = UpdateEmail | UpdatePassword;

const LoginFormReducer = (prevState: LoginFormState, action: LoginFormAction): LoginFormState => {
  switch (action.type) {
    case 'UPDATE_EMAIL':
      return { ...prevState, email: action.payload };
    case 'UPDATE_PASSWORD':
      return { ...prevState, password: action.payload };
    default:
      return { ...prevState };
  }
};

export const useLoginFormReducer = (defaults?: LoginFormState) => {
  return useReducer(LoginFormReducer, {
    ...defaultLoginFormState,
    ...defaults,
  });
};

// Error Validation
interface LoginFormStateErrors {
  email: Maybe<string>;
  password: Maybe<string>;
  isValid: boolean;
}

export const validateLoginFormState = (state: LoginFormState): LoginFormStateErrors => {
  const errors: LoginFormStateErrors = {
    email: undefined,
    password: undefined,
    isValid: true,
  };

  if (!state.email) {
    errors.email = 'Email is required';
    errors.isValid = false;
  }

  if (!!state.email && !isValidEmail(state.email)) {
    errors.email = 'Invalid email';
    errors.isValid = false;
  }

  if (!state.password) {
    errors.password = 'Password is required';
    errors.isValid = false;
  }

  return errors;
};

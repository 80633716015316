import { TextInput } from 'components/atoms/Inputs/TextInput';
import { KeyboardEvent, useEffect, useState } from 'react';
import { OutlinedWidgetWithSwitch } from './OutlinedWidgetWithSwitch';
import { useMutation } from '@apollo/client';
import { onApolloError } from '../../../../../../utils';
import { MUTATION_UPDATE_SETTINGS } from './data';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { SettingsSwitchProps } from './index';

export const RequireMemo = ({
  loading,
  customerId,
  setErrorMsg,
  initialValue,
  defaultEnabled = false,
}: SettingsSwitchProps<number>) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);
  useEffect(() => {
    setIsEnabled(defaultEnabled);
  }, [defaultEnabled]);
  const [value, setValue] = useState(initialValue || 0);
  const [updateSettings, { loading: isUpdating, called: updated }] = useMutation(
    MUTATION_UPDATE_SETTINGS,
    {
      onError: (error) => onApolloError(error, setErrorMsg, ['UpdateTeamSettingsFailed']),
    }
  );
  const onUpdate = (checked: boolean, value: number) => {
    updateSettings({
      variables: {
        customerId,
        settings: {
          requireMemoOver: checked ? value : null,
        },
      },
    });
  };

  return (
    <OutlinedWidgetWithSwitch
      title="Require memo"
      loading={loading}
      checked={isEnabled}
      isUpdating={isUpdating}
      hasUpdated={updated}
      onChange={(checked) => {
        setIsEnabled(checked);
        onUpdate(checked, value);
      }}
    >
      <div>
        <span className="align-middle">Triggers when the transaction is over</span>
        <div className="width-26 inline-block ml-3 align-middle">
          <div className="rounded-lg flex justify-between">
            {loading ? (
              <LoadingSpinner className="h-4 w-4 border-2" />
            ) : (
              <TextInput
                label=""
                type="number"
                step={1}
                min={0}
                max={9999999}
                onKeyDown={(event) => {
                  if (event.key === '.') {
                    event.preventDefault();
                  }
                }}
                onInput={(event: KeyboardEvent<HTMLInputElement>) => {
                  (event.target as HTMLInputElement).value = (
                    event.target as HTMLInputElement
                  ).value.replace(/[^0-9]*/g, '');
                }}
                prefix="$"
                defaultValue={initialValue?.toString() || ''}
                onChangeText={(newText: string) => {
                  setValue(parseInt(newText));
                  onUpdate(isEnabled, parseInt(newText) || 0);
                }}
                debounce={1000}
                disabled={!isEnabled}
              />
            )}
          </div>
        </div>
      </div>
    </OutlinedWidgetWithSwitch>
  );
};

import { LoadingSpinner } from 'components/atoms/LoadingSpinner';

const GenericLoader = ({ text = 'Loading...' }: { text?: string }) => {
  return (
    <div className="flex items-center">
      <LoadingSpinner /> <p className="ml-2">{text}</p>
    </div>
  );
};

export default GenericLoader;

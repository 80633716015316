import { RoundButton } from 'components/atoms/Buttons';
import { ReactNode } from 'react';
import { cn } from 'utils';
import { useCompanyTier, useHasPlaidConnection, useTransactionsCount } from './data';
import { UpsellZena } from 'pages/(dashboard)/MarketingDashboard/UpsellZena';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { useActiveCompany } from 'providers/ActiveCompany';
import { TabLink, TabsContainer } from 'components/atoms/Tab';
import { companyRole } from 'hooks/access';
import { TransactionsRequireAttentionNotification } from 'components/widgets/TransactionsRequireAttentionNotification';

export const TransactionsPageLayout = ({
  pageName,
  children,
  title,
  showDownloadButton = false,
  onClickDownload,
  className = '',
}: {
  children: ReactNode;
  title: string;
  pageName:
    | 'ALL_TRANSACTIONS'
    | 'ORGANIZE_TRANSACTIONS'
    | 'IMPORT_TRANSACTIONS'
    | 'EXPORT_TRANSACTIONS';
  showDownloadButton?: boolean;
  onClickDownload?: () => void;
  className?: string;
}) => {
  const { activeCompany } = useActiveCompany();
  const activeCompanySlug = activeCompany?.slug ?? '';
  const { companyTier, loading } = useCompanyTier({ companySlug: activeCompanySlug });
  const { hasPlaidConnection: hasSyncedAccounts, loading: syncedAccountsLoading } =
    useHasPlaidConnection({ companySlug: activeCompanySlug });
  const { hasZenaTransactions, loading: transactionsCountLoading } = useTransactionsCount({
    companySlug: activeCompanySlug,
  });

  const isBookkeeper = companyRole(activeCompanySlug) === 'bookkeeper';

  const showPageContent =
    companyTier === 'luxe' ||
    (hasSyncedAccounts && hasZenaTransactions) ||
    (hasSyncedAccounts && !hasZenaTransactions && pageName === 'IMPORT_TRANSACTIONS');

  return (
    <div className="pt-7 px-4 lg:pt-11 lg:pb-0 lg:px-10 flex flex-col gap-4 lg:gap-10 h-[calc(100vh-4rem)] overflow-hidden">
      <section className="flex justify-between gap-8 align-baseline">
        <h1 className="text-xl lg:text-4xl">{title}</h1>
        {showDownloadButton && onClickDownload && (
          <RoundButton variant="secondary" icon="download" onClick={onClickDownload}>
            Download
          </RoundButton>
        )}
      </section>
      <section className="flex flex-col grow overflow-hidden">
        <TabsContainer>
          <TabLink isActive={pageName === 'ALL_TRANSACTIONS'} href="/transactions">
            All Transactions
          </TabLink>
          <TabLink isActive={pageName === 'ORGANIZE_TRANSACTIONS'} href="/transactions/organize">
            <div className="flex items-center">
              <span className="mr-2">Organize</span>
              <TransactionsRequireAttentionNotification />
            </div>
          </TabLink>
          {!isBookkeeper && (
            <TabLink isActive={pageName === 'IMPORT_TRANSACTIONS'} href="/transactions/import">
              Import Transactions
            </TabLink>
          )}
          <TabLink isActive={pageName === 'EXPORT_TRANSACTIONS'} href="/transactions/exports">
            Transaction Exports
          </TabLink>
        </TabsContainer>
        <section className={cn('grow overflow-y-auto', className)}>
          {loading && (
            <div className="w-full flex justify-center mt-8">
              <LoadingSpinner />
            </div>
          )}

          {!loading && !syncedAccountsLoading && !transactionsCountLoading && (
            <>
              {showPageContent && <>{children}</>}
              {!showPageContent && <UpsellZena className="bg-white" />}
            </>
          )}
        </section>
      </section>
    </div>
  );
};
